import { RequestStoreActions, RequestStoreMutations } from "@/constants/store/request/requestStoreConstants";
import { Request, RequestFileMetadata } from "@/dataModel";

export default {
  [RequestStoreActions.SET_SELECTED_REQUEST]({ commit }: { commit: Function }, request: Request[]): void {
    commit(RequestStoreMutations.SET_SELECTED_REQUEST, request);
  },
  [RequestStoreActions.SET_REQUEST_FILE_METADATA](
    { commit }: { commit: Function },
    fileMetadata: RequestFileMetadata[],
  ): void {
    commit(RequestStoreMutations.SET_REQUEST_FILE_METADATA, fileMetadata);
  },
};
