<template>
  <basic-dialog
    id="confirm-delete-dialog"
    title="Confirm Delete"
    primaryButton="Confirm"
    secondaryButton="Cancel"
    :isDisabled="errors.length > 0"
    :primaryClick="confirm"
    :secondaryClick="cancel"
  >
    <p class="usa-prose">Are you sure you want to delete this role?</p>
    <div class="usa-alert usa-alert--info usa-alert--slim margin-top-1" v-for="error in errors" :key="error">
      <div class="usa-alert__body">
        <p class="usa-alert__text">{{ error }}</p>
      </div>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";

@Component({
  components: {
    BasicDialog,
  },
})
export default class DeleteRoleDialog extends Vue {
  @Prop({ default: false }) usersAssigned!: boolean;

  get errors() {
    const errors: string[] = [];
    if (this.usersAssigned) {
      errors.push("Cannot delete roles with assigned users.");
    }
    return errors;
  }
  cancel() {
    this.$bvModal.hide("confirm-delete-dialog");
  }
  confirm() {
    this.$emit("confirm");
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.usa-prose {
  font-weight: bold;
  margin-top: 1.5rem;
}
</style>
