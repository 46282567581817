<template>
  <fieldset class="usa-fieldset perpage-container">
    <div class="usa-button-group--segmented segmented-btn-group">
      <div class="usa-button-group__item segmented-btn-item">
        <input
          class="hide-radio"
          :id="`${uuid}-amt-20`"
          name="row-amount-20"
          type="radio"
          value="20"
          v-model.number="perPage"
        />
        <label class="usa-button segmented-btn" :for="`${uuid}-amt-20`" aria-label="20 results per page">20</label>
      </div>
      <div class="usa-button-group__item segmented-btn-item">
        <input
          class="hide-radio"
          :id="`${uuid}-amt-30`"
          name="row-amount-30"
          type="radio"
          value="30"
          v-model.number="perPage"
        />
        <label class="usa-button segmented-btn" :for="`${uuid}-amt-30`" aria-label="30 results per page">30</label>
      </div>
      <div class="usa-button-group__item segmented-btn-item">
        <input
          class="hide-radio"
          :id="`${uuid}-amt-50`"
          name="row-amount-50"
          type="radio"
          :value="50"
          v-model.number="perPage"
        />
        <label class="usa-button segmented-btn" :for="`${uuid}-amt-50`" aria-label="50 results per page">50</label>
      </div>
    </div>
  </fieldset>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { uniqueId } from "lodash";

@Component
export default class ResultsPerPageButton extends Vue {
  @Prop({ default: 20 }) readonly prevPerPage!: number;
  perPage = 20;
  uuid = "";

  @Watch("perPage")
  perPageAmountWatcher() {
    this.$emit("resultsPerPageChanged", this.perPage);
  }

  @Watch("prevPerPage")
  prevPerPageAmountWatcher() {
    this.perPage = this.prevPerPage;
  }

  beforeMount(): void {
    this.uuid = uniqueId("per-pg-");
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hide-radio {
  opacity: 0;
  height: 0;
  width: 0;
}

.hide-radio:checked + label {
  background: #005ea2;
  color: white;
  font-weight: bold;
}

.hide-radio:focus + label {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0;
  z-index: 6;
}

.segmented-btn-group {
  display: flex;
}

.segmented-btn-item {
  margin: 0;
}

.segmented-btn {
  padding-left: 1rem;
  padding-right: 1rem;
  color: #1b1b1b;
  font-weight: normal;
  background: #dfe1e2;
  margin-bottom: 0 !important;
}

.segmented-btn:hover {
  color: #1b1b1b;
  background: #a9aeb1;
}

.usa-fieldset {
  width: fit-content;
  margin-left: 2.5rem;
}

.usa-button-group--segmented .usa-button-group__item:not(:last-child) .usa-button::before {
  border-right: 0;
}

.usa-button-group--segmented .usa-button {
  width: 100% !important;
}
</style>
