<template>
  <span id="applied-selection-container" ref="appliedSelections">
    <button class="usa-button usa-button--outline" @click="onPanelButtonClicked">
      {{ panelButtonText }}
    </button>
    <span v-for="(selection, index) in selections" :key="index">
      <p class="applied-selection">
        {{ selection.displayValue }}
        <i
          class="fa fa-times delete-selection-btn float-right"
          @click="removeSelection(selection)"
          :aria-label="'Remove ' + selection.displayValue"
          tabindex="0"
          @keydown="removeAppliedFilterWithKeyboard($event, selection)"
        />
      </p>
    </span>
    <a href="#" class="usa-link" @click.prevent="toggleShowAllSelections" ref="showAllSelectionsLink">
      {{ showAllSelections ? `Show less` : `Show all` }}
      <i class="fa fa-fw" :class="showAllSelections ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
    </a>
  </span>
</template>

<script lang="ts">
import { Emit, Prop, Vue, Component, Watch } from "vue-property-decorator";
import { SelectionItem } from "@/dataModel/interfaces";

@Component
export default class AppliedFilterPanel extends Vue {
  showAllSelections: boolean = false;

  $refs!: {
    appliedSelections: HTMLElement;
    showAllSelectionsLink: HTMLElement;
  };

  @Prop({ default: () => [] }) selections!: SelectionItem[];
  @Prop({ default: "button" }) readonly panelButtonText!: string;
  @Prop({ default: 2 }) readonly maxRows!: number;
  @Prop({ default: null }) readonly defaultRowWidth!: number;

  @Emit("toggleShowAllSelections")
  toggleShowAllSelections(): void {
    this.showAllSelections = !this.showAllSelections;
    this.updateSelectionPanel();
  }

  @Emit("onPanelButtonClicked")
  onPanelButtonClicked(): void {}

  @Emit("onRemoveSelection")
  removeSelection(selection: SelectionItem) {
    return selection;
  }

  @Watch("selections")
  filtersChanged(): void {
    this.$nextTick(this.updateSelectionPanel);
  }

  updateSelectionPanel(): void {
    if (this.selections.length === 0) {
      return;
    }

    const filterContainer: HTMLElement = this.$refs.appliedSelections;
    const children: HTMLCollection = filterContainer.children;
    const showAllSelectionsLink: HTMLElement = this.$refs.showAllSelectionsLink;

    // Determine row width
    let rowWidth = filterContainer.offsetWidth;
    if (rowWidth == 0 && this.defaultRowWidth != null) {
      rowWidth = this.defaultRowWidth;
    }

    let total = 0;
    let numRows = 1;
    showAllSelectionsLink.style.display = "inline-block";

    for (let i = 0; i < children.length; ++i) {
      let item: HTMLElement = children[i] as HTMLElement;
      if (item == showAllSelectionsLink) {
        continue;
      }

      // Aggregate item width
      item.style.display = "inline-block";
      const style = window.getComputedStyle(item);
      const itemWidth = item.offsetWidth + parseFloat(style.marginRight);
      if (
        (numRows == this.maxRows &&
          total + itemWidth + showAllSelectionsLink.offsetWidth > rowWidth &&
          !this.showAllSelections) ||
        total + itemWidth > rowWidth
      ) {
        total = 0;
        numRows++;
      }

      if (numRows > this.maxRows && !this.showAllSelections) {
        item.style.display = "none";
      }

      total += itemWidth;
    }

    if (numRows <= this.maxRows) {
      this.showAllSelections = false;
      showAllSelectionsLink.style.display = "none";
    }
  }

  removeAppliedFilterWithKeyboard(event: KeyboardEvent, selection: SelectionItem): void {
    if (!event.repeat && (event.code == "Space" || event.code == "Enter")) {
      event.preventDefault();
      this.removeSelection(selection);
    }
  }

  mounted(): void {
    this.updateSelectionPanel();
    window.addEventListener("resize", this.updateSelectionPanel);
  }

  beforeDestroy(): void {
    window.removeEventListener("resize", this.updateSelectionPanel);
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/uswds/scss/uswds.scss";

.applied-selection {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  margin-right: units(1);
  background-color: color("base-lightest");
  border-radius: 10px;
  padding: 8px;
}

.delete-selection-btn {
  color: color("base-dark");
  margin-top: 5px;
  margin-left: units(1);

  &:hover {
    color: color("base-darkest");
  }
}

#applied-selection-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
</style>
