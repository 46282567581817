export enum StagedFileStoreActions {
    SET_SELECTED_FILES = 'SET_SELECTED_FILES',
    SET_STAGED_FILES_TO_UPDATE = 'SET_STAGED_FILES_TO_UPDATE',
    SET_NUMBER_OF_FILES_UPDATED = 'SET_NUMBER_OF_FILES_UPDATED',
    SET_FILTERS = 'SET_FILTERS',
    SET_PAGE = 'SET_PAGE',
    SET_RESULTS_PER_PAGE = 'SET_RESULTS_PER_PAGE',
    SET_SEARCH_TERM = 'SET_SEARCH_TERM',
    RESET_STAGED_FILE_STATE = 'RESET_STAGED_FILE_STATE',
}

export enum StagedFileStoreGetters {
    GET_SELECTED_FILES = 'GET_SELECTED_FILES',
    GET_STAGED_FILES_TO_UPDATE = 'GET_STAGED_FILES_TO_UPDATE',
    GET_NUMBER_OF_FILES_UPDATED = 'GET_NUMBER_OF_FILES_UPDATED',
    GET_FILTERS = 'GET_FILTERS',
    GET_PAGE = 'GET_PAGE',
    GET_RESULTS_PER_PAGE = 'GET_RESULTS_PER_PAGE',
    GET_SEARCH_TERM = 'GET_SEARCH_TERM',
}

export enum StagedFileStoreMutations {
    SET_SELECTED_FILES = 'SET_SELECTED_FILES',
    SET_STAGED_FILES_TO_UPDATE = 'SET_STAGED_FILES_TO_UPDATE',
    SET_NUMBER_OF_FILES_UPDATED = 'SET_NUMBER_OF_FILES_UPDATED',
    SET_FILTERS = 'SET_FILTERS',
    SET_PAGE = 'SET_PAGE',
    SET_RESULTS_PER_PAGE = 'SET_RESULTS_PER_PAGE',
    SET_SEARCH_TERM = 'SET_SEARCH_TERM',
    RESET_STAGED_FILE_STATE = 'RESET_STAGED_FILE_STATE',
}