<template>
  <div>
    <basic-dialog
      id="update-status-dialog"
      title="Update"
      primaryButton="Save Changes"
      secondaryButton="Cancel"
      :primaryClick="updateStatus"
      :secondaryClick="cancel"
    >
      <fieldset class="usa-fieldset margin-top-2">
        <legend class="usa-legend usa-legend bold">Status</legend>
        <div class="usa-radio radio-btn">
          <input
            class="usa-radio__input"
            id="status-open"
            type="radio"
            name="request-status"
            :value="RequestStatus.Open"
            v-model="requestStatus"
          />
          <label class="usa-radio__label fit" for="status-open">Open</label>
        </div>
        <div class="usa-radio radio-btn">
          <input
            class="usa-radio__input"
            id="status-closed"
            type="radio"
            name="request-status"
            :value="RequestStatus.Closed"
            v-model="requestStatus"
          />
          <label class="usa-radio__label fit" for="status-closed">Closed</label>
        </div>
      </fieldset>
    </basic-dialog>
    <success-toast id="request-updated-toast" message="Request status successfully updated."></success-toast>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Request } from "@/dataModel";
import RequestStatus from "@/constants/RequestStatus";
import BasicDialog from "@/components/common/BasicDialog.vue";

@Component({
  components: {
    BasicDialog,
  },
})
export default class UpdateRequestDialog extends Vue {
  @Prop() readonly request!: Request;
  RequestStatus = RequestStatus;
  requestStatus: string = "";

  cancel(): void {
    this.$bvModal.hide("update-status-dialog");
  }

  updateStatus(): void {
    this.$emit("confirm", this.requestStatus);
    this.$bvModal.hide("update-status-dialog");
  }

  initialize(status: RequestStatus): void {
    this.requestStatus = status;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.usa-form {
  width: 100%;
  max-width: 100%;
}

.radio-btn {
  float: left;
  width: 33%;
}

.fit {
  width: fit-content;
}

.bold {
  font-weight: bold;
}
</style>
