<template>
  <treeselect v-bind="$attrs" v-on="$listeners">
    <template v-slot:option-label="{ node, labelClassName }">
      <label :class="labelClassName">
        <span :class="{ 'text-italic': categoryService.isItalicFormat(node.raw) }">{{ node.label }}</span>
      </label>
    </template>
    <template v-slot:value-label="{ node }">
      <div :class="{ 'text-italic': categoryService.isItalicFormat(node.raw) }">{{ node.label }}</div>
    </template>
  </treeselect>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Treeselect from "@riophae/vue-treeselect";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import CategoryService from "@/services/CategoryService";

// Wrapper component for Treeselect that supports formatting category names
@Component({ components: { Treeselect } })
export default class CategoryTreeSelect extends Vue {
  private categoryService = container.get<CategoryService>(serviceTypes.CategoryService);
}
</script>
