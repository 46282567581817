import { Vue, Component } from "vue-property-decorator";
import HazardDataPropertyHttpHelper from "@/components/resources/hazardDataPropertyHttpHelper";
import HazardDataSearchHttpHelper from "@/components/resources/hazardDataSearchHttpHelper";
import { HazardType } from "@/constants/HazardType";
import container from "@/dependencyInjection/config";
import Breadcrumb from "../../common/Breadcrumb.vue";
import DataGraphView from "./DataGraphView.vue";
import serviceTypes from "@/dependencyInjection/types";
import CustomizeTableDialog from "./Dialogs/CustomizeTableDialog.vue";
import DataTable from "@/components/common/DataTable.vue";
import {
  DataTableAction,
  DataTableHeader,
  SelectionItem,
  HazardDataViewFields,
  DataViewSearchParameters,
} from "@/dataModel/interfaces";
import store from "@/store";
import StoreNames from "@/constants/store/StoreNames";
import {
  DataCollectionStoreActions,
  DataCollectionStoreGetters,
} from "@/constants/store/dataCollection/dataCollectionStoreConstants";
import ApplyFiltersDialog from "./Dialogs/ApplyFiltersDialog.vue";
import ExportDataDialog from "./Dialogs/ExportDataDialog.vue";
import { Category, BoxPlotSeries, FilterNodeInformation } from "@/dataModel";
import { DataValueSortType } from "@/constants/DataValueSortType";
import DataValueResult from "@/dataModel/hazardData/dataValueResult";
import GetDataValuesRequest from "@/dataModel/hazardData/getDataValuesRequest";
import { DataValueIdentifierType } from "@/constants/DataValueIdentifierType";
import { SortOrder } from "@/constants/SortOrder";
import DataValueOption from "@/dataModel/hazardData/dataValueOption";
import DataValueOptionResult from "@/dataModel/hazardData/dataValueOptionResult";
import IDataValueFilterRequest from "@/dataModel/hazardData/interfaces/IDataValueFilterRequest";
import DataValueFilterParent from "@/dataModel/hazardData/dataValueFilterParent";
import DataValueFilter from "@/dataModel/hazardData/dataValueFilter";
import GetBoxPlotDataRequest from "@/dataModel/hazardData/getBoxPlotDataRequest";
import DataValueIdentifierInformation from "@/dataModel/hazardData/dataValueIdentifierInformation";
import ExportDataRequest from "@/dataModel/hazardData/exportDataRequest";
import HazardPropertyDetails from "@/dataModel/hazardData/hazardPropertyDetails";
import { ExportFileType } from "@/dataModel/hazardData/enums/ExportFileType";
import ExportDataArgs from "@/dataModel/hazardData/ExportDataArgs";
import { flattenDataValueOptionResults } from "./hazardDataUtils";
import IAuthService from "@/services/interfaces/IAuthService";
import { AuthState } from "@/typings/store/states/AuthStoreState";
import FeaturedDataDialog from "./Dialogs/FeaturedDataDialog.vue";
import FeaturedResourceHttpHelper from "@/components/resources/featuredResourceHttpHelper";
import AppliedSelectionsPanel from "@/components/common/AppliedSelectionsPanel.vue";
import Spinner from "@/components/common/Spinner.vue";
import moment from "moment";

@Component({
  components: {
    Breadcrumb,
    DataTable,
    CustomizeTableDialog,
    ApplyFiltersDialog,
    ExportDataDialog,
    Spinner,
    DataGraphView,
    AppliedSelectionsPanel,
    FeaturedDataDialog,
  },
})
export class DataPageMixin extends Vue {
  page: number = 0;
  resultsPerPage: number = 50;
  sortType: DataValueSortType = DataValueSortType.SourceTitle;
  sortOrder: SortOrder = SortOrder.Ascending;
  sortIdentifierKey: string = DataValueIdentifierType.Source;

  hazardPropertyOptions: HazardPropertyDetails[] = [];
  hazardDataColumnOptions: DataValueOption[] = [];
  selectedHazardDataOptions: DataValueOption[] = [];
  selectedHazardType: HazardType = HazardType.Unknown;
  selectedPropertyId: string = "";
  selectedHazards: Category[] = [];

  dataValueFilters: DataValueFilterParent[] = [];
  selectedDataValueFilters: DataValueFilter[] = [];

  selectedGraphByOption?: DataValueOption;
  boxPlotData: BoxPlotSeries[] = [];
  isLoadingMore: boolean = false;
  isLoadingData: boolean = false;
  isLoadingFilters: boolean = false;

  tableActions: DataTableAction[] = [
    {
      name: "Customize Table Data",
      icon: "fa-columns",
      action: "customize",
    },
  ];
  tableHeaders: DataTableHeader[] = [
    {
      label: "Values",
      key: "value",
      sortable: false,
    },
  ];
  dataValues: DataValueResult[] = [];
  resultCount: number = 0;

  authState: AuthState | null = null;

  toastMessage = "Successfully added view to featured data pool";
  toastId = "added-to-pool-toast";

  private hazardDataPropertyHttpHelper = container.get<HazardDataPropertyHttpHelper>(
    serviceTypes.HazardDataPropertyHttpHelper,
  );

  private hazardDataSearchHttpHelper = container.get<HazardDataSearchHttpHelper>(
    serviceTypes.HazardDataSearchHttpHelper,
  );

  public featuredResourceHttpHelper = container.get<FeaturedResourceHttpHelper>(
    serviceTypes.FeaturedResourceHttpHelper,
  );

  private authService = container.get<IAuthService>(serviceTypes.AuthService);

  /**
   * Opens a dialog to let users change the columns displayed and their order in the data table view
   */
  openCustomizeTable(): void {
    this.$bvModal.show("customize-table-dialog");
  }

  openApplyFiltersDialog(): void {
    this.$bvModal.show("apply-filters-dialog");
  }

  openExportDataDialog(): void {
    this.$bvModal.show("export-data-dialog");
  }

  async exportDataAsync(args: ExportDataArgs): Promise<void> {
    const request = this.createExportDataRequest(args.includeAllColumns, args.fileType);
    const file = await this.hazardDataSearchHttpHelper.ExportDataAsync(request);

    // Trigger the download on the front end
    const fileExt = request.fileType === ExportFileType.Csv ? ".csv" : ".xlsx";
    const filename = `HKMA Data ${moment().format("YYYYMMDD")}${fileExt}`;
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([file]));
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  createExportDataRequest(allColumns: boolean, fileType: ExportFileType): ExportDataRequest {
    const hazardIds = this.selectedHazards.map((hazard) => hazard.id);
    const propertyId = this.selectedPropertyId.length == 0 ? undefined : this.selectedPropertyId;
    const selectedOptions: DataValueOption[] = allColumns
      ? this.hazardDataColumnOptions
      : this.selectedHazardDataOptions;
    return {
      fileType,
      property: propertyId,
      hazardAgents: hazardIds,
      selectedOptions: selectedOptions,
      categoryFilters: this.categoryFilters,
      sourceFilters: this.sourceFilters,
      evaluationRatingFilters: this.evaluationRatingFilters,
    };
  }

  get selectedProperty(): HazardPropertyDetails | undefined {
    return this.hazardPropertyOptions.filter((p) => p.id === this.selectedPropertyId)[0];
  }

  get selectedPropertyDisplay(): string {
    return this.selectedProperty?.name ?? "All Hazard Properties";
  }

  async fetchHazardDataOptionsAsync(resetSelectionsToDefault = true): Promise<void> {
    this.hazardDataColumnOptions = await this.hazardDataSearchHttpHelper.GetHazardDataOptionsAsync(
      this.selectedPropertyId,
    );

    if (resetSelectionsToDefault) {
      this.selectedHazardDataOptions = this.hazardDataColumnOptions.filter((option) => option.isDefault);
    }

    this.tableHeaders = this.mapDataValueOptionsToTableHeaders(this.selectedHazardDataOptions);
  }

  async selectedPropertyIdChanged(): Promise<void> {
    this.storeSelectedProperty();
    await this.fetchHazardDataOptionsAsync();
    this.resetTable();
    this.selectedDataValueFilters = [];
    await this.fetchDataValuesAsync();
    await this.fetchHazardDataFiltersAsync();
    await this.fetchBoxPlotDataAsync();
  }

  /** Updates the data table. Called when user clicks 'Apply' from the customize table dialog */
  async updateTable(selectedOptions: DataValueOption[]): Promise<void> {
    this.tableHeaders = this.mapDataValueOptionsToTableHeaders(selectedOptions);
    this.selectedHazardDataOptions = selectedOptions;
    this.resetTable();
    await this.resetSortingOptions();
    await this.fetchDataValuesAsync();
    await this.fetchBoxPlotDataAsync();
  }

  resetTable(): void {
    this.page = 0;
    this.dataValues = [];
  }

  resetSortingOptions(): void {
    this.sortType = DataValueSortType.SourceTitle;
    this.sortOrder = SortOrder.Ascending;
    this.sortIdentifierKey = DataValueIdentifierType.Source;
  }

  /** Updates the applied filters. Called when user clicks 'Apply' from the apply filters dialog */
  updateAppliedFilters(selectedFilters: DataValueFilter[]): void {
    this.selectedDataValueFilters = selectedFilters;
    this.resetTable();
    this.fetchDataValuesAsync();
    this.fetchBoxPlotDataAsync();
  }

  mapDataValueOptionsToTableHeaders(dataValueOptions: DataValueOption[]): DataTableHeader[] {
    return dataValueOptions.map((option) => {
      const header: DataTableHeader = {
        key: option.identifierKey,
        label: option.displayName,
        sortable: option.isSortable,
      };
      return header;
    });
  }

  async fetchDataValuesAsync(): Promise<void> {
    try {
      const request = this.createGetDataValuesRequest();
      const response = await this.hazardDataSearchHttpHelper.GetHazardDataValuesAsync(request);
      const dataValueResults = response.result.map((r) => new DataValueResult(r.dataValueId, r.optionResults));
      flattenDataValueOptionResults(dataValueResults);
      this.dataValues.push(...dataValueResults);
      this.resultCount = response.count;
    } finally {
      this.isLoadingData = false;
    }
  }

  createGetDataValuesRequest(): GetDataValuesRequest {
    const hazardIds = this.selectedHazards.map((hazard) => hazard.id);
    const propertyId = this.selectedPropertyId.length == 0 ? null : this.selectedPropertyId;
    return new GetDataValuesRequest(
      this.page,
      this.resultsPerPage,
      this.sortType,
      this.sortOrder,
      this.sortIdentifierKey,
      propertyId,
      hazardIds,
      this.selectedHazardDataOptions,
      this.categoryFilters,
      this.sourceFilters,
      this.evaluationRatingFilters,
    );
  }

  sortingChanged(ctx: any): void {
    this.resetTable();
    this.sortOrder = ctx.sortDesc ? SortOrder.Descending : SortOrder.Ascending;
    this.sortIdentifierKey = ctx.sortBy;
    this.sortType = this.getSortType(ctx.sortBy);
    this.fetchDataValuesAsync();
  }

  getSortType(sortByHeader: string): DataValueSortType {
    switch (sortByHeader) {
      case DataValueIdentifierType.Source:
        return DataValueSortType.SourceTitle;
      case DataValueIdentifierType.EvaluationRating:
        return DataValueSortType.EvaluationRating;
      case DataValueIdentifierType.HazardAgent:
        return DataValueSortType.HazardAgent;
      case DataValueIdentifierType.Value:
        return DataValueSortType.SiValue;
      case DataValueIdentifierType.Property:
        return DataValueSortType.Property;
      default:
        return DataValueSortType.HazardCategory;
    }
  }

  async loadMoreResults(): Promise<void> {
    this.page = this.page + 1;
    this.isLoadingMore = true;
    await this.fetchDataValuesAsync();
    this.isLoadingMore = false;
  }

  async fetchHazardPropertiesByTypeAsync(hazardType: HazardType): Promise<void> {
    this.hazardPropertyOptions = await this.hazardDataPropertyHttpHelper.getHazardPropertiesByType(hazardType);
  }

  async fetchHazardDataFiltersAsync(): Promise<void> {
    try {
      this.isLoadingFilters = true;
      const request = this.createDataValueFilterRequest();
      const response = await this.hazardDataSearchHttpHelper.GetHazardDataFiltersAsync(request);
      this.dataValueFilters = response;
      this.dataValueFilters.forEach((filterCategory) => {
        filterCategory.filters = filterCategory.filters.map((filter) => ({
          ...filter,
          identifierType: filterCategory.identifierType,
        }));
      });
    } finally {
      this.isLoadingFilters = false;
    }
  }

  createDataValueFilterRequest(): IDataValueFilterRequest {
    const hazardIds = this.selectedHazards.map((hazard) => hazard.id);
    const propertyId = this.selectedPropertyId.length == 0 ? null : this.selectedPropertyId;
    return {
      property: propertyId,
      hazardAgents: hazardIds,
      categoryFilters: this.categoryFilters,
      sourceFilters: this.sourceFilters,
      evaluationRatingFilters: this.evaluationRatingFilters,
    };
  }

  storeSelectedHazards(selectedHazards: FilterNodeInformation[]): void {
    const hazards: Category[] = selectedHazards.map((node) => node.value);
    store.dispatch(`${StoreNames.DataCollection}/${DataCollectionStoreActions.SET_SELECTED_HAZARDS}`, hazards);
  }

  storeSelectedProperty(): void {
    store.dispatch(
      `${StoreNames.DataCollection}/${DataCollectionStoreActions.SET_SELECTED_HAZARD_PROPERTY}`,
      this.selectedProperty,
    );
  }

  viewDataSourceFromTable(optionResults: DataValueOptionResult[]): void {
    const id = optionResults.find((optionResult) => optionResult.identifierKey == "Source-Title")!.valueId;
    this.viewDataSource(id);
  }

  viewDataSource(id: string): void {
    this.$router.push({ name: "DocumentSummary", query: { id: id } });
  }

  initializeDataCollectionStoreState(): void {
    this.selectedHazards =
      store.getters[`${StoreNames.DataCollection}/${DataCollectionStoreGetters.GET_SELECTED_HAZARDS}`];
    const selectedProperty =
      store.getters[`${StoreNames.DataCollection}/${DataCollectionStoreGetters.GET_SELECTED_HAZARD_PROPERTY}`];
    this.selectedHazardType =
      store.getters[`${StoreNames.DataCollection}/${DataCollectionStoreGetters.GET_SELECTED_HAZARD_TYPE}`];

    if (selectedProperty) {
      this.selectedPropertyId = selectedProperty.id;
    }
  }

  async initializeDataPageAsync(): Promise<void> {
    this.isLoadingData = true;
    this.initializeDataCollectionStoreState();
    this.fetchHazardPropertiesByTypeAsync(this.selectedHazardType);
    this.setAuthState();
    await this.fetchHazardDataOptionsAsync();
    await this.fetchDataValuesAsync();
    await this.fetchHazardDataFiltersAsync();
    await this.fetchBoxPlotDataAsync();
  }

  async loadDataViewAsync(searchParams: DataViewSearchParameters, hazardTypeId: HazardType): Promise<void> {
    this.isLoadingData = true;

    try {
      if (searchParams === undefined) {
        throw new Error("Data view parameters are undefined");
      }

      this.setAuthState();

      // Update stored hazard type
      store.dispatch(
        `${StoreNames.DataCollection}/${DataCollectionStoreActions.SET_SELECTED_HAZARD_TYPE}`,
        hazardTypeId,
      );

      // Updated stored property
      await this.fetchHazardPropertiesByTypeAsync(hazardTypeId);
      const dataViewProperty = this.hazardPropertyOptions.find((o) => o.id === searchParams.property);
      store.dispatch(
        `${StoreNames.DataCollection}/${DataCollectionStoreActions.SET_SELECTED_HAZARD_PROPERTY}`,
        dataViewProperty,
      );

      // Update stored hazard selections
      const tree: FilterNodeInformation = await this.hazardDataSearchHttpHelper.GetHazardDataTaxonomyAsync(
        HazardType.Biological,
        searchParams.property,
      );
      const selectedHazards: FilterNodeInformation[] = tree.leafNodes.filter((t) => {
        const hazardId: string = (t.value as Category).id;
        return searchParams.hazardAgents.includes(hazardId);
      });
      this.storeSelectedHazards(selectedHazards);

      // Set the hazard and property on the page
      this.initializeDataCollectionStoreState();

      // Set filters
      const allSelectedFilters = searchParams.categoryFilters
        .concat(searchParams.evaluationRatingFilters)
        .concat(searchParams.sourceFilters);
      await this.fetchHazardDataFiltersAsync();

      if (allSelectedFilters.length > 0) {
        this.selectedDataValueFilters = this.dataValueFilters
          .flatMap((f) => f.filters)
          .filter((f) => allSelectedFilters.includes(f.id));
      }

      // Load data and columns
      await this.fetchHazardDataOptionsAsync(false);
      const selectedOptions: DataValueOption[] = this.hazardDataColumnOptions.filter((option) =>
        searchParams.selectedOptions.some((o) => o.identifierKey === option.identifierKey),
      );
      await this.updateTable(selectedOptions);
    } catch (err) {
      console.error(err);
      alert("A problem occurred loading the data view");
    } finally {
      this.isLoadingData = false;
    }
  }

  get canLoadMore(): boolean {
    return this.dataValues.length < this.resultCount;
  }

  get dataTableResultsPerPage(): number {
    return (this.page + 1) * this.resultsPerPage;
  }

  get dataValueFilterSelectionItems(): SelectionItem[] {
    return this.selectedDataValueFilters.map((filter) => {
      const selection: SelectionItem = {
        displayValue: filter.displayValue,
        data: filter,
      };
      return selection;
    });
  }

  removeFilterSelectionItem(selection: SelectionItem): void {
    this.resetTable();
    const toRemove = this.selectedDataValueFilters.findIndex((f) => f.id === selection.data.id);
    this.selectedDataValueFilters.splice(toRemove, 1);
    this.fetchDataValuesAsync();
    this.fetchBoxPlotDataAsync();
  }

  clearAllSelectedFilters(): void {
    this.resetTable();
    this.selectedDataValueFilters = [];
    this.fetchDataValuesAsync();
    this.fetchBoxPlotDataAsync();
  }

  get categoryFilters(): string[] {
    return this.selectedDataValueFilters
      .filter((filter) => filter.identifierType == DataValueIdentifierType.Category)
      .map((filter) => filter.id);
  }

  get sourceFilters(): string[] {
    return this.selectedDataValueFilters
      .filter((filter) => filter.identifierType == DataValueIdentifierType.Source)
      .map((filter) => filter.id);
  }

  get evaluationRatingFilters(): string[] {
    return this.selectedDataValueFilters
      .filter((filter) => filter.identifierType == DataValueIdentifierType.EvaluationRating)
      .map((filter) => filter.id);
  }

  //#region Box plot methods and computed properties

  get graphByOptions(): DataValueOption[] {
    return this.hazardDataColumnOptions.filter(
      (o) =>
        o.identifierType === DataValueIdentifierType.HazardAgent ||
        o.identifierType === DataValueIdentifierType.Category,
    );
  }

  async graphBySelectionChanged(selection: DataValueOption): Promise<void> {
    this.selectedGraphByOption = selection;
    await this.fetchBoxPlotDataAsync();
  }

  async fetchBoxPlotDataAsync(): Promise<void> {
    const request = this.createBoxPlotDataRequest(this.selectedGraphByOption);
    this.boxPlotData = await this.hazardDataSearchHttpHelper.GetHazardBoxPlotDataAsync(request);
  }

  createBoxPlotDataRequest(graphByOption?: DataValueOption): GetBoxPlotDataRequest {
    const hazardIds = this.selectedHazards.map((hazard) => hazard.id);
    const graphBySelection =
      graphByOption === undefined
        ? new DataValueIdentifierInformation(DataValueIdentifierType.HazardAgent, DataValueIdentifierType.HazardAgent)
        : new DataValueIdentifierInformation(graphByOption.identifierType, graphByOption.identifierKey);
    return new GetBoxPlotDataRequest(
      this.selectedPropertyId,
      hazardIds,
      graphBySelection,
      this.categoryFilters,
      this.sourceFilters,
      this.evaluationRatingFilters,
    );
  }

  //#endregion

  //#region Featured Data Management
  async addToFeaturedDataPoolAsync({ name, description }: HazardDataViewFields): Promise<void> {
    const dataViewSearchParameters: DataViewSearchParameters = {
      ...this.createDataValueFilterRequest(),
      selectedOptions: this.selectedHazardDataOptions,
    };
    const hazardTypeId: HazardType = this.selectedHazardType;

    await this.featuredResourceHttpHelper.addPooledFeaturedDataViewAsync({
      name,
      description,
      hazardTypeId,
      dataViewSearchParameters,
    });

    this.$bvToast.show(this.toastId);
  }

  setAuthState(): void {
    this.authState = this.authService.getAuthState();
  }

  openFeaturedDataDialog(): void {
    this.$bvModal.show("featured-data-dialog");
  }

  get userHasFeaturedResourceManagementAccess(): boolean {
    return this.authState?.access.hasFeaturedResourceManagementAccess ?? false;
  }

  //#endregion
}
