import { render, staticRenderFns } from "./ConvertToSiteDocumentsPage.vue?vue&type=template&id=7875f95e&scoped=true"
import script from "./ConvertToSiteDocumentsPage.vue?vue&type=script&lang=ts"
export * from "./ConvertToSiteDocumentsPage.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7875f95e",
  null
  
)

export default component.exports