import FormatType from "@/constants/FormatType";

export class Category {
  id: string;
  parentId: string;
  parent: Category | null;
  displayName: string;
  label: string;
  children?: Category[];
  hasChildren?: boolean;
  value?: string;
  formatType: FormatType;

  constructor(
    id?: string,
    parentId?: string,
    displayName?: string,
    label?: string,
    children?: Category[],
    parent?: Category | null,
    hasChildren?: boolean,
    value?: string,
    formatType?: FormatType,
  ) {
    this.id = id ?? "";
    this.parentId = parentId ?? "";
    this.displayName = displayName ?? "";
    this.label = label ?? "";
    this.children = children;
    this.parent = parent ?? null;
    this.hasChildren = hasChildren ?? false;
    this.value = value ?? "";
    this.formatType = formatType ?? FormatType.Normal;
  }

  public toString = (): string => {
    return this.displayName;
  };
}
