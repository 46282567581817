import axios, { AxiosResponse, CancelToken, CancelTokenSource, ResponseType } from "axios";
import IAuthService from "@/services/interfaces/IAuthService";
import serviceTypes from "@/dependencyInjection/types";
import { inject, injectable } from "inversify";
import { AppConfig } from "@/dataModel";
import { ApiResponse } from "./ApiResponse";

@injectable()
export default class HttpHelper {
  private baseUrl: string;
  private auth: IAuthService;

  public constructor(
    @inject(serviceTypes.AppConfig) appConfig: AppConfig,
    @inject(serviceTypes.AuthService) auth: IAuthService,
  ) {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error?.response) {
          switch (error.response.status) {
            case 401:
              location.href = appConfig.baseUrl + "/unauthorized";
              break;
            case 403:
              location.href = appConfig.baseUrl + "/forbidden";
              break;
            case 417:
              location.href = appConfig.baseUrl + "/permissions-required";
              break;
          }
        }
        return Promise.reject(error);
      },
    );
    this.auth = auth;
    this.baseUrl = appConfig.baseUrl + appConfig.apiEndpoint + "/";
  }

  async getAxiosHeaderAsync() {
    const token = await this.auth.getAccessToken();
    if (token !== "") {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      throw new Error("An error occurred getting access token.");
    }
  }

  public async httpGet(
    url: string,
    responseType?: ResponseType,
    cancelToken?: CancelTokenSource,
  ): Promise<AxiosResponse> {
    await this.getAxiosHeaderAsync();
    return new Promise((resolve, reject) => {
      axios
        .get(this.baseUrl + url, {
          responseType: responseType,
          cancelToken: cancelToken?.token,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  public async httpPost(
    url: string,
    data: object,
    headers: any = {},
    cancelToken?: CancelTokenSource,
    responseType?: ResponseType,
  ): Promise<AxiosResponse> {
    await this.getAxiosHeaderAsync();
    return new Promise((resolve, reject) => {
      axios
        .post(this.baseUrl + url, data, {
          headers: headers,
          cancelToken: cancelToken?.token,
          responseType: responseType,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  public async httpPut(url: string, data: object) {
    await this.getAxiosHeaderAsync();
    return new Promise((resolve, reject) => {
      axios
        .put(this.baseUrl + url, data, {
          headers: {},
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  public async httpDelete(url: string): Promise<AxiosResponse> {
    await this.getAxiosHeaderAsync();
    return new Promise((resolve, reject) => {
      axios
        .delete(this.baseUrl + url, {
          headers: {},
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}
