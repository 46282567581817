<template>
  <div>
    <basic-dialog
      id="subscription-dialog"
      :title="title"
      primaryButton="Confirm"
      secondaryButton="Cancel"
      :primaryClick="confirm"
      :secondaryClick="cancel"
      :isDisabled="!hasSearchTerm"
    >
      <p class="usa-prose">{{ summary }}</p>
      <p v-if="!isSubscribed" class="usa-prose">Notifications will be sent to {{ userEmail }}</p>

      <p class="usa-prose"><span class="bold">Search term: </span>{{ searchTerm }}</p>
      <p v-if="filters.length > 0" class="usa-prose margin-bottom-0"><span class="bold">Filters:</span></p>
      <ul class="margin-left-4">
        <li class="usa-prose" v-for="(filter, index) in filters" v-bind:key="index">{{ filter.stringValue }}</li>
      </ul>

      <div class="usa-alert usa-alert--info usa-alert--slim" v-if="!hasSearchTerm">
        <div class="usa-alert__body">
          <p class="usa-alert__text">Search term cannot be empty.</p>
        </div>
      </div>
      <div class="usa-alert usa-alert--warning usa-alert--slim" v-else-if="yearFilterSelected && !isSubscribed">
        <div class="usa-alert__body">
          <p class="usa-alert__text">
            You will only receive notifications if results from the specified year(s) are added.
          </p>
        </div>
      </div>
    </basic-dialog>
    <success-toast id="subscribe-toast" message="You have successfully subscribed to the search."></success-toast>
    <success-toast id="unsubscribe-toast" message="You have successfully unsubscribed to the search."></success-toast>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import { FilterNodeInformation } from "@/dataModel";
import { FilterType } from "@/constants/FilterType";

@Component({
  components: {
    BasicDialog,
  },
})
export default class SubscriptionDialog extends Vue {
  @Prop(Boolean) readonly isSubscribed!: boolean;
  @Prop(String) readonly searchTerm!: string;
  @Prop(Array) readonly filters: FilterNodeInformation[] | undefined;
  @Prop(String) readonly userEmail!: string;

  get title(): string {
    if (!this.isSubscribed) {
      return "Subscribe";
    } else {
      return "Unsubscribe";
    }
  }
  get summary(): string {
    if (!this.isSubscribed) {
      return "Would you like to subscribe to this search? You will receive a notification by email when new results are added.";
    } else {
      return "Would you like to unsubscribe from this search? You will no longer receive notifications when new results are added.";
    }
  }

  cancel(): void {
    this.$bvModal.hide("subscription-dialog");
  }

  get hasSearchTerm(): boolean {
    return this.searchTerm.length > 0;
  }

  get yearFilterSelected(): boolean {
    return this.filters?.some((f) => f.filterType === FilterType.Year) ?? false;
  }

  confirm(): void {
    if (!this.isSubscribed) {
      this.$emit("confirmSubscribe");
    } else {
      this.$emit("confirmUnsubscribe");
    }
    this.$bvModal.hide("subscription-dialog");
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

.bold {
  font-weight: font-weight("bold");
}
</style>
