<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <!-- Page header -->
      <div class="grid-row margin-top-105em">
        <h1 class="font-heading-xl text-primary-dark margin-top-0 margin-bottom-2">Document Staging</h1>
      </div>

      <!-- Tabs -->
      <b-tabs v-model="activeTabIndex">
        <!-- Single document tab -->
        <b-tab :title="tabTitles[0]" title-link-class="tab-link" active>
          <div class="grid-row">
            <div class="margin-left-auto">
              <button class="usa-button margin-y-2 margin-right-0" @click="showDocumentDialog">Upload Documents</button>
            </div>
          </div>

          <div class="grid-row margin-bottom-2">
            <div class="desktop:grid-col-2">
              <h3 class="font-heading-lg text-bold text-primary-dark" id="filter-header">Filters</h3>
              <data-table-filter
                @filtersChanged="onFiltersChanged"
                :preSelectedFilters="preSelectedFilters"
                :filters="filters"
              />
            </div>

            <div class="desktop:grid-col padding-left-2">
              <div class="grid-row grid-gap margin-bottom-2">
                <form class="usa-search usa-search--small search-style" role="search" @submit.prevent="search">
                  <label class="usa-sr-only" for="search-field-en-small"> Search </label>
                  <input
                    class="usa-input"
                    id="search-field-en-small"
                    type="search"
                    name="search"
                    v-model.trim="searchTerm"
                    placeholder="Search Staged Documents..."
                  />
                  <button class="usa-button" type="submit">
                    <span class="usa-sr-only">Search</span>
                  </button>
                </form>

                <results-per-page-button
                  class="align-right"
                  :prevPerPage="perPage"
                  @resultsPerPageChanged="perPageAmountChanged"
                />

                <div class="grid-col-auto pagination-container">
                  <table-pagination
                    class="pagination"
                    @prev="prevPage"
                    @next="nextPage"
                    :page="page"
                    :hasNextPage="hasNextPage"
                  />
                </div>
              </div>

              <div class="grid-row">
                <div class="desktop:grid-col">
                  <data-table
                    @approve="showApproveDialog"
                    @decline="showDeclineDialog"
                    @convert="convertSelection"
                    @delete="showDeleteDialog"
                    @log="showLogDialog"
                    @selections="setSelectedFiles"
                    @sortingChanged="sortingChanged"
                    :isLoadingData="isSearchLoading"
                    :items="files"
                    :fields="headers"
                    :actions="tableActions"
                    :bulkActions="tableBulkActions"
                    :collapseActions="true"
                    :perPage="50"
                    :selected="selectedFiles"
                  >
                    <template v-slot:cell(fileName)="data">
                      <div>
                        <div class="usa-checkbox margin-y-05">
                          <input
                            class="usa-checkbox__input"
                            :id="data.item.id"
                            type="checkbox"
                            :name="data.value"
                            v-model="selectedFiles"
                            :value="data.item"
                          />
                          <label class="usa-checkbox__label margin-0" :title="data.value" :for="data.item.id">
                            <a
                              v-if="fileIsViewable(data.item, actor)"
                              href="#/"
                              @click.prevent="viewFile(data.item.id)"
                              class="usa-link filename overflow-ellipsis"
                            >
                              {{ data.value }}
                            </a>
                            <span v-else class="filename overflow-ellipsis">
                              {{ data.value }}
                            </span>
                          </label>
                        </div>
                        <div class="grid-row margin-top-1">
                          <span
                            v-for="(tag, index) in getFileInfoTags(data.item)"
                            :key="`${data.item.id}-tag-${index}`"
                            class="usa-tag"
                          >
                            {{ tag }}
                          </span>
                        </div>
                      </div>
                    </template>
                  </data-table>
                  <table-pagination
                    class="pagination"
                    @prev="prevPage"
                    @next="nextPage"
                    :page="page"
                    :hasNextPage="hasNextPage"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Dialogs -->
          <upload-document-dialog dialog-id="upload-document-dialog" @onUpload="uploadDocuments" />
          <document-log-dialog @addNote="addNoteToLog" :file="currentFile" :displayNames="logDisplayNames" />
          <decline-document-dialog
            @declineDocuments="updateFileStatus(fileStatus.Declined, 'Document(s) successfully declined.', $event)"
            :files="selectedFiles"
          />
          <confirm-dialog
            @approve="updateFileStatus(fileStatus.Approved, 'Document(s) successfully approved.')"
            @delete="deleteDocument"
            :files="selectedFiles"
            :title="confirmDialogTitle"
          />

          <!-- Toasts -->
          <success-toast id="staged-file-toast" :message="toastText" />
        </b-tab>

        <!-- Document batch tab -->
        <b-tab :title="tabTitles[1]" title-link-class="tab-link" title-item-class="tab-item">
          <batch-list :actor="actor" @on-show-file-log="showLogDialog" @on-file-delete="showDeleteDialog" />
        </b-tab>
      </b-tabs>
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import { StagedFileStoreActions, StagedFileStoreGetters } from "@/constants/store/stagedFile/stagedFileStoreConstants";
import StoreNames from "@/constants/store/StoreNames";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import DataTable from "@/components/common/DataTable.vue";
import DataTableFilter from "@/components/common/DataTableFilter.vue";
import TablePagination from "@/components/common/TablePagination.vue";
import ResultsPerPageButton from "@/components/common/ResultsPerPageButton.vue";
import DocumentLogDialog from "./DocumentStagingDialogs/DocumentLogDialog.vue";
import UploadDocumentDialog from "./DocumentStagingDialogs/UploadDialog.vue";
import DeclineDocumentDialog from "./DocumentStagingDialogs/DeclineDocumentDialog.vue";
import ConfirmDialog from "./DocumentStagingDialogs/ConfirmDialog.vue";
import { DataTableFilterData, DataTableAction, DataTableHeader, BreadcrumbPathItem } from "@/dataModel/interfaces";
import { StagedFile, StagedFileEventLog, Actor, StagedFileBatch } from "@/dataModel";
import FileStatus from "@/constants/FileStatus";
import DocumentStagingHttpHelper from "@/components/resources/documentStagingHttpHelper";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import UserHttpHelper from "@/components/resources/userHttpHelper";
import { AuthAccess, User as UserState } from "@/typings/store/states/AuthStoreState";
import IAuthService from "@/services/interfaces/IAuthService";
import BatchList from "@/components/navigation/DocumentStaging/DocumentBatch/BatchList.vue";
import { addSpaces } from "@/utils/helperFunctions";
import { getFileInfoTags, fileIsViewable } from "./documentStagingHelpers";

@Component({
  components: {
    BatchList,
    Breadcrumb,
    ConfirmDialog,
    DataTable,
    DataTableFilter,
    DeclineDocumentDialog,
    DocumentLogDialog,
    UploadDocumentDialog,
    TablePagination,
    ResultsPerPageButton,
  },
})
export default class DocumentStagingPage extends Vue {
  getFileInfoTags = getFileInfoTags;
  fileIsViewable = fileIsViewable;
  actor?: Actor;
  files: StagedFile[] = [];
  fileStatus = FileStatus;
  currentFile = new StagedFile();
  page = 1;
  perPage = 20;
  preSelectedFilters: string[] = [];
  statusFilters: string[] = [];
  sortOrder = "ascending";
  sortColumn = "status";
  searchTerm = "";
  selectedFiles: StagedFile[] = [];
  toastText = "";
  userDisplayName = "";
  resultCount = 0;
  isSearchLoading: boolean = true;
  logDisplayNames: string[] = [];
  confirmDialogTitle = "";
  userAccess!: AuthAccess;
  tabTitles = ["Single Document", "Document Batch"];
  activeTabIndex = 0;

  private docStagingHttpHelper = container.get<DocumentStagingHttpHelper>(serviceTypes.DocumentStagingHttpHelper);
  private userHttpHelper = container.get<UserHttpHelper>(serviceTypes.UserHttpHelper);
  private authService = container.get<IAuthService>(serviceTypes.AuthService);

  headers: DataTableHeader[] = [
    {
      label: "Filename",
      key: "fileName",
      sortable: true,
    },
    {
      label: "Created",
      key: "createdDateTime",
      sortable: true,
      formatter: (val) => this.$options.filters?.formatDate(val),
    },
    {
      label: "Status",
      key: "fileStatus",
      sortable: true,
      formatter: addSpaces,
    },
    {
      // header for actions
      label: "",
      key: "actions",
      sortable: false,
    },
  ];

  filters: DataTableFilterData[] = [
    {
      name: "status",
      filters: ["Approved", "Declined", "Awaiting Approval", "Converted"],
    },
  ];

  get path(): BreadcrumbPathItem[] {
    return [{ text: "Administration" }, { text: "Document Staging" }, { text: this.tabTitles[this.activeTabIndex] }];
  }

  get hasNextPage(): boolean {
    return this.page * this.perPage < this.resultCount;
  }

  async searchFiles(): Promise<void> {
    // reset selection
    this.selectedFiles.splice(0);

    try {
      this.isSearchLoading = true;
      const currentPage = this.page - 1;
      const response = await this.docStagingHttpHelper.searchStagedFiles(
        currentPage,
        this.perPage,
        this.searchTerm,
        this.sortOrder,
        this.sortColumn,
        this.statusFilters,
      );

      this.files = response.result;
      this.resultCount = response.count;
    } finally {
      this.isSearchLoading = false;
    }
  }

  async getStagedFileLog(): Promise<void> {
    const log = await this.docStagingHttpHelper.getStagedFileEventLog(this.currentFile.id);

    // sort by date
    log.sort((a, b) => new Date(a.logDateTime).getTime() - new Date(b.logDateTime).getTime());

    Vue.set(this.currentFile, "log", log);
  }

  async getLogDisplayNames(log: StagedFileEventLog[]): Promise<void> {
    this.logDisplayNames.splice(0);
    const actorsAlreadyFound: { actorId: string; name: string }[] = [];

    for (const entry of log) {
      const foundIndex = actorsAlreadyFound.map((a) => a.actorId).indexOf(entry.actorId);
      if (foundIndex != -1) {
        // don't request for actors already found
        this.logDisplayNames.push(actorsAlreadyFound[foundIndex].name);
      } else {
        const { displayName } = await this.userHttpHelper.getUserByActorId(entry.actorId);
        this.logDisplayNames.push(displayName);
        actorsAlreadyFound.push({ actorId: entry.actorId, name: displayName });
      }
    }
  }

  async addNoteToLog(note: StagedFileEventLog): Promise<void> {
    this.logDisplayNames.splice(this.logDisplayNames.length - 1, 0, this.userDisplayName);
    await this.docStagingHttpHelper.updateStagedFileEventLog(note);
  }

  async updateFileStatus(newStatus: FileStatus, toastText: string, note?: string): Promise<void> {
    if (this.selectedFiles.length > 0) {
      const ids = this.selectedFiles.map((file) => file.id);
      await this.docStagingHttpHelper.bulkChangeFileStatus(ids, newStatus, note ?? "");
      this.toastText = toastText;
      this.$bvToast.show("staged-file-toast");
      this.searchFiles();
    }
  }

  async deleteDocument(): Promise<void> {
    await this.docStagingHttpHelper.deleteStagedFile(this.currentFile.id);

    this.files.splice(this.files.indexOf(this.currentFile), 1);
    // if document was selected, remove from selection as well
    if (this.selectedFiles.includes(this.currentFile)) {
      this.selectedFiles.splice(this.selectedFiles.indexOf(this.currentFile), 1);
    }
    this.toastText = "Document successfully deleted.";
    this.$bvToast.show("staged-file-toast");
  }

  async uploadDocuments(numberOfFiles: number): Promise<void> {
    this.toastText = `${numberOfFiles} document(s) successfully uploaded.`;
    this.$bvToast.show("staged-file-toast");
    this.sortOrder = "descending";
    this.sortColumn = "created";
    this.searchFiles();
  }

  showDocumentDialog(): void {
    this.$bvModal.show("upload-document-dialog");
  }

  async showLogDialog(file: StagedFile): Promise<void> {
    this.currentFile = file;
    await this.getStagedFileLog();
    await this.getLogDisplayNames(this.currentFile.log ?? []);
    this.$bvModal.show("document-log-dialog");
  }

  viewFile(id: string): void {
    this.setSearchState();
    this.$router.push({
      name: "ViewStagedFile",
      params: { id },
    });
  }

  showApproveDialog(batch: StagedFileBatch | undefined): void {
    if (batch?.batchId) {
      this.selectedFiles = batch.stagedFiles;
    }
    this.confirmDialogTitle = "Confirm Approval";
    this.$bvModal.show("confirm-dialog");
  }

  showDeleteDialog(file: StagedFile): void {
    this.confirmDialogTitle = "Confirm Delete";
    this.currentFile = file;
    this.$bvModal.show("confirm-dialog");
  }

  showDeclineDialog(batch: StagedFileBatch | undefined): void {
    if (batch?.batchId) {
      this.selectedFiles = batch.stagedFiles;
    }
    this.$bvModal.show("decline-document-dialog");
  }

  search(): void {
    if (this.searchTerm.length) {
      this.sortColumn = "relevance";
      this.sortOrder = "ascending";
    }
    this.searchFiles();
  }

  sortingChanged(ctx: any): void {
    switch (ctx.sortBy) {
      case "createdDateTime":
        this.sortColumn = "created";
        break;
      case "fileStatus":
        this.sortColumn = "status";
        break;
      default:
        this.sortColumn = ctx.sortBy;
    }
    this.sortOrder = ctx.sortDesc ? "descending" : "ascending";
    this.searchFiles();
  }

  perPageAmountChanged(newValue: number): void {
    this.perPage = newValue;
    this.page = 1;
    this.searchFiles();
  }

  convertSelection(): void {
    // only allow approved files to be converted
    if (!this.selectedFiles.every((file) => file.fileStatus === FileStatus.Approved)) {
      this.confirmDialogTitle = "Invalid Selection";
      this.$bvModal.show("confirm-dialog");
      return;
    }

    store.dispatch(`${StoreNames.StagedFile}/${StagedFileStoreActions.SET_SELECTED_FILES}`, this.selectedFiles);
    this.setSearchState();

    this.$router.push({ name: "ConvertToSiteDocuments" }).catch(() => {
      this.confirmDialogTitle = "Empty Selection";
      this.$bvModal.show("confirm-dialog");
    });
  }

  setSelectedFiles(selectedFiles: StagedFile[]): void {
    this.selectedFiles = selectedFiles;
  }

  onFiltersChanged(selectedFilters: string[]): void {
    this.statusFilters = selectedFilters;
    this.searchFiles();
  }

  prevPage(): void {
    if (this.page > 1) {
      this.page--;
      this.searchFiles();
    }
  }

  nextPage(): void {
    if (this.hasNextPage) {
      this.page++;
      this.searchFiles();
    }
  }

  setSearchState(): void {
    store.dispatch(`${StoreNames.StagedFile}/${StagedFileStoreActions.SET_FILTERS}`, this.statusFilters);
    store.dispatch(`${StoreNames.StagedFile}/${StagedFileStoreActions.SET_PAGE}`, this.page);
    store.dispatch(`${StoreNames.StagedFile}/${StagedFileStoreActions.SET_RESULTS_PER_PAGE}`, this.perPage);
    store.dispatch(`${StoreNames.StagedFile}/${StagedFileStoreActions.SET_SEARCH_TERM}`, this.searchTerm);
  }

  get tableActions(): DataTableAction[] {
    const actions: DataTableAction[] = [
      {
        name: "View Log",
        icon: "fa-info-circle",
        action: "log",
      },
    ];

    if (this.userAccess.hasDocumentEditAccess) {
      const deleteAction: DataTableAction = {
        name: "Delete",
        icon: "fa-trash-alt",
        action: "delete",
      };

      actions.push(deleteAction);
    }

    return actions;
  }

  get tableBulkActions(): DataTableAction[] {
    const actions: DataTableAction[] = [];
    if (this.userAccess.hasDocumentEditAccess) {
      actions.push({
        name: "Convert to Site Documents",
        icon: "fa-pen",
        action: "convert",
      });
    }

    if (this.userAccess.hasDocumentApproveAccess) {
      actions.push({
        name: "Approve",
        icon: "fa-check",
        action: "approve",
      });

      actions.push({
        name: "Decline",
        icon: "fa-times",
        action: "decline",
      });
    }

    return actions;
  }

  created(): void {
    this.preSelectedFilters = store.getters[`${StoreNames.StagedFile}/${StagedFileStoreGetters.GET_FILTERS}`];
    this.page = store.getters[`${StoreNames.StagedFile}/${StagedFileStoreGetters.GET_PAGE}`];
    this.searchTerm = store.getters[`${StoreNames.StagedFile}/${StagedFileStoreGetters.GET_SEARCH_TERM}`];

    const user: UserState = this.authService.getUser();
    const authState = this.authService.getAuthState();
    if (authState != null) {
      this.userAccess = authState.access;
    }

    this.userDisplayName = user.displayName;
    this.actor = Object.assign(new Actor(), user.actor);
  }

  mounted(): void {
    this.perPage = store.getters[`${StoreNames.StagedFile}/${StagedFileStoreGetters.GET_RESULTS_PER_PAGE}`];
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/tabs.scss";

.filename {
  display: inline-block;
  max-width: 100%;
  text-indent: 0;
  vertical-align: bottom;

  &:visited {
    color: #005ea2;
  }
}

.info-icon {
  color: #005ea2;
}

.align-right {
  margin-left: auto;
}

.pagination {
  float: right;
}

.pagination-container {
  margin-top: 0.5rem;
}

.search-style {
  width: 50%;
  margin-top: 4px;
}

#filter-header {
  margin-top: 4px;
}
</style>
