<template>
  <basic-dialog
    id="select-hazards-dialog"
    size="xl"
    primaryButton="View Property Data"
    secondaryButton="Cancel"
    :primaryClick="confirm"
    :secondaryClick="cancel"
  >
    <div>
      <hazard-search-and-browse
        :isSingleSelection="false"
        :initialSelections="initialSelections"
        :showViewButton="false"
        :selectedHazardPropertyId="selectedHazardPropertyId"
        :selectedHazardType="selectedHazardType"
        @onTaxonomyTreeUpdated="updateTaxonomyTree"
        ref="searchAndBrowse"
      />

      <div v-show="isTaxonomyTreeEmpty">
        <p class="usa-prose text-bold">No data values available</p>
      </div>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import HazardSearchAndBrowse from "../HazardSearchAndBrowse.vue";
import { FilterNodeInformation } from "@/dataModel";
import { HazardType } from "@/constants/HazardType";

@Component({
  components: {
    BasicDialog,
    HazardSearchAndBrowse,
  },
})
export default class SelectHazardsDialog extends Vue {
  @Prop({ default: () => [] }) initialSelections!: string[];
  @Prop({ required: true }) selectedHazardPropertyId!: string;
  @Prop({ required: true }) readonly selectedHazardType!: HazardType;

  taxonomyTree: FilterNodeInformation[] | null = null;
  isTaxonomyTreeLoaded: boolean = false;

  get isTaxonomyTreeEmpty(): boolean {
    return this.taxonomyTree != null && this.isTaxonomyTreeLoaded && !this.taxonomyTree[0].value;
  }

  updateTaxonomyTree(taxonomyTree: FilterNodeInformation[]): void {
    this.taxonomyTree = taxonomyTree;
    this.$nextTick(() => {
      this.isTaxonomyTreeLoaded = true;
    });
  }

  confirm(): void {
    this.$emit("onUpdateHazards", (this.$refs.searchAndBrowse as HazardSearchAndBrowse).selectedHazards);
    this.$bvModal.hide("select-hazards-dialog");
  }

  cancel(): void {
    this.$bvModal.hide("select-hazards-dialog");
  }

  mounted(): void {
    this.intializeDialogOnOpen();
  }

  intializeDialogOnOpen(): void {
    this.$root.$on("bv::modal::show", () => {
      this.isTaxonomyTreeLoaded = false;
    });
  }
}
</script>
