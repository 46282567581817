<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <div class="desktop:grid-col">
          <h1 class="font-heading-xl text-primary-dark margin-top-0 margin-bottom-5">Data Collection Management</h1>
        </div>
        <div class="grid-col">
          <button class="usa-button float-right mr-0" @click="onCreateDataCollection">Create Data Collection</button>
        </div>
      </div>
      <div v-if="resultCount > 0" class="grid-row margin-bottom-3">
        <div v-if="!displaySearchTerm" class="usa-prose">
          Showing {{ startingResultCount }}-{{ endingResultCount }} of
          <span class="text-bold">{{ resultCount }} results</span>
        </div>
        <div v-else class="usa-prose">
          Showing {{ startingResultCount }}-{{ endingResultCount }} of
          <span class="text-bold">{{ resultCount }} results</span> for '{{ truncate(displaySearchTerm, 50) }}'
          <button class="usa-button--unstyled">
            <i class="fas fa-window-close" @click="clearSearchTerm"></i>
          </button>
        </div>
      </div>
      <h4 class="font-heading-md text-bold mb-2">Search for a data collection title</h4>
      <div class="grid-row">
        <div class="grid-col">
          <form class="usa-search usa-search--small search-style" role="search" @submit.prevent="throttleSearchMethod">
            <label class="usa-sr-only" for="search-field-en-small">Search</label>
            <input
              class="usa-input"
              id="search-field-en-small"
              type="search"
              name="search"
              v-model="searchTerm"
              placeholder="Search collections..."
            />
            <button class="usa-button" type="submit">
              <span class="usa-sr-only">Search</span>
            </button>
          </form>
        </div>
        <div class="grid-col-auto">
          <table-pagination
            class="float-right"
            @prev="prevPage"
            @next="nextPage"
            :page="page"
            :hasNextPage="hasNextPage"
          />
        </div>
      </div>
      <div class="grid-row mt-3">
        <div class="grid-col">
          <data-collection-table
            :searchParameters="searchParameters"
            :dataCollections="dataCollections"
            :statusCounts="statusCounts"
            :isLoadingData="isSearchLoading"
            @view-data-collection="viewDataCollectionDetails"
            @emit-sort-column="setSortColumn"
            @emit-sort-order="setSortOrder"
            @search="searchDataCollections"
          />
        </div>
      </div>
    </section>
    <error-toast id="error-loading-details" message="Unable to retrieve data collection details" />
    <success-toast id="collection-deleted" message="Data collection successfully deleted" />
  </main>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { BreadcrumbPathItem } from "@/dataModel/interfaces";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import TablePagination from "@/components/common/TablePagination.vue";
import DataCollectionTable from "./DataCollectionTable.vue";
import SearchSourcesRequest from "@/dataModel/hazardData/searchSourcesRequest";
import DataSource from "@/dataModel/hazardData/dataSource";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import HazardDataAdminHttpHelper from "@/components/resources/hazardDataAdminHttpHelper";
import DataValueStatusCounts from "@/dataModel/hazardData/dataValueStatusCounts";
import { AppConfig } from "@/dataModel";
import { truncate } from "@/services/utils";
import { throttle } from "lodash";

@Component({
  components: {
    Breadcrumb,
    TablePagination,
    DataCollectionTable,
  },
})
export default class DataCollectionManagementPage extends Vue {
  truncate = truncate;
  page: number = 1;
  resultsPerPage: number = 20;
  resultCount: number = 0;
  searchTerm: string = "";
  sortOrder: string = "ascending";
  sortColumn: string = "title";
  displaySearchTerm: string = "";
  isSearchLoading = true;

  searchParameters: SearchSourcesRequest = new SearchSourcesRequest();
  dataCollections: DataSource[] = [];
  statusCounts: DataValueStatusCounts[] = [];
  throttleSearchMethod: any;

  @Prop() errorLoadingDetailsPage!: string;
  @Prop() collectionDeleted!: string;

  private hazardDataAdminHttpHelper = container.get<HazardDataAdminHttpHelper>(serviceTypes.HazardDataAdminHttpHelper);
  private appConfig = container.get<AppConfig>(serviceTypes.AppConfig);

  path: BreadcrumbPathItem[] = [
    {
      text: "Administration",
    },
    {
      text: "Data Collection Management",
    },
  ];

  prevPage(): void {
    if (this.page > 1) {
      this.page--;
      this.throttleSearchMethod();
    }
  }

  nextPage(): void {
    if (this.hasNextPage) {
      this.page++;
      this.throttleSearchMethod();
    }
  }

  get hasNextPage(): boolean {
    return this.page * this.resultsPerPage < this.resultCount;
  }

  get startingResultCount(): number {
    return (this.page - 1) * this.resultsPerPage + 1;
  }

  get endingResultCount(): number {
    const endCount = this.page * this.resultsPerPage;
    if (endCount > this.resultCount) {
      return this.resultCount;
    } else {
      return endCount;
    }
  }

  viewDataCollectionDetails(id: string): void {
    this.$router.push({ name: "DataCollectionDetails", query: { id: id } });
  }

  onCreateDataCollection() {
    this.$router.push({
      name: "CreateNewDataCollection",
    });
  }

  clearSearchTerm(): void {
    this.searchTerm = "";
    this.searchDataCollections();
  }

  async searchDataCollections(): Promise<void> {
    let request: SearchSourcesRequest = new SearchSourcesRequest(
      this.searchTerm,
      this.sortOrder,
      this.page - 1,
      this.resultsPerPage,
      this.sortColumn,
    );
    this.searchParameters = request;

    try {
      this.isSearchLoading = true;
      const response = await this.hazardDataAdminHttpHelper.searchSources(request);
      this.dataCollections = response.result;
      this.resultCount = response.count;
      this.statusCounts = response.dataValueStatusCounts;

      this.displaySearchTerm = this.searchTerm;
    } finally {
      this.isSearchLoading = false;
    }
  }

  @Watch("displaySearchTerm")
  resetPage(): void {
    this.page = 1;
  }

  setSortColumn(sortColumn: string): void {
    this.sortColumn = sortColumn;
  }

  setSortOrder(sortOrder: string): void {
    this.sortOrder = sortOrder;
  }

  created(): void {
    this.throttleSearchMethod = throttle(async () => {
      await this.searchDataCollections();
    }, this.appConfig.searchRequestMinimumIntervalInMilliseconds);
    this.searchDataCollections();
  }

  mounted(): void {
    if (this.errorLoadingDetailsPage) {
      this.$bvToast.show("error-loading-details");
    }
    if (this.collectionDeleted) {
      this.$bvToast.show("collection-deleted");
    }
  }
}
</script>

<style scoped lang="scss"></style>
