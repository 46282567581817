import { AppConfig } from "@/dataModel";
import IAppConfigService from "./interfaces/IAppConfigService";

export default class AppConfigService implements IAppConfigService {
  private config: AppConfig | null;

  constructor() {
    this.config = null;
  }

  async getAppConfig(): Promise<AppConfig> {
    if (this.config != null) {
      return this.config;
    }

    let newConfig = new AppConfig();
    try {
      let origin = window.location.origin;
      if (origin.endsWith("/")) {
        origin = origin.substring(0, origin.lastIndexOf("/"));
      }
      const response = await fetch(origin + process.env.VUE_APP_CONFIG_ENDPOINT);
      const json = await response.json();
      newConfig.populateJson(json);

      this.config = newConfig;
      return this.config;
    } catch (error) {
      console.error("Error occurred while retrieving config.");
      throw error;
    }
  }
}
