<template>
  <div class="">
    <slot></slot>

    <div class="grid-row border-top padding-top-3">
      <div class="desktop:grid-col">
        <slot name="form-slot"></slot>
        <form class="usa-form maxw-full width-tablet" @submit.prevent>
          <div
            v-show="!formIsValid() && checkValidation"
            class="usa-alert usa-alert--error usa-alert--slim width-mobile-lg"
          >
            <div class="usa-alert__body">
              <p class="usa-alert__text" role="alert">Errors in form.</p>
            </div>
          </div>

          <!-- Document type -->
          <label class="usa-label usa-label--required" for="type">Document Type</label>
          <select
            class="usa-select width-mobile"
            name="type"
            id="type"
            v-model="type"
            aria-describedby="type-error-message"
          >
            <option value="">- Select -</option>
            <option v-for="type in documentTypes" :key="type.value" :value="type.value">
              {{ type.displayName }}
            </option>
          </select>
          <span class="usa-error-message" id="type-error-message" v-show="checkNullOrEmpty(type)">
            Type cannot be empty
          </span>

          <!-- file Classification -->
          <div v-if="!stagedFile">
            <label class="usa-label usa-label--required" for="classification">Classification</label>
            <select
              class="usa-select width-mobile"
              name="classification"
              id="classification"
              v-model="documentClassification"
              aria-describedby="classification-error-message"
            >
              <option :value="null">- Select -</option>
              <option v-for="classification in allClassifications" :key="classification.title" :value="classification">
                {{ classification.title }}
              </option>
            </select>
            <span
              class="usa-error-message"
              id="classification-error-message"
              v-show="checkNullOrEmpty(documentClassification)"
            >
              Classification cannot be empty
            </span>
          </div>

          <!-- file dis. controls -->
          <fieldset v-if="!stagedFile && allDisseminationControls.length > 0" class="usa-fieldset margin-top-3">
            <legend class="usa-label" for="disseminationControls">Dissemination Controls</legend>
            <div class="width-mobile-lg" id="disseminationControls" name="disseminationControls">
              <div
                class="usa-checkbox margin-left-2"
                v-for="disseminationControl in allDisseminationControls"
                :key="disseminationControl.id"
              >
                <input
                  class="usa-checkbox__input"
                  :id="'document_' + disseminationControl.id"
                  type="checkbox"
                  :value="disseminationControl"
                  v-model="documentDisseminationControls"
                />
                <label class="usa-checkbox__label" :for="'document_' + disseminationControl.id">{{
                  disseminationControl.title
                }}</label>
              </div>
            </div>
          </fieldset>

          <!-- file SCI -->
          <fieldset v-if="!stagedFile && allScis.length > 0" class="usa-fieldset margin-top-3">
            <legend class="usa-label" for="sci">Sensitive Compartmented Information</legend>
            <div class="width-mobile-lg" id="sci" name="sci">
              <div class="usa-checkbox margin-left-2" v-for="sci in allScis" :key="sci.id">
                <input
                  class="usa-checkbox__input"
                  :id="'document_' + sci.id"
                  type="checkbox"
                  :value="sci"
                  v-model="documentScis"
                />
                <label class="usa-checkbox__label" :for="'document_' + sci.id">{{ sci.title }}</label>
              </div>
            </div>
          </fieldset>

          <!-- distribution restrictions -->
          <fieldset v-if="allAudiences.length > 0" class="usa-fieldset margin-top-3">
            <legend class="usa-label">Distribution Restrictions</legend>
            <div class="width-mobile-lg">
              <div
                class="usa-checkbox distribution-checkbox margin-left-2"
                v-for="permission in allAudiences"
                :key="permission.id"
              >
                <input
                  class="usa-checkbox__input"
                  :id="permission.id"
                  type="checkbox"
                  :value="permission"
                  v-model="audiences"
                />
                <label class="usa-checkbox__label" :for="permission.id">{{ permission.fullName }}</label>
              </div>
            </div>
          </fieldset>

          <!-- title -->
          <label class="usa-label usa-label--required" for="title">Title</label>
          <input
            class="usa-input width-mobile-lg"
            id="title"
            name="title"
            type="text"
            v-model.trim="title"
            aria-describedby="title-error-message"
          />
          <span class="usa-error-message" id="title-error-message" v-show="checkNullOrEmpty(title)">
            Title cannot be empty
          </span>

          <!-- title classification -->
          <label class="usa-label usa-label--required" for="title-classification">Title Classification</label>
          <select
            class="usa-select width-mobile"
            name="title-classification"
            id="title-classification"
            v-model="titleClassification"
            aria-describedby="title-classification-error-message"
          >
            <option :value="null">- Select -</option>
            <option v-for="classification in allClassifications" :key="classification.title" :value="classification">
              {{ classification.title }}
            </option>
          </select>
          <span
            class="usa-error-message"
            id="title-classification-error-message"
            v-show="checkNullOrEmpty(titleClassification?.title)"
          >
            Title classification cannot be empty
          </span>

          <!-- title dis. controls -->
          <fieldset v-if="allDisseminationControls.length > 0" class="usa-fieldset margin-top-3">
            <legend class="usa-label" for="titleDisseminationControls">Title Dissemination Controls</legend>
            <div
              id="titleDisseminationControls"
              name="titleDisseminationControls"
              class="width-mobile-lg"
              role="group"
              aria-labelledby="titleDisseminationControls"
            >
              <div
                class="usa-checkbox margin-left-2"
                v-for="disseminationControl in allDisseminationControls"
                :key="disseminationControl.id"
              >
                <input
                  class="usa-checkbox__input"
                  :id="'title_' + disseminationControl.id"
                  type="checkbox"
                  :value="disseminationControl"
                  v-model="titleDisseminationControls"
                />
                <label class="usa-checkbox__label" :for="'title_' + disseminationControl.id">{{
                  disseminationControl.title
                }}</label>
              </div>
            </div>
          </fieldset>

          <!-- title sci -->
          <fieldset v-if="allScis.length > 0" class="usa-fieldset margin-top-3">
            <legend class="usa-label" for="titleSci">Title Sensitive Compartmented Information</legend>
            <div class="width-mobile-lg" id="titleSci" name="titleSci">
              <div class="usa-checkbox margin-left-2" v-for="sci in allScis" :key="sci.id">
                <input
                  class="usa-checkbox__input"
                  :id="'title_' + sci.id"
                  type="checkbox"
                  :value="sci"
                  v-model="titleScis"
                />
                <label class="usa-checkbox__label" :for="'title_' + sci.id">{{ sci.title }}</label>
              </div>
            </div>
          </fieldset>

          <!-- contributors -->
          <div class="grid-row">
            <div class="desktop:grid-col">
              <span v-for="n in contributorCount" :key="n">
                <label class="usa-label usa-label--required" :for="'contributor-' + n">Contributor {{ n }}</label>
                <div class="grid-row grid-gap-2">
                  <div class="desktop:grid-col-9">
                    <input
                      class="usa-input width-mobile-lg"
                      :id="'contributor-' + n"
                      :name="'contributor-' + n"
                      type="text"
                      v-model.trim="contributors[n - 1]"
                      aria-describedby="contributors-error-message"
                    />
                  </div>

                  <div v-if="n == 1" class="desktop:grid-col-3" id="contributor-button-holder">
                    <button class="usa-button usa-button--unstyled margin-top-2" @click="incrementContributorCount">
                      <img id="add-logo" src="../../../assets/img/add.svg" role="img" alt="Add" aria-hidden="true" />Add
                      Contributor
                    </button>
                  </div>
                  <div v-else class="desktop:grid-col-3">
                    <button
                      class="usa-button usa-button--unstyled margin-top-2"
                      @click="decrementContributorCount(n)"
                      :aria-label="'Remove contributor' + n"
                    >
                      <img
                        id="remove-logo"
                        src="../../../assets/img/close.svg"
                        role="img"
                        alt="Remove"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
              </span>
              <span class="usa-error-message" id="contributors-error-message" v-show="checkNullOrEmpty(contributors)">
                Contributors cannot be empty
              </span>
            </div>
          </div>

          <label class="usa-label" for="journal">Journal</label>
          <input class="usa-input width-mobile-lg" id="journal" name="journal" type="text" v-model.trim="journal" />

          <label class="usa-label" for="volume">Volume</label>
          <input class="usa-input width-mobile-lg" id="volume" name="volume" type="text" v-model.trim="volume" />

          <label class="usa-label" for="pages">Pages</label>
          <input class="usa-input width-mobile" id="pages" name="pages" type="text" v-model.trim="pages" />

          <div v-if="isNewCopyrightRestricted || isCopyrightRestricted">
            <label class="usa-label" for="location">Location</label>
            <input
              class="usa-input width-mobile-lg"
              id="location"
              name="location"
              type="text"
              v-model.trim="location"
            />
          </div>

          <!-- date section -->
          <label class="usa-label usa-label--required" for="classification">Date Precision</label>
          <select class="usa-select width-mobile" name="date-precision" id="date-precision" v-model="datePrecision">
            <option :value="undefined">None</option>
            <option :value="DatePrecision.Year">Year</option>
            <option :value="DatePrecision.Month">Month/Year</option>
            <option :value="DatePrecision.Day">Month/Day/Year</option>
          </select>

          <div class="usa-memorable-date">
            <div v-show="!isMonthInputDisabled" class="usa-form-group usa-form-group--month">
              <label class="usa-label usa-label--required" for="month">Month</label>
              <input
                class="usa-input usa-input--inline"
                id="month"
                name="month"
                type="text"
                maxlength="2"
                inputmode="numeric"
                placeholder="mm"
                v-model.trim="month"
                aria-describedby="date-error-message"
              />
            </div>
            <div v-show="!isDayInputDisabled" class="usa-form-group usa-form-group--day">
              <label class="usa-label usa-label--required" for="day">Day</label>
              <input
                class="usa-input usa-input--inline"
                id="day"
                name="day"
                type="text"
                maxlength="2"
                inputmode="numeric"
                placeholder="dd"
                v-model.trim="day"
              />
            </div>
            <div v-show="!isYearInputDisabled" class="usa-form-group usa-form-group--year">
              <label class="usa-label usa-label--required" for="year">Year</label>
              <input
                class="usa-input usa-input--inline"
                id="year"
                name="year"
                type="text"
                minlength="4"
                maxlength="4"
                inputmode="numeric"
                placeholder="yyyy"
                v-model.trim="year"
              />
            </div>
          </div>
          <span class="usa-error-message" id="date-error-message" v-show="isNotValidDate(day, month, year)">
            Must be a valid date
          </span>

          <label class="usa-label" for="doi" aria-label="D O I">DOI</label>
          <input class="usa-input width-mobile" id="doi" name="doi" type="text" v-model.trim="doi" />

          <fieldset class="usa-fieldset margin-top-3">
            <label class="usa-label usa-label--required">Site Publication Status</label>
            <div class="display-flex">
              <div class="usa-radio">
                <input
                  class="usa-radio__input"
                  id="status-published"
                  type="radio"
                  name="status-published"
                  :value="true"
                  v-model="isPublished"
                />
                <label class="usa-radio__label" for="status-published"> Published </label>
              </div>
              <div class="usa-radio margin-left-2em">
                <input
                  class="usa-radio__input"
                  id="status-unpublished"
                  type="radio"
                  name="status-unpublished"
                  :value="false"
                  v-model="isPublished"
                  aria-describedby="status-error-message"
                />
                <label class="usa-radio__label" for="status-unpublished"> Unpublished </label>
              </div>
            </div>
            <span class="usa-error-message" id="status-error-message" v-show="checkNullOrEmpty(isPublished)">
              Site publication status cannot be empty
            </span>
          </fieldset>

          <label class="usa-label" for="category-tree">Categories</label>
          <treeselect id="category-tree" v-model="categoryIds" :flat="true" :multiple="true" :options="categoryTree" />
          <div class="usa-alert usa-alert--warning usa-alert--slim margin-top-5" v-if="checkHazardTypePermissions()">
            <div class="usa-alert__body">
              <p class="usa-alert__text">
                The document will not be viewable once created since the current user does not have the permissions to
                view it.
              </p>
            </div>
          </div>
          <label class="usa-label" for="abstract"> Abstract </label>
          <textarea class="usa-textarea" id="abstract" name="abstract" v-model.trim="abstract"></textarea>

          <div v-if="!isNewCopyrightRestricted">
            <fieldset class="usa-fieldset margin-top-3">
              <label class="usa-label usa-label--required">Copyright Status</label>
              <div class="display-flex">
                <div class="usa-radio">
                  <input
                    class="usa-radio__input"
                    id="status-copyright-restricted"
                    type="radio"
                    name="copyright-status"
                    :value="true"
                    v-model="isCopyrightRestricted"
                    aria-describedby="copyright-error-message"
                  />
                  <label class="usa-radio__label" for="status-copyright-restricted"> Copyright Restricted </label>
                </div>
                <div class="usa-radio margin-left-2em">
                  <input
                    class="usa-radio__input"
                    id="status-not-copyright-restricted"
                    type="radio"
                    name="status-not-copyright-restricted"
                    :value="false"
                    v-model="isCopyrightRestricted"
                  />
                  <label class="usa-radio__label" for="status-not-copyright-restricted">
                    Not Copyright Restricted
                  </label>
                </div>
              </div>
              <span
                class="usa-error-message"
                id="copyright-error-message"
                v-show="checkNullOrEmpty(isCopyrightRestricted)"
              >
                Copyright status cannot be empty.
              </span>
            </fieldset>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import {
  SiteDocument,
  StagedFile,
  Category,
  Classification,
  DisseminationControl,
  Sci,
  KnowledgeResourceAudience,
  KnowledgeResource,
  TitlePermissions,
  Actor,
} from "@/dataModel";
import Treeselect from "@/components/common/CategoryTreeSelect.vue";
import moment from "moment";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import CategoryHttpHelper from "@/components/resources/categoryHttpHelper";
import DatePrecision from "@/constants/DatePrecision";
import IPermissionService from "@/services/interfaces/IPermissionService";
import { DocumentMixin } from "@/mixins";
import { isNullOrEmpty } from "@/services/utils";
import IAuthService from "@/services/interfaces/IAuthService";
import ICategoryService from "@/services/interfaces/ICategoryService";
import HazardTypeOption from "@/dataModel/hazardData/hazardTypeOption";
import { HazardType } from "@/constants/HazardType";

@Component({
  components: {
    Treeselect,
  },
})
export default class DocumentInputFields extends Mixins(DocumentMixin) {
  @Prop() readonly document: SiteDocument | undefined;
  @Prop() readonly stagedFile: StagedFile | undefined;
  @Prop() readonly isNewCopyrightRestricted!: boolean;

  allClassifications: Classification[] = [];
  allDisseminationControls: DisseminationControl[] = [];
  allScis: Sci[] = [];
  allAudiences: KnowledgeResourceAudience[] = [];
  DatePrecision = DatePrecision;
  checkValidation: boolean = false;
  contributorCount: number = 1;
  values: string[] = [];
  title: string = "";
  siteDocumentId: string = "";
  titleClassification: Classification | null = null;
  titleDisseminationControls: DisseminationControl[] = [];
  titleScis: Sci[] = [];
  contributors: string[] = [];
  type: string = "";
  journal: string | undefined = "";
  volume: string | undefined = "";
  pages: string = "";
  month: string = "";
  day: string = "";
  year: string = "";
  doi: string | undefined = "";
  documentClassification: Classification | null = null;
  documentDisseminationControls: DisseminationControl[] = [];
  documentScis: Sci[] = [];
  isPublished: boolean | null = null;
  isCopyrightRestricted: boolean | null = null;
  audiences: KnowledgeResourceAudience[] = [];
  abstract: string = "";
  categoryIds: string[] = [];
  url: string = "";
  location: string = "";
  datePrecision: DatePrecision | undefined = DatePrecision.Day;
  documentKnowledgeResource: KnowledgeResource | null = null;
  hazardTypeOptions: HazardTypeOption[] = [];
  categoryList: Category[] = [];
  categoryTree: Category[] = [];

  private categoryHttpHelper = container.get<CategoryHttpHelper>(serviceTypes.CategoryHttpHelper);
  private authService = container.get<IAuthService>(serviceTypes.AuthService);
  private permissionService = container.get<IPermissionService>(serviceTypes.PermissionService);
  private categoryService = container.get<ICategoryService>(serviceTypes.CategoryService);

  async getCategoriesAsync() {
    this.categoryList = await this.categoryHttpHelper.getAllCategories();
    this.categoryTree = this.categoryService.getCategoryTreeFromList(this.categoryList);
  }

  private getFilteredHazardTypeOptions(): void {
    const actor: Actor = Object.assign(new Actor(), this.authService.getUser().actor);
    this.hazardTypeOptions = actor.getAuthorizedHazardTypeOptions();
  }

  incrementContributorCount(): void {
    this.contributorCount++;
  }

  decrementContributorCount(index: number): void {
    this.contributorCount--;
    this.contributors.splice(index - 1, 1);
  }

  emitFields(): void {
    this.checkValidation = true;

    const titleClassifications = new TitlePermissions(
      this.titleClassification,
      this.titleDisseminationControls,
      this.titleScis,
    );
    const knowledgeResource = new KnowledgeResource(
      "",
      this.documentClassification ?? undefined,
      this.documentDisseminationControls,
      this.documentScis,
      this.audiences,
      titleClassifications,
    );

    if (this.formIsValid()) {
      const tempDocument = new SiteDocument(
        this.siteDocumentId,
        knowledgeResource,
        "", // Classification marking
        "", // Title classification marking
        this.isPublished!,
        this.type!,
        this.title,
        new Date(Date.now()),
        this.pages,
        this.isCopyrightRestricted!,
        this.categoryIds,
        this.doi,
        this.abstract,
        this.contributors,
        this.journal,
        this.createNewDate(this.day, this.month, this.year),
        this.volume,
        this.url,
        this.datePrecision,
        this.location,
        this.getCategoryPaths(),
        this.stagedFile?.knowledgeResource.id,
        this.stagedFile?.fileType,
        this.stagedFile?.fileName,
      );
      this.$emit("fields", tempDocument);
    } else {
      this.$emit("invalidForm");
      window.scrollTo(0, 0);
    }
  }

  formIsValid(): boolean {
    return (
      this.title.length > 0 &&
      this.titleClassification != null &&
      this.contributors.length > 0 &&
      this.type.length > 0 &&
      this.documentClassification != null &&
      this.isPublished != null &&
      ((this.isCopyrightRestricted != null && !this.isNewCopyrightRestricted) || this.isNewCopyrightRestricted) &&
      !this.isNotValidDate(this.day, this.month, this.year)
    );
  }

  createNewDate(day: string, month: string, year: string): Date | undefined {
    if (this.datePrecision == DatePrecision.Day) {
      const dateString = `${month}/${day}/${year}`;
      return moment(dateString, "MM/DD/YYYY").toDate();
    } else if (this.datePrecision == DatePrecision.Month) {
      const dateString = `${month}/${year}`;
      return moment(dateString, "MM/YYYY").toDate();
    } else if (this.datePrecision == DatePrecision.Year) {
      const dateString = `${year}`;
      return moment(dateString, "YYYY").toDate();
    } else {
      return undefined;
    }
  }

  checkNullOrEmpty(input: any): boolean {
    return isNullOrEmpty(input) && this.checkValidation;
  }

  isNotValidDate(day: string, month: string, year: string): boolean {
    if (this.datePrecision == DatePrecision.Day) {
      const dateString = `${month}/${day}/${year}`;
      const validDateNumber = moment(dateString, "MM/DD/YYYY").isValid();

      // mm/dd/yyyy or m/d/yyyy are valid
      return (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString) || !validDateNumber) && this.checkValidation;
    } else if (this.datePrecision == DatePrecision.Month) {
      const dateString = `${month}/${year}`;
      const validDateNumber = moment(dateString, "MM/YYYY").isValid();

      // mm/dd/yyyy or m/d/yyyy are valid
      return (!/^\d{1,2}\/\d{4}$/.test(dateString) || !validDateNumber) && this.checkValidation;
    } else if (this.datePrecision == DatePrecision.Year) {
      const dateString = `${year}`;
      const validDateNumber = moment(dateString, "YYYY").isValid();

      // mm/dd/yyyy or m/d/yyyy are valid
      return (!/^\d{4}$/.test(dateString) || !validDateNumber) && this.checkValidation;
    } else {
      return false;
    }
  }

  pageLessThanZero(page: string): boolean {
    if (this.checkNullOrEmpty(page)) {
      return false;
    }
    return Number(page) <= 0 && this.checkValidation;
  }

  pageIsNotNumber(page: string): boolean {
    const isNumberRegEx = /^-?\d+$/;
    if (this.checkNullOrEmpty(page)) {
      return false;
    }
    return !isNumberRegEx.test(page) && this.checkValidation;
  }

  getCategoryPaths(): string[][] {
    return (
      this.categoryIds?.map((id) =>
        this.categoryService.getCategoryPath(id, this.categoryList).map((p) => p.displayName),
      ) ?? []
    );
  }

  @Watch("contributors")
  contributorWatcher() {
    for (let i = 0; i < this.contributors.length; i++) {
      const contributor = this.contributors[i];
      if (contributor === "") {
        this.contributors.splice(i, 1);
      }
    }
  }

  initialize(categoryIds: string[]): void {
    this.siteDocumentId = this.document!.id;
    this.title = this.document!.title;
    this.titleClassification = this.documentKnowledgeResource?.titleClassifications.classification ?? null;
    this.titleDisseminationControls = this.documentKnowledgeResource?.titleClassifications.disseminationControls ?? [];
    this.titleScis = this.documentKnowledgeResource?.titleClassifications.scis ?? [];
    this.contributors = this.document!.contributors;
    this.contributorCount = isNullOrEmpty(this.document!.contributors) ? 1 : this.document!.contributors.length;
    this.type = this.document!.documentType;
    this.journal = this.document!.journalName;
    this.volume = this.document!.volume;
    this.pages = this.document!.pages;
    [this.month, this.day, this.year] = this.parseDate(this.document!.documentDateTime);
    this.doi = this.document!.doi;
    this.datePrecision = this.document!.documentDatePrecision ?? undefined;
    this.documentClassification = this.documentKnowledgeResource?.classification ?? null;
    this.documentDisseminationControls = this.documentKnowledgeResource?.disseminationControls ?? [];
    this.documentScis = this.documentKnowledgeResource?.scis ?? [];
    this.isPublished = this.document!.isPublished;
    this.categoryIds = categoryIds;
    this.audiences = this.documentKnowledgeResource?.audiences ?? [];
    this.abstract = this.document!.abstract;
    this.isCopyrightRestricted = this.document!.isCopyrightRestricted;
    this.location = this.document!.location ?? "";
    this.url = this.document!.url ?? "";
  }

  initializeFromStagedFile(): void {
    this.documentClassification = this.stagedFile!.knowledgeResource.classification;
    this.documentDisseminationControls = this.stagedFile!.knowledgeResource.disseminationControls;
    this.documentScis = this.stagedFile!.knowledgeResource.scis;
  }

  parseDate(date: Date | undefined): [month: string, day: string, year: string] {
    const mDate = moment(date);

    if (!mDate.isValid) {
      return ["", "", ""];
    }

    const utc = mDate.utc();
    return [utc.format("MM"), utc.format("DD"), utc.format("YYYY")];
  }

  checkHazardTypePermissions(): boolean {
    if (!this.categoryIds || !this.categoryIds.length || !this.categoryTree[0]) {
      return false;
    }

    const selectedHazardTypes: (keyof typeof HazardType)[] = [];
    const actorHazardTypeNames = this.hazardTypeOptions.map((option) => option.name);

    for (const hazardType in HazardType) {
      if (!Number.isNaN(Number(hazardType))) {
        // skip values, only process enum keys
        continue;
      }

      const castType = hazardType as keyof typeof HazardType;
      const sample = this.getSampleForHazardType(castType);
      if (this.sampleIncludesASelectedValue(sample)) {
        selectedHazardTypes.push(castType);
      }
    }

    return !selectedHazardTypes.some((typeName) => actorHazardTypeNames.includes(typeName));
  }

  sampleIncludesASelectedValue(sample: (Category | undefined)[]): boolean {
    while (sample.length > 0) {
      if (sample[0]) {
        // check all possible entries for a match
        for (let i = 0; i < this.categoryIds.length; i++) {
          if (sample[0]!.id === this.categoryIds[i]) {
            return true;
          }
        }
        if (sample[0]!.children != undefined) {
          for (let child = 0; child < sample[0]!.children.length; child++) {
            sample.push(sample[0]!.children[child]);
          }
        }
      }
      sample.splice(0, 1);
    }
    return false;
  }

  getSampleForHazardType(hazardType: keyof typeof HazardType): (Category | undefined)[] {
    const hazardTypeCategories = this.categoryTree[0].children?.find((option) => option.displayName === hazardType);
    const focusAreaCategories = this.categoryTree[1].children?.find((option) => option.displayName === hazardType);

    return [hazardTypeCategories, focusAreaCategories];
  }

  get isYearInputDisabled(): boolean {
    if (this.datePrecision == undefined) {
      this.year = "";
    }
    return this.datePrecision == undefined;
  }

  get isMonthInputDisabled(): boolean {
    if (this.datePrecision == undefined || this.datePrecision == DatePrecision.Year) {
      this.month = "";
    }
    return this.datePrecision == undefined || this.datePrecision == DatePrecision.Year;
  }

  get isDayInputDisabled(): boolean {
    if (
      this.datePrecision == undefined ||
      this.datePrecision == DatePrecision.Year ||
      this.datePrecision == DatePrecision.Month
    ) {
      this.day = "";
    }
    return (
      this.datePrecision == undefined ||
      this.datePrecision == DatePrecision.Year ||
      this.datePrecision == DatePrecision.Month
    );
  }

  async created(): Promise<void> {
    this.allClassifications = this.permissionService.getClassifications();
    this.allDisseminationControls = this.permissionService.getDisseminationControls();
    this.allScis = this.permissionService.getScis();
    this.allAudiences = this.permissionService.getAudiences();

    if (this.document) {
      if (this.document.categoryIds) {
        this.documentKnowledgeResource = this.document.knowledgeResource;
        this.initialize(this.document.categoryIds);
      } else {
        const response = await this.siteDocumentHttpHelper.getSiteDocument(this.document.id, false);
        const fullyDefinedDocument = response.document;
        this.documentKnowledgeResource = fullyDefinedDocument.knowledgeResource;
        this.initialize(fullyDefinedDocument.categoryIds);
      }
    } else if (this.stagedFile) {
      this.initializeFromStagedFile();
    }

    await this.getCategoriesAsync();
    this.getFilteredHazardTypeOptions();
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/vue-treeselect/modified-vue-treeselect.css";

.usa-form .usa-button {
  margin-top: 1rem;
}

.line-break {
  margin: 0;
  background: #c9c9c9;
}

.distribution-container {
  height: 150px;
  border: 1px solid #565c65;
  overflow-y: scroll;
}

.distribution-checkbox {
  margin-left: 1rem;
}

.usa-memorable-date input:disabled {
  border-color: rgba(118, 118, 118, 0.3);
}
</style>
