<template>
  <!-- This router view displays the Subscription Management child components -->
  <router-view></router-view>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class SubscriptionManagementBase extends Vue {}
</script>

<style lang="scss" scoped></style>
