<template>
  <div>
    <basic-dialog
      id="edit-user-dialog"
      title="Edit User"
      primaryButton="Save Changes"
      secondaryButton="Cancel"
      :primaryClick="editUser"
      :secondaryClick="cancel"
    >
      <form class="usa-form margin-bottom-2" @submit.prevent="editUser">
        <label class="usa-label" for="input-display-name">Display Name</label>
        <input class="usa-input" id="input-display-name" name="input-display-name" type="text" v-model="displayName" />
        <span class="usa-error-message" id="input-error-message" v-if="isEmpty(displayName)"
          >Display name cannot be empty</span
        >

        <label class="usa-label" for="input-firstname">First Name</label>
        <input
          class="usa-input"
          id="input-firstname"
          name="input-firstname"
          type="text"
          v-model="firstName"
          aria-describedby="firstname-error-message"
        />
        <span class="usa-error-message" id="firstname-error-message" v-if="isEmpty(firstName)"
          >First name cannot be empty</span
        >

        <label class="usa-label" for="input-lastname">Last Name</label>
        <input
          class="usa-input"
          id="input-lastname"
          name="input-lastname"
          type="text"
          v-model="lastName"
          aria-describedby="lastname-error-message"
        />
        <span class="usa-error-message" id="lastname-error-message" v-if="isEmpty(lastName)"
          >Last name cannot be empty</span
        >

        <label class="usa-label" for="input-email">Email</label>
        <input
          class="usa-input"
          id="input-email"
          name="input-email"
          type="text"
          v-model="email"
          aria-describedby="email-error-message valid-email-error-message"
        />
        <span class="usa-error-message" id="email-error-message" v-if="isEmpty(email)">Email cannot be empty</span>
        <span class="usa-error-message" id="valid-email-error-message" v-else-if="!isValidEmail(email)"
          >Must be a valid email</span
        >

        <label class="usa-label" for="input-organization">Organization</label>
        <input
          class="usa-input"
          id="input-organization"
          name="input-organization"
          type="text"
          v-model="organization"
          aria-describedby="org-error-message"
        />
        <span class="usa-error-message" id="org-error-message" v-if="isEmpty(organization)"
          >Organization cannot be empty</span
        >

        <fieldset class="usa-fieldset margin-top-2">
          <legend class="usa-legend usa-legend">Select Roles</legend>
          <role-selection
            :roles="roles"
            :selected="selectedRoles"
            @emitRoles="setSelectedRoles($event)"
            ref="roleSelection"
            aria-describedby="role-error-message"
          />
          <span class="usa-error-message" id="role-error-message" v-if="isValidRoleSelection(selectedRoles)"
            >At least 1 role must be selected</span
          >
        </fieldset>

        <fieldset class="usa-fieldset margin-top-2">
          <legend class="usa-legend usa-legend">Set Status</legend>
          <div class="usa-radio radio-btn">
            <input
              class="usa-radio__input"
              :disabled="!isApproved"
              id="status-active"
              type="radio"
              name="status"
              value="Active"
              v-model="userStatus"
            />
            <label class="usa-radio__label fit" for="status-active">Active</label>
          </div>
          <div class="usa-radio radio-btn">
            <input
              class="usa-radio__input"
              :disabled="!isApproved"
              id="status-inactive"
              type="radio"
              name="status"
              value="Inactive"
              v-model="userStatus"
            />
            <label class="usa-radio__label fit" for="status-inactive">Inactive</label>
          </div>
        </fieldset>
      </form>
    </basic-dialog>
    <success-toast id="user-edit-toast" message="The user has successfully been updated."></success-toast>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import RoleSelection from "@/components/navigation/UserManagement/RoleSelection.vue";
import Status from "@/constants/UserStatus";
import { User } from "@/dataModel";

@Component({
  components: {
    BasicDialog,
    RoleSelection,
  },
})
export default class EditUserDialog extends Vue {
  @Prop(Array) readonly roles: [] | undefined;
  @Prop() readonly existingUser!: any;

  email: string = "";
  firstName: string = "";
  lastName: string = "";
  organization: string = "";
  displayName: string = "";
  selectedRoles: string[] = [];
  userStatus: string = "";
  userId: string = "";
  userApprovalJustification: string = "";

  @Watch("existingUser")
  setup() {
    this.email = this.existingUser.email;
    this.firstName = this.existingUser.firstName;
    this.lastName = this.existingUser.lastName;
    this.organization = this.existingUser.organization;
    this.displayName = this.existingUser.displayName;
    this.selectedRoles = this.existingUser.actor.roles.map(function (role: any) {
      return role.id;
    });
    this.userId = this.existingUser.userId;
    this.userStatus = this.existingUser.userStatus;
    this.userApprovalJustification = this.existingUser.approvalJustification;
  }

  get isApproved() {
    return this.userStatus === Status.ACTIVE || this.userStatus === Status.INACTIVE;
  }

  isEmpty(input: string) {
    if (input == null) {
      return true;
    }
    return input.trim().length == 0;
  }

  isValidEmail(email: string) {
    return email.match(/\S+@\S+\.\S+/);
  }

  isValidRoleSelection(input: string[]) {
    return input.length == 0;
  }

  isValidForm() {
    return (
      this.displayName.trim().length > 0 &&
      this.email.trim().length > 0 &&
      this.isValidEmail(this.email.trim()) &&
      this.firstName.trim().length > 0 &&
      this.lastName.trim().length > 0 &&
      this.organization.trim().length > 0 &&
      this.selectedRoles.length > 0
    );
  }

  setSelectedRoles(roles: string[]) {
    this.selectedRoles = roles;
  }

  cancel() {
    this.$bvModal.hide("edit-user-dialog");
  }
  editUser() {
    if (this.isValidForm()) {
      const updatedUser = new User(
        this.userId,
        undefined,
        this.email,
        this.displayName,
        this.firstName,
        this.lastName,
        this.organization,
        this.userStatus,
        this.selectedRoles,
        undefined,
        this.userApprovalJustification,
      );
      this.$emit("confirm", updatedUser);
      this.$bvModal.hide("edit-user-dialog");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.usa-form {
  width: 100%;
  max-width: 100%;
}

.radio-btn {
  float: left;
  width: 33%;
}

.btn {
  width: 46%;
  margin-bottom: 0;
}

.fit {
  width: fit-content;
}
</style>
