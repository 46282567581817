<template>
    <div>
        <button class="arrow" @click="prevPage" :aria-disabled="!hasPrevPage" aria-label="previous page">
            <i class="fa fa-angle-left fa-lg pagination-icon text-primary-dark" :class="{disable: !hasPrevPage}"/>
        </button>
        <span class="font-ui-md page-number">{{page}}</span>
        <button class="arrow" @click="nextPage" :aria-disabled="!hasNextPage" aria-label="next page">
            <i class="fa fa-angle-right fa-lg pagination-icon text-primary-dark" :class="{disable: !hasNextPage}"/>
        </button>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class TablePagination extends Vue {
    @Prop({ default: () => 1 }) page!: number;
    @Prop() hasNextPage!: boolean;

    get hasPrevPage() {
        return this.page > 1;
    }
    prevPage() {
        this.$emit("prev");
    }

    nextPage() {
        this.$emit("next");
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.arrow {
    background: transparent;
    border: 0;
}

.page-number {
    font-weight: bold;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.disable {
    color: #a9aeb1;
}
</style>
