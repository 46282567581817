<template>
  <nav aria-label="Table Filters">
    <ul class="usa-sidenav">
      <li v-for="(filter, index) in filters" :key="filter.identifierKey" class="usa-sidenav__item">
        <a
          v-b-toggle
          @click.prevent
          :href="`#collapse-${filter.identifierKey}`"
          class="usa-current filter"
          :aria-label="filter.displayValue + ' filter'"
        >
          {{ filter.displayValue }}
        </a>
        <b-collapse :visible="!collapseAll && index === 0" :id="`collapse-${filter.identifierKey}`">
          <ul class="usa-sidenav__sublist">
            <li v-for="filterItem in filter.filters" :key="filterItem.id">
              <div class="usa-checkbox">
                <input
                  class="usa-checkbox__input"
                  :id="filterItem.id"
                  type="checkbox"
                  :name="filterItem.displayValue"
                  :value="filterItem"
                  v-model="selectedFilters"
                />
                <label
                  v-if="filterItem.identifierType == DataValueIdentifierType.Source"
                  class="usa-checkbox__label margin-left-105em"
                  :for="filterItem.id"
                >
                  <span
                    @click="viewDataSource($event, filterItem.metadata.SiteDocumentId)"
                    class="filter-link usa-link pointer"
                    >{{ truncate(filterItem.displayValue, 190) }} ({{ filterItem.dataValueCount }})</span
                  >
                  <div class="margin-top-1 padding-left-4 text-bold">
                    <span v-if="filterItem.metadata.DocumentDateTime">
                      ({{ getYear(filterItem.metadata.DocumentDateTime) }})
                    </span>
                    {{ filterItem.metadata.ContributorDisplay }}
                  </div>
                </label>

                <label v-else class="usa-checkbox__label margin-left-105em" :for="filterItem.id">
                  {{ filterItem.displayValue }} ({{ filterItem.dataValueCount }})
                </label>
              </div>
            </li>
          </ul>
        </b-collapse>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DataValueFilterParent from "@/dataModel/hazardData/dataValueFilterParent";
import DataValueFilter from "@/dataModel/hazardData/dataValueFilter";
import { DataValueIdentifierType } from "@/constants/DataValueIdentifierType";
import { truncate } from "@/services/utils";

@Component
export default class DataValueFilters extends Vue {
  truncate = truncate;
  selectedFilters: DataValueFilter[] = [];
  DataValueIdentifierType = DataValueIdentifierType;

  @Prop({ required: true }) filters!: DataValueFilterParent[];
  @Prop({ default: () => [] }) preSelectedFilters!: DataValueFilter[];
  @Prop() collapseAll!: boolean;
  @Prop({ default: false }) showCounts!: boolean;

  @Watch("selectedFilters")
  filterWatcher(): void {
    this.$emit("filtersChanged", this.selectedFilters);
  }

  viewDataSource(event: Event, sourceId: string): void {
    event.stopPropagation();
    event.preventDefault();
    this.$emit("viewDataSource", sourceId);
  }

  getYear(date: string): number {
    return new Date(date).getFullYear();
  }

  created(): void {
    this.selectedFilters = this.preSelectedFilters;
  }
}
</script>

<style scoped lang="scss">
.filter {
  text-transform: capitalize;
}

.usa-sidenav .usa-sidenav__sublist a {
  text-decoration: underline !important;
}

.filter-link {
  color: #005ea2;
}

.filter-link:hover {
  color: #1a4480;
}

.usa-checkbox__label .filter-link {
  text-indent: 0;
  padding-left: 0;
}
</style>
