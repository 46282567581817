import { Classification, DisseminationControl, KnowledgeResourceAudience, Role, Sci } from "./";
import HazardTypeOption from "./hazardData/hazardTypeOption";
import { HazardType } from "@/constants/HazardType";

export class Actor {
  id: string;
  clearanceLevel: Classification;
  readOnlyRoles: Role[];
  readOnlyDisseminationControls: DisseminationControl[];
  readOnlyAudiences: KnowledgeResourceAudience[];
  scis: Sci[];
  private getDistinctCategoryAndPermissions: { [key: string]: any[] };

  constructor(
    id?: string,
    clearanceLevel?: Classification,
    roles?: Role[],
    readOnlyDisseminationControls?: DisseminationControl[],
    audiences?: KnowledgeResourceAudience[],
    scis?: Sci[],
    getDistinctCategoryAndPermissions?: { [key: string]: any[] },
  ) {
    this.id = id ?? "";
    this.readOnlyRoles = roles ?? [];
    this.clearanceLevel = clearanceLevel ?? new Classification();
    this.readOnlyDisseminationControls = readOnlyDisseminationControls ?? [];
    this.readOnlyAudiences = audiences ?? [];
    this.scis = scis ?? [];
    this.getDistinctCategoryAndPermissions = getDistinctCategoryAndPermissions ?? {};
  }

  getKnowledgeResourceAccessPermissionIds(): string[] {
    return [this.clearanceLevel.id].concat(
      this.readOnlyDisseminationControls.map((c) => c.id),
      this.readOnlyAudiences.map((a) => a.id),
      this.scis.map((s) => s.id),
    );
  }

  getAuthorizedHazardTypeOptions(): HazardTypeOption[] {
    const authorizedHazardTypes: HazardTypeOption[] = [];
    const hazardTypePermissionCategoryId = "56bdce3c-d251-4f4c-a9e9-1a2e47e6f328";
    const hazardTypePermissionCategory = this.getDistinctCategoryAndPermissions[hazardTypePermissionCategoryId]!;

    for (let i = 0; i < hazardTypePermissionCategory.length; i++) {
      const hazardTypeName: string = hazardTypePermissionCategory[i].name;
      authorizedHazardTypes.push(
        new HazardTypeOption(HazardType[hazardTypeName], hazardTypeName, HazardType[hazardTypeName]),
      );
    }

    return authorizedHazardTypes;
  }
}
