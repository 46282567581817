import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import { SettingState } from "@/typings/store/states/SettingStoreState";

const state: SettingState = {
  AI_Setting: null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
