<template>
  <nav class="usa-breadcrumb" aria-label="Breadcrumbs">
    <ol class="usa-breadcrumb__list">
      <li
        v-for="(step, index) in path"
        :key="step.text"
        :class="`usa-breadcrumb__list-item ${isLastItem(index) ? 'usa-current' : ''}`"
        :aria-current="`${isLastItem(index) ? 'page' : ''}`"
      >
        <template v-if="isLastItem(index) || step.url == null">
          <span>{{ step.text }}</span>
        </template>
        <template v-else>
          <router-link :to="step.url" class="usa-breadcrumb__link">
            <span>{{ step.text }}</span>
          </router-link>
        </template>
      </li>
    </ol>
  </nav>
</template>

<script lang="ts">
import { BreadcrumbPathItem } from "@/dataModel/interfaces";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Breadcrumb extends Vue {
  @Prop({ required: true }) path!: BreadcrumbPathItem[];

  isLastItem(index: number): boolean {
    return index === this.path.length - 1;
  }
}
</script>

<style scoped lang="scss"></style>
