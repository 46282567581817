import { HazardType } from "@/constants/HazardType";
import { DataViewSearchParameters } from "@/dataModel/interfaces";

export class FeaturedHazardDataView {
  pooledFeaturedDataViewId?: string;
  name: string;
  description: string;
  searchParameters: DataViewSearchParameters | undefined;
  hazardType: HazardType;

  constructor(
    pooledFeaturedDataViewId: string,
    name: string,
    description: string | undefined,
    searchParameters: DataViewSearchParameters,
    hazardType: HazardType,
  ) {
    this.pooledFeaturedDataViewId = pooledFeaturedDataViewId ?? "";
    this.name = name ?? "";
    this.description = description ?? "";
    this.searchParameters = searchParameters;
    this.hazardType = hazardType;
  }
}
