export default class SearchSourcesRequest {
  searchTerm: string;
  sortOrder: string;
  page: number;
  resultsPerPage: number;
  sortColumn: string;
  constructor(
    searchTerm?: string,
    sortOrder?: string,
    page?: number,
    resultsPerPage?: number,
    sortColumn?: string
  ) {
    this.searchTerm = searchTerm ?? "";
    this.sortOrder = sortOrder ?? "";
    this.page = page ?? 0;
    this.resultsPerPage = resultsPerPage ?? 0;
    this.sortColumn = sortColumn ?? "";

  }
}