<template>
  <div>
    <div class="grid-row mb-3">
      <div class="grid-col">
        <applied-site-document-filters
          :searchFilters="appliedSearchFilters"
          :shownAppliedFilters="appliedSearchFilters"
          @emit-remove-selection="removeAppliedFilter"
          @clear-filters="clearAppliedFilters"
        />
      </div>
    </div>

    <div class="grid-row grid-gap">
      <div class="grid-col-3">
        <table-filters
          @emit-search-filters="setSearchFilters"
          @search="searchDocuments"
          @update-applied-filters="updateAppliedFilters"
          ref="tableFilters"
        />
      </div>
      <div class="grid-col-9">
        <div class="grid-row grid-gap">
          <div class="grid-col-7">
            <form
              class="usa-search usa-search--small search-style"
              role="search"
              @submit.prevent="throttleSearchMethod"
              v-bind:class="{ 'search-focused': isSearchBoxFocused }"
            >
              <label class="usa-sr-only" for="search-field-en-small">Search</label>
              <input
                class="usa-input"
                id="search-field-en-small"
                type="search"
                name="search"
                v-model="searchTerm"
                placeholder="Search documents..."
              />
              <button class="usa-button" type="submit">
                <span class="usa-sr-only">Search</span>
              </button>
            </form>
          </div>

          <results-per-page-button
            class="align-right"
            :perPage="resultsPerPage"
            @resultsPerPageChanged="perPageAmountChanged"
          />
          <div class="grid-col-auto pagination-container">
            <table-pagination
              class="pagination"
              @prev="prevPage"
              @next="nextPage"
              :page="page"
              :hasNextPage="hasNextPage"
            />
          </div>
        </div>
        <div class="grid-row">
          <div class="grid-col">
            <site-documents-table
              :searchParameters="currentSearchParameters"
              :documents="siteDocuments"
              @emit-sort-column="setSortColumn"
              @emit-sort-order="setSortOrder"
              @search="searchDocuments"
              @add-featured-document="addFeaturedDocument"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Emit } from "vue-property-decorator";
import SiteDocumentsTable from "./SiteDocumentsTable.vue";
import DataTableFilter from "@/components/common/DataTableFilter.vue";
import ResultsPerPageButton from "@/components/common/ResultsPerPageButton.vue";
import TablePagination from "@/components/common/TablePagination.vue";
import TableFilters from "./SiteDocumentTableFilters.vue";
import { SearchResponse } from "@/dataModel/responses";
import container from "@/dependencyInjection/config";
import SiteDocumentAdminHttpHelper from "@/components/resources/siteDocumentAdminHttpHelper";
import serviceTypes from "@/dependencyInjection/types";
import { SearchSiteDocumentsRequest } from "@/dataModel/requests";
import AppliedSiteDocumentFilters from "./AppliedSiteDocumentFilters.vue";
import { FilterNodeInformation, SiteDocument, AppConfig } from "@/dataModel";
import { throttle } from "lodash";

@Component({
  components: {
    DataTableFilter,
    ResultsPerPageButton,
    TablePagination,
    SiteDocumentsTable,
    TableFilters,
    AppliedSiteDocumentFilters,
  },
})
export default class SiteDocumentsSection extends Vue {
  searchTerm: string = "";
  isSearchBoxFocused: boolean = false;
  currentSearchParameters: SearchSiteDocumentsRequest = new SearchSiteDocumentsRequest();
  siteDocuments: SiteDocument[] = [];
  resultCount: number = 0;
  resultsPerPage: number = 20;
  page: number = 1;
  appliedSearchFilters: FilterNodeInformation[] = [];
  throttleSearchMethod: any;

  private siteDocumentAdminHttpHelper = container.get<SiteDocumentAdminHttpHelper>(
    serviceTypes.SiteDocumentAdminHttpHelper,
  );
  private appConfig = container.get<AppConfig>(serviceTypes.AppConfig);

  async searchDocuments(): Promise<void> {
    const response: SearchResponse<SiteDocument> = await this.siteDocumentAdminHttpHelper.searchSiteDocuments(
      this.page - 1,
      this.resultsPerPage,
      this.searchTerm,
      this.currentSearchParameters.sortOrder,
      this.currentSearchParameters.sortColumn,
      this.currentSearchParameters.classificationFilters,
      this.currentSearchParameters.disseminationControlFilters,
      this.currentSearchParameters.categoryFilters,
      this.currentSearchParameters.documentTypeFilters,
      this.currentSearchParameters.isCopyrightRestrictedFilters,
      this.currentSearchParameters.audienceFilters,
      this.currentSearchParameters.sciFilters,
      this.currentSearchParameters.isPublishedFilters,
    );
    this.siteDocuments = response.result;
    this.resultCount = response.count;
  }

  setSortColumn(sortColumn: string): void {
    this.currentSearchParameters.sortColumn = sortColumn;
  }

  setSortOrder(sortOrder: string): void {
    this.currentSearchParameters.sortOrder = sortOrder;
  }

  setSearchFilters(searchFilters: SearchSiteDocumentsRequest): void {
    this.currentSearchParameters.classificationFilters = searchFilters.classificationFilters;
    this.currentSearchParameters.disseminationControlFilters = searchFilters.disseminationControlFilters;
    this.currentSearchParameters.categoryFilters = searchFilters.categoryFilters;
    this.currentSearchParameters.documentTypeFilters = searchFilters.documentTypeFilters;
    this.currentSearchParameters.isCopyrightRestrictedFilters = searchFilters.isCopyrightRestrictedFilters;
    this.currentSearchParameters.audienceFilters = searchFilters.audienceFilters;
    this.currentSearchParameters.sciFilters = searchFilters.sciFilters;
    this.currentSearchParameters.isPublishedFilters = searchFilters.isPublishedFilters;

    if (this.page != -1) {
      this.page = 1;
    }
  }

  prevPage(): void {
    if (this.page > 1) {
      this.page--;
      this.throttleSearchMethod();
    }
  }

  nextPage(): void {
    if (this.hasNextPage) {
      this.page++;
      this.throttleSearchMethod();
    }
  }

  get hasNextPage(): boolean {
    return this.page * this.resultsPerPage < this.resultCount;
  }

  perPageAmountChanged(val: number): void {
    this.resultsPerPage = val;
    this.page = 1;
    this.throttleSearchMethod();
  }

  updateAppliedFilters(filters: FilterNodeInformation[]): void {
    this.appliedSearchFilters = filters;
  }

  removeAppliedFilter(filter: FilterNodeInformation): void {
    (<TableFilters>this.$refs.tableFilters).removeSelection(filter);
  }

  clearAppliedFilters(): void {
    (<TableFilters>this.$refs.tableFilters).clearFilters();
  }

  @Emit()
  addFeaturedDocument(document: SiteDocument): SiteDocument {
    return document;
  }

  async mounted(): Promise<void> {
    this.throttleSearchMethod = throttle(async () => {
      await this.searchDocuments();
    }, this.appConfig.searchRequestMinimumIntervalInMilliseconds);
    await this.searchDocuments();
  }
}
</script>

<style scoped lang="scss">
.align-right {
  margin-left: auto;
}

.pagination {
  float: right;
}

.search-style {
  width: 100%;
  margin-top: 4px;
}
</style>
