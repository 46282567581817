<template>
  <main id="main-content">
    <div id="section-background" class="bg-primary-darker">
      <img id="hkma-dhs-background-logo" src="../../../assets/img/dhs-logo.svg" role="img" alt="" aria-hidden="true" />
      <section class="grid-container usa-section">
        <div class="grid-row grid-gap">
          <div id="welcome" class="desktop:grid-col-6">
            <span id="welcome-to">Welcome to</span>
            <h1 id="hkma-name">Hazard Knowledge Management & Analysis</h1>
          </div>

          <div class="desktop:grid-col-6 redirect-wrapper margin-top-8">
            <div class="redirect-wrapper">
              <div class="float-left">
                <h2 id="subtitle">Redirecting Now...</h2>
              </div>
              <div class="float-right">
                <spinner class="spinner-sm margin-left-3" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { User as UserState } from "@/typings/store/states/AuthStoreState";
import Spinner from "@/components/common/Spinner.vue";
import IAuthService from "@/services/interfaces/IAuthService";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import IPermissionService from "@/services/interfaces/IPermissionService";
import UserStatus from "@/constants/UserStatus";

@Component({
  components: {
    Spinner,
  },
})
export default class CallbackPage extends Vue {
  private auth = container.get<IAuthService>(serviceTypes.AuthService);
  private permissionService = container.get<IPermissionService>(serviceTypes.PermissionService);

  mounted() {
    this.auth.completeAuthentication().then(
      async (userState: UserState) => {
        const status = userState.userStatus;
        if (status === UserStatus.LOGIN_CREATED || status === UserStatus.ACCESS_REQUESTED) {
          this.$router.push("/request-access");
        } else if (status === UserStatus.INACTIVE || status === UserStatus.DENIED_ACCESS) {
          this.$router.push("/access-denied");
        } else {
          await this.permissionService.populateKnowledgeResourcePermissions();
          this.$router.push("/home");
        }
      },
      (error) => {
        console.error(error);
        this.$router.push("/login-error");
      },
    );
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./src/styles/login-flow.scss";

.redirect-wrapper {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

#section-background {
  min-height: 32.5em;
}
</style>
