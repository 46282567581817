<template>
  <div>
    <basic-dialog
      id="confirm-status-dialog"
      :title="title"
      primaryButton="Confirm"
      secondaryButton="Cancel"
      :primaryClick="confirm"
      :secondaryClick="cancel"
      :isDisabled="errors.length > 0 || selectedUserStatuses.length == 0"
    >
      <div class="usa-alert usa-alert--info usa-alert--slim margin-top-5" v-if="selectedUserStatuses.length == 0">
        <div class="usa-alert__body">
          <p class="usa-alert__text">At least 1 user must be selected from the table.</p>
        </div>
      </div>
      <div class="usa-alert usa-alert--error usa-alert--slim margin-top-1" v-for="error in errors" :key="error">
        <div class="usa-alert__body">
          <p class="usa-alert__text">{{ error }}</p>
        </div>
      </div>
      <div class="usa-alert usa-alert--warning usa-alert--slim margin-top-1" v-for="warn in warnings" :key="warn">
        <div class="usa-alert__body">
          <p class="usa-alert__text">{{ warn }}</p>
        </div>
      </div>
    </basic-dialog>
    <success-toast id="confirm-status-toast" message=""></success-toast>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import Status from "@/constants/UserStatus";

@Component({
  components: {
    BasicDialog,
  },
})
export default class BulkEditDialog extends Vue {
  @Prop(Number) readonly statusAction!: 0;
  @Prop(Array) readonly selectedUserStatuses!: string[];
  @Prop() readonly StatusActions!: any;

  get title() {
    switch (this.statusAction) {
      case this.StatusActions.ACTIVATE:
        return "Confirm Activation";
      case this.StatusActions.DEACTIVATE:
        return "Confirm Deactivation";
      case this.StatusActions.APPROVE:
        return "Confirm Approval";
      case this.StatusActions.DENY:
        return "Confirm Denial";
    }
  }

  get errors() {
    const errors: string[] = [];
    switch (this.statusAction) {
      case this.StatusActions.ACTIVATE:
        if (this.selectedUserStatuses.includes(Status.LOGIN_CREATED)) {
          errors.push("Cannot activate users who have not requested access. User must request access first.");
        }
        if (this.selectedUserStatuses.includes(Status.ACCESS_REQUESTED)) {
          errors.push("Cannot activate users who are requesting access. User must be approved first.");
        }
        if (this.selectedUserStatuses.includes(Status.DENIED_ACCESS)) {
          errors.push("Cannot activate users who are denied access.");
        }
        if (this.selectedUserStatuses.includes(Status.ACTIVE)) {
          errors.push("Cannot activate users who are already active.");
        }
        break;
      case this.StatusActions.DEACTIVATE:
        if (this.selectedUserStatuses.includes(Status.LOGIN_CREATED)) {
          errors.push("Cannot deactivate users who have not requested access. User must request access first.");
        }
        if (this.selectedUserStatuses.includes(Status.ACCESS_REQUESTED)) {
          errors.push("Cannot deactivate users who are requesting access. User must be approved first.");
        }
        if (this.selectedUserStatuses.includes(Status.DENIED_ACCESS)) {
          errors.push("Cannot deactivate users who are denied access.");
        }
        if (this.selectedUserStatuses.includes(Status.INACTIVE)) {
          errors.push("Cannot deactivate users who are already inactive.");
        }
        break;
      case this.StatusActions.APPROVE:
        if (this.selectedUserStatuses.includes(Status.LOGIN_CREATED)) {
          errors.push("Cannot approve users who have not requested access. User must request access first.");
        }
        if (this.selectedUserStatuses.includes(Status.ACTIVE)) {
          errors.push("Cannot approve users who are already active.");
        }
        if (this.selectedUserStatuses.includes(Status.INACTIVE)) {
          errors.push("Cannot approve users who are already inactive.");
        }
        break;
      case this.StatusActions.DENY:
        if (this.selectedUserStatuses.includes(Status.LOGIN_CREATED)) {
          errors.push("Cannot deny users who have not requested access. User must request access first.");
        }
        if (this.selectedUserStatuses.includes(Status.ACTIVE)) {
          errors.push("Cannot deny users who are already active.");
        }
        if (this.selectedUserStatuses.includes(Status.INACTIVE)) {
          errors.push("Cannot deny users who are already inactive.");
        }
        break;
    }
    return errors;
  }

  get warnings() {
    const warnings: string[] = [];
    switch (this.statusAction) {
      case this.StatusActions.ACTIVATE:
      case this.StatusActions.DEACTIVATE:
      case this.StatusActions.DENY:
        break;
      case this.StatusActions.APPROVE:
        if (this.selectedUserStatuses.includes(Status.DENIED_ACCESS)) {
          warnings.push("Some selected users have been denied access and will now be approved.");
        }
        break;
    }
    return warnings;
  }

  cancel() {
    this.$bvModal.hide("confirm-status-dialog");
  }
  confirm() {
    if (this.errors.length == 0) {
      this.$emit("confirm", this.statusAction);
      this.$bvModal.hide("confirm-status-dialog");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.usa-form {
  width: 100%;
  max-width: 100%;
}
</style>
