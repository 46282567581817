<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <div class="desktop:grid-col-11">
          <h1 class="font-heading-xl text-primary-dark margin-top-0">{{ request.title }}</h1>
        </div>
        <div class="desktop:grid-col-1 margin-left-auto requestButtonDiv">
          <button class="usa-button usa-button--outline" @click="returnToRequests" id="back-to-requests-button">
            Back
          </button>
        </div>
      </div>

      <hr class="margin-bottom-5" />

      <div class="request-container">
        <h3 class="font-heading-lg text-primary-dark text-bold">Request Log</h3>
        <div
          v-for="(entry, index) of log"
          :key="index"
          :class="`grid-row border-top padding-y-1 font-body-xs ${index === log.length - 1 ? 'border-bottom' : ''}`"
        >
          <div class="grid-col-3 text-bold margin-left-2">
            {{ addSpaces(entry.logAction) }}
          </div>
          <div class="grid-col-3">
            <i class="fa fa-clock text-base"></i>
            {{ formatDate(entry.logDateTime) }}
          </div>
          <div class="grid-col hide-overflow margin-right-2">{{ entry.displayName }}</div>
          <div v-if="canDeleteComment(entry)" class="grid-col-auto">
            <button
              class="usa-button usa-button--unstyled delete-button margin-right-2"
              @click="deleteCommentHandler(entry)"
            >
              Delete
            </button>
          </div>
          <div class="grid-col-12 note padding-top-1 margin-left-2" v-if="entry.comment">
            {{ entry.comment }}
          </div>
        </div>

        <div class="grid-row margin-top-105em" v-if="request.requestStatus == RequestStatus.Open">
          <form class="usa-form maxw-full" @submit.prevent="addCommentHandler">
            <label class="usa-label" for="comment"> Comment </label>
            <textarea
              class="usa-textarea"
              cols="95"
              id="comment"
              name="comment"
              placeholder="Enter a comment to be appended to the log"
              v-model.trim="comment"
              aria-describedby="documents-error-message"
            ></textarea>
            <span class="usa-error-message" id="documents-error-message" v-if="!formIsValid() && showValidation">
              Comment cannot be empty
            </span>
            <button class="usa-button" id="add-comment-button">Add Comment</button>
          </form>
        </div>
      </div>

      <delete-comment-dialog @confirm="deleteComment" />
      <success-toast id="comment-added-toast" message="Comment successfully added." />
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Request, RequestEventLog } from "@/dataModel";
import RequestStatus from "@/constants/RequestStatus";
import RequestLogAction from "@/constants/RequestLogAction";
import RequestHttpHelper from "@/components/resources/requestHttpHelper";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import store from "@/store";
import { RequestStoreGetters } from "@/constants/store/request/requestStoreConstants";
import StoreNames from "@/constants/store/StoreNames";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import { BreadcrumbPathItem } from "@/dataModel/interfaces";
import DeleteCommentDialog from "@/components/navigation/UserRequests/DeleteCommentDialog.vue";
import moment from "moment";
import AuthService from "@/services/AuthService";

@Component({
  components: {
    Breadcrumb,
    DeleteCommentDialog,
  },
})
export default class UserRequestsLogPage extends Vue {
  comment = "";
  showValidation = false;
  log: RequestEventLog[] = [];
  deleteLog!: RequestEventLog;
  request!: Request;
  userId!: string;
  actorId!: string;
  userHasRequestAdminAccess = false;
  RequestStatus = RequestStatus;

  private requestHttpHelper = container.get<RequestHttpHelper>(serviceTypes.RequestHttpHelper);
  private authService = container.get<AuthService>(serviceTypes.AuthService);

  path: BreadcrumbPathItem[] = [
    {
      text: "My Content",
    },
    {
      text: "My Requests",
      url: "/user-requests",
    },
    {
      text: "Request Log",
    },
  ];

  returnToRequests(): void {
    this.$router.push("/user-requests");
  }

  formatDate(date: Date): string {
    return moment(date).format("MM-DD-YYYY HH:mm:ss");
  }

  addSpaces(str: string): string {
    return str.replace(/([A-Z])/g, " $1");
  }

  async getLogsForRequest(): Promise<void> {
    const response = await this.requestHttpHelper.getRequest(this.request.id);
    this.log = response.events;
  }

  deleteCommentHandler(eventLog: RequestEventLog): void {
    this.$bvModal.show("delete-comment-dialog");
    this.deleteLog = eventLog;
  }

  async deleteComment(): Promise<void> {
    await this.requestHttpHelper.deleteCommentFromRequestLog(this.request.id, this.deleteLog.id);
    await this.getLogsForRequest();
    this.$bvToast.show("comment-deleted-toast");
  }

  canDeleteComment(entry: RequestEventLog): boolean {
    const belongsToUser = this.actorId === entry.actorId;

    return entry.logAction === RequestLogAction.CommentAdded && belongsToUser && this.userHasRequestAdminAccess;
  }

  formIsValid(): boolean {
    // max length of comment?
    return this.comment.length > 0;
  }
  addCommentHandler(): void {
    if (this.comment.length > 0) {
      this.addComment(this.comment);
      this.resetForm();
    } else {
      this.showValidation = true;
    }
  }

  async addComment(text: string): Promise<void> {
    await this.requestHttpHelper.addCommentToRequestLog(this.request.id, text);
    this.$bvToast.show("comment-added-toast");
    await this.getLogsForRequest();
  }

  resetForm(): void {
    this.comment = "";
    this.showValidation = false;
  }

  created(): void {
    this.request = store.getters[`${StoreNames.Request}/${RequestStoreGetters.GET_SELECTED_REQUEST}`];

    const { user, access } = this.authService.getAuthState() ?? {};
    this.userId = user?.userId ?? "";
    this.actorId = user?.actor?.id ?? "";
    this.userHasRequestAdminAccess = access?.hasAdminUserRequestAccess ?? false;

    this.getLogsForRequest();
  }
}
</script>

<style scoped lang="scss">
.comment {
  overflow-wrap: break-word;
  word-break: break-word;
}

.request-container {
  width: 75%;
}
</style>
