import { HazardDataStatus } from "@/constants/HazardDataStatus";
import { HazardType } from "@/constants/HazardType";
import DataValueCategoryRequest from "./DataValueCategoryRequest";
import DataValueDetailRequest from "./DataValueDetailRequest";
import DataValueRequest from "./dataValueRequest";

export default class CreateDataValueRequest extends DataValueRequest {
  sourceId: string;

  constructor(
    sourceId?: string,
    propertyId?: string,
    hazardId?: string,
    scientificUnitId?: string,
    evaluationRatingId?: string,
    uncertaintyMeasureId?: string,
    uncertaintyValueOne?: number | null,
    uncertaintyValueTwo?: number | null,
    containsAssumptions?: boolean,
    value?: string,
    siValue?: string,
    status?: HazardDataStatus,
    categoryValueRequests?: DataValueCategoryRequest[],
    detailValueRequests?: DataValueDetailRequest[],
    hazardTypeId?: HazardType,
  ) {
    super(
      propertyId,
      hazardId,
      scientificUnitId,
      evaluationRatingId,
      uncertaintyMeasureId,
      uncertaintyValueOne,
      uncertaintyValueTwo,
      containsAssumptions,
      value,
      siValue,
      status,
      categoryValueRequests,
      detailValueRequests,
      hazardTypeId,
    );
    this.sourceId = sourceId ?? "";
  }
}
