<template>
  <div>
    <basic-dialog
      id="confirm-status-dialog"
      :title="title"
      primaryButton="Confirm"
      secondaryButton="Cancel"
      :primaryClick="confirm"
      :secondaryClick="cancel"
      :isDisabled="selectedDocumentCount == 0 || containsDocumentWithStatus"
    >
      <div class="usa-alert usa-alert--info usa-alert--slim margin-top-5" v-if="selectedDocumentCount == 0">
        <div class="usa-alert__body">
          <p class="usa-alert__text">At least 1 document must be selected from the table.</p>
        </div>
      </div>

      <div class="usa-alert usa-alert--info usa-alert--slim margin-top-5" v-if="containsDocumentWithStatus">
        <div class="usa-alert__body">
          <p class="usa-alert__text">Cannot {{ statusString }} document(s) that are already {{ statusString }}ed.</p>
        </div>
      </div>
    </basic-dialog>
    <success-toast id="confirm-status-toast" message="Statuses have successfully been updated."></success-toast>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";

@Component({
  components: {
    BasicDialog,
  },
})
export default class ConfirmStatusChangeDialog extends Vue {
  @Prop(Boolean) readonly isPublished!: boolean;
  @Prop(Boolean) readonly containsDocumentWithStatus!: boolean;
  @Prop(Number) readonly selectedDocumentCount!: number;

  get title(): string {
    if (this.isPublished) {
      return "Confirm Publish";
    } else {
      return "Confirm Unpublish";
    }
  }

  get statusString(): string {
    if (this.isPublished) {
      return "publish";
    } else {
      return "unpublish";
    }
  }

  cancel(): void {
    this.$bvModal.hide("confirm-status-dialog");
  }
  confirm(): void {
    this.$emit("confirm", this.isPublished);
    this.$bvModal.hide("confirm-status-dialog");
  }
}
</script>

<style scoped lang="scss"></style>
