<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <div class="desktop:grid-col">
          <h2 class="font-heading-xl text-primary-dark">Explore Hazard Data</h2>
        </div>
      </div>

      <div class="grid-row margin-top-2">
        <div class="grid-col">
          <p>
            The HKMA is home to an ever-growing amount of hazard research data. This data has been created using several
            documents within the system. If you have suggestions on data that could be added, please
            <router-link :to="{ name: 'UserRequests' }" class="usa-link">create a request</router-link>. Begin exploring
            data using one of the methods given below.
          </p>
        </div>
      </div>

      <div class="grid-row margin-top-3">
        <div class="grid-col">
          <ul class="usa-card-group">
            <li class="grid-col usa-card usa-card--flag">
              <explore-card
                @click="goToPage('ExploreByHazards')"
                header="by Hazards"
                :img="require('@/assets/img/icons/BiologicalAgent.svg')"
                body-text="View research data related to a specific hazard,
                such as Bacillus anthracis, from multiple documents"
                button-text="Explore Hazards"
              />
            </li>

            <li class="grid-col usa-card usa-card--flag">
              <explore-card
                @click="goToPage('ExploreByProperties')"
                header="by Properties"
                :img="require('@/assets/img/icons/Properties.svg')"
                body-text="View research data associated with a single property,
                such as LD50, for one or more hazards"
                button-text="Explore Properties"
              />
            </li>
          </ul>
        </div>
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import { BreadcrumbPathItem } from "@/dataModel/interfaces";
import ExploreCard from "@/components/common/ExploreCard.vue";

@Component({
  components: {
    Breadcrumb,
    ExploreCard,
  },
})
export default class ExploreHazardDataPage extends Vue {
  path: BreadcrumbPathItem[] = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Explore Hazard Data",
    },
  ];

  goToPage(name: string): void {
    this.$router.push({ name });
  }
}
</script>

<style lang="scss">
.heading-icon {
  width: 4.5rem;
}

.center-y {
  display: flex;
  align-items: center;
}

.line-break {
  margin-bottom: 3rem;
}
</style>
