<template>
  <basic-dialog
    :id="dialogId"
    title="Add To Featured Data"
    primaryButton="Add"
    secondaryButton="Cancel"
    :primaryClick="onAddData"
    :secondaryClick="cancel"
    :isDisabled="hasFormError || hasParameterError"
  >
    <p>
      Please provide some additional information to add the selected parameters to the featured data pool. The full pool
      can be viewed on the
      <router-link class="usa-link" :to="{ name: 'FeaturedResourceManagement' }"
        >Featured Resource Management page</router-link
      >.
    </p>

    <div class="usa-prose margin-top-3">
      <!-- hazard(s) -->
      <template v-if="selectedHazards.length === 1">
        <p><span class="text-bold">Hazard:</span> {{ selectedHazards[0].displayName }}</p>
      </template>
      <template v-else-if="selectedHazards.length">
        <p class="text-bold">Hazards:</p>
        <ul class="margin-left-4">
          <li v-for="hazard in selectedHazards" :key="hazard.id">{{ hazard.displayName }}</li>
        </ul>
      </template>

      <!-- property -->
      <p v-if="selectedProperty"><span class="text-bold">Property:</span> {{ selectedProperty }}</p>

      <!-- filters -->
      <template v-if="selectedFilters.length">
        <p class="text-bold">Filters:</p>
        <ul class="margin-left-4">
          <li v-for="filter in selectedFilters" :key="filter.id">
            {{ filter.displayValue }}
          </li>
        </ul>
      </template>

      <!-- column options -->
      <template v-if="selectedDataOptions.length">
        <p class="text-bold">Table Columns:</p>
        <ol class="margin-left-4">
          <li v-for="option in selectedDataOptions" :key="option.displayName">{{ option.displayName }}</li>
        </ol>
      </template>
    </div>

    <fieldset class="padding-x-0 margin-top-05">
      <div class="usa-form-group">
        <label class="usa-label" for="name">Name</label>
        <input
          class="usa-input"
          id="name"
          name="name"
          aria-describedby="name-error-message"
          :disabled="hasParameterError"
          v-model="name"
        />
        <span v-show="hasFormError" class="usa-error-message" id="name-error-message" role="alert"
          >Name cannot be empty</span
        >
      </div>

      <div class="usa-form-group">
        <label class="usa-label" for="description">Description (optional)</label>
        <input
          class="usa-input"
          id="description"
          name="description"
          :disabled="hasParameterError"
          v-model="description"
        />
      </div>
    </fieldset>

    <div class="usa-alert usa-alert--info usa-alert--slim" v-if="hasParameterError">
      <div class="usa-alert__body">
        <p class="usa-alert__text">{{ parameterError }}</p>
      </div>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import { Category } from "@/dataModel";
import DataValueOption from "@/dataModel/hazardData/dataValueOption";
import { isNullOrEmpty } from "@/services/utils";
import DataValueFilter from "@/dataModel/hazardData/dataValueFilter";
import DataValueFilterParent from "@/dataModel/hazardData/dataValueFilterParent";

@Component({
  components: {
    BasicDialog,
  },
})
export default class FeaturedDataDialog extends Vue {
  @Prop({ required: true }) selectedHazards!: Category[];
  @Prop({ default: "" }) selectedProperty!: string;
  @Prop({ default: () => [] }) selectedFilters!: DataValueFilter[];
  @Prop({ default: () => [] }) filterParents!: DataValueFilterParent[];
  @Prop({ required: true })
  selectedDataOptions!: DataValueOption[];

  dialogId = "featured-data-dialog";
  submitClicked = false;

  name = "";
  description = "";

  get hasFormError(): boolean {
    return this.submitClicked ? isNullOrEmpty(this.name) : false;
  }

  get hasParameterError(): boolean {
    return !isNullOrEmpty(this.parameterError);
  }

  get parameterError(): string {
    if (isNullOrEmpty(this.selectedHazards)) {
      return "A hazard must be selected";
    }
    if (isNullOrEmpty(this.selectedDataOptions)) {
      return "At least one table column must be selected";
    }

    return "";
  }

  onAddData(): void {
    this.submitClicked = true;
    if (this.hasParameterError || this.hasFormError) {
      return;
    }
    this.$emit("on-add-data", { name: this.name, description: this.description });
    this.$bvModal.hide(this.dialogId);
  }

  cancel(): void {
    this.$bvModal.hide(this.dialogId);
  }

  resetDialog(): void {
    this.name = "";
    this.description = "";
    this.submitClicked = false;
  }

  /** gets called once after the page using the FeaturedDataDialog is loaded */
  mounted(): void {
    this.$root.$on("bv::modal::show", (_, modalId: string) => {
      if (modalId === this.dialogId) {
        this.resetDialog();
      }
    });
  }
}
</script>
