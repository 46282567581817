import { DocumentManagementStoreMutations } from "@/constants/store/siteDocumentManagement/documentManagementStoreConstants";
import { DocumentManagementStoreState } from "@/typings/store/states/SiteDocumentManagementStoreState";
import { FilterNodeInformation } from "@/dataModel";

export default {
  [DocumentManagementStoreMutations.SET_SEARCH_TERM](state: DocumentManagementStoreState, searchTerm: string) {
    state.searchTerm = searchTerm;
  },
  [DocumentManagementStoreMutations.SET_PAGE](state: DocumentManagementStoreState, page: number) {
    state.page = page;
  },
  [DocumentManagementStoreMutations.SET_RESULTS_PER_PAGE](state: DocumentManagementStoreState, resultsPerPage: number) {
    state.resultsPerPage = resultsPerPage;
  },
  [DocumentManagementStoreMutations.SET_FILTERS](
    state: DocumentManagementStoreState,
    filters: FilterNodeInformation[],
  ) {
    state.filters = filters;
  },
};
