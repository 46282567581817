import { HazardDataStatus } from "@/constants/HazardDataStatus";
import { HazardType } from "@/constants/HazardType";
import DataValueCategoryRequest from "./DataValueCategoryRequest";
import DataValueDetailRequest from "./DataValueDetailRequest";
import DataValueRequest from "./dataValueRequest";

export default class UpdateDataValueRequest extends DataValueRequest {
  hazardDataValueId: string;
  isPublished: boolean;

  constructor(
    hazardDataValueId?: string,
    isPublished?: boolean,
    propertyId?: string,
    hazardId?: string,
    scientificUnitId?: string,
    evaluationRatingId?: string,
    uncertaintyMeasureId?: string,
    uncertainyValueOne?: number | null,
    uncertainyValueTwo?: number | null,
    containsAssumptions?: boolean,
    value?: string,
    siValue?: string,
    status?: HazardDataStatus,
    categoryValueRequests?: DataValueCategoryRequest[],
    detailValueRequests?: DataValueDetailRequest[],
    hazardTypeId?: HazardType,
  ) {
    super(
      propertyId,
      hazardId,
      scientificUnitId,
      evaluationRatingId,
      uncertaintyMeasureId,
      uncertainyValueOne,
      uncertainyValueTwo,
      containsAssumptions,
      value,
      siValue,
      status,
      categoryValueRequests,
      detailValueRequests,
      hazardTypeId,
    );
    this.hazardDataValueId = hazardDataValueId ?? "";
    this.isPublished = isPublished ?? false;
  }
}
