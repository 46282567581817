<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <div class="desktop:grid-col">
          <h1 class="font-heading-xl text-primary-dark margin-top-0 margin-bottom-5">
            {{ headingText }}
          </h1>
        </div>
      </div>

      <div class="grid-row">
        <div class="grid-col">
          <h3 class="font-heading-lg text-primary-dark mt-0 mb-2">Hazard Information</h3>
          <p v-if="selectedHazardTypeId == null || isNullOrEmpty(selectedHazardPropertyId)" class="usa-prose mb-0">
            Select a Hazard Type, then select a Hazard Property to display the corresponding new data value fields.
          </p>
        </div>
      </div>

      <div class="grid-row">
        <div class="grid-col-4">
          <data-value-field
            label="Hazard Type"
            :options="hazardTypeOptions"
            :hasSubmitted="hasSubmitted"
            :loadValue="selectedHazardTypeId"
            @onInputChange="onInputChange(Object.keys({ selectedHazardTypeId })[0], $event)"
          />
        </div>
      </div>

      <div v-if="selectedHazardTypeId != null" class="grid-row">
        <div class="grid-col-4">
          <data-value-field
            label="Hazard Property"
            :options="hazardPropertyOptions"
            :hasSubmitted="hasSubmitted"
            :loadValue="selectedHazardPropertyId"
            @onInputChange="onInputChange(Object.keys({ selectedHazardPropertyId })[0], $event)"
          />
        </div>
      </div>

      <div v-if="selectedHazardTypeId != null && !isNullOrEmpty(selectedHazardPropertyId)">
        <div class="grid-row">
          <div class="grid-col-4">
            <label class="usa-label">Hazard</label>
            <treeselect
              id="category-tree"
              v-model="selectedHazardId"
              :multiple="false"
              :options="hazardCategories"
              placeholder="Select a hazard..."
              :disableRootNodes="false"
              :disable-branch-nodes="true"
              :clearable="false"
            />
            <span
              class="usa-error-message"
              id="required-error-message-category-tree"
              v-show="hasSubmitted && isNullOrEmpty(selectedHazardId)"
            >
              This field is required
            </span>
          </div>
        </div>

        <h3 class="font-heading-lg text-primary-dark mt-5 mb-0">Data Values</h3>
        <div class="grid-row">
          <div class="grid-col-4 mr-4">
            <data-value-field
              label="Value"
              :hasSubmitted="hasSubmitted"
              :loadValue="value"
              @onInputChange="onInputChange(Object.keys({ value })[0], $event)"
            />
          </div>
          <div class="grid-col-4">
            <data-value-field
              class="scientific-unit-input"
              label="Scientific Unit"
              :options="scientificUnitOptions"
              :hasSubmitted="hasSubmitted"
              :loadValue="selectedUnitId"
              @onInputChange="onInputChange(Object.keys({ selectedUnitId })[0], $event)"
            />
          </div>
        </div>
        <div class="grid-row">
          <div class="grid-col-4 mr-4">
            <data-value-field
              label="Source Evaluation"
              :options="evaluationRatingOptions"
              :hasSubmitted="hasSubmitted"
              :loadValue="selectedEvaluationRatingId"
              additionalInfoButtonText="Evaluation Criteria"
              @infoClicked="showEvaluationCriteriaDialog"
              @onInputChange="onInputChange(Object.keys({ selectedEvaluationRatingId })[0], $event)"
            />
          </div>
          <div class="grid-col-4">
            <data-value-field
              label="Uncertainty Measure"
              :options="uncertaintyMeasureOptions"
              :hasSubmitted="hasSubmitted"
              :loadValue="selectedUncertaintyMeasureId"
              :isRequired="false"
              @onInputChange="onInputChange(Object.keys({ selectedUncertaintyMeasureId })[0], $event)"
            />
          </div>
        </div>
        <div class="grid-row">
          <div class="grid-col-4 mr-4">
            <data-value-field
              label="Uncertainty Value 1"
              :hasSubmitted="hasSubmitted"
              :loadValue="uncertaintyValue1"
              :isRequired="false"
              inputFieldType="number"
              @onInputChange="onInputChange(Object.keys({ uncertaintyValue1 })[0], $event)"
            />
          </div>
          <div class="grid-col-4">
            <data-value-field
              label="Uncertainty Value 2"
              :hasSubmitted="hasSubmitted"
              :loadValue="uncertaintyValue2"
              :isRequired="false"
              inputFieldType="number"
              @onInputChange="onInputChange(Object.keys({ uncertaintyValue2 })[0], $event)"
            />
          </div>
        </div>

        <div class="mt-4" v-show="loadingPropertySpecifications">
          <spinner />
        </div>

        <div v-if="hasPropertySpecifications && !loadingPropertySpecifications" class="grid-row">
          <div class="grid-col-4">
            <h3 class="font-heading-lg text-primary-dark mt-5 mb-0">Production Information</h3>
            <div v-for="detail in hazardPropertySpecifications.details" :key="detail.id">
              <data-value-field
                :label="detail.name"
                :hasSubmitted="hasSubmitted"
                :isRequired="detail.isRequired"
                :loadValue="loadDetailValue(detail)"
                :inputFieldType="getDetailInputFieldType(detail.valueType)"
                @onInputChange="onDetailSpecificationInputChange(detail, $event)"
              />
            </div>
            <div v-for="category in hazardPropertySpecifications.categories" :key="category.id">
              <data-value-field
                :label="category.displayName"
                :options="category.categories"
                :hasSubmitted="hasSubmitted"
                :isCategoryDetail="true"
                :isRequired="category.isRequired"
                :loadValue="loadCategoryValue(category)"
                @onInputChange="onCategorySpecificationInputChange(category, $event)"
              />
            </div>
          </div>
        </div>

        <div class="grid-row grid-gap mt-5">
          <div class="grid-col">
            <button class="usa-button" @click="saveDataValue(false)">Save</button>
            <button v-if="showSaveAndApproveButton" class="usa-button" @click="saveDataValue(true)">
              Save & Approve
            </button>
            <button class="usa-button usa-button--outline" @click="cancel">Cancel</button>
          </div>
        </div>
      </div>
    </section>
    <evaluation-criteria-dialog :evaluationRatings="evaluationRatingOptions" />
  </main>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import store from "@/store";
import StoreNames from "@/constants/store/StoreNames";
import { DataCollectionStoreGetters } from "@/constants/store/dataCollection/dataCollectionStoreConstants";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import HazardDataPropertyHttpHelper from "@/components/resources/hazardDataPropertyHttpHelper";
import HazardDataEvaluationRatingHttpHelper from "@/components/resources/hazardDataEvaluationRatingHttpHelper";
import HazardDataUnitsHttpHelper from "@/components/resources/hazardDataUnitsHttpHelper";
import HazardDataUncertaintyMeasureHttpHelper from "@/components/resources/hazardDataUncertaintyMeasureHttpHelper";
import { HazardType } from "@/constants/HazardType";
import HazardPropertyDetails from "@/dataModel/hazardData/hazardPropertyDetails";
import DataSource from "@/dataModel/hazardData/dataSource";
import { BreadcrumbPathItem } from "@/dataModel/interfaces";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import UncertaintyMeasure from "@/dataModel/hazardData/uncertaintyMeasure";
import HazardUnit from "@/dataModel/hazardData/hazardUnit";
import EvaluationRating from "@/dataModel/hazardData/evaluationRating";
import HazardPropertySpecifications from "@/dataModel/hazardData/hazardPropertySpecifications";
import DataValueField from "./DataValueField.vue";
import HazardDataValue from "@/dataModel/hazardData/hazardDataValue";
import Spinner from "@/components/common/Spinner.vue";
import HazardCategoryDetails from "@/dataModel/hazardData/hazardCategoryDetails";
import EvaluationCriteriaDialog from "./EvaluationCriteriaDialog.vue";
import CategoryHttpHelper from "@/components/resources/categoryHttpHelper";
import Treeselect from "@/components/common/CategoryTreeSelect.vue";
import HazardDataAdminHttpHelper from "@/components/resources/hazardDataAdminHttpHelper";
import { isNullOrEmpty } from "@/services/utils";
import HazardDetail from "@/dataModel/hazardData/details/hazardDetail";
import DataValueCategoryRequest from "@/dataModel/hazardData/DataValueCategoryRequest";
import DataValueDetailRequest from "@/dataModel/hazardData/DataValueDetailRequest";
import UpdateDataValueRequest from "@/dataModel/hazardData/updateDataValueRequest";
import CreateDataValueRequest from "@/dataModel/hazardData/createDataValueRequest";
import HazardTypeOption from "@/dataModel/hazardData/hazardTypeOption";
import { HazardDataStatus } from "@/constants/HazardDataStatus";
import { DetailValueType } from "@/constants/DetailValueType";
import { FilterNodeInformation } from "@/dataModel";
import CategoryService from "@/services/CategoryService";

@Component({
  components: {
    Breadcrumb,
    DataValueField,
    Spinner,
    EvaluationCriteriaDialog,
    Treeselect,
  },
})
export default class AddEditDataValue extends Vue {
  selectedHazardTypeId: number | null = null;
  selectedHazardPropertyId: string = "";
  selectedHazardId?: string | null = null;
  selectedUncertaintyMeasureId: string = "";
  selectedUnitId: string = "";
  selectedEvaluationRatingId: string = "";
  value: string = "";
  uncertaintyValue1: string = "";
  uncertaintyValue2: string = "";
  categorySpecifications: DataValueCategoryRequest[] = [];
  detailSpecifications: DataValueDetailRequest[] = [];
  hazardPropertySpecifications: HazardPropertySpecifications = new HazardPropertySpecifications();
  dataCollection: DataSource = new DataSource();
  existingDataValue: HazardDataValue | null = null;
  hasSubmitted: boolean = false;
  loadingPropertySpecifications: boolean = false;
  initializingDataValueFields: boolean = false;

  hazardTypeOptions: HazardTypeOption[] = [];
  hazardPropertyOptions: HazardPropertyDetails[] = [];
  scientificUnitOptions: HazardUnit[] = [];
  uncertaintyMeasureOptions: UncertaintyMeasure[] = [];
  evaluationRatingOptions: EvaluationRating[] = [];
  hazardCategories: FilterNodeInformation[] = [];
  allHazardCategories: FilterNodeInformation[] = [];

  @Prop() readonly isNewValueFromMetadata: boolean | undefined;

  private categoryService = container.get<CategoryService>(serviceTypes.CategoryService);
  private hazardDataAdminHttpHelper = container.get<HazardDataAdminHttpHelper>(serviceTypes.HazardDataAdminHttpHelper);
  private hazardDataPropertyHttpHelper = container.get<HazardDataPropertyHttpHelper>(
    serviceTypes.HazardDataPropertyHttpHelper,
  );
  private hazardDataEvaluationRatingHttpHelper = container.get<HazardDataEvaluationRatingHttpHelper>(
    serviceTypes.HazardDataEvaluationRatingHttpHelper,
  );
  private hazardDataUncertaintyMeasureHttpHelper = container.get<HazardDataUncertaintyMeasureHttpHelper>(
    serviceTypes.HazardDataUncertaintyMeasureHttpHelper,
  );
  private hazardDataUnitsHttpHelper = container.get<HazardDataUnitsHttpHelper>(serviceTypes.HazardDataUnitsHttpHelper);
  private categoryHttpHelper = container.get<CategoryHttpHelper>(serviceTypes.CategoryHttpHelper);

  path: BreadcrumbPathItem[] = [
    {
      text: "Administration",
    },
    {
      text: "Data Collection Management",
      url: "/data-collection-management",
    },
  ];

  async saveDataValue(approve: boolean): Promise<void> {
    this.hasSubmitted = true;

    if (this.isFormValid()) {
      if (this.isEditing) {
        await this.updateDataValue(approve);
      } else {
        await this.createDataValue(approve);
      }
      this.viewDataCollectionDetails(this.dataCollection.id);
      return;
    }
  }

  async updateDataValue(approve: boolean): Promise<void> {
    const hazardDataStatus = approve ? HazardDataStatus.Approved : this.existingDataValue?.status;
    const request = new UpdateDataValueRequest(
      this.existingDataValue?.id,
      this.existingDataValue?.isPublished,
      this.selectedHazardPropertyId,
      this.selectedHazardId!,
      this.selectedUnitId,
      this.selectedEvaluationRatingId,
      this.selectedUncertaintyMeasureId,
      this.parseUncertaintyValue(this.uncertaintyValue1),
      this.parseUncertaintyValue(this.uncertaintyValue2),
      false,
      this.value,
      "",
      hazardDataStatus,
      this.categorySpecifications,
      this.detailSpecifications,
      this.selectedHazardTypeId!,
    );
    await this.hazardDataAdminHttpHelper.updateDataValue(request);
  }

  async createDataValue(approve: boolean): Promise<void> {
    const hazardDataStatus = approve ? HazardDataStatus.Approved : HazardDataStatus.AwaitingApproval;
    const request = new CreateDataValueRequest(
      this.dataCollection.id,
      this.selectedHazardPropertyId,
      this.selectedHazardId!,
      this.selectedUnitId,
      this.selectedEvaluationRatingId,
      this.selectedUncertaintyMeasureId,
      this.parseUncertaintyValue(this.uncertaintyValue1),
      this.parseUncertaintyValue(this.uncertaintyValue2),
      false,
      this.value,
      "",
      hazardDataStatus,
      this.categorySpecifications,
      this.detailSpecifications,
      this.selectedHazardTypeId!,
    );
    await this.hazardDataAdminHttpHelper.createDataValue(request);
  }

  isFormValid(): boolean {
    return (
      !isNullOrEmpty(this.selectedHazardPropertyId) &&
      !isNullOrEmpty(this.selectedHazardId!) &&
      !isNullOrEmpty(this.selectedUnitId) &&
      !isNullOrEmpty(this.selectedEvaluationRatingId) &&
      !isNullOrEmpty(this.value) &&
      !this.isRequiredHazardDetailsNullOrEmpty() &&
      !this.isRequiredHazardCategoriesNullOrEmpty() &&
      this.selectedHazardTypeId != null
    );
  }

  parseUncertaintyValue(uncertaintyValue: string): number | null {
    if (isNullOrEmpty(uncertaintyValue)) {
      return null;
    }
    return parseFloat(uncertaintyValue);
  }

  isRequiredHazardDetailsNullOrEmpty(): boolean {
    let selectedDetailIds = this.detailSpecifications.map((ds) => ds.hazardDetailId);
    let requiredDetails = this.hazardPropertySpecifications.details.filter((d) => d.isRequired);
    return !requiredDetails.every((requiredDetail) => selectedDetailIds.includes(requiredDetail.id));
  }

  isRequiredHazardCategoriesNullOrEmpty(): boolean {
    let selectedCategoryIds = this.categorySpecifications.map((c) => c.parentId);
    let requiredCategories = this.hazardPropertySpecifications.categories.filter((c) => c.isRequired);
    return !requiredCategories.every((requiredCategory) => selectedCategoryIds.includes(requiredCategory.id));
  }

  cancel(): void {
    this.$router.push({
      name: "DataCollectionDetails",
      query: { id: this.dataCollection.id },
    });
  }

  onInputChange(variableName: string, newValue: string): void {
    this[variableName] = newValue;
  }

  onDetailSpecificationInputChange(detail: HazardDetail, inputValue: string): void {
    const detailIndex = this.detailSpecifications.findIndex((d) => d.hazardDetailId == detail.id);

    if (!isNullOrEmpty(inputValue)) {
      let value = this.parseDetailValueInput(inputValue, detail.valueType);
      if (detailIndex >= 0) {
        this.detailSpecifications[detailIndex].value = value;
        return;
      }

      this.detailSpecifications.push(new DataValueDetailRequest(detail.id, value, detail.valueType));
      return;
    }

    if (detailIndex >= 0) this.detailSpecifications.splice(detailIndex, 1);
  }

  parseDetailValueInput(inputValue: string, valueType: DetailValueType): number | string | Date {
    switch (valueType) {
      case DetailValueType.DateTime:
        return Date.parse(inputValue);
      case DetailValueType.Double:
        return parseFloat(inputValue);
      case DetailValueType.Integer:
        return parseInt(inputValue);
      default:
        return inputValue;
    }
  }

  onCategorySpecificationInputChange(category: HazardCategoryDetails, selectedCategoryId: string): void {
    const categoryIndex = this.categorySpecifications.findIndex((c) => c.parentId == category.id);
    if (!isNullOrEmpty(selectedCategoryId)) {
      if (categoryIndex >= 0) {
        this.categorySpecifications[categoryIndex].hazardCategoryId = selectedCategoryId;
        return;
      }
      this.categorySpecifications.push(new DataValueCategoryRequest(category.id, selectedCategoryId));
      return;
    }

    if (categoryIndex >= 0) this.categorySpecifications.splice(categoryIndex, 1);
  }

  @Watch("selectedHazardTypeId")
  hazardTypeChanged(): void {
    if (!this.initializingDataValueFields) {
      this.selectedHazardPropertyId = "";
      this.selectedHazardId = undefined;
    } else {
      this.initializingDataValueFields = false;
    }

    if (this.selectedHazardTypeId != null && this.selectedHazardTypeId != 0) {
      this.getHazardPropertiesByType(this.selectedHazardTypeId);
      this.getHazardUnitsByType(this.selectedHazardTypeId);
      this.getHazardCategoriesByType(this.selectedHazardTypeId);
    }
  }

  @Watch("selectedHazardPropertyId")
  hazardPropertyChanged(): void {
    if (!isNullOrEmpty(this.selectedHazardPropertyId!)) {
      this.getHazardPropertySpecifications(this.selectedHazardPropertyId!);
    }
  }

  async getHazardPropertySpecifications(hazardPropertyId: string): Promise<void> {
    try {
      this.loadingPropertySpecifications = true;
      const response = await this.hazardDataPropertyHttpHelper.getHazardPropertySpecifications(hazardPropertyId);
      this.hazardPropertySpecifications = response;
    } finally {
      this.loadingPropertySpecifications = false;
    }
  }

  getFilteredHazardTypeOptions(): void {
    for (let i = 0; i < this.allHazardCategories.length; i++) {
      const hazardTypeName = this.allHazardCategories[i].stringValue!;
      this.hazardTypeOptions.push(
        new HazardTypeOption(HazardType[hazardTypeName], hazardTypeName, HazardType[hazardTypeName]),
      );
    }
  }

  getHazardCategoriesByType(hazardTypeId: number): void {
    this.hazardCategories = this.allHazardCategories.find(
      (category) => category.stringValue == HazardType[hazardTypeId],
    )?.children!;
  }

  async getHazardPropertiesByType(hazardType: HazardType): Promise<void> {
    const response = await this.hazardDataPropertyHttpHelper.getHazardPropertiesByType(hazardType);
    this.hazardPropertyOptions = response;
  }

  async getEvaluationRatings(): Promise<void> {
    const response = await this.hazardDataEvaluationRatingHttpHelper.getEvaluationRatings();
    this.evaluationRatingOptions = response;
  }

  async getUncertaintyMeasures(): Promise<void> {
    const response = await this.hazardDataUncertaintyMeasureHttpHelper.getUncertaintyMeasures();
    this.uncertaintyMeasureOptions = response;
  }

  async getHazardUnitsByType(hazardType: HazardType): Promise<void> {
    const response = await this.hazardDataUnitsHttpHelper.getHazardUnitsByType(hazardType);
    this.scientificUnitOptions = response;
  }

  async getHazardCategories(): Promise<void> {
    const response = await this.categoryHttpHelper.getAllCategoriesTree();
    this.allHazardCategories = response.find((category) => category.stringValue == "Hazard Type")?.children ?? [];
    this.allHazardCategories = this.categoryService.addCategoryTreeSelectProperties(this.allHazardCategories);
  }

  created(): void {
    this.initialize();
    this.addTitleToBreadcrumbPath();
  }

  async initialize(): Promise<void> {
    this.dataCollection =
      store.getters[`${StoreNames.DataCollection}/${DataCollectionStoreGetters.GET_SELECTED_DATA_COLLECTION}`];
    this.existingDataValue =
      store.getters[`${StoreNames.DataCollection}/${DataCollectionStoreGetters.GET_SELECTED_DATA_VALUE}`];
    await this.getHazardCategories();
    this.getFilteredHazardTypeOptions();
    this.getEvaluationRatings();
    this.getUncertaintyMeasures();
    this.initializeDataValueFields();
  }

  initializeDataValueFields(): void {
    if (this.existingDataValue) {
      this.initializingDataValueFields = true;
      this.selectedHazardTypeId = Number(HazardType[this.existingDataValue.hazardTypeId]);
      this.selectedHazardPropertyId = this.existingDataValue.propertyId;
      this.selectedHazardId = this.existingDataValue.hazardId;
      this.selectedUncertaintyMeasureId = this.existingDataValue.uncertaintyMeasure?.id ?? null;
      this.selectedUnitId = this.existingDataValue.scientificUnit.id;
      this.selectedEvaluationRatingId = this.existingDataValue.evaluationRating.id;
      this.value = this.existingDataValue.value;
      this.uncertaintyValue1 = this.existingDataValue.uncertaintyValueOne?.toString() ?? "";
      this.uncertaintyValue2 = this.existingDataValue.uncertaintyValueTwo?.toString() ?? "";
    }
  }

  loadCategoryValue(categorySpecification: HazardCategoryDetails): string | null {
    if (this.existingDataValue) {
      return (
        this.existingDataValue.categories.find((category) => category.id == categorySpecification.id)?.categories[0]
          .id ?? null
      );
    }
    return null;
  }

  loadDetailValue(detailSpecification): string {
    if (this.existingDataValue) {
      return (
        this.existingDataValue.details
          .find((detail) => detail.id == detailSpecification.id)
          ?.values[0].value.toString() ?? ""
      );
    }
    return "";
  }

  showEvaluationCriteriaDialog(): void {
    this.$bvModal.show("evaluation-criteria-dialog");
  }

  viewDataCollectionDetails(dataCollectionId: string): void {
    this.$router.push({
      name: "DataCollectionDetails",
      query: {
        id: dataCollectionId,
        saveSuccess: "true",
      },
    });
  }

  addTitleToBreadcrumbPath(): void {
    const title = this.dataCollection.titleClassificationMarking + " " + this.dataCollection.title;
    this.path.push({
      text: title,
      url: "details/:id?" + this.dataCollection.id,
    });
  }

  get hasPropertySpecifications(): boolean {
    return (
      this.hazardPropertySpecifications.categories.length > 0 || this.hazardPropertySpecifications.details.length > 0
    );
  }

  get isEditing(): boolean {
    return this.existingDataValue != null && this.isNewValueFromMetadata == null;
  }

  get showSaveAndApproveButton(): boolean {
    return !this.isEditing || (this.isEditing && this.existingDataValue?.status == HazardDataStatus.AwaitingApproval);
  }

  get headingText(): string {
    if (this.isEditing) {
      return "Edit Data Value";
    }
    if (this.isNewValueFromMetadata) {
      return "New Value From Metadata";
    }
    return "New Data Value";
  }

  getDetailInputFieldType(type: DetailValueType): string {
    switch (type) {
      case DetailValueType.Double:
        return "number";
      case DetailValueType.Integer:
        return "number";
      case DetailValueType.DateTime:
        return "date";
      default:
        return "text";
    }
  }

  isNullOrEmpty(input: any): boolean {
    return isNullOrEmpty(input);
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/tree-select.scss";

.scientific-unit-input {
  width: 65%;
}
</style>
