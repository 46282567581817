<template>
  <li class="dropdown-section">
    <input
      v-if="hasChildren"
      class="dropdown"
      type="checkbox"
      :id="'drowpdown-' + node.value"
      :aria-label="'Expand ' + node.value + ' filter tree'"
    />
    <label v-if="hasChildren" class="dropdown-icon" :for="'drowpdown-' + node.value">
      <i class="fa fa-chevron-down"></i>
    </label>
    <input
      type="checkbox"
      class="filter-select"
      :id="node.id"
      :value="node.id"
      v-model="selectedFilters"
      :aria-label="'Apply ' + node.value + ' filter'"
    />
    <label :for="node.id" :class="isTopLevel && 'top-level'">{{ node.displayName }}</label>
    <ul v-if="hasChildren" class="filter-tree">
      <document-management-search-filter-node
        v-for="child in node.children"
        :key="child.id"
        :node="child"
        :isTopLevel="false"
        :parentSelectedFilters="parentSelectedFilters"
        @filtersChanged="filtersChanged"
      />
    </ul>
  </li>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Category } from "@/dataModel";

@Component
export default class DocumentManagementSearchFilterNode extends Vue {
  @Prop({ required: true }) node!: Category;
  @Prop({ required: false }) filters!: Category;
  @Prop({ required: false }) isTopLevel!: boolean;
  @Prop({ default: () => [] }) parentSelectedFilters!: string[];

  selectedFilters: string[] = [];

  @Watch("selectedFilters")
  filterWatcher(): void {
    this.$emit("filtersChanged", this.selectedFilters);
  }

  @Watch("parentSelectedFilters")
  parentSelectedFiltersWatcher(): void {
    this.selectedFilters = this.parentSelectedFilters;
  }

  get hasChildren() {
    return this.node.children && this.node.children.length;
  }

  filtersChanged(selectedFilters: string[]): void {
    this.selectedFilters = selectedFilters;
  }
  mounted(): void {
    this.selectedFilters = this.parentSelectedFilters;
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

.filter-select {
  margin-left: units(1);
  margin-right: units(1);
}
.filter-tree {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-section ul {
  display: none;
}

.dropdown-section :first-child:checked ~ ul {
  display: block;
}

.dropdown-section .dropdown {
  overflow: hidden;
  height: 0;
  width: 0;
  margin-right: 0.8rem;
}

.dropdown-section {
  position: relative;
  margin-left: 35px !important;
}

.dropdown-section .dropdown:focus {
  outline: none;
}

.dropdown-icon {
  position: absolute;
  top: 0;
  left: 0;
}
/* Specify the background color to be shown when hovering over checkbox */
.dropdown-section:hover input ~ div.dropdown-icon {
  cursor: pointer;
}
/* Specify the background color to be shown when checkbox is checked */
.dropdown-section input:checked ~ .dropdown-icon {
  transform: rotate(180deg);
}

label {
  margin-bottom: 0;
}

.top-level {
  font-weight: bold;
  color: color("primary");
}
</style>
