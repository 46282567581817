<template>
  <basic-dialog
    :id="dialogId"
    title="Decline Batch"
    primaryButton="Confirm"
    secondaryButton="Cancel"
    :primaryClick="declineBatch"
    :secondaryClick="cancel"
    :isDisabled="errorText.length > 0"
  >
    <p class="text-body-xs text-bold margin-bottom-0">Filenames: {{ formatNames(fileNames) }}</p>
    <form class="usa-form maxw-full">
      <label class="usa-label" for="reason"> Reason for rejection </label>
      <textarea
        class="usa-textarea"
        id="reason"
        name="reason"
        placeholder="Enter the reason why the batch is being declined..."
        v-model.trim="reason"
        :disabled="batch.stagedFiles?.length === 0"
      ></textarea>
      <span class="usa-error-message" id="documents-error-message" v-if="!formIsValid && showValidation">
        Reason cannot be empty
      </span>
    </form>
    <div class="usa-alert usa-alert--info usa-alert--slim margin-top-5" v-if="errorText.length > 0">
      <div class="usa-alert__body">
        <p class="usa-alert__text">
          {{ errorText }}
        </p>
      </div>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import { StagedFileBatch } from "@/dataModel";
import FileStatus from "@/constants/FileStatus";

@Component({
  components: { BasicDialog },
})
export default class DeclineBatchDialog extends Vue {
  @Prop({ required: true }) dialogId!: string;
  @Prop({ required: true }) batch!: StagedFileBatch;

  reason = "";
  showValidation = false;

  //#region Computed properties

  get fileNames(): string[] {
    return this.batch.stagedFiles?.map((file) => file.fileName) ?? [];
  }

  get errorText(): string {
    if (this.allFilesHaveStatus(FileStatus.Converted)) {
      return "Cannot decline files that are already converted.";
    }
    if (this.allFilesHaveStatus(FileStatus.Declined)) {
      return "Cannot decline files that are already declined.";
    }

    return "";
  }

  get formIsValid(): boolean {
    return this.reason.length > 0;
  }

  //#endregion

  allFilesHaveStatus(status: FileStatus): boolean {
    return this.batch?.stagedFiles && this.batch.stagedFiles.every((file) => file.fileStatus === status);
  }

  declineBatch(): void {
    this.showValidation = true;
    if (this.formIsValid) {
      this.$emit("decline", this.reason);
      this.resetForm();
      this.$bvModal.hide(this.dialogId);
    }
  }

  resetForm(): void {
    this.reason = "";
    this.showValidation = false;
  }

  cancel(): void {
    this.resetForm();
    this.$bvModal.hide(this.dialogId);
  }

  formatNames(names: string[]): string {
    return names.join(", ");
  }
}
</script>

<style lang="scss" scoped></style>
