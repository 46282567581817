<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <div class="desktop:grid-col-8">
          <h1 class="font-heading-xl text-primary-dark margin-top-0 margin-bottom-5">Document Management</h1>
        </div>
        <div class="desktop:grid-col-4">
          <div class="create-button">
            <button class="usa-button" @click="createDocument">Create Copyright Restricted</button>
          </div>
        </div>
      </div>

      <div class="grid-row">
        <span v-if="searchFilters.length > 0" ref="appliedFilters" id="applied-filter-container">
          <button class="usa-button usa-button--outline" @click="clearFilters">Clear all filters</button>
          <span v-for="(filter, index) in shownAppliedFilters" :key="index">
            <p class="applied-filter">
              {{ filter.stringValue }}
              <i
                class="fa fa-times delete-filter-btn float-right"
                @click="removeSelection(filter)"
                :aria-label="'Remove ' + filter.stringValue + ' filter'"
                tabindex="0"
                @keydown="removeAppliedFilterWithKeyboard($event, filter)"
              />
            </p>
          </span>
          <a
            v-if="!showAllAppliedFilters"
            href="#"
            class="usa-link"
            @click.prevent="toggleShowAllAppliedFilters"
            ref="showAllAppliedFiltersLink"
            >Show all<i class="fa fa-chevron-down fa-fw"></i
          ></a>
          <a
            v-else
            href="#"
            class="usa-link"
            @click.prevent="toggleShowAllAppliedFilters"
            ref="showAllAppliedFiltersLink"
            >Show less<i class="fa fa-chevron-up fa-fw"></i
          ></a>
        </span>
      </div>

      <div class="grid-row margin-bottom-2">
        <div class="desktop:grid-col-3 margin-bottom-2 filters">
          <search-filter
            class="margin-bottom-2"
            :taxonomy="taxonomy"
            :isTaxonomyLoading="isLoadingFilters"
            :includeCount="false"
            @filtersChanged="filtersChanged"
            @sectionExpanded="nodeExpanded"
            ref="filters"
            :parentSelectedFilters="categoryFilters"
          />
        </div>

        <div class="desktop:grid-col padding-left-4">
          <div class="grid-row grid-gap margin-bottom-2">
            <form
              class="usa-search usa-search--small search-style"
              role="search"
              @submit.prevent="submitNewSearch"
              v-bind:class="{ 'search-focused': isSearchBoxFocused }"
            >
              <label class="usa-sr-only" for="search-field-en-small">Search</label>
              <input
                class="usa-input"
                id="search-field-en-small"
                type="search"
                name="search"
                v-model="searchTerm"
                placeholder="Search documents..."
              />
              <button class="usa-button" type="submit">
                <span class="usa-sr-only">Search</span>
              </button>
            </form>

            <results-per-page-button
              class="align-right"
              :prevPerPage="perPage"
              @resultsPerPageChanged="perPageAmountChanged"
            ></results-per-page-button>

            <div class="grid-col-auto pagination-container">
              <table-pagination
                class="pagination"
                @prev="prevPage"
                @next="nextPage"
                :page="page"
                :hasNextPage="hasNextPage"
              />
            </div>
          </div>

          <div class="grid-row grid-gap">
            <div class="desktop:grid-col">
              <data-table
                @publish="changeStatusHandler(true)"
                @unpublish="changeStatusHandler(false)"
                @bulkEdit="bulkEditHandler"
                @edit="editSingleDocument"
                @selections="getSelectedDocuments($event)"
                @sortingChanged="sortingChanged"
                :isLoadingData="isSearchLoading"
                :items="documents"
                :fields="headers"
                :actions="actions"
                :bulkActions="bulkActions"
                :perPage="perPage"
                :page="page"
                :selected="selectedItems"
                ref="documentTable"
              >
                <template v-slot:cell(title)="data">
                  <div class="usa-checkbox margin-y-05 maxw-mobile table-col-md">
                    <input
                      class="usa-checkbox__input"
                      :id="data.item.id"
                      type="checkbox"
                      :name="data.value"
                      v-model="selectedItems"
                      :value="data.item"
                    />
                    <label
                      :class="['usa-checkbox__label', 'margin-0', 'overflow-ellipsis']"
                      :for="data.item.id"
                      :title="data.value"
                      >{{ data.value }}
                    </label>
                  </div>
                </template>
                <template v-slot:cell(classificationMarking)="data">
                  <div class="margin-y-05 table-col-xs overflow-ellipsis" :title="data.value">
                    {{ data.value }}
                  </div>
                </template>
              </data-table>
              <table-pagination
                v-if="documents.length > 0"
                class="pagination"
                @prev="prevPage"
                @next="nextPage"
                :page="page"
                :hasNextPage="hasNextPage"
              />
            </div>
          </div>
        </div>
      </div>
      <confirm-status-change-dialog
        :selectedDocumentCount="selectedItems.length"
        @confirm="changeStatus($event)"
        :isPublished="documentStatus"
        :containsDocumentWithStatus="documentsContainUpdateStatus(documentStatus)"
      />
      <bulk-edit-dialog
        :selectedDocumentCount="selectedItems.length"
        :options="categoryTreeSelectOptions"
        @confirm="bulkEdit"
        ref="bulkEdit"
      />
      <success-toast id="users-update-status-toast" message="Statuses have successfully been updated." />
      <success-toast id="document-updated-toast" message="Document updated successfully." />
      <success-toast id="document-created-toast" message="Copyright restricted document created." />
      <error-toast id="error-toast" :message="errorMessage" />
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import store from "@/store";
import { DocumentStoreActions } from "@/constants/store/document/documentStoreConstants";
import StoreNames from "@/constants/store/StoreNames";
import DocumentType from "@/constants/DocumentTypeWithDisplayName";
import DataTable from "@/components/common/DataTable.vue";
import DataTableFilter from "@/components/common/DataTableFilter.vue";
import TablePagination from "@/components/common/TablePagination.vue";
import ResultsPerPageButton from "@/components/common/ResultsPerPageButton.vue";
import ConfirmStatusChangeDialog from "@/components/navigation/DocumentManagement/ConfirmStatusChangeDialog.vue";
import BulkEditDialog from "@/components/navigation/DocumentManagement/BulkEditDialog.vue";
import Spinner from "@/components/common/Spinner.vue";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import {
  SiteDocument,
  Category,
  FilterNodeInformation,
  AppConfig,
  Classification,
  DisseminationControl,
  Sci,
  KnowledgeResourceAudience,
} from "@/dataModel";
import { DataTableAction, DataTableHeader, BreadcrumbPathItem, BulkUpdateDocument } from "@/dataModel/interfaces";
import { SearchResponse } from "@/dataModel/responses";
import CategoryHttpHelper from "@/components/resources/categoryHttpHelper";
import SiteDocumentAdminHttpHelper from "@/components/resources/siteDocumentAdminHttpHelper";
import serviceTypes from "@/dependencyInjection/types";
import container from "@/dependencyInjection/config";
import DocumentManagementSearchFilter from "@/components/navigation/DocumentManagement/DocumentManagementSearchFilter.vue";
import SearchFilter from "@/components/common/SearchFilter.vue";
import { AuthStoreGetters } from "@/constants/store/auth/authStoreConstants";
import {
  DocumentManagementStoreActions,
  DocumentManagementStoreGetters,
} from "@/constants/store/siteDocumentManagement/documentManagementStoreConstants";
import { FilterType } from "@/constants/FilterType";
import { NonCategoryFilters } from "@/constants/NonCategoryFilters";
import CopyrightStatus from "@/constants/CopyrightStatus";
import PublicationStatus from "@/constants/PublicationStatus";
import IPermissionService from "@/services/interfaces/IPermissionService";
import { SortColumn } from "@/dataModel/constants/SortColumn";
import { getModule } from "vuex-module-decorators";
import SiteDocumentManagement from "@/store/modules/siteDocumentManagementModule";
import CategoryService from "@/services/interfaces/ICategoryService";
import { throttle } from "lodash";

const siteDocumentModule = getModule(SiteDocumentManagement, store);

@Component({
  components: {
    Breadcrumb,
    DataTable,
    DataTableFilter,
    TablePagination,
    ResultsPerPageButton,
    ConfirmStatusChangeDialog,
    BulkEditDialog,
    DocumentManagementSearchFilter,
    SearchFilter,
    Spinner,
  },
})
export default class DocumentManagementPage extends Vue {
  @Prop() readonly editSuccess: boolean | undefined;
  @Prop() readonly createSuccess: boolean | undefined;

  searchTerm: string = "";
  perPage = 20;
  page: number = 1;
  searchFilters: FilterNodeInformation[] = []; // added
  shownAppliedFilters: FilterNodeInformation[] = []; //added
  sortOrder: string = "ascending";
  sortColumn: string = "title";

  categoryFilters: FilterNodeInformation[] = [];
  classificationFilters: FilterNodeInformation[] = [];
  disseminationControlFilters: FilterNodeInformation[] = [];
  audienceFilters: FilterNodeInformation[] = [];
  documentTypeFilters: FilterNodeInformation[] = [];
  isCopyrightRestrictedFilters: FilterNodeInformation[] = [];
  isPublishedFilters: FilterNodeInformation[] = [];
  taxonomy: FilterNodeInformation[] = [];
  isLoadingFilters: boolean = true;

  lastExpandedNode?: FilterNodeInformation;
  isSearchLoading: boolean = true;
  isSearchBoxFocused: boolean = false;

  categoryPaths: string[] = [];
  classifications: string[] = [];
  disseminationControls: string[] = [];
  documentTypes: string[] = [];
  audiences: string[] = [];
  scis: string[] = [];
  copyrightStatuses: boolean[] = [];
  publicationStatuses: boolean[] = [];

  showAllAppliedFilters: boolean = false;

  userClearanceLevel!: Classification;
  userDisseminationControls: DisseminationControl[] = [];
  userAudiences: KnowledgeResourceAudience[] = [];
  userScis: Sci[] = [];

  selectedDocumentIds: string[] = [];
  selectedItems: SiteDocument[] = [];
  documentStatus: boolean = true;
  documents: SiteDocument[] = [];
  resultCount: number = -1;

  categoryTreeSelectOptions: FilterNodeInformation[] = [];

  throttleSearchMethod: any;
  usePreviousSetup: boolean = false;
  errorMessage: string = "";
  readonly maxFilterRows = 2;
  $refs!: {
    appliedFilters: HTMLElement;
    showAllAppliedFiltersLink: HTMLElement;
    filters: SearchFilter;
    documentTable: DataTable;
    bulkEdit: BulkEditDialog;
  };

  private categoryService = container.get<CategoryService>(serviceTypes.CategoryService);
  private permissionService = container.get<IPermissionService>(serviceTypes.PermissionService);
  private categoryHttpHelper = container.get<CategoryHttpHelper>(serviceTypes.CategoryHttpHelper);
  private siteDocumentAdminHttpHelper = container.get<SiteDocumentAdminHttpHelper>(
    serviceTypes.SiteDocumentAdminHttpHelper,
  );
  private appConfig = container.get<AppConfig>(serviceTypes.AppConfig);

  path: BreadcrumbPathItem[] = [
    {
      text: "Administration",
    },
    {
      text: "Document Management",
    },
  ];

  actions: DataTableAction[] = [
    {
      name: "Edit",
      icon: "fa-pen",
      action: "edit",
    },
  ];

  bulkActions: DataTableAction[] = [
    {
      name: "Publish",
      icon: "fa-check",
      action: "publish",
    },
    {
      name: "Unpublish",
      icon: "fa-times",
      action: "unpublish",
    },
    {
      name: "Bulk Edit",
      icon: "fa-pen",
      action: "bulkEdit",
    },
  ];

  headers: DataTableHeader[] = [
    {
      label: "Title",
      key: "title",
      sortable: true,
    },
    {
      label: "Classification",
      key: "classificationMarking",
      sortable: true,
    },
    {
      label: "Modified",
      key: "lastUpdatedDateTime",
      sortable: true,
      formatter: (val) => this.$options.filters?.formatDate(val),
    },
    {
      label: "Status",
      key: "isPublished",
      sortable: true,
      formatter: this.formatIsPublished,
    },
    {
      // header for actions
      label: "",
      key: "actions",
      sortable: false,
    },
  ];

  nodeExpanded(expandedNode: FilterNodeInformation) {
    this.lastExpandedNode = expandedNode;
  }

  async getCategories(): Promise<void> {
    try {
      this.isLoadingFilters = true;
      const response = await this.categoryHttpHelper.getAllCategoriesTree();
      this.categoryTreeSelectOptions = this.categoryService.addCategoryTreeSelectProperties(response);
    } finally {
      this.isLoadingFilters = false;
    }
  }

  getStaticClassMembers(type: any): any[] {
    let memberNames = Object.keys(type);
    return memberNames.map((x) => type[x]);
  }

  submitNewSearch(): void {
    this.page = 1;
    this.throttleSearchMethod();
  }

  async searchDocuments(): Promise<void> {
    try {
      this.isSearchLoading = true;
      const currentPage = this.page - 1;
      const response: SearchResponse<SiteDocument> = await this.siteDocumentAdminHttpHelper.searchSiteDocuments(
        currentPage,
        this.perPage,
        this.searchTerm,
        this.sortOrder,
        this.sortColumn,
        this.classifications,
        this.disseminationControls,
        this.categoryPaths,
        this.documentTypes,
        this.copyrightStatuses,
        this.audiences,
        this.scis,
        this.publicationStatuses,
      );
      this.documents = response.result;
      this.resultCount = response.count;
    } finally {
      this.isSearchLoading = false;
    }
  }

  createDocument(): void {
    this.saveTableNavigation();
    this.$router.push({ name: "CreateCopyrightDocument" });
  }

  changeStatusHandler(publish: boolean): void {
    this.documentStatus = publish;
    this.$bvModal.show("confirm-status-dialog");
  }

  async changeStatus(publish: boolean): Promise<void> {
    try {
      await this.siteDocumentAdminHttpHelper.bulkChangeStatusSiteDocuments(this.selectedDocumentIds, publish);
      this.throttleSearchMethod();
      this.$bvToast.show("confirm-status-toast");
      (<DataTable>this.$refs.documentTable).clearSelections();
    } catch (error) {
      this.showErrorToast("Update was unsuccessful.");
    }
  }

  bulkEditHandler(): void {
    (<BulkEditDialog>this.$refs.bulkEdit).clearForm();
    this.$bvModal.show("bulk-edit-documents-dialog");
  }

  async bulkEdit(bulkUpdateResponse: BulkUpdateDocument): Promise<void> {
    bulkUpdateResponse.siteDocumentIds = this.selectedDocumentIds;
    try {
      await this.siteDocumentAdminHttpHelper.bulkUpdateSiteDocuments(bulkUpdateResponse).then((x) => {
        (<DataTable>this.$refs.documentTable).clearSelections();
        this.throttleSearchMethod();
      });
      this.$bvToast.show("confirm-bulk-edit");
    } catch (error) {
      this.showErrorToast("Edit was unsuccessful.");
    }
  }

  editSingleDocument(document: SiteDocument): void {
    store.dispatch(`${StoreNames.Document}/${DocumentStoreActions.SET_SELECTED_DOCUMENT}`, document);
    this.saveTableNavigation();
    this.$router.push({ name: "EditDocument" });
  }

  saveTableNavigation(): void {
    store.dispatch(
      `${StoreNames.SiteDocumentManagement}/${DocumentManagementStoreActions.SET_SEARCH_TERM}`,
      this.searchTerm,
    );
    store.dispatch(`${StoreNames.SiteDocumentManagement}/${DocumentManagementStoreActions.SET_PAGE}`, this.page);
    store.dispatch(
      `${StoreNames.SiteDocumentManagement}/${DocumentManagementStoreActions.SET_RESULTS_PER_PAGE}`,
      this.perPage,
    );
    store.dispatch(
      `${StoreNames.SiteDocumentManagement}/${DocumentManagementStoreActions.SET_FILTERS}`,
      this.searchFilters,
    );
  }

  prevPage(): void {
    if (this.page > 1) {
      this.page--;
      this.throttleSearchMethod();
    }
  }

  nextPage(): void {
    if (this.hasNextPage) {
      this.page++;
      this.throttleSearchMethod();
    }
  }

  get hasNextPage(): boolean {
    return this.page * this.perPage < this.resultCount;
  }

  perPageAmountChanged(val: number): void {
    this.perPage = val;
    if (!this.usePreviousSetup) {
      this.page = 1;
      this.throttleSearchMethod();
    }
  }

  sortingChanged(ctx: any): void {
    this.sortColumn = ctx.sortBy;
    this.sortOrder = ctx.sortDesc ? "descending" : "ascending";
    if (this.sortColumn === "lastUpdatedDateTime") {
      this.sortColumn = SortColumn.Updated;
    }
    this.throttleSearchMethod();
  }

  toggleShowAllAppliedFilters() {
    this.showAllAppliedFilters = !this.showAllAppliedFilters;
    Vue.nextTick(() => {
      this.updateAppliedFilters();
    });
  }

  private updateFilters(selectedFilters: FilterNodeInformation[]) {
    this.classifications = selectedFilters
      .filter((x) => x.filterType == FilterType.Classification)
      .map((x) => (x.value as Classification).id);
    this.documentTypes = selectedFilters
      .filter((x) => x.filterType == FilterType.DocumentType)
      .map((x) => (x.value as DocumentType).value);
    this.copyrightStatuses = selectedFilters
      .filter((x) => x.filterType == FilterType.CopyrightRestriction)
      .map((x) => (x.value as CopyrightStatus).isCopyrightRestricted);
    this.publicationStatuses = selectedFilters
      .filter((x) => x.filterType == FilterType.Publication)
      .map((x) => (x.value as PublicationStatus).isPublished);
    this.disseminationControls = selectedFilters
      .filter((x) => x.filterType == FilterType.DisseminationControl)
      .map((x) => (x.value as DisseminationControl).id);
    this.audiences = selectedFilters
      .filter((x) => x.filterType == FilterType.Audience)
      .map((x) => (x.value as KnowledgeResourceAudience).id);
    this.scis = selectedFilters.filter((x) => x.filterType == FilterType.Sci).map((x) => (x.value as Sci).id);
    this.categoryPaths = selectedFilters.filter((x) => x.filterType == FilterType.Category).map((x) => x.value?.id);
    this.searchFilters = selectedFilters;
    this.shownAppliedFilters = selectedFilters;

    Vue.nextTick(() => {
      this.updateAppliedFilters();
    });
  }

  updateAppliedFilters() {
    if (this.searchFilters.length > 0) {
      let filterContainer: HTMLElement = this.$refs.appliedFilters;
      let rowWidth = filterContainer.offsetWidth;
      let children: HTMLCollection = filterContainer.children;
      let total = 0;
      let numRows = 1;
      let showAllAppliedFiltersLink: HTMLElement = this.$refs.showAllAppliedFiltersLink;
      showAllAppliedFiltersLink.style.display = "inline-block";
      for (let i = 0; i < children.length; ++i) {
        let item: HTMLElement = children[i] as HTMLElement;
        if (item == showAllAppliedFiltersLink) {
          continue;
        }
        item.style.display = "inline-block";
        let style = window.getComputedStyle(item);
        let itemWidth = item.offsetWidth + parseFloat(style.marginRight);
        if (
          (numRows == this.maxFilterRows &&
            total + itemWidth + showAllAppliedFiltersLink.offsetWidth > rowWidth &&
            !this.showAllAppliedFilters) ||
          total + itemWidth > rowWidth
        ) {
          total = 0;
          ++numRows;
        }
        if (numRows > this.maxFilterRows && !this.showAllAppliedFilters) {
          item.style.display = "none";
        }
        total += itemWidth;
      }
      if (numRows <= this.maxFilterRows) {
        showAllAppliedFiltersLink.style.display = "none";
      }
    }
  }

  filtersChanged(selectedFilters: FilterNodeInformation[]): void {
    this.updateFilters(selectedFilters);
    if (!this.usePreviousSetup) {
      this.page = 1;
    }
    this.throttleSearchMethod();
  }

  removeAppliedFilterWithKeyboard(event: KeyboardEvent, filter: FilterNodeInformation): void {
    if (!event.repeat && (event.code == "Space" || event.code == "Enter")) {
      event.preventDefault();
      this.removeSelection(filter);
    }
  }

  getSelectedDocuments(documents: SiteDocument[]): void {
    this.selectedItems = documents;
    this.selectedDocumentIds = documents.map(function (documents: SiteDocument) {
      return documents.id;
    });
  }

  documentsContainUpdateStatus(status: boolean): boolean {
    const selectedDocumentStatuses = this.selectedItems.map(function (documents: SiteDocument) {
      return documents.isPublished;
    });
    return selectedDocumentStatuses.includes(status);
  }

  selectFilters(filters: FilterNodeInformation[]): void {
    (<SearchFilter>this.$refs.filters).selectFilters(filters);
  }

  removeSelection(filter: FilterNodeInformation): void {
    (<SearchFilter>this.$refs.filters).removeSelectedFilter(filter);
    this.updateAppliedFilters();
  }

  clearFilters(): void {
    (<SearchFilter>this.$refs.filters).clearSelections();
  }

  addSpaces(status: string): string {
    return status.replace(/([A-Z][a-z])/g, " $1");
  }

  removeSpaces(str: string) {
    return str.replaceAll(" ", "");
  }

  formatIsPublished(isPublished: boolean): string {
    if (isPublished) {
      return "Published";
    } else {
      return "Unpublished";
    }
  }

  initializeCategories(categories: FilterNodeInformation[]) {
    categories.sort(function (a, b) {
      return a.stringValue!.toLowerCase().localeCompare(b.stringValue!.toLowerCase());
    });
    for (let category of categories) {
      category.filterType = FilterType.Category;
      category.count = 0;
      if (category.children) {
        this.initializeCategories(category.children);
      }
    }
  }

  getClassificationFilters(): FilterNodeInformation | null {
    let root = new FilterNodeInformation();
    root.stringValue = NonCategoryFilters.Classification;
    root.children = [];
    root.filterType == FilterType.None;
    root.hideSelectAll = true;
    root.path = ["Additional Details", "Classification"];
    let classifications: Classification[] = this.permissionService.getClassifications();
    for (let i = 0; i < classifications.length; ++i) {
      let classification = classifications[i];
      if (classification.value > this.userClearanceLevel.value) {
        continue;
      }
      let childNode = new FilterNodeInformation();
      childNode.stringValue = classification.title;
      childNode.value = classification;
      childNode.filterType = FilterType.Classification;
      childNode.path = ["Additional Details", "Classification", classification.title];
      root.children.push(childNode);
    }
    if (root.children.length <= 1) {
      return null;
    }
    return root;
  }

  getDisseminationControlFilters(): FilterNodeInformation | null {
    let root = new FilterNodeInformation();
    root.stringValue = NonCategoryFilters.DisseminationControls;
    root.children = [];
    root.filterType == FilterType.None;
    root.hideSelectAll = true;
    root.path = ["Additional Details", "DisseminationControl"];
    const disseminationControls: DisseminationControl[] = this.permissionService.getDisseminationControls();
    for (let i = 0; i < disseminationControls.length; ++i) {
      let disseminationControl = disseminationControls[i];
      let childNode = new FilterNodeInformation();
      childNode.stringValue = disseminationControl.title;
      childNode.value = disseminationControl;
      childNode.filterType = FilterType.DisseminationControl;
      childNode.path = ["Additional Details", "DisseminationControl", disseminationControl.title];
      root.children.push(childNode);
    }
    if (root.children.length <= 1) {
      return null;
    }
    return root;
  }

  getAudienceFilters(): FilterNodeInformation | null {
    let root = new FilterNodeInformation();
    root.stringValue = NonCategoryFilters.Audience;
    root.filterType = FilterType.None;
    root.children = [];
    root.hideSelectAll = true;
    root.path = ["Additional Details", "Audience"];
    let audiences: KnowledgeResourceAudience[] = this.permissionService.getAudiences();
    let allowedAudiences = audiences.filter((x) => this.userAudiences.findIndex((a) => a.id == x.id) >= 0);
    for (let audience of allowedAudiences) {
      let childNode = new FilterNodeInformation();
      childNode.stringValue = audience.fullName;
      childNode.value = audience;
      childNode.filterType = FilterType.Audience;
      childNode.path = ["Additional Details", "Audience", audience.fullName];
      root.children.push(childNode);
    }
    if (root.children.length <= 1) {
      return null;
    }
    return root;
  }

  getSciFilters(): FilterNodeInformation | null {
    let root = new FilterNodeInformation();
    root.stringValue = NonCategoryFilters.Sci;
    root.filterType = FilterType.None;
    root.children = [];
    root.hideSelectAll = true;
    root.path = ["Additional Details", "SCI"];
    let scis: Sci[] = this.permissionService.getScis();
    let allowedScis = scis.filter((x) => this.userScis.findIndex((a) => a.id == x.id) >= 0);
    for (let sci of allowedScis) {
      let childNode = new FilterNodeInformation();
      childNode.stringValue = sci.title;
      childNode.value = sci;
      childNode.filterType = FilterType.Sci;
      childNode.path = ["Additional Details", "SCI", sci.title];
      root.children.push(childNode);
    }
    if (root.children.length <= 1) {
      return null;
    }
    return root;
  }

  getFilters(
    filter: NonCategoryFilters,
    filterType: FilterType,
    classMembers: any[],
    userHasAccessMethod: Function,
  ): FilterNodeInformation | null {
    let root = new FilterNodeInformation();
    root.stringValue = filter;
    root.children = [];
    root.filterType == FilterType.None;
    root.hideSelectAll = true;
    root.path = ["Additional Details", filterType.toString()];
    for (let classMember of classMembers) {
      if (!userHasAccessMethod(classMember)) {
        continue;
      }
      let childNode = new FilterNodeInformation();
      childNode.stringValue = classMember.displayName;
      childNode.value = classMember;
      childNode.filterType = filterType;
      childNode.path = ["Additional Details", filterType.toString(), classMember.value];
      root.children.push(childNode);
    }
    if (root.children.length <= 1) {
      return null;
    }
    return root;
  }

  async populateTaxonomy(): Promise<void> {
    await siteDocumentModule.fetchDocumentTypes();
    let categories = await this.categoryHttpHelper.getAllCategoriesTree();
    this.initializeCategories(categories);
    this.taxonomy = categories;

    let additionalDetailsSection = new FilterNodeInformation();
    additionalDetailsSection.stringValue = "Additional Details";
    additionalDetailsSection.filterType = FilterType.None;

    const potentialFilters = [
      this.getClassificationFilters(),
      this.getDisseminationControlFilters(),
      this.getSciFilters(),
      this.getAudienceFilters(),
      this.getFilters(
        NonCategoryFilters.CopyrightStatus,
        FilterType.CopyrightRestriction,
        this.getStaticClassMembers(CopyrightStatus),
        (x: CopyrightStatus) => true,
      ),
      this.getFilters(
        NonCategoryFilters.DocumentType,
        FilterType.DocumentType,
        siteDocumentModule.documentTypes,
        (x: DocumentType) => true,
      ),
      this.getFilters(
        NonCategoryFilters.Publication,
        FilterType.Publication,
        this.getStaticClassMembers(PublicationStatus),
        (x: PublicationStatus) => true,
      ),
    ];

    additionalDetailsSection.children = potentialFilters.filter((f) => f !== null) as FilterNodeInformation[];

    this.taxonomy.push(additionalDetailsSection);
  }

  async getUser(): Promise<void> {
    const user = store.getters[`${StoreNames.Auth}/${AuthStoreGetters.GET_USER}`];
    this.userClearanceLevel = user.actor!.clearanceLevel;
    this.userDisseminationControls = user.actor!.readOnlyDisseminationControls ?? [];
    this.userAudiences = user.actor!.readOnlyAudiences ?? [];
    this.userScis = user.actor!.scis ?? [];
  }

  created(): void {
    this.getCategories();

    this.throttleSearchMethod = throttle(async () => {
      await this.searchDocuments();
    }, this.appConfig.searchRequestMinimumIntervalInMilliseconds);

    this.getUser().then(() => {
      this.populateTaxonomy().then(() => {
        this.usePreviousSetup = Boolean(this.$route.query?.usePreviousSetup);
        if (this.usePreviousSetup) {
          this.searchTerm =
            store.getters[`${StoreNames.SiteDocumentManagement}/${DocumentManagementStoreGetters.GET_SEARCH_TERM}`];
          const preSelectedFilters =
            store.getters[`${StoreNames.SiteDocumentManagement}/${DocumentManagementStoreGetters.GET_FILTERS}`];
          this.perPage =
            store.getters[
              `${StoreNames.SiteDocumentManagement}/${DocumentManagementStoreGetters.GET_RESULTS_PER_PAGE}`
            ];
          this.page = store.getters[`${StoreNames.SiteDocumentManagement}/${DocumentManagementStoreGetters.GET_PAGE}`];
          this.selectFilters(preSelectedFilters);

          if (preSelectedFilters.length > 0) {
            return;
          }
        }
        this.throttleSearchMethod();
      });
    });
  }

  mounted(): void {
    if (this.editSuccess) {
      this.showSuccessToast("document-updated-toast");
    } else if (this.createSuccess) {
      this.showSuccessToast("document-created-toast");
    }
    this.usePreviousSetup = false;
  }

  showSuccessToast(toastId: string): void {
    this.$bvToast.show(toastId);
    this.removeSuccessQueriesFromRoute();
  }

  showErrorToast(message: string): void {
    this.errorMessage = message;
    this.$bvToast.show("error-toast");
  }

  removeSuccessQueriesFromRoute(): void {
    let newQuery = Object.assign({}, this.$route.query);
    delete newQuery.editSuccess;
    delete newQuery.createSuccess;

    this.$router.replace({
      name: "DocumentManagement",
      query: newQuery,
    });
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

.applied-filter-header {
  color: color("primary");
  font-weight: font-weight("bold");
  margin-bottom: 0.4rem;
}

.applied-filter {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: units(1);
  background-color: color("base-lightest");
  border-radius: 10px;
  padding: 8px;
}

.delete-filter-btn {
  color: color("base-dark");
  margin-top: 5px;
  margin-left: units(1);
}

.delete-filter-btn:hover {
  color: color("base-darkest");
}

.pagination {
  float: right;
}

.pagination-container {
  margin-top: 0.5rem;
}

.search-style {
  width: 50%;
  margin-top: 4px;
}

.hide {
  display: none;
}

.align-right {
  margin-left: auto;
}

.filters {
  margin-top: 4px;
}

@media all and (min-width: 64em) {
  .create-button {
    float: right;

    .usa-button {
      margin-right: 0 !important;
    }
  }
}

#applied-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

#applied-filter-container > * {
  margin-bottom: units(2);
}

.table-col-xs {
  width: 9.5rem;
}
</style>
