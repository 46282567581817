<template>
  <div>
    <basic-dialog id="about-dialog" title="About" primaryButton="Close" :primaryClick="close">
      <div v-if="isLoading" class="display-flex flex-column flex-align-center">
        <spinner />
      </div>
      <div v-else>
        <p>API Version: {{ apiVersion }}</p>
        <p>Website Version: {{ websiteVersion }}</p>
      </div>
    </basic-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/services/services.types";
import ApplicationHttpHelper from "../resources/applicationHttpHelper";
import Spinner from "@/components/common/Spinner.vue";
import { getModule } from "vuex-module-decorators";
import AppModule from "@/store/modules/appModule";
import store from "@/store";

@Component({
  components: {
    BasicDialog,
    Spinner,
  },
})
export default class AboutDialog extends Vue {
  isLoading = false;
  apiVersion = "";
  websiteVersion = "";
  private appHttpHelper = container.get<ApplicationHttpHelper>(serviceTypes.ApplicationHttpHelper);
  private appModule = getModule(AppModule, store);

  created(): void {
    this.$root.$on("bv::modal::show", async () => {
      this.isLoading = true;
      this.apiVersion = this.appHttpHelper ? await this.appHttpHelper.getApiVersionAsync() : "";
      this.websiteVersion = this.appModule.websiteVersion;
      this.isLoading = false;
    });
  }

  close() {
    this.$bvModal.hide("about-dialog");
  }
}
</script>
