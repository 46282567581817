import FeaturedLengthType from "@/constants/featuredLengthType";
import FeaturedHazardType from "@/constants/FeaturedHazardType";

export class NewFeaturedHazardDataRequest {
  featuredHazardDataId?: string | null;
  knowledgeResourceId?: string | null;
  pooledFeaturedDataViewId?: string | null;
  displayOrder: number;
  featuredHazardType?: FeaturedHazardType;
  startDate: Date;
  endDate?: Date | null;
  featuredLengthType: FeaturedLengthType;
  customFeaturedLength: number;

  constructor(
    featuredHazardDataId?: string | null,
    knowledgeResourceId?: string | null,
    pooledFeaturedDataViewId?: string | null,
    displayOrder?: number,
    startDate?: Date,
    endDate?: Date | null,
    featuredLengthType?: FeaturedLengthType,
    featuredHazardType?: FeaturedHazardType,
    customFeaturedLength?: number,
  ) {
    this.featuredHazardDataId = featuredHazardDataId;
    this.knowledgeResourceId = knowledgeResourceId || "";
    this.pooledFeaturedDataViewId = pooledFeaturedDataViewId;
    this.displayOrder = displayOrder || 0;
    this.startDate = startDate || new Date();
    this.endDate = endDate || null;
    this.featuredLengthType = featuredLengthType || FeaturedLengthType.NoEndDate;
    this.featuredHazardType = featuredHazardType;
    this.customFeaturedLength = customFeaturedLength || 0;
  }
}
