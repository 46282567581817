import { UserSearchStoreMutations } from "@/constants/store/userSearch/userSearchStoreConstants";
import { UserSearchParameters } from "@/dataModel";
import { UserSearchState } from "@/typings/store/states/UserSearchStoreState";

export default {
  [UserSearchStoreMutations.SET_SEARCH_PARAMETERS](state: UserSearchState, searchParameters: UserSearchParameters) {
    state.searchParameters = searchParameters;
  },
  [UserSearchStoreMutations.SET_SEARCH_TERM](state: UserSearchState, searchTerm: string) {
    state.searchTerm = searchTerm;
  },
  [UserSearchStoreMutations.SET_USE_SAVED_PARAMS](state: UserSearchState, useSavedParams: boolean) {
    state.useSavedParams = useSavedParams;
  },
};
