import RequestStatus from "../constants/RequestStatus";
import RequestType from "../constants/RequestType";
import { RequestEventLog } from "./";

export class Request {
  id: string;
  actorId: string;
  requestStatus: RequestStatus;
  title: string;
  reason: string;
  email: string;
  phoneNumber: string;
  organization: string;
  isDeleted: boolean;
  createdDateTime?: Date;
  dateNeededBy?: Date;
  requestType?: RequestType;
  lastUpdated?: Date;
  log?: RequestEventLog[];

  constructor(
    id?: string,
    actorId?: string,
    requestStatus?: RequestStatus,
    title?: string,
    reason?: string,
    email?: string,
    phoneNumber?: string,
    organization?: string,
    isDeleted?: boolean,
    createdDateTime?: Date,
    dateNeededBy?: Date,
    requestType?: RequestType,
    lastUpdated?: Date,
  ) {
    this.id = id || "";
    this.actorId = actorId || "";
    this.requestStatus = requestStatus || RequestStatus.Open;
    this.title = title || "";
    this.reason = reason || "";
    this.email = email || "";
    this.phoneNumber = phoneNumber || "";
    this.organization = organization || "";
    this.isDeleted = isDeleted ?? false;
    this.createdDateTime = createdDateTime;
    this.dateNeededBy = dateNeededBy;
    this.requestType = requestType;
    this.lastUpdated = lastUpdated;
  }
}
