<template>
  <div>
    <basic-dialog
      id="delete-comment-dialog"
      title="Confirm Delete"
      primaryButton="Confirm"
      secondaryButton="Cancel"
      :primaryClick="confirm"
      :secondaryClick="cancel"
    >
      <p class="usa-prose">Are you sure you want to delete this comment?</p>
    </basic-dialog>
    <success-toast
      v-bind="$attrs"
      id="comment-deleted-toast"
      message="The comment has successfully been deleted."
    ></success-toast>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";

@Component({
  components: {
    BasicDialog,
  },
})
export default class DeleteRoleDialog extends Vue {
  cancel(): void {
    this.$bvModal.hide("delete-comment-dialog");
  }
  confirm(): void {
    this.$emit("confirm");
    this.$bvModal.hide("delete-comment-dialog");
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.usa-prose {
  font-weight: bold;
  margin-top: 1.5rem;
}
</style>
