import { SiteDocument, StagedFile } from "@/dataModel";

export class StagedFileWithDocumentMatch {
  file: StagedFile;
  document?: SiteDocument;

  public constructor(file: StagedFile, document?: SiteDocument) {
    this.file = file;
    this.document = document;
  }
}
