<template>
  <basic-dialog
    id="evaluation-criteria-dialog"
    title="Evaluation Criteria"
    primaryButton="Close"
    :primaryClick="close"
    :isDisabled="false"
  >
    <p>
      <i>*Some properties may have more specific criteria. If these particulars are desired, please contact DHS S&T.</i>
    </p>
    <div v-for="(rating, index) in evaluationRatings" :key="index">
      <p class="usa-prose">
        <strong>{{ rating.name }}</strong> - {{ rating.description }}
      </p>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import EvaluationRating from "@/dataModel/hazardData/evaluationRating";

@Component({
  components: {
    BasicDialog,
  },
})
export default class EvaluationCriteriaDialog extends Vue {
  @Prop({ required: true }) evaluationRatings!: EvaluationRating[];

  close(): void {
    this.$bvModal.hide("evaluation-criteria-dialog");
  }
}
</script>

<style scoped lang="scss"></style>
