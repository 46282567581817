<template>
  <main id="main-content">
    <section class="grid-container usa-section">
      <div class="grid-row grid-gap">
        <div class="grid-col-12" role="alert" aria-live="assertive">
          <h1 class="font-heading-xl margin-bottom-3">Permissions Required</h1>
          <div class="font-body-sm">
            <p>The roles this user is assigned do not have sufficient permissions to access this site.</p>
            <p>
              Please
              <a
                v-if="adminContactInfo"
                class="usa-link"
                :href="`mailto:${adminContactInfo}?subject=Permissions Error When Accessing HKMA`"
              >
                contact an administrator</a
              >
              <template v-else>contact an administrator</template>
              to assign at least one User Portal Access permission.
            </p>
            <p v-if="feedbackFormUrl">
              To help us improve the HKMA, please also consider
              <a class="usa-link" :href="feedbackFormUrl" target="_blank">submitting a bug report</a>.
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import { AppConfig } from "@/dataModel";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class PermissionsErrorPage extends Vue {
  private readonly appConfig = container.get<AppConfig>(serviceTypes.AppConfig);

  get feedbackFormUrl(): string {
    return this.appConfig.errorPageSettings.feedbackFormUrl;
  }

  get adminContactInfo(): string {
    return this.appConfig.errorPageSettings.adminContactInfo?.join(";") ?? "";
  }
}
</script>
