<template>
  <main id="main-content">
    <template v-if="!isAuthorized">
      <div id="section-background" class="usa-section--dark">
        <div id="background-logo-container" class="hero">
          <section class="grid-container usa-section">
            <div class="grid-row grid-gap">
              <div class="desktop:grid-col-5">
                <h1 class="font-heading-2xl margin-top-0 margin-bottom-2">
                  Discover Hazard Information and Applications
                </h1>
                <ul class="usa-button-group">
                  <li class="usa-button-group__item">
                    <button
                      id="enter-hkma-button"
                      class="usa-button text-primary-dark bg-white hover:bg-primary-vivid"
                      @click="login"
                    >
                      Enter HKMA
                    </button>
                  </li>
                </ul>
                <span> </span>
              </div>
              <div class="desktop:grid-col-6 desktop:grid-offset-1 usa-prose">
                <p>
                  The Hazard Knowledge Management &amp; Analysis (HKMA) portal contains hazard data, reports, and links
                  to applications that support hazard research and analysis. The Science and Technology Directorate
                  Hazard Knowledge Center (HKC) maintains the portal and its data. The HKC is continually expanding and
                  incorporating new information and resources into the HKMA. Please check back frequently to discover
                  new resources.
                </p>
                <p>Request access by clicking the "Enter HKMA" button and logging in with your credentials.</p>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section class="usa-graphic-list usa-section">
        <div class="grid-container">
          <div class="usa-graphic-list__row grid-row grid-gap">
            <div class="usa-media-block tablet:grid-col">
              <span class="fa-stack fa-4x usa-media-block__img">
                <img
                  id="documents-logo"
                  src="../../assets/img/Documents-enclosed.svg"
                  role="img"
                  alt
                  aria-hidden="true"
                />
              </span>
              <div class="usa-media-block__body">
                <h2 class="usa-graphic-list__heading">Documents</h2>
                <p>
                  The HKMA has a large number of documents from sources that include books, journals, government
                  reports, and more. Easily search for hazard information using HKMA's advanced search capability. Most
                  of the documents are available for viewing and download with a few exceptions due to copyright and
                  distribution restrictions. Documents are primarily in PDF format.
                </p>
              </div>
            </div>
          </div>
          <div class="usa-graphic-list__row grid-row grid-gap">
            <div class="usa-media-block tablet:grid-col">
              <span class="fa-stack fa-4x usa-media-block__img">
                <img
                  id="applications-logo"
                  src="../../assets/img/Applications-Enclosed.svg"
                  role="img"
                  alt
                  aria-hidden="true"
                />
              </span>
              <div class="usa-media-block__body">
                <h2 class="usa-graphic-list__heading">Applications</h2>
                <p>
                  The Department of Homeland Security Science &amp; technology directorate's Probabilistic Assessment of
                  National Threats Hazards and Risks (PANTHR) program has developed various applications to support
                  risk-informed decisions about CBRNE threats and hazards. The HKMA contains links and descriptions of
                  the various applications to help people discover and access the various applications that exist.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <inactive-dialog />
    </template>
    <template v-else>
      <authorized-welcome-page />
    </template>
  </main>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import IAuthService from "@/services/interfaces/IAuthService";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import InactiveDialog from "@/components/navigation/InactiveDialog.vue";
import AuthorizedWelcomePage from "@/components/navigation/AuthorizedWelcomePage.vue";
import UserStatus from "@/constants/UserStatus";

@Component({
  components: {
    InactiveDialog,
    AuthorizedWelcomePage,
  },
})
export default class Home extends Vue {
  private auth = container.get<IAuthService>(serviceTypes.AuthService);
  public currentUser: string = "";
  public accessTokenExpired: boolean | undefined = false;
  public isAuthorized: boolean = false;
  public showInactiveAlert = false;

  get username(): string {
    return this.currentUser;
  }

  public login() {
    this.auth.login().then(
      () => {},
      (error) => {
        console.error(error);
        this.$router.push("/login-error");
      },
    );
  }

  public logout() {
    this.auth.logout();
  }

  public created() {
    const authState = this.auth.getAuthState();
    let isLoggedIn = authState?.isAuthenticated ?? false;
    let isApproved = authState?.user?.userStatus == UserStatus.ACTIVE;
    this.isAuthorized = isLoggedIn && isApproved;
  }

  public mounted() {
    if (localStorage.getItem("showInactiveDialog") === "yes") {
      this.$bvModal.show("inactive-dialog");
      localStorage.setItem("showInactiveDialog", "no"); // reset the flag
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.usa-prose > p {
  max-width: none;
}

.fa-stack {
  width: 2em;
}

#section-background {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #162e51;

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-image: url(../../assets/img/keyboard.jpg);
    background-size: cover;
    background-position: bottom center;
    opacity: 11%;
    background-repeat: no-repeat;
  }
}

#enter-hkma-button {
  width: 160px;
  margin-top: 60px;
  &:hover {
    color: #1a4480; //primary-dark
  }
  &:active {
    color: #162e51; //primary-darker
  }
}
</style>
