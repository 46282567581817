import HttpHelper from "@/components/resources/httpHelper";
import { inject, injectable } from "inversify";
import serviceTypes from "@/dependencyInjection/types";
import { toCamelCase } from "@/services/utils";
import {
  NewFeaturedDocumentRequest,
  NewFeaturedHazardDataRequest,
  ModifyFeaturedDocumentRequest,
  ModifyFeaturedHazardDataRequest,
  AddPooledFeaturedDataViewRequest,
} from "@/dataModel/requests";
import { PooledFeaturedDataView } from "@/dataModel/interfaces";
import { FeaturedDocument, FeaturedHazardData } from "@/dataModel";

const featuredResourceAdminUrl = "admin/featured-resources";
const featuredResourceUrl = "featured-resources";
const featuredDocumentsUrl = "/documents";
const featuredDataUrl = "/hazard-data";
const dataViewUrl = "/pooled-hazard-data-view";

@injectable()
export default class FeaturedResourceHttpHelper {
  httpHelper: HttpHelper;

  constructor(@inject(serviceTypes.HttpHelper) httpHelper: HttpHelper) {
    this.httpHelper = httpHelper;
  }

  //#region  Featured documents

  async getFeaturedDocuments(limit: number): Promise<FeaturedDocument[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(featuredResourceUrl + `/featured-documents?limit=${limit}`)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + "retrieving featured documents",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while retrieving featured documents"));
        });
    });
  }

  async getAllFeaturedDocuments(): Promise<FeaturedDocument[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(featuredResourceAdminUrl + featuredDocumentsUrl)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " +
                  response.status +
                  " : " +
                  response.statusText +
                  "retrieving all featured documents",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while retrieving all featured documents"));
        });
    });
  }

  async addFeaturedDocument(request: NewFeaturedDocumentRequest): Promise<FeaturedDocument[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(featuredResourceAdminUrl + featuredDocumentsUrl, request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + "adding featured document",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while adding featured document"));
        });
    });
  }

  async updateFeaturedDocuments(request: ModifyFeaturedDocumentRequest): Promise<FeaturedDocument[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPut(featuredResourceAdminUrl + featuredDocumentsUrl + "/update", request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + "updating featured document",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while updating featured document"));
        });
    });
  }

  async deleteFeaturedDocuments(request: ModifyFeaturedDocumentRequest): Promise<FeaturedDocument[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPut(featuredResourceAdminUrl + featuredDocumentsUrl + "/delete", request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + "deleting featured document",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while deleting featured document"));
        });
    });
  }

  //#endregion

  //#region  Featured hazard data

  async getFeaturedHazardData(limit: number): Promise<FeaturedHazardData[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(featuredResourceUrl + `/featured-hazard-data?limit=${limit}`)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(this.mapResponseToFeaturedData(response.data));
          } else {
            reject(
              new Error(`Response returned ${response.status}: ${response.statusText} retrieving featured hazard data`),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while retrieving featured hazard data"));
        });
    });
  }

  async getAllFeaturedHazardData(): Promise<FeaturedHazardData[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(featuredResourceAdminUrl + featuredDataUrl)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(this.mapResponseToFeaturedData(response.data));
          } else {
            reject(
              new Error(
                `Response returned ${response.status}: ${response.statusText} retrieving all featured hazard data`,
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while retrieving all featured data"));
        });
    });
  }

  async addFeaturedHazardDataAsync(request: NewFeaturedHazardDataRequest): Promise<FeaturedHazardData[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(featuredResourceAdminUrl + featuredDataUrl, request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(`Response returned ${response.status}: ${response.statusText} adding featured hazard data`),
            );
          }
        })
        .catch((error) => {
          console.error(error.response);
        });
    });
  }

  async updateFeaturedHazardDataAsync(request: ModifyFeaturedHazardDataRequest): Promise<FeaturedHazardData[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPut(featuredResourceAdminUrl + featuredDataUrl, request)
        .then((response: any) => {
          if (response.status === 200) {
            const featuredDataList = response.data.map((d: any) => FeaturedHazardData.FromApiResponse(d));
            resolve(featuredDataList);
          } else {
            reject(
              new Error(`Response returned ${response.status}: ${response.statusText} updating featured hazard data`),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while updating featured hazard data"));
        });
    });
  }

  async deleteFeaturedHazardDataAsync(request: ModifyFeaturedHazardDataRequest): Promise<FeaturedHazardData[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPut(featuredResourceAdminUrl + featuredDataUrl + "/delete", request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(`Response returned ${response.status}: ${response.statusText} deleting featured hazard data`),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while deleting featured document"));
        });
    });
  }

  //#endregion

  //#region  Pooled data views

  async addPooledFeaturedDataViewAsync(request: AddPooledFeaturedDataViewRequest): Promise<PooledFeaturedDataView> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(featuredResourceAdminUrl + dataViewUrl, request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                `Response returned ${response.status}: ${response.statusText} adding data view to featured data pool`,
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while adding data view to featured data pool"));
        });
    });
  }

  async getPooledFeaturedDataViewsAsync(): Promise<PooledFeaturedDataView[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(featuredResourceAdminUrl + dataViewUrl)
        .then((response: any) => {
          if (response.status === 200) {
            const dataViews: PooledFeaturedDataView[] = response.data.map((v) => {
              const view = v as PooledFeaturedDataView;
              view.dataViewSearchParameters = JSON.parse(v.dataViewSearchParameters, toCamelCase);
              return view;
            });

            resolve(dataViews);
          } else {
            reject(new Error(`Response returned ${response.status}: ${response.statusText} getting pooled data views`));
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting pooled data views"));
        });
    });
  }

  async getPooledFeaturedDataViewByIdAsync(id: string): Promise<PooledFeaturedDataView> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(featuredResourceAdminUrl + dataViewUrl + `/${id}`)
        .then((response: any) => {
          if (response.status === 200) {
            const dataView = response.data as PooledFeaturedDataView;
            dataView.dataViewSearchParameters = JSON.parse(response.data.dataViewSearchParameters, toCamelCase);
            resolve(dataView);
          } else {
            reject(
              new Error(
                `Response returned ${response.status}: ${response.statusText} getting pooled data view with id: ${id}`,
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error.response);
        });
    });
  }

  async deletePooledFeaturedDataViewAsync(id: string): Promise<PooledFeaturedDataView> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpDelete(`${featuredResourceAdminUrl}${dataViewUrl}/${id}`)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(`Response returned ${response.status}: ${response.statusText} deleting pooled data view ${id}`),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while deleting pooled data view"));
        });
    });
  }

  //#endregion

  private mapResponseToFeaturedData(responseData: object[]): FeaturedHazardData[] {
    return responseData.map((d: any) => {
      const featData = FeaturedHazardData.FromApiResponse(d);
      if (d.hazardDataView) {
        featData.hazardDataView!.searchParameters = JSON.parse(d.hazardDataView.dataSearchParameters, toCamelCase);
      }
      return featData;
    });
  }
}
