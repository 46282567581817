<template>
  <div>
    <h1 class="font-heading-xl text-primary-dark mt-0 mb-3">{{ titleWithClassificationMarking }}</h1>
    <h3 class="font-heading-lg text-primary-dark mt-0 mb-3">{{ dataCollection.overallClassificationMarking }}</h3>
    <div class="grid-row grid-gap">
      <div class="grid-col-2">
        <p class="usa-prose">
          <span class="text-bold">Author: </span>
        </p>
        <p class="usa-prose">
          <span class="text-bold">Publication Date: </span>
        </p>
        <p class="usa-prose">
          <span class="text-bold">Last Updated: </span>
        </p>
        <button class="usa-button usa-button--unstyled mb-3" @click="viewSiteDocument">View Document</button>
      </div>
      <div class="grid-col">
        <p class="usa-prose">{{ dataCollection.contributorDisplay }}</p>
        <p class="usa-prose">{{ getDocumentDatetimeDisplay(dataCollection.documentDateTime) }}</p>
        <p class="usa-prose">{{ dataCollection.modifiedDateTime | formatDate }}</p>
      </div>
    </div>
    <error-toast id="error-loading-document" message="Unable to retrieve the document" />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import DataSource from "@/dataModel/hazardData/dataSource";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import SiteDocumentHttpHelper from "@/components/resources/siteDocumentHttpHelper";
import { SiteDocument } from "@/dataModel";
import ErrorToast from "@/components/common/ErrorToast.vue";

@Component({
  components: {
    ErrorToast,
  },
})
export default class DataCollectionDetailsMetadata extends Vue {
  @Prop({ required: true }) dataCollection!: DataSource;

  private siteDocumentHttpHelper = container.get<SiteDocumentHttpHelper>(serviceTypes.SiteDocumentHttpHelper);

  get titleWithClassificationMarking(): string {
    return this.dataCollection.titleClassificationMarking + " " + this.dataCollection.title;
  }

  getDocumentDatetimeDisplay(datetime: Date | null): string {
    return datetime !== null
      ? this.$options.filters?.formatDate(datetime, undefined, true) // display as UTC
      : "Not Available";
  }

  async viewSiteDocument(): Promise<void> {
    try {
      const response: SiteDocument = (
        await this.siteDocumentHttpHelper.getDocumentsByKnowledgeResourceIds(
          new Array(this.dataCollection.knowledgeResourceId),
        )
      )[0].document;
      this.$router.push({ name: "DocumentSummary", query: { id: response.id } });
    } catch {
      this.$bvToast.show("error-loading-document");
    }
  }
}
</script>

<style scoped lang="scss"></style>
