<template>
  <div>
    <ul class="usa-button-group">
      <li :class="`usa-button-group__item ${size === 'lg' ? 'maxw-card' : ''}`">
        <button
          class="usa-button"
          @click="primaryButtonAction"
          :disabled="isLoading || primaryButtonDisabled"
          :aria-disabled="isLoading || primaryButtonDisabled"
          :aria-label="primaryButtonText"
          id="primary-button"
        >
          {{ primaryButtonText }}
        </button>
      </li>
      <template v-if="secondaryButtonText">
        <li :class="`usa-button-group__item ${size === 'lg' ? 'maxw-card' : ''}`">
          <button
            class="usa-button usa-button--outline"
            @click="secondaryButtonAction"
            :disabled="secondaryButtonDisabled"
            :aria-disabled="secondaryButtonDisabled"
            :aria-label="secondaryButtonText"
            id="secondary-button"
          >
            {{ secondaryButtonText }}
          </button>
        </li>
      </template>
    </ul>

    <div id="spinner-container" v-if="isLoading" class="d-flex">
      <spinner class="spinner-sm loading-spinner" />
      <span id="loading-text">{{ loadingText }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import Spinner from "@/components/common/Spinner.vue";

@Component({ components: { Spinner } })
export default class LoadingButtons extends Vue {
  @Prop({ default: "" }) loadingText!: string;
  @Prop({ default: "" }) primaryButtonText!: string;
  @Prop({ default: "" }) secondaryButtonText!: string;
  @Prop({ default: false }) primaryButtonDisabled!: boolean;
  @Prop({ default: false }) secondaryButtonDisabled!: boolean;
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({ default: false }) done!: boolean;
  @Prop({ default: "" }) size!: string;

  primaryButtonAction() {
    this.$emit("primaryButtonClick");
  }

  secondaryButtonAction() {
    this.$emit("secondaryButtonClick");
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

.usa-button-group {
  margin-left: 0;
  margin-right: 0;
}

.usa-button-group__item {
  margin-left: 0;
  margin-right: units(4);
  flex: 1 1 0;
}

.usa-button {
  width: 100%;
}

#spinner-container {
  align-items: center;
  margin-top: 7px;
  margin-left: 7px;
}

#loading-text {
  margin-left: 5px;
}
</style>
