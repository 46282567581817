import HazardDateTimeDetails from "./common/hazardDateTimeDetails";
import HazardPropertyDetails from "./hazardPropertyDetails";

export default class DataSource extends HazardDateTimeDetails {
  id: string;
  knowledgeResourceId: string;
  title: string;
  titleClassificationMarking: string;
  overallClassificationMarking: string;
  documentDateTime: Date;
  contributorDisplay: string;
  properties: HazardPropertyDetails[];
  approvedDataValueCount: number;
  awaitingApprovalDataValueCount: number;

  constructor(
    id?: string,
    knowledgeResourceId?: string,
    title?: string,
    titleClassificationMarking?: string,
    overallClassificationMarking?: string,
    documentDateTime?: Date,
    contributorDisplay?: string,
    modifiedDateTime?: Date,
    properties?: HazardPropertyDetails[],
    approvedDataValueCount?: number,
    awaitingApprovalDataValueCount?: number,
  ) {
    super();
    this.id = id ?? "";
    this.knowledgeResourceId = knowledgeResourceId ?? "";
    this.title = title ?? "";
    this.titleClassificationMarking = titleClassificationMarking ?? "";
    this.overallClassificationMarking = overallClassificationMarking ?? "";
    this.documentDateTime = documentDateTime ?? new Date();
    this.contributorDisplay = contributorDisplay ?? "";
    this.modifiedDateTime = modifiedDateTime ?? new Date();
    this.properties = properties ?? [];
    this.approvedDataValueCount = approvedDataValueCount ?? 0;
    this.awaitingApprovalDataValueCount = awaitingApprovalDataValueCount ?? 0;
  }
}
