<template>
  <main id="main-content">
    <section class="usa-section usa-section--dark pt-4 pb-4 mb-5">
      <div class="grid-container">
        <div class="grid-row grid-gap flex-align-center usa-prose">
          <div class="panthr-logo-col">
            <img
              class="panthr-icon"
              src="../../assets/img/PANTHR-Logo.png"
              role="img"
              alt="Panthr Logo"
              aria-hidden="true"
            />
          </div>
          <div class="grid-col pl-0">
            <h1 class="font-heading-xl text-semibold mt-0 mb-3">PANTHR Applications</h1>
            <div>
              <p class="usa-prose mb-0">
                The Probabilistic Analysis for National Threats Hazards and Risks (PANTHR) program has developed a suite
                of applications for interacting with CBRN data and research. Some applications require you to submit a
                request for access.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="grid-container">
        <div class="app-list">
          <p class="text-semibold" v-if="isLoading">Loading...</p>
          <p class="text-semibold" v-if="applications && applications.length == 0 && !isLoading">
            There are currently no applications available. Please check back soon.
          </p>
          <ul v-else class="usa-card-group">
            <div class="grid-row" v-for="(app, index) in applications" :key="index">
              <application-card
                :name="app.displayName"
                :description="app.descriptionParagraphs[0]"
                :iconFileName="app.imagePath"
                :loginUrl="app.loginUrl"
              />
            </div>
          </ul>
        </div>
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Application } from "@/dataModel";
import ApplicationHttpHelper from "@/components/resources/applicationHttpHelper";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import { BreadcrumbPathItem } from "@/dataModel/interfaces";
import ApplicationCard from "@/components/common/ApplicationCard.vue";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";

@Component({
  components: {
    Breadcrumb,
    ApplicationCard,
  },
})
export default class Applications extends Vue {
  public applications: Application[] = [];
  public isLoading: boolean = true;

  path: BreadcrumbPathItem[] = [{ text: "Home", url: "/home" }, { text: "Applications" }];

  private applicationHttpHelper = container.get<ApplicationHttpHelper>(serviceTypes.ApplicationHttpHelper);

  async getAllApplications(): Promise<void> {
    try {
      this.applications = await this.applicationHttpHelper.getAllApplications();
    } finally {
      this.isLoading = false;
    }
  }

  public created(): void {
    this.getAllApplications();
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

.app-list {
  ul {
    list-style-type: none;
    padding: 0px;
  }
}

.text-semibold {
  font-weight: 700;
}

.panthr-icon {
  width: 11rem;
  padding: 1rem;
  filter: drop-shadow(0px 6px 5px #0b0b0b);
  -webkit-filter: drop-shadow(0px 6px 5px #0b0b0b);
}
</style>
