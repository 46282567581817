import { render, staticRenderFns } from "./Breadcrumb.vue?vue&type=template&id=4fe2ddb2&scoped=true"
import script from "./Breadcrumb.vue?vue&type=script&lang=ts"
export * from "./Breadcrumb.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fe2ddb2",
  null
  
)

export default component.exports