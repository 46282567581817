import HttpHelper from '@/components/resources/httpHelper'
import { inject, injectable } from 'inversify';
import serviceTypes from '@/dependencyInjection/types';
import UncertaintyMeasure from '@/dataModel/hazardData/uncertaintyMeasure';

const hazardDataUncertaintyMeasuresUrl = "hazard-data/uncertainty-measures/";

@injectable()
export default class HazardDataUncertaintyMeasureHttpHelper {
    httpHelper: HttpHelper;

    constructor(@inject(serviceTypes.HttpHelper) httpHelper: HttpHelper) {
        this.httpHelper = httpHelper;
    }

    async getUncertaintyMeasures(): Promise<UncertaintyMeasure[]> {
        return new Promise((resolve, reject) => {
            this.httpHelper.httpGet(hazardDataUncertaintyMeasuresUrl).then((response: any ) => {
                if(response.status === 200){
                    resolve(response.data);
                } else {
                    reject(new Error("Response returned " + response.status + " : " + response.statusText + " getting " +
                        "hazard data uncertainty measures"));
                }
            }).catch(error => {
                console.error(error);
                reject(new Error("An error occurred while getting hazard data uncertainty measures"));
            });
        });
    }
}