import { render, staticRenderFns } from "./PdfViewer.vue?vue&type=template&id=6b92bbbf&scoped=true"
import script from "./PdfViewer.vue?vue&type=script&lang=ts"
export * from "./PdfViewer.vue?vue&type=script&lang=ts"
import style0 from "pdfjs-dist/web/viewer.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./PdfViewer.vue?vue&type=style&index=1&id=6b92bbbf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b92bbbf",
  null
  
)

export default component.exports