export enum PermissionStoreActions {
    SET_CLASSIFICATIONS = 'SET_CLASSIFICATIONS',
    SET_DISSEMINATION_CONTROLS = 'SET_DISSEMINATION_CONTROLS',
    SET_SCIS = 'SET_SCIS',
    SET_AUDIENCES = 'SET_AUDIENCES',
}

export enum PermissionStoreGetters {
    GET_CLASSIFICATIONS = 'GET_CLASSIFICATIONS',
    GET_DISSEMINATION_CONTROLS = 'GET_DISSEMINATION_CONTROLS',
    GET_SCIS = 'GET_SCIS',
    GET_AUDIENCES = 'GET_AUDIENCES',
}

export enum PermissionStoreMutations {
    SET_CLASSIFICATIONS = 'SET_CLASSIFICATIONS',
    SET_DISSEMINATION_CONTROLS = 'SET_DISSEMINATION_CONTROLS',
    SET_SCIS = 'SET_SCIS',
    SET_AUDIENCES = 'SET_AUDIENCES',
}