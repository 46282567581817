<template>
  <basic-dialog id="view-users-dialog" size="lg" hide-footer v-bind="$attrs">
    <div class="grid-row">
      <div class="grid-col">
        <section aria-label="Small search component" class="margin-top-1">
          <form class="usa-search usa-search--small search-style" role="search" @submit.prevent="throttleSearchMethod">
            <label class="usa-sr-only" for="search-field-users">Search</label>
            <input
              class="usa-input"
              id="search-field-users"
              type="search"
              name="search"
              v-model="searchTerm"
              placeholder="Search for a user's name, email, or organization..."
            />
            <button class="usa-button" type="submit">
              <span class="usa-sr-only">Search</span>
            </button>
          </form>
        </section>
      </div>

      <div class="grid-col-auto pagination-container">
        <table-pagination
          class="pagination pagination-top"
          @prev="prevPage"
          @next="nextPage"
          :page="page"
          :hasNextPage="hasNextPage"
        />
      </div>
    </div>
    <data-table
      @sortingChanged="sortingChanged"
      :items="users"
      :fields="mockHeaders"
      :perPage="perPage"
      :page="page"
      ref="userTable"
    >
      <template v-slot:cell(fullName)="row">
        <div class="margin-y-05 table-col-sm overflow-ellipsis">
          {{ row.item.firstName + " " + row.item.lastName }}
        </div>
      </template>
      <template v-slot:cell(email)="data">
        <div class="margin-y-05 table-col-md overflow-ellipsis">
          {{ data.value }}
        </div>
      </template>
      <template v-slot:cell(organization)="data">
        <div class="margin-y-05 table-col-md overflow-ellipsis">
          {{ data.value }}
        </div>
      </template>
    </data-table>
    <table-pagination class="pagination" @prev="prevPage" @next="nextPage" :page="page" :hasNextPage="hasNextPage" />
  </basic-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import { User, Role, AppConfig } from "@/dataModel";
import DataTable from "@/components/common/DataTable.vue";
import TablePagination from "@/components/common/TablePagination.vue";
import UserHttpHelper from "@/components/resources/userHttpHelper";
import { DataTableHeader } from "@/dataModel/interfaces";
import { throttle } from "lodash";

@Component({
  components: {
    BasicDialog,
    DataTable,
    TablePagination,
  },
})
export default class ViewAssignedUsersDialog extends Vue {
  @Prop() readonly role!: Role;

  perPage: string = "20";
  page: number = 1;
  users: User[] = [];
  resultCount: number = 0;
  searchTerm: string = "";
  statusFilters: string[] = [];
  sortOrder: string = "ascending";
  sortColumn: string = "fullName"; // Change
  throttleSearchMethod: any;

  private userHttpHelper = container.get<UserHttpHelper>(serviceTypes.UserHttpHelper);
  private appConfig = container.get<AppConfig>(serviceTypes.AppConfig);

  mockHeaders: DataTableHeader[] = [
    {
      label: "Name",
      key: "fullName",
      sortable: true,
    },
    {
      label: "Email",
      key: "email",
      sortable: true,
    },
    {
      label: "Organization",
      key: "organization",
      sortable: true,
    },
  ];

  async searchUsers(): Promise<void> {
    const currentPage = this.page - 1;
    const perPage: number = Number(this.perPage);
    const response = await this.userHttpHelper.searchUsers(
      currentPage,
      perPage,
      this.searchTerm,
      this.sortOrder,
      this.sortColumn,
      this.statusFilters,
      this.role.id,
    );
    this.users = response.result;
    this.resultCount = response.count;
  }

  search(): void {
    if (this.searchTerm.trim() !== "") {
      this.sortColumn = "relevance";
      this.sortOrder = "ascending";
    }
    this.searchUsers();
    this.sortColumn = "email";
  }

  prevPage(): void {
    if (this.page > 1) {
      this.page--;
      this.searchUsers();
    }
  }

  nextPage(): void {
    if (this.hasNextPage) {
      this.page++;
      this.searchUsers();
    }
  }

  get hasNextPage(): boolean {
    return this.page < this.resultCount / Number(this.perPage);
  }

  sortingChanged(ctx: any): void {
    this.sortColumn = ctx.sortBy;
    this.sortOrder = ctx.sortDesc ? "descending" : "ascending";
    this.searchUsers();
  }

  created(): void {
    this.throttleSearchMethod = throttle(async () => {
      this.search();
    }, this.appConfig.searchRequestMinimumIntervalInMilliseconds);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.pagination {
  float: right;
}

.pagination-container {
  margin-top: 0.5rem;
}

.search-style {
  width: 70%;
}
</style>
