import DataValueIdentifierInformation from "./dataValueIdentifierInformation";

export default class GetBoxPlotDataRequest {
  property: string;
  hazardAgents: string[];
  selectedOption: DataValueIdentifierInformation;
  categoryFilters: string[];
  sourceFilters: string[];
  evaluationRatingFilters: string[];

  constructor(
    property: string,
    hazardAgents: string[],
    selectedOption: DataValueIdentifierInformation,
    categoryFilters?: string[],
    sourceFilters?: string[],
    evaluationRatingFilters?: string[],
  ) {
    this.property = property;
    this.hazardAgents = hazardAgents;
    this.selectedOption = selectedOption;
    this.categoryFilters = categoryFilters ?? [];
    this.sourceFilters = sourceFilters ?? [];
    this.evaluationRatingFilters = evaluationRatingFilters ?? [];
  }
}
