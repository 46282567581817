import HttpHelper from "@/components/resources/httpHelper";
import { inject, injectable } from "inversify";
import serviceTypes from "@/dependencyInjection/types";
import { FilterNodeInformation, BoxPlotSeries } from "@/dataModel";
import { SearchResponse } from "@/dataModel/responses";
import DataValueOption from "@/dataModel/hazardData/dataValueOption";
import { HazardType } from "@/constants/HazardType";
import DataValueResult from "@/dataModel/hazardData/dataValueResult";
import GetDataValuesRequest from "@/dataModel/hazardData/getDataValuesRequest";
import IDataValueFilterRequest from "@/dataModel/hazardData/interfaces/IDataValueFilterRequest";
import DataValueFilterParent from "@/dataModel/hazardData/dataValueFilterParent";
import GetBoxPlotDataRequest from "@/dataModel/hazardData/getBoxPlotDataRequest";
import ExportDataRequest from "@/dataModel/hazardData/exportDataRequest";
import IDataValueResultsRequest from "@/dataModel/hazardData/interfaces/IDataValueResultsRequest";

const hazardDataSearchUrl = "hazard-data/search/";

@injectable()
export default class HazardDataSearchHttpHelper {
  httpHelper: HttpHelper;

  constructor(@inject(serviceTypes.HttpHelper) httpHelper: HttpHelper) {
    this.httpHelper = httpHelper;
  }

  async GetHazardDataTaxonomyAsync(
    hazardType: HazardType,
    propertyId: string | null = "",
  ): Promise<FilterNodeInformation> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(hazardDataSearchUrl + `taxonomy/${hazardType}/${propertyId ?? ""}`)
        .then((response: any) => {
          if (response.status === 200) {
            const rootNode = new FilterNodeInformation(
              response.data.value,
              response.data.stringValue,
              response.data.path,
              response.data.children,
            );
            resolve(rootNode);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " getting hazard data taxonomy",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting hazard data taxonomy"));
        });
    });
  }

  async GetHazardDataOptionsAsync(propertyId: string = ""): Promise<DataValueOption[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(hazardDataSearchUrl + `options/${propertyId}`)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " getting hazard data options",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting hazard data options"));
        });
    });
  }

  async GetHazardDataValuesAsync(request: GetDataValuesRequest): Promise<SearchResponse<DataValueResult>> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(hazardDataSearchUrl + "data-values", request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(`Response returned ${response.status}: ${response.statusText} getting hazard data values`),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting hazard data values"));
        });
    });
  }

  GetHazardDataValueResultsByIdAsync(request: IDataValueResultsRequest): Promise<DataValueResult> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(hazardDataSearchUrl + "data-values/results", request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                `Response returned ${response.status}: ${response.statusText} getting hazard data value results`,
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error(`An error occurred while getting hazard data value results for '${request.dataValueId}'`));
        });
    });
  }

  async GetHazardDataFiltersAsync(request: IDataValueFilterRequest): Promise<DataValueFilterParent[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(hazardDataSearchUrl + "filters", request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " getting hazard data filters",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting hazard data filters"));
        });
    });
  }

  async GetHazardBoxPlotDataAsync(request: GetBoxPlotDataRequest): Promise<BoxPlotSeries[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(hazardDataSearchUrl + "box-plot-series", request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error(`Response returned ${response.status}: ${response.statusText} getting box plot data`));
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting box plot data"));
        });
    });
  }

  async ExportDataAsync(request: ExportDataRequest): Promise<string> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(hazardDataSearchUrl + "export-data", request, undefined, undefined, "blob")
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error(`Response returned ${response.status}: ${response.statusText} exporting data`));
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while exporting data"));
        });
    });
  }
}
