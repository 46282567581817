export class SearchSiteDocumentsRequest {
  page: number;
  resultsPerPage: number;
  searchTerm: string;
  sortOrder: string;
  sortColumn: string;
  classificationFilters: string[];
  disseminationControlFilters: string[];
  categoryFilters: string[];
  documentTypeFilters: string[];
  isCopyrightRestrictedFilters: boolean[];
  audienceFilters: string[];
  sciFilters: string[];
  isPublishedFilters: boolean[];

  constructor(
    page?: number,
    resultsPerPage?: number,
    searchTerm?: string,
    sortOrder?: string,
    sortColumn?: string,
    classificationFilters?: string[],
    disseminationControlFilters?: string[],
    categoryFilters?: string[],
    documentTypeFilters?: string[],
    isCopyrightRestrictedFilters?: boolean[],
    audienceFilters?: string[],
    sciFilters?: string[],
    isPublishedFilters?: boolean[],
  ) {
    this.page = page || 0;
    this.resultsPerPage = resultsPerPage || 0;
    this.searchTerm = searchTerm || "";
    this.sortOrder = sortOrder || "";
    this.sortColumn = sortColumn || "";
    this.classificationFilters = classificationFilters || [];
    this.disseminationControlFilters = disseminationControlFilters || [];
    this.categoryFilters = categoryFilters || [];
    this.documentTypeFilters = documentTypeFilters || [];
    this.isCopyrightRestrictedFilters = isCopyrightRestrictedFilters || [];
    this.audienceFilters = audienceFilters || [];
    this.sciFilters = sciFilters || [];
    this.isPublishedFilters = isPublishedFilters || [];
  }
}
