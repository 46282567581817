<template>
  <div>
    <basic-dialog
      id="download-file-dialog"
      title="Downloading File"
      primaryButton="Cancel Download"
      :primaryClick="cancel"
      :no-close-on-backdrop="true"
    >
      <div class="spinner-container">
        <p class="usa-prose">Please wait</p>
        <spinner class="spinner-sm margin-left-3" />
      </div>
    </basic-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import Spinner from "@/components/common/Spinner.vue";

@Component({
  components: {
    BasicDialog,
    Spinner,
  },
})
export default class DownloadFileDialog extends Vue {
  cancel(): void {
    this.$emit("cancelDownload");
    this.$bvModal.hide("download-file-dialog");
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.usa-prose {
  font-weight: bold;
  margin-top: 1.5rem;
}

.spinner-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
