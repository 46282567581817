<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <h1 class="font-heading-xl margin-top-0 margin-bottom-2">Role Management</h1>
      </div>
      <div class="grid-row grid-gap">
        <!-- Role selection -->
        <div class="desktop:grid-col-4">
          <form class="usa-form">
            <label class="usa-label" for="role">Select Role</label>
            <select
              @change="roleHasUsers = false"
              class="usa-select"
              name="role"
              id="role"
              ref="roleSelect"
              v-model="selectedRoleIndex"
            >
              <option :value="-1" disabled>-- Select a Role --</option>
              <option v-for="(role, index) in allRoles" :key="index" :value="index">{{ role.name }}</option>
            </select>
          </form>
        </div>

        <!-- Role action buttons -->
        <div class="desktop:grid-col-8">
          <ul class="usa-button-group">
            <li class="usa-button-group__item">
              <button id="viewUsersBtn" class="usa-button" :disabled="!isRoleSelected" @click="viewUserHandler">
                View Assigned Users
              </button>
            </li>
            <li class="usa-button-group__item">
              <button class="usa-button" :disabled="!isRoleSelected" @click="$bvModal.show('save-changes-dialog')">
                Save
              </button>
            </li>
            <li class="usa-button-group__item">
              <button class="usa-button" :disabled="!isRoleSelected" @click="$bvModal.show('confirm-delete-dialog')">
                Delete Role
              </button>
            </li>
            <li class="usa-button-group__item">
              <button class="usa-button usa-button--outline" @click="createNewRoleHandler">Create New Role</button>
            </li>
          </ul>
        </div>
      </div>

      <!-- Selected role header -->
      <div class="grid-row grid-gap">
        <span class="sub-header"
          >Role Permissions: <span v-if="isRoleSelected">{{ allRoles[selectedRoleIndex].name }}</span></span
        >
      </div>
      <hr class="permission-line-break" />

      <!-- Permission category groups -->
      <template v-if="selectedRoleIndex >= 0">
        <div v-for="category in allPermissions" :key="category.id">
          <div class="grid-row grid-gap">
            <span class="sub-header permission-header">
              {{ category.name }}
            </span>
            <span v-if="category.valueType === 'IntegerHierarchy' && radioSelectedId.length !== 0">
              <button class="usa-button usa-button--unstyled clear" @click="clearSelection">Clear Selection</button>
            </span>
          </div>
          <!-- Permission selections -->
          <div class="grid-row grid-gap" v-if="showPermissionCategorySection(category.id)">
            <div class="desktop:grid-col-4" v-for="permission in category.permissions" :key="permission.id">
              <div class="usa-checkbox" v-if="permission.value == null">
                <input
                  class="usa-checkbox__input"
                  :id="permission.id"
                  type="checkbox"
                  :value="permission.id"
                  v-model="checkboxSelectedIds"
                />
                <label class="usa-checkbox__label fit" :for="permission.id">
                  {{ permission.name }}
                </label>
              </div>
              <div class="usa-radio" v-else>
                <input
                  class="usa-radio__input"
                  :id="permission.id"
                  type="radio"
                  :value="permission.id"
                  v-model="radioSelectedId"
                />
                <label class="usa-radio__label fit" :for="permission.id">
                  {{ permission.name }}
                </label>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="text-italic text-base">
              Admin access must be applied before selecting permissions from this category
            </p>
          </div>
          <hr class="permission-line-break" />
        </div>
      </template>

      <!-- Dialogs -->
      <delete-role-dialog @confirm="deleteRoleAsync" :usersAssigned="roleHasUsers"></delete-role-dialog>
      <create-new-role-dialog @confirm="createRoleAsync($event)" ref="createNewRole"></create-new-role-dialog>
      <save-changes-dialog @confirm="updateRoleAsync" :hasError="isPermissionsEmpty"></save-changes-dialog>
      <view-assigned-users-dialog
        :title="selectedRole.name + ' Users'"
        ref="viewUsers"
        :role="selectedRole"
      ></view-assigned-users-dialog>

      <success-toast id="role-management-toast" :message="toastMessage" />
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import CreateNewRoleDialog from "@/components/navigation/RoleManagement/CreateNewRoleDialog.vue";
import DeleteRoleDialog from "@/components/navigation/RoleManagement/DeleteRoleDialog.vue";
import SaveChangesDialog from "@/components/navigation/RoleManagement/SaveChangesDialog.vue";
import ViewAssignedUsersDialog from "@/components/navigation/RoleManagement/ViewAssignedUsersDialog.vue";
import RoleHttpHelper from "@/components/resources/roleHttpHelper";
import UserHttpHelper from "@/components/resources/userHttpHelper";
import PermissionHttpHelper from "@/components/resources/permissionHttpHelper";
import { Role } from "@/dataModel";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import { BreadcrumbPathItem, PermissionCategory } from "@/dataModel/interfaces";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import PermCategoryToAdminPermMap from "@/constants/PermissionCategoryToAdminPermissionMap";
import { orderBy } from "lodash";
import SuccessToast from "@/components/common/SuccessToast.vue";

@Component({
  components: {
    CreateNewRoleDialog,
    DeleteRoleDialog,
    SaveChangesDialog,
    ViewAssignedUsersDialog,
    Breadcrumb,
    SuccessToast,
  },
})
export default class RoleManagementPage extends Vue {
  allRoles: Role[] = [];
  allPermissions: PermissionCategory[] = [];
  selectedRole: Role = new Role();
  selectedRoleIndex: number = -1;
  radioSelectedId: string = ""; // Hazard-type permission (single select)
  checkboxSelectedIds: string[] = []; // All other permission categories multi-select
  private roleHttpHelper = container.get<RoleHttpHelper>(serviceTypes.RoleHttpHelper);
  private permissionHttpHelper = container.get<PermissionHttpHelper>(serviceTypes.PermissionHttpHelper);
  private userHttpHelper = container.get<UserHttpHelper>(serviceTypes.UserHttpHelper);
  roleHasUsers: boolean = false;
  toastMessage = "";

  path: BreadcrumbPathItem[] = [
    {
      text: "Administration",
    },
    {
      text: "Role Management",
    },
  ];

  get isRoleSelected(): boolean {
    return this.selectedRoleIndex != null && this.selectedRoleIndex >= 0;
  }

  get isPermissionsEmpty(): boolean {
    return this.radioSelectedId == "" && this.checkboxSelectedIds.length === 0;
  }

  @Watch("selectedRoleIndex")
  selectionChanged() {
    if (this.isRoleSelected) {
      this.getRole().then(() => this.setRolePermissionIds());
    } else {
      this.selectedRole = new Role();
      this.clearPermissions();
    }
    this.roleHasUsers = false;
  }

  showPermissionCategorySection(categoryId: string): boolean {
    const adminAccessPermissionId: string = PermCategoryToAdminPermMap[categoryId];
    return (
      adminAccessPermissionId === undefined || this.checkboxSelectedIds.some((id) => id === adminAccessPermissionId)
    );
  }

  showToastWithMessage(message: string): void {
    this.toastMessage = message;
    this.$bvToast.show("role-management-toast");
  }

  async getAllRoleMetaData(): Promise<void> {
    const roles = await this.roleHttpHelper.getAllRoleMetaData();
    this.allRoles = roles;
  }

  async getAllPermissions(): Promise<void> {
    const permissions: PermissionCategory[] = await this.permissionHttpHelper.getPermissionCategoryAll();
    this.allPermissions = orderBy(
      permissions,
      (c: PermissionCategory) => c.name.toLowerCase() === "user portal access",
      "desc",
    );
  }

  async getRole(): Promise<void> {
    const roleId = this.allRoles[this.selectedRoleIndex].id;
    const role = await this.roleHttpHelper.getRole(roleId);
    this.selectedRole.id = role.id;
    this.selectedRole.name = role.name;
    this.selectedRole.permissions = role.permissions;
  }

  async updateRoleAsync(): Promise<void> {
    const roleId = this.allRoles[this.selectedRoleIndex].id;
    const updatedRole = await this.roleHttpHelper.updateRole(roleId, this.getSelectedPermissionIds());
    this.selectedRole.permissions = updatedRole.permissions;
    this.showToastWithMessage("The role has been successfully updated.");
  }

  async createRoleAsync(newRole: Role): Promise<void> {
    const role = await this.roleHttpHelper.createRole(newRole.name, newRole.permissions);
    this.allRoles.push(new Role(role.id, role.name, role.permissions));
    this.selectedRoleIndex = this.allRoles.findIndex((r) => r.id === role.id);
    this.showToastWithMessage("The role has been successfully created.");
  }

  async hasAssignedUsersAsync(): Promise<boolean> {
    // Only requires one page and one result to determine value
    const page = 1;
    const perPage = 1;
    const searchTerm = "";
    const sortColumn = "fullName";
    const sortOrder = "ascending";
    const filters = [];
    const roleFilter = this.allRoles[this.selectedRoleIndex].id;

    const response = await this.userHttpHelper.searchUsers(
      page,
      perPage,
      searchTerm,
      sortOrder,
      sortColumn,
      filters,
      roleFilter,
    );

    return response.count > 0;
  }

  async deleteRoleAsync(): Promise<void> {
    this.roleHasUsers = await this.hasAssignedUsersAsync();

    if (!this.roleHasUsers) {
      const roleId = this.allRoles[this.selectedRoleIndex].id;
      await this.roleHttpHelper.deleteRole(roleId);
      this.allRoles.splice(this.selectedRoleIndex, 1);
      this.selectedRoleIndex = -1;
      this.$bvModal.hide("confirm-delete-dialog");
      this.showToastWithMessage("The role has been successfully deleted.");
    } else {
      this.$bvModal.show("confirm-delete-dialog");
    }
  }

  setRolePermissionIds(): void {
    this.clearPermissions();
    this.selectedRole.permissions.forEach((permission) => {
      if (permission.value != null) {
        this.radioSelectedId = permission.id;
      } else {
        this.checkboxSelectedIds.push(permission.id);
      }
    });
  }

  getSelectedPermissionIds(): string[] {
    const ids = this.checkboxSelectedIds;
    if (this.radioSelectedId.length !== 0) {
      ids.push(this.radioSelectedId);
    }
    return ids;
  }

  clearSelection(): void {
    if (this.checkboxSelectedIds[0] === this.radioSelectedId) {
      this.checkboxSelectedIds.pop();
    }
    this.radioSelectedId = "";
  }

  clearPermissions(): void {
    this.radioSelectedId = "";
    this.checkboxSelectedIds.splice(0, this.checkboxSelectedIds.length);
  }

  createNewRoleHandler(): void {
    (<CreateNewRoleDialog>this.$refs.createNewRole).clearRole();
    this.$bvModal.show("create-role-dialog");
  }

  viewUserHandler(): void {
    (<ViewAssignedUsersDialog>this.$refs.viewUsers).search();
    this.$bvModal.show("view-users-dialog");
  }

  created(): void {
    this.getAllPermissions();
    this.getAllRoleMetaData();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

.font-heading-xl {
  color: color("primary-dark");
}

.sub-header {
  display: block;
  font-family: family("heading");
  font-size: size("heading", "md");
  line-height: lh("heading", 2);
  font-weight: font-weight("bold");
  color: color("primary-dark");
  margin-bottom: units(1);
  margin-top: units(3);
}

.clear {
  padding: 0;
  margin-top: units(2);
}

.usa-button-group {
  padding-top: 51px;
}

.permission-header {
  font-weight: font-weight("normal");
  margin-top: units(2);
}

.permission-line-break {
  margin: 0;
  margin-top: units(2);
  background: #c9c9c9;
}

.fit {
  width: fit-content;
}
</style>
