import HttpHelper from "@/components/resources/httpHelper";
import { inject, injectable } from "inversify";
import serviceTypes from "@/dependencyInjection/types";
import RequestStatus from "@/constants/RequestStatus";
import { Request, RequestEventLog, RequestFileMetadata } from "@/dataModel";
import { RequestFullInformation } from "@/dataModel/interfaces";
import { SearchResponse } from "@/dataModel/responses";
import { UploadDhsRequestFileRequest } from "@/dataModel/requests";
import { CancelTokenSource } from "axios";

const requestUrl = "dhs-requests/";

@injectable()
export default class RequestHttpHelper {
  httpHelper: HttpHelper;

  constructor(@inject(serviceTypes.HttpHelper) httpHelper: HttpHelper) {
    this.httpHelper = httpHelper;
  }

  async getRequest(requestId: string): Promise<RequestFullInformation> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(requestUrl + requestId)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error("Response returned " + response.status + " : " + response.statusText + " getting request"),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting request by id " + requestId));
        });
    });
  }

  async getRequestsByUserId(userId: string): Promise<Request[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(requestUrl + "user/" + userId)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " getting requests for user",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting requests by user id " + userId));
        });
    });
  }

  async updateRequestStatus(requestId: string, newStatus: RequestStatus): Promise<Request> {
    const data = {
      RequestId: requestId,
      NewStatus: newStatus,
    };

    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPut(requestUrl, data)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " updating request status",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while updating request status"));
        });
    });
  }

  async createRequest(request: Request): Promise<Request> {
    const data = {
      Title: request.title,
      Reason: request.reason,
      RequestType: request.requestType,
      DateNeededBy: request.dateNeededBy,
      Organization: request.organization,
      Email: request.email,
      PhoneNumber: request.phoneNumber,
    };

    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(requestUrl, data)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error("Response returned " + response.status + " : " + response.statusText + " creating request"),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while creating request"));
        });
    });
  }

  async addCommentToRequestLog(requestId: string, comment: string): Promise<RequestEventLog> {
    const data = {
      RequestId: requestId,
      Comment: comment,
    };

    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(requestUrl + "comment/", data)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " adding comment to request log",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while adding comment to request log"));
        });
    });
  }

  async deleteCommentFromRequestLog(requestId: string, logId: number): Promise<RequestEventLog> {
    const data = {
      RequestId: requestId,
      RequestEventLogId: logId,
    };

    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPut(requestUrl + "comment/", data)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " +
                  response.status +
                  " : " +
                  response.statusText +
                  " deleting comment from request log",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while deleting comment from request log"));
        });
    });
  }

  async addFileToRequest(
    request: UploadDhsRequestFileRequest,
    cancelToken: CancelTokenSource,
  ): Promise<RequestFileMetadata> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("File", request.file);
      formData.append("ClassificationId", request.classification);
      for (let i = 0; i < request.disseminationControlIds.length; i++) {
        formData.append(`DisseminationControlIds[${i}]`, request.disseminationControlIds[i]);
      }
      for (let i = 0; i < request.scis.length; i++) {
        formData.append(`SciIds[${i}]`, request.scis[i]);
      }
      formData.append("RequestId", request.dhsRequestId);
      formData.append("UploadRequestId", request.uploadRequestId);

      const headers = {
        "Content-Type": "multipart/form-data",
      };

      this.httpHelper
        .httpPost(`${requestUrl}file`, formData, headers, cancelToken)
        .then((response: any) => {
          if (response.status === 201) {
            resolve(response.data);
          } else {
            reject(new Error(`Response returned ${response.status}: ${response.statusText} adding file to request`));
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  async searchRequests(
    page: number,
    resultsPerPage: number,
    searchTerm: string,
    sortOrder: string,
    sortColumn: string,
    filters: string[],
  ): Promise<SearchResponse<Request>> {
    const data = {
      Page: page,
      ResultsPerPage: resultsPerPage,
      SearchTerm: searchTerm,
      SortOrder: sortOrder,
      SortColumn: sortColumn,
      RequestStatuses: filters,
    };

    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(requestUrl + "search/", data)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " searching dhs requests",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while searching dhs requests"));
        });
    });
  }

  async downloadFile(fileId: string, cancelToken: CancelTokenSource, convertToPdf: boolean): Promise<string> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(`${requestUrl}download/${fileId}/${convertToPdf}`, "arraybuffer", cancelToken)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error("Response returned " + response.status + " : " + response.statusText + " downloading file"),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while downloading file by file id " + fileId));
        });
    });
  }

  async downloadZipFile(requestId: string, cancelToken: CancelTokenSource): Promise<string> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(requestUrl + "download-zip/" + requestId, "arraybuffer", cancelToken)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error("Response returned " + response.status + " : " + response.statusText + " downloading zip file"),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while downloading zip file by request id " + requestId));
        });
    });
  }

  async getAllRequestFileMetadata(requestId: string): Promise<RequestFileMetadata[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(requestUrl + "files/" + requestId)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " getting file metadata",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting file metadata by request id " + requestId));
        });
    });
  }
}
