import HttpHelper from "@/components/resources/httpHelper";
import { injectable } from "inversify";
import { StagedFileBatch } from "@/dataModel";
import { ApiResponse } from "./ApiResponse";
import { CreateStagedFileBatchRequest } from "@/dataModel/requests/CreateStagedFileBatchRequest";
import { SiteDocumentParsingResult } from "@/dataModel/documentStaging/SiteDocumentParsingResult";
import { isEmpty } from "lodash";

const stagedBatchUrl = "admin/documents/batch/";

@injectable()
export default class DocumentStagingBatchHttpHelper extends HttpHelper {
  async getAllBatchesAsync(): Promise<ApiResponse<StagedFileBatch[]>> {
    const response = new ApiResponse<StagedFileBatch[]>();
    try {
      const { data, status } = await this.httpGet(stagedBatchUrl);
      response.data = data;
      response.status = status;
    } catch (e) {
      response.addError(e);
      console.error(e);
    }
    return response;
  }

  async getBatchByIdAsync(id: string): Promise<ApiResponse<StagedFileBatch>> {
    const response = new ApiResponse<StagedFileBatch>();
    try {
      const { data, status } = await this.httpGet(stagedBatchUrl + id);
      response.data = data;
      response.status = status;
    } catch (e) {
      response.addError(e);
      console.error(e);
    }
    return response;
  }

  async createBatchAsync(name: string, description: string): Promise<ApiResponse<StagedFileBatch>> {
    const resWrapper = new ApiResponse<StagedFileBatch>();
    try {
      const request: CreateStagedFileBatchRequest = {
        name,
        description,
      };
      const { data, status } = await this.httpPost(stagedBatchUrl, request);
      resWrapper.data = <StagedFileBatch>data;
      resWrapper.status = status;
    } catch (e) {
      resWrapper.addError(e);
      console.error(e);
    }

    return resWrapper;
  }

  async deleteBatchAsync(id: string): Promise<ApiResponse<void>> {
    const responseWrapper = new ApiResponse<void>();
    try {
      const { data, status } = await this.httpDelete(stagedBatchUrl + id);
      responseWrapper.data = data;
      responseWrapper.status = status;
    } catch (e) {
      responseWrapper.addError(e);
      console.error(e);
    }
    return responseWrapper;
  }

  async parseBatchMetadataAsync(id: string, file: File): Promise<ApiResponse<SiteDocumentParsingResult[]>> {
    const responseWrapper = new ApiResponse<SiteDocumentParsingResult[]>();
    try {
      const formData = new FormData();
      formData.append("file", file);
      const { data, status } = await this.httpPost(stagedBatchUrl + id + "/parse", formData);

      responseWrapper.data = data;
      responseWrapper.status = status;

      responseWrapper.data.forEach((result, i) => {
        if (isEmpty(result.validationFailures)) {
          return;
        }

        let validationFailedStr = `The following errors were found parsing File #${i + 1}: `;
        for (let col in result.validationFailures) {
          validationFailedStr += `${col} - ${result.validationFailures[col]}; `;
        }

        responseWrapper.addError(validationFailedStr);
      });
    } catch (e) {
      responseWrapper.addError(e);
      console.error(e);
    }
    return responseWrapper;
  }
}
