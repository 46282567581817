<template>
  <div class="grid-container padding-0 margin-0">
    <div class="grid-row grid-gap">
      <div class="desktop:grid-col">
        <div v-if="bulkActions.length" class="grid-row font-body-xs">
          <!-- Bulk table actions: begin -->
          <div class="desktop:grid-col display-flex flex-align-center data-table-bulk-actions bg-base-lightest">
            <div v-if="!singleSelect" class="usa-checkbox flex-1 padding-left-2">
              <input
                class="usa-checkbox__input"
                id="select-all"
                type="checkbox"
                name="select-all"
                v-model="selectAll"
              />
              <label class="usa-checkbox__label" for="select-all"> Select All </label>
            </div>
            <div v-for="{ name, icon, action } in bulkActions" :key="name" class="margin-105">
              <a href="#/" @click.prevent="$emit(action, name)" class="text-base-darker">
                <i :class="`fa ${icon}`"></i>
                <span class="margin-left-05">{{ name }}</span>
              </a>
            </div>
          </div>
          <!-- Bulk table actions: end -->
        </div>

        <div class="grid-row">
          <!-- Data table: begin -->
          <div class="desktop:grid-col">
            <b-table
              id="data-table"
              class="usa-table usa-table--borderless"
              :class="{ 'table-hover': hover }"
              :outlined="outlined"
              :small="compact"
              :fields="fields"
              :items="items"
              :filter="filter"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :per-page="perPage"
              sort-icon-left
              :thead-class="useLightHeader ? 'bg-base-light' : 'bg-primary-darker'"
              :thead-tr-class="[
                'font-heading-sm',
                useLightHeader ? 'text-black' : 'text-white',
                isResponsive ? 'text-no-wrap' : '',
              ]"
              :no-local-sorting="noLocalSort"
              no-sort-reset
              @sort-changed="sortingChanged"
              @row-hovered="rowHovered"
              @row-unhovered="rowUnhovered"
              show-empty
              :responsive="isResponsive"
              :busy="isLoadingData"
            >
              <template v-for="slotName in Object.keys($scopedSlots)" v-slot:[slotName]="slotScope">
                <slot :name="slotName" v-bind="slotScope"></slot>
              </template>

              <template #cell(classification)="data">
                <div class="margin-y-05 margin-x-03">
                  {{ data.value }}
                </div>
              </template>

              <!-- default cell style -->
              <template v-if="!useCustomDefaultCell" #cell()="data">
                <div class="margin-y-05 table-col-default-max overflow-ellipsis" :title="data.value">
                  {{ data.value }}
                </div>
              </template>

              <!-- Actions -->
              <template #cell(actions)="data">
                <div class="margin-y-05 text-no-wrap" v-if="!disableActionsComputed[data.index]">
                  <template v-if="collapseActions">
                    <b-dropdown
                      text="Actions"
                      variant="primary"
                      menu-class="actions-menu"
                      toggle-class="actions-button"
                    >
                      <b-dropdown-item
                        v-for="action in actions"
                        :key="`${action.name}-${data.index}`"
                        @click.prevent="$emit(action.action, data.item)"
                      >
                        <i class="margin-right-1" :class="`fa ${action.icon}`"></i> {{ action.name }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                  <template v-else>
                    <a
                      v-for="(action, index) in actions"
                      :key="action.name"
                      href="#/"
                      @click.prevent="$emit(action.action, data.item)"
                      :class="`data-table-item-action usa-link ${index !== 0 ? 'margin-left-105em' : ''}`"
                    >
                      <i :class="`fa ${action.icon}`"></i>
                      <span class="margin-left-05">{{ action.name }}</span>
                    </a>
                  </template>
                </div>
              </template>

              <template #table-busy>
                <div class="table-busy my-2">
                  <spinner />
                </div>
              </template>
            </b-table>
            <!-- Data table: end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import { DataTableHeader, DataTableAction } from "@/dataModel/interfaces";
import Spinner from "./Spinner.vue";

@Component({
  components: {
    Spinner,
  },
})
export default class DataTable extends Vue {
  @Prop({ required: true }) items!: any[];
  @Prop({ required: true }) fields!: DataTableHeader[];
  @Prop({ default: () => [] }) bulkActions!: DataTableAction[];
  @Prop({ default: () => [] }) actions!: DataTableAction[];
  @Prop({ default: () => [] }) disableActions!: boolean[];
  @Prop({ default: 20 }) perPage!: number;
  @Prop({ default: "" }) filter!: string;
  @Prop({ default: 1 }) page!: number;
  @Prop({ default: false }) singleSelect!: boolean;
  @Prop({ default: () => [] }) selected!: any[];
  @Prop({ default: true }) noLocalSort!: boolean;
  @Prop({ default: false }) isResponsive!: boolean;
  @Prop({ default: false }) useCustomDefaultCell!: boolean;
  @Prop({ default: false }) isLoadingData!: boolean;
  @Prop({ default: false }) hover!: boolean;
  @Prop({ default: false }) collapseActions!: boolean;
  @Prop({ default: false }) useLightHeader!: boolean;
  @Prop({ default: false }) outlined!: boolean;
  @Prop({ default: false }) compact!: boolean;

  sortBy = "";
  sortDesc = false;
  selectedItems: any[] = [];
  selectAll: boolean = false;

  get disableActionsComputed(): boolean[] {
    // default to all actions enabled
    return this.disableActions ?? new Array(this.items.length).fill(false);
  }

  @Watch("selected")
  selectedItemsWatcher() {
    this.selectedItems = this.selected;
    if (this.singleSelect && this.selectedItems.length > 1) {
      this.selectedItems.splice(0, 1);
    }
    const selection = this.singleSelect ? [this.selectedItems[this.selectedItems.length - 1]] : this.selectedItems;
    this.$emit("selections", selection);
  }

  @Watch("page")
  perPageWatcher() {
    this.selectedItems = [];
    this.selectAll = false;
    this.$emit("selections", this.selectedItems);
  }

  @Watch("selectAll")
  selectAllWatcher() {
    this.selectedItems = [];
    if (this.selectAll) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.disableActions != undefined) {
          if (!this.disableActions[i]) {
            this.selectedItems.push(this.items[i] as any);
          }
        } else {
          this.selectedItems.push(this.items[i] as any);
        }
      }
    }
    this.$emit("selections", this.selectedItems);
  }

  @Emit("row-hovered")
  rowHovered(event: any): any {
    return event;
  }
  @Emit("row-unhovered")
  rowUnhovered(event: any): any {
    return event;
  }

  sortingChanged(ctx: any) {
    this.$emit("sortingChanged", ctx);
  }

  clearSelections() {
    this.selectedItems = [];
    this.selectAll = false;
    this.$emit("selections", this.selectedItems);
  }

  clearSortBy(): void {
    this.sortBy = "";
  }
}
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";

// Overwrite header sort icon images to use dark themed versions (white)
thead [aria-sort="none"] {
  background-image: bv-escape-svg($b-table-sort-icon-bg-dark-not-sorted) !important;
}

thead [aria-sort="ascending"] {
  background-image: bv-escape-svg($b-table-sort-icon-bg-dark-ascending) !important;
}

thead [aria-sort="descending"] {
  background-image: bv-escape-svg($b-table-sort-icon-bg-dark-descending) !important;
}

.table-hover tbody tr:hover {
  td {
    background-color: #f0f0f0;
  }
}

.actions-button.btn-primary {
  padding: 0;
  background-color: transparent;
  color: #005ea2;
  border-width: 0px;
  border-radius: 0px;
  font-weight: inherit;

  .show > &,
  &:hover,
  &:focus {
    color: #005ea2;
    background-color: transparent;
  }

  &:hover {
    text-decoration: underline;
  }
}

.actions-menu {
  background-color: #005ea2;
  border-radius: 0px;
  margin: 0px;

  .dropdown-item {
    color: white;
    font-weight: inherit;
    &:hover {
      text-decoration: underline;
      background-color: transparent;
    }
  }
}

.data-table-bulk-actions > .usa-checkbox > .usa-checkbox__label::before {
  background-color: #f0f0f0;
}

.data-table-bulk-actions > .usa-checkbox > .usa-checkbox__input:checked + .usa-checkbox__label::before {
  background-color: #005ea2;
}

.data-table-item-action.usa-link:visited {
  color: #005ea2;
}

.data-table-item-action > i {
  text-decoration: none;
}

.data-table-item-action > span {
  text-decoration: underline;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// prevent checkbox outline from being cutoff
#data-table .usa-checkbox__label.overflow-ellipsis {
  margin: -0.25em -0.25em -0.25em -0.25em !important;
  padding: 0.25em 0.25em 0.25em calc(32px + 0.25em) !important;
}

.table-col-default-max {
  max-width: 15rem;
}

.table-col-icon {
  width: 3rem;
}

.table-col-xs {
  width: 9.5rem;
}

.table-col-sm {
  width: 11.5rem;
}

.table-col-md {
  width: 13rem;
}

.table-col-lg {
  width: 16.5rem;
}

.table-col-xl {
  width: 35rem;
}

.expander {
  cursor: pointer;
}

.draggable {
  cursor: grab;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

.usa-table td,
.usa-table th:not(.b-table-sort-icon-left),
.usa-prose > table td {
  padding: 0.5rem 0.8rem !important;
}

tr.b-table-has-details td {
  border-bottom-color: #fff;
}

.table-busy {
  display: flex;
  justify-content: center;
}
</style>
