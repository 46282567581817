<template>
  <div>
    <label class="usa-label" :for="inputId">
      {{ label }}
      <span v-if="!isRequired" class="text-base right"> Optional </span>
      <button v-if="additionalInfoButtonText" class="usa-button--unstyled right" @click="infoClicked">
        <span> <i class="fa fa-info-circle pr-1" />{{ additionalInfoButtonText }} </span>
      </button>
    </label>
    <input
      v-if="isNull(options)"
      class="usa-input"
      :id="inputId"
      :name="label"
      :type="inputFieldType || 'text'"
      v-model="inputValue"
    />
    <select v-else class="usa-select" :name="label" :id="inputId" v-model="inputValue">
      <option value>- Select {{ label }} -</option>
      <option v-for="option in options" :key="option.id" :value="option.id">
        {{ !isCategoryDetail ? option.name : option.displayName }}
      </option>
    </select>
    <span class="usa-error-message" :id="`required-error-message-${label}`" v-show="showError">
      This field is required
    </span>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class DataValueField extends Vue {
  inputValue: string = "";

  @Prop({ required: true }) label!: string;
  @Prop({ required: false }) options!: Array<Object>;
  @Prop({ default: true }) isRequired!: boolean;
  @Prop({ default: false }) isCategoryDetail!: boolean;
  @Prop({ default: false }) hasSubmitted!: boolean;
  @Prop({ required: false }) loadValue!: string;
  @Prop({ required: false }) additionalInfoButtonText!: string;
  @Prop({ required: false }) inputFieldType!: string;

  @Watch("inputValue")
  onInputChange(): void {
    this.$emit("onInputChange", this.inputValue);
  }

  @Watch("loadValue")
  onLoadValue(): void {
    this.inputValue = this.loadValue;
  }

  infoClicked(): void {
    this.$emit("infoClicked");
  }

  get inputId(): string {
    const labelWithoutSpaces = this.label.replaceAll(/\W+/g, "-");
    return this.isNull(this.options)
      ? `data-value-input-${labelWithoutSpaces}`
      : `data-value-options-${labelWithoutSpaces}`;
  }

  get showError(): boolean {
    return this.hasSubmitted && this.isRequired && (this.inputValue.length == 0 || this.inputValue == null);
  }

  isNull<T>(arr: Array<T>): boolean {
    return arr == null || arr == undefined;
  }

  created(): void {
    if (this.loadValue != null && this.loadValue.length > 0) {
      this.inputValue = this.loadValue;
    }
  }
}
</script>

<style scoped lang="scss">
.right {
  margin-right: 0;
  margin-left: auto;
  float: right;
}
</style>
