<template>
  <data-table
    :items="pooledDataViews"
    :fields="headers"
    :actions="actions"
    :perPage="100000"
    @add="addFeaturedDataView"
    @remove="removeSavedDataView"
  >
    <template #cell(name)="data">
      <div class="margin-y-05 table-col-lg overflow-ellipsis">
        <a
          v-if="showSaveDataViewLink(data.item)"
          @click="goToDataView(data.item)"
          class="data-table-item-action usa-link pointer"
          :title="data.value"
        >
          <span>{{ data.value }}</span>
        </a>
        <span v-else>{{ data.value }}</span>
      </div>
    </template>
  </data-table>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import DataTable from "@/components/common/DataTable.vue";
import { DataTableAction, DataTableHeader } from "@/dataModel/interfaces";
import { PooledFeaturedDataView } from "@/dataModel/interfaces";
import { HazardType } from "@/constants/HazardType";

@Component({
  components: {
    DataTable,
  },
})
export default class SavedDataViewsTable extends Vue {
  @Prop({ required: true }) pooledDataViews!: PooledFeaturedDataView[];
  @Prop({ required: true }) userHazardTypes!: HazardType[];

  actions: DataTableAction[] = [
    {
      name: "Add",
      icon: "fa-plus",
      action: "add",
    },
    {
      name: "Remove",
      icon: "fa-trash-alt",
      action: "remove",
    },
  ];

  headers: DataTableHeader[] = [
    {
      label: "Name",
      key: "name",
      sortable: false,
    },
    {
      label: "Hazard Type",
      key: "hazardTypeId",
      sortable: false,
    },
    {
      label: "Description",
      key: "description",
      sortable: false,
    },
    {
      label: "",
      key: "actions",
      sortable: false,
      tdClass: "table-col-sm",
    },
  ];

  async goToDataView(view: PooledFeaturedDataView): Promise<void> {
    const routeToPage = view.dataViewSearchParameters.hazardAgents.length > 1 ? "DataByProperty" : "DataByHazards";

    try {
      this.$router.push({
        name: routeToPage,
        params: {
          dataViewName: view.name,
          searchParams: JSON.stringify(view.dataViewSearchParameters),
          hazardTypeId: HazardType[view.hazardTypeId],
        },
      });
    } catch (err: any) {
      console.error(err);
    }
  }

  @Emit()
  addFeaturedDataView(view: PooledFeaturedDataView): PooledFeaturedDataView {
    return view;
  }

  @Emit()
  removeSavedDataView(view: PooledFeaturedDataView): PooledFeaturedDataView {
    return view;
  }

  showSaveDataViewLink(view: PooledFeaturedDataView): boolean {
    return this.userHazardTypes.includes(HazardType[view.hazardTypeId.toString()]);
  }
}
</script>
