export default class AccessItems {
    public static readonly ADMIN_DOCUMENTS = 'admin-documents';
    public static readonly ADMIN_ROLES = 'admin-roles';
    public static readonly ADMIN_USER_REQUESTS = 'admin-user-requests';
    public static readonly USER_SEARCH = 'user-search';
    public static readonly USER_REQUESTS = 'user-requests';
    public static readonly USER_APPLICATIONS = 'user-applications';
    public static readonly USER_DOCUMENTS = 'user-documents';
    public static readonly ADMIN_USER_MANAGEMENT = 'admin-user-management';
    public static readonly ADMIN_REPORT = 'admin-report';
    public static readonly DOCUMENT_APPROVE = 'document-approve';
    public static readonly DOCUMENT_CREATE_UPLOAD = 'document-create-upload';
    public static readonly DOCUMENT_EDIT = 'document-edit';
    public static readonly CATEGORY_MANAGEMENT = 'category-management';
    public static readonly FEATURED_RESOURCE_MANAGEMENT = 'featured-resource-management';
    public static readonly ADMIN_HAZARD_DATA = 'admin-hazard-data';
    public static readonly USER_HAZARD_DATA = 'user-hazard-data';
    public static readonly USAGE_ANALYTICS = 'usage-analytics';
  }
