<template>
  <li v-if="currentLevel == 0" class="filter-list__item">
    <!-- Root accordion header -->
    <a
      class="accordion filter list-item"
      v-b-toggle="'accordion-' + node.id + index"
      :aria-label="node.stringValue + ' filter'"
      tabindex="0"
      @keydown="toggleNodeCollapse($event, `accordion-${node.id}${index}`)"
    >
      {{ node.stringValue }}
      <span class="when-opened">
        <i class="fa fa-chevron-up"></i>
      </span>
      <span class="when-closed">
        <i class="fa fa-chevron-down"></i>
      </span>
    </a>

    <!-- Root accordion content -->
    <b-collapse
      ref="accordion"
      :id="'accordion-' + node.id + index"
      accordion="filter-accordion"
      v-model="node.isExpanded"
    >
      <ul class="filter-list__sublist filter-tree">
        <!-- Search within the current filter node -->
        <div v-if="node.children && node.children.length > minimumNumberOfChildrenForSearch" class="list-addon">
          <input
            class="usa-input filter-search-box"
            :placeholder="'Search ' + node.stringValue"
            type="text"
            v-model="filterSearchTerm"
          />
        </div>

        <!-- Select all -->
        <li v-if="!node.hideSelectAll && !isSingleSelection">
          <a class="list-item">
            <input
              type="checkbox"
              :id="'select-all-' + node.id + index"
              class="filter-select"
              v-model="isSelected"
              @change="checkboxChanged()"
            />
            <label :for="'select-all-' + node.id + index">
              {{ getSelectAllLabel(node) }}
            </label>
          </a>
        </li>

        <!-- Nodes within current node -->
        <search-filter-node
          :node="child"
          v-for="(child, childIndex) in node.children"
          v-show="isChildVisible(child, childIndex)"
          :key="childIndex"
          @filtersChanged="filtersChanged"
          @childDeselected="childDeselected"
          :index="childIndex"
          :includeCount="includeCount"
          :parentsAreSelectable="parentsAreSelectable"
          :canSearchWithinGroup="canSearchWithinGroup"
          :isSingleSelection="isSingleSelection"
          :truncatedNumberOfChildren="truncatedNumberOfChildren"
          :level="currentLevel + 1"
          ref="childNodes"
        />

        <!-- Show more/less -->
        <div v-if="visibleChildCount >= truncatedNumberOfChildren" class="list-addon">
          <a href="" class="show-all-link usa-link" @click.prevent="showAllChildren = !showAllChildren">
            {{ showAllChildren ? `Show less` : `Show more` }}
          </a>
        </div>
      </ul>
    </b-collapse>
  </li>
  <li v-else class="dropdown-section">
    <template v-if="hasChildren">
      <a
        class="accordion filter list-item"
        v-b-toggle="'accordion-' + node.id"
        :aria-label="node.stringValue + ' filter'"
        tabindex="0"
        @keydown="toggleNodeCollapse($event, `accordion-${node.id}`)"
      >
        <span>
          <input
            v-if="parentsAreSelectable && !isSingleSelection"
            type="checkbox"
            class="filter-select"
            :id="node.id"
            v-model="isSelected"
            @change="checkboxChanged()"
            :level="currentLevel + 1"
            @click.stop
            :aria-label="'Apply ' + node.stringValue + ' filter'"
            @keydown.stop
          />
          <span v-html="getNodeLabel(node)" />
        </span>
        <span class="when-opened">
          <i class="fa fa-chevron-up"></i>
        </span>
        <span class="when-closed">
          <i class="fa fa-chevron-down"></i>
        </span>
      </a>
      <b-collapse :id="'accordion-' + node.id" :accordion="'sub-accordion' + currentLevel" v-model="node.isExpanded">
        <ul class="filter-list__sublist filter-tree">
          <template
            v-if="node.children && node.children.length > minimumNumberOfChildrenForSearch && canSearchWithinGroup"
          >
            <div class="list-addon">
              <input
                class="usa-input filter-search-box"
                :placeholder="'Search ' + node.stringValue"
                type="text"
                v-model="filterSearchTerm"
              />
            </div>
          </template>
          <search-filter-node
            v-for="(child, childIndex) in node.children"
            :key="childIndex"
            v-show="isChildVisible(child, childIndex)"
            :index="childIndex"
            :node="child"
            :level="currentLevel + 1"
            :includeCount="includeCount"
            :parentsAreSelectable="parentsAreSelectable"
            :canSearchWithinGroup="canSearchWithinGroup"
            :isSingleSelection="isSingleSelection"
            :truncatedNumberOfChildren="truncatedNumberOfChildren"
            ref="childNodes"
            @filtersChanged="filtersChanged"
            @childDeselected="childDeselected"
          />
          <template v-if="visibleChildCount >= truncatedNumberOfChildren">
            <div class="list-addon">
              <a href="" class="show-all-link usa-link" @click.prevent="showAllChildren = !showAllChildren">
                <template v-if="showAllChildren"> Show less </template>
                <template v-else> Show all </template>
              </a>
            </div>
          </template>
        </ul>
      </b-collapse>
    </template>
    <template v-else>
      <a class="filter list-item" :aria-label="node.stringValue + ' filter'">
        <!-- Multi select with checkbox -->
        <span v-if="!isSingleSelection" class="filter-input">
          <input
            type="checkbox"
            class="filter-select"
            :id="node.id"
            :level="currentLevel + 1"
            v-model="isSelected"
            @change="checkboxChanged()"
            :aria-label="'Apply ' + node.stringValue + ' filter'"
          />
          <label :for="node.id" v-html="getNodeLabel(node)" />
        </span>

        <!-- Single select -->
        <div v-else class="grid-row">
          <p class="grid-col margin-0" v-html="getNodeLabel(node)" />
          <a
            class="usa-link grid-col-auto cursor-pointer"
            @click="onSingleSelect"
            tabindex="0"
            @keydown.enter="onSingleSelect"
            >View Data</a
          >
        </div>
      </a>
    </template>
  </li>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { FilterNodeInformation } from "@/dataModel";
import { v4 as uuidv4 } from "uuid";
import { BCollapse } from "bootstrap-vue";
import { FilterType } from "@/constants/FilterType";
import serviceTypes from "@/dependencyInjection/types";
import container from "@/dependencyInjection/config";
import CategoryService from "@/services/interfaces/ICategoryService";
import {
  unrestrictedAudience,
  unrestrictedDissemination,
  unrestrictedSci,
} from "@/constants/UserSearchUnrestrictedFilterValues";

@Component({
  name: "search-filter-node",
})
export default class SearchFilterNode extends Vue {
  @Prop({ required: true }) node!: FilterNodeInformation;
  @Prop({ required: false }) filters!: FilterNodeInformation;
  @Prop({ required: true }) index!: number;
  @Prop({ required: true }) includeCount!: boolean;
  @Prop({ default: 0 }) level?: number;
  @Prop({ default: true }) parentsAreSelectable!: boolean;
  @Prop({ default: true }) canSearchWithinGroup!: boolean;
  @Prop({ default: false }) isSingleSelection!: boolean;
  @Prop({ required: true }) readonly truncatedNumberOfChildren!: number;

  $refs!: {
    childNodes: SearchFilterNode[];
    accordion: BCollapse;
  };
  selectedFilters: FilterNodeInformation[] = [];
  children: any = [];
  isSelected: boolean = false;
  showAllChildren = false;
  readonly minimumNumberOfChildrenForSearch = 15;
  filterSearchTerm: string = "";
  visibleChildCount = 0;

  private categoryService = container.get<CategoryService>(serviceTypes.CategoryService);

  @Watch("selectedFilters")
  filterWatcher(): void {
    this.$emit("filtersChanged", this.selectedFilters);
  }

  @Watch("isSelected")
  isSelectedWatcher(): void {
    if (!this.isSelected) {
      this.$emit("childDeselected");
    }
  }

  onSingleSelect() {
    this.isSelected = true;
    this.$emit("filtersChanged", this.selectedFilters);
  }

  getSelectAllLabel(node: FilterNodeInformation): string {
    let label = this.hasChildren ? "Select all" : node.stringValue ?? "";

    if (this.includeCount) {
      label += ` ${this.count}`;
    }

    return label;
  }

  getNodeLabel(node: FilterNodeInformation): string {
    if (node.stringValue == null) {
      return "";
    }

    const textClass = this.categoryService.isItalicFormat(node.value) ? "text-italic" : "text-no-italic";
    let label = `<span class="${textClass}">${node.stringValue}</span>`;

    if (this.includeCount) {
      label += ` ${this.count}`;
    }

    return label;
  }

  isChildVisible(child: FilterNodeInformation, index: number): boolean {
    let updatedIndex = index;
    if (this.filterSearchTerm) {
      let matchingChildren = this.node.children?.filter((x) =>
        x.stringValue?.toLowerCase().includes(this.filterSearchTerm.toLowerCase()),
      );
      this.visibleChildCount = matchingChildren?.length ?? 0;
      updatedIndex = matchingChildren?.indexOf(child) ?? -1;
      if (updatedIndex < 0) {
        return false;
      }
    } else {
      this.visibleChildCount = this.node.children?.length ?? 0;
    }
    return this.showAllChildren || updatedIndex < this.truncatedNumberOfChildren - 1;
  }

  checkboxChanged() {
    this.changeSelectionOnAllChildren(this.isSelected);
    if (!this.isSelected) {
      this.$emit("childDeselected");
    }
    this.$emit("filtersChanged");
  }

  getSelectedFilters(): FilterNodeInformation[] {
    if (this.isSelected) {
      return [this.node];
    }
    if (this.$refs.childNodes) {
      let selectedChildren: FilterNodeInformation[] = [];
      this.$refs.childNodes.forEach((x) => {
        selectedChildren = selectedChildren.concat(x.getSelectedFilters());
      });
      return selectedChildren;
    }
    return [];
  }

  selectFilters(filters: FilterNodeInformation[]) {
    let filtersContainNode = false;
    switch (this.node.filterType) {
      case FilterType.DocumentType:
      case FilterType.CopyrightRestriction:
      case FilterType.Publication:
        filtersContainNode =
          this.node.value !== undefined && filters.map((x) => x.value?.value).includes(this.node.value.value);
        break;
      case FilterType.Audience:
        filtersContainNode =
          this.node.value === unrestrictedAudience
            ? filters.some((filter) => filter.value === this.node.value)
            : this.selectedFiltersContainNodeId(filters);
        break;
      case FilterType.DisseminationControl:
        filtersContainNode =
          this.node.value === unrestrictedDissemination
            ? filters.some((filter) => filter.value === this.node.value)
            : this.selectedFiltersContainNodeId(filters);
        break;
      case FilterType.Sci:
        filtersContainNode =
          this.node.value === unrestrictedSci
            ? filters.some((filter) => filter.value === this.node.value)
            : this.selectedFiltersContainNodeId(filters);
        break;
      default:
        filtersContainNode = this.selectedFiltersContainNodeId(filters);
    }
    if (filtersContainNode) {
      this.isSelected = true;
      this.checkboxChanged();
      return;
    }
    if (this.$refs.childNodes) {
      for (let childNode of this.$refs.childNodes) {
        childNode.selectFilters(filters);
      }
    }
  }

  selectedFiltersContainNodeId(filters: FilterNodeInformation[]): boolean {
    return (
      this.node.value !== undefined &&
      this.node.value.id !== undefined &&
      filters.map((x) => x.value?.id).includes(this.node.value?.id)
    );
  }

  filtersChanged(): void {
    this.$emit("filtersChanged");
  }

  childDeselected(): void {
    this.isSelected = false;
    this.$emit("childDeselected");
  }

  toggleNodeCollapse(event: KeyboardEvent, targetedNode: string): void {
    if (!this.isKeyHeld(event) && (event.code == "Space" || event.code == "Enter")) {
      event.preventDefault();
      this.$root.$emit("bv::toggle::collapse", targetedNode);
    }
  }

  isKeyHeld(event: KeyboardEvent): boolean {
    return event.repeat;
  }

  //#region Public methods

  public deselectFilterIfExists(filter: FilterNodeInformation): boolean {
    if (this.node == filter) {
      this.isSelected = false;
      this.checkboxChanged();
      return true;
    }
    if (this.$refs.childNodes) {
      for (let childNode of this.$refs.childNodes) {
        let removed = childNode.deselectFilterIfExists(filter);
        if (removed) {
          return true;
        }
      }
    }
    return false;
  }

  public changeSelectionOnAllChildren(checked: boolean) {
    this.isSelected = checked;
    if (!this.$refs.childNodes) {
      return;
    }
    this.$refs.childNodes.forEach((x) => {
      x.changeSelectionOnAllChildren(checked);
    });
  }

  //#endregion

  //#region Getters

  get hasChildren() {
    return this.node.children && this.node.children.length;
  }

  get count() {
    if (typeof this.node.count == "number") {
      return "(" + this.node.count.toLocaleString() + ")";
    } else {
      return "";
    }
  }

  get currentLevel() {
    return this.level ? this.level : 0;
  }

  //#endregion

  mounted() {
    if (this.$refs.accordion) {
      this.$refs.accordion.$on("show", () => {
        this.$emit("sectionExpanded", this.node);
      });
    }
    if (this.$refs.accordion) {
      this.$refs.accordion.$on("hide", () => {
        this.$emit("sectionCollapsed", this.node);
      });
    }
  }

  created() {
    this.node.id = uuidv4();
    this.visibleChildCount = this.node.children?.length ?? 0;
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

.dropdown-section .dropdown {
  overflow: hidden;
  height: 0;
  width: 0;
  margin-right: 0.8rem;
}

.dropdown-section .dropdown:focus {
  outline: none;
}

.dropdown-icon {
  position: absolute;
  top: 0;
  left: 0;
}
/* Specify the background color to be shown when hovering over checkbox */
.dropdown-section:hover input ~ div.dropdown-icon {
  cursor: pointer;
}
/* Specify the background color to be shown when checkbox is checked */
.dropdown-section input:checked ~ .dropdown-icon {
  transform: rotate(180deg);
}

label {
  margin-bottom: 0;
}

.filter-input {
  display: flex;
  flex-direction: row;
}

.top-level {
  font-weight: bold;
  color: color("primary");
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.accordion.not-collapsed {
  @extend .usa-current;
}

.accordion {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.filter-tree {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.usa-prose {
  margin-bottom: 0.5rem;
}

.section-list-item {
  list-style-type: none;
}

.section-title {
  color: color("base");
  font-family: family("heading");
  font-size: size("heading", "md");
  font-weight: bold;
}

.filter-select {
  margin-right: units(1);
  margin-top: units(0.5);
}

.filter-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.applied-filter {
  border: 1px solid black;
  padding: 1px 8px;
  margin-right: units(1);
  border-radius: 12px;
  background-color: #e6e6e6;
}

.filter {
  display: flex;
  flex-direction: row;
  align-items: flex-start !important;
}

.delete-filter-btn {
  color: color("base-dark");
  margin-left: 10px;
}

.line-break {
  background: #c9c9c9;
}

.filter-list > li {
  margin-bottom: 0;
  max-width: unset;
}
.filter-list__item {
  border-top: 1px solid #dfe1e2;
}
.filter-list .list-item {
  color: #565c65;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
}
.filter-list .list-item:hover {
  background-color: #f0f0f0;
  color: #005ea2;
  text-decoration: none;
}
.filter-list .list-item:focus {
  outline-offset: 0;
}
.filter-list__sublist .list-item {
  padding-left: 2rem;
}
.filter-list__sublist .filter-list__sublist .list-item {
  padding-left: 3rem;
}
.filter-list__sublist .filter-list__sublist .filter-list__sublist .list-item {
  padding-left: 4rem;
}
.filter-list__sublist .list-addon {
  padding-left: 2rem;
}
.filter-list__sublist .filter-list__sublist .list-addon {
  padding-left: 3rem;
}
.filter-list__sublist .filter-list__sublist .filter-list__sublist .list-addon {
  padding-left: 4rem;
}

.filter-list .not-collapsed {
  position: relative;
  color: #005ea2;
  font-weight: bold;
}

.filter-list .not-collapsed::after {
  background-color: #005ea2;
  border-radius: 99rem;
  content: "";
  display: block;
  position: absolute;
  bottom: 0.25rem;
  top: 0.25rem;
  width: 0.25rem;
  left: 0;
}

.show-all-link {
  margin-top: units(0.5);
  margin-bottom: units(1);
  display: inline-block;
}

.filter-search-box {
  height: 2rem !important;
  width: 90% !important;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
