<template>
  <div>
    <basic-dialog
      id="document-log-dialog"
      title="Log"
      primaryButton="Add Note"
      secondaryButton="Cancel"
      :primaryClick="addNote"
      :secondaryClick="cancel"
      size="lg"
    >
      <p class="font-body-xs"><span class="text-bold">Filename:</span> {{ file.fileName }}</p>
      <h3 class="font-heading-lg text-bold text-primary-dark">File Log</h3>
      <div class="grid-container padding-0">
        <div
          v-for="(entry, index) of file.log"
          :key="`${entry.id}-${index}`"
          :class="`grid-row border-top padding-y-1 font-body-xs ${
            index === file.log.length - 1 ? 'border-bottom' : ''
          }`"
        >
          <div class="grid-col-2 text-bold">
            {{ addSpaces(entry.logAction) }}
          </div>
          <div class="grid-col-3">
            <i class="fa fa-clock text-base"></i>
            {{ entry.logDateTime | formatDate("MM-DD-YYYY HH:mm:ss") }}
          </div>
          <div class="grid-col">{{ displayNames[index] }}</div>
          <div class="grid-col-12 note" v-if="entry.note">
            {{ entry.note }}
          </div>
        </div>
      </div>
      <form class="usa-form maxw-full">
        <label class="usa-label" for="note"> Note </label>
        <textarea
          class="usa-textarea"
          id="note"
          name="note"
          placeholder="Enter a note to be appended to the log"
          v-model.trim="note"
        ></textarea>
        <span class="usa-error-message" id="documents-error-message" v-if="!formIsValid() && showValidation">
          Note cannot be empty
        </span>
      </form>
    </basic-dialog>
    <success-toast id="note-added-toast" message="Note successfully added." />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import { StagedFile, StagedFileEventLog, FileLogAction } from "@/dataModel/";

@Component({
  components: {
    BasicDialog,
  },
})
export default class DocumentLogDialog extends Vue {
  @Prop({ required: true }) file!: StagedFile;

  @Prop({ required: true }) displayNames!: string[];

  note = "";
  showValidation = false;

  formIsValid(): boolean {
    return this.note.length > 0;
  }

  addNote(): void {
    this.showValidation = true;
    if (this.formIsValid()) {
      const note = new StagedFileEventLog(0, this.file.id, this.note, FileLogAction.NoteAdded, new Date(Date.now()));
      this.$emit("addNote", note);

      // update display to include new note
      this.file.log?.splice(this.file.log.length, 0, note);

      this.resetForm();
      this.$bvToast.show("note-added-toast");
    }
  }

  resetForm(): void {
    this.note = "";
    this.showValidation = false;
  }

  cancel(): void {
    this.resetForm();
    this.$bvModal.hide("document-log-dialog");
  }

  addSpaces(status: string): string {
    return status.replace(/([A-Z])/g, " $1");
  }
}
</script>

<style scoped lang="scss">
.note {
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-line;
}
</style>
