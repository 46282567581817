import {
  DocumentManagementStoreActions,
  DocumentManagementStoreMutations,
} from "@/constants/store/siteDocumentManagement/documentManagementStoreConstants";
import { FilterNodeInformation } from "@/dataModel";

export default {
  [DocumentManagementStoreActions.SET_SEARCH_TERM]({ commit }: { commit: Function }, searchTerm: string): void {
    commit(DocumentManagementStoreMutations.SET_SEARCH_TERM, searchTerm);
  },

  [DocumentManagementStoreActions.SET_PAGE]({ commit }: { commit: Function }, page: number): void {
    commit(DocumentManagementStoreMutations.SET_PAGE, page);
  },

  [DocumentManagementStoreActions.SET_RESULTS_PER_PAGE](
    { commit }: { commit: Function },
    resultsPerPage: number,
  ): void {
    commit(DocumentManagementStoreMutations.SET_RESULTS_PER_PAGE, resultsPerPage);
  },

  [DocumentManagementStoreActions.SET_FILTERS](
    { commit }: { commit: Function },
    filters: FilterNodeInformation[],
  ): void {
    commit(DocumentManagementStoreMutations.SET_FILTERS, filters);
  },
};
