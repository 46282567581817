import FeaturedLengthType from "@/constants/featuredLengthType";

export class NewFeaturedDocumentRequest {
  siteDocumentId: string;
  displayOrder: number;
  startDate: Date;
  endDate?: Date | null;
  featuredLengthType: FeaturedLengthType;
  customFeaturedLength: number;

  constructor(
    siteDocumentId?: string,
    displayOrder?: number,
    startDate?: Date,
    endDate?: Date | null,
    featuredLengthType?: FeaturedLengthType,
    customFeaturedLength?: number,
  ) {
    this.siteDocumentId = siteDocumentId || "";
    this.displayOrder = displayOrder || 0;
    this.startDate = startDate || new Date();
    this.endDate = endDate || null;
    this.featuredLengthType = featuredLengthType || FeaturedLengthType.NoEndDate;
    this.customFeaturedLength = customFeaturedLength || 0;
  }
}
