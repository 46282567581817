import { HazardDataStatus } from "@/constants/HazardDataStatus";
import { HazardType } from "@/constants/HazardType";
import DataValueCategoryRequest from "./DataValueCategoryRequest";
import DataValueDetailRequest from "./DataValueDetailRequest";

export default class DataValueRequest {
  propertyId: string;
  hazardId: string;
  scientificUnitId: string;
  evaluationRatingId: string;
  uncertaintyMeasureId: string | null;
  uncertaintyValueOne: number | null;
  uncertaintyValueTwo: number | null;
  containsAssumptions: boolean;
  value: string;
  siValue: string;
  status: HazardDataStatus;
  categoryValueRequests: DataValueCategoryRequest[];
  detailValueRequests: DataValueDetailRequest[];
  hazardTypeId: HazardType;

  constructor(
    propertyId?: string,
    hazardId?: string,
    scientificUnitId?: string,
    evaluationRatingId?: string,
    uncertaintyMeasureId?: string,
    uncertaintyValueOne?: number | null,
    uncertaintyValueTwo?: number | null,
    containsAssumptions?: boolean,
    value?: string,
    siValue?: string,
    status?: HazardDataStatus,
    categoryValueRequests?: DataValueCategoryRequest[],
    detailValueRequests?: DataValueDetailRequest[],
    hazardTypeId?: HazardType,
  ) {
    this.propertyId = propertyId ?? "";
    this.hazardId = hazardId ?? "";
    this.scientificUnitId = scientificUnitId ?? "";
    this.evaluationRatingId = evaluationRatingId ?? "";
    this.uncertaintyMeasureId = uncertaintyMeasureId ?? null;
    this.uncertaintyValueOne = uncertaintyValueOne ?? null;
    this.uncertaintyValueTwo = uncertaintyValueTwo ?? null;
    this.containsAssumptions = containsAssumptions ?? false;
    this.value = value ?? "";
    this.siValue = siValue ?? "";
    this.status = status ?? HazardDataStatus.Unknown;
    this.categoryValueRequests = categoryValueRequests ?? [];
    this.detailValueRequests = detailValueRequests ?? [];
    this.hazardTypeId = hazardTypeId ?? HazardType.Unknown;
  }
}
