<template>
  <basic-dialog
    id="advanced-search-dialog"
    title="Advanced Search Options"
    size="lg"
    primaryButton="Close"
    :primaryClick="close"
  >
    <p class="usa-prose">To perform an advanced search, utilize one or more of the concepts detailed below.</p>

    <table class="usa-table">
      <thead>
        <tr>
          <th scope="col" class="text-center" width="30%">Concept</th>
          <th scope="col" class="text-center">Search Examples</th>
          <th scope="col" class="text-center" width="40%">Results</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="font-heading-md concept">AND</td>
          <td>Politics <span class="font-weight-bold">AND</span> Media</td>
          <td class="text-center">
            <img id="and" src="@/assets/img/search/and.svg" class="search-img" role="img" alt aria-hidden="true" />
            Both words must be present
          </td>
        </tr>
        <tr>
          <td class="font-heading-md concept">OR</td>
          <td>Dairy <span class="font-weight-bold">OR</span> Nutrition</td>
          <td class="text-center">
            <img id="or" src="@/assets/img/search/or.svg" class="search-img" role="img" alt aria-hidden="true" />
            Either word must be present
          </td>
        </tr>
        <tr>
          <td class="font-heading-md concept">NOT</td>
          <td>Caribbean <span class="font-weight-bold">NOT</span> Cuba</td>
          <td class="text-center">
            <img id="not" src="@/assets/img/search/not.svg" class="search-img" role="img" alt aria-hidden="true" />
            First word must be present, but must exclude other word
          </td>
        </tr>
        <tr>
          <td class="font-heading-md concept">PHRASE</td>
          <td><span class="font-weight-bold">"</span>Sustainable Living<span class="font-weight-bold">"</span></td>
          <td class="text-center">
            <img
              id="phrase"
              src="@/assets/img/search/phrase.svg"
              class="search-img"
              role="img"
              alt
              aria-hidden="true"
            />
            Words inside of quotations must include these exact words in this exact order
          </td>
        </tr>
        <tr>
          <td class="font-heading-md concept">WILD CARD</td>
          <td>BAC<span class="font-weight-bold">*</span></td>
          <td class="text-center">
            <img
              id="wild-card"
              src="@/assets/img/search/wild-card.svg"
              class="search-img"
              role="img"
              alt
              aria-hidden="true"
            />
            Words with all combinations of the letters prior to the asterisk
          </td>
        </tr>
        <tr>
          <td class="font-heading-md concept">FUZZY MATCHING</td>
          <td>APLE<span class="font-weight-bold">~</span></td>
          <td class="text-center">
            <img
              id="fuzzy-matching"
              src="@/assets/img/search/fuzzy-matching.svg"
              class="search-img"
              role="img"
              alt
              aria-hidden="true"
            />
            Words closely matching the letters prior to the tilde
          </td>
        </tr>
      </tbody>
    </table>
  </basic-dialog>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";

@Component({ components: { BasicDialog } })
export default class AdvancedSearchDialog extends Vue {
  close(): void {
    this.$bvModal.hide("advanced-search-dialog");
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.concept {
  color: #00578d;
  font-weight: bold;
}
.search-img {
  display: block;
  margin: auto auto 0.75rem;
}
.usa-prose {
  font-weight: bold;
  margin: 1rem 0 1.5rem;
}
</style>
