<template>
  <b-toast
    v-bind="$attrs"
    solid
    toast-class="toast-wrapper error"
    header-class="toast-header error"
    body-class="toast-body error"
    no-close-button
    auto-hide-delay="3000"
  >
    <template #toast-title>
      <h3 class="text title">
        <span class="icon"><i class="fa fa-times-circle"></i></span>
        Error!
      </h3>
    </template>
    <p class="text message">{{ message }}</p>
  </b-toast>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ErrorToast extends Vue {
  @Prop({ required: true }) message!: string;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/styles/toasts.scss";
</style>
