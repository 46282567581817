import { HazardType } from "@/constants/HazardType";

export default class HazardTypeOption {
  id: number;
  name: string;
  type: HazardType;

  constructor(id?: number, name?: string, type?: HazardType) {
    this.id = id ?? 0;
    this.name = name ?? "";
    this.type = type ?? HazardType.Unknown;
  }
}
