<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div v-if="prevRouteContainsUserSearch(prevRouteOfDocumentSummary)" class="grid-col">
          <breadcrumb :path="path" />
        </div>
        <div v-else class="padding-top-2 padding-bottom-2">
          <button class="usa-button usa-button--unstyled" @click="goToPreviousPage">
            <i class="fa fa-arrow-left margin-right-05" />Back
          </button>
        </div>
      </div>

      <download-original-file
        :fileName="siteDocument?.title"
        :fileType="siteDocument?.fileType"
        :fileContents="originalFileContents"
        :availableForDownload="availableForDownload"
        @downloadOriginalFile="getOriginalFileContents"
      />

      <p v-if="!availableForDownload" class="usa-prose margin-top-1">
        Note: This document is not available for download.
      </p>

      <pdf-viewer v-if="file" :file="file" :allowDownload="availableForDownload" class="margin-top-2" />
      <template v-else>
        <div v-if="!hasError" class="margin-top-3 d-flex flex-column align-items-center">
          <spinner class="spinner margin-bottom-105"></spinner>
          Loading document...
        </div>

        <div v-else class="usa-alert usa-alert--error margin-x-auto margin-top-6" role="alert">
          <div class="usa-alert__body">
            <p class="usa-alert__text">
              An error occurred while loading the document.
              <a @click.prevent="retry" href="#/">Click here to retry</a>.
            </p>
          </div>
        </div>
      </template>
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import { BreadcrumbPathItem } from "@/dataModel/interfaces";
import Spinner from "@/components/common/Spinner.vue";
import PdfViewer from "@/components/common/PdfViewer.vue";
import DownloadOriginalFile from "@/components/common/DownloadOriginalFile.vue";
import store from "@/store";
import StoreNames from "@/constants/store/StoreNames";
import { UserSearchStoreGetters } from "@/constants/store/userSearch/userSearchStoreConstants";
import { DocumentMixin } from "@/mixins";

@Component({
  components: {
    Breadcrumb,
    PdfViewer,
    Spinner,
    DownloadOriginalFile,
  },
})
export default class DocumentFileView extends Mixins(DocumentMixin) {
  hasError = false;
  file: string | null = null;
  prevRouteOfDocumentSummary: string | null = null;
  originalFileContents: string | null = null;

  get path(): BreadcrumbPathItem[] {
    return [
      {
        text: "Home",
        url: "/",
      },
      {
        text: "Search",
        url: this.getSearchUrl(),
      },
      {
        text: "Document Summary",
        url: `/document-summary/?id=${this.id}`,
      },
      {
        text: "File Viewer",
      },
    ];
  }

  get availableForDownload(): boolean {
    return !this.siteDocument?.isCopyrightRestricted;
  }

  async getFile(): Promise<void> {
    try {
      const { document } = await this.siteDocumentHttpHelper.getSiteDocument(this.id);
      this.siteDocument = document;
      this.file = await this.siteDocumentHttpHelper.downloadDocument(this.id, true);
    } catch {
      if (!this.siteDocument) {
        // error getting document metadata
        this.goToDocumentErrorPage();
        return;
      }
      // error downloading document
      this.hasError = true;
    }
  }

  async getOriginalFileContents(): Promise<void> {
    let response = await this.siteDocumentHttpHelper.downloadDocument(this.id, false);
    if (response) {
      this.originalFileContents = response;
    }
  }

  goToDocumentErrorPage(): void {
    this.$router.push({
      name: "DocumentError",
    });
  }

  retry(): void {
    this.hasError = false;
    this.getFile();
  }

  async created(): Promise<void> {
    this.searchTerm = store.getters[`${StoreNames.UserSearch}/${UserSearchStoreGetters.GET_SEARCH_TERM}`];
    this.getFile();
    this.prevRouteOfDocumentSummary = localStorage.getItem("prevRoute");
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.usa-alert {
  width: 615px;

  & > .usa-alert__body {
    padding-right: 52px;
  }
}
</style>
