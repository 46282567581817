<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row">
        <h1 class="font-heading-xl text-primary-dark">{{ selectedHazardDisplay }}</h1>
        <div class="margin-left-auto padding-top-2">
          <button v-if="userHasFeaturedResourceManagementAccess" class="usa-button" @click="openFeaturedDataDialog">
            Add To Featured Data
          </button>
          <button class="usa-button" @click="openExportDataDialog">Export Data</button>
        </div>
      </div>

      <hr class="margin-y-1" />

      <div class="grid-row">
        <div class="grid-col">
          <h1 class="font-heading-md text-primary-dark margin-top-2">{{ selectedPropertyDisplay }}</h1>
          <p class="text-bold">{{ resultCount }} Results</p>
        </div>

        <!-- Property selector -->
        <div class="grid-col-auto">
          <label class="usa-label margin-top-2" for="property-options">Select a Hazard Property</label>
          <select
            class="usa-select"
            name="property-options"
            id="property-options"
            v-model="selectedPropertyId"
            @change="selectedPropertyIdChanged"
          >
            <option value>All Hazard Properties</option>
            <option v-for="property in hazardPropertyOptions" :key="property.id" :value="property.id">
              {{ property.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="grid-row mt-4">
        <div class="grid-col-2 display-flex flex-align-start pt-1">
          <button @click="openApplyFiltersDialog" class="usa-button">Apply Filters</button>
        </div>
        <div class="grid-col display-flex flex-align-start">
          <applied-selections-panel
            v-show="selectedDataValueFilters.length > 0"
            :panelButtonText="'Clear All'"
            :selections="dataValueFilterSelectionItems"
            @onPanelButtonClicked="clearAllSelectedFilters"
            @onRemoveSelection="removeFilterSelectionItem"
          />
        </div>
      </div>

      <!-- Table/Graph tabs -->
      <div class="margin-top-3">
        <b-tabs content-class="mt-3">
          <b-tab title="Data Table" title-item-class="tab-item" title-link-class="tab-link" active>
            <data-table
              :bulkActions="tableActions"
              :fields="tableHeaders"
              :items="dataValues"
              :singleSelect="true"
              :isResponsive="true"
              :perPage="dataTableResultsPerPage"
              :useCustomDefaultCell="true"
              :isLoadingData="isLoadingData"
              @customize="openCustomizeTable"
              @sortingChanged="sortingChanged"
              class="margin-top-3"
            >
              <template #cell(Value)="data">
                <div class="margin-y-05 table-col-min-width">
                  <p class="text-bold margin-y-05">
                    {{ data.item["Value-Value"] }} {{ data.item["Value-ScientificUnit"] }}
                  </p>
                </div>
              </template>
              <template #cell(Source)="data">
                <div class="margin-y-05 table-source-col-min-width">
                  <a
                    href="#/"
                    @click="viewDataSourceFromTable(data.item.optionResults)"
                    class="usa-link"
                    :title="data.item['Source-Title']"
                    :aria-label="data.item['Source-Title']"
                  >
                    <span>{{ truncate(data.item["Source-Title"], 90) }}</span>
                  </a>
                  <div>{{ data.item["Source-ContributorDisplay"] }}</div>
                </div>
              </template>
              <template #cell(EvaluationRating)="data">
                <div class="margin-y-05 table-col-min-width">
                  <div class="display-flex">
                    <div>
                      <i :class="`fa fa-circle fa-xs mr-2 ${data.item.getEvaluationRatingIconColor(data.value)}`" />
                    </div>
                    <div>
                      {{ data.value }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell()="data">
                <div class="margin-y-05 table-col-min-width" :title="data.value">
                  {{ data.value }}
                </div>
              </template>
            </data-table>
            <div class="horizontal-center">
              <spinner v-show="isLoadingMore" />
              <button
                v-show="!isLoadingMore && canLoadMore && !isLoadingData"
                class="usa-button usa-button--outline mr-0"
                @click="loadMoreResults"
              >
                Load More
              </button>
            </div>
          </b-tab>
          <b-tab title="Graph" title-link-class="tab-link" title-item-class="tab-item">
            <data-graph-view
              v-if="selectedPropertyId && selectedPropertyId.length > 0"
              :boxPlotData="boxPlotData"
              :selectedProperty="selectedProperty"
              :graphByOptions="graphByOptions"
              :dataValues="dataValues"
              :tableHeaders="tableHeaders"
              :isBusy="isLoadingData"
              :selectedHazardDataOptions="selectedHazardDataOptions"
              @graph-option-changed="graphBySelectionChanged"
              @source-clicked="viewDataSource"
            />
            <p v-else>Select a property to view the graph</p>
          </b-tab>
        </b-tabs>
      </div>
    </section>

    <customize-table-dialog
      @onApply="updateTable"
      :allHazardDataOptions="hazardDataColumnOptions"
      :selectedHazardDataOptions="selectedHazardDataOptions"
    />
    <apply-filters-dialog
      :filters="dataValueFilters"
      :isLoading="isLoadingFilters"
      :currentSelectedFilters="selectedDataValueFilters"
      @apply="updateAppliedFilters"
      @clear-all="clearAllSelectedFilters"
      @viewDataSource="viewDataSource"
    />
    <export-data-dialog @on-export-data="exportDataAsync" />
    <featured-data-dialog
      @on-add-data="addToFeaturedDataPoolAsync"
      :selected-hazards="selectedHazards"
      :selected-property="selectedProperty ? selectedPropertyDisplay : undefined"
      :selected-data-options="selectedHazardDataOptions"
      :selected-filters="selectedDataValueFilters"
    />

    <success-toast :id="toastId" :message="toastMessage" />
  </main>
</template>

<script lang="ts">
import { BreadcrumbPathItem } from "@/dataModel/interfaces/";
import { DataViewSearchParameters } from "@/dataModel/interfaces";
import { Component, Mixins } from "vue-property-decorator";
import { DataPageMixin } from "./DataPageMixin";
import { NavigationGuardNext, Route } from "vue-router";
import { truncate } from "@/services/utils";

@Component
export default class DataByHazardsPage extends Mixins(DataPageMixin) {
  truncate = truncate;
  path: BreadcrumbPathItem[] = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Explore Hazard Data",
      url: "/hazard-data",
    },
    {
      text: "Explore by Hazards",
    },
  ];

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<DataByHazardsPage>) {
    if (to.params.dataViewName) {
      // User selected a data view to load
      next(async (vm) => {
        const hazardTypeId = +to.params.hazardTypeId;
        const searchParams: DataViewSearchParameters = JSON.parse(to.params.searchParams);
        await vm.loadDataViewAsync(searchParams, hazardTypeId);
        vm.path[2] = { text: "Explore by Hazards", url: "/hazard-data/hazards" };
        vm.path = [...vm.path, { text: to.params.dataViewName }];
      });
      return;
    }

    next(async (vm) => {
      await vm.initializeDataPageAsync();
    });
  }

  get selectedHazardDisplay(): string {
    return this.selectedHazards[0]?.displayName ?? "";
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/tabs.scss";
@import "~@/styles/user-hazard-data.scss";
</style>
