import { HazardType } from "@/constants/HazardType";
import {
  DataCollectionStoreActions,
  DataCollectionStoreMutations,
} from "@/constants/store/dataCollection/dataCollectionStoreConstants";
import { Category } from "@/dataModel";
import DataSource from "@/dataModel/hazardData/dataSource";
import HazardDataValue from "@/dataModel/hazardData/hazardDataValue";
import HazardPropertyDetails from "@/dataModel/hazardData/hazardPropertyDetails";

export default {
  [DataCollectionStoreActions.SET_SELECTED_DATA_COLLECTION](
    { commit }: { commit: Function },
    dataCollection: DataSource[],
  ): void {
    commit(DataCollectionStoreMutations.SET_SELECTED_DATA_COLLECTION, dataCollection);
  },
  [DataCollectionStoreActions.SET_SELECTED_DATA_VALUE](
    { commit }: { commit: Function },
    dataValue: HazardDataValue[],
  ): void {
    commit(DataCollectionStoreMutations.SET_SELECTED_DATA_VALUE, dataValue);
  },
  [DataCollectionStoreActions.SET_SELECTED_HAZARDS]({ commit }: { commit: Function }, hazards: Category[]): void {
    commit(DataCollectionStoreMutations.SET_SELECTED_HAZARDS, hazards);
  },
  [DataCollectionStoreActions.SET_SELECTED_HAZARD_PROPERTY](
    { commit }: { commit: Function },
    hazardProperty: HazardPropertyDetails[],
  ): void {
    commit(DataCollectionStoreMutations.SET_SELECTED_HAZARD_PROPERTY, hazardProperty);
  },
  [DataCollectionStoreActions.SET_SELECTED_HAZARD_TYPE](
    { commit }: { commit: Function },
    hazardType: HazardType,
  ): void {
    commit(DataCollectionStoreMutations.SET_SELECTED_HAZARD_TYPE, hazardType);
  },
};
