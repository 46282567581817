import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import { DocumentManagementStoreState } from '@/typings/store/states/SiteDocumentManagementStoreState';

 const state: DocumentManagementStoreState = {
    searchTerm: "",
    page: 0,
    resultsPerPage: 20,
    filters: []
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};