import { Vue, Component } from "vue-property-decorator";
import HazardTypeSelection from "@/components/common/HazardTypeSelection.vue";
import { HazardType } from "@/constants/HazardType";
import { FilterNodeInformation } from "@/dataModel";

@Component({
  components: {
    HazardTypeSelection,
  },
})
export class ExplorePageMixin extends Vue {
  taxonomyTree: FilterNodeInformation[] | null = null;
  selectedHazardType: HazardType | null = null;

  setHazardType(hazardType: HazardType | null): void {
    this.selectedHazardType = hazardType;
  }

  updateTaxonomyTree(taxonomyTree: FilterNodeInformation[]): void {
    this.taxonomyTree = taxonomyTree;
  }
}
