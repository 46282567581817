<template>
  <basic-card :title="title" cardWidth="6" :description="description">
    <plotly :data="chartData" :layout="layout" :id="title" />
  </basic-card>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { Plotly } from "vue-plotly";
import { Data, Layout } from "plotly.js";
import BasicCard from "@/components/common/BasicCard.vue";

@Component({
  components: {
    BasicCard,
    Plotly,
  },
})
export default class UsageGraphCard extends Vue {
  @Prop({ default: () => ({}) }) readonly data!: object;
  @Prop({ default: "" }) readonly title!: string;
  @Prop({ default: "" }) readonly description!: string;

  get chartData(): Data[] {
    let x: string[] = [];
    const y: number[] = [];
    x = Object.keys(this.data);

    for (let i = 0; i < x.length; i++) {
      y.push(this.data[x[i]]);
    }

    return [
      {
        x,
        y,
        type: "bar",
      },
    ];
  }

  get layout(): Partial<Layout> {
    return {
      margin: {
        t: 20,
        r: 20,
        b: 60,
        l: 20,
      },
      yaxis: {
        rangemode: "nonnegative",
      },
    };
  }
}
</script>

<style scoped lang="scss"></style>
