<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <div class="desktop:grid-col">
          <h1 class="font-heading-xl text-primary-dark margin-top-0 margin-bottom-5">Usage Analytics</h1>
        </div>
      </div>
      <div class="grid-row margin-bottom-5">
        <div class="grid-col-3">
          <label class="usa-label" for="date-range-options">Select a Date Range</label>
          <select class="usa-select" name="date-range-options" id="date-range-options" v-model="dateRange">
            <option v-for="range in UsageDateRangeType" :key="range" :value="range">This {{ range }}</option>
          </select>
        </div>
      </div>

      <ul class="usa-card-group">
        <template v-for="analytics in usageAnalyticsDetails">
          <template v-for="analyticDetails in analytics">
            <usage-total-card
              v-if="isTypeUsageCounts(analyticDetails)"
              :key="`total-${analyticDetails.name}`"
              :title="analyticDetails.name"
              :count="sumCounts(analyticDetails)"
              :description="analyticDetails.description"
            />
          </template>
        </template>
      </ul>

      <div class="grid-row margin-bottom-5">
        <div class="grid-col-3">
          <label class="usa-label" for="interval-options">Select an Interval</label>
          <select class="usa-select" name="interval-options" id="interval-options" v-model="interval">
            <option v-for="interval in UsageIntervalType" :key="interval" :value="interval">{{ interval }}</option>
          </select>
        </div>
      </div>

      <ul class="usa-card-group">
        <template v-for="analytics in usageAnalyticsDetails">
          <template v-for="(analyticDetails, name, index) in analytics">
            <usage-graph-card
              v-if="isGraphUsage(analytics, index)"
              :key="`graph-${analyticDetails.name}`"
              :title="analyticDetails.name"
              :data="castAsUsageCount(analyticDetails)"
              :description="analyticDetails.description"
            />
          </template>
        </template>
      </ul>

      <div class="grid-row margin-top-105em">
        <div class="desktop:grid-col">
          <h1 class="font-heading-xl text-primary-dark margin-top-0 margin-bottom-5">Top Resources</h1>
        </div>
      </div>

      <ul class="usa-card-group">
        <template v-for="analytics in usageAnalyticsDetails">
          <template v-for="analyticDetails in analytics">
            <usage-list-card
              v-if="isListUsage(analyticDetails)"
              :key="`list-${analyticDetails.name}`"
              :title="analyticDetails.name"
              :documentUsage="castAsUsageList(analyticDetails)"
              :description="analyticDetails.description"
            />
          </template>
        </template>
      </ul>
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import { BreadcrumbPathItem } from "@/dataModel/interfaces";
import UsageTotalCard from "./Cards/UsageTotalCard.vue";
import UsageAnalyticsHttpHelper from "@/components/resources/usageAnalyticsHttpHelper";
import { GetUsageAnalyticsRequest } from "@/dataModel/requests/";
import UsageAnalyticsDetails from "@/dataModel/usageAnalytics/usageAnalyticsDetails";
import UsageDateRangeType from "@/constants/UsageDateRangeType";
import UsageIntervalType from "@/constants/UsageIntervalType";
import UsageGraphCard from "./Cards/UsageGraphCard.vue";
import UsageListCard from "./Cards/UsageListCard.vue";
import { UsageCounts, UsageList } from "@/dataModel/usageAnalytics/specificUsageDetails";
import DocumentUsageMetadata from "@/dataModel/usageAnalytics/documentUsageMetadata";

@Component({
  components: {
    Breadcrumb,
    UsageTotalCard,
    UsageGraphCard,
    UsageListCard,
  },
})
export default class UsageAnalytics extends Vue {
  dateRange: UsageDateRangeType = UsageDateRangeType.Month;
  interval: UsageIntervalType = UsageIntervalType.Day;
  limit: number = 5;
  usageAnalyticsDetails: UsageAnalyticsDetails = new UsageAnalyticsDetails();

  UsageDateRangeType: typeof UsageDateRangeType = UsageDateRangeType;
  UsageIntervalType: typeof UsageIntervalType = UsageIntervalType;

  path: BreadcrumbPathItem[] = [{ text: "Administration" }, { text: "Usage Analytics" }];

  private usageAnalyticsHttpHelper = container.get<UsageAnalyticsHttpHelper>(serviceTypes.UsageAnalyticsHttpHelper);

  @Watch("dateRange")
  dateRangeUpdated(): void {
    this.getUsageAnalyticsAsync();
  }

  @Watch("interval")
  intervalUpdated(): void {
    this.getUsageAnalyticsAsync();
  }

  async getUsageAnalyticsAsync(): Promise<void> {
    const request = new GetUsageAnalyticsRequest(this.limit, this.interval, this.dateRange);
    const response: UsageAnalyticsDetails = await this.usageAnalyticsHttpHelper.getUsageAnalyticsAsync(request);
    this.usageAnalyticsDetails = response;
  }

  async created(): Promise<void> {
    await this.getUsageAnalyticsAsync();
  }

  castAsUsageList(usageList: object) {
    return (usageList as UsageList<DocumentUsageMetadata>).topResources;
  }

  castAsUsageCount(usageList: object) {
    return (usageList as UsageCounts).counts;
  }

  sumCounts(countsObj: object): number {
    let usageCounts = countsObj as UsageCounts;
    return Object.values(usageCounts.counts).reduce((a, b) => a + b, 0);
  }

  isTypeUsageCounts(usageDetails: any): boolean {
    return Object.keys(usageDetails).includes("counts");
  }

  isGraphUsage(obj: any, index: number): boolean {
    const propertyNameWithSpaces = Object.keys(obj)[index];
    return (
      propertyNameWithSpaces == "totalDocumentViewCounts" || propertyNameWithSpaces == "totalDocumentDownloadCounts"
    );
  }

  isListUsage(usageDetails: any): boolean {
    return Object.keys(usageDetails).includes("topResources");
  }
}
</script>

<style scoped lang="scss"></style>
