export default class BoxPlotStats {
  seriesName: string = "";
  min: number = 0;
  q1: number = 0;
  median: number = 0;
  q3: number = 0;
  max: number = 0;
  mean: number = 0;
  stdDev: number = 0;

  public AssignFromData(seriesName: string, boxData: any): void {
    this.seriesName = seriesName;
    this.min = boxData.min;
    this.q1 = boxData.q1;
    this.median = boxData.med;
    this.q3 = boxData.q3;
    this.max = boxData.max;
    this.mean = boxData.mean;
    this.stdDev = boxData.sd;
  }
}
