<template>
  <div>
    <h1 class="header">No results found</h1>
    <p v-if="searchTerm" class="usa-prose">
      Your search for '<span class="bold">{{ searchTerm }}</span
      >' did not match any documents.
    </p>
    <p v-else class="usa-prose">Your search did not match any documents.</p>
    <p class="usa-prose mb-1">Suggestions:</p>
    <ul>
      <li class="usa-prose">Check the spelling in your search</li>
      <li class="usa-prose">Try a different set of keywords</li>
    </ul>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class NoResultsFound extends Vue {
  @Prop(String) searchTerm: string | undefined;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

.bold {
  font-weight: font-weight("bold");
}

.header {
  font-family: family("heading");
  font-size: size("heading", "lg");
  line-height: lh("heading", 2);
  font-weight: font-weight("bold");
}
</style>
