<template>
  <basic-dialog
    id="existing-data-collection-dialog"
    title="Data Collection Exists"
    primaryButton="View Data Collection"
    :primaryClick="viewDataCollection"
    :isDisabled="false"
  >
    <div>
      <p class="usa-prose mt-2 mb-1">
        <span class="text-bold">The selected document is an existing data collection.</span>
      </p>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Vue, Component, Emit } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";

@Component({
  components: {
    BasicDialog,
  },
})
export default class ExistingDataCollectionDialog extends Vue {
  @Emit()
  viewDataCollection(): void {
    this.$bvModal.hide("existing-data-collection-dialog");
  }
}
</script>

<style scoped lang="scss"></style>
