<template>
  <div id="app" class="wrapper">
    <classification-notice class="fixed-top" />
    <div id="content-wrapper">
      <nav-bar></nav-bar>
      <router-view></router-view>
    </div>

    <dhs-footer></dhs-footer>
    <classification-notice class="fixed-bottom" />
    <inactive-warning-dialog />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import "@/assets/uswds/js/uswds";
import NavBar from "./components/base/NavBar.vue";
import ClassificationNotice from "./components/base/ClassificationNotice.vue";
import DhsFooter from "./components/base/Footer.vue";
import IAuthService from "@/services/interfaces/IAuthService";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import { AppConfig } from "./dataModel";
import InactiveWarningDialog from "@/components/navigation/InactiveWarningDialog.vue";
import { AuthStoreActions } from "./constants/store/auth/authStoreConstants";
import StoreNames from "./constants/store/StoreNames";
import store from "@/store";

@Component({
  components: {
    NavBar,
    ClassificationNotice,
    DhsFooter,
    InactiveWarningDialog,
  },
})
export default class App extends Vue {
  private auth = container.get<IAuthService>(serviceTypes.AuthService);
  private appConfig = container.get<AppConfig>(serviceTypes.AppConfig);
  private idleTimerId!: number;
  private idleWarningTimerId!: number;
  private readonly idleResetEvents = ["mousemove", "keydown", "mousedown", "touchstart", "wheel"];

  created() {
    let lastActiveTime = this.getLastActiveTime();
    if (!lastActiveTime || new Date().getTime() - lastActiveTime >= this.appConfig.sessionTimeoutInSeconds * 1000) {
      this.signOutIfLoggedIn();
    }
    store.dispatch(`${StoreNames.Auth}/${AuthStoreActions.SET_SHOWING_WARNING_DIALOG}`, false);
    this.resetActiveState();
    this.startIdleTimer(this.appConfig.sessionTimeoutInSeconds * 1000);
    this.idleResetEvents.forEach((event) => {
      window.addEventListener(event, this.resetActiveState);
    });
    this.startIdleWarningTimer();
  }

  resetActiveState() {
    if (store.state[StoreNames.Auth].showingWarningDialog) {
      // disable idle reset when warning dialog is open
      return;
    }
    clearTimeout(this.idleTimerId);
    localStorage.setItem("lastActiveTime", new Date().toString());
    this.startIdleTimer(this.appConfig.sessionTimeoutInSeconds * 1000);
  }

  startIdleTimer(interval: number) {
    this.idleTimerId = setTimeout(this.onIdle, interval);
  }

  startIdleWarningTimer(): void {
    this.idleWarningTimerId = setInterval(() => {
      const lastActiveTime = this.getLastActiveTime();
      const isSignedIn = this.auth.getAuthState()?.isAuthenticated ?? false;

      if (lastActiveTime && isSignedIn) {
        const inactiveTimeInSeconds = Math.round((new Date().getTime() - lastActiveTime) / 1000);
        store.dispatch(`${StoreNames.Auth}/${AuthStoreActions.SET_SECONDS_IDLE}`, inactiveTimeInSeconds);
        if (
          inactiveTimeInSeconds >=
          this.appConfig.sessionTimeoutInSeconds - this.appConfig.secondsBeforeIdleLogoutToShowWarning
        ) {
          store.dispatch(`${StoreNames.Auth}/${AuthStoreActions.SET_SHOWING_WARNING_DIALOG}`, true);
          this.$bvModal.show("inactive-warning-dialog");
        }
      }
    }, 1000);
  }

  onIdle() {
    let lastActiveTime = this.getLastActiveTime();
    if (lastActiveTime) {
      if (new Date().getTime() - lastActiveTime < this.appConfig.sessionTimeoutInSeconds * 1000) {
        this.startIdleTimer(this.appConfig.idleTabSessionTimeoutInSeconds * 1000);
        return;
      }
    }
    this.signOutIfLoggedIn();
  }

  getLastActiveTime(): number | undefined {
    let lastActiveTimeStr = localStorage.getItem("lastActiveTime");
    if (lastActiveTimeStr) {
      let lastActiveTime = Date.parse(lastActiveTimeStr);
      return lastActiveTime;
    }
    return undefined;
  }

  signOutIfLoggedIn() {
    const isSignedIn = this.auth.getAuthState()?.isAuthenticated ?? false;
    if (isSignedIn) {
      localStorage.setItem("showInactiveDialog", "yes");
      this.auth.logout();
    }
  }
}
</script>

<style lang="scss">
@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "bootstrap/dist/css/bootstrap.css";
@import "bootstrap-vue/dist/bootstrap-vue.css";
@import "~@/assets/uswds/scss/uswds.scss";

.wrapper {
  min-height: 98vh;
  position: relative;
}

.fixed-top {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
}

.fixed-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10000;
}

#hkma-dhs-background-logo {
  width: 440px;
  height: 440px;
  transform: translate(-40px, 40px);
  opacity: 11%;
  margin-right: 20px;
  position: absolute;
}

#content-wrapper {
  padding-bottom: 12em;
}
</style>
