import axios, { AxiosError, AxiosResponse } from "axios";

export class ApiResponse<Type> {
  public data!: Type;
  public status!: number;
  public readonly errorMessages: string[] = [];
  public get hasError(): boolean {
    return this.errorMessages.length > 0;
  }
  public get errorMessage(): string {
    return this.errorMessages.join("\n");
  }

  public addError(e: any): void {
    if (typeof e === "string") {
      this.errorMessages.push(e);
    } else if (e instanceof Error) {
      this.errorMessages.push(e.message);
    } else if (axios.isAxiosError(e)) {
      this.errorMessages.push((e as AxiosError).message);
    }
  }
}
