import DataValueResult from "@/dataModel/hazardData/dataValueResult";
import Vue from "vue";

export function flattenDataValueOptionResults(values: DataValueResult[]): void {
  for (let i = 0; i < values.length; i++) {
    for (let j = 0; j < values[i].optionResults.length; j++) {
      const optionResult = values[i].optionResults[j];
      Vue.set(values[i], optionResult.identifierKey, optionResult.displayValue);
    }
  }
}
