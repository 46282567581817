<template>
  <li class="grid-col usa-card usa-card--flag">
    <div class="usa-card__container">
      <header class="usa-card__header">
        <a
          v-if="loginUrl"
          class="usa-card__heading text-bold text-primary-dark text-underline"
          :href="loginUrl"
          target="_blank"
          >{{ name }}</a
        >
        <template v-else>
          <div class="usa-card__heading text-bold text-base-dark">{{ name }}</div>
          <div class="font-heading-xs text-base-dark padding-top-05">Coming soon</div>
        </template>
      </header>
      <div class="usa-card__media">
        <img class="application-icon" :src="iconFilePath" role="img" :alt="name" aria-hidden="true" />
      </div>
      <div class="usa-card__body">
        <p>{{ visibleDescription }}</p>
      </div>
      <div class="usa-card__footer">
        <button class="usa-button usa-button--unstyled" @click="showDetails">{{ showDetailsButtonText }}</button>
      </div>
    </div>
  </li>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { truncate } from "@/services/utils";

@Component
export default class ApplicationCard extends Vue {
  @Prop({ default: "" }) name!: string;
  @Prop({ default: "" }) description!: string;
  @Prop({ default: "" }) iconFileName!: string;
  @Prop({ default: "" }) loginUrl!: string;

  isDescriptionExpanded: boolean = false;

  get iconFilePath(): string {
    return require(`@/assets/img/icons/${this.iconFileName}`);
  }

  get visibleDescription(): string {
    if (this.isDescriptionExpanded) {
      return this.description;
    }
    return truncate(this.description, 200);
  }

  get showDetailsButtonText(): string {
    if (this.isDescriptionExpanded) {
      return "Hide Details";
    }
    return "Show Details";
  }

  showDetails(): void {
    this.isDescriptionExpanded = !this.isDescriptionExpanded;
  }
}
</script>

<style scoped lang="scss">
$card-media-width: 11rem;

.application-icon {
  width: 7rem;
}

.usa-card__media {
  background-color: #f0f0f0;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (min-width: 40em) {
  .usa-card__media {
    width: $card-media-width !important;
  }

  .usa-card__header,
  .usa-card__body,
  .usa-card__footer {
    margin-left: $card-media-width !important;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
</style>
