import { Classification, DisseminationControl, Sci, KnowledgeResourceAudience, TitlePermissions } from "@/dataModel";

export class KnowledgeResource {
  id: string;
  classification: Classification;
  disseminationControls: DisseminationControl[];
  scis: Sci[];
  audiences: KnowledgeResourceAudience[];
  titleClassifications: TitlePermissions;

  constructor(
    id?: string,
    classification?: Classification,
    disseminationControls?: DisseminationControl[],
    scis?: Sci[],
    audiences?: KnowledgeResourceAudience[],
    titleClassifications?: TitlePermissions,
  ) {
    this.id = id || "";
    this.classification = classification || new Classification();
    this.disseminationControls = disseminationControls || [];
    this.scis = scis || [];
    this.audiences = audiences || [];
    this.titleClassifications = titleClassifications || new TitlePermissions();
  }
}
