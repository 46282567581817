<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <h1 class="font-heading-xl text-primary-dark margin-top-0 margin-bottom-5">User Management</h1>
      </div>

      <div class="grid-row margin-bottom-2">
        <div class="desktop:grid-col-2 margin-bottom-2 filters">
          <h3 class="font-heading-lg text-bold text-primary-dark">Filters</h3>
          <data-table-filter
            :filterBy="'status'"
            :filters="filters"
            @filtersChanged="statusFiltersChanged"
            :preSelectedFilters="['Active']"
          />
        </div>

        <div class="desktop:grid-col padding-left-2">
          <div class="grid-row grid-gap margin-bottom-2">
            <form
              class="usa-search usa-search--small search-style"
              role="search"
              @submit.prevent="throttleSearchMethod"
            >
              <label class="usa-sr-only" for="search-field-en-small">Search</label>
              <input
                class="usa-input"
                id="search-field-en-small"
                type="search"
                name="search"
                v-model="searchTerm"
                placeholder="User's name, email, or organization..."
              />
              <button class="usa-button" type="submit">
                <span class="usa-sr-only">Search</span>
              </button>
            </form>

            <results-per-page-button
              class="margin-left-2 align-right"
              @resultsPerPageChanged="perPageAmountChanged"
            ></results-per-page-button>

            <div class="grid-col-auto pagination-container">
              <table-pagination
                class="pagination pagination-top"
                @prev="prevPage"
                @next="nextPage"
                :page="page"
                :hasNextPage="hasNextPage"
              />
            </div>
          </div>

          <div class="grid-row grid-gap">
            <div class="desktop:grid-col">
              <data-table
                @activate="bulkEditStatusHandler(StatusActions.ACTIVATE)"
                @deactivate="bulkEditStatusHandler(StatusActions.DEACTIVATE)"
                @approveAccess="bulkEditStatusHandler(StatusActions.APPROVE)"
                @denyAccess="bulkEditStatusHandler(StatusActions.DENY)"
                @bulkEdit="bulkEditHandler"
                @edit="editUserHandler"
                @selections="getSelectedUsers($event)"
                @sortingChanged="sortingChanged"
                :isLoadingData="isSearchLoading"
                :items="users"
                :fields="mockHeaders"
                :actions="actions"
                :bulkActions="bulkActions"
                :details="details"
                :perPage="perPage"
                :page="page"
                :selected="selectedItems"
                ref="userTable"
              >
                <template v-slot:cell(email)="data">
                  <div class="usa-checkbox margin-y-05 maxw-mobile table-col-lg">
                    <input
                      class="usa-checkbox__input"
                      :id="data.item.userId"
                      type="checkbox"
                      :name="data.value"
                      v-model="selectedItems"
                      :value="data.item"
                    />
                    <label :class="['usa-checkbox__label', 'margin-0', 'overflow-ellipsis']" :for="data.item.userId"
                      >{{ data.value }}
                    </label>
                  </div>
                </template>
                <template v-slot:cell(organization)="data">
                  <div class="margin-y-05 table-col-md overflow-ellipsis">
                    {{ data.value }}
                  </div>
                </template>
                <template v-slot:cell(show_details)="row">
                  <div class="mt-1">
                    <a
                      tabindex="0"
                      @click="row.toggleDetails"
                      @keypress.enter="row.toggleDetails"
                      class="usa-link expander"
                    >
                      <i
                        v-if="!row.detailsShowing"
                        class="fa fa-plus usa-tooltip"
                        data-position="top"
                        title="Click for more info"
                      ></i>
                      <i v-else class="fa fa-minus"></i>
                    </a>
                  </div>
                </template>
                <template v-slot:row-details="row">
                  <div class="margin-top-1">
                    <div v-for="(detail, index) in details" :key="index" class="grid-row mb-2">
                      <b-col sm="3" class="text-sm-right font-heading-sm padding-left-0"
                        ><b>{{ detail.name }}:</b></b-col
                      >
                      <b-col v-if="detail.type === 'date'">{{ row.item[detail.key] | formatDate }}</b-col>
                      <b-col v-else>{{ row.item[detail.key] }}</b-col>
                    </div>
                  </div>
                </template>
              </data-table>
            </div>
          </div>
        </div>
      </div>
      <table-pagination class="pagination" @prev="prevPage" @next="nextPage" :page="page" :hasNextPage="hasNextPage" />

      <!-- Dialogs -->
      <create-user-dialog :roles="allRoles" @confirm="createUser($event)" ref="createNewUser" />
      <edit-user-dialog :roles="allRoles" :existingUser="existingUser" @confirm="editUser($event)" />
      <bulk-edit-dialog
        :roles="allRoles"
        @bulk-edit="bulkEdit"
        @approve="approveUsersAndAssignRoles"
        :users="isBulkEdit ? selectedItems : selectedUsersWithoutRoles"
        :isBulkEdit="isBulkEdit"
        ref="bulkEdit"
      />
      <confirm-status-change-dialog
        :statusAction="statusAction"
        :selectedUserStatuses="selectedUserStatuses"
        :StatusActions="StatusActions"
        @confirm="bulkEditStatus($event)"
      />
      <success-toast id="users-update-status-toast" message="Statuses have successfully been updated." />
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { User, AppConfig } from "@/dataModel";
import DataTable from "@/components/common/DataTable.vue";
import DataTableFilter from "@/components/common/DataTableFilter.vue";
import TablePagination from "@/components/common/TablePagination.vue";
import ResultsPerPageButton from "@/components/common/ResultsPerPageButton.vue";
import EditUserDialog from "@/components/navigation/UserManagement/EditUserDialog.vue";
import BulkEditDialog from "@/components/navigation/UserManagement/BulkEditDialog.vue";
import ConfirmStatusChangeDialog from "@/components/navigation/UserManagement/ConfirmStatusChangeDialog.vue";
import RoleHttpHelper from "@/components/resources/roleHttpHelper";
import UserHttpHelper from "@/components/resources/userHttpHelper";
import Status from "@/constants/UserStatus";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import {
  DataTableAction,
  DataTableFilterData,
  DataTableHeader,
  DataTableDetail,
  BreadcrumbPathItem,
} from "@/dataModel/interfaces";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import { throttle } from "lodash";

@Component({
  components: {
    Breadcrumb,
    DataTable,
    DataTableFilter,
    TablePagination,
    EditUserDialog,
    BulkEditDialog,
    ConfirmStatusChangeDialog,
    ResultsPerPageButton,
  },
})
export default class UserManagementPage extends Vue {
  allRoles: any[] = [];
  perPage = 20;
  page: number = 1;
  users: User[] = [];
  resultCount: number = 0;
  existingUser: any = {};
  searchTerm: string = "";
  statusFilters: string[] = [];
  sortOrder: string = "ascending";
  sortColumn: string = "email"; // Change
  selectedUsersIds: string[] = [];
  selectedItems: User[] = [];
  selectedUserStatuses: string[] = [];
  statusAction: number = 0;
  throttleSearchMethod: any;
  isBulkEdit: boolean = true;
  isSearchLoading: boolean = true;

  private userHttpHelper = container.get<UserHttpHelper>(serviceTypes.UserHttpHelper);
  private roleHttpHelper = container.get<RoleHttpHelper>(serviceTypes.RoleHttpHelper);
  private appConfig = container.get<AppConfig>(serviceTypes.AppConfig);

  StatusActions = {
    ACTIVATE: 0,
    DEACTIVATE: 1,
    APPROVE: 2,
    DENY: 3,
  };

  path: BreadcrumbPathItem[] = [
    {
      text: "Administration",
    },
    {
      text: "User Management",
    },
  ];

  actions: DataTableAction[] = [
    {
      name: "Edit",
      icon: "fa-pen",
      action: "edit",
    },
  ];

  details: DataTableDetail[] = [
    {
      name: "Display Name",
      key: "displayName",
    },
    {
      name: "Last Login",
      key: "lastLogin",
      type: "date",
    },
    {
      name: "Reason for Approval",
      key: "approvalJustification",
    },
  ];

  bulkActions: DataTableAction[] = [
    {
      name: "Activate",
      icon: "fa-plus",
      action: "activate",
    },
    {
      name: "Deactivate",
      icon: "fa-minus",
      action: "deactivate",
    },
    {
      name: "Approve Access",
      icon: "fa-check",
      action: "approveAccess",
    },
    {
      name: "Deny Access",
      icon: "fa-times",
      action: "denyAccess",
    },
    {
      name: "Bulk Edit",
      icon: "fa-pen",
      action: "bulkEdit",
    },
  ];

  mockHeaders: DataTableHeader[] = [
    {
      label: "Email",
      key: "email",
      sortable: true,
    },
    {
      label: "Organization",
      key: "organization",
      sortable: true,
    },
    {
      label: "Status",
      key: "userStatus",
      sortable: true,
      formatter: this.addSpaces,
    },
    {
      // header for actions
      label: "",
      key: "actions",
      sortable: false,
    },
    {
      // header for show details
      label: "",
      key: "show_details",
      sortable: false,
    },
  ];

  filters: DataTableFilterData[] = [
    {
      name: "status",
      filters: ["Active", "Inactive", "Access Requested"],
    },
  ];

  get selectedUsersWithoutRoles(): User[] {
    return this.selectedItems.filter((user) => !user.actor || !user.actor.readOnlyRoles.length);
  }

  async getAllRoleMetaData(): Promise<void> {
    const response = await this.roleHttpHelper.getAllRoleMetaData();
    this.allRoles = response;
  }

  async searchUsers(): Promise<void> {
    (this.$refs.userTable as DataTable).clearSelections();

    try {
      this.isSearchLoading = true;
      const currentPage = this.page - 1;
      const response = await this.userHttpHelper.searchUsers(
        currentPage,
        this.perPage,
        this.searchTerm,
        this.sortOrder,
        this.sortColumn,
        this.statusFilters,
      );
      this.users = response.result;
      this.resultCount = response.count;
    } finally {
      this.isSearchLoading = false;
    }
  }

  async getUser(id: string): Promise<void> {
    const response = await this.userHttpHelper.getUser(id);
    this.existingUser = response;
  }

  async createUser(newUser: User): Promise<void> {
    await this.userHttpHelper.createUser(newUser);
    this.searchUsers();
    this.$bvToast.show("user-create-toast");
  }

  async editUser(updatedUser: User): Promise<void> {
    await this.userHttpHelper.updateUser(updatedUser);
    this.searchUsers();
    this.$bvToast.show("user-edit-toast");
  }

  async bulkEditStatus(status: number, skipRoleCheck = false): Promise<void> {
    switch (status) {
      case this.StatusActions.ACTIVATE:
        await this.userHttpHelper.bulkUpdateStatus(this.selectedUsersIds, Status.ACTIVE);
        break;
      case this.StatusActions.DEACTIVATE:
        await this.userHttpHelper.bulkUpdateStatus(this.selectedUsersIds, Status.INACTIVE);
        break;
      case this.StatusActions.APPROVE:
        if (this.selectedUsersWithoutRoles.length > 0 && !skipRoleCheck) {
          // require users to have roles applied when being approved
          this.isBulkEdit = false;
          this.$bvModal.show("bulk-edit-dialog");
          return;
        }
        await this.userHttpHelper.bulkUpdateStatus(this.selectedUsersIds, Status.ACTIVE);
        break;
      case this.StatusActions.DENY:
        await this.userHttpHelper.bulkUpdateStatus(this.selectedUsersIds, Status.DENIED_ACCESS);
        break;
    }

    if (!skipRoleCheck) {
      this.searchUsers();
      this.$bvToast.show("users-update-status-toast");
    }
  }

  async bulkEdit(roles: string[], userIdsToUpdate = this.selectedUsersIds): Promise<void> {
    await this.userHttpHelper.bulkUpdateRoles(userIdsToUpdate, roles);
    this.searchUsers();
    this.$bvToast.show("user-bulk-edit-toast");
  }

  async approveUsersAndAssignRoles(roleIds: string[]): Promise<void> {
    await this.bulkEditStatus(this.StatusActions.APPROVE, true);
    await this.bulkEdit(
      roleIds,
      this.selectedUsersWithoutRoles.map((user) => user.userId),
    );
  }

  bulkEditHandler(): void {
    this.isBulkEdit = true;
    (<BulkEditDialog>this.$refs.bulkEdit).clearUser();
    this.$bvModal.show("bulk-edit-dialog");
  }

  bulkEditStatusHandler(action: number): void {
    this.statusAction = action;
    this.$bvModal.show("confirm-status-dialog");
  }

  editUserHandler(item: any): void {
    this.getUser(item.userId);
    this.$bvModal.show("edit-user-dialog");
  }

  search(): void {
    if (this.searchTerm.trim() !== "") {
      this.sortColumn = "relevance";
      this.sortOrder = "ascending";
    }
    this.searchUsers();
    this.sortColumn = "email";
  }

  prevPage(): void {
    if (this.page > 1) {
      this.page--;
      this.searchUsers();
    }
  }

  nextPage(): void {
    if (this.hasNextPage) {
      this.page++;
      this.searchUsers();
    }
  }

  get hasNextPage(): boolean {
    return this.page * this.perPage < this.resultCount;
  }

  perPageAmountChanged(val: number) {
    this.perPage = val;
    this.page = 1;
    this.searchUsers();
  }

  sortingChanged(ctx: any): void {
    this.sortColumn = ctx.sortBy;
    this.sortOrder = ctx.sortDesc ? "descending" : "ascending";
    if (this.sortColumn === "userStatus") {
      this.sortColumn = "status";
    }
    this.searchUsers();
  }

  statusFiltersChanged(selectedFilters: string[]): void {
    this.statusFilters = selectedFilters;
    this.page = 1;
    this.searchUsers();
  }

  getSelectedUsers(users: any[]): void {
    this.selectedItems = users;
    this.selectedUsersIds = users.map(function (user: any) {
      return user.userId;
    });
    this.selectedUserStatuses = users.map(function (user: any) {
      return user.userStatus;
    });
  }

  addSpaces(status: string): string {
    return status.replace(/([A-Z])/g, " $1");
  }

  created(): void {
    this.getAllRoleMetaData();

    this.throttleSearchMethod = throttle(async () => {
      this.search();
    }, this.appConfig.searchRequestMinimumIntervalInMilliseconds);
  }

  mounted(): void {
    this.$root.$on("bv::modal::hide", (_, modalId: string) => {
      if (modalId === "bulk-edit-dialog" && !this.isBulkEdit) {
        // reset approval flow if dialog closed
        this.isBulkEdit = true;
      }
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.pagination {
  float: right;
}

.pagination-container {
  margin-top: 0.5rem;
}

.search-style {
  width: 50%;
  margin-top: 4px;
}

.hide {
  display: none;
}

.align-right {
  margin-left: auto;
}

.filters {
  margin-top: 4px;
}
</style>
