<template>
  <div class="usa-card__container">
    <header class="usa-card__header">
      <h2 class="usa-card__heading text-bold">{{ header }}</h2>
    </header>
    <div class="usa-card__media">
      <img
        :src="img"
        class="explore-icon"
        role="img"
        alt=""
        aria-hidden="true"
      />
    </div>
    <div class="usa-card__body">
      <p>{{ bodyText }}</p>
    </div>
    <div class="usa-card__footer">
      <button class="usa-button" @click="$emit('click')">
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ExploreCard extends Vue {
  @Prop({ required: true }) header!: string;
  @Prop({ required: true }) img!: string;
  @Prop({ required: true }) bodyText!: string;
  @Prop({ required: true }) buttonText!: string;
}
</script>

<style lang="scss" scoped>
$card-media-width: 9rem;

.explore-icon {
  width: 5rem;
}

.usa-card__media {
  background-color:  #f0f0f0;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media(min-width: 40em) {
  .usa-card__media {
    width: $card-media-width !important;
  }

  .usa-card__header, .usa-card__body, .usa-card__footer{
    margin-left: $card-media-width !important;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
</style>
