import HttpHelper from "@/components/resources/httpHelper";
import { Role } from "@/dataModel";
import { inject, injectable } from "inversify";
import serviceTypes from "@/dependencyInjection/types";

const roleUrl = "admin/roles/";

@injectable()
export default class RoleHttpHelper {
  httpHelper: HttpHelper;

  constructor(@inject(serviceTypes.HttpHelper) httpHelper: HttpHelper) {
    this.httpHelper = httpHelper;
  }

  async getRole(roleId: string): Promise<Role> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(roleUrl + roleId)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error("Response returned " + response.status + " : " + response.statusText + " getting role"));
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting role by id " + roleId));
        });
    });
  }

  async getAllRoleMetaData(): Promise<Role[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(roleUrl)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " getting all role meta data",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting all role meta data"));
        });
    });
  }

  async createRole(name: string, permissionIds: any): Promise<Role> {
    const data = {
      Name: name,
      PermissionIds: permissionIds,
    };

    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(roleUrl, data)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error("Response returned " + response.status + " : " + response.statusText + " creating role"));
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while creating role " + name));
        });
    });
  }

  async updateRole(roleId: string, permissionIds: string[]): Promise<Role> {
    const data = {
      Id: roleId,
      PermissionIds: permissionIds,
    };

    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPut(roleUrl, data)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error("Response returned " + response.status + " : " + response.statusText + " updating role"));
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while updating role " + roleId));
        });
    });
  }

  async deleteRole(roleId: string): Promise<Role> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpDelete(roleUrl + roleId)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error("Response returned " + response.status + " : " + response.statusText + " deleting role"));
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while deleting role " + roleId));
        });
    });
  }
}
