<template>
  <div v-if="originalFileAvailableForDownload" class="grid-row margin-top-105em">
    <div class="grid-col-8">
      <p class="usa-prose">
        The file below has been converted to a PDF. To download and view the file in its original format, click the
        download button to the right.
      </p>
    </div>
    <div class="grid-col-4 text-right">
      <button class="usa-button usa-button--outline margin-right-0" @click="onDownloadOrignalFileClicked">
        Download Original File<i class="fa fa-download margin-left-1" aria-hidden="true" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import serviceTypes from "@/dependencyInjection/types";
import container from "@/dependencyInjection/config";
import { AppConfig } from "@/dataModel";
import { FileType } from "@/constants/FileType";
import FileTypeSetting from "@/dataModel/fileUpload/fileTypeSetting";

@Component
export default class DownloadOriginalFile extends Vue {
  allowedFileTypes: FileTypeSetting[] = [];

  @Prop() readonly fileName: string | undefined;
  @Prop() readonly fileType: FileType | undefined;
  @Prop() readonly fileContents: string | undefined;
  @Prop() readonly availableForDownload!: boolean;

  readonly appConfig = container.get<AppConfig>(serviceTypes.AppConfig);

  get fileContentType(): string {
    let fileTypeSetting = this.allowedFileTypes.find(
      (fileTypeSetting) => fileTypeSetting.displayName == this.fileType?.toString().toUpperCase(),
    );
    return fileTypeSetting!.fileType!;
  }

  get originalFileAvailableForDownload(): boolean {
    return (
      this.availableForDownload && this.fileType != undefined && this.fileType.toString() != FileType[FileType.Pdf]
    );
  }

  @Emit("downloadOriginalFile")
  onDownloadOrignalFileClicked(): void {}

  @Watch("fileContents")
  onFileContentsChanged(): void {
    this.downloadOriginalFile();
  }

  downloadOriginalFile(): void {
    if (this.fileContents && this.fileName) {
      this.downloadHelper(this.fileContents, this.fileName, this.fileContentType);
    }
  }

  downloadHelper(byteArray: string, fileName: string, mimeType: string): void {
    let link = document.createElement("a");
    link.download = fileName;
    link.href = window.URL.createObjectURL(new Blob([byteArray], { type: mimeType }));
    link.click();
  }

  created(): void {
    this.allowedFileTypes = this.appConfig.fileUploadSettings.documents.allowedFileTypes;
  }
}
</script>

<style scoped lang="scss"></style>
