import { Vue, Component } from "vue-property-decorator";
import { throttle, join } from "lodash";
import Container from "@/dependencyInjection/config";
import { SiteDocument, AppConfig } from "@/dataModel";
import { SearchResponse } from "@/dataModel/responses";
import ServiceTypes from "@/dependencyInjection/types";
import SiteDocumentAdminHttpHelper from "@/components/resources/siteDocumentAdminHttpHelper";
import { SortColumn } from "@/dataModel/constants/SortColumn";

@Component
export class DocumentSearchMixin extends Vue {
  siteDocumentAdminHttpHelper = Container.get<SiteDocumentAdminHttpHelper>(ServiceTypes.SiteDocumentAdminHttpHelper);

  throttleSearchMethod: Function = () => {};
  isSearchLoading = false;
  isSearchBoxFocused: boolean = false;

  searchTerm = "";
  sortOrder = "ascending";
  sortColumn = SortColumn.Title;

  // Pagenation
  perPage = 20;
  page = 1;
  resultCount = -1;

  audiences: string[] = [];
  categoryPaths: string[] = [];
  classifications: string[] = [];
  copyrightStatuses: boolean[] = [];
  disseminationControls: string[] = [];
  documentTypes: string[] = [];
  publicationStatuses: boolean[] = [];
  scis: string[] = [];
  documents: SiteDocument[] = [];

  readonly appConfig = Container.get<AppConfig>(ServiceTypes.AppConfig);

  created(): void {
    this.throttleSearchMethod = throttle(async () => {
      await this.searchDocuments();
    }, this.appConfig.searchRequestMinimumIntervalInMilliseconds);
  }

  async searchDocuments(): Promise<void> {
    try {
      this.isSearchLoading = true;
      const currentPage = this.page - 1;
      const response: SearchResponse<SiteDocument> = await this.siteDocumentAdminHttpHelper.searchSiteDocuments(
        currentPage,
        this.perPage,
        this.searchTerm,
        this.sortOrder,
        this.sortColumn,
        this.classifications,
        this.disseminationControls,
        this.categoryPaths,
        this.documentTypes,
        this.copyrightStatuses,
        this.audiences,
        this.scis,
        this.publicationStatuses,
      );
      this.documents = response.result;
      this.resultCount = response.count;
    } finally {
      this.isSearchLoading = false;
    }
  }

  documentSortChanged(ctx: any): void {
    if (ctx.sortBy === "lastUpdatedDateTime") {
      this.sortColumn = SortColumn.Updated;
    } else {
      this.sortColumn = ctx.sortBy;
    }
    this.sortOrder = ctx.sortDesc ? "descending" : "ascending";
    this.throttleSearchMethod();
  }

  //#region Pagenation

  prevPage(): void {
    if (this.page > 1) {
      this.page--;
      this.throttleSearchMethod();
    }
  }

  nextPage(): void {
    if (this.hasNextPage) {
      this.page++;
      this.throttleSearchMethod();
    }
  }

  perPageAmountChanged(val: number): void {
    this.perPage = val;
    this.page = 1;
    this.throttleSearchMethod();
  }

  get hasNextPage(): boolean {
    return this.page * this.perPage < this.resultCount;
  }

  //#endregion

  //#region Table cell formatters

  formatContributorList(contributors: string[]): string {
    return contributors.length > 0 ? join(contributors, "; ") : "None";
  }

  //#endregion
}
