import { Classification, DisseminationControl, Sci } from "@/dataModel";

export class TitlePermissions {
  classification?: Classification | null;
  disseminationControls?: DisseminationControl[] | null;
  scis?: Sci[] | null;

  constructor(
    classification?: Classification | null,
    disseminationControls?: DisseminationControl[] | null,
    scis?: Sci[] | null,
  ) {
    this.classification = classification ?? new Classification();
    this.disseminationControls = disseminationControls ?? [];
    this.scis = scis ?? [];
  }
}
