enum StoreNames {
  Auth = "auth",
  App = "app",
  StagedFile = "stagedFile",
  Request = "request",
  Document = "document",
  UserSearch = "userSearch",
  Permission = "permission",
  SiteDocumentManagement = "siteDocumentManagement",
  DataCollection = "dataCollection",
  DocumentModule = "documentModule",
  Setting = "setting",
}

export default StoreNames;
