import {
  UserSearchStoreActions,
  UserSearchStoreMutations,
} from "@/constants/store/userSearch/userSearchStoreConstants";
import { UserSearchParameters } from "@/dataModel";

export default {
  [UserSearchStoreActions.SET_SEARCH_PARAMETERS](
    { commit }: { commit: Function },
    searchParameters: UserSearchParameters,
  ): void {
    commit(UserSearchStoreMutations.SET_SEARCH_PARAMETERS, searchParameters);
  },
  [UserSearchStoreActions.SET_SEARCH_TERM]({ commit }: { commit: Function }, searchTerm: string): void {
    commit(UserSearchStoreMutations.SET_SEARCH_TERM, searchTerm);
  },
  [UserSearchStoreActions.SET_USE_SAVED_PARAMS]({ commit }: { commit: Function }, useSavedParams: boolean): void {
    commit(UserSearchStoreMutations.SET_USE_SAVED_PARAMS, useSavedParams);
  },
};
