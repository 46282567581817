<template>
  <main id="main-content">
    <section class="grid-container usa-section">
      <div class="grid-row grid-gap">
        <div class="grid-col-12" role="alert" aria-live="assertive">
          <h1 class="font-heading-xl margin-bottom-3">Error</h1>
          <div class="font-body-sm">
            <p>{{ message }}</p>
            <p>
              You will be redirected to the homepage within 10 seconds. If you are not redirected automatically,
              <router-link @click.native="cancelTimer" class="usa-link" to="/home">click here</router-link>.
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ErrorPage extends Vue {
  @Prop() readonly message!: string;

  timerId!: number;

  created() {
    this.timerId = setTimeout(() => this.$router.push({ path: "/home" }), 10000);
  }

  cancelTimer() {
    clearTimeout(this.timerId);
  }

  beforeDestroy() {
    this.cancelTimer();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
