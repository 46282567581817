<template>
  <div class="result-container">
    <div class="grid-row margin-x-2">
      <div class="grid-col" />
      <div class="grid-col-auto">
        <span v-html="classificationMarking" />
      </div>
      <div class="grid-col">
        <p v-if="!result.isCopyrightRestricted" class="full-width align-right margin-bottom-0">Full Text Available</p>
        <p class="usa-prose align-right margin-bottom-0">{{ formatDate(result.documentDateTime) }}</p>
      </div>
    </div>
    <div class="grid-row">
      <div class="desktop:grid-col-12 padding-left-2 padding-right-2 padding-top-2">
        <div class="header">
          <div class="margin-right-1 primary float-left">{{ result.titleClassificationMarking }}</div>
          <router-link
            :to="{ name: 'DocumentSummary', query: { id: result.id } }"
            v-if="result.highlights.hasOwnProperty('title')"
            v-html="result.highlights['title'][0]"
            class="usa-button usa-button--unstyled header"
          />
          <router-link
            v-else
            class="usa-button usa-button--unstyled header"
            :to="{ name: 'DocumentSummary', query: { id: result.id } }"
            >{{ result.title }}
          </router-link>
        </div>
        <p v-if="abstractSectionToShow.length > 0" class="usa-prose margin-top-2">
          <span class="bold">Abstract: </span>
          <span v-html="abstractSectionToShow"></span>
        </p>
        <p class="usa-prose margin-top-2">
          <span v-if="result.highlights.hasOwnProperty('journalName')">
            <span class="bold">Journal: </span>
            <span v-html="result.highlights['journalName'][0]"></span>
          </span>
        </p>
        <p class="usa-prose margin-top-2">
          <span v-if="result.highlights.hasOwnProperty('contributors')">
            <span class="bold">Contributors: </span>
            <span v-html="parseContributorHighlights(result.highlights['contributors'])"></span>
          </span>
        </p>
        <p v-if="result.highlights.hasOwnProperty('fullText')" class="usa-prose margin-top-2">
          <span class="bold">Full Text Matches: </span>
          <span v-html="allFullTextMatches"></span>
        </p>
      </div>
    </div>
    <div class="grid-row">
      <div class="classification full-width mt-2">
        <span v-html="classificationMarking" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { DocumentSearchResult } from "@/dataModel";
import DatePrecision from "@/constants/DatePrecision";
import moment from "moment";

@Component({
  components: {},
})
export default class SearchResults extends Vue {
  @Prop() searchTerm: string | undefined;
  @Prop({ required: true }) result!: DocumentSearchResult;
  abstractSectionToShow: string = "";
  allFullTextMatches: string = "";
  pageJustCreated: boolean = false;

  created(): void {
    this.setResultValues();
    this.pageJustCreated = true;
  }

  beforeUpdate(): void {
    if (!this.pageJustCreated) {
      this.setResultValues();
    } else {
      this.pageJustCreated = false;
    }
  }

  setResultValues(): void {
    if (this.result?.highlights.hasOwnProperty("abstract")) {
      this.abstractSectionToShow = "... " + this.result?.highlights["abstract"].join(" ... ") + " ...";
    } else if (this.result?.abstract !== undefined && this.result?.abstract !== null) {
      this.abstractSectionToShow =
        this.result.abstract.length < 200 ? this.result.abstract : this.result.abstract.slice(0, 200) + " ...";
    }
    if (this.result?.highlights.hasOwnProperty("fullText")) {
      this.allFullTextMatches = "... " + this.result?.highlights["fullText"].join(" ... ") + " ...";
    }
  }

  parseTextFragments(fragments: string[]): string {
    let piecesToJoin: string[] = [];
    let i = 0;
    while (piecesToJoin.join("...").length < 200 && i <= fragments.length - 1) {
      piecesToJoin.push(fragments[i]);
      i++;
    }
    return " " + piecesToJoin.join("...");
  }

  parseContributorHighlights(fragments: string[]): string {
    const allContributors = this.result?.contributors ?? [];
    const fragmentsMinusTags: string[] = [];
    for (let i = 0; i < fragments.length; i++) {
      const cleanFragment = fragments[i].replace("<mark>", "").replace("</mark>", "");
      fragmentsMinusTags.push(cleanFragment);
    }
    for (let i = 0; i < fragmentsMinusTags.length; i++) {
      for (let j = 0; j < allContributors.length; j++) {
        if (allContributors[j] == fragmentsMinusTags[i]) {
          allContributors[j] = fragments[i];
          break;
        }
      }
    }
    return allContributors.join("; ");
  }

  formatDate(date: Date): string {
    let processedDate = moment(date).utc();
    if (!processedDate.isValid()) {
      return "";
    }
    if (this.result?.documentDatePrecision == DatePrecision.Day ?? true) return processedDate.format("YYYY-MM-DD");
    else if (this.result?.documentDatePrecision == DatePrecision.Month ?? false)
      return processedDate.format("MMMM YYYY");
    else return processedDate.format("YYYY");
  }

  toDocument(): void {
    this.$router.push({ name: "DocumentSummary", query: { id: this.result?.id } });
  }

  toFullText(): void {
    this.$router.push({ name: "DocumentFileView" });
  }

  get classificationMarking(): string {
    // replace all sections ending in punctuation with non-wrapping spans
    let classificationMarking = this.result.classificationMarking;
    let regex = /.*?(\/\/|\/|$)/g;
    let formatted = classificationMarking.replaceAll(regex, '<span class="no-wrap">$&</span>');
    return formatted;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

:deep(mark) {
  background-color: #d0ecfead !important;
}

.grid-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.bold {
  font-weight: font-weight("bold");
}

.header {
  font-family: family("heading");
  font-size: size("heading", "lg");
  line-height: lh("heading", 2);
}

a.header {
  display: inline;
}

.line-break {
  background: #c9c9c9;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.right-col {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.primary {
  color: color("primary");
}

.title {
  margin-bottom: 0.3rem;
  margin-top: units(2);
}

.full-width {
  width: 100%;
}

.classification {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
}

.red-text {
  color: color("secondary-dark");
}

#left-top {
  display: flex;
  justify-content: space-between;
  justify-content: left;
}

.result-container {
  border: 1px solid #c9c9c9;
  border-radius: 10px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: units(3);
  padding-top: units(2);
  padding-bottom: units(2);
}

.result-container:last-of-type {
  margin-bottom: 0px;
}

.classification :deep(.no-wrap) {
  white-space: nowrap;
  display: inline-block;
}
</style>
