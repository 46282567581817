<template>
  <div>
    <basic-dialog id="inactive-dialog" title="Logged out" primaryButton="OK" :primaryClick="close">
      <p class="usa-prose mb-0">You have been logged out due to inactivity.</p>
    </basic-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";

@Component({
  components: {
    BasicDialog,
  },
})
export default class InactiveDialog extends Vue {
  close() {
    this.$bvModal.hide("inactive-dialog");
  }
}
</script>
