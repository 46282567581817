import { render, staticRenderFns } from "./LoadingButtons.vue?vue&type=template&id=415d8b45&scoped=true"
import script from "./LoadingButtons.vue?vue&type=script&lang=ts"
export * from "./LoadingButtons.vue?vue&type=script&lang=ts"
import style0 from "./LoadingButtons.vue?vue&type=style&index=0&id=415d8b45&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "415d8b45",
  null
  
)

export default component.exports