import HttpHelper from "@/components/resources/httpHelper";
import { SiteDocument } from "@/dataModel";
import { RetrainWithMetadataRequest, MAEUploadFileRequest } from "@/dataModel/requests/";
import { inject, injectable } from "inversify";
import serviceTypes from "@/dependencyInjection/types";
import { MAEFileStatusResponse } from "@/dataModel/documentStaging/MAEFileStatusResponse";
import { MAEUploadBulkFileRequest } from "@/dataModel/requests/MAEUploadBulkFileRequest";

const autoExtractionUrl = "admin/auto-extract/";

@injectable()
export default class MetadataAutoExtractionHttpHelper {
  httpHelper: HttpHelper;

  constructor(@inject(serviceTypes.HttpHelper) httpHelper: HttpHelper) {
    this.httpHelper = httpHelper;
  }

  async getFileStatus(id: string): Promise<MAEFileStatusResponse> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(autoExtractionUrl + id)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                `Response returned \"${response.status}: ${response.statusText}\" 
                getting Metadata Auto-Extraction file status.`,
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error(`An error occurred while getting Metadata Auto-Extraction file status by id: ${id}`));
        });
    });
  }

  // Returns Guid of file uploaded for use with other Mesur.io API calls
  async uploadFileForExtraction(request: MAEUploadFileRequest): Promise<string> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(autoExtractionUrl, request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                `Response returned \"${response.status}: ${response.statusText}\" 
                uploading file to Metadata Auto-Extraction service.`,
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while uploading file to Metadata Auto-Extraction service."));
        });
    });
  }

  // Returns Guids of files uploaded for use with other Mesur.io API calls
  async uploadFileForExtractionBulk(request: MAEUploadBulkFileRequest): Promise<string[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(autoExtractionUrl + "bulk", request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                `Response returned \"${response.status}: ${response.statusText}\" 
                uploading bulk file to Metadata Auto-Extraction service.`,
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while uploading bulk file to Metadata Auto-Extraction service."));
        });
    });
  }

  async getMetadata(id: string): Promise<SiteDocument> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(autoExtractionUrl + "metadata/" + id)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                `Response returned \"${response.status}: ${response.statusText}\" 
                getting metadata for file with id: ${id} from Metadata Auto-Extraction service.`,
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting auto-extracted metadata for file with id: " + id));
        });
    });
  }

  async retrainWithMetadata(request: RetrainWithMetadataRequest): Promise<void> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(autoExtractionUrl + "retrain", request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                `Response returned \"${response.status}: ${response.statusText}\" 
                uploading metadata file to Metadata Auto-Extraction service.`,
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while uploading metadata file to Metadata Auto-Extraction service."));
        });
    });
  }
}
