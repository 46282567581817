<template>
    <div class="spinner"/>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";


@Component
export default class Spinner extends Vue{}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.spinner-sm {
    width: 30px !important;
    height: 30px !important;
    border-width: 4px !important;
}

.spinner-lg {
    width: 120px !important;
    height: 120px !important;
    border-width: 16px !important;
}

.spinner {
    border-style: solid;
    border-color: #dfe1e2;
    border-top-color: #005ea2;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    border-width: 8px;
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>