<template>
  <div>
    <div class="grid-row flex-align-center feat-doc-heading-container">
      <div class="grid-col-2 pr-2">
        <img class="explore-icon" src="../../../assets/img/icons/Document.svg" role="img" alt="" aria-hidden="true" />
      </div>
      <div class="grid-col-10">
        <h3 class="font-heading-lg text-primary-dark text-bold mb-0" id="documents-head">Documents</h3>
      </div>
    </div>
    <template v-if="isLoading">
      <spinner />
    </template>
    <template v-else>
      <template v-if="documents && documents.length > 0">
        <ul class="resource-list">
          <li v-for="(document, index) in documents" :key="index" class="featured-document-item">
            <router-link class="usa-link" :to="{ name: 'DocumentSummary', query: { id: document.siteDocumentId } }">
              {{ getTitleWithClassificationMarking(document.titleClassification, document.title) }}
            </router-link>
            <template v-if="document.contributors && document.contributors.length > 0">
              <br />
              {{ getFormattedContributors(document.contributors) }}
            </template>
          </li>
        </ul>
      </template>
      <template v-else>
        <p class="mt-0 empty-list">No documents featured at this time.</p>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Spinner from "@/components/common/Spinner.vue";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import FeaturedResourceHttpHelper from "@/components/resources/featuredResourceHttpHelper";
import { AppConfig, FeaturedDocument } from "@/dataModel";

@Component({
  components: {
    Spinner,
  },
})
export default class FeaturedDocumentsSection extends Vue {
  private featuredResourceHttpHelper = container.get<FeaturedResourceHttpHelper>(
    serviceTypes.FeaturedResourceHttpHelper,
  );
  private appConfig = container.get<AppConfig>(serviceTypes.AppConfig);

  isLoading: boolean = true;
  documents: FeaturedDocument[] = [];

  getTitleWithClassificationMarking(titleClassification: string, title: string): string {
    return titleClassification + " " + title;
  }

  getFormattedContributors(contributors: string[]) {
    if (contributors && contributors.length > 0) {
      return contributors.join("; ");
    }
    return "";
  }

  mounted() {
    (async () => {
      try {
        this.documents = await this.featuredResourceHttpHelper.getFeaturedDocuments(
          this.appConfig.featuredDocumentsLimit,
        );
      } finally {
        this.isLoading = false;
      }
    })();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.resource-list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
}

.resource-list-heading {
  margin-bottom: 0.5em;
}

.featured-document-item {
  border-bottom: 1px solid black;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
}

.empty-list {
  padding: 0.5rem 1.5rem;
}

.feat-doc-heading-container {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid black;
}

.usa-link:visited {
  color: #005ea2;
}
</style>
