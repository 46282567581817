<template>
  <div id="pdfViewerWrapper">
    <div id="appContainer" tabindex="1">
      <div id="outerContainer">
        <div id="sidebarContainer">
          <div id="toolbarSidebar">
            <div id="toolbarSidebarLeft">
              <div class="splitToolbarButton toggled">
                <button
                  id="viewThumbnail"
                  class="toolbarButton toggled"
                  title="Show Thumbnails"
                  tabindex="2"
                  data-l10n-id="thumbs"
                >
                  <span data-l10n-id="thumbs_label">Thumbnails</span>
                </button>
                <button
                  id="viewOutline"
                  class="toolbarButton"
                  title="Show Document Outline (double-click to expand/collapse all items)"
                  tabindex="3"
                  data-l10n-id="document_outline"
                >
                  <span data-l10n-id="document_outline_label"> Document Outline </span>
                </button>
              </div>
            </div>

            <div id="toolbarSidebarRight">
              <div id="outlineOptionsContainer" class="hidden">
                <div class="verticalToolbarSeparator"></div>

                <button
                  id="currentOutlineItem"
                  class="toolbarButton"
                  disabled="disabled"
                  title="Find Current Outline Item"
                  tabindex="6"
                  data-l10n-id="current_outline_item"
                >
                  <span data-l10n-id="current_outline_item_label"> Current Outline Item </span>
                </button>
              </div>
            </div>
          </div>
          <div id="sidebarContent">
            <div id="thumbnailView"></div>
            <div id="outlineView" class="hidden"></div>
          </div>
          <div id="sidebarResizer"></div>
        </div>
        <!-- sidebarContainer -->

        <div id="mainContainer">
          <div class="findbar hidden doorHanger" id="findbar">
            <div id="findbarInputContainer">
              <input
                id="findInput"
                class="toolbarField"
                title="Find"
                placeholder="Find in document…"
                tabindex="91"
                data-l10n-id="find_input"
              />
              <div class="splitToolbarButton">
                <button
                  id="findPrevious"
                  class="toolbarButton findPrevious"
                  title="Find the previous occurrence of the phrase"
                  tabindex="92"
                  data-l10n-id="find_previous"
                >
                  <span data-l10n-id="find_previous_label">Previous</span>
                </button>
                <div class="splitToolbarButtonSeparator"></div>
                <button
                  id="findNext"
                  class="toolbarButton findNext"
                  title="Find the next occurrence of the phrase"
                  tabindex="93"
                  data-l10n-id="find_next"
                >
                  <span data-l10n-id="find_next_label">Next</span>
                </button>
              </div>
            </div>

            <div id="findbarOptionsOneContainer">
              <input type="checkbox" id="findHighlightAll" class="toolbarField" tabindex="94" />
              <label for="findHighlightAll" class="toolbarLabel" data-l10n-id="find_highlight">Highlight all</label>
              <input type="checkbox" id="findMatchCase" class="toolbarField" tabindex="95" />
              <label for="findMatchCase" class="toolbarLabel" data-l10n-id="find_match_case_label">Match case</label>
            </div>
            <div id="findbarOptionsTwoContainer">
              <input type="checkbox" id="findEntireWord" class="toolbarField" tabindex="96" />
              <label for="findEntireWord" class="toolbarLabel" data-l10n-id="find_entire_word_label">Whole words</label>
              <span id="findResultsCount" class="toolbarLabel hidden"></span>
            </div>

            <div id="findbarMessageContainer">
              <span id="findMsg" class="toolbarLabel"></span>
            </div>
          </div>
          <!-- findbar -->

          <div id="secondaryToolbar" class="secondaryToolbar hidden doorHangerRight">
            <div id="secondaryToolbarButtonContainer">
              <button
                id="secondaryPresentationMode"
                class="secondaryToolbarButton presentationMode visibleLargeView"
                title="Switch to Presentation Mode"
                tabindex="51"
                data-l10n-id="presentation_mode"
              >
                <span data-l10n-id="presentation_mode_label">Presentation Mode</span>
              </button>

              <button
                v-if="allowDownload"
                id="secondaryDownload"
                class="secondaryToolbarButton download visibleMediumView"
                title="Download"
                tabindex="54"
                data-l10n-id="download"
              >
                <span data-l10n-id="download_label">Download</span>
              </button>

              <div class="horizontalToolbarSeparator visibleLargeView"></div>

              <button
                id="firstPage"
                class="secondaryToolbarButton firstPage"
                title="Go to First Page"
                tabindex="56"
                data-l10n-id="first_page"
              >
                <span data-l10n-id="first_page_label">Go to First Page</span>
              </button>
              <button
                id="lastPage"
                class="secondaryToolbarButton lastPage"
                title="Go to Last Page"
                tabindex="57"
                data-l10n-id="last_page"
              >
                <span data-l10n-id="last_page_label">Go to Last Page</span>
              </button>

              <div class="horizontalToolbarSeparator"></div>

              <button
                id="pageRotateCw"
                class="secondaryToolbarButton rotateCw"
                title="Rotate Clockwise"
                tabindex="58"
                data-l10n-id="page_rotate_cw"
              >
                <span data-l10n-id="page_rotate_cw_label">Rotate Clockwise</span>
              </button>
              <button
                id="pageRotateCcw"
                class="secondaryToolbarButton rotateCcw"
                title="Rotate Counterclockwise"
                tabindex="59"
                data-l10n-id="page_rotate_ccw"
              >
                <span data-l10n-id="page_rotate_ccw_label">Rotate Counterclockwise</span>
              </button>

              <div class="horizontalToolbarSeparator"></div>

              <button
                id="cursorSelectTool"
                class="secondaryToolbarButton selectTool toggled"
                title="Enable Text Selection Tool"
                tabindex="60"
                data-l10n-id="cursor_text_select_tool"
              >
                <span data-l10n-id="cursor_text_select_tool_label">Text Selection Tool</span>
              </button>
              <button
                id="cursorHandTool"
                class="secondaryToolbarButton handTool"
                title="Enable Hand Tool"
                tabindex="61"
                data-l10n-id="cursor_hand_tool"
              >
                <span data-l10n-id="cursor_hand_tool_label">Hand Tool</span>
              </button>

              <div class="horizontalToolbarSeparator"></div>

              <button
                id="scrollVertical"
                class="secondaryToolbarButton scrollModeButtons scrollVertical toggled"
                title="Use Vertical Scrolling"
                tabindex="62"
                data-l10n-id="scroll_vertical"
              >
                <span data-l10n-id="scroll_vertical_label">Vertical Scrolling</span>
              </button>
              <button
                id="scrollHorizontal"
                class="secondaryToolbarButton scrollModeButtons scrollHorizontal"
                title="Use Horizontal Scrolling"
                tabindex="63"
                data-l10n-id="scroll_horizontal"
              >
                <span data-l10n-id="scroll_horizontal_label">Horizontal Scrolling</span>
              </button>
              <button
                id="scrollWrapped"
                class="secondaryToolbarButton scrollModeButtons scrollWrapped"
                title="Use Wrapped Scrolling"
                tabindex="64"
                data-l10n-id="scroll_wrapped"
              >
                <span data-l10n-id="scroll_wrapped_label">Wrapped Scrolling</span>
              </button>

              <div class="horizontalToolbarSeparator scrollModeButtons"></div>

              <button
                id="spreadNone"
                class="secondaryToolbarButton spreadModeButtons spreadNone toggled"
                title="Do not join page spreads"
                tabindex="65"
                data-l10n-id="spread_none"
              >
                <span data-l10n-id="spread_none_label">No Spreads</span>
              </button>
              <button
                id="spreadOdd"
                class="secondaryToolbarButton spreadModeButtons spreadOdd"
                title="Join page spreads starting with odd-numbered pages"
                tabindex="66"
                data-l10n-id="spread_odd"
              >
                <span data-l10n-id="spread_odd_label">Odd Spreads</span>
              </button>
              <button
                id="spreadEven"
                class="secondaryToolbarButton spreadModeButtons spreadEven"
                title="Join page spreads starting with even-numbered pages"
                tabindex="67"
                data-l10n-id="spread_even"
              >
                <span data-l10n-id="spread_even_label">Even Spreads</span>
              </button>

              <div class="horizontalToolbarSeparator spreadModeButtons"></div>

              <button
                id="documentProperties"
                class="secondaryToolbarButton documentProperties"
                title="Document Properties…"
                tabindex="68"
                data-l10n-id="document_properties"
              >
                <span data-l10n-id="document_properties_label">Document Properties…</span>
              </button>
            </div>
          </div>
          <!-- secondaryToolbar -->

          <div class="toolbar">
            <div id="toolbarContainer">
              <div id="toolbarViewer">
                <div id="toolbarViewerLeft">
                  <button
                    id="sidebarToggle"
                    class="toolbarButton"
                    title="Toggle Sidebar"
                    tabindex="11"
                    data-l10n-id="toggle_sidebar"
                    aria-expanded="false"
                    aria-controls="sidebarContainer"
                  >
                    <span data-l10n-id="toggle_sidebar_label">Toggle Sidebar</span>
                  </button>
                  <div class="toolbarButtonSpacer"></div>
                  <button
                    id="viewFind"
                    class="toolbarButton"
                    title="Find in Document"
                    tabindex="12"
                    data-l10n-id="findbar"
                    aria-expanded="false"
                    aria-controls="findbar"
                  >
                    <span data-l10n-id="findbar_label">Find</span>
                  </button>
                  <div class="splitToolbarButton hiddenSmallView">
                    <button
                      class="toolbarButton pageUp"
                      title="Previous Page"
                      id="previous"
                      tabindex="13"
                      data-l10n-id="previous"
                    >
                      <span data-l10n-id="previous_label">Previous</span>
                    </button>
                    <div class="splitToolbarButtonSeparator"></div>
                    <button
                      class="toolbarButton pageDown"
                      title="Next Page"
                      id="next"
                      tabindex="14"
                      data-l10n-id="next"
                    >
                      <span data-l10n-id="next_label">Next</span>
                    </button>
                  </div>
                  <input
                    type="number"
                    id="pageNumber"
                    class="toolbarField pageNumber"
                    title="Page"
                    value="1"
                    size="4"
                    min="1"
                    tabindex="15"
                    data-l10n-id="page"
                    autocomplete="off"
                  />
                  <span id="numPages" class="toolbarLabel"></span>
                </div>
                <div id="toolbarViewerRight">
                  <button
                    id="presentationMode"
                    class="toolbarButton presentationMode hiddenLargeView"
                    title="Switch to Presentation Mode"
                    tabindex="31"
                    data-l10n-id="presentation_mode"
                  >
                    <span data-l10n-id="presentation_mode_label">Presentation Mode</span>
                  </button>

                  <button
                    v-if="allowDownload"
                    id="download"
                    class="toolbarButton download hiddenMediumView"
                    title="Download"
                    tabindex="34"
                    data-l10n-id="download"
                  >
                    <span data-l10n-id="download_label">Download</span>
                  </button>

                  <div class="verticalToolbarSeparator hiddenSmallView"></div>

                  <button
                    id="secondaryToolbarToggle"
                    class="toolbarButton"
                    title="Tools"
                    tabindex="36"
                    data-l10n-id="tools"
                    aria-expanded="false"
                    aria-controls="secondaryToolbar"
                  >
                    <span data-l10n-id="tools_label">Tools</span>
                  </button>
                </div>
                <div id="toolbarViewerMiddle">
                  <div class="splitToolbarButton">
                    <button
                      id="zoomOut"
                      class="toolbarButton zoomOut"
                      title="Zoom Out"
                      tabindex="21"
                      data-l10n-id="zoom_out"
                    >
                      <span data-l10n-id="zoom_out_label">Zoom Out</span>
                    </button>
                    <div class="splitToolbarButtonSeparator"></div>
                    <button
                      id="zoomIn"
                      class="toolbarButton zoomIn"
                      title="Zoom In"
                      tabindex="22"
                      data-l10n-id="zoom_in"
                    >
                      <span data-l10n-id="zoom_in_label">Zoom In</span>
                    </button>
                  </div>
                  <span id="scaleSelectContainer" class="dropdownToolbarButton">
                    <select id="scaleSelect" title="Zoom" tabindex="23" data-l10n-id="zoom">
                      <option
                        id="pageAutoOption"
                        title=""
                        value="auto"
                        selected="selected"
                        data-l10n-id="page_scale_auto"
                      >
                        Automatic Zoom
                      </option>
                      <option id="pageActualOption" title="" value="page-actual" data-l10n-id="page_scale_actual">
                        Actual Size
                      </option>
                      <option id="pageFitOption" title="" value="page-fit" data-l10n-id="page_scale_fit">
                        Page Fit
                      </option>
                      <option id="pageWidthOption" title="" value="page-width" data-l10n-id="page_scale_width">
                        Page Width
                      </option>
                      <option id="customScaleOption" title="" value="custom" disabled="disabled" hidden="true"></option>
                      <option title="" value="0.5" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 50 }'>
                        50%
                      </option>
                      <option title="" value="0.75" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 75 }'>
                        75%
                      </option>
                      <option title="" value="1" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 100 }'>
                        100%
                      </option>
                      <option title="" value="1.25" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 125 }'>
                        125%
                      </option>
                      <option title="" value="1.5" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 150 }'>
                        150%
                      </option>
                      <option title="" value="2" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 200 }'>
                        200%
                      </option>
                      <option title="" value="3" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 300 }'>
                        300%
                      </option>
                      <option title="" value="4" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 400 }'>
                        400%
                      </option>
                    </select>
                  </span>
                </div>
              </div>
              <div id="loadingBar">
                <div class="progress">
                  <div class="glimmer"></div>
                </div>
              </div>
            </div>
          </div>

          <div id="viewerContainer" tabindex="0">
            <div id="viewer" class="pdfViewer"></div>
          </div>

          <div id="errorWrapper" hidden="true">
            <div id="errorMessageLeft">
              <span id="errorMessage"></span>
              <button id="errorShowMore" data-l10n-id="error_more_info">More Information</button>
              <button id="errorShowLess" data-l10n-id="error_less_info" hidden="true">Less Information</button>
            </div>
            <div id="errorMessageRight">
              <button id="errorClose" data-l10n-id="error_close">Close</button>
            </div>
            <div class="clearBoth"></div>
            <textarea id="errorMoreInfo" hidden="true" readonly="readonly"></textarea>
          </div>
        </div>
        <!-- mainContainer -->

        <div id="overlayContainer" class="hidden">
          <div id="documentPropertiesOverlay" class="container hidden">
            <div class="dialog">
              <div class="row">
                <span data-l10n-id="document_properties_file_name">File name:</span>
                <p id="fileNameField">-</p>
              </div>
              <div class="row">
                <span data-l10n-id="document_properties_file_size">File size:</span>
                <p id="fileSizeField">-</p>
              </div>
              <div class="separator"></div>
              <div class="row">
                <span data-l10n-id="document_properties_title">Title:</span>
                <p id="titleField">-</p>
              </div>
              <div class="row">
                <span data-l10n-id="document_properties_author">Author:</span>
                <p id="authorField">-</p>
              </div>
              <div class="row">
                <span data-l10n-id="document_properties_subject">Subject:</span>
                <p id="subjectField">-</p>
              </div>
              <div class="row">
                <span data-l10n-id="document_properties_keywords">Keywords:</span>
                <p id="keywordsField">-</p>
              </div>
              <div class="row">
                <span data-l10n-id="document_properties_creation_date">Creation Date:</span>
                <p id="creationDateField">-</p>
              </div>
              <div class="row">
                <span data-l10n-id="document_properties_modification_date">Modification Date:</span>
                <p id="modificationDateField">-</p>
              </div>
              <div class="row">
                <span data-l10n-id="document_properties_creator">Creator:</span>
                <p id="creatorField">-</p>
              </div>
              <div class="separator"></div>
              <div class="row">
                <span data-l10n-id="document_properties_producer">PDF Producer:</span>
                <p id="producerField">-</p>
              </div>
              <div class="row">
                <span data-l10n-id="document_properties_version">PDF Version:</span>
                <p id="versionField">-</p>
              </div>
              <div class="row">
                <span data-l10n-id="document_properties_page_count">Page Count:</span>
                <p id="pageCountField">-</p>
              </div>
              <div class="row">
                <span data-l10n-id="document_properties_page_size">Page Size:</span>
                <p id="pageSizeField">-</p>
              </div>
              <div class="separator"></div>
              <div class="row">
                <span data-l10n-id="document_properties_linearized">Fast Web View:</span>
                <p id="linearizedField">-</p>
              </div>
              <div class="buttonRow">
                <button id="documentPropertiesClose" class="overlayButton">
                  <span data-l10n-id="document_properties_close">Close</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- overlayContainer -->
      </div>
      <!-- outerContainer -->
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { PDFViewerApplication, webViewerLoad } from "pdfjs-dist/web/viewer";
import "worker-loader?esModule=false&filename=[name].js!pdfjs-dist/build/pdf.worker.js";

@Component
export default class PdfViewer extends Vue {
  @Prop() file!: string | ArrayBuffer;

  @Prop({ default: false }) allowDownload!: boolean;

  addLocaleLink(): void {
    const link = document.createElement("link");
    link.setAttribute("rel", "resource");
    link.setAttribute("type", "application/l10n");
    link.setAttribute("href", "/locale/viewer.properties");
    link.setAttribute("id", "l10n");

    document.head.appendChild(link);
  }

  mounted(): void {
    this.addLocaleLink();
    webViewerLoad();
    PDFViewerApplication.open(this.file);
  }

  beforeDestroy(): void {
    PDFViewerApplication.close();
    // remove locale link
    document.getElementById("l10n")?.remove();
  }
}
</script>

<style src="pdfjs-dist/web/viewer.css">
/* imported styles */
</style>

<style lang="scss" scoped>
#pdfViewerWrapper {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
  box-sizing: content-box;
  z-index: 0;
}

button:not([disabled]):focus,
input:not([disabled]):focus {
  outline: 0 !important;
}
</style>
