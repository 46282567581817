<template>
  <data-table
    @edit="editCategory"
    @delete="deleteCategory"
    :items="filteredTableData"
    :fields="headers"
    :actions="actions"
    :noLocalSort="false"
    :perPage="resultsPerPage"
    ref="categoryTable"
  >
    <template slot="top-row" v-if="!isTableDataNullOrEmpty(tableData)">
      <td key="displayName">
        <input class="search" placeholder="Search Name" v-model="searchTerm" />
      </td>
      <td key="hasChildren" />
      <td key="view" />
      <td key="actions" />
    </template>
    <template #cell(displayName)="data">
      <div class="margin-y-05 table-col-md overflow-ellipsis">
        <span class="bold" :class="{ 'text-italic': categoryService.isItalicFormat(data.item) }">{{ data.value }}</span>
      </div>
    </template>
    <template #cell(view)="data">
      <div class="margin-y-05 table-col-sm">
        <a href="#/" @click="viewSubCategory(data.item.id)" class="data-table-item-action usa-link">
          <span>View</span>
        </a>
      </div>
    </template>
  </data-table>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import DataTable from "@/components/common/DataTable.vue";
import { DataTableAction, DataTableHeader } from "@/dataModel/interfaces";
import { Category } from "@/dataModel";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import CategoryService from "@/services/interfaces/ICategoryService";

@Component({
  components: {
    DataTable,
  },
})
export default class CategoryDataTable extends Vue {
  @Prop({ required: true }) tableData!: Category[];

  filteredTableData: Category[] = [];
  searchTerm: string = "";
  resultsPerPage: number = 1000000;

  private categoryService = container.get<CategoryService>(serviceTypes.CategoryService);

  actions: DataTableAction[] = [
    {
      name: "Edit",
      icon: "fa-pen",
      action: "edit",
    },
    {
      name: "Delete",
      icon: "fa-trash",
      action: "delete",
    },
  ];

  headers: DataTableHeader[] = [
    {
      label: "Display Name",
      key: "displayName",
      sortable: true,
    },
    {
      label: "Subcategory",
      key: "hasChildren",
      formatter: this.formatHasChildren,
      sortable: true,
    },
    {
      label: "View Subcategory",
      key: "view",
      sortable: false,
    },
    {
      // header for actions
      label: "",
      key: "actions",
      sortable: false,
    },
  ];

  @Watch("searchTerm")
  searchTermUpdated(): void {
    if (!this.isEmpty(this.searchTerm)) {
      this.filterTableData();
    } else {
      this.initializeFilteredTableData();
    }
  }

  filterTableData(): void {
    this.filteredTableData = this.tableData.filter((category) =>
      this.displayNameIncludesSearchTerm(category.displayName, this.searchTerm),
    );
  }

  displayNameIncludesSearchTerm(displayName: string, search: string): boolean {
    return displayName.toLowerCase().includes(search.trim().toLowerCase());
  }

  @Watch("tableData")
  initializeFilteredTableData(): void {
    this.filteredTableData = this.tableData;
    this.searchTerm = "";
  }

  @Emit()
  editCategory(category: Category): Category {
    return category;
  }

  @Emit()
  deleteCategory(category: Category): Category {
    return category;
  }

  @Emit()
  viewSubCategory(categoryId: string): string {
    return categoryId;
  }

  formatHasChildren(hasChildren: boolean): string {
    return hasChildren ? "Yes" : "No";
  }

  isTableDataNullOrEmpty(data: Category[]): boolean {
    return data.length == 0 || data == null;
  }

  isEmpty(str: string): boolean {
    return str.trim().length == 0;
  }
}
</script>

<style scoped lang="scss">
.bold {
  font-weight: bold;
}

.search {
  height: 2rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  padding: 0.5rem 0.5rem;
}
</style>
