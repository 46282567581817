<template>
  <div>
    <search-filter
      class="margin-bottom-2"
      :taxonomy="taxonomy"
      :isTaxonomyLoading="isTaxonomyLoading"
      :includeCount="false"
      @filtersChanged="filtersChanged"
      @sectionExpanded="nodeExpanded"
      ref="filters"
      :parentSelectedFilters="categoryFilters"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Emit, Mixins } from "vue-property-decorator";
import { FilterType } from "@/constants/FilterType";
import { NonCategoryFilters } from "@/constants/NonCategoryFilters";
import {
  Classification,
  DisseminationControl,
  Sci,
  KnowledgeResourceAudience,
  FilterNodeInformation,
} from "@/dataModel";
import SearchFilter from "@/components/common/SearchFilter.vue";
import CopyrightStatus from "@/constants/CopyrightStatus";
import PublicationStatus from "@/constants/PublicationStatus";
import DocumentType from "@/constants/DocumentTypeWithDisplayName";
import container from "@/dependencyInjection/config";
import IPermissionService from "@/services/interfaces/IPermissionService";
import CategoryHttpHelper from "@/components/resources/categoryHttpHelper";
import serviceTypes from "@/dependencyInjection/types";
import store from "@/store";
import StoreNames from "@/constants/store/StoreNames";
import { AuthStoreGetters } from "@/constants/store/auth/authStoreConstants";
import { SearchSiteDocumentsRequest } from "@/dataModel/requests";
import { DocumentMixin } from "@/mixins";
import Spinner from "@/components/common/Spinner.vue";

@Component({
  components: {
    SearchFilter,
    Spinner,
  },
})
export default class SiteDocumentTableFilters extends Mixins(DocumentMixin) {
  searchFilters: FilterNodeInformation[] = [];
  shownAppliedFilters: FilterNodeInformation[] = [];
  categoryFilters: FilterNodeInformation[] = [];
  classificationFilters: FilterNodeInformation[] = [];
  disseminationControlFilters: FilterNodeInformation[] = [];
  audienceFilters: FilterNodeInformation[] = [];
  documentTypeFilters: FilterNodeInformation[] = [];
  isCopyrightRestrictedFilters: FilterNodeInformation[] = [];
  isPublishedFilters: FilterNodeInformation[] = [];
  taxonomy: FilterNodeInformation[] = [];
  isTaxonomyLoading = true;

  lastExpandedNode?: FilterNodeInformation;
  categoryPaths: string[] = [];
  classifications: string[] = [];
  disseminationControls: string[] = [];
  documentTypeValues: string[] = [];
  audiences: string[] = [];
  scis: string[] = [];
  copyrightStatuses: boolean[] = [];
  publicationStatuses: boolean[] = [];

  userClearanceLevel!: Classification;
  userDisseminationControls: DisseminationControl[] = [];
  userAudiences: KnowledgeResourceAudience[] = [];
  userScis: Sci[] = [];

  $refs!: { filters: SearchFilter };

  private permissionService = container.get<IPermissionService>(serviceTypes.PermissionService);
  private categoryHttpHelper = container.get<CategoryHttpHelper>(serviceTypes.CategoryHttpHelper);

  nodeExpanded(expandedNode: FilterNodeInformation) {
    this.lastExpandedNode = expandedNode;
  }

  getStaticClassMembers(type: any): any[] {
    let memberNames = Object.keys(type);
    return memberNames.map((x) => type[x]);
  }

  private updateFilters(selectedFilters: FilterNodeInformation[]) {
    this.classifications = selectedFilters
      .filter((x) => x.filterType == FilterType.Classification)
      .map((x) => (x.value as Classification).id);
    this.documentTypeValues = selectedFilters
      .filter((x) => x.filterType == FilterType.DocumentType)
      .map((x) => (x.value as DocumentType).value);
    this.copyrightStatuses = selectedFilters
      .filter((x) => x.filterType == FilterType.CopyrightRestriction)
      .map((x) => (x.value as CopyrightStatus).isCopyrightRestricted);
    this.publicationStatuses = selectedFilters
      .filter((x) => x.filterType == FilterType.Publication)
      .map((x) => (x.value as PublicationStatus).isPublished);
    this.disseminationControls = selectedFilters
      .filter((x) => x.filterType == FilterType.DisseminationControl)
      .map((x) => (x.value as DisseminationControl).id);
    this.audiences = selectedFilters
      .filter((x) => x.filterType == FilterType.Audience)
      .map((x) => (x.value as KnowledgeResourceAudience).id);
    this.scis = selectedFilters.filter((x) => x.filterType == FilterType.Sci).map((x) => (x.value as Sci).id);
    this.categoryPaths = selectedFilters.filter((x) => x.filterType == FilterType.Category).map((x) => x.value?.id);
    this.searchFilters = selectedFilters;
    this.shownAppliedFilters = selectedFilters;

    Vue.nextTick(() => {
      this.updateAppliedFilters(this.searchFilters);
    });
  }

  @Emit()
  updateAppliedFilters(appliedSearchFilters: FilterNodeInformation[]): FilterNodeInformation[] {
    return appliedSearchFilters;
  }

  filtersChanged(selectedFilters: FilterNodeInformation[]): void {
    this.updateFilters(selectedFilters);
    this.createSearchFilterRequest(1);
    this.search();
  }

  selectFilters(filters: FilterNodeInformation[]): void {
    (<SearchFilter>this.$refs.filters).selectFilters(filters);
  }

  removeSelection(filter: FilterNodeInformation): void {
    (<SearchFilter>this.$refs.filters).removeSelectedFilter(filter);
  }

  clearFilters(): void {
    (<SearchFilter>this.$refs.filters).clearSelections();
  }

  initializeCategories(categories: FilterNodeInformation[]) {
    categories.sort(function (a, b) {
      return a.stringValue!.toLowerCase().localeCompare(b.stringValue!.toLowerCase());
    });
    for (let category of categories) {
      category.filterType = FilterType.Category;
      category.count = 0;
      if (category.children) {
        this.initializeCategories(category.children);
      }
    }
  }

  getClassificationFilters(): FilterNodeInformation | null {
    let root = new FilterNodeInformation();
    root.stringValue = NonCategoryFilters.Classification;
    root.children = [];
    root.filterType == FilterType.None;
    root.hideSelectAll = true;
    root.path = ["Additional Details", "Classification"];
    let classifications: Classification[] = this.permissionService.getClassifications();
    for (let i = 0; i < classifications.length; ++i) {
      let classification = classifications[i];
      if (classification.value > this.userClearanceLevel.value) {
        continue;
      }
      let childNode = new FilterNodeInformation();
      childNode.stringValue = classification.title;
      childNode.value = classification;
      childNode.filterType = FilterType.Classification;
      childNode.path = ["Additional Details", "Classification", classification.title];
      root.children.push(childNode);
    }
    if (root.children.length <= 1) {
      return null;
    }
    return root;
  }

  getDisseminationControlFilters(): FilterNodeInformation | null {
    let root = new FilterNodeInformation();
    root.stringValue = NonCategoryFilters.DisseminationControls;
    root.children = [];
    root.filterType == FilterType.None;
    root.hideSelectAll = true;
    root.path = ["Additional Details", "DisseminationControl"];
    let disseminationControls: DisseminationControl[] = this.permissionService.getDisseminationControls();
    for (let i = 0; i < disseminationControls.length; ++i) {
      let disseminationControl = disseminationControls[i];
      let childNode = new FilterNodeInformation();
      childNode.stringValue = disseminationControl.title;
      childNode.value = disseminationControl;
      childNode.filterType = FilterType.DisseminationControl;
      childNode.path = ["Additional Details", "DisseminationControl", disseminationControl.title];
      root.children.push(childNode);
    }
    if (root.children.length <= 1) {
      return null;
    }
    return root;
  }

  getAudienceFilters(): FilterNodeInformation | null {
    let root = new FilterNodeInformation();
    root.stringValue = NonCategoryFilters.Audience;
    root.filterType = FilterType.None;
    root.children = [];
    root.hideSelectAll = true;
    root.path = ["Additional Details", "Audience"];
    let audiences: KnowledgeResourceAudience[] = this.permissionService.getAudiences();
    let allowedAudiences = audiences.filter((x) => this.userAudiences.findIndex((a) => a.id == x.id) >= 0);
    for (let audience of allowedAudiences) {
      let childNode = new FilterNodeInformation();
      childNode.stringValue = audience.fullName;
      childNode.value = audience;
      childNode.filterType = FilterType.Audience;
      childNode.path = ["Additional Details", "Audience", audience.fullName];
      root.children.push(childNode);
    }
    if (root.children.length <= 1) {
      return null;
    }
    return root;
  }

  getSciFilters(): FilterNodeInformation | null {
    let root = new FilterNodeInformation();
    root.stringValue = NonCategoryFilters.Sci;
    root.filterType = FilterType.None;
    root.children = [];
    root.hideSelectAll = true;
    root.path = ["Additional Details", "SCI"];
    let scis: Sci[] = this.permissionService.getScis();
    let allowedScis = scis.filter((x) => this.userScis.findIndex((a) => a.id == x.id) >= 0);
    for (let sci of allowedScis) {
      let childNode = new FilterNodeInformation();
      childNode.stringValue = sci.title;
      childNode.value = sci;
      childNode.filterType = FilterType.Sci;
      childNode.path = ["Additional Details", "SCI", sci.title];
      root.children.push(childNode);
    }
    if (root.children.length <= 1) {
      return null;
    }
    return root;
  }

  getFilters(
    filter: NonCategoryFilters,
    filterType: FilterType,
    classMembers: any[],
    userHasAccessMethod,
  ): FilterNodeInformation | null {
    let root = new FilterNodeInformation();
    root.stringValue = filter;
    root.children = [];
    root.filterType == FilterType.None;
    root.hideSelectAll = true;
    root.path = ["Additional Details", filterType.toString()];
    for (let i = 0; i < classMembers.length; ++i) {
      let classMember = classMembers[i];
      if (!userHasAccessMethod(classMember)) {
        continue;
      }
      let childNode = new FilterNodeInformation();
      childNode.stringValue = classMember.displayName;
      childNode.value = classMember;
      childNode.filterType = filterType;
      childNode.path = ["Additional Details", filterType.toString(), classMember.value];
      root.children.push(childNode);
    }
    if (root.children.length <= 1) {
      return null;
    }
    return root;
  }

  async populateTaxonomy(): Promise<void> {
    try {
      this.isTaxonomyLoading = true;
      const categories = await this.categoryHttpHelper.getAllCategoriesTree();
      this.initializeCategories(categories);
      this.taxonomy = categories;
    } finally {
      this.isTaxonomyLoading = false;
    }

    let additionalDetailsSection = new FilterNodeInformation();
    additionalDetailsSection.stringValue = "Additional Details";
    additionalDetailsSection.filterType = FilterType.None;
    additionalDetailsSection.children = [];
    const potentialFilters = [
      this.getClassificationFilters(),
      this.getDisseminationControlFilters(),
      this.getSciFilters(),
      this.getAudienceFilters(),
      this.getFilters(
        NonCategoryFilters.CopyrightStatus,
        FilterType.CopyrightRestriction,
        this.getStaticClassMembers(CopyrightStatus),
        (x: CopyrightStatus) => true,
      ),
      this.getFilters(
        NonCategoryFilters.DocumentType,
        FilterType.DocumentType,
        this.documentTypes,
        (x: DocumentType) => true,
      ),
      this.getFilters(
        NonCategoryFilters.Publication,
        FilterType.Publication,
        this.getStaticClassMembers(PublicationStatus),
        (x: PublicationStatus) => true,
      ),
    ];
    for (let filters of potentialFilters) {
      if (filters != null) {
        additionalDetailsSection.children.push(filters);
      }
    }
    this.taxonomy.push(additionalDetailsSection);
  }

  async getUser(): Promise<void> {
    const user = store.getters[`${StoreNames.Auth}/${AuthStoreGetters.GET_USER}`];
    this.userClearanceLevel = user.actor!.clearanceLevel;
    this.userDisseminationControls = user.actor!.readOnlyDisseminationControls ?? [];
    this.userAudiences = user.actor!.readOnlyAudiences ?? [];
    this.userScis = user.actor!.scis ?? [];
  }

  createSearchFilterRequest(page: number = -1): void {
    const request = new SearchSiteDocumentsRequest(
      page,
      undefined,
      undefined,
      undefined,
      undefined,
      this.classifications,
      this.disseminationControls,
      this.categoryPaths,
      this.documentTypeValues,
      this.copyrightStatuses,
      this.audiences,
      this.scis,
      this.publicationStatuses,
    );

    this.emitSearchFilters(request);
  }

  @Emit()
  emitSearchFilters(request: SearchSiteDocumentsRequest): SearchSiteDocumentsRequest {
    return request;
  }

  @Emit()
  search(): void {}

  async created(): Promise<void> {
    const gettingUser = (async () => {
      await this.getUser();
    })();
    gettingUser.then(
      async () => {
        await this.populateTaxonomy();
        this.createSearchFilterRequest();
      },
      (reason) => {
        console.error(reason);
      },
    );
  }
}
</script>

<style scoped lang="scss">
.applied-filter {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: units(1);
  background-color: color("base-lightest");
  border-radius: 10px;
  padding: 8px;
}

.delete-filter-btn {
  color: color("base-dark");
  margin-top: 5px;
  margin-left: units(1);
}

.delete-filter-btn:hover {
  color: color("base-darkest");
}

#applied-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

#applied-filter-container > * {
  margin-bottom: units(2);
}

:deep(ul li:first-child h1:first-child) {
  margin-top: 0;
}
</style>
