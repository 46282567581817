<template>
  <!-- This router view displays the User Hazard Data child components -->
  <router-view />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class UserHazardDataBase extends Vue {}
</script>
