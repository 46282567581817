/**
 * This map contains keys that are permission category ids and values that map to the corresponding Admin Access permission
 */
export default {
  "457e4d46-fc0b-414a-96c6-b959ba6ac82d": "fccb3807-6583-4e6a-98e1-1efc928169ab", // CategoryManagement
  "6e9362d7-e23e-45b9-a80b-f8d814ed9175": "0a2babfc-b5c1-4515-a511-c8e7a8c1f859", // DocumentManagement
  "953f14e3-e702-43c3-ae0d-6f0299844115": "5f76c1f8-a568-4ce6-ac6b-a5c1c78aaf56", // UserRequestManagement
  "53271ef9-2051-47f4-8f80-e0ff308f93dd": "e6804878-f3a1-47db-9c37-c5d9487a307e", // UserManagement
  "ad2cfbbf-bc4a-466e-b298-ba4f32fd7e42": "a32584d0-3761-4866-acee-b35a099d793b", // FeaturedResourceManagement
  "d10497ae-08a5-4374-bcde-71f78e057d82": "e14a30e7-3da8-492b-b28b-6dac59580654", // HazardDataManagement
};
