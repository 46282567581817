import UsageDateRangeType from "@/constants/UsageDateRangeType";
import UsageIntervalType from "@/constants/UsageIntervalType";

export class GetUsageAnalyticsRequest {
  limit: number;
  interval: UsageIntervalType;
  dateRange: UsageDateRangeType;
  startDate: Date;
  endDate: Date;

  constructor(
    limit?: number,
    interval?: UsageIntervalType,
    dateRange?: UsageDateRangeType,
    startDate?: Date,
    endDate?: Date,
  ) {
    this.limit = limit ?? 5;
    this.interval = interval ?? UsageIntervalType.Day;
    this.dateRange = dateRange ?? UsageDateRangeType.Month;
    this.startDate = startDate ?? new Date();
    this.endDate = endDate ?? new Date();
  }
}
