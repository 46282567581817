import { AuthStoreActions, AuthStoreMutations } from "@/constants/store/auth/authStoreConstants";
import { User } from "@/typings/store/states/AuthStoreState";
import AccessItems from "@/dataModel/constants/accessItems";
import { Commit } from "vuex";

export default {
  [AuthStoreActions.INITIALIZE_PAGE_ACCESS]({ commit }: { commit: Commit }, accessList: string[]): void {
    commit(AuthStoreMutations.SET_PAGE_ACCESS, accessList);

    accessList.forEach((element) => {
      switch (element) {
        case AccessItems.ADMIN_DOCUMENTS:
          commit(AuthStoreMutations.SET_HAS_ADMIN_DOC_ACCESS, true);
          break;
        case AccessItems.ADMIN_ROLES:
          commit(AuthStoreMutations.SET_HAS_ADMIN_ROLE_ACCESS, true);
          break;
        case AccessItems.ADMIN_USER_REQUESTS:
          commit(AuthStoreMutations.SET_HAS_ADMIN_USER_REQUEST_ACCESS, true);
          break;
        case AccessItems.USER_SEARCH:
          commit(AuthStoreMutations.SET_HAS_USER_SEARCH_ACCESS, true);
          break;
        case AccessItems.USER_REQUESTS:
          commit(AuthStoreMutations.SET_HAS_USER_REQUEST_ACCESS, true);
          break;
        case AccessItems.USER_APPLICATIONS:
          commit(AuthStoreMutations.SET_HAS_USER_APP_ACCESS, true);
          break;
        case AccessItems.USER_DOCUMENTS:
          commit(AuthStoreMutations.SET_HAS_USER_DOC_ACCESS, true);
          break;
        case AccessItems.ADMIN_USER_MANAGEMENT:
          commit(AuthStoreMutations.SET_HAS_ADMIN_USER_MANAGEMENT_ACCESS, true);
          break;
        case AccessItems.ADMIN_REPORT:
          commit(AuthStoreMutations.SET_HAS_ADMIN_REPORT_ACCESS, true);
          break;
        case AccessItems.DOCUMENT_APPROVE:
          commit(AuthStoreMutations.SET_DOCUMENT_APPROVE_ACCESS, true);
          break;
        case AccessItems.DOCUMENT_CREATE_UPLOAD:
          commit(AuthStoreMutations.SET_DOCUMENT_CREATE_UPLOAD_ACCESS, true);
          break;
        case AccessItems.DOCUMENT_EDIT:
          commit(AuthStoreMutations.SET_DOCUMENT_EDIT_ACCESS, true);
          break;
        case AccessItems.CATEGORY_MANAGEMENT:
          commit(AuthStoreMutations.SET_CATEGORY_MANAGEMENT_ACCESS, true);
          break;
        case AccessItems.FEATURED_RESOURCE_MANAGEMENT:
          commit(AuthStoreMutations.SET_FEATURED_RESOURCE_MANAGEMENT_ACCESS, true);
          break;
        case AccessItems.ADMIN_HAZARD_DATA:
          commit(AuthStoreMutations.SET_DATA_COLLECTION_MANAGEMENT_ACCESS, true);
          break;
        case AccessItems.USER_HAZARD_DATA:
          commit(AuthStoreMutations.SET_HAS_USER_HAZARD_DATA_ACCESS, true);
          break;
        case AccessItems.USAGE_ANALYTICS:
          commit(AuthStoreMutations.SET_USAGE_ANALYTICS_ACCESS, true);
          break;
      }
    });
  },
  [AuthStoreActions.SET_USER]({ commit }: { commit: Commit }, user: User): void {
    commit(AuthStoreMutations.SET_USER, user);
  },
  [AuthStoreActions.LOGOUT]({ commit }: { commit: Commit }): void {
    commit(AuthStoreMutations.RESET_AUTHENTICATION);
  },
  [AuthStoreActions.SET_SECONDS_IDLE]({ commit }: { commit: Commit }, newValue: number): void {
    commit(AuthStoreMutations.SET_SECONDS_IDLE, newValue);
  },
  [AuthStoreActions.SET_SHOWING_WARNING_DIALOG]({ commit }: { commit: Commit }, newValue: boolean): void {
    commit(AuthStoreMutations.SET_SHOWING_WARNING_DIALOG, newValue);
  }
};
