import HttpHelper from "@/components/resources/httpHelper";
import { inject, injectable } from "inversify";
import serviceTypes from "@/dependencyInjection/types";
import { DocumentSearchResult, SiteDocument } from "@/dataModel";
import { SiteDocumentResponse, FilterCountResponse, SearchResponse } from "@/dataModel/responses";
import { FilterCountFilterType } from "@/constants/FilterCountFilterType";

const siteDocumentUrl = "documents/";

@injectable()
export default class SiteDocumentHttpHelper {
  httpHelper: HttpHelper;

  constructor(@inject(serviceTypes.HttpHelper) httpHelper: HttpHelper) {
    this.httpHelper = httpHelper;
  }

  async getFeaturedDocuments(limit: number): Promise<SiteDocument[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(`${siteDocumentUrl}/featured-documents?limit=${limit}`)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(`Response returned ${response.status}: ${response.statusText} getting featured documents`),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error(`An error occured while getting featured documents.`));
        });
    });
  }

  async getFeaturedHazardData(limit: number): Promise<SiteDocument[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(`${siteDocumentUrl}/featured-hazard-data?limit=${limit}`)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(`Response returned ${response.status}: ${response.statusText} getting featured hazard data`),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error(`An error occured while getting featured hazard data.`));
        });
    });
  }

  async getSiteDocument(id: string, includeFile = false): Promise<SiteDocumentResponse> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(`${siteDocumentUrl}${id}?includeFile=${includeFile}`)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error(`Response returned ${response.status}: ${response.statusText} getting site document`));
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error(`An error occured while getting site document '${id}'`));
        });
    });
  }

  async getDocumentTypes(): Promise<Record<string, string>> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(`${siteDocumentUrl}document-types`)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error(`Response returned ${response.status}: ${response.statusText} getting document types.`));
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error(`An error occured while getting document types`));
        });
    });
  }

  async downloadDocument(id: string, convertToPdf: boolean): Promise<string> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(`${siteDocumentUrl}file/${id}/${convertToPdf}`, "arraybuffer")
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error(`Response returned ${response.status}: ${response.statusText} getting site document`));
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error(`An error occured while getting site document '${id}'`));
        });
    });
  }

  async searchDocuments(request: DocumentSearchRequest): Promise<SearchResponse<DocumentSearchResult>> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(siteDocumentUrl, request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " searching site documents.",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while searching site documents."));
        });
    });
  }

  async getFilterCounts(request: FilterCountRequest): Promise<FilterCountResponse> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(siteDocumentUrl + "counts", request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " searching site documents.",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while searching site documents."));
        });
    });
  }

  async getDocumentsByKnowledgeResourceIds(
    knowledgeResourceIds: string[],
    includeFile = false,
  ): Promise<SiteDocumentResponse[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(siteDocumentUrl + `knowledge-resource-ids?includeFile=${includeFile}`, knowledgeResourceIds)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error(`Response returned ${response.status}: ${response.statusText} getting site documents`));
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error(`An error occured while getting site documents '${knowledgeResourceIds}'`));
        });
    });
  }
}

export class DocumentSearchRequest {
  page!: number;
  resultsPerPage!: number;
  searchTerm!: string;
  copyrightStatuses?: string[];
  sortType?: string;
  sortOrder?: string;
  categoryPaths?: string[][];
  classifications?: string[];
  audiences?: ListFieldFilterWrapper<string>;
  disseminationControls?: ListFieldFilterWrapper<string>;
  scis?: ListFieldFilterWrapper<string>;
  documentTypes?: string[];
  startYear?: Date;
  endYear?: Date;
}

export class FilterCountRequest {
  searchTerm!: string;
  categoryPaths?: string[][];
  classifications?: string[];
  copyrightStatuses?: string[];
  documentTypes?: string[];
  audiences?: ListFieldFilterWrapper<string>;
  disseminationControls?: ListFieldFilterWrapper<string>;
  scis?: ListFieldFilterWrapper<string>;
  filterType?: FilterCountFilterType;
  categoryRoot?: string;
  startYear?: Date;
  endYear?: Date;
}

export class ListFieldFilterWrapper<T> {
  matchingValues: T[];
  includeEmptyFields: boolean;

  constructor(matchingValues: T[], includeEmptyFields: boolean) {
    this.matchingValues = matchingValues;
    this.includeEmptyFields = includeEmptyFields;
  }
}
