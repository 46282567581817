import DataValueOptionResult from "./dataValueOptionResult";

export default class DataValueResult {
  [key: string]: any;
  dataValueId: string;
  optionResults: DataValueOptionResult[];

  constructor(dataValueId?: string, optionResults?: DataValueOptionResult[]) {
    this.dataValueId = dataValueId ?? "";
    this.optionResults = optionResults ?? [];
  }

  public getEvaluationRatingIconColor(evalRating: string): string {
    switch (evalRating) {
      case "Good":
        return "text-green";
      case "Fair":
        return "text-yellow";
      case "Poor":
        return "text-red";
      default:
        return "text-gray-10";
    }
  }
}
