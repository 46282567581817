import HazardDetail from "./details/hazardDetail";
import HazardCategoryDetails from "./hazardCategoryDetails";
import HazardPropertyDetails from "./hazardPropertyDetails";

export default class HazardPropertySpecifications extends HazardPropertyDetails {
    details: HazardDetail[];
    categories: HazardCategoryDetails[];

  constructor( 
    details?: HazardDetail[],
    categories?: HazardCategoryDetails[],
  ) {
    super();
    this.details = details ?? [];
    this.categories = categories ?? [];
  }
}