import { HazardType } from "@/constants/HazardType";
import { DataCollectionStoreGetters } from "@/constants/store/dataCollection/dataCollectionStoreConstants";
import { Category } from "@/dataModel";
import DataSource from "@/dataModel/hazardData/dataSource";
import HazardDataValue from "@/dataModel/hazardData/hazardDataValue";
import HazardPropertyDetails from "@/dataModel/hazardData/hazardPropertyDetails";
import { DataCollectionState } from "@/typings/store/states/DataCollectionStoreState";

export default {
  [DataCollectionStoreGetters.GET_SELECTED_DATA_COLLECTION]: (state: DataCollectionState): DataSource | null =>
    state.dataCollection,
  [DataCollectionStoreGetters.GET_SELECTED_DATA_VALUE]: (state: DataCollectionState): HazardDataValue | null =>
    state.dataValue,
  [DataCollectionStoreGetters.GET_SELECTED_HAZARDS]: (state: DataCollectionState): Category[] | null => state.hazards,
  [DataCollectionStoreGetters.GET_SELECTED_HAZARD_PROPERTY]: (
    state: DataCollectionState,
  ): HazardPropertyDetails | null => state.hazardProperty,
  [DataCollectionStoreGetters.GET_SELECTED_HAZARD_TYPE]: (state: DataCollectionState): HazardType | null =>
    state.hazardType,
};
