<template>
  <div>
    <basic-dialog
      :id="dialogId"
      title="Inactive Session"
      primaryButton="Stay Signed In"
      :primaryClick="continueSession"
      secondaryButton="Sign Out"
      :secondaryClick="signOut"
      :hideHeaderClose="true"
      :noCloseOnBackdrop="true"
      :noCloseOnEsc="true"
    >
      <p class="usa-prose mb-0">
        Due to inactivity, your session will expire in <span class="text-bold">{{ secondsUntilLogout }}</span> seconds.
      </p>
    </basic-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import store from "@/store";
import IAuthService from "@/services/interfaces/IAuthService";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import StoreNames from "@/constants/store/StoreNames";
import { AuthStoreActions } from "@/constants/store/auth/authStoreConstants";
import { AppConfig } from "@/dataModel";

@Component({
  components: {
    BasicDialog,
  },
})
export default class InactiveWarningDialog extends Vue {
  private auth = container.get<IAuthService>(serviceTypes.AuthService);
  private appConfig = container.get<AppConfig>(serviceTypes.AppConfig);
  private dialogId = "inactive-warning-dialog";

  get secondsUntilLogout(): number {
    return this.appConfig.sessionTimeoutInSeconds - store.state[`${StoreNames.Auth}`].secondsIdle;
  }

  continueSession(): void {
    store.dispatch(`${StoreNames.Auth}/${AuthStoreActions.SET_SHOWING_WARNING_DIALOG}`, false);
    // reset idle countdown
    window.dispatchEvent(new KeyboardEvent("keydown"));
    this.$bvModal.hide(this.dialogId);
  }

  signOut(): void {
    this.auth.logout();
  }
}
</script>
