import { DataValueIdentifierType } from "@/constants/DataValueIdentifierType";

export default class DataValueIdentifierInformation {
  identifierType: DataValueIdentifierType;
  identifierKey: string;

  constructor(
    identifierType: DataValueIdentifierType,
    identifierKey?: string,
  ) {
    this.identifierType = identifierType;
    this.identifierKey = identifierKey ?? "";
  }
}