<template>
  <div>
    <span v-if="searchFilters.length > 0" ref="appliedFilters" id="applied-filter-container">
      <button class="usa-button usa-button--outline" @click="clearFilters">Clear all filters</button>
      <span v-for="(filter, index) in shownAppliedFilters" :key="index">
        <p class="applied-filter">
          {{ filter.stringValue
          }}<i
            class="fa fa-times delete-filter-btn float-right"
            @click="removeSelection(filter)"
            :aria-label="'Remove ' + filter.stringValue + ' filter'"
          />
        </p>
      </span>
      <a
        v-if="!showAllAppliedFilters"
        href="#"
        class="usa-link"
        @click.prevent="toggleShowAllAppliedFilters"
        ref="showAllAppliedFiltersLink"
        :style="{ display: showShowAllAppliedFiltersLink ? 'inline-block' : 'none' }"
      >
        Show all<i class="fa fa-chevron-down fa-fw" />
      </a>
      <a
        v-else
        href="#"
        class="usa-link"
        @click.prevent="toggleShowAllAppliedFilters"
        ref="showAllAppliedFiltersLink"
        :style="{ display: showShowAllAppliedFiltersLink ? 'inline-block' : 'none' }"
      >
        Show less<i class="fa fa-chevron-up fa-fw" />
      </a>
    </span>
  </div>
</template>

<script lang="ts">
import { FilterNodeInformation } from "@/dataModel";
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";

@Component
export default class AppliedSiteDocumentFilters extends Vue {
  showAllAppliedFilters: boolean = false;
  currentNumberOfRows: number = 0;
  readonly maxFilterRows: number = 2;
  $refs!: {
    appliedFilters: HTMLElement;
    showAllAppliedFiltersLink: HTMLElement;
  };

  @Prop({ default: [] }) searchFilters!: FilterNodeInformation[];
  @Prop({ default: [] }) shownAppliedFilters!: FilterNodeInformation[];

  @Watch("searchFilters")
  searchFiltersUpdated(): void {
    Vue.nextTick(() => {
      this.updateAppliedFilters();
    });
  }

  toggleShowAllAppliedFilters() {
    this.showAllAppliedFilters = !this.showAllAppliedFilters;
    Vue.nextTick(() => {
      this.updateAppliedFilters();
    });
  }

  updateAppliedFilters() {
    if (this.searchFilters.length > 0) {
      let filterContainer: HTMLElement = this.$refs.appliedFilters;
      let rowWidth = filterContainer.offsetWidth;
      let children: HTMLCollection = filterContainer.children;
      let total = 0;
      let numRows = 1;
      let showAllAppliedFiltersLink: HTMLElement = this.$refs.showAllAppliedFiltersLink;
      for (let i = 0; i < children.length; ++i) {
        let item: HTMLElement = children[i] as HTMLElement;
        if (item == showAllAppliedFiltersLink) {
          continue;
        }
        item.style.display = "inline-block";
        let style = window.getComputedStyle(item);
        let itemWidth = item.offsetWidth + parseFloat(style.marginRight);
        if (
          (numRows == this.maxFilterRows &&
            total + itemWidth + showAllAppliedFiltersLink.offsetWidth > rowWidth &&
            !this.showAllAppliedFilters) ||
          total + itemWidth > rowWidth
        ) {
          total = 0;
          ++numRows;
        }
        if (numRows > this.maxFilterRows && !this.showAllAppliedFilters) {
          item.style.display = "none";
        }
        total += itemWidth;
      }
      this.currentNumberOfRows = numRows;
      return;
    }
  }

  get showShowAllAppliedFiltersLink(): boolean {
    return this.currentNumberOfRows > this.maxFilterRows;
  }

  @Emit()
  emitRemoveSelection(filter: FilterNodeInformation): FilterNodeInformation {
    return filter;
  }

  removeSelection(filter: FilterNodeInformation): void {
    this.emitRemoveSelection(filter);
    this.updateAppliedFilters();
  }

  @Emit()
  clearFilters(): void {}
}
</script>

<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

.applied-filter {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: units(1);
  background-color: color("base-lightest");
  border-radius: 10px;
  padding: 8px;
}

.delete-filter-btn {
  color: color("base-dark");
  margin-top: 5px;
  margin-left: units(1);
}

.delete-filter-btn:hover {
  color: color("base-darkest");
}

#applied-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

#applied-filter-container > * {
  margin-bottom: units(2);
}
</style>
