import { render, staticRenderFns } from "./DataCollectionManagementPage.vue?vue&type=template&id=03f4d754&scoped=true"
import script from "./DataCollectionManagementPage.vue?vue&type=script&lang=ts"
export * from "./DataCollectionManagementPage.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03f4d754",
  null
  
)

export default component.exports