export enum DocumentStoreActions {
    SET_SELECTED_DOCUMENT = 'SET_SELECTED_DOCUMENT',
}

export enum DocumentStoreGetters {
    GET_SELECTED_DOCUMENT = 'GET_SELECTED_DOCUMENT',
}

export enum DocumentStoreMutations {
    SET_SELECTED_DOCUMENT = 'SET_SELECTED_DOCUMENT',
}