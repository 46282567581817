<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <h1 class="font-heading-xl text-primary-dark margin-top-0 margin-bottom-5">Request Management</h1>
      </div>

      <div class="grid-row margin-bottom-2">
        <div class="desktop:grid-col-2 margin-bottom-2 filters">
          <h3 class="font-heading-lg text-bold text-primary-dark">Filters</h3>
          <data-table-filter
            :filterBy="'status'"
            :filters="filters"
            @filtersChanged="statusFiltersChanged"
            :preSelectedFilters="['Open']"
          />
        </div>

        <div class="desktop:grid-col padding-left-2">
          <div class="grid-row grid-gap margin-bottom-0">
            <form
              class="usa-search usa-search--small search-style"
              role="search"
              @submit.prevent="throttleSearchMethod"
            >
              <label class="usa-sr-only" for="search-field-en-small">Search</label>
              <input
                class="usa-input"
                id="search-field-en-small"
                type="search"
                name="search"
                v-model="searchTerm"
                placeholder="Search for a request or email..."
              />
              <button class="usa-button" type="submit">
                <span class="usa-sr-only">Search</span>
              </button>
            </form>

            <results-per-page-button
              class="margin-left-2 align-right"
              @resultsPerPageChanged="perPageAmountChanged"
            ></results-per-page-button>

            <div class="grid-col-auto pagination-container">
              <table-pagination
                class="pagination pagination-top"
                @prev="prevPage"
                @next="nextPage"
                :page="page"
                :hasNextPage="hasNextPage"
              />
            </div>
          </div>

          <div class="grid-row grid-gap">
            <div class="desktop:grid-col">
              <data-table
                @sortingChanged="sortingChanged"
                @itemSelected="viewRequest"
                :isLoadingData="isSearchLoading"
                :items="requests"
                :fields="mockHeaders"
                :perPage="perPage"
                :page="page"
                ref="userTable"
              >
                <template #cell(title)="data">
                  <div class="margin-y-05 table-col-md">
                    <a
                      href="#/"
                      @click="viewRequest(data.item)"
                      class="data-table-item-action usa-link overflow-ellipsis title-link"
                    >
                      <span>{{ data.value }}</span>
                    </a>
                  </div>
                </template>
                <template #cell(email)="data">
                  <div class="margin-y-05 table-col-sm overflow-ellipsis">
                    {{ data.value }}
                  </div>
                </template>
              </data-table>
            </div>
          </div>
        </div>
      </div>
      <table-pagination class="pagination" @prev="prevPage" @next="nextPage" :page="page" :hasNextPage="hasNextPage" />
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import { RequestStoreActions } from "@/constants/store/request/requestStoreConstants";
import StoreNames from "@/constants/store/StoreNames";
import { Request, AppConfig } from "@/dataModel";
import DataTable from "@/components/common/DataTable.vue";
import DataTableFilter from "@/components/common/DataTableFilter.vue";
import TablePagination from "@/components/common/TablePagination.vue";
import ResultsPerPageButton from "@/components/common/ResultsPerPageButton.vue";
import RequestHttpHelper from "@/components/resources/requestHttpHelper";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import { DataTableFilterData, DataTableHeader, BreadcrumbPathItem } from "@/dataModel/interfaces";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import { throttle } from "lodash";

@Component({
  components: {
    Breadcrumb,
    DataTable,
    DataTableFilter,
    TablePagination,
    ResultsPerPageButton,
  },
})
export default class RequestManagementPage extends Vue {
  perPage = 20;
  page: number = 1;
  searchTerm: string = "";
  statusFilters: string[] = [];
  sortOrder: string = "ascending";
  sortColumn: string = "title";
  selectedRequestStatuses: string[] = [];
  statusAction: number = 0;
  selectedRequest: Request | undefined;
  requests: Request[] = [];
  isSearchLoading: boolean = true;
  totalRequestCount: number = 0;
  throttleSearchMethod: any;

  private requestHttpHelper = container.get<RequestHttpHelper>(serviceTypes.RequestHttpHelper);
  private appConfig = container.get<AppConfig>(serviceTypes.AppConfig);

  path: BreadcrumbPathItem[] = [
    {
      text: "Administration",
    },
    {
      text: "Request Management",
    },
  ];

  mockHeaders: DataTableHeader[] = [
    {
      label: "Title",
      key: "title",
      sortable: true,
    },
    {
      label: "Email",
      key: "email",
      sortable: true,
    },
    {
      label: "Created",
      key: "createdDateTime",
      sortable: true,
      formatter: (val) => this.$options.filters?.formatDate(val),
    },
    {
      label: "Updated",
      key: "lastUpdated",
      sortable: true,
      formatter: (val) => this.$options.filters?.formatDate(val),
    },
    {
      label: "Status",
      key: "requestStatus",
      sortable: true,
    },
  ];

  filters: DataTableFilterData[] = [
    {
      name: "status",
      filters: ["Open", "Closed"],
    },
  ];

  async searchRequests(): Promise<void> {
    try {
      this.isSearchLoading = true;
      const currentPage = this.page - 1;
      const response = await this.requestHttpHelper.searchRequests(
        currentPage,
        this.perPage,
        this.searchTerm,
        this.sortOrder,
        this.sortColumn,
        this.statusFilters,
      );
      this.requests = response.result;
      this.totalRequestCount = response.count;
    } finally {
      this.isSearchLoading = false;
    }
  }

  search(): void {
    if (this.searchTerm.trim() !== "") {
      this.sortColumn = "relevance";
      this.sortOrder = "ascending";
    }
    this.searchRequests();
    this.sortColumn = "title";
  }

  prevPage(): void {
    if (this.page > 1) {
      this.page--;
      this.searchRequests();
    }
  }

  nextPage(): void {
    if (this.hasNextPage) {
      this.page++;
      this.searchRequests();
    }
  }

  get hasNextPage(): boolean {
    return this.page * this.perPage < this.totalRequestCount;
  }

  perPageAmountChanged(val: number): void {
    this.perPage = val;
    this.page = 1;
    this.searchRequests();
  }

  sortingChanged(ctx: any): void {
    this.sortColumn = ctx.sortBy;
    this.sortOrder = ctx.sortDesc ? "descending" : "ascending";
    this.searchRequests();
  }

  statusFiltersChanged(selectedFilters: string[]): void {
    this.statusFilters = selectedFilters;
    this.page = 1;
    this.searchRequests();
  }

  viewRequest(request: Request): void {
    store.dispatch(`${StoreNames.Request}/${RequestStoreActions.SET_SELECTED_REQUEST}`, request);
    this.$router.push({ name: "IndividualRequest" });
  }

  created() {
    // reset state
    store.dispatch(`${StoreNames.Request}/${RequestStoreActions.SET_SELECTED_REQUEST}`, null);
    this.throttleSearchMethod = throttle(async () => {
      this.search();
    }, this.appConfig.searchRequestMinimumIntervalInMilliseconds);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.pagination {
  float: right;
}

.pagination-container {
  margin-top: 0.5rem;
}

.search-style {
  width: 50%;
  margin-top: 4px;
}

.hide {
  display: none;
}

.align-right {
  margin-left: auto;
}

.filters {
  margin-top: 4px;
}

.title-link {
  display: inline-block;
  max-width: 100%;
  vertical-align: bottom;
}
</style>
