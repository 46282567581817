<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <div class="desktop:grid-col-9">
          <h1 class="font-heading-xl text-primary-dark margin-top-0 margin-bottom-4">
            Create Copyright Restricted Document
          </h1>
        </div>
      </div>

      <document-input-fields
        ref="createDocument"
        @fields="createDocument"
        @invalidForm="doneLoading()"
        :isNewCopyrightRestricted="true"
      />

      <loading-buttons
        class="padding-top-3 width-tablet"
        @primaryButtonClick="createDocumentHandler"
        @secondaryButtonClick="returnToDocumentManagement"
        :isLoading="isLoading"
        :secondaryButtonDisabled="isLoading"
        primaryButtonText="Create"
        secondaryButtonText="Back"
        loadingText="Creating document..."
      />
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import { BreadcrumbPathItem } from "@/dataModel/interfaces";
import { SiteDocument, TitlePermissionIds } from "@/dataModel";
import { CreateSiteDocumentRequest } from "@/dataModel/requests";
import DocumentInputFields from "@/components/navigation/DocumentManagement/DocumentInputFields.vue";
import SiteDocumentAdminHttpHelper from "@/components/resources/siteDocumentAdminHttpHelper";
import serviceTypes from "@/dependencyInjection/types";
import container from "@/dependencyInjection/config";
import LoadingButtons from "@/components/common/LoadingButtons.vue";

@Component({
  components: {
    Breadcrumb,
    DocumentInputFields,
    LoadingButtons,
  },
})
export default class CreateCopyrightDocument extends Vue {
  newDocument!: SiteDocument;
  isLoading: boolean = false;

  private siteDocumentAdminHttpHelper = container.get<SiteDocumentAdminHttpHelper>(
    serviceTypes.SiteDocumentAdminHttpHelper,
  );

  path: BreadcrumbPathItem[] = [
    {
      text: "Administration",
    },
    {
      text: "Document Management",
      url: "/document-management",
    },
    {
      text: "Create Copyright Restricted Document",
    },
  ];

  async createDocument(document: SiteDocument): Promise<void> {
    const titlePermissionIds = new TitlePermissionIds(
      document.knowledgeResource.titleClassifications.classification!.id,
      document.knowledgeResource.titleClassifications.disseminationControls!.map((x) => x.id),
      document.knowledgeResource.titleClassifications.scis!.map((x) => x.id),
    );
    const request = new CreateSiteDocumentRequest(
      titlePermissionIds,
      document.knowledgeResource.classification.id,
      document.knowledgeResource.disseminationControls.map((x) => x.id),
      document.knowledgeResource.scis.map((x) => x.id),
      document.knowledgeResource.audiences.map((x) => x.id),
      document.title,
      document.documentType,
      document.contributors,
      document.pages,
      document.journalName,
      document.isPublished,
      document.doi,
      document.volume,
      document.abstract,
      document.documentDateTime,
      true, // is copyright restricted
      document.categoryIds,
      document.documentDatePrecision,
      "", // stagedfileid
      "", // file name
      document.location,
    );
    await this.siteDocumentAdminHttpHelper.createCopyrightRestrictedSiteDocument(request);
    this.isLoading = false;
    this.$bvToast.show("document-created-toast");
    this.$router.push({ name: "DocumentManagement", query: { createSuccess: "true" } });
  }

  createDocumentHandler(): void {
    this.isLoading = true;
    (<DocumentInputFields>this.$refs.createDocument).emitFields();
  }

  doneLoading(): void {
    this.isLoading = false;
  }

  returnToDocumentManagement(): void {
    this.$router.push({ name: "DocumentManagement", query: { usePreviousSetup: "true" } });
  }
}
</script>

<style scoped lang="scss">
.line-break {
  margin: 0;
  background: #c9c9c9;
}

.distribution-container {
  height: 150px;
  border: 1px solid #565c65;
  overflow-y: scroll;
}

.distribution-checkbox {
  margin-left: 1rem;
}
</style>
