export class TitlePermissionIds {
  classification?: string | null;
  disseminationControls?: string[] | null;
  scis?: string[] | null;

  constructor(classification?: string | null, disseminationControls?: string[] | null, scis?: string[] | null) {
    this.classification = classification ?? null;
    this.disseminationControls = disseminationControls ?? [];
    this.scis = scis ?? [];
  }
}
