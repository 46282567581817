<template>
  <basic-dialog
    :id="dialogId"
    title="Select Metadata File"
    primaryButton="Continue"
    secondaryButton="Cancel"
    :primaryClick="continueToSummary"
    :secondaryClick="cancel"
    :isDisabled="!canContinue"
    :isLoading="isLoading"
  >
    <template v-if="batchHasUnapprovedFiles">
      <div class="usa-alert usa-alert--info usa-alert--slim margin-bottom-2">
        <div class="usa-alert__body">
          <p class="usa-alert__text">All files must be approved</p>
        </div>
      </div>
    </template>
    <p>
      Selected Batch: <strong>{{ batch.name }}</strong>
    </p>
    <hr />
    <div class="usa-form-group margin-bottom-2">
      <label class="usa-label" for="file-input-single">Upload Metadata File</label>
      <span class="usa-hint" id="file-input-specific-hint">Excel file or CSV</span>
      <input
        ref="filePicker"
        id="file-input-single"
        class="usa-file-input"
        type="file"
        name="file-input-single"
        aria-describedby="file-input-specific-hint"
        :accept="allowedFileTypeString"
        @change="onFileChanged"
      />
    </div>

    <template v-if="errors.length > 0">
      <div class="usa-alert usa-alert--error usa-alert--slim" v-for="(err, i) in errors" :key="`error-${i}`">
        <div class="usa-alert__body">
          <p class="usa-alert__text">{{ err }}</p>
        </div>
      </div>
    </template>
    <template v-else-if="canContinue">
      <div class="usa-alert usa-alert--success usa-alert--slim">
        <div class="usa-alert__body">
          <p class="usa-alert__text">Metadata parsed successfully.</p>
        </div>
      </div>
    </template>
  </basic-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import { StagedFileBatch } from "@/dataModel";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import DocumentStagingBatchHttpHelper from "@/components/resources/DocumentStagingBatchHttpHelper";
import { DocumentStagingActions } from "@/store/modules/documentStaging/actions";
import { isNullOrEmpty } from "@/services/utils";
import FileStatus from "@/constants/FileStatus";

@Component({
  components: { BasicDialog },
})
export default class MetadataFileDialog extends Vue {
  @Prop({ required: true }) dialogId!: string;
  @Prop({ required: true }) batch!: StagedFileBatch;

  errors: string[] = [];
  isLoading = false;
  allowedFileTypeString = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv";
  selectedFile: File | null = null;

  stagingBatchHttpHelper = container.get<DocumentStagingBatchHttpHelper>(serviceTypes.DocumentStagingBatchHttpHelper);

  //#region Computed properties

  get canContinue(): boolean {
    return !!this.selectedFile && !this.isLoading && isNullOrEmpty(this.errors) && !this.batchHasUnapprovedFiles;
  }

  get batchHasUnapprovedFiles(): boolean {
    return this.batch.stagedFiles?.some(
      (f) => f.fileStatus !== FileStatus.Approved && f.fileStatus !== FileStatus.Converted,
    );
  }

  //#endregion

  //#region Methods

  async onFileChanged(event: Event): Promise<void> {
    // set selected files
    const input = event.target as HTMLInputElement;
    if (!input.files) {
      return;
    }

    this.isLoading = true;
    this.selectedFile = input.files[0];

    const apiResponse = await this.stagingBatchHttpHelper.parseBatchMetadataAsync(
      this.batch.batchId,
      this.selectedFile,
    );

    this.errors = apiResponse.errorMessages;
    if (apiResponse.hasError) {
      this.isLoading = false;
      this.selectedFile = null;
      input.value = "";
      this.$store.dispatch(DocumentStagingActions.UPDATE_SUMMARIZED_BATCH_DOCUMENTS, []);
      return;
    }

    const tempDocumentObjects = apiResponse.data.map((parseResult) => parseResult.siteDocument);
    this.$store.dispatch(DocumentStagingActions.UPDATE_SUMMARIZED_BATCH_DOCUMENTS, tempDocumentObjects);

    this.isLoading = false;
  }

  continueToSummary(): void {
    this.close();

    const id = this.batch.batchId;
    this.$router.push({
      name: "BatchSummaryPage",
      params: { id },
    });
  }

  cancel(): void {
    this.close();
  }

  close(): void {
    this.errors = [];
    this.isLoading = false;
    this.selectedFile = null;
    this.$bvModal.hide(this.dialogId);
  }

  //#endregion
}
</script>

<style lang="scss" scoped></style>
