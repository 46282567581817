import { ActionTree } from "vuex";
import { DocumentStagingState } from "@/typings/store/states/DocumentStagingState";
import { DocumentStagingMutations } from "./mutations";
import { SiteDocument } from "@/dataModel";

export enum DocumentStagingActions {
  UPDATE_SUMMARIZED_BATCH_ID = "UPDATE_SUMMARIZED_BATCH_ID",
  UPDATE_SUMMARIZED_BATCH_DOCUMENTS = "UPDATE_SUMMARIZED_BATCH_DOCUMENTS",
  UPDATE_BATCH_DOCUMENT_METADATA = "UPDATE_BATCH_DOCUMENT_METADATA",
}

export const actions: ActionTree<DocumentStagingState, any> = {
  [DocumentStagingActions.UPDATE_SUMMARIZED_BATCH_ID]({ commit }, id: string): void {
    commit(DocumentStagingMutations.SET_SUMMARIZED_BATCH_ID, id);
  },

  [DocumentStagingActions.UPDATE_SUMMARIZED_BATCH_DOCUMENTS]({ commit }, documents: SiteDocument[]): void {
    commit(DocumentStagingMutations.SET_SUMMARIZED_BATCH_DOCUMENTS, documents);
  },

  [DocumentStagingActions.UPDATE_BATCH_DOCUMENT_METADATA]({ commit }, document: SiteDocument): void {
    commit(DocumentStagingMutations.UPDATE_BATCH_DOCUMENT_METADATA, document);
  },
};
