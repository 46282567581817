import { RequestStoreMutations } from "@/constants/store/request/requestStoreConstants";
import { Request, RequestFileMetadata } from "@/dataModel";
import { RequestState } from "@/typings/store/states/RequestStoreState";

export default {
  [RequestStoreMutations.SET_SELECTED_REQUEST](state: RequestState, request: Request) {
    state.request = request;
  },
  [RequestStoreMutations.SET_REQUEST_FILE_METADATA](state: RequestState, fileMetadata: RequestFileMetadata) {
    state.requestFileMetadata = fileMetadata;
  },
};
