import { StagedFileStoreGetters } from "@/constants/store/stagedFile/stagedFileStoreConstants";
import { StagedFile, StagedFileWithDocumentMatch } from "@/dataModel";
import { StagedFileState } from "@/typings/store/states/StagedFileStoreState";

export default {
  [StagedFileStoreGetters.GET_SELECTED_FILES]: (state: StagedFileState): StagedFile[] => state.selectedFiles,
  [StagedFileStoreGetters.GET_STAGED_FILES_TO_UPDATE]: (state: StagedFileState): StagedFileWithDocumentMatch[] =>
    state.stagedFilesToUpdate,
  [StagedFileStoreGetters.GET_NUMBER_OF_FILES_UPDATED]: (state: StagedFileState): number => state.numberFilesUpdated,
  [StagedFileStoreGetters.GET_FILTERS]: (state: StagedFileState): string[] => state.filters,
  [StagedFileStoreGetters.GET_PAGE]: (state: StagedFileState): number => state.page,
  [StagedFileStoreGetters.GET_RESULTS_PER_PAGE]: (state: StagedFileState): number => state.resultsPerPage,
  [StagedFileStoreGetters.GET_SEARCH_TERM]: (state: StagedFileState): string => state.searchTerm,
};
