<template>
  <div>
    <div class="grid-row">
      <h1 class="font-heading-lg text-bold text-primary-dark margin-top-0">
        {{ isSingleSelection ? `Select a Hazard` : `Select Hazards` }}
      </h1>
    </div>

    <div class="grid-row">
      <div class="grid-col-6">
        <!-- Hazard search -->
        <form class="usa-search usa-search--small" role="search" @submit.prevent="onSearch">
          <label class="usa-sr-only" for="search-field-en-small">Search</label>
          <input
            class="usa-input"
            id="search-field-en-small"
            type="search"
            name="search"
            v-model="searchTerm"
            placeholder="Search for a specific hazard..."
          />
          <button class="usa-button" type="submit">
            <span class="usa-sr-only">Search</span>
          </button>
        </form>
      </div>

      <div v-if="!isSingleSelection && showViewButton" class="grid-col-6">
        <button class="usa-button margin-0 float-right" :disabled="!canViewData" @click="onViewData(selectedHazards)">
          View Property Data
        </button>
      </div>
    </div>

    <p v-if="showNoResultsMsg" class="margin-y-1">No results found.</p>

    <!-- Selected hazards -->
    <div v-if="!isSingleSelection && selectedHazards.length > 0" class="grid-row margin-y-2">
      <applied-selections-panel
        :panelButtonText="'Clear All'"
        :selections="selectionPanelItems"
        @onPanelButtonClicked="removeAllSelections"
        @onRemoveSelection="removeSelection($event)"
      />
    </div>

    <!-- Selection tree -->
    <div class="grid-row margin-top-3">
      <div class="grid-col-6">
        <search-filter
          :taxonomy="taxonomyTree"
          :isTaxonomyLoading="loadingTaxonomy"
          :includeCount="true"
          :parentsAreSelectable="false"
          :canSearchWithinGroup="false"
          :isSingleSelection="isSingleSelection"
          :truncatedNumberOfChildren="truncateNodeLists ? 5 : 1000"
          @filtersChanged="onSelectionChanged"
          :key="taxonomyTreeKey"
          ref="taxonomy"
        />
      </div>
    </div>

    <error-toast id="taxonomy-error" message="An error occurred loading the hazard taxonomy" />
  </div>
</template>

<script lang="ts">
import { Prop, Component, Vue, Emit, Watch } from "vue-property-decorator";
import Fuse from "fuse.js";
import HazardDataSearchHttpHelper from "@/components/resources/hazardDataSearchHttpHelper";
import Container from "@/dependencyInjection/config";
import ServiceTypes from "@/dependencyInjection/types";
import { FilterNodeInformation } from "@/dataModel";
import SearchFilter from "../../common/SearchFilter.vue";
import AppliedSelectionsPanel from "../../common/AppliedSelectionsPanel.vue";
import Spinner from "../../common/Spinner.vue";
import ErrorToast from "@/components/common/ErrorToast.vue";
import { SelectionItem } from "@/dataModel/interfaces";
import { HazardType } from "@/constants/HazardType";

@Component({
  components: {
    AppliedSelectionsPanel,
    SearchFilter,
    Spinner,
    ErrorToast,
  },
})
export default class HazardSearchAndBrowse extends Vue {
  private searchHttpHelper: HazardDataSearchHttpHelper = Container.get<HazardDataSearchHttpHelper>(
    ServiceTypes.HazardDataSearchHttpHelper,
  );

  searchTerm: string = "";
  loadingTaxonomy: boolean = false;
  truncateNodeLists: boolean = true;
  showNoResultsMsg: boolean = false;
  selectedHazards: FilterNodeInformation[] = [];
  taxonomyTree: FilterNodeInformation[] = [];
  taxonomyLeafNodes: FilterNodeInformation[] = [];
  taxonomyTreeKey: number = 0;

  @Prop({ default: false }) readonly isSingleSelection!: boolean;
  @Prop({ default: true }) readonly showViewButton!: boolean;
  @Prop({ default: () => [] }) readonly initialSelections!: string[];
  @Prop({ required: false }) readonly selectedHazardPropertyId!: string | null;
  @Prop({ required: true }) readonly selectedHazardType!: HazardType | null;

  @Emit("onViewData")
  onViewData(selections: FilterNodeInformation[]) {
    return selections;
  }

  onSearch(): void {
    if (!this.searchTerm) {
      this.truncateNodeLists = true;
      this.showNoResultsMsg = false;
      return;
    }

    this.truncateNodeLists = false;

    // Run fuse method given the search term
    const options = {
      keys: ["path"],
      threshold: 0.4,
    };
    const fuse = new Fuse(this.taxonomyLeafNodes, options);
    const results = fuse.search(this.searchTerm);
    const match = results[0]?.item;
    if (match === undefined) {
      this.showNoResultsMsg = true;
      return;
    }
    this.showNoResultsMsg = false;

    // Loop through tree and expand the path for the match found
    const path = (match.path as string[]).slice(1);
    let node = this.taxonomyTree[0];
    while (path.length > 0 && node?.children !== undefined) {
      const pathItem = path.shift();
      const child = node.children.filter((c) => c.value?.value === pathItem)[0];
      if (child) {
        child.isExpanded = true;
      }
      node = child;
    }
  }

  onSelectionChanged(selections: FilterNodeInformation[]) {
    if (this.isSingleSelection) {
      this.onViewData(selections);
    } else {
      this.selectedHazards = selections;
    }
  }

  removeSelection(selection: SelectionItem) {
    const node = selection.data as FilterNodeInformation;
    this.selectedHazards.splice(this.selectedHazards.indexOf(node), 1);
    (this.$refs.taxonomy as SearchFilter).removeSelectedFilter(node);
  }

  removeAllSelections() {
    this.selectedHazards = [];
    (this.$refs.taxonomy as SearchFilter).clearSelections();
  }

  get rootNode(): FilterNodeInformation {
    return this.taxonomyTree[0];
  }

  get canViewData(): boolean {
    return this.selectedHazards.length > 0;
  }

  get selectionPanelItems(): SelectionItem[] {
    return this.selectedHazards.map((h) => {
      const selection: SelectionItem = {
        displayValue: h.stringValue ?? "N/A",
        data: h,
      };
      return selection;
    });
  }

  @Watch("selectedHazardPropertyId")
  async selectedHazardPropertyIdWatcher(): Promise<void> {
    await this.populateTaxonomy();
    this.forceRerenderTaxonomyTree();
  }

  @Watch("taxonomyTree")
  taxonomyTreeWatcher(): void {
    this.emitTaxonomyTree();
  }

  emitTaxonomyTree(): void {
    this.$emit("onTaxonomyTreeUpdated", this.taxonomyTree);
  }

  forceRerenderTaxonomyTree(): void {
    this.taxonomyTreeKey += 1;
  }

  async populateTaxonomy(): Promise<void> {
    const tree: FilterNodeInformation = await this.searchHttpHelper.GetHazardDataTaxonomyAsync(
      this.selectedHazardType!,
      this.selectedHazardPropertyId,
    );

    this.taxonomyTree = [tree];

    // Hide 'select all' options
    this.rootNode.children?.forEach((n) => (n.hideSelectAll = true));

    // Get leaf nodes for searching
    this.taxonomyLeafNodes = this.taxonomyTree.flatMap((t) => t.leafNodes);
  }

  @Watch("selectedHazardType")
  async selectedHazardTypeWatcher(): Promise<void> {
    if (this.selectedHazardType != null) {
      await this.populateTaxonomy();
    }
  }

  async created(): Promise<void> {
    if (this.selectedHazardType == null) {
      return;
    }

    this.loadingTaxonomy = true;
    await this.populateTaxonomy()
      .then(() => {
        // Select nodes that are part of initial selections
        const selectedNodes = this.taxonomyLeafNodes.filter((n) =>
          this.initialSelections.includes(n.stringValue ?? ""),
        );
        (this.$refs.taxonomy as SearchFilter).selectFilters(selectedNodes);
      })
      .catch((err) => {
        console.error(err);
        this.$bvToast.show("taxonomy-error");
      })
      .finally(() => (this.loadingTaxonomy = false));
  }

  mounted(): void {
    this.truncateNodeLists = true;
  }
}
</script>
