import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import { PermissionState } from '@/typings/store/states/PermissionStoreState';

 const state: PermissionState = {
    classifications: [],
    disseminationControls: [],
    scis: [],
    audiences: []
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};