<template>
  <basic-dialog
    id="apply-filters-dialog"
    title="Filters"
    size="lg"
    primaryButton="Apply"
    secondaryButton="Cancel"
    :primaryClick="apply"
    :secondaryClick="cancel"
  >
    <div v-if="isLoading" class="horizontal-center">
      <spinner />
    </div>

    <applied-selections-panel
      v-show="selectedFilters.length > 0"
      class="mt-3"
      panelButtonText="Clear All"
      :selections="selectionPanelItems"
      :defaultRowWidth="702"
      @onPanelButtonClicked="removeAllSelections"
      @onRemoveSelection="removeSelection"
      ref="appliedFiltersPanel"
    />

    <data-value-filters
      class="mt-4"
      :filters="filters"
      :showCounts="true"
      :preSelectedFilters="selectedFilters"
      @filtersChanged="filtersChanged"
      @viewDataSource="viewDataSource"
    />
  </basic-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import AppliedSelectionsPanel from "@/components/common/AppliedSelectionsPanel.vue";
import { SelectionItem } from "@/dataModel/interfaces";
import DataValueFilters from "@/components/navigation/UserHazardData/Dialogs/DataValueFilters.vue";
import DataValueFilterParent from "@/dataModel/hazardData/dataValueFilterParent";
import DataValueFilter from "@/dataModel/hazardData/dataValueFilter";
import Spinner from "@/components/common/Spinner.vue";

@Component({
  components: {
    BasicDialog,
    AppliedSelectionsPanel,
    DataValueFilters,
    Spinner,
  },
})
export default class ApplyFiltersDialog extends Vue {
  selectedFilters: DataValueFilter[] = [];

  @Prop({ default: () => [] }) filters!: DataValueFilterParent[];
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({ default: () => [] }) currentSelectedFilters!: DataValueFilter[];

  filtersChanged(selections: DataValueFilter[]): void {
    this.selectedFilters = selections;
  }

  get selectionPanelItems(): SelectionItem[] {
    return this.selectedFilters.map((filterItem) => {
      const selection: SelectionItem = {
        displayValue: filterItem.displayValue ?? "N/A",
        data: filterItem,
      };
      return selection;
    });
  }

  removeSelection(selection: SelectionItem) {
    const node = selection.data as DataValueFilter;
    this.selectedFilters.splice(this.selectedFilters.indexOf(node), 1);
  }

  removeAllSelections() {
    this.selectedFilters.splice(0, this.selectedFilters.length);
  }

  apply(): void {
    this.$emit("apply", this.selectedFilters);
    this.$bvModal.hide("apply-filters-dialog");
  }

  cancel(): void {
    this.$bvModal.hide("apply-filters-dialog");
  }

  viewDataSource(sourceId: string): void {
    this.$emit("viewDataSource", sourceId);
  }

  created(): void {
    this.$root.$on("bv::modal::show", () => {
      this.selectedFilters = this.currentSelectedFilters;
    });
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/user-hazard-data.scss";
</style>
