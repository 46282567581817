import { DocumentManagementStoreGetters } from "@/constants/store/siteDocumentManagement/documentManagementStoreConstants";
import { DocumentManagementStoreState } from "@/typings/store/states/SiteDocumentManagementStoreState";
import { FilterNodeInformation } from "@/dataModel";

export default {
  [DocumentManagementStoreGetters.GET_SEARCH_TERM]: (state: DocumentManagementStoreState): string => state.searchTerm,
  [DocumentManagementStoreGetters.GET_PAGE]: (state: DocumentManagementStoreState): number => state.page,
  [DocumentManagementStoreGetters.GET_RESULTS_PER_PAGE]: (state: DocumentManagementStoreState): number =>
    state.resultsPerPage,
  [DocumentManagementStoreGetters.GET_FILTERS]: (state: DocumentManagementStoreState): FilterNodeInformation[] =>
    state.filters,
};
