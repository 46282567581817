import FeaturedLengthType from "@/constants/featuredLengthType";
import { FeaturedResource } from "./interfaces";

export class FeaturedDocument implements FeaturedResource {
  private _title: string;
  private _titleClassification: string;

  siteDocumentId: string;
  displayOrder: number;
  startDate: Date;
  endDate?: Date | null;
  featuredLengthType: FeaturedLengthType;
  customFeaturedLength?: number;
  contributors: string[];
  knowledgeResourceId?: string;

  public get title() {
    return this._title;
  }

  public get titleClassification(): string {
    return this._titleClassification;
  }

  constructor(
    siteDocumentId?: string,
    displayOrder?: number,
    startDate?: Date,
    endDate?: Date | null,
    featuredLengthType?: FeaturedLengthType,
    customFeaturedLength?: number,
    title?: string,
    titleClassification?: string,
    contributors?: string[],
    knowledgeResourceId?: string,
  ) {
    this.siteDocumentId = siteDocumentId ?? "";
    this.displayOrder = displayOrder ?? 0;
    this.startDate = startDate ?? new Date();
    this.endDate = endDate ?? null;
    this.featuredLengthType = featuredLengthType ?? FeaturedLengthType.OneMonth;
    this.customFeaturedLength = customFeaturedLength ?? 0;
    this._title = title ?? "";
    this._titleClassification = titleClassification ?? "";
    this.contributors = contributors ?? [];
    this.knowledgeResourceId = knowledgeResourceId ?? "";
  }
}
