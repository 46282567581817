<template>
  <basic-dialog
    id="edit-category-dialog"
    title="Edit Category"
    primaryButton="Save"
    secondaryButton="Cancel"
    :primaryClick="confirmFormIsValid"
    :secondaryClick="cancel"
    :isDisabled="false"
  >
    <div>
      <label class="usa-label" for="display-name">Display Name</label>
      <input
        class="usa-input"
        id="display-name"
        name="display-name"
        type="text"
        aria-describedby="empty-display-name-error-message 
        duplicate-sibling-error-message 
        duplicate-parent-error-message 
        duplicate-child-error-message"
        v-model.trim="displayName"
      />
      <span
        class="usa-error-message"
        id="empty-display-name-error-message"
        v-show="hasTriedToSubmit && !isValid(displayName)"
      >
        The display name cannot be empty
      </span>

      <span
        class="usa-error-message"
        id="duplicate-sibling-error-message"
        v-show="hasTriedToSubmit && !isUniqueWithSiblings(displayName)"
      >
        The display name cannot be the same as its sibling categories
      </span>
      <span
        class="usa-error-message"
        id="duplicate-parent-error-message"
        v-show="hasTriedToSubmit && isValid(displayName) && !isUniqueWithParent(displayName)"
      >
        The display name cannot be the same as the parent category
      </span>
      <span
        class="usa-error-message"
        id="duplicate-child-error-message"
        v-show="hasTriedToSubmit && isValid(displayName) && !isUniqueWithChildren(displayName)"
      >
        The display name cannot be the same as a child category
      </span>
    </div>
    <div>
      <label class="usa-label" for="format">Display Format</label>
      <select class="usa-select width-mobile" name="format" id="format" v-model="format">
        <option :value="formatTypes.Normal">Normal</option>
        <option :value="formatTypes.Italic">Italic</option>
      </select>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Vue, Component, Emit, Prop, Watch } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import { Category } from "@/dataModel";
import { UpdateCategoryRequest } from "@/dataModel/requests";
import FormatType from "@/constants/FormatType";

@Component({
  components: {
    BasicDialog,
  },
})
export default class EditCategoryDialog extends Vue {
  displayName: string = "";
  hasTriedToSubmit: boolean = false;
  format = FormatType.Normal;
  formatTypes = FormatType;

  @Prop({ required: true }) category!: Category;
  @Prop({ required: true }) siblingCategories!: Category[];
  @Prop({ required: true }) parentDisplayName!: string;
  @Prop({ required: true }) childCategories!: Category[];
  @Watch("category")
  setInputFieldsFromCategory() {
    this.displayName = this.category.displayName;
    this.format = this.category.formatType;
  }

  confirmFormIsValid(): void {
    if (!this.isValidForm()) {
      this.displayErrors();
      return;
    }
    const request = new UpdateCategoryRequest(this.category.id, this.displayName, this.category.parentId, this.format);
    this.updateCategory(request);
  }

  isValidForm(): boolean {
    return this.isValid(this.displayName) && this.isValid(this.category.id) && this.isUnique(this.displayName);
  }

  displayErrors(): void {
    this.hasTriedToSubmit = true;
  }

  isValid(input: string): boolean {
    return input != null && input != undefined && input.length > 0;
  }

  isUnique(displayName: string): boolean {
    return (
      this.isUniqueWithSiblings(displayName) &&
      this.isUniqueWithParent(displayName) &&
      this.isUniqueWithChildren(displayName)
    );
  }

  isUniqueWithSiblings(displayName: string): boolean {
    const siblingCategories = this.siblingCategories.filter((sibling) => sibling.id != this.category.id);
    const siblingDisplayNames = siblingCategories.map((category) => category.displayName.toLowerCase());
    return !siblingDisplayNames.includes(displayName.toLowerCase());
  }

  isUniqueWithParent(displayName: string): boolean {
    return displayName.toLowerCase() != this.parentDisplayName.toLowerCase();
  }

  isUniqueWithChildren(displayName: string): boolean {
    const childDisplayNames = this.childCategories.map((category) => category.displayName.toLowerCase());
    return !childDisplayNames.includes(displayName.toLowerCase());
  }

  @Emit()
  updateCategory(request: UpdateCategoryRequest): UpdateCategoryRequest {
    this.$bvModal.hide("edit-category-dialog");
    return request;
  }

  cancel(): void {
    this.$bvModal.hide("edit-category-dialog");
  }

  mounted(): void {
    this.resetFormOnDialogOpen();
  }

  resetFormOnDialogOpen(): void {
    this.$root.$on("bv::modal::show", () => {
      this.setInputFieldsFromCategory();
      this.hasTriedToSubmit = false;
    });
  }
}
</script>

<style scoped lang="scss"></style>
