<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row mb-3">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>
      <div v-if="resultCount > 0" class="grid-row margin-bottom-3">
        <div v-if="!termUsedInSearch" class="usa-prose">
          Showing {{ startingResultCount }}-{{ endingResultCount }} of
          <span class="bold">{{ resultCount }} results</span>
        </div>
        <div v-else class="usa-prose">
          Showing {{ startingResultCount }}-{{ endingResultCount }} of
          <span class="bold">{{ resultCount }} results</span> for '{{ formattedTermUsedInSearch | truncate(50) }}'
          <button class="usa-button--unstyled">
            <i class="fas fa-window-close" @click="clearSearchTerm()"></i>
          </button>
        </div>
      </div>
      <div class="grid-row">
        <div class="desktop:grid-col-12 filters">
          <div class="grid-row margin-bottom-1 search-options-container">
            <form
              id="search-form"
              class="usa-search usa-search--small search-style"
              role="search"
              @submit.prevent="submitNewSearch()"
              v-bind:class="{ 'search-focused': isSearchBoxFocused }"
            >
              <input
                class="usa-input"
                id="search-field-en-small"
                type="search"
                name="search"
                v-model="searchTerm"
                aria-describedby="search-term-error"
                placeholder="Enter Search Terms..."
                @focus="isSearchBoxFocused = true"
                @blur="isSearchBoxFocused = false"
              />
              <button class="usa-button" type="submit">
                <span class="usa-sr-only">Search</span>
              </button>
            </form>
            <span id="sort-by-container">
              <label id="sort-by-label" for="sort-by">Sort By:</label>
              <select
                class="usa-select sort-by-style"
                name="sort-by"
                id="sort-by"
                v-model="sortSelection"
                @change="sortOrderChanged"
              >
                <option :value="SortSelection.RELEVANCE" v-show="searchTerm">Most Relevant</option>
                <option :value="SortSelection.DOCUMENT_DATE_DESCENDING">Most Recent</option>
                <option :value="SortSelection.DOCUMENT_DATE_ASCENDING">Oldest</option>
                <option :value="SortSelection.TITLE_ASCENDING">Title (ascending)</option>
                <option :value="SortSelection.TITLE_DESCENDING">Title (descending)</option>
              </select>
            </span>
            <span class="cn">
              <results-per-page-button
                class="margin-right-2"
                @resultsPerPageChanged="perPageAmountChanged"
                :prevPerPage="perPage"
              />
              <table-pagination
                v-if="resultCount > 0"
                @prev="prevPage"
                @next="nextPage"
                :page="page"
                :hasNextPage="hasNextPage"
              />
            </span>
          </div>
        </div>
      </div>
      <div class="margin-bottom-3">
        <div v-if="searchTermError.length" id="search-term-error" class="usa-error-message margin-top-neg-1">
          {{ searchTermError }}
        </div>
        <button @click="showAdvancedSearchDialog" class="usa-button usa-button--unstyled">
          Learn How to Improve Your Search
        </button>
      </div>
      <div class="grid-row">
        <span v-if="searchFilters.length > 0" ref="appliedFilters" id="applied-filter-container">
          <button class="usa-button usa-button--outline" @click="clearFilters">Clear all filters</button>
          <span v-for="(filter, index) in shownAppliedFilters" :key="index">
            <p class="applied-filter">
              {{ filter.stringValue
              }}<i
                class="fa fa-times delete-filter-btn float-right"
                @click="removeSelection(filter)"
                :aria-label="'Remove ' + filter.stringValue + ' filter'"
                tabindex="0"
                @keydown="removeAppliedFilterWithKeyboard($event, filter)"
              />
            </p>
          </span>
          <a
            v-if="!showAllAppliedFilters"
            href="#"
            class="usa-link"
            @click.prevent="toggleShowAllAppliedFilters"
            ref="showAllAppliedFiltersLink"
            >Show all<i class="fa fa-chevron-down fa-fw"></i
          ></a>
          <a
            v-else
            href="#"
            class="usa-link"
            @click.prevent="toggleShowAllAppliedFilters"
            ref="showAllAppliedFiltersLink"
            >Show less<i class="fa fa-chevron-up fa-fw"></i
          ></a>
        </span>
      </div>
      <div class="grid-row">
        <div class="desktop:grid-col-3 margin-bottom-2 filters">
          <search-filter
            class="margin-bottom-2"
            :isTaxonomyLoading="isTaxonomyLoading"
            :taxonomy="taxonomy"
            :includeCount="true"
            @filtersChanged="filtersChanged"
            @sectionExpanded="nodeExpanded"
            ref="filters"
            :parentSelectedFilters="searchFilters"
            @datesChanged="onDatesChanged"
            :yearCount="yearCount"
          />

          <button v-if="!isSubscribed" class="usa-button usa-button--unstyled alert-btn" @click="subscriptionHandler">
            <i class="fa fa-bell mr-1" />
            Set search alert
          </button>
          <button v-else class="usa-button usa-button--unstyled alert-btn" @click="subscriptionHandler">
            <i class="fa fa-bell mr-1" />
            Unsubscribe
          </button>
        </div>
        <div class="desktop:grid-col-9 padding-left-4">
          <div v-if="mustSelectTermOrFilters" class="grid-row">
            <invalid-search
              errorTitle="Start exploring the HKMA"
              errorMessage="Please select a filter or enter a search term to view results."
            />
          </div>
          <div v-else-if="!isSearchLoading && resultCount == 0" class="grid-row">
            <no-results-found :searchTerm="termUsedInSearch" />
          </div>
          <div v-else>
            <search-results
              v-for="result in searchResults"
              v-bind:key="result.id"
              :result="result"
              :searchTerm="termUsedInSearch"
            />
            <hr class="line-break" />

            <div id="spinner-container" v-show="isSearchLoading">
              <spinner id="spinner-overlay" />
            </div>
          </div>

          <div v-if="resultCount > 0" class="grid-row margin-top-3">
            <div class="grid-col-4 grid-offset-8">
              <div class="cn">
                <div></div>
                <table-pagination @prev="prevPage" @next="nextPage" :page="page" :hasNextPage="hasNextPage" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <subscription-dialog
      :isSubscribed="isSubscribed"
      :filters="searchFilters"
      :searchTerm="searchTerm"
      @confirmSubscribe="subscribe"
      @confirmUnsubscribe="unsubscribe"
      :userEmail="userEmail"
    />
    <advanced-search-dialog />
  </main>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import TablePagination from "@/components/common/TablePagination.vue";
import ResultsPerPageButton from "@/components/common/ResultsPerPageButton.vue";
import { BreadcrumbPathItem } from "@/dataModel/interfaces";
import SubscriptionDialog from "@/components/navigation/UserSearch/SubscriptionDialog.vue";
import NoResultsFound from "@/components/navigation/UserSearch/NoResultsFound.vue";
import InvalidSearch from "@/components/navigation/UserSearch/InvalidSearch.vue";
import SearchResults from "@/components/navigation/UserSearch/SearchResults.vue";
import Spinner from "@/components/common/Spinner.vue";
import DocumentType from "@/constants/DocumentTypeWithDisplayName";
import CopyrightStatus from "@/constants/CopyrightStatus";
import { NonCategoryFilters } from "@/constants/NonCategoryFilters";
import SearchFilter from "@/components/common/SearchFilter.vue";
import {
  FilterNodeInformation,
  DocumentSearchResult,
  AppConfig,
  Classification,
  DisseminationControl,
  KnowledgeResourceAudience,
  Sci,
  UserSearchParameters,
} from "@/dataModel";
import { SearchResponse } from "@/dataModel/responses";
import {
  DocumentSearchRequest,
  FilterCountRequest,
  ListFieldFilterWrapper,
} from "@/components/resources/siteDocumentHttpHelper";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import { AuthStoreGetters } from "@/constants/store/auth/authStoreConstants";
import { UserSearchStoreActions, UserSearchStoreGetters } from "@/constants/store/userSearch/userSearchStoreConstants";
import store from "@/store";
import StoreNames from "@/constants/store/StoreNames";
import CategoryHttpHelper from "@/components/resources/categoryHttpHelper";
import SortSelection from "@/constants/SortSelection";
import { FilterCountResponse } from "@/dataModel/responses";
import { FilterType } from "@/constants/FilterType";
import { FilterCountFilterType } from "@/constants/FilterCountFilterType";
import NotificationHttpHelper, {
  Filter,
  CreateSubscriptionRequest,
} from "@/components/resources/notificationHttpHelper";
import { v4 as uuidv4 } from "uuid";
import SubscriptionType from "@/constants/SubscriptionType";
import PermissionService from "@/services/PermissionService";
import { User } from "@/typings/store/states/AuthStoreState";
import AdvancedSearchDialog from "./AdvancedSearchDialog.vue";
import { YearCount } from "@/dataModel/interfaces";
import { UserSearchState } from "@/typings/store/states/UserSearchStoreState";
import { DocumentMixin } from "@/mixins";
import { throttle } from "lodash";
import {
  unrestrictedAudience,
  unrestrictedDissemination,
  unrestrictedSci,
} from "@/constants/UserSearchUnrestrictedFilterValues";

@Component({
  components: {
    Breadcrumb,
    ResultsPerPageButton,
    TablePagination,
    SubscriptionDialog,
    NoResultsFound,
    InvalidSearch,
    SearchResults,
    SearchFilter,
    Spinner,
    AdvancedSearchDialog,
  },
})
export default class UserSearchPage extends Mixins(DocumentMixin) {
  @Prop() initialSearchTerm: string | undefined;
  useSavedParams = false;
  searchTerm: string = "";
  termUsedInSearch: string = "";
  perPage = 20;
  page: number = 1;
  searchFilters: FilterNodeInformation[] = [];
  shownAppliedFilters: FilterNodeInformation[] = [];
  sortOrder: string = "descending";

  categoryFilters: FilterNodeInformation[] = [];
  classificationFilters: FilterNodeInformation[] = [];
  audienceFilters: FilterNodeInformation[] = [];
  disseminationControlFilters: FilterNodeInformation[] = [];
  copyrightFilters: FilterNodeInformation[] = [];

  SortSelection = SortSelection;
  sortSelection: SortSelection = SortSelection.RELEVANCE;
  lastExpandedNode?: FilterNodeInformation;

  sortType: string = "relevance";
  isSubscribed: boolean = false;
  categoryPaths: string[][] = [];

  classifications?: string[] = undefined;
  disseminationControls?: ListFieldFilterWrapper<string>;
  audiences?: ListFieldFilterWrapper<string>;
  scis?: ListFieldFilterWrapper<string>;
  documentTypeValues: string[] = [];
  copyrightStatuses: string[] = [];

  startYear?: Date = undefined;
  endYear?: Date = undefined;

  searchResults: DocumentSearchResult[] = [];
  resultCount: number = -1;
  yearCount: YearCount[] = [];

  userEmail: string = "";
  userClearanceLevel!: Classification;
  userDisseminationControls: DisseminationControl[] = [];
  userAudiences: KnowledgeResourceAudience[] = [];
  userScis: Sci[] = [];

  userId: string = "";
  showAllAppliedFilters: boolean = false;
  refreshCounts: boolean = true;
  subscriptionFilters: Filter[] = [];
  searchQuerySubscriptionId: string = "";
  searchTermError = "";

  isSearchLoading: boolean = false;
  isSearchBoxFocused: boolean = false;
  isTaxonomyLoading: boolean = true;

  // adv search operators
  readonly pairOperators = "AND|&&|OR|\\|\\|";
  readonly notOperators = "NOT|!";
  readonly allOperators = `${this.pairOperators}|${this.notOperators}`;

  private categoryHttpHelper = container.get<CategoryHttpHelper>(serviceTypes.CategoryHttpHelper);
  private notificationHttpHelper = container.get<NotificationHttpHelper>(serviceTypes.NotificationHttpHelper);
  private permissionService = container.get<PermissionService>(serviceTypes.PermissionService);
  private appConfig = container.get<AppConfig>(serviceTypes.AppConfig);
  private taxonomy: FilterNodeInformation[] = [];

  path: BreadcrumbPathItem[] = [
    {
      text: "Home",
      url: "/home",
    },
    {
      text: "Search",
    },
  ];

  readonly maxFilterRows = 2;
  $refs!: {
    appliedFilters: HTMLElement;
    showAllAppliedFiltersLink: HTMLElement;
    filters: SearchFilter;
  };
  throttleSearchMethod: any;

  countsMap: any = {};
  emptyFieldCount: number = 0;
  throttleGetFilterCountsMethod: any;
  mustSelectTermOrFilters: boolean = false;
  isInitialLoadCompleted: boolean = false;
  existingSearchParameters: UserSearchParameters | undefined;

  /** Formats termUsedInSearch to add implicit OR operators */
  get formattedTermUsedInSearch(): string {
    if (!this.termUsedInSearch) {
      return "";
    }

    // remove any extra spaces
    const trimmedTerm = this.termUsedInSearch.replaceAll(/ {2,}/g, " ").trim();

    // replace any whitespace characters not preceded/followed by one of these operators
    const regex = new RegExp(
      `"[^"]+"|(?<!(${this.notOperators}))(?<! (${this.allOperators})) (?!(${this.allOperators}) )`,
      "g",
    );

    return trimmedTerm.replaceAll(regex, (match) => (match.trim().length ? match : " OR "));
  }

  validateSearchTerm(): boolean {
    if (!this.searchTerm) {
      return true;
    }

    // remove content inside of quotes
    const termWithoutQuotes = this.searchTerm.replaceAll(/"[^"]+"/g, `""`);

    const pairsRegex = new RegExp(`(^| )(${this.pairOperators})( |$)`, "g");
    const notsRegex = new RegExp(`(^| )${this.notOperators}( *|$)`, "g");

    // get all instances of operators
    const pairs = termWithoutQuotes.match(pairsRegex);
    const nots = termWithoutQuotes.match(notsRegex);
    if (!pairs && !nots) {
      // search term doesn't include operators
      return true;
    }

    const allOperatorsRegex = new RegExp(`${pairsRegex.source}|${notsRegex.source}}`, "g");
    const termWithoutOperators = termWithoutQuotes.replaceAll(allOperatorsRegex, "").trim();
    if (!termWithoutOperators.length) {
      // search term only includes operators
      this.searchTermError = "Invalid search term";
      return false;
    }

    const validPairRegex = new RegExp(`(?<=\\w|\\d|"|\\)) +(${this.pairOperators}) +(?=\\w|\\d|"|\\()`, "g");
    const validNotRegex = new RegExp(`(${this.notOperators}) *(?=\\w|\\d|"|\\(|\\))`, "g");

    const pairsAreValid = this.operatorsAreValid(pairs, validPairRegex, termWithoutQuotes);
    const notsAreValid = this.operatorsAreValid(nots, validNotRegex, termWithoutQuotes);
    if (!pairsAreValid || !notsAreValid) {
      // not all operators are valid
      this.searchTermError = "An advanced search operator is missing terms";
      return false;
    }

    return true;
  }

  private operatorsAreValid(allInstances: RegExpMatchArray | null, validator: RegExp, search: string): boolean {
    if (!allInstances) {
      return true;
    }

    const validInstances = search.match(validator);
    return allInstances.length === validInstances?.length;
  }

  async submitNewSearch(): Promise<void> {
    this.page = 1;
    if (this.searchTerm && !this.termUsedInSearch) {
      this.updateSortSelection(SortSelection.RELEVANCE);
    }
    await this.throttleSearchMethod();
    this.countsMap = {};
    await this.throttleGetFilterCountsMethod();
    await this.getYearCounts();
  }

  async clearSearchTerm(): Promise<void> {
    this.searchTerm = "";
    await this.submitNewSearch();
  }

  async searchDocuments(): Promise<void> {
    if (!this.validateSearchTerm()) {
      return;
    }

    this.searchTermError = "";
    this.isSearchLoading = true;
    this.searchResults = [];
    this.resultCount = 0;
    this.termUsedInSearch = this.searchTerm;
    if (!this.searchTerm && this.sortSelection == SortSelection.RELEVANCE) {
      this.updateSortSelection(SortSelection.TITLE_ASCENDING);
    }
    this.updateSavedSearchParameters();
    store.dispatch(`${StoreNames.UserSearch}/${UserSearchStoreActions.SET_USE_SAVED_PARAMS}`, true);
    try {
      if (this.isSearchTermEmpty() && this.searchFilters.length == 0) {
        this.mustSelectTermOrFilters = true;
      } else {
        this.mustSelectTermOrFilters = false;
        const currentPage = this.page - 1;
        let searchRequest = new DocumentSearchRequest();
        searchRequest.page = currentPage;
        searchRequest.resultsPerPage = this.perPage;
        searchRequest.searchTerm = this.searchTerm;
        searchRequest.copyrightStatuses = this.copyrightStatuses;
        searchRequest.sortType = this.sortType;
        searchRequest.sortOrder = this.sortOrder;
        searchRequest.categoryPaths = this.categoryPaths;
        searchRequest.classifications = this.classifications;
        searchRequest.disseminationControls = this.disseminationControls;
        searchRequest.audiences = this.audiences;
        searchRequest.scis = this.scis;
        searchRequest.documentTypes = this.documentTypeValues;
        searchRequest.startYear = this.startYear;
        searchRequest.endYear = this.endYear;
        const response: SearchResponse<DocumentSearchResult> = await this.siteDocumentHttpHelper.searchDocuments(
          searchRequest,
        );

        this.searchResults = response.result;
        this.resultCount = response.count;

        this.checkSubscriptionState();
      }
    } finally {
      this.isSearchLoading = false;
    }
  }

  updateSavedSearchParameters(): void {
    if (!this.existingSearchParameters) {
      this.existingSearchParameters = new UserSearchParameters();
    }
    this.existingSearchParameters.page = this.page;
    this.existingSearchParameters.resultsPerPage = this.perPage;
    this.existingSearchParameters.filters = this.formatSavedFilters();
    this.existingSearchParameters.sortSelection = this.sortSelection;
    this.existingSearchParameters.startYear = this.startYear;
    this.existingSearchParameters.endYear = this.endYear;
    store.dispatch(
      `${StoreNames.UserSearch}/${UserSearchStoreActions.SET_SEARCH_PARAMETERS}`,
      this.existingSearchParameters,
    );
    store.dispatch(`${StoreNames.UserSearch}/${UserSearchStoreActions.SET_SEARCH_TERM}`, this.searchTerm);
  }

  isSearchTermEmpty(): boolean {
    return !this.searchTerm || !this.searchTerm.trim();
  }

  createFilterCountRequest(): FilterCountRequest {
    let request: FilterCountRequest = new FilterCountRequest();

    request.searchTerm = this.searchTerm;
    request.categoryPaths = this.categoryPaths;
    request.classifications = this.classifications;
    request.disseminationControls = this.disseminationControls;
    request.scis = this.scis;
    request.audiences = this.audiences;
    request.copyrightStatuses = this.copyrightStatuses;
    request.documentTypes = this.documentTypeValues;
    request.startYear = this.startYear;
    request.endYear = this.endYear;

    return request;
  }

  async getFilterCounts(): Promise<void> {
    let expandedNode = this.lastExpandedNode;
    if (!expandedNode) {
      return;
    }
    this.resetFilterCounts(expandedNode);
    let request = this.createFilterCountRequest();
    if (expandedNode.children) {
      switch (expandedNode.children[0].filterType) {
        case FilterType.Category:
          request.categoryPaths = this.categoryPaths.filter((x) => x[0] != expandedNode!.path![0]);
          request.filterType = FilterCountFilterType.Category;
          request.categoryRoot = expandedNode.path![0];
          break;
        case FilterType.Classification:
          request.classifications = undefined;
          request.filterType = FilterCountFilterType.Classification;
          break;
        case FilterType.DisseminationControl:
          request.disseminationControls = undefined;
          request.filterType = FilterCountFilterType.DisseminationControl;
          break;
        case FilterType.Audience:
          request.audiences = undefined;
          request.filterType = FilterCountFilterType.Audience;
          break;
        case FilterType.Sci:
          request.scis = undefined;
          request.filterType = FilterCountFilterType.Sci;
          break;
        case FilterType.CopyrightRestriction:
          request.copyrightStatuses = [];
          request.filterType = FilterCountFilterType.CopyrightStatus;
          break;
        case FilterType.DocumentType:
          request.documentTypes = [];
          request.filterType = FilterCountFilterType.DocumentType;
          break;
      }
    }
    const response: FilterCountResponse = await this.siteDocumentHttpHelper.getFilterCounts(request);

    this.countsMap = {};
    this.emptyFieldCount = 0;
    this.populateMapping(response.countResult.bucketCounts, this.countsMap);
    this.emptyFieldCount = response.countResult.emptyFieldCount;

    this.fillInCategoryCounts([expandedNode]);
    this.refreshCounts = false;
  }

  populateMapping(values: any[], outputMap: any): void {
    values.reduce(function (map, obj) {
      map[obj.value] = obj.count;
      return map;
    }, outputMap);
  }

  async nodeExpanded(expandedNode: FilterNodeInformation): Promise<void> {
    this.lastExpandedNode = expandedNode;
    await this.throttleGetFilterCountsMethod();
  }

  resetFilterCounts(collapsedNode: FilterNodeInformation): void {
    collapsedNode.count = undefined;
    if (collapsedNode.children) {
      collapsedNode.children.forEach((x) => {
        this.resetFilterCounts(x);
      });
    }
  }

  updateSortSelection(sortSelection: SortSelection): void {
    this.sortSelection = sortSelection;
    this.setSortBasedOnSelection();
  }

  setSortBasedOnSelection(): void {
    switch (this.sortSelection) {
      case SortSelection.RELEVANCE:
        this.sortType = "relevance";
        break;
      case SortSelection.DOCUMENT_DATE_ASCENDING:
      case SortSelection.DOCUMENT_DATE_DESCENDING:
        this.sortType = "documentDate";
        break;
      case SortSelection.TITLE_ASCENDING:
      case SortSelection.TITLE_DESCENDING:
        this.sortType = "title";
        break;
    }
    switch (this.sortSelection) {
      case SortSelection.RELEVANCE:
      case SortSelection.DOCUMENT_DATE_DESCENDING:
      case SortSelection.TITLE_DESCENDING:
        this.sortOrder = "descending";
        break;
      case SortSelection.DOCUMENT_DATE_ASCENDING:
      case SortSelection.TITLE_ASCENDING:
        this.sortOrder = "ascending";
        break;
    }
  }

  sortOrderChanged(): void {
    this.setSortBasedOnSelection();
    this.page = 1;
    if (this.isInitialLoadCompleted) {
      this.throttleSearchMethod();
    }
  }

  subscriptionHandler(): void {
    this.$bvModal.show("subscription-dialog");
  }

  showAdvancedSearchDialog(): void {
    this.$bvModal.show("advanced-search-dialog");
  }

  async created(): Promise<void> {
    const searchState: UserSearchState =
      store.getters[`${StoreNames.UserSearch}/${UserSearchStoreGetters.GET_SEARCH_STATE}`];

    this.useSavedParams = searchState.useSavedParams;
    this.searchTerm = this.initialSearchTerm ?? (this.useSavedParams ? searchState.searchTerm : "");
    if (!this.searchTerm || this.searchTerm.length == 0) {
      this.updateSortSelection(SortSelection.TITLE_ASCENDING);
    }
    if (this.useSavedParams) {
      this.existingSearchParameters = searchState.searchParameters;
    }
    this.throttleSearchMethod = throttle(async () => {
      await this.searchDocuments();
    }, this.appConfig.searchRequestMinimumIntervalInMilliseconds);
    this.throttleGetFilterCountsMethod = throttle(async () => {
      await this.getFilterCounts();
    }, this.appConfig.searchRequestMinimumIntervalInMilliseconds);
    await this.getUser();
    try {
      await this.populateTaxonomy();
      if (this.existingSearchParameters) {
        this.initializeSearchParameters(this.existingSearchParameters);
      }
    } catch (err) {
      console.error(err);
    }

    await this.throttleSearchMethod();
    if (this.searchTermError) {
      this.mustSelectTermOrFilters = true;
    } else {
      await this.getYearCounts();
    }

    this.isInitialLoadCompleted = true;
  }

  initializeSearchParameters(searchParameters: UserSearchParameters): void {
    this.page = searchParameters.page ?? this.page;
    this.perPage = searchParameters.resultsPerPage ?? this.perPage;
    if (searchParameters.filters) {
      this.setPreselectedFilters(searchParameters.filters);
    }

    if (searchParameters.sortSelection) {
      this.sortSelection = searchParameters.sortSelection;
      this.setSortBasedOnSelection();
    }
    this.startYear = searchParameters.startYear;
    this.endYear = searchParameters.endYear;
  }

  getClassificationFilters(): FilterNodeInformation | null {
    let root = new FilterNodeInformation();
    root.stringValue = NonCategoryFilters.Classification;
    root.children = [];
    root.filterType == FilterType.None;
    root.hideSelectAll = true;
    let classifications: Classification[] = this.permissionService.getClassifications();
    for (let i = 0; i < classifications.length; ++i) {
      let classification = classifications[i];
      if (classification.value > this.userClearanceLevel.value) {
        continue;
      }
      let childNode = new FilterNodeInformation();
      childNode.stringValue = classification.title;
      childNode.value = classification;
      childNode.filterType = FilterType.Classification;
      root.children.push(childNode);
    }
    if (root.children.length <= 1) {
      return null;
    }
    return root;
  }

  getDisseminationControlFilters(): FilterNodeInformation | null {
    let root = new FilterNodeInformation();
    root.stringValue = NonCategoryFilters.DisseminationControls;
    root.children = [];
    root.filterType == FilterType.None;
    root.hideSelectAll = true;
    let noDisseminationControlNode = new FilterNodeInformation();
    noDisseminationControlNode.stringValue = "No dissemination controls";
    noDisseminationControlNode.value = unrestrictedDissemination;
    noDisseminationControlNode.filterType = FilterType.DisseminationControl;
    root.children.push(noDisseminationControlNode);
    let disseminationControls: DisseminationControl[] = this.permissionService.getDisseminationControls();
    let allowedDisseminationControls = disseminationControls.filter(
      (x) => this.userDisseminationControls.findIndex((c) => c.id === x.id) >= 0,
    );
    for (let disseminationControl of allowedDisseminationControls) {
      let childNode = new FilterNodeInformation();
      childNode.stringValue = disseminationControl.title;
      childNode.value = disseminationControl;
      childNode.filterType = FilterType.DisseminationControl;
      root.children.push(childNode);
    }

    if (root.children.length <= 1) {
      return null;
    }
    return root;
  }

  getAudienceFilters(): FilterNodeInformation | null {
    let root = new FilterNodeInformation();
    root.stringValue = NonCategoryFilters.Audience;
    root.filterType = FilterType.None;
    root.children = [];
    root.hideSelectAll = true;
    let noAudienceNode = new FilterNodeInformation();
    noAudienceNode.stringValue = "No audience restrictions";
    noAudienceNode.value = unrestrictedAudience;
    noAudienceNode.filterType = FilterType.Audience;
    root.children.push(noAudienceNode);
    let audiences: KnowledgeResourceAudience[] = this.permissionService.getAudiences();
    let allowedAudiences = audiences.filter((x) => this.userAudiences.findIndex((a) => a.id === x.id) >= 0);
    for (let audience of allowedAudiences) {
      let childNode = new FilterNodeInformation();
      childNode.stringValue = audience.fullName;
      childNode.value = audience;
      childNode.filterType = FilterType.Audience;
      root.children.push(childNode);
    }
    if (root.children.length <= 1) {
      return null;
    }
    return root;
  }

  getSciFilters(): FilterNodeInformation | null {
    let root = new FilterNodeInformation();
    root.stringValue = NonCategoryFilters.Sci;
    root.filterType = FilterType.None;
    root.children = [];
    root.hideSelectAll = true;
    let noSciNode = new FilterNodeInformation();
    noSciNode.stringValue = "No SCI restrictions";
    noSciNode.value = unrestrictedSci;
    noSciNode.filterType = FilterType.Sci;
    root.children.push(noSciNode);
    let scis: Sci[] = this.permissionService.getScis();
    let allowedScis = scis.filter((x) => this.userScis.findIndex((a) => a.id === x.id) >= 0);
    for (let sci of allowedScis) {
      let childNode = new FilterNodeInformation();
      childNode.stringValue = sci.title;
      childNode.value = sci;
      childNode.filterType = FilterType.Sci;
      root.children.push(childNode);
    }
    if (root.children.length <= 1) {
      return null;
    }
    return root;
  }

  getDocumentTypeFilters(): FilterNodeInformation {
    let root = new FilterNodeInformation();
    root.stringValue = NonCategoryFilters.DocumentType;
    root.children = [];
    root.hideSelectAll = true;
    root.filterType = FilterType.None;

    for (let documentType of this.documentTypes) {
      let childNode = new FilterNodeInformation();
      childNode.stringValue = documentType.displayName;
      childNode.value = documentType;
      childNode.filterType = FilterType.DocumentType;
      root.children.push(childNode);
    }
    return root;
  }

  getCopyrightFilters(): FilterNodeInformation {
    let root = new FilterNodeInformation();
    root.stringValue = NonCategoryFilters.CopyrightStatus;
    root.filterType = FilterType.None;
    root.children = [];
    root.hideSelectAll = true;
    let copyrightStatuses: CopyrightStatus[] = this.getStaticClassMembers(CopyrightStatus);
    for (let status of copyrightStatuses) {
      let childNode = new FilterNodeInformation();
      childNode.stringValue = status.displayName;
      childNode.value = status;
      childNode.filterType = FilterType.CopyrightRestriction;
      root.children.push(childNode);
    }
    return root;
  }

  getStaticClassMembers(type: any): any[] {
    let memberNames = Object.keys(type);
    return memberNames.map((x) => type[x]);
  }

  async populateTaxonomy(): Promise<void> {
    try {
      this.isTaxonomyLoading = true;
      const categories = await this.categoryHttpHelper.getAllCategoriesTree();
      this.initializeCategories(categories);
      this.taxonomy = categories;
    } finally {
      this.isTaxonomyLoading = false;
    }

    let additionalDetailsSection = new FilterNodeInformation();
    additionalDetailsSection.stringValue = "Additional Details";
    additionalDetailsSection.filterType = FilterType.None;
    additionalDetailsSection.children = [];
    const potentialFilters = [
      this.getClassificationFilters(),
      this.getDisseminationControlFilters(),
      this.getSciFilters(),
      this.getAudienceFilters(),
      this.getCopyrightFilters(),
      this.getDocumentTypeFilters(),
    ];

    for (let filters of potentialFilters) {
      if (filters != null) {
        additionalDetailsSection.children.push(filters);
      }
    }
    let documentsByYearSection = new FilterNodeInformation();
    documentsByYearSection.stringValue = "Documents By Year";
    documentsByYearSection.filterType = FilterType.Year;

    this.taxonomy.push(documentsByYearSection, additionalDetailsSection);
  }

  initializeCategories(categories: FilterNodeInformation[]) {
    categories.sort(function (a, b) {
      return a.stringValue!.toLowerCase().localeCompare(b.stringValue!.toLowerCase());
    });
    for (let category of categories) {
      category.filterType = FilterType.Category;
      category.count = 0;
      if (category.children) {
        this.initializeCategories(category.children);
      }
    }
  }

  fillInCategoryCounts(categories: FilterNodeInformation[]): void {
    for (let category of categories) {
      if (category.filterType !== FilterType.Category) {
        if (
          category.value === unrestrictedSci ||
          category.value === unrestrictedAudience ||
          category.value === unrestrictedDissemination
        ) {
          category.count = this.emptyFieldCount;
        } else {
          this.fillInCountForNonCategoryFilter(category, category.filterType);
        }
      } else {
        if (category.path) {
          let count = this.countsMap[category.path!.toString()];
          if (!count) {
            count = 0;
          }
          category.count = count;
        } else {
          category.count = 0;
        }
      }
      if (category.children) {
        this.fillInCategoryCounts(category.children);
      }
    }
  }

  fillInCountForNonCategoryFilter(category: FilterNodeInformation, type: FilterType): void {
    switch (type) {
      case FilterType.Classification:
        category.count = this.countsMap[(category.value as Classification).id];
        break;
      case FilterType.DisseminationControl:
        category.count = this.countsMap[(category.value as DisseminationControl).id];
        break;
      case FilterType.Audience:
        category.count = this.countsMap[(category.value as KnowledgeResourceAudience).id];
        break;
      case FilterType.CopyrightRestriction:
        category.count = this.countsMap[(category.value as CopyrightStatus).value];
        break;
      case FilterType.DocumentType:
        category.count = this.countsMap[(category.value as DocumentType).value];
        break;
      case FilterType.Sci:
        category.count = this.countsMap[(category.value as Sci).id];
        break;
      default:
        break;
    }

    if (!category.count) {
      category.count = 0;
    }
  }

  removeSpacesAndCapitalize(str: string): string {
    return str
      .toLowerCase()
      .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
      .replaceAll(" ", "");
  }

  formatSavedFilters(): Filter[] {
    const formattedFilters: Filter[] = [];
    for (let filter of this.searchFilters) {
      const formattedFilter = new Filter();
      formattedFilter.FilterType = filter.filterType;
      switch (formattedFilter.FilterType) {
        case FilterType.Category:
          formattedFilter.Value = filter.path!.join("_");
          break;
        case FilterType.Classification:
          formattedFilter.Value = (filter.value as Classification).id;
          break;
        case FilterType.Audience:
          formattedFilter.Value =
            filter.value === unrestrictedAudience
              ? unrestrictedAudience
              : (filter.value as KnowledgeResourceAudience).id;
          break;
        case FilterType.CopyrightRestriction:
          formattedFilter.Value = this.removeSpacesAndCapitalize(filter.stringValue!);
          break;
        case FilterType.DocumentType:
          formattedFilter.Value = this.removeSpacesAndCapitalize(filter.stringValue!);
          break;
        case FilterType.DisseminationControl:
          formattedFilter.Value =
            filter.value === unrestrictedDissemination
              ? unrestrictedDissemination
              : (filter.value as DisseminationControl).id;
          break;
        case FilterType.Sci:
          formattedFilter.Value = filter.value === unrestrictedSci ? unrestrictedSci : (filter.value as Sci).id;
          break;
        case FilterType.Year:
          formattedFilter.Value = filter.stringValue!;
          break;
      }
      formattedFilters.push(formattedFilter);
    }
    return formattedFilters;
  }

  async checkSubscriptionState(): Promise<void> {
    this.subscriptionFilters = this.formatSavedFilters();
    const response = await this.notificationHttpHelper.getSubscriptionForUserBySearchQuery(
      this.userId,
      this.searchTerm,
      this.subscriptionFilters,
    );
    if (response) {
      this.isSubscribed = true;
      this.searchQuerySubscriptionId = response.subscription.id;
    } else {
      this.isSubscribed = false;
      this.searchQuerySubscriptionId = "";
    }
  }

  async subscribe(): Promise<void> {
    let createSubscriptionRequest = new CreateSubscriptionRequest();
    createSubscriptionRequest.id = uuidv4();
    createSubscriptionRequest.userId = this.userId;
    createSubscriptionRequest.subscriptionType = SubscriptionType.Query;
    createSubscriptionRequest.searchTerms = this.searchTerm;
    createSubscriptionRequest.searchFilters = this.subscriptionFilters;
    createSubscriptionRequest.searchResults = this.searchResults.slice(0, 10).map((result) => {
      return result.id;
    });

    const response = await this.notificationHttpHelper.createSubscription(createSubscriptionRequest);
    this.$bvToast.show("subscribe-toast");
    this.isSubscribed = true;
  }

  async unsubscribe(): Promise<void> {
    if (this.searchQuerySubscriptionId) {
      await this.notificationHttpHelper.deleteSubscription(this.searchQuerySubscriptionId);
      this.$bvToast.show("unsubscribe-toast");
      this.isSubscribed = false;
    }
  }

  prevPage(): void {
    if (this.page > 1) {
      this.page--;
      this.throttleSearchMethod();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }

  nextPage(): void {
    if (this.hasNextPage) {
      this.page++;
      this.throttleSearchMethod();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }

  get hasNextPage(): boolean {
    return this.page * this.perPage < this.resultCount;
  }

  get startingResultCount(): number {
    return (this.page - 1) * this.perPage + 1;
  }

  get endingResultCount(): number {
    const endCount = this.page * this.perPage;

    if (endCount > this.resultCount) {
      return this.resultCount;
    } else {
      return endCount;
    }
  }

  perPageAmountChanged(val: number): void {
    if (!this.isInitialLoadCompleted) {
      return;
    }
    this.perPage = val;
    this.page = 1;
    this.throttleSearchMethod();
  }

  addSpaces(str: string): string {
    return str.replace(/([a-z])([A-Z])/g, "$1 $2");
  }

  setPreselectedFilters(preselectedFilters: Filter[]): void {
    const selectedFilters: FilterNodeInformation[] = [];
    for (let preselectedFilter of preselectedFilters) {
      const filterType = preselectedFilter.FilterType;
      let filterNodeInfo = this.taxonomy;
      if (filterType != FilterType.Category) {
        let node: FilterNodeInformation | undefined;
        if (filterType === FilterType.Year) {
          node = new FilterNodeInformation();
          node.filterType = FilterType.Year;
          node.stringValue = preselectedFilter.Value;
          node.value = preselectedFilter.Value;
        } else {
          node = this.getPreselectedNonCategoryFilter(preselectedFilter);
        }

        if (node) {
          selectedFilters.push(node);
        } else {
          console.error("Preselected filter not found.", preselectedFilter);
        }
        continue;
      } else {
        const filterPath = preselectedFilter.Value.split("_");
        for (let j = 0; j < filterPath.length; j++) {
          const node = filterNodeInfo.find(
            (category) => category.value!.value!.toLowerCase() == filterPath[j].toLowerCase(),
          );

          if (node && j == filterPath.length - 1) {
            selectedFilters.push(node);
          } else if (node) {
            filterNodeInfo = node.children!;
          }
        }
      }
    }
    this.selectFilters(selectedFilters);
    this.updateFilters(selectedFilters);
  }

  getPreselectedNonCategoryFilter(filter: Filter): FilterNodeInformation | undefined {
    const nonCategoryFiltersParent = this.taxonomy.find((category) => category.stringValue === "Additional Details")!
      .children!;
    let nodeStringValue = "";
    switch (filter.FilterType) {
      case FilterType.CopyrightRestriction:
        nodeStringValue = NonCategoryFilters.CopyrightStatus;
        break;
      case FilterType.DocumentType:
        nodeStringValue = NonCategoryFilters.DocumentType;
        break;
      case FilterType.Classification:
        nodeStringValue = NonCategoryFilters.Classification;
        break;
      case FilterType.DisseminationControl:
        nodeStringValue = NonCategoryFilters.DisseminationControls;
        break;
      case FilterType.Sci:
        nodeStringValue = NonCategoryFilters.Sci;
        break;
      case FilterType.Audience:
        nodeStringValue = NonCategoryFilters.Audience;
        break;
      default:
        return;
    }
    const child = nonCategoryFiltersParent.find((x) => x.stringValue === nodeStringValue);
    if (!child) {
      return;
    }
    switch (filter.FilterType) {
      case FilterType.CopyrightRestriction:
      case FilterType.DocumentType:
        return child.children?.find((c) => c.stringValue!.toLowerCase() === this.addSpaces(filter.Value).toLowerCase());
      case FilterType.Classification:
        return child.children?.find((c) => (c.value as Classification).id === filter.Value);
      case FilterType.DisseminationControl:
        return filter.Value === unrestrictedDissemination
          ? this.findUnrestrictedDissemination(child)
          : child.children?.find((c) => (c.value as DisseminationControl).id === filter.Value);
      case FilterType.Sci:
        return filter.Value === unrestrictedSci
          ? this.findUnrestrictedSci(child)
          : child.children?.find((c) => (c.value as Sci).id === filter.Value);
      case FilterType.Audience:
        return filter.Value === unrestrictedAudience
          ? this.findUnrestrictedAudience(child)
          : child.children?.find((c) => (c.value as KnowledgeResourceAudience).id === filter.Value);
      default:
        return;
    }
  }

  findUnrestrictedSci(child: FilterNodeInformation): FilterNodeInformation | undefined {
    return child.children?.find((x) => x.value === unrestrictedSci);
  }

  findUnrestrictedAudience(child: FilterNodeInformation): FilterNodeInformation | undefined {
    return child.children?.find((x) => x.value === unrestrictedAudience);
  }

  findUnrestrictedDissemination(child: FilterNodeInformation): FilterNodeInformation | undefined {
    return child.children?.find((x) => x.value === unrestrictedDissemination);
  }

  filtersChanged(selectedFilters: FilterNodeInformation[], lastSelectedNode: FilterNodeInformation): void {
    if (!this.isInitialLoadCompleted) {
      return;
    }
    this.updateFilters(selectedFilters);
    this.getYearCounts();
    this.page = 1;
    this.throttleSearchMethod();

    if (this.lastExpandedNode && this.refreshCounts) {
      this.throttleGetFilterCountsMethod();
    }
  }

  private updateFilters(selectedFilters: FilterNodeInformation[]): void {
    this.classifications = selectedFilters
      .filter((x) => x.filterType == FilterType.Classification)
      .map((x) => (x.value as Classification).id);
    this.documentTypeValues = selectedFilters
      .filter((x) => x.filterType == FilterType.DocumentType)
      .map((x) => (x.value as DocumentType).value);
    this.copyrightStatuses = selectedFilters
      .filter((x) => x.filterType == FilterType.CopyrightRestriction)
      .map((x) => (x.value as CopyrightStatus).value);
    this.disseminationControls = this.getListFieldFilter(selectedFilters, FilterType.DisseminationControl);
    this.audiences = this.getListFieldFilter(selectedFilters, FilterType.Audience);
    this.scis = this.getListFieldFilter(selectedFilters, FilterType.Sci);
    this.categoryPaths = selectedFilters.filter((x) => x.filterType == FilterType.Category).map((x) => x.path!);
    const dates = selectedFilters
      .find((x) => x.filterType === FilterType.Year)
      ?.stringValue?.split("-")
      .map((x) => parseInt(x));
    if (dates?.[0]) {
      this.startYear = new Date(dates[0], 0);
      this.endYear = new Date(dates[1] ?? dates[0], 0);
    }

    this.searchFilters = selectedFilters;
    this.shownAppliedFilters = selectedFilters;

    this.$nextTick(() => {
      this.updateAppliedFilters();
    });
  }

  getListFieldFilter(
    selectedFilters: FilterNodeInformation[],
    filterType: FilterType,
  ): ListFieldFilterWrapper<string> | undefined {
    let matchingFilters = selectedFilters.filter((x) => x.filterType === filterType);
    if (matchingFilters && matchingFilters.length > 0) {
      return new ListFieldFilterWrapper<string>(
        matchingFilters
          .filter(
            (x) =>
              x.value !== unrestrictedSci && x.value !== unrestrictedAudience && x.value !== unrestrictedDissemination,
          )
          ?.map((x) => x.value.id),
        matchingFilters.map((x) => x.value).includes(unrestrictedSci) ||
          matchingFilters.map((x) => x.value).includes(unrestrictedAudience) ||
          matchingFilters.map((x) => x.value).includes(unrestrictedDissemination),
      );
    }
    return undefined;
  }

  toggleShowAllAppliedFilters(): void {
    this.showAllAppliedFilters = !this.showAllAppliedFilters;
    this.$nextTick(() => {
      this.updateAppliedFilters();
    });
  }

  updateAppliedFilters(): void {
    if (this.searchFilters.length > 0) {
      const filterContainer: HTMLElement = this.$refs.appliedFilters;
      const rowWidth = filterContainer.offsetWidth;
      const children: HTMLCollection = filterContainer.children;
      let total = 0;
      let numRows = 1;
      const showAllAppliedFiltersLink: HTMLElement = this.$refs.showAllAppliedFiltersLink;
      showAllAppliedFiltersLink.style.display = "inline-block";
      for (let i = 0; i < children.length; ++i) {
        let item: HTMLElement = children[i] as HTMLElement;

        if (item == showAllAppliedFiltersLink) {
          continue;
        }
        item.style.display = "inline-block";
        const style = window.getComputedStyle(item);
        const itemWidth = item.offsetWidth + parseFloat(style.marginRight);
        if (
          (numRows == this.maxFilterRows &&
            total + itemWidth + showAllAppliedFiltersLink.offsetWidth > rowWidth &&
            !this.showAllAppliedFilters) ||
          total + itemWidth > rowWidth
        ) {
          total = 0;
          ++numRows;
        }
        if (numRows > this.maxFilterRows && !this.showAllAppliedFilters) {
          item.style.display = "none";
        }
        total += itemWidth;
      }
      if (numRows <= this.maxFilterRows) {
        showAllAppliedFiltersLink.style.display = "none";
      }
    }
  }

  removeSelection(filter: FilterNodeInformation): void {
    this.refreshCounts = true;
    (this.$refs.filters as SearchFilter).removeSelectedFilter(filter);
    this.updateAppliedFilters();
  }

  selectFilters(filters: FilterNodeInformation[]): void {
    this.refreshCounts = true;
    (this.$refs.filters as SearchFilter).selectFilters(filters);
  }

  clearFilters(): void {
    this.refreshCounts = true;
    (this.$refs.filters as SearchFilter).clearSelections();
  }

  async getUser(): Promise<void> {
    const user: User = store.getters[`${StoreNames.Auth}/${AuthStoreGetters.GET_USER}`];
    this.userEmail = user.email;
    this.userId = user.userId;
    this.userClearanceLevel = user.actor!.clearanceLevel;
    this.userDisseminationControls = user.actor!.readOnlyDisseminationControls ?? [];
    this.userAudiences = user.actor!.readOnlyAudiences ?? [];
    this.userScis = user.actor!.scis ?? [];
  }

  async getYearCounts(): Promise<void> {
    if (!this.searchFilters.length && !this.searchTerm) {
      // clear yearCount to reset date slider
      this.yearCount.splice(0);
    }

    const request = this.createFilterCountRequest();
    // set to arbitrary filter type for now
    request.filterType = FilterCountFilterType.Category;
    const { yearCounts } = await this.siteDocumentHttpHelper.getFilterCounts(request);
    this.yearCount = yearCounts;
  }

  onDatesChanged(event?: number[]): void {
    this.startYear = event ? new Date(event[0], 0) : undefined;
    this.endYear = event ? new Date(event[1], 0) : undefined;

    if (this.lastExpandedNode) {
      this.resetFilterCounts(this.lastExpandedNode);
      this.throttleGetFilterCountsMethod();
    }

    if (event) {
      this.throttleSearchMethod();
    }
  }

  removeAppliedFilterWithKeyboard(event: KeyboardEvent, filter: FilterNodeInformation): void {
    if (!event.repeat && (event.code == "Space" || event.code == "Enter")) {
      event.preventDefault();
      this.removeSelection(filter);
    }
  }

  mounted(): void {
    window.addEventListener("resize", this.updateAppliedFilters);
  }

  beforeDestroy(): void {
    window.removeEventListener("resize", this.updateAppliedFilters);
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

.applied-filter-header {
  color: color("primary");
  font-weight: font-weight("bold");
  margin-bottom: 0.4rem;
}

.applied-filter {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: units(1);
  background-color: color("base-lightest");
  border-radius: 10px;
  padding: 8px;
}

.float-right {
  float: right;
}

.delete-filter-btn {
  color: color("base-dark");
  margin-top: 5px;
  margin-left: units(1);
}

.delete-filter-btn:hover {
  color: color("base-darkest");
}

.search-style {
  width: 45%;
  margin-right: units(2);
}

.sort-by-style {
  margin-top: 0;
  width: fit-content;
}

.usa-search [type="submit"] {
  height: 2.5rem;
}

[type="search"],
.usa-search__input {
  height: 2.5rem;
}

.alert-btn {
  text-decoration: none;
}

.bold {
  font-weight: font-weight("bold");
}

.cn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
}

.line-break {
  background: #c9c9c9;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

#applied-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

#applied-filter-container > * {
  margin-bottom: units(2);
}

#sort-by-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#sort-by-label {
  margin-right: units(1);
}

label {
  margin-bottom: 0 !important;
}

#spinner-container {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.search-focused {
  box-shadow: #2491ff 0px 0px 8px;
}

#search-form {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

#search-field-en-small:focus {
  outline: none;
}
</style>
