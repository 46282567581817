<template>
  <main id="main-content">
    <div id="section-background" class="bg-primary-darker">
      <img id="hkma-dhs-background-logo" src="../../../assets/img/dhs-logo.svg" role="img" alt="" aria-hidden="true" />
      <section class="grid-container usa-section">
        <div class="grid-row grid-gap">
          <div id="welcome" class="desktop:grid-col-6">
            <h1 id="hkma-name">Hazard Knowledge Management & Analysis</h1>
            <h1 id="hkma-name"></h1>
          </div>
          <div class="desktop:grid-col-6">
            <div class="usa-card__container">
              <header class="usa-card__header">
                <h2 class="usa-card__heading">Access Denied</h2>
                This user is inactive or has been explicitly denied access. To gain access, contact an administrator to
                activate your account.
              </header>
              <div class="usa-card__body" style="height: 309px">
                <div>
                  <button class="card-button usa-button" @click="signOut">Sign Out</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <error-toast id="user-not-found-toast" message="The username you entered was not found." />
  </main>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { User as UserState } from "@/typings/store/states/AuthStoreState";
import IAuthService from "@/services/interfaces/IAuthService";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";

@Component
export default class AccessDeniedPage extends Vue {
  private auth = container.get<IAuthService>(serviceTypes.AuthService);

  signOut() {
    this.auth.logout();
  }

  created() {
    const userState: UserState | null = this.auth.getAuthState()?.user ?? null;
    if (userState == null) {
      this.$bvToast.show("user-not-found-toast");
      this.$router.push("/home");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./src/styles/login-flow.scss";
</style>
