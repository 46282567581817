import IPermissionService from "./interfaces/IPermissionService";
import store from "@/store";
import { PermissionStoreActions, PermissionStoreGetters } from "@/constants/store/permission/permissionStoreConstants";
import { Classification, DisseminationControl, Sci, KnowledgeResourceAudience } from "@/dataModel";
import StoreNames from "@/constants/store/StoreNames";
import { injectable } from "inversify";
import PermissionHttpHelper from "@/components/resources/permissionHttpHelper";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";

@injectable()
export default class PermissionService implements IPermissionService {
  private permissionHttpHelper = container.get<PermissionHttpHelper>(serviceTypes.PermissionHttpHelper);

  public getClassifications(): Classification[] {
    return store.getters[`${StoreNames.Permission}/${PermissionStoreGetters.GET_CLASSIFICATIONS}`];
  }

  public getDisseminationControls(): DisseminationControl[] {
    return store.getters[`${StoreNames.Permission}/${PermissionStoreGetters.GET_DISSEMINATION_CONTROLS}`];
  }

  public getScis(): Sci[] {
    return store.getters[`${StoreNames.Permission}/${PermissionStoreGetters.GET_SCIS}`];
  }

  public getAudiences(): KnowledgeResourceAudience[] {
    return store.getters[`${StoreNames.Permission}/${PermissionStoreGetters.GET_AUDIENCES}`];
  }

  public async populateClassifications(): Promise<void> {
    const classifications = await this.permissionHttpHelper.getClassifications();
    store.dispatch(`${StoreNames.Permission}/${PermissionStoreActions.SET_CLASSIFICATIONS}`, classifications);
  }

  public async populateDisseminationControls(): Promise<void> {
    const disseminationControls = await this.permissionHttpHelper.getDisseminationControls();
    store.dispatch(
      `${StoreNames.Permission}/${PermissionStoreActions.SET_DISSEMINATION_CONTROLS}`,
      disseminationControls,
    );
  }

  public async populateScis(): Promise<void> {
    const scis = await this.permissionHttpHelper.getScis();
    store.dispatch(`${StoreNames.Permission}/${PermissionStoreActions.SET_SCIS}`, scis);
  }

  public async populateAudiences(): Promise<void> {
    const audiences = await this.permissionHttpHelper.getAudiences();
    store.dispatch(`${StoreNames.Permission}/${PermissionStoreActions.SET_AUDIENCES}`, audiences);
  }

  public async populateKnowledgeResourcePermissions(): Promise<void> {
    await this.populateClassifications();
    await this.populateDisseminationControls();
    await this.populateScis();
    await this.populateAudiences();
  }
}
