export enum UserSearchStoreActions {
    SET_SEARCH_PARAMETERS = "SET_SEARCH_PARAMETERS",
    SET_SEARCH_TERM = "SET_SEARCH_TERM",
    SET_USE_SAVED_PARAMS = "SET_USE_SAVED_PARAMS"
}

export enum UserSearchStoreGetters {
    GET_SEARCH_PARAMETERS = "GET_SEARCH_PARAMETERS",
    GET_SEARCH_STATE = "GET_SEARCH_STATE",
    GET_SEARCH_TERM = "GET_SEARCH_TERM",
    GET_USE_SAVED_PARAMS = "GET_USE_SAVED_PARAMS",
}

export enum UserSearchStoreMutations {
    SET_SEARCH_PARAMETERS = "SET_SEARCH_PARAMETERS",
    SET_SEARCH_TERM = "SET_SEARCH_TERM",
    SET_USE_SAVED_PARAMS = "SET_USE_SAVED_PARAMS"
}