<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <div class="desktop:grid-col">
          <h1 class="font-heading-xl text-primary-dark margin-top-0 margin-bottom-5">Edit Citation</h1>
        </div>
      </div>

      <div class="grid-row">
        <div class="grid-col">
          <label class="usa-label" for="title-text-input">Document Title</label>
          <input
            class="usa-input max-width-100"
            id="title-text-input"
            name="title-text-input"
            type="text"
            v-model.trim="documentTitle"
          />
          <span class="usa-error-message" id="title-required-error-message" v-show="showError(documentTitle)">
            Title cannot be empty
          </span>

          <label class="usa-label" for="author-text-input">Author(s)</label>
          <div class="usa-hint" id="author-hint">Separate each author by comma</div>
          <input
            class="usa-input max-width-100"
            id="author-text-input"
            name="author-text-input"
            type="text"
            v-model.trim="contributors"
            aria-describedby="author-hint"
          />
          <span class="usa-error-message" id="contributors-required-error-message" v-show="showError(contributors)">
            Authors cannot be empty
          </span>

          <label class="usa-label" for="input-type-text">Classification</label>
          <p class="usa-prose read-only">{{ dataCollection.overallClassificationMarking }}</p>

          <label class="usa-label" id="publication-date-label" for="publication-date"> Publication Date </label>
          <p class="usa-prose read-only max-width-30">{{ dataCollection.documentDateTime | formatDate }}</p>
        </div>
      </div>

      <div class="grid-row mt-5">
        <div class="grid-col">
          <button class="usa-button" @click="confirmClicked">Update Citation</button>
          <button class="usa-button usa-button--outline" @click="cancel">Cancel</button>
        </div>
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import store from "@/store";
import StoreNames from "@/constants/store/StoreNames";
import { DataCollectionStoreGetters } from "@/constants/store/dataCollection/dataCollectionStoreConstants";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import { BreadcrumbPathItem } from "@/dataModel/interfaces";
import DataSource from "@/dataModel/hazardData/dataSource";
import { UpdateSourceRequest } from "@/dataModel/requests";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import HazardDataAdminHttpHelper from "@/components/resources/hazardDataAdminHttpHelper";
import { isNullOrEmpty } from "@/services/utils";

@Component({
  components: {
    Breadcrumb,
  },
})
export default class EditDataCollectionPage extends Vue {
  dataCollection: DataSource = new DataSource();
  hasSubmitted: boolean = false;
  documentTitle: string = "";
  contributors: string = "";

  private hazardDataAdminHttpHelper = container.get<HazardDataAdminHttpHelper>(serviceTypes.HazardDataAdminHttpHelper);

  path: BreadcrumbPathItem[] = [
    {
      text: "Administration",
    },
    {
      text: "Data Collection Management",
      url: "/data-collection-management",
    },
  ];

  cancel(): void {
    this.viewDataCollectionDetails();
  }

  async confirmClicked(): Promise<void> {
    if (this.formIsValid) {
      await this.updateDataCollectionCitation().then(() => {
        this.viewDataCollectionDetails();
      });
    }
    this.hasSubmitted = true;
  }

  async updateDataCollectionCitation(): Promise<void> {
    let request = new UpdateSourceRequest(
      this.dataCollection.id,
      this.dataCollection.knowledgeResourceId,
      this.documentTitle,
      this.contributors,
      this.dataCollection.documentDateTime,
    );
    await this.hazardDataAdminHttpHelper.updateSource(request);
  }

  get formIsValid(): boolean {
    return !isNullOrEmpty(this.documentTitle) && !isNullOrEmpty(this.contributors);
  }

  viewDataCollectionDetails(): void {
    this.$router.push({ name: "DataCollectionDetails", query: { id: this.dataCollection.id } });
  }

  showError(input: string): boolean {
    return this.hasSubmitted && isNullOrEmpty(input);
  }

  created(): void {
    this.dataCollection =
      store.getters[`${StoreNames.DataCollection}/${DataCollectionStoreGetters.GET_SELECTED_DATA_COLLECTION}`];
    this.intializeFields();
    this.addTitleToBreadcrumbPath();
  }

  intializeFields(): void {
    this.documentTitle = this.dataCollection.title;
    this.contributors = this.dataCollection.contributorDisplay;
  }

  addTitleToBreadcrumbPath(): void {
    this.path.push({
      text: this.dataCollection.titleClassificationMarking + " " + this.dataCollection.title,
    });
  }
}
</script>

<style scoped lang="scss">
.max-width-100 {
  max-width: 100%;
}

.max-width-30 {
  max-width: 30%;
}

.read-only {
  padding: 0.5rem;
  border: 1px solid #565c65;
  margin-bottom: 0;
  margin-top: 0.5rem;
  color: #1b1b1b;
  height: 2.5rem;
  line-height: 1.3;
  background-color: #f5f5f5;
  cursor: default;
}

.read-only:active {
  cursor: not-allowed;
}
</style>
