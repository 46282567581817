export function isNullOrEmpty(input: string | Array<any> | number | undefined | null): boolean {
  if (input === null || input === undefined) {
    return true;
  } else if (typeof input == "string") {
    return input.trim().length == 0;
  } else if (Array.isArray(input)) {
    return input.length == 0;
  } else {
    return false;
  }
}

export function toCamelCase(key, value) {
  if (value && typeof value === "object") {
    for (let k in value) {
      if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
        value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
        delete value[k];
      }
    }
  }
  return value;
}

export function truncate(text: string, stop: number, clamp = "..."): string {
  if (text === undefined) {
    return "";
  }
  return text.slice(0, stop) + (stop < text.length ? clamp : "");
}
