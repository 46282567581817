import HttpHelper from "@/components/resources/httpHelper";
import { inject, injectable } from "inversify";
import serviceTypes from "@/dependencyInjection/types";
import { GetUsageAnalyticsRequest } from "@/dataModel/requests";
import UsageAnalyticsDetails from "@/dataModel/usageAnalytics/usageAnalyticsDetails";

const usageAnalyticUrl = "admin/usage-analytics";

@injectable()
export default class UsageAnalyticsHttpHelper {
  httpHelper: HttpHelper;

  constructor(@inject(serviceTypes.HttpHelper) httpHelper: HttpHelper) {
    this.httpHelper = httpHelper;
  }

  async getUsageAnalyticsAsync(request: GetUsageAnalyticsRequest): Promise<UsageAnalyticsDetails> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(usageAnalyticUrl, request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " while getting usage analytics",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting usage analytics"));
        });
    });
  }
}
