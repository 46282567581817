<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <div class="desktop:grid-col">
          <b-tabs content-class="mt-5" v-model="activeTabIndex">
            <b-tab title="Featured Documents" title-link-class="tab-link" active>
              <featured-document-section id="document-section" @show-new-status="showNewStatus" />
            </b-tab>
            <b-tab title="Featured Hazard Data" title-link-class="tab-link" title-item-class="tab-item">
              <featured-hazard-data-section @show-new-status="showNewStatus" />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </section>

    <featured-resource-status-dialog :status-message="featuredResourceStatusMessage" />
  </main>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import { BreadcrumbPathItem } from "@/dataModel/interfaces";
import FeaturedDocumentSection from "./FeaturedDocumentManagement/FeaturedDocumentSection.vue";
import FeaturedHazardDataSection from "./FeaturedHazardDataManagement/FeaturedHazardDataSection.vue";
import FeaturedResourceStatusDialog from "./Dialogs/FeaturedResourceStatusDialog.vue";
import { FeaturedResourcesMixin } from "./FeaturedDocumentManagement/FeaturedResourcesMixin";
import { FeaturedResourceAction } from "./Constants/FeaturedResourceAction";
import FeaturedHazardType from "@/constants/FeaturedHazardType";

@Component({
  components: {
    Breadcrumb,
    FeaturedDocumentSection,
    FeaturedHazardDataSection,
    FeaturedResourceStatusDialog,
  },
})
export default class FeaturedResourceManagementPage extends Mixins(FeaturedResourcesMixin) {
  activeTabIndex = 0;
  tabTitles = ["Featured Documents", "Featured Hazard Data"];
  featuredResourceStatusMessage = "";

  get path(): BreadcrumbPathItem[] {
    return [
      {
        text: "Administration",
      },
      {
        text: "Featured Resource Management",
      },
      {
        text: this.tabTitles[this.activeTabIndex],
      },
    ];
  }

  showNewStatus({ action, resourceType }): void {
    const resourceTypeLabel = resourceType === FeaturedHazardType.Document ? "Featured Document" : "Featured Data View";

    // Update message
    switch (action) {
      case FeaturedResourceAction.CREATE:
        this.featuredResourceStatusMessage = `${resourceTypeLabel} Added Successfully`;
        break;
      case FeaturedResourceAction.UPDATE:
        this.featuredResourceStatusMessage = `${resourceTypeLabel} Updated Successfully`;
        break;
      case FeaturedResourceAction.DELETE:
        this.featuredResourceStatusMessage = `${resourceTypeLabel} Deleted Successfully`;
        break;
      case FeaturedResourceAction.ORDER:
        this.featuredResourceStatusMessage = "Order Updated Successfully";
        break;
      case FeaturedResourceAction.NONE:
        this.featuredResourceStatusMessage = "N/A";
        break;
    }

    // Open modal
    this.$bvModal.show("featured-resource-status-dialog");
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";
@import "~@/styles/tabs.scss";

.pointer {
  cursor: pointer;
}

.title-classification {
  color: color("primary");
}
</style>
