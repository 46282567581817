<template>
  <basic-dialog
    id="delete-data-value-dialog"
    title="Confirm Delete"
    primaryButton="Confirm"
    secondaryButton="Cancel"
    :primaryClick="confirm"
    :secondaryClick="cancel"
    :isDisabled="isNullOrEmpty(this.selectedDataValues)"
  >
    <p class="usa-prose mt-3">
      Are you sure you want to delete {{ selectedDataValues.length &gt; 1 ? "these data values?" : "this data value?" }}
    </p>

    <div class="usa-alert usa-alert--info usa-alert--slim margin-top-5" v-if="isNullOrEmpty(this.selectedDataValues)">
      <div class="usa-alert__body">
        <p class="usa-alert__text">At least 1 data value must be selected from the table.</p>
      </div>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import HazardDataValue from "@/dataModel/hazardData/hazardDataValue";
import { isNullOrEmpty } from "@/services/utils";

@Component({
  components: {
    BasicDialog,
  },
})
export default class ConfirmDeleteDataValueDialog extends Vue {
  @Prop() selectedDataValues!: HazardDataValue[];

  @Emit()
  confirm(): void {
    this.$bvModal.hide("delete-data-value-dialog");
  }

  cancel(): void {
    this.$bvModal.hide("delete-data-value-dialog");
  }

  isNullOrEmpty(input: any): boolean {
    return isNullOrEmpty(input);
  }
}
</script>

<style scoped lang="scss"></style>
