import { AuthAccess } from "@/typings/store/states/AuthStoreState";

export interface NavItem {
  label: string;
  href?: string;
  children?: Array<NavItem>;
}

export default class NavBarService {
  getNavBarItems(authState: AuthAccess | null = null): Array<NavItem> {
    const navItems: Array<NavItem> = [{ label: "Home", href: "/home" }];

    NavBarService.addUserItemsCategory(navItems, authState);

    if (authState?.hasUserApplicationAccess) {
      navItems.push({ label: "Applications", href: "/applications-list" });
    }

    NavBarService.addAdminCategory(navItems, authState);

    return navItems;
  }

  private static addUserItemsCategory(navItems: Array<NavItem>, authState: AuthAccess | null = null) {
    if (authState?.hasUserHazardDataAccess) {
      navItems.push({ label: "Explore Hazard Data", href: "/hazard-data" });
    }
    const myItemsCategory: NavItem = { label: "My Content", children: [] };
    if (authState?.hasUserSearchAccess) {
      myItemsCategory.children?.push({ label: "Subscriptions", href: "/subscription-management" });
    }
    if (authState?.hasUserRequestAccess) {
      myItemsCategory.children?.push({ label: "Requests", href: "/user-requests" });
    }
    if (myItemsCategory.children!.length > 0) {
      navItems.push(myItemsCategory);
    }
  }

  private static addAdminCategory(navItems: Array<NavItem>, authState: AuthAccess | null = null) {
    const adminCategory: NavItem = { label: "Administration", children: [] };
    if (authState?.hasAdminDocumentAccess) {
      adminCategory.children?.push({ label: "Document staging", href: "/document-staging" });
      adminCategory.children?.push({ label: "Manage documents", href: "/document-management" });
    }
    if (authState?.hasDataCollectionManagementAccess) {
      adminCategory.children?.push({ label: "Manage data collections", href: "/data-collection-management" });
    }
    if (authState?.hasCategoryManagementAccess) {
      adminCategory.children?.push({ label: "Manage categories", href: "/category-management" });
    }
    if (authState?.hasAdminUserRequestAccess) {
      adminCategory.children?.push({ label: "Manage requests", href: "/request-management" });
    }
    if (authState?.hasAdminUserManagementAccess) {
      adminCategory.children?.push({ label: "Manage users", href: "/user-management" });
    }
    if (authState?.hasAdminRolesAccess) {
      adminCategory.children?.push({ label: "Manage roles", href: "/role-management" });
    }
    if (authState?.hasFeaturedResourceManagementAccess) {
      adminCategory.children?.push({ label: "Manage featured resources", href: "/featured-resource-management" });
    }
    if (adminCategory.children!.length > 0) {
      navItems.push(adminCategory);
    }
    if (authState?.hasUsageAnalyticsAccess) {
      adminCategory.children?.push({ label: "View usage analytics", href: "/usage-analytics" });
    }
  }
}
