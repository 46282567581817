export enum DocumentManagementStoreActions {
  SET_SEARCH_TERM = "SET_SEARCH_TERM",
  SET_PAGE = "SET_PAGE",
  SET_RESULTS_PER_PAGE = "SET_RESULTS_PER_PAGE",
  SET_FILTERS = "SET_FILTERS",
}

export enum DocumentManagementStoreGetters {
  GET_SEARCH_TERM = "GET_SEARCH_TERM",
  GET_PAGE = "GET_PAGE",
  GET_RESULTS_PER_PAGE = "GET_RESULTS_PER_PAGE",
  GET_FILTERS = "GET_FILTERS",
}

export enum DocumentManagementStoreMutations {
  SET_SEARCH_TERM = "SET_SEARCH_TERM",
  SET_PAGE = "SET_PAGE",
  SET_RESULTS_PER_PAGE = "SET_RESULTS_PER_PAGE",
  SET_FILTERS = "SET_FILTERS",
  UPDATE_DOCUMENT_TYPES = "UPDATE_DOCUMENT_TYPES",
}
