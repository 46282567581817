<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <h1 class="font-heading-xl text-primary-dark margin-top-0">Update Existing Document</h1>
        <div class="margin-left-auto">
          <button @click="cancel" class="usa-button usa-button--outline width-card">Cancel</button>
        </div>
      </div>
      <template v-if="showDocumentMatch && stagedFileToUpdate.document">
        <document-match-found-page
          :file="stagedFileToUpdate.file"
          :document="stagedFileToUpdate.document"
          @autoMatch="autoMatch"
          @manuallySelect="manuallySelect"
        />
      </template>
      <template v-else>
        <div class="grid-row flex-column font-body-xs border-top padding-top-2 margin-bottom-2">
          <p class="margin-bottom-0">Select an existing site document to update from the table below.</p>
          <p class="maxw-tablet-lg overflow-ellipsis">
            The selected document will be updated with the file:
            <b :title="stagedFileToUpdate.file.fileName">
              {{ stagedFileToUpdate.file.fileName }}
            </b>
          </p>
        </div>

        <div class="grid-row margin-bottom-2">
          <div class="grid-col">
            <applied-site-document-filters
              :searchFilters="appliedSearchFilters"
              :shownAppliedFilters="appliedSearchFilters"
              @emit-remove-selection="removeAppliedFilter"
              @clear-filters="clearAppliedFilters"
            />
          </div>
        </div>

        <div class="grid-row margin-bottom-2">
          <div class="desktop:grid-col-3 margin-bottom-2 filters">
            <table-filters
              @emit-search-filters="setSearchFilters"
              @search="searchDocuments"
              @update-applied-filters="updateAppliedFilters"
              ref="tableFilters"
            />
          </div>

          <div class="desktop:grid-col padding-left-2">
            <div class="grid-row grid-gap">
              <form class="usa-search usa-search--small search-style" role="search" @submit.prevent="search">
                <label class="usa-sr-only" for="search-field-en-small"> Search </label>
                <input
                  class="usa-input"
                  id="search-field-en-small"
                  type="search"
                  name="search"
                  v-model="searchTerm"
                  placeholder="Search Documents..."
                />
                <button class="usa-button" type="submit">
                  <span class="usa-sr-only">Search</span>
                </button>
              </form>
              <results-per-page-button class="margin-left-auto" @resultsPerPageChanged="perPageAmountChanged" />

              <div class="grid-col-auto pagination-container">
                <table-pagination
                  class="pagination pagination-top"
                  @prev="prevPage"
                  @next="nextPage"
                  :page="page"
                  :hasNextPage="hasNextPage"
                />
              </div>
            </div>

            <div class="grid-row">
              <div class="desktop:grid-col">
                <data-table
                  @sortingChanged="sortingChanged"
                  @select="showApproveDialog"
                  :details="details"
                  :actions="actions"
                  :items="documents"
                  :fields="headers"
                  :perPage="perPage"
                  :page="page"
                >
                  <template #cell(title)="data">
                    <div class="margin-y-05 table-col-md overflow-ellipsis">
                      <span :title="data.value">
                        {{ data.value }}
                      </span>
                    </div>
                  </template>
                  <template #cell(classificationMarking)="data">
                    <div class="margin-y-05 table-col-sm overflow-ellipsis" :title="data.value">
                      {{ data.value }}
                    </div>
                  </template>
                  <template #cell(documentType)="data">
                    <div class="margin-y-05 width-15 overflow-ellipsis" :title="data.value">
                      {{ data.value }}
                    </div>
                  </template>
                  <template #cell(show_details)="row">
                    <div class="mt-1">
                      <a
                        tabindex="0"
                        @click.prevent="row.toggleDetails"
                        @keypress.enter="row.toggleDetails"
                        class="usa-link expander"
                      >
                        <i
                          v-if="!row.detailsShowing"
                          class="fa fa-plus usa-tooltip"
                          data-position="top"
                          title="Click for more info"
                        ></i>
                        <i v-else class="fa fa-minus"></i>
                      </a>
                    </div>
                  </template>
                  <template #row-details="row">
                    <div class="margin-top-1">
                      <div v-for="detail in details" :key="detail.name" class="grid-row mb-2">
                        <template v-if="row.item[detail.key]">
                          <b-col sm="3" class="text-sm-right font-heading-sm padding-left-0">
                            <b>{{ detail.name }}:</b>
                          </b-col>
                          <b-col v-if="detail.type === 'date'">
                            {{ row.item[detail.key] | formatDate }}
                          </b-col>
                          <b-col v-else-if="detail.type === 'list'">
                            {{ row.item[detail.key].join("; ") }}
                          </b-col>
                          <b-col v-else>
                            {{ row.item[detail.key] }}
                          </b-col>
                        </template>
                      </div>
                    </div>
                  </template>
                </data-table>
                <table-pagination
                  class="pagination"
                  @prev="prevPage"
                  @next="nextPage"
                  :page="page"
                  :hasNextPage="hasNextPage"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <update-document-dialog
        @update="updateDocument"
        :stagedFileToUpdate="stagedFileToUpdate.file"
        :siteDocumentToUpdate="siteDocumentToUpdate"
      />
      <basic-dialog
        id="success-dialog"
        primaryButton="Continue"
        :primaryClick="cancel"
        title="Success"
        noCloseOnBackdrop
        noCloseOnEsc
        hideHeaderClose
      >
        <p>
          File <b>{{ stagedFileToUpdate.file.fileName }}</b> was successfully updated.
        </p>
      </basic-dialog>
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import DataTable from "@/components/common/DataTable.vue";
import ResultsPerPageButton from "@/components/common/ResultsPerPageButton.vue";
import TablePagination from "@/components/common/TablePagination.vue";
import ConfirmDialog from "@/components/navigation/DocumentStaging/DocumentStagingDialogs/ConfirmDialog.vue";
import DocumentMatchFoundPage from "@/components/navigation/DocumentStaging/DocumentMatchFoundPage.vue";
import { DataTableHeader, DataTableAction, DataTableDetail, BreadcrumbPathItem } from "@/dataModel/interfaces";
import store from "@/store";
import StoreNames from "@/constants/store/StoreNames";
import { StagedFileStoreActions, StagedFileStoreGetters } from "@/constants/store/stagedFile/stagedFileStoreConstants";
import { SiteDocument, StagedFileWithDocumentMatch, FilterNodeInformation } from "@/dataModel";
import { SearchResponse } from "@/dataModel/responses";
import { SearchSiteDocumentsRequest } from "@/dataModel/requests";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import UpdateDocumentDialog from "@/components/navigation/DocumentStaging/DocumentStagingDialogs/UpdateDocumentDialog.vue";
import SiteDocumentAdminHttpHelper from "@/components/resources/siteDocumentAdminHttpHelper";
import BasicDialog from "@/components/common/BasicDialog.vue";
import TableFilters from "@/components/navigation/FeaturedResourceManagement/FeaturedDocumentManagement/SiteDocumentTableFilters.vue";
import { DocumentMixin } from "@/mixins";
import AppliedSiteDocumentFilters from "@/components/navigation/FeaturedResourceManagement/FeaturedDocumentManagement/AppliedSiteDocumentFilters.vue";

@Component({
  components: {
    Breadcrumb,
    ConfirmDialog,
    DataTable,
    TableFilters,
    AppliedSiteDocumentFilters,
    TablePagination,
    ResultsPerPageButton,
    UpdateDocumentDialog,
    DocumentMatchFoundPage,
    BasicDialog,
  },
})
export default class UpdateExistingDocumentsPage extends Mixins(DocumentMixin) {
  perPage = 20;
  page = 1;
  sortOrder: string = "ascending";
  sortColumn: string = "title";
  resultCount: number = 0;
  searchTerm = "";

  categoryFilters: string[] = [];
  classificationFilters: string[] = [];
  disseminationControlFilters: string[] = [];
  documentTypeFilters: string[] = [];
  audienceFilters: string[] = [];
  sciFilters: string[] = [];
  isPublishedFilters: boolean[] = [];
  isCopyrightRestrictedFilters: boolean[] = [];
  appliedSearchFilters: FilterNodeInformation[] = [];

  stagedFileToUpdate!: StagedFileWithDocumentMatch;
  siteDocumentToUpdate: SiteDocument = new SiteDocument();
  stagedFiles: StagedFileWithDocumentMatch[] = [];
  documentSelected = false;
  documents: SiteDocument[] = [];

  private siteDocumentAdminHttpHelper = container.get<SiteDocumentAdminHttpHelper>(
    serviceTypes.SiteDocumentAdminHttpHelper,
  );

  actions: DataTableAction[] = [
    {
      name: "Select",
      icon: "",
      action: "select",
    },
  ];

  headers: DataTableHeader[] = [
    {
      label: "Title",
      key: "title",
      sortable: true,
    },
    {
      label: "Classification",
      key: "classificationMarking",
      sortable: true,
    },
    {
      label: "Type",
      key: "documentType",
      sortable: true,
      formatter: this.documentTypeFormatter,
    },
    {
      // header for actions
      label: "",
      key: "actions",
      sortable: false,
    },
    {
      // header for details
      label: "",
      key: "show_details",
      sortable: false,
    },
  ];

  showDocumentMatch: boolean = true;

  path: BreadcrumbPathItem[] = [
    {
      text: "Administration",
    },
    {
      text: "Document Staging",
      url: "/document-staging",
    },
    {
      text: "Convert to Site Documents",
      url: "/document-staging/site-documents",
    },
    {
      text: "Update Existing Document",
    },
  ];

  details: DataTableDetail[] = [
    {
      name: "Filename",
      key: "fileName",
    },
    {
      name: "Created",
      key: "createdDateTime",
      type: "date",
    },
    {
      name: "Contributors",
      key: "contributors",
      type: "list",
    },
    {
      name: "Abstract",
      key: "abstract",
    },
  ];

  async searchDocuments(): Promise<void> {
    const currentPage = this.page - 1;
    const response: SearchResponse<SiteDocument> = await this.siteDocumentAdminHttpHelper.searchSiteDocuments(
      currentPage,
      this.perPage,
      this.searchTerm,
      this.sortOrder,
      this.sortColumn,
      this.classificationFilters,
      this.disseminationControlFilters,
      this.categoryFilters,
      this.documentTypeFilters,
      this.isCopyrightRestrictedFilters,
      this.audienceFilters,
      this.sciFilters,
      this.isPublishedFilters,
    );

    this.documents = response.result;
    this.resultCount = response.count;
  }

  search(): void {
    if (this.searchTerm.trim() !== "") {
      this.sortColumn = "relevance";
      this.sortOrder = "ascending";
    }
    this.searchDocuments();
  }

  updateDocument(documentUpdated: boolean) {
    if (documentUpdated) {
      this.stagedFiles.splice(0, 1);
      store.dispatch(`${StoreNames.StagedFile}/${StagedFileStoreActions.SET_STAGED_FILES_TO_UPDATE}`, this.stagedFiles);
      this.$bvModal.show("success-dialog");
    } else {
      this.documentSelected = false;
    }
  }

  showApproveDialog(document: SiteDocument): void {
    // get knowledge resource for site document
    this.siteDocumentHttpHelper.getSiteDocument(document.id).then((response) => {
      this.siteDocumentToUpdate = response.document;
      this.documentSelected = true;
      this.$bvModal.show("update-document-dialog");
    });
  }

  cancel(): void {
    this.$router.push({ name: "ConvertToSiteDocuments" });
  }

  sortingChanged(ctx: any): void {
    this.sortColumn = ctx.sortBy;
    this.sortOrder = ctx.sortDesc ? "descending" : "ascending";
    this.searchDocuments();
  }

  updateAppliedFilters(filters: FilterNodeInformation[]): void {
    this.appliedSearchFilters = filters;
  }

  removeAppliedFilter(filter: FilterNodeInformation): void {
    (<TableFilters>this.$refs.tableFilters).removeSelection(filter);
  }

  clearAppliedFilters(): void {
    (<TableFilters>this.$refs.tableFilters).clearFilters();
  }

  setSearchFilters(searchFilters: SearchSiteDocumentsRequest): void {
    this.classificationFilters = searchFilters.classificationFilters;
    this.disseminationControlFilters = searchFilters.disseminationControlFilters;
    this.categoryFilters = searchFilters.categoryFilters;
    this.documentTypeFilters = searchFilters.documentTypeFilters;
    this.isCopyrightRestrictedFilters = searchFilters.isCopyrightRestrictedFilters;
    this.audienceFilters = searchFilters.audienceFilters;
    this.sciFilters = searchFilters.sciFilters;
    this.isPublishedFilters = searchFilters.isPublishedFilters;

    if (this.page != -1) {
      this.page = 1;
    }
  }

  prevPage() {
    if (this.page > 1) {
      this.page--;
      this.search();
    }
  }

  nextPage(): void {
    if (this.hasNextPage) {
      this.page++;
      this.search();
    }
  }

  get hasNextPage(): boolean {
    return this.page * this.perPage < this.resultCount;
  }

  perPageAmountChanged(val: number): void {
    this.perPage = val;
    this.page = 1;
    this.search();
  }

  documentTypeFormatter(documentType: string): string {
    return this.documentTypes.find((type) => type.value === documentType)?.displayName ?? "Unknown";
  }

  autoMatch() {
    this.showApproveDialog(this.stagedFileToUpdate.document!);
  }

  manuallySelect() {
    this.showDocumentMatch = false;
  }

  async created(): Promise<void> {
    this.stagedFiles = store.getters[`${StoreNames.StagedFile}/${StagedFileStoreGetters.GET_STAGED_FILES_TO_UPDATE}`];
    this.stagedFileToUpdate = this.stagedFiles[0];
    await this.searchDocuments();
  }

  beforeDestroy(): void {
    // clear state when navigating away (unless a file is updated)
    if (!this.documentSelected) {
      store.dispatch(`${StoreNames.StagedFile}/${StagedFileStoreActions.SET_STAGED_FILES_TO_UPDATE}`, []);
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

.pagination {
  float: right;
}

.pagination-container {
  margin-top: 0.5rem;
}

.search-style {
  width: 50%;
  margin-top: 4px;
}

.hide {
  display: none;
}

.align-right {
  margin-left: auto;
}

.filters {
  margin-top: 4px;
}
</style>
