<template>
  <div class="path-container">
    <p class="usa-prose bold align-element mr-2">Category Path:</p>
    <nav class="usa-breadcrumb align-element show-outline" aria-label="Breadcrumbs">
      <ol class="usa-breadcrumb__list pl-2">
        <li
          v-for="(step, index) in path"
          :key="step.displayName"
          :class="`usa-breadcrumb__list-item ${isLastItem(index) ? 'usa-current' : ''}`"
          :aria-current="`${isLastItem(index) ? 'page' : ''}`"
        >
          <template v-if="isLastItem(index) || step.id == null">
            <span :class="{ 'text-italic': categoryService.isItalicFormat(step) }">{{ step.displayName }}</span>
          </template>
          <template v-else>
            <button
              class="usa-button usa-button--unstyled"
              :class="{ 'text-italic': categoryService.isItalicFormat(step) }"
              @click="selectedCategoryId(step.id)"
            >
              <span>{{ step.displayName }}</span>
            </button>
          </template>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script lang="ts">
import { CategoryPathItem } from "@/dataModel/interfaces";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import CategoryService from "@/services/interfaces/ICategoryService";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class CategoryPath extends Vue {
  @Prop({ required: true }) path!: CategoryPathItem[];

  private categoryService = container.get<CategoryService>(serviceTypes.CategoryService);

  @Emit()
  selectedCategoryId(id: string): string {
    return id;
  }

  isLastItem(index: number): boolean {
    return index === this.path.length - 1;
  }
}
</script>

<style scoped lang="scss">
.path-container {
  margin: 1rem 0;
}

.align-element {
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.bold {
  font-weight: bold;
}
</style>
