import HttpHelper from "@/components/resources/httpHelper";
import { inject, injectable } from "inversify";
import serviceTypes from "@/dependencyInjection/types";
import { Category, FilterNodeInformation } from "@/dataModel";
import { CreateCategoryRequest, UpdateCategoryRequest } from "@/dataModel/requests";
import { CategoryUsedByDocumentsResponse } from "@/dataModel/responses";

const categoryUrl = "admin/documents/category";

@injectable()
export default class CategoryHttpHelper {
  httpHelper: HttpHelper;

  constructor(@inject(serviceTypes.HttpHelper) httpHelper: HttpHelper) {
    this.httpHelper = httpHelper;
  }

  async getAllCategories(): Promise<Category[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(categoryUrl)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error("Response returned " + response.status + " : " + response.statusText + " getting categories"),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting categories"));
        });
    });
  }

  async getAllCategoriesTree(): Promise<FilterNodeInformation[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet("admin/documents/category/tree")
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error("Response returned " + response.status + " : " + response.statusText + " getting categories"),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting categories"));
        });
    });
  }

  async getChildCategories(id: string): Promise<Category[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(categoryUrl + "/children/" + id)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " getting child categories",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting child categories"));
        });
    });
  }

  async addCategory(request: CreateCategoryRequest): Promise<Category> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(categoryUrl, request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " while adding new category",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while adding new category"));
        });
    });
  }

  async updateCategory(request: UpdateCategoryRequest): Promise<Category> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPut(categoryUrl, request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " while updating category",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while updating category"));
        });
    });
  }

  async deleteCategory(id: string): Promise<Category> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpDelete(categoryUrl + "/" + id)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " while deleting category",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while deleting category"));
        });
    });
  }

  async isCategoryUsedByDocuments(id: string): Promise<CategoryUsedByDocumentsResponse> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(categoryUrl + "/used-by-documents/" + id)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " +
                  response.status +
                  " : " +
                  response.statusText +
                  " while retrieving if " +
                  "the category is associated with any documents",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while retrieving if the category is associated with any documents"));
        });
    });
  }

  async downloadCategoriesAsync(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet("admin/documents/category/download")
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " +
                  response.status +
                  " : " +
                  response.statusText +
                  " downloading categories csv file",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while downloading categories csv file"));
        });
    });
  }
}
