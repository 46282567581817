import HttpHelper from '@/components/resources/httpHelper'
import { inject, injectable } from 'inversify';
import serviceTypes from '@/dependencyInjection/types';
import EvaluationRating from '@/dataModel/hazardData/evaluationRating';

const hazardDataEvaluationRatingUrl = "hazard-data/evaluation-ratings/";

@injectable()
export default class HazardDataEvaluationRatingHttpHelper {
    httpHelper: HttpHelper;

    constructor(@inject(serviceTypes.HttpHelper) httpHelper: HttpHelper) {
        this.httpHelper = httpHelper;
    }

    async getEvaluationRatings(): Promise<EvaluationRating[]> {
        return new Promise((resolve, reject) => {
            this.httpHelper.httpGet(hazardDataEvaluationRatingUrl).then((response: any ) => {
                if(response.status === 200){
                    resolve(response.data);
                } else {
                    reject(new Error("Response returned " + response.status + " : " + response.statusText + " getting " +
                        "hazard data evaluation ratings"));
                }
            }).catch(error => {
                console.error(error);
                reject(new Error("An error occurred while getting hazard data evaluation ratings"));
            });
        });
    }
}