import HttpHelper from "@/components/resources/httpHelper";
import { inject, injectable } from "inversify";
import serviceTypes from "@/dependencyInjection/types";
import { Subscription } from "@/dataModel";
import FrequencyType from "@/constants/FrequencyType";
import SubscriptionType from "@/constants/SubscriptionType";
import { FilterType } from "@/constants/FilterType";

const notificationUrl = "notifications/";

@injectable()
export default class NotificationHttpHelper {
  httpHelper: HttpHelper;

  constructor(@inject(serviceTypes.HttpHelper) httpHelper: HttpHelper) {
    this.httpHelper = httpHelper;
  }

  async getSubscriptionsByUserId(userId: string): Promise<SubscriptionState[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(notificationUrl + "subscriptions/" + userId)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " +
                  response.status +
                  " : " +
                  response.statusText +
                  " getting subscriptions for user",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting subscriptions by user id " + userId));
        });
    });
  }

  async getNotificationSettingsForUser(userId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(notificationUrl + "settings/" + userId)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " +
                  response.status +
                  " : " +
                  response.statusText +
                  " getting notification settings for user",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting notification settings for user id " + userId));
        });
    });
  }

  async updateNotificationSetting(userId: string, requestedFrequency: FrequencyType): Promise<any> {
    const data = {
      UserId: userId,
      RequestedFrequency: requestedFrequency,
    };

    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(notificationUrl + "settings", data)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " +
                  response.status +
                  " : " +
                  response.statusText +
                  " updating notification settings for user",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while updating notification settings for user"));
        });
    });
  }

  async deleteSubscription(subscriptionId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpDelete(notificationUrl + "subscriptions/" + subscriptionId)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " +
                  response.status +
                  " : " +
                  response.statusText +
                  " unsubscribing from subscription",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(
            new Error("An error occurred while unsubscribing from subscription for subscription " + subscriptionId),
          );
        });
    });
  }

  async createSubscription(request: CreateSubscriptionRequest): Promise<SubscriptionState> {
    const data = {
      Id: request.id,
      UserId: request.userId,
      SubscriptionType: request.subscriptionType,
      KnowledgeResourceId: request.knowledgeResourceId,
      SearchTerms: request.searchTerms,
      SearchFilters: request.searchFilters,
      SearchResults: request.searchResults,
    };

    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPut(notificationUrl + "subscriptions", data)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " creating notification",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while creating notification"));
        });
    });
  }

  async getSubscriptionForUserBySearchQuery(
    userId: string,
    searchTerms: string,
    searchFilters: Filter[],
  ): Promise<SubscriptionState> {
    const data = {
      UserId: userId,
      SearchTerms: searchTerms,
      SearchFilters: searchFilters,
    };

    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(notificationUrl + "subscriptions/query", data)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " +
                  response.status +
                  " : " +
                  response.statusText +
                  " getting subscription by search query",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting subscription by search query"));
        });
    });
  }

  async getSubscriptionForUserByKnowledgeResourceId(
    userId: string,
    knowledgeResourceId: string,
  ): Promise<Subscription> {
    const url = `${notificationUrl}subscriptions/knowledge-resource/${knowledgeResourceId}/user/${userId}`;

    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(url)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " +
                  response.status +
                  " : " +
                  response.statusText +
                  " getting subscription by knowledge resource id",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(
            new Error(
              `An error occurred while getting subscription with knowledge resource id '${knowledgeResourceId}' for user ${userId}`,
            ),
          );
        });
    });
  }
}

export class SubscriptionState {
  subscription!: Subscription;
  searchResults?: NotificationSearchResults[];
}

export class NotificationSearchResults {
  id!: string;
  notificationSubscriptionId!: string;
  searchResults!: string[];
  createdDateTime!: Date;
}

export class CreateSubscriptionRequest {
  id!: string;
  userId!: string;
  subscriptionType!: SubscriptionType;
  knowledgeResourceId?: string;
  searchTerms?: string;
  searchFilters?: Filter[];
  searchResults?: string[];
}

export class Filter {
  FilterType!: FilterType;
  Value!: string;
}
