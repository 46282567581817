<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <div class="desktop:grid-col-11">
          <h1 class="font-heading-xl text-primary-dark margin-top-0 margin-bottom-4">Request Management</h1>
        </div>
        <div class="desktop:grid-col-1 margin-left-auto requestButtonDiv">
          <button class="usa-button usa-button--outline" @click="returnToRequests" id="back-to-requests-button">
            Back
          </button>
        </div>
      </div>
      <hr class="line-break" />

      <div class="request-container">
        <div class="grid-row grip-gap">
          <span class="sub-header margin-right-5">{{ request.title }}</span>
          <button class="usa-button update-button" @click="updateStatusHandler">Update Status</button>
        </div>

        <div class="usa-accordion usa-accordion--bordered margin-top-3">
          <h2 class="usa-accordion__heading">
            <button class="usa-accordion__button" aria-expanded="true" aria-controls="b-a1">Request Details</button>
          </h2>
          <div id="b-a1" class="usa-accordion__content usa-prose">
            <p class="usa-prose detail-title">Title:</p>
            <p class="usa-prose detail">{{ request.title }}</p>
            <div class="grid-row request-type-container" style="">
              <p class="usa-prose detail-title margin-bottom-0">Type of Request:</p>
            </div>
            <p class="usa-prose detail">{{ request.requestType }}</p>
            <p class="usa-prose detail-title">Organization Requesting Information:</p>
            <p class="usa-prose detail">{{ request.organization }}</p>
            <p class="usa-prose detail-title">Reason for Request:</p>
            <p class="usa-prose detail">{{ request.reason }}</p>
            <div v-if="request.dateNeededBy">
              <p class="usa-prose detail-title margin-bottom-0">Information Requested for:</p>
              <p class="usa-prose detail">{{ request.dateNeededBy | formatDate }}</p>
            </div>
            <div v-if="request.requestType == RequestType.Share && fileMetadata && fileMetadata.length > 0">
              <p class="usa-prose detail-title margin-bottom-0">Associated Files:</p>

              <div v-for="(file, index) in fileMetadata" :key="index" class="margin-bottom-05">
                <button v-if="canDownloadFiles" class="usa-button usa-button--unstyled" @click="viewFile(file)">
                  {{ file.fileName }}
                </button>
                <span v-else>
                  {{ file.fileName }}
                </span>
              </div>
              <button
                v-if="canDownloadFiles && fileMetadata.length > 1"
                class="usa-button usa-button--unstyled mt-4"
                @click="downloadZipFile()"
              >
                Download All as Zip File<i class="fa fa-download margin-left-1" aria-hidden="true" />
              </button>
              <p class="usa-prose detail-title margin-bottom-0" id="classificationMarking">Classification Marking:</p>
              <p class="usa-prose detail">{{ ClassificationMarking }}</p>
            </div>
          </div>
        </div>

        <div class="grid-container padding-0 margin-top-6">
          <span class="sub-header margin-bottom-2">Request Log</span>
          <hr class="line-break" />
          <form class="usa-form maxw-full padding-bottom-1 margin-bottom-3" @submit.prevent="addCommentHandler">
            <label class="usa-label" for="comment"> Comment </label>
            <textarea
              class="usa-textarea height-15"
              id="comment"
              name="comment"
              placeholder="Enter a comment to be appended to the request log"
              v-model.trim="comment"
              aria-describedby="request-error-message"
            ></textarea>
            <span
              class="usa-error-message"
              id="request-error-message"
              v-if="showValidation && isEmpty(comment)"
              role="alert"
            >
              Comment cannot be empty
            </span>
            <button class="usa-button update-button">Add Comment</button>
          </form>
          <div
            v-for="(entry, index) of log"
            :key="index"
            :class="`grid-row border-top padding-y-1 font-body-xs ${index === log.length - 1 ? 'border-bottom' : ''}`"
          >
            <div class="grid-col-3 text-bold margin-left-2">
              {{ addSpaces(entry.logAction) }}
            </div>
            <div class="grid-col-3">
              <i class="fa fa-clock text-base"></i>
              {{ entry.logDateTime | formatDate("MM-DD-YYYY HH:mm:ss") }}
            </div>
            <div class="grid-col hide-overflow margin-right-2">{{ entry.displayName }}</div>
            <div v-if="canDeleteComment(entry)" class="grid-col-auto">
              <button
                class="usa-button usa-button--unstyled delete-button margin-right-2"
                @click="deleteCommentHandler(entry)"
              >
                Delete
              </button>
            </div>
            <div class="grid-col-12 note padding-top-1 margin-left-2" v-if="entry.comment">
              {{ entry.comment }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <update-request-dialog :request="request" @confirm="updateStatus" ref="updateRequestDialog" />
    <delete-comment-dialog @confirm="deleteComment" />
    <download-file-dialog @cancelDownload="cancelDownload" />

    <success-toast id="comment-added-toast" message="Comment successfully added." />
  </main>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Request, RequestEventLog, RequestFileMetadata, Actor } from "@/dataModel";
import RequestLogAction from "@/constants/RequestLogAction";
import store from "@/store";
import { RequestStoreActions, RequestStoreGetters } from "@/constants/store/request/requestStoreConstants";
import { AuthStoreGetters } from "@/constants/store/auth/authStoreConstants";
import StoreNames from "@/constants/store/StoreNames";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import { BreadcrumbPathItem } from "@/dataModel/interfaces";
import UpdateRequestDialog from "@/components/navigation/RequestManagement/UpdateRequestDialog.vue";
import DeleteCommentDialog from "@/components/navigation/RequestManagement/DeleteCommentDialog.vue";
import DownloadFileDialog from "@/components/navigation/RequestManagement/DownloadFileDialog.vue";
import RequestHttpHelper from "@/components/resources/requestHttpHelper";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import RequestStatus from "@/constants/RequestStatus";
import RequestType from "@/constants/RequestType";
import axios, { CancelTokenSource } from "axios";

@Component({
  components: {
    Breadcrumb,
    UpdateRequestDialog,
    DeleteCommentDialog,
    DownloadFileDialog,
  },
})
export default class IndividualRequest extends Vue {
  request!: Request;
  comment: string = "";
  showValidation: boolean = false;
  userId: string = "";
  actor: Actor | null = null;
  deleteLog!: RequestEventLog;
  log: RequestEventLog[] = [];
  fileMetadata: RequestFileMetadata[] = [];
  RequestType = RequestType;
  cancelToken?: CancelTokenSource;

  private requestHttpHelper = container.get<RequestHttpHelper>(serviceTypes.RequestHttpHelper);

  path: BreadcrumbPathItem[] = [
    {
      text: "Administration",
    },
    {
      text: "Request Management",
      url: "/request-management",
    },
    {
      text: "Request",
    },
  ];

  get ClassificationMarking(): string {
    return this.fileMetadata[0].classificationMarking ?? "";
  }

  get canDownloadFiles(): boolean {
    if (!this.actor) {
      return false;
    }

    const kr = this.fileMetadata[0]?.knowledgeResource;
    // Get knowldege resource permission ids
    const krPermissions: string[] = kr.disseminationControls.map((c) => c.id).concat(kr.scis.map((s) => s.id));

    // Get actor permission ids
    const actorPermissions = this.actor.getKnowledgeResourceAccessPermissionIds();

    // Compare actor and kr clearance levels
    const actorHasClearance = this.actor.clearanceLevel.value >= kr.classification.value;

    return actorHasClearance && krPermissions.every((permission) => actorPermissions.includes(permission));
  }

  async getLogsForRequest(): Promise<void> {
    const response = await this.requestHttpHelper.getRequest(this.request.id);
    this.log = response.events.reverse();
  }

  addCommentHandler(): void {
    if (this.comment.length > 0) {
      this.addComment(this.comment);
      this.showValidation = false;
      this.comment = "";
    } else {
      this.showValidation = true;
    }
  }

  async addComment(text: string): Promise<void> {
    await this.requestHttpHelper.addCommentToRequestLog(this.request.id, text);
    this.$bvToast.show("comment-added-toast");
    await this.getLogsForRequest();
  }

  deleteCommentHandler(eventLog: RequestEventLog): void {
    this.$bvModal.show("delete-comment-dialog");
    this.deleteLog = eventLog;
  }

  async getFileMetaData(): Promise<void> {
    const response: RequestFileMetadata[] = await this.requestHttpHelper.getAllRequestFileMetadata(this.request.id);
    this.fileMetadata = response;
  }

  async viewFile(fileMetadata: RequestFileMetadata): Promise<void> {
    if (!this.canDownloadFiles) {
      return;
    }
    store.dispatch(`${StoreNames.Request}/${RequestStoreActions.SET_REQUEST_FILE_METADATA}`, fileMetadata);
    this.$router.push({ name: "RequestFileView", params: { id: fileMetadata.fileId } });
  }

  async downloadZipFile(): Promise<void> {
    if (!this.canDownloadFiles) {
      return;
    }
    this.cancelToken = axios.CancelToken.source();
    this.$bvModal.show("download-file-dialog");
    const response: string | void = await this.requestHttpHelper
      .downloadZipFile(this.request.id, this.cancelToken)
      .catch((error) => {
        if (!axios.isCancel(error)) {
        }
      });
    if (response) {
      this.downloadHelper(response, "request_files.zip", "application/zip");
    }
    this.$bvModal.hide("download-file-dialog");
  }

  async deleteComment(): Promise<void> {
    await this.requestHttpHelper.deleteCommentFromRequestLog(this.request.id, this.deleteLog.id);
    await this.getLogsForRequest();
    this.$bvToast.show("comment-deleted-toast");
  }

  async updateStatus(newStatus: RequestStatus): Promise<void> {
    const response: any = await this.requestHttpHelper.updateRequestStatus(this.request.id, newStatus);
    this.$bvToast.show("request-updated-toast");
    this.request = response;
    await this.getLogsForRequest();
  }

  downloadHelper(byteArray: string, fileName: string, mimeType: string): void {
    const link = document.createElement("a");
    link.download = fileName;
    link.href = window.URL.createObjectURL(new Blob([byteArray], { type: mimeType }));
    link.click();
  }

  cancelDownload(): void {
    this.cancelToken?.cancel();
  }

  updateStatusHandler(): void {
    (<UpdateRequestDialog>this.$refs.updateRequestDialog).initialize(this.request.requestStatus);
    this.$bvModal.show("update-status-dialog");
  }

  isEmpty(str: string): boolean {
    return str.length === 0;
  }

  canDeleteComment(entry: any): boolean {
    const belongsToUser = this.actor?.id == entry.actorId;

    return entry.logAction === RequestLogAction.CommentAdded && belongsToUser;
  }

  addSpaces(str: string): string {
    return str.replace(/([A-Z])/g, " $1");
  }

  returnToRequests(): void {
    this.$router.push({ name: "RequestManagement" });
  }

  created(): void {
    this.request = store.getters[`${StoreNames.Request}/${RequestStoreGetters.GET_SELECTED_REQUEST}`];
    const user = store.getters[`${StoreNames.Auth}/${AuthStoreGetters.GET_USER}`];

    this.userId = user.userId;
    this.actor = Object.assign(new Actor(), user.actor);

    this.getLogsForRequest();

    if (this.request.requestType == RequestType.Share) {
      this.getFileMetaData();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

#classificationMarking {
  margin-top: 15px;
}

.line-break {
  margin: 0;
  background: #c9c9c9;
}

.sub-header {
  display: block;
  font-family: family("heading");
  font-size: size("heading", "md");
  line-height: lh("heading", 2);
  font-weight: font-weight("bold");
  color: color("primary-dark");
  margin-top: units(4);
}

.update-button {
  margin-top: 1.3rem;
}

.detail-title {
  font-weight: font-weight("bold");
}

.detail {
  margin-top: units(0) !important;
}

.request-container {
  width: 75%;
}

.request-type-container button {
  height: fit-content;
  padding-top: 0.3rem;
  margin-left: units(2);
}

.hide-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
