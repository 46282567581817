<template>
  <basic-dialog
    v-bind="$attrs"
    size="lg"
    title="Featured Resource Settings"
    :primaryButton="primaryButtonText"
    secondaryButton="Cancel"
    :primaryClick="confirmFormIsValid"
    :secondaryClick="cancel"
    :isDisabled="isSelectionAlreadyFeatured"
  >
    <p>{{ subheaderText }}</p>
    <p class="text-bold mb-4">{{ featuredResourceTitle }}</p>
    <b-table
      :fields="headers"
      :items="[]"
      thead-class="bg-primary-darker"
      :thead-tr-class="['text-white', 'font-heading-sm']"
      id="data-table"
      no-sort-reset
    >
      <template slot="top-row">
        <td key="featuredLength">
          <!-- Component docs: https://bootstrap-vue.org/docs/components/form-select#form-select -->
          <b-form-select
            class="usa-select"
            v-model="featuredLengthType"
            :options="featuredLengthOptions"
            @change="featuredLengthTypeChanged"
          />
        </td>
        <td key="startDate" class="date-picker-container">
          <b-input-group class="flex-align-end">
            <div class="date-display-container center-vertical">
              <p class="usa-prose pl-2 mb-0">{{ formatStartDate }}</p>
            </div>
            <b-input-group-append>
              <b-form-datepicker
                class="date-picker date-picker-button"
                v-model="startDate"
                right
                locale="en-US"
                button-only
                aria-controls="start-date-input"
                :hide-header="true"
                label-help=""
                @input="startDateChangedByDatepicker"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </td>
        <td key="endDate" class="date-picker-container">
          <b-input-group class="flex-align-end">
            <div
              class="date-display-container center-vertical"
              :style="{ background: featuredLengthType != FeaturedLengthType.Custom ? '#f0f0f0' : 'none' }"
            >
              <p class="usa-prose pl-2 mb-0">{{ formatEndDate }}</p>
            </div>
            <b-input-group-append>
              <b-form-datepicker
                class="date-picker date-picker-button"
                v-model="endDate"
                right
                locale="en-US"
                button-only
                aria-controls="end-date-input"
                :hide-header="true"
                label-help=""
                :min="minEndDate"
                :disabled="featuredLengthType != FeaturedLengthType.Custom"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </td>
        <td key="status" class="center-vertical">
          <div class="status-container">
            {{ featuredResourceStatus }}
          </div>
        </td>
      </template>
    </b-table>
    <span
      class="usa-error-message"
      id="empty-display-name-error-message"
      v-show="hasTriedToSubmit && !isEndDateValid()"
    >
      The end date cannot be empty when using a custom length.
    </span>
    <span class="usa-error-message" id="empty-display-name-error-message" v-show="isSelectionAlreadyFeatured">
      The selected resource is already featured.
    </span>
  </basic-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import { SiteDocument, FeaturedDocument, FeaturedHazardData } from "@/dataModel";
import { NewFeaturedDocumentRequest, NewFeaturedHazardDataRequest } from "@/dataModel/requests";
import FeaturedLengthType from "@/constants/featuredLengthType";
import { DataTableHeader } from "@/dataModel/interfaces/";
import moment from "moment";
import { FeaturedResource } from "@/dataModel/interfaces";
import FeaturedHazardType from "@/constants/FeaturedHazardType";

@Component({
  components: {
    BasicDialog,
  },
})
export default class FeaturedResourceDialog extends Vue {
  FeaturedLengthType = FeaturedLengthType;
  featuredLengthType: FeaturedLengthType = FeaturedLengthType.NoEndDate;
  datepickerFormat = "YYYY-MM-DD";
  startDate: string = moment(new Date()).utc().format(this.datepickerFormat);
  endDate: string = "";
  customFeaturedLength: number | undefined = 0;
  siteDocumentId: string = "";
  hasTriedToSubmit: boolean = false;
  primaryButtonText: string = "";
  featuredResourceTitle: string = "";
  titleText: string = "";
  subheaderText: string = "";

  @Prop() selectedDocument!: SiteDocument;
  @Prop() selectedFeaturedResource!: FeaturedResource;
  @Prop() isAdding!: boolean;
  @Prop() featuredResourceType!: FeaturedHazardType;
  @Prop({ default: false }) isSelectionAlreadyFeatured!: boolean;

  headers: DataTableHeader[] = [
    {
      label: "Length",
      key: "featuredLength",
      sortable: false,
    },
    {
      label: "Start Date",
      key: "startDate",
      sortable: false,
    },
    {
      label: "End Date",
      key: "endDate",
      sortable: false,
    },
    {
      label: "Status",
      key: "status",
      sortable: false,
    },
  ];

  mounted(): void {
    this.intializeDialogOnOpen();
  }

  intializeDialogOnOpen(): void {
    this.$root.$on("bv::modal::show", () => {
      if (this.isAdding) {
        this.initializeDialogForAdd();
      } else {
        this.initializeDialogForEdit();
      }
    });
  }

  confirmFormIsValid(): void {
    if (!this.isValidForm()) {
      this.displayErrors();
      return;
    }

    const displayIndex = 0;
    const start = new Date(this.startDate);
    const end = this.endDate == "" ? null : new Date(this.endDate);

    switch (this.featuredResourceType) {
      case FeaturedHazardType.Document:
        const docRequest = new NewFeaturedDocumentRequest(
          this.siteDocumentId,
          displayIndex,
          start,
          end,
          this.featuredLengthType,
          this.customFeaturedLength,
        );
        this.documentConfirmed(docRequest);
        break;

      case FeaturedHazardType.HazardData:
        const newFeaturedData = this.selectedFeaturedResource as FeaturedHazardData;
        const dataRequest = new NewFeaturedHazardDataRequest(
          newFeaturedData.featuredHazardDataId,
          newFeaturedData.knowledgeResourceId,
          newFeaturedData.pooledFeaturedDataViewId,
          displayIndex,
          start,
          end,
          this.featuredLengthType,
          newFeaturedData.featuredHazardType,
          this.customFeaturedLength,
        );
        this.dataConfirmed(dataRequest);
        break;
    }
  }

  @Emit()
  documentConfirmed(request: NewFeaturedDocumentRequest): NewFeaturedDocumentRequest {
    this.hideDialog();
    return request;
  }

  @Emit()
  dataConfirmed(request: NewFeaturedHazardDataRequest): NewFeaturedHazardDataRequest {
    this.hideDialog();
    return request;
  }

  isValidForm(): boolean {
    return this.startDate.length > 0 && this.isEndDateValid() && !this.isSelectionAlreadyFeatured;
  }

  displayErrors(): void {
    this.hasTriedToSubmit = true;
  }

  isEndDateValid(): boolean {
    return this.featuredLengthType == FeaturedLengthType.NoEndDate || this.endDate != "";
  }

  cancel(): void {
    this.hideDialog();
  }

  featuredLengthTypeChanged(): void {
    let start = new Date(this.startDate);
    this.endDate = this.getNewEndDateOnInputChange(start);
  }

  initializeDialogForAdd(): void {
    this.clearInputFields();
    this.hasTriedToSubmit = false;
    this.primaryButtonText = "Add";
    this.subheaderText = `New featured ${this.getFeaturedResourceTypeLabel()} publishing details.`;
  }

  initializeDialogForEdit(): void {
    this.setInputFieldsForEdit();
    this.hasTriedToSubmit = false;
    this.primaryButtonText = "Save";
    this.subheaderText = `Edit featured ${this.getFeaturedResourceTypeLabel()} publishing details.`;
  }

  clearInputFields(): void {
    this.featuredLengthType = FeaturedLengthType.NoEndDate;
    this.startDate = this.$options.filters!.formatDate(new Date(), undefined, true);
    this.endDate = "";
    this.customFeaturedLength = 0;
  }

  setInputFieldsForEdit(): void {
    if (!this.selectedFeaturedResource.title) {
      return;
    }
    this.featuredLengthType = this.selectedFeaturedResource.featuredLengthType;
    this.startDate = moment(this.selectedFeaturedResource.startDate).utc().format(this.datepickerFormat);
    this.endDate =
      this.selectedFeaturedResource.endDate != null
        ? moment(this.selectedFeaturedResource.endDate).utc().format(this.datepickerFormat)
        : "";
    this.customFeaturedLength = this.selectedFeaturedResource.customFeaturedLength;
  }

  hideDialog(): void {
    this.$bvModal.hide(this.$attrs.id);
  }

  startDateChangedByDatepicker(): void {
    let start = new Date(this.startDate);
    if (this.featuredLengthType == FeaturedLengthType.Custom && start > new Date(this.endDate)) {
      this.endDate = this.startDate;
      return;
    }
    this.endDate = this.getNewEndDateOnInputChange(start);
  }

  getNewEndDateOnInputChange(start: Date): string {
    switch (this.featuredLengthType) {
      case FeaturedLengthType.OneMonth:
        return moment(new Date(start.getUTCFullYear(), start.getUTCMonth() + 1, start.getUTCDate() - 1))
          .utc()
          .format(this.datepickerFormat);
      case FeaturedLengthType.ThreeMonths:
        return moment(new Date(start.getUTCFullYear(), start.getUTCMonth() + 3, start.getUTCDate() - 1))
          .utc()
          .format(this.datepickerFormat);
      case FeaturedLengthType.SixMonths:
        return moment(new Date(start.getUTCFullYear(), start.getUTCMonth() + 6, start.getUTCDate() - 1))
          .utc()
          .format(this.datepickerFormat);
      default:
        return "";
    }
  }

  getFeaturedResourceTypeLabel(): string {
    if (
      this.selectedFeaturedResource.featuredHazardType === FeaturedHazardType.Document ||
      this.featuredResourceType === FeaturedHazardType.Document
    ) {
      return "document";
    } else if (this.selectedFeaturedResource.featuredHazardType === FeaturedHazardType.HazardData) {
      return "hazard data";
    } else {
      return "Unknown Type";
    }
  }

  //#region  Watchers

  @Watch("selectedDocument")
  documentSelectionChanged(doc: SiteDocument) {
    this.featuredResourceTitle = `${doc.titleClassificationMarking} ${doc.title}`;
    this.siteDocumentId = doc.id;
  }

  @Watch("selectedFeaturedResource")
  featuredResourceSelectionChanged(selectedResource: FeaturedResource) {
    this.featuredResourceTitle = selectedResource.title;
    this.siteDocumentId = (this.selectedFeaturedResource as FeaturedDocument)?.siteDocumentId;
  }

  //#endregion

  //#region  Computed properties

  get formatStartDate(): string {
    return this.$options.filters!.formatDate(this.startDate, undefined, true);
  }

  get formatEndDate(): string {
    if (this.endDate == "") {
      return "None";
    }
    return this.$options.filters!.formatDate(this.endDate, undefined, true);
  }

  get minEndDate(): string {
    return moment(new Date(this.startDate)).utc().format(this.datepickerFormat);
  }

  get featuredLengthOptions(): Object[] {
    const addSpaces = (str: string) => str.replace(/([a-z])([A-Z])/g, "$1 $2");
    const options: any = [];
    for (let length in FeaturedLengthType) {
      options.push({ value: length, text: addSpaces(length) });
    }

    return options;
  }

  get featuredResourceStatus(): string {
    let currentDate: Date = new Date(Date.now());
    let start: Date = new Date(this.startDate);
    let end: Date = new Date(this.endDate);
    if (this.startDate != null && this.endDate != null) {
      if (start > currentDate) {
        return "Upcoming";
      }
      if (start <= currentDate && (end >= currentDate || this.endDate == "")) {
        return "Now Featured";
      }
      if (start <= currentDate && end < currentDate) {
        return "Past";
      }
    }
    return "--";
  }

  //#endregion
}
</script>

<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

.center-vertical {
  vertical-align: middle !important;
}

.date-picker-container {
  width: 13rem;
}

.status-container {
  width: 6rem;
}

.date-picker {
  margin-top: 8px;
  height: 40px;
  & :deep(*) {
    border-radius: 0 !important;
  }
}

.date-picker-input {
  border: 1px solid #565c65;
}

.date-display-container {
  width: 8rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  border: 1px solid color("base-dark");
}
</style>
