export enum DataValueIdentifierType {
  Value = "Value",
  Source = "Source",
  HazardAgent = "HazardAgent",
  EvaluationRating = "EvaluationRating",
  UncertaintyMeasure = "UncertaintyMeasure",
  UncertaintyValue = "UncertaintyValue",
  Category = "Category",
  Detail = "Detail",
  Property = "Property",
}
