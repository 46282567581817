import HttpHelper from "@/components/resources/httpHelper";
import FileStatus from "@/constants/FileStatus";
import { StagedFile, StagedFileEventLog, ReviewerInformation } from "@/dataModel";
import { inject, injectable } from "inversify";
import serviceTypes from "@/dependencyInjection/types";
import { SearchResponse } from "@/dataModel/responses";
import { BulkUpdateFileStatusRequest, CreateDownloadRequest, UploadStagedFileRequest } from "@/dataModel/requests";
import { AxiosResponse, CancelToken, CancelTokenSource } from "axios";

const stagedFileUrl = "admin/documents/";

@injectable()
export default class DocumentStagingHttpHelper {
  httpHelper: HttpHelper;

  constructor(@inject(serviceTypes.HttpHelper) httpHelper: HttpHelper) {
    this.httpHelper = httpHelper;
  }

  async getStagedFile(id: string): Promise<StagedFile> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(stagedFileUrl + id)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error("Response returned " + response.status + " : " + response.statusText + " getting staged file."),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting staged file by id: " + id));
        });
    });
  }

  async getStagedFileEventLog(id: string): Promise<StagedFileEventLog[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(stagedFileUrl + "log/" + id)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " +
                  response.status +
                  " : " +
                  response.statusText +
                  " getting staged file event log.",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting staged file event log for staged file by id: " + id));
        });
    });
  }

  async uploadFile(request: UploadStagedFileRequest, cancelToken?: CancelTokenSource): Promise<StagedFile> {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("file", request.file);
      formData.append("classificationId", request.classification);
      formData.append("requestId", request.requestId);

      if (request.disseminationControls !== undefined) {
        for (let i = 0; i < request.disseminationControls.length; i++) {
          formData.append("DisseminationControlIds[" + i.toString() + "]", request.disseminationControls[i]);
        }
      }

      if (request.scis !== undefined) {
        for (let i = 0; i < request.scis.length; i++) {
          formData.append("SciIds[" + i.toString() + "]", request.scis[i]);
        }
      }

      if (request.note !== undefined && request.note !== null) {
        formData.append("note", request.note);
      }

      if (request.reviewerUserId !== undefined && request.reviewerUserId !== null) {
        formData.append("reviewerUserId", request.reviewerUserId);
      }

      if (request.batchId) {
        formData.append("batchId", request.batchId);
      }

      let headers = { "Content-Type": "multipart/form-data" };

      this.httpHelper
        .httpPost(stagedFileUrl, formData, headers, cancelToken)
        .then((response: any) => {
          if (response.status === 200 || response.status === 201) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " adding uploaded file.",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  async updateStagedFileEventLog(eventLog: StagedFileEventLog): Promise<StagedFileEventLog> {
    const data = {
      StagedFileId: eventLog.stagedFileId,
      Note: eventLog.note,
    };

    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(stagedFileUrl + "log/", data)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " +
                  response.status +
                  " : " +
                  response.statusText +
                  " updating staged file event log.",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while updating staged file event"));
        });
    });
  }

  async changeFileStatus(file: StagedFile, note: string): Promise<StagedFile> {
    const data = {
      StagedFileId: file.id,
      NewFileStatus: file.fileStatus,
      Note: note,
    };

    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPut(stagedFileUrl, data)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " updating file status.",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while updating file status."));
        });
    });
  }

  async deleteStagedFile(id: string): Promise<unknown> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpDelete(stagedFileUrl + id)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " deleting staged file.",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while deleting staged file " + id));
        });
    });
  }

  async bulkChangeFileStatus(stagedFileIds: string[], newStatus: FileStatus, note: string): Promise<StagedFile[]> {
    const request: BulkUpdateFileStatusRequest = {
      stagedFileIds: stagedFileIds,
      newFileStatus: newStatus,
      note: note,
    };

    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPut(stagedFileUrl + "bulk-change-status/", request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " bulk updating file statuses.",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while bulk updating file statuses."));
        });
    });
  }

  async searchStagedFiles(
    page: number,
    resultsPerPage: number,
    searchTerm: string,
    sortOrder: string,
    sortColumn: string,
    filters: string[],
  ): Promise<SearchResponse<StagedFile>> {
    const data = {
      Page: page,
      ResultsPerPage: resultsPerPage,
      SearchTerm: searchTerm,
      SortOrder: sortOrder,
      SortColumn: sortColumn,
      FileStatusFilters: filters,
    };

    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(stagedFileUrl + "search/", data)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " searching staged files.",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while searching staged files."));
        });
    });
  }

  async downloadStagedFile(id: string, convertToPdf: boolean): Promise<string> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(`${stagedFileUrl}download/${id}/${convertToPdf}`, "arraybuffer")
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error(`Response returned ${response.status}: ${response.statusText} downloading staged file`));
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error(`An error occured while downloading staged file '${id}'`));
        });
    });
  }

  async getReviewers(): Promise<ReviewerInformation[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(`${stagedFileUrl}reviewers`)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error(`Response returned ${response.status}: ${response.statusText} getting reviewers`));
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occured getting reviewers"));
        });
    });
  }

  async downloadTemplateDataAsync(request: CreateDownloadRequest): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.httpHelper.httpPost(
        `${stagedFileUrl}download-template`,
        request,
        undefined,
        undefined,
        "blob",
      );
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Response returned ${response.status}: ${response.statusText} downloading csv template`);
      }
    } catch (e) {
      console.error(e);
    }
  }
}
