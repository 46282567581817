import { ContainerModule, interfaces } from "inversify";
import "reflect-metadata";
import IAuthService from "@/services/interfaces/IAuthService";
import AuthService from "@/services/AuthService";
import PROVIDER_TYPES from "./services.types";
import AccessRequestHttpHelper from "@/components/resources/accessRequestHttpHelper";
import HttpHelper from "@/components/resources/httpHelper";
import PermissionHttpHelper from "@/components/resources/permissionHttpHelper";
import RoleHttpHelper from "@/components/resources/roleHttpHelper";
import UserHttpHelper from "@/components/resources/userHttpHelper";
import IPermissionService from "@/services/interfaces/IPermissionService";
import PermissionService from "@/services/PermissionService";
import RouterWrapper from "@/router";
import DocumentStagingHttpHelper from "@/components/resources/documentStagingHttpHelper";
import DocumentStagingBatchHttpHelper from "@/components/resources/DocumentStagingBatchHttpHelper";
import RequestHttpHelper from "@/components/resources/requestHttpHelper";
import SiteDocumentAdminHttpHelper from "@/components/resources/siteDocumentAdminHttpHelper";
import SiteDocumentHttpHelper from "@/components/resources/siteDocumentHttpHelper";
import CategoryHttpHelper from "@/components/resources/categoryHttpHelper";
import NotificationHttpHelper from "@/components/resources/notificationHttpHelper";
import ApplicationHttpHelper from "@/components/resources/applicationHttpHelper";
import FeaturedResourceHttpHelper from "@/components/resources/featuredResourceHttpHelper";
import HazardDataAdminHttpHelper from "@/components/resources/hazardDataAdminHttpHelper";
import HazardDataPropertyHttpHelper from "@/components/resources/hazardDataPropertyHttpHelper";
import HazardDataEvaluationRatingHttpHelper from "@/components/resources/hazardDataEvaluationRatingHttpHelper";
import HazardDataUncertaintyMeasureHttpHelper from "@/components/resources/hazardDataUncertaintyMeasureHttpHelper";
import HazardDataUnitsHttpHelper from "@/components/resources/hazardDataUnitsHttpHelper";
import HazardDataHttpHelper from "@/components/resources/hazardDataHttpHelper";
import HazardDataSearchHttpHelper from "@/components/resources/hazardDataSearchHttpHelper";
import MetadataAutoExtractionHttpHelper from "@/components/resources/MetadataAutoExtractionHttpHelper";
import CategoryService from "@/services/CategoryService";
import SettingHttpHelper from "@/components/resources/SettingHttpHelper";
import UsageAnalyticsHttpHelper from "@/components/resources/usageAnalyticsHttpHelper";

const providersContainerModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IAuthService>(PROVIDER_TYPES.AuthService).to(AuthService).inSingletonScope();
  bind<HttpHelper>(PROVIDER_TYPES.HttpHelper).to(HttpHelper).inSingletonScope();
  bind<PermissionHttpHelper>(PROVIDER_TYPES.PermissionHttpHelper).to(PermissionHttpHelper).inSingletonScope();
  bind<IPermissionService>(PROVIDER_TYPES.PermissionService).to(PermissionService).inSingletonScope();
  bind<AccessRequestHttpHelper>(PROVIDER_TYPES.AccessRequestHttpHelper).to(AccessRequestHttpHelper).inSingletonScope();
  bind<RoleHttpHelper>(PROVIDER_TYPES.RoleHttpHelper).to(RoleHttpHelper).inSingletonScope();
  bind<UserHttpHelper>(PROVIDER_TYPES.UserHttpHelper).to(UserHttpHelper).inSingletonScope();
  bind<RouterWrapper>(PROVIDER_TYPES.RouterWrapper).to(RouterWrapper).inSingletonScope();
  bind<SettingHttpHelper>(PROVIDER_TYPES.SettingHttpHelper).to(SettingHttpHelper).inSingletonScope();
  bind<MetadataAutoExtractionHttpHelper>(PROVIDER_TYPES.MetadataAutoExtractionHttpHelper)
    .to(MetadataAutoExtractionHttpHelper)
    .inSingletonScope();
  bind<DocumentStagingHttpHelper>(PROVIDER_TYPES.DocumentStagingHttpHelper)
    .to(DocumentStagingHttpHelper)
    .inSingletonScope();
  bind<DocumentStagingBatchHttpHelper>(PROVIDER_TYPES.DocumentStagingBatchHttpHelper)
    .to(DocumentStagingBatchHttpHelper)
    .inSingletonScope();
  bind<SiteDocumentAdminHttpHelper>(PROVIDER_TYPES.SiteDocumentAdminHttpHelper)
    .to(SiteDocumentAdminHttpHelper)
    .inSingletonScope();
  bind<RequestHttpHelper>(PROVIDER_TYPES.RequestHttpHelper).to(RequestHttpHelper).inSingletonScope();
  bind<SiteDocumentHttpHelper>(PROVIDER_TYPES.SiteDocumentHttpHelper).to(SiteDocumentHttpHelper).inSingletonScope();
  bind<CategoryHttpHelper>(PROVIDER_TYPES.CategoryHttpHelper).to(CategoryHttpHelper).inSingletonScope();
  bind<CategoryService>(PROVIDER_TYPES.CategoryService).to(CategoryService).inSingletonScope();
  bind<NotificationHttpHelper>(PROVIDER_TYPES.NotificationHttpHelper).to(NotificationHttpHelper).inSingletonScope();
  bind<ApplicationHttpHelper>(PROVIDER_TYPES.ApplicationHttpHelper).to(ApplicationHttpHelper).inSingletonScope();
  bind<FeaturedResourceHttpHelper>(PROVIDER_TYPES.FeaturedResourceHttpHelper)
    .to(FeaturedResourceHttpHelper)
    .inSingletonScope();
  bind<HazardDataAdminHttpHelper>(PROVIDER_TYPES.HazardDataAdminHttpHelper)
    .to(HazardDataAdminHttpHelper)
    .inSingletonScope();
  bind<HazardDataHttpHelper>(PROVIDER_TYPES.HazardDataHttpHelper).to(HazardDataHttpHelper).inSingletonScope();
  bind<HazardDataPropertyHttpHelper>(PROVIDER_TYPES.HazardDataPropertyHttpHelper)
    .to(HazardDataPropertyHttpHelper)
    .inSingletonScope();
  bind<HazardDataEvaluationRatingHttpHelper>(PROVIDER_TYPES.HazardDataEvaluationRatingHttpHelper)
    .to(HazardDataEvaluationRatingHttpHelper)
    .inSingletonScope();
  bind<HazardDataUncertaintyMeasureHttpHelper>(PROVIDER_TYPES.HazardDataUncertaintyMeasureHttpHelper)
    .to(HazardDataUncertaintyMeasureHttpHelper)
    .inSingletonScope();
  bind<HazardDataUnitsHttpHelper>(PROVIDER_TYPES.HazardDataUnitsHttpHelper)
    .to(HazardDataUnitsHttpHelper)
    .inSingletonScope();
  bind<HazardDataSearchHttpHelper>(PROVIDER_TYPES.HazardDataSearchHttpHelper)
    .to(HazardDataSearchHttpHelper)
    .inSingletonScope();
  bind<UsageAnalyticsHttpHelper>(PROVIDER_TYPES.UsageAnalyticsHttpHelper)
    .to(UsageAnalyticsHttpHelper)
    .inSingletonScope();
});

export default providersContainerModule;
