import {
  StagedFileStoreActions,
  StagedFileStoreMutations,
} from "@/constants/store/stagedFile/stagedFileStoreConstants";
import { StagedFile, StagedFileWithDocumentMatch } from "@/dataModel";

export default {
  [StagedFileStoreActions.SET_SELECTED_FILES]({ commit }: { commit: Function }, selectedFiles: StagedFile[]): void {
    commit(StagedFileStoreMutations.SET_SELECTED_FILES, selectedFiles);
  },

  [StagedFileStoreActions.SET_STAGED_FILES_TO_UPDATE](
    { commit }: { commit: Function },
    stagedFilesToUpdate: StagedFileWithDocumentMatch[],
  ): void {
    commit(StagedFileStoreMutations.SET_STAGED_FILES_TO_UPDATE, stagedFilesToUpdate);
  },

  [StagedFileStoreActions.SET_NUMBER_OF_FILES_UPDATED](
    { commit }: { commit: Function },
    numberFilesUpdated: number,
  ): void {
    commit(StagedFileStoreMutations.SET_NUMBER_OF_FILES_UPDATED, numberFilesUpdated);
  },

  [StagedFileStoreActions.SET_FILTERS]({ commit }: { commit: Function }, filters: string[]): void {
    commit(StagedFileStoreMutations.SET_FILTERS, filters);
  },

  [StagedFileStoreActions.SET_PAGE]({ commit }: { commit: Function }, page: number): void {
    commit(StagedFileStoreMutations.SET_PAGE, page);
  },

  [StagedFileStoreActions.SET_RESULTS_PER_PAGE]({ commit }: { commit: Function }, resultsPerPage: number): void {
    commit(StagedFileStoreMutations.SET_RESULTS_PER_PAGE, resultsPerPage);
  },

  [StagedFileStoreActions.SET_SEARCH_TERM]({ commit }: { commit: Function }, searchTerm: string): void {
    commit(StagedFileStoreMutations.SET_SEARCH_TERM, searchTerm);
  },

  [StagedFileStoreActions.RESET_STAGED_FILE_STATE]({ commit }: { commit: Function }): void {
    commit(StagedFileStoreMutations.RESET_STAGED_FILE_STATE);
  },
};
