<template>
  <div id="app" class="wrapper">
    <header class="usa-header">
      <div>
        <div class="usa-alert usa-alert--error" role="alert">
          <div class="usa-alert__body">
            <h4 class="usa-alert__heading">Error</h4>
            <p class="usa-alert__text">
              Backend services are currently unavailable. Please <a href="" class="usa-link">refresh</a> or try again
              later.
            </p>
          </div>
        </div>
      </div>
      <div class="grid-container">
        <div class="grid-row">
          <div class="desktop:grid-col-6">
            <h1 class="usa-hero__heading">
              <router-link :to="{ name: 'Home' }">
                <img
                  @click="homepageRedirect"
                  id="dhs-logo"
                  class="height-9 width-9"
                  src="../../assets/img/dhs-logo.svg"
                  role="img"
                  alt="DHS Logo"
                  aria-label="Return to home page"
                />
              </router-link>
              <span id="logo-text">Hazard Knowledge Management & Analysis</span>
            </h1>
          </div>
          <div class="desktop:grid-col-6"></div>
        </div>
      </div>
    </header>
    <div id="content-wrapper">
      <main id="main-content">
        <div id="section-background" class="usa-section--dark">
          <div id="background-logo-container" class="hero">
            <section class="grid-container usa-section">
              <div class="grid-row grid-gap">
                <div class="desktop:grid-col-5">
                  <h1 class="font-heading-2xl margin-top-0 margin-bottom-2">
                    Discover Hazard Information and Applications
                  </h1>
                  <span> </span>
                </div>
                <div class="desktop:grid-col-6 desktop:grid-offset-1 usa-prose">
                  <p>
                    The Hazard Knowledge Management &amp; Analysis (HKMA) portal contains hazard data, reports, and
                    links to applications that support hazard research and analysis. The Science and Technology
                    Directorate Hazard Knowledge Center (HKC) maintains the portal and its data. The HKC is continually
                    expanding and incorporating new information and resources into the HKMA. Please check back
                    frequently to discover new resources.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    </div>

    <dhs-footer></dhs-footer>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import "@/assets/uswds/js/uswds";
import Home from "../navigation/Home.vue";
import DhsFooter from "../base/Footer.vue";

@Component({
  components: {
    Home,
    DhsFooter,
  },
})
export default class App extends Vue {}
</script>

<style lang="scss">
$theme-show-notifications: false;
@import "~@/assets/uswds/scss/uswds.scss";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-vue/dist/bootstrap-vue.css";

#content-wrapper {
  padding-bottom: 12em;
}

.usa-prose > p {
  max-width: none;
}

#section-background {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #162e51;

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-image: url(../../assets/img/keyboard.jpg);
    background-size: cover;
    background-position: bottom center;
    opacity: 11%;
    background-repeat: no-repeat;
  }
}

.usa-hero__heading {
  padding-top: units(2);
  padding-bottom: units(2);
}

.usa-header {
  background-color: #f8f8f8;
  padding-bottom: units(1);
}

#dhs-logo {
  vertical-align: middle;
}

#dhs-logo:hover {
  cursor: pointer;
}

#logo-text {
  vertical-align: middle;
  display: inline-block;
  color: color("primary");
  font-family: family("heading");
  font-size: size("heading", "lg");
  line-height: lh("heading", 2);
  margin: 0;
  max-width: 290px;
  margin-left: units(3);
}

usa-header {
  border-bottom: 0;
}
</style>
