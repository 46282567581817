import { UserSearchStoreGetters } from "@/constants/store/userSearch/userSearchStoreConstants";
import { UserSearchParameters } from "@/dataModel";
import { UserSearchState } from "@/typings/store/states/UserSearchStoreState";

export default {
  [UserSearchStoreGetters.GET_SEARCH_PARAMETERS]: (state: UserSearchState): UserSearchParameters | undefined =>
    state.searchParameters,
  [UserSearchStoreGetters.GET_SEARCH_STATE]: (state: UserSearchState): UserSearchState => state,
  [UserSearchStoreGetters.GET_SEARCH_TERM]: (state: UserSearchState): string => state.searchTerm,
  [UserSearchStoreGetters.GET_USE_SAVED_PARAMS]: (state: UserSearchState): boolean => state.useSavedParams,
};
