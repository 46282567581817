export default {
  Blue: "rgba(0, 118, 214, 1)",
  Red: "rgba(229, 34, 7, 1)",
  Green: "rgba(83, 130, 0, 1)",
  Orange: "rgba(230, 111, 14, 1)",
  Violet: "rgba(129, 104, 179, 1)",
  Gold: "rgba(255, 190, 46, 1)",
  Mint: "rgba(4, 197, 133, 1)",
  Cyan: "rgba(0, 158, 193, 1)",
  Indigo: "rgba(103, 108, 200, 1)",
};
