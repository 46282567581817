<template>
  <basic-dialog
    id="create-role-dialog"
    title="Create New Role"
    primaryButton="Confirm"
    secondaryButton="Cancel"
    :primaryClick="createNewRole"
    :secondaryClick="cancel"
    :isDisabled="hasNameError"
  >
    <form class="usa-form margin-bottom-2" @submit.prevent="createNewRole">
      <label class="usa-label" for="input-new-role">Enter role name</label>
      <input class="usa-input" id="input-new-role" name="input-new-role" type="text" v-model="name" />
    </form>

    <div class="usa-alert usa-alert--info usa-alert--slim" v-if="hasNameError">
      <div class="usa-alert__body">
        <p class="usa-alert__text">Role name cannot be empty.</p>
      </div>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import { Role } from "@/dataModel";

@Component({
  components: {
    BasicDialog,
  },
})
export default class CreateNewRoleDialog extends Vue {
  name = "";

  get hasNameError() {
    return this.name.trim().length == 0;
  }

  clearRole() {
    this.name = "";
  }

  cancel() {
    this.$bvModal.hide("create-role-dialog");
  }
  createNewRole() {
    const newRole = new Role("", this.name.trim(), []);
    this.$emit("confirm", newRole);
    this.$bvModal.hide("create-role-dialog");
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#input-new-role {
  width: 100%;
}
.usa-form {
  width: 100%;
  max-width: 100%;
}
</style>
