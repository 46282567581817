<template>
  <nav aria-label="Table Filters">
    <ul class="usa-sidenav">
      <li v-for="(filter, index) in filters" :key="filter.name" class="usa-sidenav__item">
        <a
          v-b-toggle
          @click.prevent
          :href="`#collapse-${index}-${removeSpaces(filter.name)}`"
          class="usa-current filter"
          :aria-label="filter.name + ' filter'"
        >
          {{ filter.name }}
        </a>
        <b-collapse :visible="!collapseAll && index === 0" :id="`collapse-${index}-${removeSpaces(filter.name)}`">
          <ul class="usa-sidenav__sublist">
            <li v-for="subFilter in filter.filters" :key="subFilter">
              <div class="usa-checkbox">
                <input
                  class="usa-checkbox__input"
                  :id="subFilter"
                  type="checkbox"
                  :name="subFilter"
                  :value="removeSpaces(subFilter)"
                  v-model="selectedFilters"
                />
                <label class="usa-checkbox__label margin-left-105em" :for="subFilter"
                  >{{ addSpaces(subFilter) }}
                  <!-- TODO: Add counts for each filter -->
                </label>
              </div>
            </li>
          </ul>
        </b-collapse>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { DataTableFilterData } from "@/dataModel/interfaces";

@Component
export default class DataTableFilter extends Vue {
  @Prop({ required: true }) filters!: DataTableFilterData[];
  @Prop({ default: () => [] }) preSelectedFilters!: string[];
  @Prop({ required: false }) collapseAll!: boolean;

  selectedFilters: string[] = [];

  @Watch("selectedFilters")
  filterWatcher() {
    this.$emit("filtersChanged", this.selectedFilters);
  }

  removeSpaces(str: string) {
    return str.replaceAll(" ", "");
  }

  addSpaces(status: string): string {
    return status.replace(/(.)([A-Z][a-z])/g, "$1 $2");
  }

  clearSelections(): void {
    this.selectedFilters = [];
  }

  removeSelection(index: number): void {
    this.selectedFilters.splice(index, 1);
  }

  created() {
    this.selectedFilters = this.preSelectedFilters;
  }
}
</script>

<style scoped lang="scss">
.filter {
  text-transform: capitalize;
}
</style>
