import HttpHelper from '@/components/resources/httpHelper'
import { inject, injectable } from 'inversify';
import serviceTypes from '@/dependencyInjection/types';
import DataSource from '@/dataModel/hazardData/dataSource';

const hazardDataUrl = "hazard-data/sources/";

@injectable()
export default class HazardDataHttpHelper {
    httpHelper: HttpHelper;

    constructor(@inject(serviceTypes.HttpHelper) httpHelper: HttpHelper) {
        this.httpHelper = httpHelper;
    }

    async getSourceWithDetails(id: string): Promise<DataSource> {
        return new Promise((resolve, reject) => {
            this.httpHelper.httpGet(hazardDataUrl + id + '/details').then((response: any ) => {
                if(response.status === 200){
                    resolve(response.data);
                } else {
                    reject(new Error("Response returned " + response.status + " : " + response.statusText + " getting source with details"));
                }
            }).catch(error => {
                console.error(error);
                reject(new Error('An error occurred while getting source with details'));
            });
        });
    }
}