<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <div class="desktop:grid-col">
          <h1 class="font-heading-xl text-primary-dark margin-top-0 margin-bottom-5">Category Management</h1>
        </div>
      </div>
      <div class="grid-row input-container">
        <div class="desktop:grid-col">
          <label class="usa-label mt-0" for="category-tree">Category Selection</label>
          <treeselect
            id="category-tree"
            v-model="selectedParentCategoryId"
            :multiple="false"
            :options="categories"
            placeholder="Select a category..."
            :disableRootNodes="false"
            :clearable="false"
          />
        </div>
        <div class="desktop:grid-col create-btn-container">
          <div class="create-button">
            <button class="usa-button" @click="showCreateCategoryDialog">Create New Category</button>
          </div>
        </div>
      </div>
      <div class="grid-row">
        <category-path :path="categoryPath" @selected-category-id="setCategoryValue" />
      </div>
      <div class="grid-row grid-gap">
        <div class="desktop:grid-col">
          <category-data-table
            :tableData="categoryData"
            @view-sub-category="setCategoryValue"
            @edit-category="showEditCategoryDialog"
            @delete-category="showDeleteCategoryDialog"
          />
        </div>
      </div>
    </section>
    <create-category-dialog
      :categoryTree="categories"
      :selectedCategoryId="selectedParentCategoryId"
      :flatCategoryList="flatCategoryList"
      @create-new-category="createNewCategory"
    />
    <edit-category-dialog
      :category="selectedCategory"
      :siblingCategories="categoryData"
      :parentDisplayName="parentDisplayName"
      :childCategories="selectedCategoryChildren"
      @update-category="editCategory"
    />
    <delete-category-dialog
      :category="selectedCategory"
      :isCategoryUsedByDocuments="isSelectedCategoryUsedByDocuments"
      :numberOfCategoriesBeingDeleted="numberOfCategoriesBeingDeleted"
      @delete-category="deleteCategory"
    />
    <response-status-dialog
      :requestType="requestType"
      :parentCategoryId="parentCategoryId"
      @view-data-path="viewDataPath"
    />
  </main>
</template>

<script lang="ts">
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import { Category, FilterNodeInformation } from "@/dataModel";
import { BreadcrumbPathItem, CategoryPathItem } from "@/dataModel/interfaces";
import { UpdateCategoryRequest, CreateCategoryRequest } from "@/dataModel/requests";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import CategoryHttpHelper from "@/components/resources/categoryHttpHelper";
import Treeselect from "@/components/common/CategoryTreeSelect.vue";
import CategoryPath from "./CategoryPath.vue";
import CategoryDataTable from "./CategoryDataTable.vue";
import CreateCategoryDialog from "./CreateCategoryDialog.vue";
import EditCategoryDialog from "./EditCategoryDialog.vue";
import DeleteCategoryDialog from "./DeleteCategoryDialog.vue";
import ResponseStatusDialog from "./ResponseStatusDialog.vue";
import { Vue, Component, Watch } from "vue-property-decorator";
import CategoryService from "@/services/interfaces/ICategoryService";

@Component({
  components: {
    Breadcrumb,
    CategoryPath,
    Treeselect,
    CategoryDataTable,
    CreateCategoryDialog,
    EditCategoryDialog,
    DeleteCategoryDialog,
    ResponseStatusDialog,
  },
})
export default class CategoryManagementPage extends Vue {
  categories: FilterNodeInformation[] = [];
  selectedParentCategoryId: string | null = null;
  categoryPath: CategoryPathItem[] = [];
  flatCategoryList: Category[] = [];
  categoryData: Category[] = [];
  searchTerm: string = "";
  selectedCategory: Category = new Category();
  selectedCategoryChildren: Category[] = [];
  isSelectedCategoryUsedByDocuments: boolean = true;
  requestType: string = "";
  parentCategoryId: string = "";
  numberOfCategoriesBeingDeleted: number = 0;

  private categoryService = container.get<CategoryService>(serviceTypes.CategoryService);
  private categoryHttpHelper = container.get<CategoryHttpHelper>(serviceTypes.CategoryHttpHelper);

  path: BreadcrumbPathItem[] = [
    {
      text: "Administration",
    },
    {
      text: "Category Management",
    },
  ];

  @Watch("selectedParentCategoryId")
  categorySelectionChanged(): void {
    if (this.selectedParentCategoryId) {
      this.categoryPath = this.categoryService.getCategoryPath(this.selectedParentCategoryId, this.flatCategoryList);
      this.getChildCategories(this.categories);
    }
  }

  setCategoryValue(id: string): void {
    this.selectedParentCategoryId = id;
  }

  hasChildren(category: Category): boolean {
    return this.flatCategoryList.some((flatCategory) => flatCategory.parentId == category.id);
  }

  setHasChildrenPropertyForTableData(): void {
    this.categoryData.forEach((category) => {
      category.hasChildren = this.hasChildren(category);
    });
  }

  async createNewCategory(request: CreateCategoryRequest): Promise<void> {
    const response = await this.categoryHttpHelper.addCategory(request);
    this.showResponseStatusDialog("create", response.parentId);
    this.refreshTableAndCategoryTree();
  }

  async editCategory(request: UpdateCategoryRequest): Promise<void> {
    const response = await this.categoryHttpHelper.updateCategory(request);
    this.showResponseStatusDialog("update");
    this.refreshTableAndCategoryTree();
  }

  async deleteCategory(id: string): Promise<void> {
    await this.categoryHttpHelper.deleteCategory(id);
    this.showResponseStatusDialog("delete");
    this.refreshTableAndCategoryTree();
  }

  getChildCategories(filterNodeInformation: FilterNodeInformation[]): void {
    if (this.selectedParentCategoryId == null) return;

    let childCategories = filterNodeInformation;
    for (let i = 0; i < this.categoryPath.length; i++) {
      const categoryInPath = childCategories.find((category) => category.id == this.categoryPath[i].id)!;

      if (!categoryInPath.hasChildren) {
        this.categoryData = [];
        return;
      }
      childCategories = categoryInPath.children!;
    }

    this.categoryData = this.sortAlphabetically(childCategories.map((category) => category.value));
    this.setHasChildrenPropertyForTableData();
  }

  async getCategoryTree(): Promise<void> {
    const response: FilterNodeInformation[] = await this.categoryHttpHelper.getAllCategoriesTree();
    this.categories = this.categoryService.addCategoryTreeSelectProperties(response);
  }

  async getFlatCategoryList(): Promise<void> {
    const response: Category[] = await this.categoryHttpHelper.getAllCategories();
    this.flatCategoryList = response;
  }

  sortAlphabetically(categories: Category[]): Category[] {
    return categories.sort(function (categoryOne, categoryTwo) {
      const displayNameOne = categoryOne.displayName.toLowerCase();
      const displayNameTwo = categoryTwo.displayName.toLowerCase();
      if (displayNameOne < displayNameTwo) {
        return -1;
      }
      if (displayNameOne) {
        return 1;
      }
      return 0;
    });
  }

  async refreshTableAndCategoryTree(): Promise<void> {
    await this.getFlatCategoryList();
    await this.getCategoryTree();
    this.getChildCategories(this.categories);
  }

  viewDataPath(categoryId: string): void {
    this.selectedParentCategoryId = categoryId;
  }

  get parentDisplayName(): string {
    return this.flatCategoryList.find((category) => category.id == this.selectedParentCategoryId)?.displayName ?? "";
  }

  created(): void {
    this.getCategoryTree();
    this.getFlatCategoryList();
  }

  async showEditCategoryDialog(category: Category): Promise<void> {
    this.selectedCategory = category;
    this.selectedCategoryChildren = await this.categoryHttpHelper.getChildCategories(category.id);
    this.$bvModal.show("edit-category-dialog");
  }

  showCreateCategoryDialog(): void {
    this.$bvModal.show("create-category-dialog");
  }

  async showDeleteCategoryDialog(category: Category): Promise<void> {
    const response = await this.categoryHttpHelper.isCategoryUsedByDocuments(category.id);
    this.isSelectedCategoryUsedByDocuments = response.isCategoryUsedByDocuments;
    this.selectedCategory = category;
    this.numberOfCategoriesBeingDeleted = response.rootAndDescendantCategoryIds.length;
    this.$bvModal.show("delete-category-dialog");
  }

  showResponseStatusDialog(requestType: string, parentCategoryId: string = ""): void {
    this.requestType = requestType;
    this.parentCategoryId = parentCategoryId;
    this.$bvModal.show("response-status-dialog");
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

.input-container {
  display: flex;
}
.create-btn-container {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
}
</style>
