import FileTypeSetting from "./fileTypeSetting";

export default class UserRequestFileUploadConfiguration {
  maxFileSizeInBytes: number = 400000000;
  allowedFileTypes: FileTypeSetting[] = [
    new FileTypeSetting("application/pdf", "PDF"),
    new FileTypeSetting("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "XLSX"),
    new FileTypeSetting("application/vnd.openxmlformats-officedocument.wordprocessingml.document", "DOCX"),
    new FileTypeSetting("pplication/vnd.openxmlformats-officedocument.presentationml.presentation", "PPTX"),
  ];
}
