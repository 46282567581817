import SettingHttpHelper from "@/components/resources/SettingHttpHelper";
import FileStatus from "@/constants/FileStatus";
import { FileType } from "@/constants/FileType";
import StoreNames from "@/constants/store/StoreNames";
import { SettingStoreActions, SettingStoreGetters } from "@/constants/store/setting/settingStoreConstants";
import { Actor, Setting } from "@/dataModel";
import { StagedFile } from "@/dataModel/StagedFile";
import { FileTypeStrings } from "@/dataModel/constants/FileTypeStrings";
import FileTypeSetting from "@/dataModel/fileUpload/fileTypeSetting";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import store from "@/store";
import { join } from "lodash";

export function getFileInfoTags(file: StagedFile): string[] {
  let tags: string[] = [];
  const resource = file.knowledgeResource;
  if (!resource) {
    return tags;
  }

  if (resource.classification) {
    tags.push(resource.classification.title);
  }

  if (resource.disseminationControls.length > 0) {
    tags.push(
      join(
        resource.disseminationControls.map((c) => c.portionMark),
        " | ",
      ),
    );
  }

  if (resource.scis.length > 0) {
    tags.push(
      join(
        resource.scis.map((s) => s.portionMark),
        " | ",
      ),
    );
  }

  return tags;
}

async function getAISetting(): Promise<Setting> {
  const settingHttpHelper = container.get<SettingHttpHelper>(serviceTypes.SettingHttpHelper);
  return await settingHttpHelper.getSettingByKey("AI:Enabled");
}

export async function AISettingIsEnabled(): Promise<boolean> {
  // Check if setting has been defined
  let AI_Setting = store.getters[`${StoreNames.Setting}/${SettingStoreGetters.GET_AI_SETTING}`];

  if (AI_Setting == null) {
    // Retrieve setting from API
    AI_Setting = await getAISetting();

    // Update value in store
    store.dispatch(`${StoreNames.Setting}/${SettingStoreActions.SET_AI_SETTING}`, AI_Setting);
  }

  return AI_Setting.valueVersion === "true";
}

// TODO: Function to be removed after document processor work is completed
export function sleep(ms: number): Promise<any> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isAutoExtractableFileType(file: StagedFile, allowedFileTypes: FileTypeSetting[]): boolean {
  return allowedFileTypes.some((fileTypeSetting) =>
    // TODO: refactor StagedFile.FileType int enum to string to make this comparison not as terrible!!
    fileTypeSetting.fileType ? fileTypeSetting.displayName === file.fileType.toString().toUpperCase() : false,
  );
}

export function fileIsViewable(file: StagedFile, actor?: Actor): boolean {
  if (!actor || file.fileStatus === FileStatus.Converted) {
    return false;
  }

  const { knowledgeResource } = file;
  if (!knowledgeResource) {
    return false;
  }
  const krPermissions = knowledgeResource.disseminationControls
    .map((kr) => kr.id)
    .concat(knowledgeResource.scis.map((sci) => sci.id));
  const actorPermissions = actor.getKnowledgeResourceAccessPermissionIds();
  const actorHasClearance = actor.clearanceLevel.value >= knowledgeResource.classification.value;

  return actorHasClearance && krPermissions.every((permission) => actorPermissions.includes(permission));
}
