import { DataValueSortType } from "@/constants/DataValueSortType";
import { SortOrder } from "@/constants/SortOrder";
import DataValueIdentifierInformation from "./dataValueIdentifierInformation";

export default class GetDataValuesRequest {
  page: number;
  resultsPerPage: number;
  sortType: DataValueSortType;
  sortOrder: SortOrder;
  sortIdentifierKey: string;
  property: string | null;
  hazardAgents: string[];
  selectedOptions: DataValueIdentifierInformation[];
  categoryFilters: string[];
  sourceFilters: string[];
  evaluationRatingFilters: string[];

  constructor(
    page?: number,
    resultsPerPage?: number,
    sortType?: DataValueSortType,
    sortOrder?: SortOrder,
    sortIdentifierKey?: string,
    property?: string | null,
    hazardAgents?: string[],
    selectedOptions?: DataValueIdentifierInformation[],
    categoryFilters?: string[],
    sourceFilters?: string[],
    evaluationRatingFilters?: string[],
  ) {
    this.page = page ?? 0;
    this.resultsPerPage = resultsPerPage ?? 0;
    this.sortType = sortType ?? DataValueSortType.SourceTitle;
    this.sortOrder = sortOrder ?? SortOrder.Descending;
    this.sortIdentifierKey = sortIdentifierKey ?? "";
    this.property = property ?? null;
    this.hazardAgents = hazardAgents ?? [];
    this.selectedOptions = selectedOptions ?? [];
    this.categoryFilters = categoryFilters ?? [];
    this.sourceFilters = sourceFilters ?? [];
    this.evaluationRatingFilters = evaluationRatingFilters ?? [];
  }
}
