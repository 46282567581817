import FeaturedHazardType from "@/constants/FeaturedHazardType";
import FeaturedLengthType from "@/constants/featuredLengthType";
import { FeaturedHazardDataView, FeaturedHazardDocument, SiteDocument } from "@/dataModel";
import { FeaturedResource, PooledFeaturedDataView } from "@/dataModel/interfaces";

export class FeaturedHazardData implements FeaturedResource {
  displayOrder: number;
  startDate: Date;
  endDate?: Date | null;
  featuredLengthType: FeaturedLengthType;
  customFeaturedLength?: number;
  featuredHazardType?: FeaturedHazardType;
  featuredHazardDataId?: string;
  knowledgeResourceId?: string;
  pooledFeaturedDataViewId?: string;
  hazardDocument?: FeaturedHazardDocument;
  hazardDataView?: FeaturedHazardDataView;

  public get title() {
    return this.hazardDocument?.title ?? this.hazardDataView?.name ?? "";
  }

  public get titleClassification() {
    return this.hazardDocument?.titleClassification ?? "";
  }

  constructor(
    displayOrder?: number,
    startDate?: Date,
    endDate?: Date | null,
    featuredLengthType?: FeaturedLengthType,
    customFeaturedLength?: number,
    featuredHazardType?: FeaturedHazardType,
    featuredHazardDataId?: string,
    knowledgeResourceId?: string,
    pooledFeaturedDataViewId?: string,
    hazardDocument?: FeaturedHazardDocument,
    hazardDataView?: FeaturedHazardDataView,
  ) {
    this.displayOrder = displayOrder ?? 0;
    this.startDate = startDate ?? new Date();
    this.endDate = endDate ?? null;
    this.featuredLengthType = featuredLengthType ?? FeaturedLengthType.OneMonth;
    this.customFeaturedLength = customFeaturedLength ?? 0;
    this.featuredHazardType = featuredHazardType ?? FeaturedHazardType.HazardData;
    this.featuredHazardDataId = featuredHazardDataId;
    this.knowledgeResourceId = knowledgeResourceId;
    this.pooledFeaturedDataViewId = pooledFeaturedDataViewId;
    this.hazardDocument = hazardDocument;
    this.hazardDataView = hazardDataView;
  }

  static FromApiResponse(obj: any): FeaturedHazardData {
    return new FeaturedHazardData(
      obj.displayOrder,
      obj.startDate,
      obj.endDate,
      obj.featuredLengthType,
      obj.customFeaturedLength,
      obj.featuredHazardType,
      obj.featuredHazardDataId,
      obj.knowledgeResourceId,
      obj.pooledFeaturedDataViewId,
      obj.hazardDocument,
      obj.hazardDataView,
    );
  }

  static CreateFromDocument(document: SiteDocument): FeaturedHazardData {
    const newData = new FeaturedHazardData();

    newData.displayOrder = 0;
    newData.startDate = new Date();
    newData.featuredLengthType = FeaturedLengthType.NoEndDate;
    newData.customFeaturedLength = 0;
    newData.featuredHazardType = FeaturedHazardType.Document;
    newData.knowledgeResourceId = document.knowledgeResourceId;

    newData.hazardDocument = new FeaturedHazardDocument(
      document.knowledgeResourceId,
      document.title,
      document.titleClassificationMarking,
    );

    return newData;
  }

  static CreateFromPooledDataView(pooledDataView: PooledFeaturedDataView): FeaturedHazardData {
    const newData = new FeaturedHazardData();

    newData.displayOrder = 0;
    newData.startDate = new Date();
    newData.featuredLengthType = FeaturedLengthType.NoEndDate;
    newData.customFeaturedLength = 0;
    newData.featuredHazardType = FeaturedHazardType.HazardData;
    newData.pooledFeaturedDataViewId = pooledDataView.id;

    newData.hazardDataView = new FeaturedHazardDataView(
      pooledDataView.id,
      pooledDataView.name,
      pooledDataView.description,
      pooledDataView.dataViewSearchParameters,
      pooledDataView.hazardTypeId,
    );

    return newData;
  }
}
