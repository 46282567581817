export class UpdateSourceRequest {
  id: string;
  knowledgeResourceId: string;
  title: string;
  contributorDisplay: string;
  documentDateTime: Date;

  constructor(
    id?: string,
    knowledgeResourceId?: string,
    title?: string,
    contributorDisplay?: string,
    documentDateTime?: Date,
  ) {
    this.id = id ?? "";
    this.knowledgeResourceId = knowledgeResourceId ?? "";
    this.title = title ?? "";
    this.contributorDisplay = contributorDisplay ?? "";
    this.documentDateTime = documentDateTime ?? new Date();
  }
}
