<template>
  <b-modal
    v-bind="$attrs"
    header-border-variant="transparent"
    footer-border-variant="transparent"
    content-class="modal-border"
    body-class="body"
    footer-class="footer"
    header-class="header"
    :size="size"
    :no-close-on-backdrop="noCloseOnBackdrop"
    :no-close-on-esc="noCloseOnEsc"
    :hide-header-close="hideHeaderClose"
  >
    <div slot="modal-title">
      <h1 id="dialog-title">{{ title }}</h1>
    </div>
    <div slot="default" id="dialog-content">
      <slot></slot>
    </div>
    <div slot="modal-footer" id="dialog-footer">
      <loading-buttons
        @primaryButtonClick="primaryClick"
        @secondaryButtonClick="secondaryClick"
        :isLoading="isLoading"
        :primaryButtonText="primaryButton"
        :secondaryButtonText="secondaryButton"
        :primaryButtonDisabled="isDisabled"
        :secondaryButtonDisabled="false"
        :size="size"
        :loadingText="loadingText"
      />
    </div>
  </b-modal>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import LoadingButtons from "@/components/common/LoadingButtons.vue";

@Component({ components: { LoadingButtons } })
export default class BasicDialog extends Vue {
  @Prop({ default: "" }) readonly title!: string;
  @Prop({ default: "" }) primaryButton!: string;
  @Prop({ default: "" }) secondaryButton!: string;
  @Prop({ default: "" }) primaryClick!: string;
  @Prop({ default: "" }) secondaryClick!: string;
  @Prop({ default: "" }) size!: string;
  @Prop({ default: false }) isDisabled!: boolean;
  @Prop({ default: false }) noCloseOnBackdrop!: boolean;
  @Prop({ default: false }) noCloseOnEsc!: boolean;
  @Prop({ default: false }) hideHeaderClose!: boolean;
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({ default: "" }) loadingText!: string;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

#dialog-title {
  color: color("primary-dark");
  font-family: family("heading");
  font-size: size("heading", "xl");
  margin-left: units(4);
  margin-right: units(4);
  margin-top: units(3);
  margin-bottom: units(0);
}

#dialog-content {
  margin-left: units(4);
  margin-right: units(4);
  margin-bottom: 0;
}

#dialog-footer {
  width: 100%;
  margin-left: units(6);
  margin-right: units(6);
  margin-bottom: units(3);
}

:deep(.modal-border) {
  border-radius: 0;
}

:deep(.header) {
  border-bottom-color: transparent;
}

:deep(.body) {
  padding-top: 0;
}

:deep(.footer) {
  padding-left: 0;
  padding-right: 0;
  border-top-color: transparent;
}
</style>
