import { render, staticRenderFns } from "./SaveChangesDialog.vue?vue&type=template&id=148d8235&scoped=true"
import script from "./SaveChangesDialog.vue?vue&type=script&lang=ts"
export * from "./SaveChangesDialog.vue?vue&type=script&lang=ts"
import style0 from "./SaveChangesDialog.vue?vue&type=style&index=0&id=148d8235&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "148d8235",
  null
  
)

export default component.exports