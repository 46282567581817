import { MutationTree } from "vuex";
import { DocumentStagingState } from "@/typings/store/states/DocumentStagingState";
import { SiteDocument } from "@/dataModel";

export enum DocumentStagingMutations {
  SET_SUMMARIZED_BATCH_ID = "SET_SUMMARIZED_BATCH_ID",
  SET_SUMMARIZED_BATCH_DOCUMENTS = "SET_SUMMARIZED_BATCH_DOCUMENTS",
  UPDATE_BATCH_DOCUMENT_METADATA = "UPDATE_BATCH_DOCUMENT_METADATA",
}

export const mutations: MutationTree<DocumentStagingState> = {
  [DocumentStagingMutations.SET_SUMMARIZED_BATCH_ID](state, payload: string) {
    state.summarizedBatchId = payload;
  },

  [DocumentStagingMutations.SET_SUMMARIZED_BATCH_DOCUMENTS](state, payload: SiteDocument[]) {
    state.summarizedBatchSiteDocuments = payload;
  },

  [DocumentStagingMutations.UPDATE_BATCH_DOCUMENT_METADATA](state, payload: SiteDocument) {
    const docIndex = state.summarizedBatchSiteDocuments.findIndex(
      (d) => d.knowledgeResourceId && d.knowledgeResourceId == payload.knowledgeResourceId,
    );
    if (docIndex >= 0) {
      state.summarizedBatchSiteDocuments.splice(docIndex, 1, payload);
    }
  },
};
