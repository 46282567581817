import HttpHelper from "@/components/resources/httpHelper";
import { inject, injectable } from "inversify";
import serviceTypes from "@/dependencyInjection/types";
import { HazardType } from "@/constants/HazardType";
import HazardPropertyDetails from "@/dataModel/hazardData/hazardPropertyDetails";
import HazardPropertySpecifications from "@/dataModel/hazardData/hazardPropertySpecifications";

const hazardDataPropertyUrl = "hazard-data/properties/";

@injectable()
export default class HazardDataPropertyHttpHelper {
  httpHelper: HttpHelper;

  constructor(@inject(serviceTypes.HttpHelper) httpHelper: HttpHelper) {
    this.httpHelper = httpHelper;
  }

  async getHazardPropertiesByType(type: HazardType): Promise<HazardPropertyDetails[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(hazardDataPropertyUrl + "type/" + type)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " +
                  response.status +
                  " : " +
                  response.statusText +
                  " getting " +
                  "hazard properties by hazard type",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting hazard properties by hazard type"));
        });
    });
  }

  async getHazardPropertySpecifications(hazardPropertyId: string): Promise<HazardPropertySpecifications> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(hazardDataPropertyUrl + hazardPropertyId + "/specifications")
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " +
                  response.status +
                  " : " +
                  response.statusText +
                  " getting " +
                  "hazard property specifications by id",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting hazard property specifications by id"));
        });
    });
  }
}
