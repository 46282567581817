<template>
  <basic-dialog
    :id="dialogId"
    :title="titleText"
    :primaryButton="primaryButtonText"
    secondaryButton="Cancel"
    :primaryClick="primaryClick"
    :isDisabled="isDisabled"
    :secondaryClick="cancel"
    noCloseOnBackdrop
    noCloseOnEsc
    hideHeaderClose
    :isLoading="uploadInProgress"
    loadingText="Upload in progress..."
  >
    <form class="usa-form--large">
      <template v-if="isBatchUpload">
        <div class="usa-form-group">
          <label for="batch-name-field" class="usa-label usa-label--required">Name</label>
          <input
            class="usa-input"
            type="text"
            name="batch-name-field"
            id="batch-name-field"
            placeholder="Batch Name"
            v-model="batchName"
            aria-describedby="batch-name-error-message"
          />
        </div>
        <span v-if="isEmpty(batchName)" class="usa-error-message" id="batch-name-error-message">
          Batch must have a name
        </span>

        <div class="usa-form-group">
          <label for="batch-desc-field" class="usa-label">Description</label>
          <input
            class="usa-input"
            type="text"
            name="batch-desc-field"
            id="batch-desc-field"
            placeholder="Batch Description"
            v-model="batchDescription"
          />
        </div>
        <hr class="margin-y-4" />
        <div class="usa-alert usa-alert--slim usa-alert--warning">
          <div class="usa-alert__body">
            <p class="usa-alert__text">The fields below will be applied to each file included in the batch.</p>
          </div>
        </div>
      </template>

      <!-- Reviewer -->
      <label class="usa-label" for="reviewer">Reviewer (optional)</label>
      <select
        class="usa-select"
        name="reviewer"
        id="reviewer"
        :disabled="initialUploadCompleted"
        v-model="selectedReviewer"
      >
        <option :value="null">- Select -</option>
        <option v-for="(reviewer, index) in reviewers" :key="index" :value="reviewer">
          {{ reviewer.displayName }}
        </option>
      </select>

      <!-- Classification -->
      <label class="usa-label usa-label--required" for="classification">Classification</label>
      <select
        class="usa-select width-mobile"
        name="classification"
        id="classification"
        :disabled="initialUploadCompleted"
        v-model="documentClassification"
        aria-describedby="classification-error-message"
      >
        <option :value="null">- Select -</option>
        <option v-for="classification in allClassifications" :key="classification.title" :value="classification.id">
          {{ classification.title }}
        </option>
      </select>
      <span v-if="isEmpty(documentClassification)" class="usa-error-message" id="classification-error-message">
        Classification cannot be empty
      </span>

      <!-- Dissemination controls -->
      <fieldset class="usa-fieldset margin-top-3" v-if="allDisseminationControls.length > 0">
        <legend class="usa-label">Dissemination controls (optional)</legend>
        <div class="width-mobile">
          <div
            class="usa-checkbox margin-left-2"
            v-for="disseminationControl in allDisseminationControls"
            :key="disseminationControl.portionMark"
          >
            <input
              class="usa-checkbox__input"
              :id="disseminationControl.id"
              type="checkbox"
              :value="disseminationControl.id"
              :disabled="initialUploadCompleted"
              v-model="documentDisseminationControls"
            />
            <label class="usa-checkbox__label" :for="disseminationControl.id">{{ disseminationControl.title }}</label>
          </div>
        </div>
      </fieldset>

      <!-- SCI -->
      <fieldset v-if="allScis.length > 0" class="usa-fieldset margin-top-3">
        <legend class="usa-label">Sensitive compartmented information (optional)</legend>
        <div class="width-mobile">
          <div class="usa-checkbox margin-left-2" v-for="sci in allScis" :key="sci.portionMark">
            <input
              class="usa-checkbox__input"
              :id="sci.id"
              type="checkbox"
              :value="sci.id"
              :disabled="initialUploadCompleted"
              v-model="documentScis"
            />
            <label class="usa-checkbox__label" :for="sci.id">{{ sci.title }}</label>
          </div>
        </div>
      </fieldset>

      <!-- Additional information -->
      <div class="usa-character-count">
        <div class="usa-form-group">
          <label class="usa-label" for="note-textarea"> Additional information (optional) </label>
          <textarea
            class="usa-textarea usa-character-count__field"
            id="note-textarea"
            maxlength="1000"
            name="note-textarea"
            rows="2"
            aria-describedby="note-textarea-info note-textarea-hint"
            v-text="note"
            :disabled="initialUploadCompleted"
            v-model="note"
          ></textarea>
        </div>

        <span id="note-textarea-info" class="usa-hint usa-character-count__message" aria-live="polite">
          You can enter up to 1000 characters
        </span>
      </div>

      <!-- File upload -->
      <div class="usa-form-group">
        <label class="usa-label usa-label--required" for="document-input" id="document-label"> Files </label>
        <span class="usa-hint" id="allowed-file-types"> Allowed file types: {{ fileTypesDisplayString }} </span>
        <input
          id="document-input"
          class="usa-file-input"
          type="file"
          name="document-input"
          :accept="fileInputTypesString"
          :disabled="initialUploadCompleted"
          multiple
          required
          @change="onFileChanged"
          aria-describedby="allowed-file-types documents-empty-error-message documents-size-error-message documents-type-error-message"
        />
      </div>
      <span
        v-if="isEmpty(documents) && !invalidFileTypeExists"
        class="usa-error-message"
        id="documents-empty-error-message"
      >
        File selection cannot be empty
      </span>
      <span
        v-if="showValidation && !isEmpty(documents) && !areAllFilesValidLength(documents)"
        class="usa-error-message"
        id="documents-size-error-message"
      >
        File size cannot exceed {{ Math.round(maxFileSize / 1000000) }} MB
      </span>
      <span v-if="invalidFileTypeExists" class="usa-error-message" id="documents-type-error-message">
        Invalid file type
      </span>
    </form>

    <div v-if="showProgress" class="progress" id="progress">
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        :aria-valuenow="progressValue"
        aria-valuemin="0"
        aria-valuemax="100"
        :style="'width: ' + progressValue + '%'"
      >
        {{ progressValue }}%
      </div>
    </div>
    <ul id="file-status-list">
      <template v-for="(requestWithStatus, index) in requestsWithStatus">
        <li :key="index" class="upload-item" v-if="cancelled && requestWithStatus.status == FileUploadStatus.Pending">
          <div class="usa-alert usa-alert--warning usa-alert--slim">
            <div class="usa-alert__body">
              <p class="usa-alert__text" role="alert">{{ requestWithStatus.request.file.name }} was cancelled.</p>
            </div>
          </div>
        </li>
        <li :key="index" class="upload-item" v-if="requestWithStatus.status == FileUploadStatus.Succeeded">
          <div class="usa-alert usa-alert--success usa-alert--slim">
            <div class="usa-alert__body">
              <p class="usa-alert__text" role="alert">
                {{ requestWithStatus.request.file.name }} successfully uploaded.
              </p>
            </div>
          </div>
        </li>
        <li :key="index" class="upload-item" v-if="requestWithStatus.status == FileUploadStatus.Failed">
          <div class="usa-alert usa-alert--error usa-alert--slim">
            <div class="usa-alert__body">
              <p class="usa-alert__text" role="alert">{{ requestWithStatus.request.file.name }} failed to upload.</p>
            </div>
          </div>
        </li>
      </template>
    </ul>
    <div v-if="errorMessage" class="usa-alert usa-alert--slim usa-alert--error">
      <div class="usa-alert__body">
        <p class="usa-alert__text">{{ errorMessage }}</p>
      </div>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import store from "@/store";
import StoreNames from "@/constants/store/StoreNames";
import { AuthStoreGetters } from "@/constants/store/auth/authStoreConstants";
import BasicDialog from "@/components/common/BasicDialog.vue";
import DocumentStagingHttpHelper from "@/components/resources/documentStagingHttpHelper";
import serviceTypes from "@/dependencyInjection/types";
import container from "@/dependencyInjection/config";
import DocumentInputFields from "@/components/navigation/DocumentManagement/DocumentInputFields.vue";
import { v4 as uuidv4 } from "uuid";
import axios, { CancelTokenSource } from "axios";
import { FileUploadStatus } from "@/dataModel/constants/fileUploadStatus";
import {
  AppConfig,
  Classification,
  DisseminationControl,
  Sci,
  ReviewerInformation,
  StagedFileBatch,
} from "@/dataModel";
import { UploadStagedFileRequest, UploadFileRequestWrapper as RequestWrapper } from "@/dataModel/requests";
import IPermissionService from "@/services/interfaces/IPermissionService";
import DocumentUploadConfiguration from "@/dataModel/fileUpload/documentUploadConfiguration";
import DocumentStagingBatchHttpHelper from "@/components/resources/DocumentStagingBatchHttpHelper";
import { ApiResponse } from "@/components/resources/ApiResponse";

@Component({
  components: {
    BasicDialog,
    DocumentInputFields,
  },
})
export default class UploadDialog extends Vue {
  allClassifications: Classification[] = [];
  allDisseminationControls: DisseminationControl[] = [];
  allScis: Sci[] = [];
  reviewers: ReviewerInformation[] = [];
  readonly uploadButtonText = "Upload";
  readonly retryButtonText = "Retry";
  readonly doneButtonText = "Done";
  maxFileSize = 0;
  fileInputTypesString = "";
  fileTypesDisplayString = "";
  showProgress = false;
  progressValue = 0;
  selectedReviewer: ReviewerInformation | null = null;

  documentClassification: string | null = null;
  documentDisseminationControls: string[] = [];
  documentScis: string[] = [];
  batchName: string = "";
  batchDescription: string = "";
  newBatchId: string = "";

  documents: FileList | null = null;
  showValidation = false;
  primaryButtonText = this.uploadButtonText;
  isDisabled = false;
  initialUploadCompleted = false;
  note = "";
  filesUploaded = 0;
  cancelToken?: CancelTokenSource;
  requestsWithStatus: RequestWrapper[] = [];
  FileUploadStatus = FileUploadStatus;
  cancelled = false;
  done = false;
  uploadInProgress = false;
  checkValidation = false;
  allowedFileContentTypes: string[] = [];
  errorMessage = "";

  private docStagingHttpHelper = container.get<DocumentStagingHttpHelper>(serviceTypes.DocumentStagingHttpHelper);
  private docStagingBatchHttpHelper = container.get<DocumentStagingBatchHttpHelper>(
    serviceTypes.DocumentStagingBatchHttpHelper,
  );
  readonly appConfig = container.get<AppConfig>(serviceTypes.AppConfig);
  private permissionService = container.get<IPermissionService>(serviceTypes.PermissionService);

  @Prop({ required: true }) dialogId!: string;
  @Prop({ default: false }) isBatchUpload!: boolean;

  get titleText(): string {
    return this.isBatchUpload ? "Upload Document Batch" : "Upload Documents";
  }

  get invalidFileTypeExists(): boolean {
    for (const document of [...(this.documents ?? [])]) {
      if (!this.allowedFileContentTypes.includes(document.type)) {
        return true;
      }
    }
    return false;
  }

  async primaryClick(): Promise<void> {
    if (this.done) {
      this.closeDialog();
      return;
    }
    this.checkValidation = true;
    this.requestsWithStatus
      .filter((x) => x.status == FileUploadStatus.Failed)
      .forEach((x) => (x.status = FileUploadStatus.Pending));
    this.cancelled = false;
    this.showValidation = true;

    if (!this.formIsValid()) {
      return;
    }

    // Create batch
    if (this.isBatchUpload) {
      await this.createBatchAsync();
      if (!this.newBatchId) {
        this.showRetry();
        return;
      }
    }

    // Create and process document upload requests
    this.populateRequests();
    await this.processRequests();

    // Check upload statuses
    if (this.requestsWithStatus.some((x) => x.status != FileUploadStatus.Succeeded)) {
      // There are files remaining to retry
      this.showRetry();
    } else {
      this.primaryButtonText = this.doneButtonText;
      this.done = true;
      this.isDisabled = false;
      this.showValidation = false;
    }
  }

  showRetry(): void {
    this.showProgress = false;
    this.progressValue = 0;
    this.isDisabled = false;
    this.primaryButtonText = this.retryButtonText;
  }

  populateRequests(): void {
    // generate requests list only if empty
    if (this.requestsWithStatus.length > 0) {
      return;
    }

    this.requestsWithStatus = [...this.documents!!].map((file) => {
      const request: UploadStagedFileRequest = {
        batchId: this.isBatchUpload ? this.newBatchId : undefined,
        file,
        classification: this.documentClassification!!,
        disseminationControls: this.documentDisseminationControls,
        scis: this.documentScis,
        requestId: uuidv4(),
        note: this.note ?? "",
        reviewerUserId: this.selectedReviewer?.userId,
      };

      return new RequestWrapper(request);
    });
  }

  async processRequests() {
    this.isDisabled = true;
    this.showProgress = true;
    this.initialUploadCompleted = true;
    this.uploadInProgress = true;

    for (const requestWithStatus of this.requestsWithStatus) {
      if (requestWithStatus.status == FileUploadStatus.Succeeded || this.cancelled) {
        this.updateProgressValue();
        continue;
      }
      requestWithStatus.cancelToken = axios.CancelToken.source();
      await this.uploadFileAsync(requestWithStatus);
      this.updateProgressValue();
    }

    this.uploadInProgress = false;
    this.showProgress = false;
  }

  async uploadFileAsync(requestWithStatus: RequestWrapper): Promise<void> {
    try {
      await this.docStagingHttpHelper.uploadFile(
        requestWithStatus.request as UploadStagedFileRequest,
        requestWithStatus.cancelToken,
      );
      ++this.filesUploaded;
      requestWithStatus.status = FileUploadStatus.Succeeded;
    } catch (error: any) {
      if (error?.response?.status == 409) {
        // file was already uploaded before
        ++this.filesUploaded;
        requestWithStatus.status = FileUploadStatus.Succeeded;
      } else if (!axios.isCancel(error)) {
        requestWithStatus.status = FileUploadStatus.Failed;
      }
    }
  }

  async createBatchAsync(): Promise<void> {
    const response: ApiResponse<StagedFileBatch> = await this.docStagingBatchHttpHelper.createBatchAsync(
      this.batchName,
      this.batchDescription,
    );

    if (response.hasError) {
      this.newBatchId = "";
      this.errorMessage = response.errorMessage;
      this.showRetry();
      return;
    }

    this.newBatchId = response.data.batchId;
  }

  updateProgressValue(): void {
    this.progressValue = Math.min(Math.round(this.progressValue + 100 / this.documents!.length), 100);
  }

  isEmpty(input: string | FileList | null): boolean {
    let condition;
    if (typeof input === "string") {
      condition = !input.trim().length;
    } else if (input == null) {
      condition = true;
    } else {
      condition == !input.length;
    }
    return this.showValidation && condition;
  }

  formIsValid(): boolean {
    return (
      this.documents !== null &&
      this.documents.length > 0 &&
      this.areAllFilesValidLength(this.documents) &&
      this.documentClassification !== null &&
      !this.isEmpty(this.documentClassification) &&
      !this.invalidFileTypeExists &&
      (!this.isBatchUpload || (this.isBatchUpload && this.batchName.length > 0))
    );
  }

  areAllFilesValidLength(files: FileList | null): boolean {
    if (this.isEmpty(files)) {
      return true;
    }

    for (let i = 0; i < files!.length; ++i) {
      if (files![i].size > this.maxFileSize) {
        return false;
      }
    }

    return true;
  }

  resetForm(): void {
    this.batchName = "";
    this.batchDescription = "";
    this.note = "";
    this.showValidation = false;
    this.selectedReviewer = null;
    this.documents = null;
    this.documentClassification = null;
    this.documentDisseminationControls = [];
    this.documentScis = [];
    this.progressValue = 0;
    this.showProgress = false;
    this.requestsWithStatus = [];
    this.isDisabled = false;
    this.initialUploadCompleted = false;
    this.primaryButtonText = this.uploadButtonText;
    this.filesUploaded = 0;
    this.done = false;
    this.cancelled = false;
    this.uploadInProgress = false;
  }

  cancel(): void {
    if (this.uploadInProgress) {
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      this.cancelled = true;
      this.showProgress = false;
      this.progressValue = 0;
      this.requestsWithStatus.forEach((x) => {
        if (x.cancelToken) {
          x.cancelToken.cancel();
        }
      });
    } else {
      this.closeDialog();
    }
  }

  closeDialog(): void {
    if (this.filesUploaded > 0) {
      this.$emit("onUpload", this.filesUploaded);
    }
    this.resetForm();
    this.$bvModal.hide(this.dialogId);
  }

  onFileChanged(event: Event): void {
    // set selected files
    const input = event.target as HTMLInputElement;
    if (!input.files) {
      return;
    }
    this.documents = input.files;
  }

  async getReviewers(): Promise<void> {
    const user = store.getters[`${StoreNames.Auth}/${AuthStoreGetters.GET_USER}`];
    const reviewerList = await this.docStagingHttpHelper.getReviewers();
    this.reviewers = reviewerList.filter((r) => r.userId !== user.userId);
  }

  created(): void {
    this.getReviewers();
    this.allClassifications = this.permissionService.getClassifications();
    this.allDisseminationControls = this.permissionService.getDisseminationControls();
    this.allScis = this.permissionService.getScis();

    const docConfig: DocumentUploadConfiguration = this.appConfig.fileUploadSettings.documents;
    this.maxFileSize = docConfig.maxFileSizeInBytes;
    this.fileInputTypesString = docConfig.allowedFileTypes.map((x) => x.fileType).join(",");
    this.fileTypesDisplayString = docConfig.allowedFileTypes.map((x) => x.displayName).join(", ");
    this.allowedFileContentTypes = docConfig.allowedFileTypes.map((x) => x.fileType!);
  }
}
</script>

<style scoped lang="scss">
#progress {
  margin-top: 16px;
}

#file-status-list {
  margin-top: 16px;
  max-height: 8rem;
  overflow: auto;
}

.upload-item {
  list-style-type: none;
}

.usa-textarea {
  height: 4rem;
}

.usa-alert__text {
  font-size: 14px;
}
</style>
