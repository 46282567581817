<template>
  <div>
    <!-- Buttons -->
    <div class="grid-row">
      <div class="margin-left-auto">
        <div class="usa-button-group margin-y-2">
          <li class="usa-button-group__item">
            <a href="#0" class="usa-button usa-button--outline" @click="showTemplateDownloadDialog">
              Download Metadata Template
            </a>
          </li>
          <li class="usa-button-group__item">
            <a href="#0" class="usa-button" @click="showUploadBatchDialog"> Upload Document Batch </a>
          </li>
        </div>
      </div>
    </div>

    <!-- Batch table -->
    <data-table
      id="batch-table"
      :fields="batchTableHeaders"
      :items="batches"
      :actions="batchRowActions"
      :collapseActions="true"
      @convert="promptAutoExtractOrFilePicker"
      @approveAll="showApproveConfirm"
      @declineAll="showDeclineDialog"
      @deleteBatch="showDeleteConfirm"
    >
      <!-- Batch name/desc -->
      <template #cell(name-desc)="data">
        <div>
          <p class="margin-bottom-0">
            <span class="text-bold">{{ data.item.name }}</span> ({{ data.item.stagedFiles.length }} files)
          </p>
          <p class="font-body-xs">{{ data.item.description }}</p>
        </div>
      </template>

      <!-- Batch status -->
      <template #cell(status)="data">
        <batch-status-tag
          v-for="info in getBatchStatusInfo(data.item)"
          :status="info.fileStatus"
          :count="info.count"
        ></batch-status-tag>
      </template>

      <!-- Batch date -->
      <template #cell(date)="data">
        <p class="margin-top-05">{{ formatBatchDate(data.item) }}</p>
      </template>

      <!-- Batch details expander toggle -->
      <template v-slot:cell(show_details)="row">
        <div class="mt-1">
          <a tabindex="0" @click="row.toggleDetails" @keypress.enter="row.toggleDetails" class="usa-link expander">
            <i
              v-if="!row.detailsShowing"
              class="fa fa-plus usa-tooltip"
              data-position="top"
              title="Click to view document list"
            ></i>
            <i v-else class="fa fa-minus"></i>
          </a>
        </div>
      </template>

      <!-- Details (expanded content) -->
      <template v-slot:row-details="row">
        <data-table
          class="margin-top-neg-2"
          :id="`${row.item.batchId}-docs-table`"
          :fields="batchDocumentsTableHeaders"
          :items="row.item.stagedFiles"
          :actions="documentRowActions"
          :use-light-header="true"
          :outlined="true"
          :compact="true"
          @log="showFileLog"
          @delete="deleteFile"
        >
          <template v-slot:cell(fileName)="data">
            <div>
              <p>
                <a
                  v-if="fileIsViewable(data.item, actor)"
                  href="#/"
                  @click.prevent="viewFile(data.item.id)"
                  class="usa-link filename overflow-ellipsis"
                >
                  {{ data.value }}
                </a>
                <span v-else class="filename overflow-ellipsis">
                  {{ data.value }}
                </span>
              </p>
              <div class="grid-row margin-top-1">
                <span
                  v-for="(tag, index) in getFileInfoTags(data.item)"
                  :key="`${data.item.id}-tag-${index}`"
                  class="usa-tag"
                >
                  {{ tag }}
                </span>
              </div>
            </div>
          </template>
        </data-table>
      </template>
    </data-table>

    <!-- Dialogs -->
    <document-metadata-template-dialog dialog-id="template-download-dialog" />
    <metadata-file-dialog dialog-id="metadata-file-dialog" :batch="selectedBatch" />
    <upload-document-dialog dialog-id="upload-batch-dialog" :isBatchUpload="true" @onUpload="loadBatchesAsync" />
    <confirm-batch-dialog
      dialog-id="confirm-batch-dialog"
      :title="confirmDialogTitle"
      :batch="selectedBatch"
      @approve="approveAllDocumentsInBatchAsync"
      @delete="deleteBatchAsync"
    />
    <decline-batch-dialog
      dialog-id="decline-batch-dialog"
      :batch="selectedBatch"
      @decline="declineAllDocumentsInBatchAsync"
    />

    <auto-extract-or-file-picker-dialog
      dialog-id="auto-extract-or-file-picker-dialog"
      @confirm-file="promptForMetadataFile"
      @confirm-auto="autoExtractHandler"
      :allowedFileTypes="allowedAutoExtractFileTypes"
    />

    <auto-extract-metadata-batch-dialog dialog-id="auto-extract-metadata-batch-dialog" :selectedBatch="selectedBatch" />

    <!-- Toasts -->
    <error-toast id="batch-error-toast" :message="errorMessage"></error-toast>
    <success-toast id="batch-success-toast" :message="successMessage"></success-toast>
  </div>
</template>

<script lang="ts">
import DataTable from "@/components/common/DataTable.vue";
import DocumentStagingBatchHttpHelper from "@/components/resources/DocumentStagingBatchHttpHelper";
import DocumentStagingHttpHelper from "@/components/resources/documentStagingHttpHelper";
import FileStatus from "@/constants/FileStatus";
import { Actor, AppConfig, BatchStatusInfo, StagedFile, StagedFileBatch } from "@/dataModel";
import { DataTableAction, DataTableHeader } from "@/dataModel/interfaces";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import { addSpaces, formatDate } from "@/utils/helperFunctions";
import { groupBy } from "lodash";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import BatchStatusTag from "./BatchStatusTag.vue";
import ConfirmBatchDialog from "../DocumentStagingDialogs/ConfirmBatchDialog.vue";
import { ConfirmDialogTitles } from "../DocumentStagingDialogs/ConfirmDialogTitles";
import DeclineBatchDialog from "../DocumentStagingDialogs/DeclineBatchDialog.vue";
import DocumentMetadataTemplateDialog from "../DocumentStagingDialogs/DocumentMetadataTemplateDialog.vue";
import UploadDocumentDialog from "../DocumentStagingDialogs/UploadDialog.vue";
import MetadataFileDialog from "../DocumentStagingDialogs/MetadataFileDialog.vue";
import AutoExtractOrFilePickerDialog from "../DocumentStagingDialogs/AutoExtractOrFilePickerDialog.vue";
import { getFileInfoTags, fileIsViewable, AISettingIsEnabled } from "../documentStagingHelpers";
import IAuthService from "@/services/interfaces/IAuthService";
import { AuthAccess } from "@/typings/store/states/AuthStoreState";
import FileTypeSetting from "@/dataModel/fileUpload/fileTypeSetting";
import AutoExtractMetadataBatchDialog from "../DocumentStagingDialogs/AutoExtractMetadataBatchDialog.vue";
import StoreNames from "@/constants/store/StoreNames";
import { SettingStoreActions } from "@/constants/store/setting/settingStoreConstants";

@Component({
  components: {
    DocumentMetadataTemplateDialog,
    UploadDocumentDialog,
    ConfirmBatchDialog,
    DeclineBatchDialog,
    MetadataFileDialog,
    DataTable,
    BatchStatusTag,
    AutoExtractOrFilePickerDialog,
    AutoExtractMetadataBatchDialog,
  },
})
export default class BatchList extends Vue {
  //#region Data properties

  getFileInfoTags = getFileInfoTags;
  fileIsViewable = fileIsViewable;
  userAccess!: AuthAccess;
  errorMessage = "";
  successMessage = "";
  batchTableHeaders: DataTableHeader[] = [
    { label: "Name/Description", key: "name-desc", sortable: false },
    { label: "Status", key: "status", sortable: false },
    { label: "Created", key: "date", sortable: false },
    { label: "", key: "actions", sortable: false },
    { label: "", key: "show_details", sortable: false },
  ];
  documentRowActions: DataTableAction[] = [
    { name: "Log", icon: "fa-info-circle", action: "log" },
    { name: "Delete", icon: "fa-trash-alt", action: "delete" },
  ];
  batchDocumentsTableHeaders: DataTableHeader[] = [
    { label: "Filename", key: "fileName", sortable: false },
    { label: "Status", key: "fileStatus", sortable: false, formatter: addSpaces },
    { label: "", key: "actions", sortable: false },
  ];
  batches: StagedFileBatch[] = [];
  selectedBatch: StagedFileBatch = {} as StagedFileBatch;
  confirmDialogTitle: ConfirmDialogTitles = ConfirmDialogTitles.Empty;
  AISettingEnabled: boolean = false;

  private docStagingBatchHttpHelper = container.get<DocumentStagingBatchHttpHelper>(
    serviceTypes.DocumentStagingBatchHttpHelper,
  );
  private docStagingHttpHelper = container.get<DocumentStagingHttpHelper>(serviceTypes.DocumentStagingHttpHelper);
  private authService = container.get<IAuthService>(serviceTypes.AuthService);
  private appConfig = container.get<AppConfig>(serviceTypes.AppConfig);

  allowedAutoExtractFileTypes: FileTypeSetting[] =
    this.appConfig.fileUploadSettings.documents.allowedAutoExtractFileTypes;

  //#endregion

  @Prop({ required: true }) actor!: Actor;

  //#region Methods

  get batchRowActions(): DataTableAction[] {
    const authState = this.authService.getAuthState();
    if (authState != null) {
      this.userAccess = authState.access;
    }

    let actions: DataTableAction[] = [{ name: "Convert to Site Documents", icon: "none", action: "convert" }];

    if (this.userAccess.hasDocumentApproveAccess) {
      actions.push({ name: "Approve All Documents", icon: "none", action: "approveAll" });
      actions.push({ name: "Decline All Documents", icon: "none", action: "declineAll" });
    }

    if (this.userAccess.hasDocumentEditAccess) {
      actions.push({ name: "Delete Batch", icon: "none", action: "deleteBatch" });
    }

    return actions;
  }

  formatBatchDate(batch: StagedFileBatch) {
    return formatDate(batch.createdDateTime);
  }

  viewFile(id: string): void {
    this.$router.push({
      name: "ViewStagedFile",
      params: { id },
    });
  }

  async loadBatchesAsync(): Promise<void> {
    const response = await this.docStagingBatchHttpHelper.getAllBatchesAsync();
    if (response.hasError) {
      this.notifyError(`Error getting document batches: ${response.errorMessage}`);
    }

    this.batches = response.data;
  }

  async approveAllDocumentsInBatchAsync(): Promise<void> {
    const ids = this.selectedBatch.stagedFiles.map((file) => file.id);
    await this.docStagingHttpHelper.bulkChangeFileStatus(ids, FileStatus.Approved, "");
    this.notifySuccess("Batch documents successfully approved.");

    await this.loadBatchesAsync();
  }

  async declineAllDocumentsInBatchAsync(reason: string): Promise<void> {
    const ids = this.selectedBatch.stagedFiles.map((file) => file.id);
    await this.docStagingHttpHelper.bulkChangeFileStatus(ids, FileStatus.Declined, reason);
    this.notifySuccess("Batch documents successfully declined.");

    await this.loadBatchesAsync();
  }

  @Emit("on-show-file-log")
  showFileLog(file: StagedFile): StagedFile {
    return file;
  }

  @Emit("on-file-delete")
  deleteFile(file: StagedFile): StagedFile {
    return file;
  }

  async deleteBatchAsync(): Promise<void> {
    if (!this.selectedBatch) {
      this.notifyError("A problem occurred while deleting the batch.");
      return;
    }

    const response = await this.docStagingBatchHttpHelper.deleteBatchAsync(this.selectedBatch.batchId);
    if (response.hasError) {
      this.notifyError(response.errorMessage);
    } else {
      this.notifySuccess("Batch successfully deleted.");
    }

    await this.loadBatchesAsync();
  }

  async promptAutoExtractOrFilePicker(batch: StagedFileBatch): Promise<void> {
    this.selectedBatch = batch;

    const files = this.selectedBatch.stagedFiles;
    if (files.length > 0 && files.every((f) => f.fileStatus === FileStatus.Converted)) {
      this.notifySuccess("This batch is already converted");
      return;
    }

    // Check setting & add alert & skip if unavailable
    try {
      this.AISettingEnabled = await AISettingIsEnabled();
    } catch (err: any) {
      this.AISettingEnabled = false;
      //this.notifyError("Error occurred retrieving AI:Enabled setting");
    }

    if (this.AISettingEnabled) {
      this.$bvModal.show("auto-extract-or-file-picker-dialog");
    } else {
      this.promptForMetadataFile();
      //this.notifyError("Metadata auto-extraction is currently disabled by the administrator");
    }
  }

  autoExtractHandler(): void {
    this.$bvModal.show("auto-extract-metadata-batch-dialog");
  }

  promptForMetadataFile(): void {
    this.$bvModal.show("metadata-file-dialog");
  }

  showApproveConfirm(batchToApprove: StagedFileBatch): void {
    this.confirmDialogTitle = ConfirmDialogTitles.Approve;
    this.selectedBatch = batchToApprove;
    this.$bvModal.show("confirm-batch-dialog");
  }

  showDeleteConfirm(batchToDelete: StagedFileBatch): void {
    this.confirmDialogTitle = ConfirmDialogTitles.Delete;
    this.selectedBatch = batchToDelete;
    this.$bvModal.show("confirm-batch-dialog");
  }

  showDeclineDialog(batchToDecline: StagedFileBatch): void {
    this.selectedBatch = batchToDecline;
    this.$bvModal.show("decline-batch-dialog");
  }

  showTemplateDownloadDialog(): void {
    this.$bvModal.show("template-download-dialog");
  }

  showUploadBatchDialog(): void {
    this.$bvModal.show("upload-batch-dialog");
  }

  notifyError(msg: string): void {
    this.errorMessage = msg;
    this.$bvToast.show("batch-error-toast");
  }

  notifySuccess(msg: string): void {
    this.successMessage = msg;
    this.$bvToast.show("batch-success-toast");
  }

  getBatchStatusInfo(batch: StagedFileBatch): BatchStatusInfo[] {
    if (batch.stagedFiles.length === 0) {
      return [
        {
          fileStatus: undefined,
          count: 0,
        },
      ];
    }

    const groups = groupBy(batch.stagedFiles, (f: StagedFile) => f.fileStatus);
    const tagInfo: BatchStatusInfo[] = [];
    for (const key in groups) {
      tagInfo.push({
        fileStatus: addSpaces(key) as FileStatus,
        count: groups[key].length,
      });
    }

    return tagInfo;
  }

  //#endregion

  async mounted(): Promise<void> {
    await this.loadBatchesAsync();
  }

  beforeDestroy(): void {
    // Reset Setting store state so that an updated value is retrieved next time AISettingIsEnabled is called
    this.$store.dispatch(`${StoreNames.Setting}/${SettingStoreActions.SET_AI_SETTING}`, null);
  }
}
</script>
