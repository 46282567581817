<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <div class="grid-col-1">
          <img
            :src="require('@/assets/img/icons/BiologicalAgent.svg')"
            class="heading-icon"
            role="img"
            alt=""
            aria-hidden="true"
          />
        </div>
        <div class="grid-col-11 center-y">
          <h1 class="font-heading-xl text-primary-dark mb-0 ml-3">Explore Hazards</h1>
        </div>
      </div>

      <div class="grid-row margin-top-2">
        <div class="grid-col">
          <p class="usa-prose mb-4">
            The HKMA is home to an ever-growing amount of hazard research data. To start exploring, select a hazard
            type, then search or browse for a hazard from the list below. Then click the view data button to the right
            of the hazard to view the results.
          </p>
          <p class="usa-prose mb-4">
            Looking for a specific hazard property, like lethal dose or concentration?
            <router-link :to="{ name: 'ExploreByProperties' }" class="usa-link">
              Explore hazard data by hazard properties.
            </router-link>
          </p>
        </div>
      </div>

      <div class="grid-row">
        <div class="grid-col">
          <div class="grid-row margin-top-2">
            <h1 class="font-heading-lg text-bold text-primary-dark margin-top-0 margin-bottom-0">
              Select a Hazard Type
            </h1>
          </div>

          <div class="grid-row">
            <div class="grid-col">
              <hazard-type-selection @hazard-type-changed="setHazardType" />
            </div>
          </div>
        </div>
      </div>

      <hr class="margin-y-4" />

      <hazard-search-and-browse
        v-show="isHazardTypeAndTaxonomyNotNull && taxonomyTree[0].value"
        :isSingleSelection="true"
        :selectedHazardType="selectedHazardType"
        @onViewData="viewData"
        @onTaxonomyTreeUpdated="updateTaxonomyTree"
      />
      <div v-show="isHazardTypeAndTaxonomyNotNull && !taxonomyTree[0].value">
        <p class="usa-prose text-bold">No data values available</p>
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { ExplorePageMixin } from "./ExplorePageMixin";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import { BreadcrumbPathItem } from "@/dataModel/interfaces";
import HazardSearchAndBrowse from "./HazardSearchAndBrowse.vue";
import { FilterNodeInformation, Category } from "@/dataModel";
import store from "@/store";
import StoreNames from "@/constants/store/StoreNames";
import { DataCollectionStoreActions } from "@/constants/store/dataCollection/dataCollectionStoreConstants";

@Component({
  components: {
    Breadcrumb,
    HazardSearchAndBrowse,
  },
})
export default class ExploreByHazardsPage extends Mixins(ExplorePageMixin) {
  path: BreadcrumbPathItem[] = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Explore Hazard Data",
      url: "/hazard-data",
    },
    {
      text: "Explore by Hazards",
    },
  ];

  get isHazardTypeAndTaxonomyNotNull(): boolean {
    return this.selectedHazardType != null && this.taxonomyTree != null;
  }

  viewData(selectedHazards: FilterNodeInformation[]) {
    this.setDataCollectionStoreState(selectedHazards);
    this.$router.push({ name: "DataByHazards" });
  }

  setDataCollectionStoreState(selectedHazards: FilterNodeInformation[]): void {
    const hazards: Category[] = selectedHazards.map((node) => node.value);
    store.dispatch(`${StoreNames.DataCollection}/${DataCollectionStoreActions.SET_SELECTED_HAZARDS}`, hazards);
    store.dispatch(`${StoreNames.DataCollection}/${DataCollectionStoreActions.SET_SELECTED_HAZARD_PROPERTY}`, null);
    store.dispatch(
      `${StoreNames.DataCollection}/${DataCollectionStoreActions.SET_SELECTED_HAZARD_TYPE}`,
      this.selectedHazardType,
    );
  }
}
</script>

<style lang="scss" scoped></style>
