<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em mb-5">
        <div class="grid-col">
          <div class="display-flex flex-column flex-align-end">
            <div>
              <button class="usa-button usa-button--outline" @click="showConfirmDeleteDataCollectionDialog">
                Delete Collection
              </button>
              <button class="usa-button mr-0" @click="viewEditDataCollection">Edit Citation</button>
            </div>
          </div>
        </div>
      </div>

      <div class="grid-row pb-2 mb-5">
        <div class="grid-col">
          <data-collection-metadata :dataCollection="dataCollection" />
        </div>
      </div>

      <div class="grid-row">
        <div class="grid-col">
          <h3 class="font-heading-lg text-primary-dark mb-0">Data Values</h3>
        </div>
      </div>

      <div id="data-value-section">
        <div class="grid-row">
          <div class="grid-col" v-show="collectionContainsDataValues">
            <label class="usa-label" for="property-options">Filter by Property</label>
            <select class="usa-select" name="property-options" id="property-options" v-model="propertyFilterId">
              <option value>All Properties</option>
              <option v-for="property in dataCollection.properties" :key="property.id" :value="property.id">
                {{ property.name }}
              </option>
            </select>
          </div>
          <div class="grid-col align-bottom-right">
            <button v-show="selectedDataValues.length == 1" class="usa-button" @click="viewAddValueFromMetadata">
              New Value from Metadata
            </button>
            <button class="usa-button mr-0" @click="viewAddDataValue(id)">Add Data Values</button>
          </div>
        </div>

        <div class="grid-row data-table-container" v-show="collectionContainsDataValues">
          <div class="grid-col">
            <data-value-table
              :dataCollection="dataCollection"
              :propertyFilterId="propertyFilterId"
              :allCategories="allCategories"
              @selected-data-values-changed="selectedDataValuesChanged"
              @edit-data-value="viewEditDataValue"
              @delete-data-values="showConfirmDeleteDataValueDialog"
              @change-publication-status="showUpdatePublicationStatusDialog"
              @change-data-value-status="showUpdateDataValueStatusDialog"
            />
          </div>
        </div>
      </div>
      <div v-show="!collectionContainsDataValues" class="grid-row mt-3">
        <div class="grid-col">
          <p class="usa-prose">
            When adding individual data values to this collection, ensure each value has the accompanying metadata
            included. For values with similar metadata, you can copy a selected value and adjust only the discreet
            values without having to input the metadata a second time.
          </p>
        </div>
      </div>
    </section>
    <success-dialog :message="successDialogMessage" />
    <confirm-delete-data-value-dialog
      id="delete-data-value-dialog"
      @confirm="deleteDataValues"
      :selectedDataValues="selectedDataValues"
    />
    <confirm-delete-data-collection-dialog @confirm="deleteDataCollection" />
    <update-data-value-status-dialog
      id="update-status-dialog"
      @confirm="bulkUpdateDataValueStatus($event)"
      :selectedDataValues="selectedDataValues"
    />
    <update-publication-status-dialog
      id="update-publication-dialog"
      @confirm="bulkUpdateDataValueStatus(undefined, $event)"
      :selectedDataValues="selectedDataValues"
    />
  </main>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import DataCollectionMetadata from "../DataCollectionDetails/DataCollectionDetailsMetadata.vue";
import DataValueTable from "../DataCollectionDetails/DataValueTable.vue";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import { BreadcrumbPathItem } from "@/dataModel/interfaces";
import DataSource from "@/dataModel/hazardData/dataSource";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import HazardDataHttpHelper from "@/components/resources/hazardDataHttpHelper";
import HazardDataValue from "@/dataModel/hazardData/hazardDataValue";
import CategoryHttpHelper from "@/components/resources/categoryHttpHelper";
import { Category } from "@/dataModel";
import store from "@/store";
import StoreNames from "@/constants/store/StoreNames";
import { DataCollectionStoreActions } from "@/constants/store/dataCollection/dataCollectionStoreConstants";
import ConfirmDeleteDataValueDialog from "./ConfirmDeleteDataValueDialog.vue";
import ConfirmDeleteDataCollectionDialog from "./ConfirmDeleteCollectionDialog.vue";
import UpdateDataValueStatusDialog from "./UpdateDataValueStatusDialog.vue";
import UpdatePublicationStatusDialog from "./UpdatePublicationStatusDialog.vue";
import HazardDataAdminHttpHelper from "@/components/resources/hazardDataAdminHttpHelper";
import { HazardDataStatus } from "@/constants/HazardDataStatus";
import BulkUpdateDataValueStatusRequest from "@/dataModel/hazardData/bulkUpdateDataValueStatusRequest";
import SuccessDialog from "@/components/common/SuccessDialog.vue";

@Component({
  components: {
    Breadcrumb,
    DataCollectionMetadata,
    DataValueTable,
    ConfirmDeleteDataValueDialog,
    ConfirmDeleteDataCollectionDialog,
    UpdateDataValueStatusDialog,
    UpdatePublicationStatusDialog,
    SuccessDialog,
  },
})
export default class DataCollectionDetailsPage extends Vue {
  dataCollection: DataSource = new DataSource();
  selectedDataValues: HazardDataValue[] = [];
  propertyFilterId: string = "";
  allCategories: Category[] = [];
  successDialogMessage = "";

  @Prop({ required: true }) id!: string;
  @Prop() readonly saveSuccess: boolean | undefined;
  @Prop() readonly createSuccess: boolean | undefined;

  private hazardDataAdminHttpHelper = container.get<HazardDataAdminHttpHelper>(serviceTypes.HazardDataAdminHttpHelper);
  private hazardDataHttpHelper = container.get<HazardDataHttpHelper>(serviceTypes.HazardDataHttpHelper);
  private categoryHttpHelper = container.get<CategoryHttpHelper>(serviceTypes.CategoryHttpHelper);

  path: BreadcrumbPathItem[] = [
    {
      text: "Administration",
    },
    {
      text: "Data Collection Management",
      url: "/data-collection-management",
    },
  ];

  get selectedDataValueIds(): string[] {
    return this.selectedDataValues.map((dv) => dv.id);
  }

  addTitleToBreadcrumbPath(): void {
    this.path.push({
      text: this.dataCollection.titleClassificationMarking + " " + this.dataCollection.title,
    });
  }

  async getDataCollection(id: string): Promise<void> {
    try {
      const response = await this.hazardDataHttpHelper.getSourceWithDetails(id);
      this.$set(this, "dataCollection", response);
      this.selectedDataValues.splice(0);
    } catch (error) {
      this.$router.push({ name: "DataCollectionManagement", params: { errorLoadingDetailsPage: "true" } });
    }
  }

  async getAllCategories(): Promise<void> {
    const response = await this.categoryHttpHelper.getAllCategories();
    this.allCategories = response;
  }

  selectedDataValuesChanged(selectedValues: HazardDataValue[]): void {
    this.selectedDataValues = selectedValues;
  }

  created(): void {
    this.initialize();
  }

  mounted(): void {
    if (this.saveSuccess) {
      this.showSuccessDialog("Value saved successfully");
      this.$router.replace({
        name: "DataCollectionDetails",
        query: {
          id: this.id,
        },
      });
    }
    if (this.createSuccess) {
      this.showSuccessDialog("Collection created successfully");
      this.$router.replace({
        name: "DataCollectionDetails",
        query: {
          id: this.id,
        },
      });
    }
  }

  async initialize(): Promise<void> {
    await this.getDataCollection(this.id);
    await this.getAllCategories();
    this.addTitleToBreadcrumbPath();
  }

  get collectionContainsDataValues(): boolean {
    return (
      this.dataCollection.properties != null &&
      this.dataCollection.properties != undefined &&
      this.dataCollection.properties.length != 0
    );
  }

  viewEditDataCollection(): void {
    store.dispatch(
      `${StoreNames.DataCollection}/${DataCollectionStoreActions.SET_SELECTED_DATA_COLLECTION}`,
      this.dataCollection,
    );

    this.$router.push({ name: "EditDataCollection", query: { id: this.id } });
  }

  viewAddDataValue(id: string): void {
    store.dispatch(
      `${StoreNames.DataCollection}/${DataCollectionStoreActions.SET_SELECTED_DATA_COLLECTION}`,
      this.dataCollection,
    );
    store.dispatch(`${StoreNames.DataCollection}/${DataCollectionStoreActions.SET_SELECTED_DATA_VALUE}`, null);
    this.$router.push({ name: "AddEditDataValue", query: { id: id } });
  }

  viewEditDataValue(selectedDataValue: HazardDataValue): void {
    store.dispatch(
      `${StoreNames.DataCollection}/${DataCollectionStoreActions.SET_SELECTED_DATA_COLLECTION}`,
      this.dataCollection,
    );
    store.dispatch(
      `${StoreNames.DataCollection}/${DataCollectionStoreActions.SET_SELECTED_DATA_VALUE}`,
      selectedDataValue,
    );
    this.$router.push({ name: "AddEditDataValue", query: { id: this.id } });
  }

  viewAddValueFromMetadata(): void {
    const selectedDataValue = this.selectedDataValues[0];

    store.dispatch(
      `${StoreNames.DataCollection}/${DataCollectionStoreActions.SET_SELECTED_DATA_COLLECTION}`,
      this.dataCollection,
    );
    store.dispatch(
      `${StoreNames.DataCollection}/${DataCollectionStoreActions.SET_SELECTED_DATA_VALUE}`,
      selectedDataValue,
    );
    this.$router.push({ name: "AddEditDataValue", query: { id: this.id, isNewValueFromMetadata: "true" } });
  }

  async deleteDataCollection(): Promise<void> {
    await this.hazardDataAdminHttpHelper.deleteSource(this.dataCollection.id);
    this.$router.push({ name: "DataCollectionManagement", params: { collectionDeleted: "true" } });
  }

  async deleteDataValues(): Promise<void> {
    await this.hazardDataAdminHttpHelper.deleteDataValues(this.selectedDataValueIds);
    const message = this.getDataValueDialogMessage("deleted");
    this.showSuccessDialog(message);
    await this.getDataCollection(this.dataCollection.id);
  }

  async bulkUpdateDataValueStatus(status?: HazardDataStatus, isPublished?: boolean): Promise<void> {
    const request = new BulkUpdateDataValueStatusRequest(
      this.selectedDataValueIds,
      status ?? HazardDataStatus.Approved,
    );
    const isApproved = request.status === HazardDataStatus.Approved;
    request.isPublished = isApproved ? isPublished : false;

    await this.hazardDataAdminHttpHelper.bulkUpdateDataValueStatusAsync(request);
    const message =
      isApproved && isPublished !== undefined
        ? this.getDataValueDialogMessage(request.isPublished ? "published" : "unpublished")
        : this.getDataValueDialogMessage(request.status.toLowerCase());
    this.showSuccessDialog(message);
    await this.getDataCollection(this.dataCollection.id);
  }

  getDataValueDialogMessage(result: string): string {
    return `Value${this.selectedDataValues.length > 1 ? "s" : ""} successfully ${result}`;
  }

  showConfirmDeleteDataValueDialog(): void {
    this.$bvModal.show("delete-data-value-dialog");
  }

  showConfirmDeleteDataCollectionDialog(): void {
    this.$bvModal.show("delete-data-collection-dialog");
  }

  showUpdatePublicationStatusDialog(): void {
    this.$bvModal.show("update-publication-dialog");
  }

  showUpdateDataValueStatusDialog(): void {
    this.$bvModal.show("update-status-dialog");
  }

  showSuccessDialog(message: string): void {
    this.successDialogMessage = message;
    this.$bvModal.show("success-dialog");
  }
}
</script>

<style scoped lang="scss">
.align-bottom-right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.data-table-container {
  margin-top: 1.25rem;
}

#property-options {
  width: 60%;
}
</style>
