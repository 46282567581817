import { HazardType } from "@/constants/HazardType";
import { DataCollectionStoreMutations } from "@/constants/store/dataCollection/dataCollectionStoreConstants";
import { Category } from "@/dataModel";
import DataSource from "@/dataModel/hazardData/dataSource";
import HazardDataValue from "@/dataModel/hazardData/hazardDataValue";
import HazardPropertyDetails from "@/dataModel/hazardData/hazardPropertyDetails";
import { DataCollectionState } from "@/typings/store/states/DataCollectionStoreState";

export default {
  [DataCollectionStoreMutations.SET_SELECTED_DATA_COLLECTION](state: DataCollectionState, dataCollection: DataSource) {
    state.dataCollection = dataCollection;
  },
  [DataCollectionStoreMutations.SET_SELECTED_DATA_VALUE](state: DataCollectionState, dataValue: HazardDataValue) {
    state.dataValue = dataValue;
  },
  [DataCollectionStoreMutations.SET_SELECTED_HAZARDS](state: DataCollectionState, hazards: Category[]) {
    state.hazards = hazards;
  },
  [DataCollectionStoreMutations.SET_SELECTED_HAZARD_PROPERTY](
    state: DataCollectionState,
    hazardProperty: HazardPropertyDetails,
  ) {
    state.hazardProperty = hazardProperty;
  },
  [DataCollectionStoreMutations.SET_SELECTED_HAZARD_TYPE](state: DataCollectionState, hazardType: HazardType) {
    state.hazardType = hazardType;
  },
};
