<template>
  <div v-if="data.length" class="padding-x-1">
    <plotly :data="chartData" :layout="layout" :id="id" :staticPlot="true" />

    <div ref="slider" class="margin-top-105 margin-bottom-3" />

    <div class="usa-memorable-date justify-content-between margin-bottom-5">
      <div class="usa-form-group usa-form-group--year">
        <label class="usa-label" for="from">From</label>
        <input
          class="usa-input"
          id="from"
          name="from"
          type="text"
          minlength="4"
          maxlength="4"
          pattern="[0-9]*"
          inputmode="numeric"
          @input="changeSliderValues($event, 0)"
          @blur="onBlur(0)"
          @keypress="onKeypress"
          :value="values[0]"
          :aria-invalid="!isValidYear(values[0])"
        />
      </div>

      <div class="usa-form-group usa-form-group--year">
        <label class="usa-label" for="to">To</label>
        <input
          class="usa-input"
          id="to"
          name="to"
          type="text"
          minlength="4"
          maxlength="4"
          pattern="[0-9]*"
          inputmode="numeric"
          @input="changeSliderValues($event, 1)"
          @blur="onBlur(1)"
          @keypress="onKeypress"
          :value="values[1]"
          aria-describedby="to-error-message"
          :aria-invalid="!isValidYear(values[1])"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import noUiSlider, { target } from "nouislider";
import "nouislider/dist/nouislider.min.css";
import { v4 as uuidv4 } from "uuid";
import { Plotly } from "vue-plotly";
import { Data, Layout } from "plotly.js";
import { UserSearchStoreGetters } from "@/constants/store/userSearch/userSearchStoreConstants";
import StoreNames from "@/constants/store/StoreNames";
import store from "@/store";
import { UserSearchParameters } from "@/dataModel";
import { YearCount } from "@/dataModel/interfaces";

/** Inspired by the vue-histogram-slider library: https://github.com/oguzhaninan/vue-histogram-slider */
@Component({ components: { Plotly } })
export default class DateSlider extends Vue {
  @Prop({ required: true }) data!: YearCount[];

  $refs!: {
    slider: target;
  };

  id = uuidv4();
  /** First value is 'from', second value is 'to' */
  values: number[] = [];

  get chartData(): Data[] {
    const x: number[] = [];
    const y: number[] = [];
    const colors: string[] = [];

    this.data.forEach(({ year, count }) => {
      x.push(year);
      y.push(count);
      const color = year >= this.values[0] && year <= this.values[1] ? "#005ea2" : "#f0f0f0";
      colors.push(color);
    });

    return [
      {
        x,
        y,
        type: "bar",
        marker: {
          color: colors,
        },
      },
    ];
  }

  get min(): number {
    return this.data[0].year;
  }

  get max(): number {
    return this.data[this.data.length - 1].year;
  }

  get layout(): Partial<Layout> {
    return {
      autosize: false,
      bargap: 0.4,
      height: 90,
      margin: {
        l: 0,
        r: 0,
        b: 0,
        t: 0,
        pad: 0,
      },
      width: 226,
      xaxis: {
        range: [this.min, this.max],
        showgrid: false,
        visible: false,
      },
      yaxis: {
        showgrid: false,
        visible: false,
      },
    };
  }

  /** Checks if year values are sequential or equivalent */
  get fromIsBeforeOrSameAsTo(): boolean {
    return this.values[1] >= this.values[0];
  }

  /** Determines if 'from' and 'to' are both 4 digit numbers */
  get yearsAreFourDigits(): boolean {
    return this.values.every((year) => year.toString().length === 4);
  }

  changeSliderValues(event: KeyboardEvent, index: number): void {
    const value = Number((event.target as HTMLInputElement).value);
    this.$set(this.values, index, value);

    if (this.yearsAreFourDigits) {
      if (!this.fromIsBeforeOrSameAsTo) {
        /* if 'from' is after 'to', set 'to' = 'from'
         * OR
         * if 'to' is before 'from', set 'from' = 'to'
         */
        this.$set(this.values, index ? 0 : 1, this.values[index]);
      }

      this.$refs.slider.noUiSlider?.set(this.values);
      this.$emit("datesChanged", this.values);
    }
  }

  /** Function for merging overlapping tooltips https://refreshless.com/nouislider/examples/#section-merging-tooltips */
  mergeTooltips(slider: target, threshold: number, separator: string): void {
    const tooltips = slider.noUiSlider?.getTooltips() ?? [];
    const origins = slider.noUiSlider?.getOrigins() ?? [];

    // Move tooltips into the origin element.
    Object.values(tooltips).forEach((tooltip, index) => {
      if (tooltip) {
        origins[index].appendChild(tooltip);
      }
    });

    slider.noUiSlider?.on("update", (values: (string | number)[], _handle, _unencoded, _tap, positions: number[]) => {
      let pools: number[][] = [[]];
      let poolPositions: number[][] = [[]];
      let poolValues: string[][] = [[]];
      let atPool = 0;

      // Assign the first tooltip to the first pool, if the tooltip is configured
      if (tooltips[0]) {
        pools[0][0] = 0;
        poolPositions[0][0] = positions[0];
        poolValues[0][0] = this.formatTooltip(values[0]);
      }

      for (let i = 1; i < positions.length; i++) {
        if (!tooltips[i] || positions[i] - positions[i - 1] > threshold) {
          atPool++;
          pools[atPool] = [];
          poolValues[atPool] = [];
          poolPositions[atPool] = [];
        }

        if (tooltips[i]) {
          pools[atPool].push(i);
          poolValues[atPool].push(this.formatTooltip(values[i]));
          poolPositions[atPool].push(positions[i]);
        }
      }

      pools.forEach(function (pool, poolIndex) {
        const handlesInPool = pool.length;

        for (let j = 0; j < handlesInPool; j++) {
          const tooltip = tooltips[pool[j]];
          if (!tooltip) {
            continue;
          }

          if (j === handlesInPool - 1) {
            let offset = 0;

            poolPositions[poolIndex].forEach(function (value) {
              offset += 1000 - value;
            });

            const direction = "right";
            const last = handlesInPool - 1;
            const lastOffset = 1000 - poolPositions[poolIndex][last];
            offset = offset / handlesInPool - lastOffset;

            // Center this tooltip over the affected handles
            tooltip.innerText = poolValues[poolIndex].filter((val, i, arr) => arr.indexOf(val) === i).join(separator);
            tooltip.style.display = "block";
            tooltip.style[direction] = offset + "%";
          } else {
            // Hide this tooltip
            tooltip.style.display = "none";
          }
        }
      });
    });
  }

  formatTooltip(value: number | string): string {
    if (typeof value === "number") {
      value = value.toString();
    }

    return value.split(".")[0];
  }

  initSlider(): void {
    // create slider on slider ref
    noUiSlider.create(this.$refs.slider, {
      connect: true,
      range: {
        min: this.min,
        max: this.max,
      },
      start: this.values,
      step: 1,
      tooltips: true,
      handleAttributes: [
        { "aria-label": "date range slider handle from" },
        { "aria-label": "date range slider handle to" },
      ],
    });

    // update from/to when slider changes
    this.$refs.slider.noUiSlider?.on("update", (values: (string | number)[]) => {
      this.values = values.map((v) => parseInt(`${v}`));
    });

    this.$refs.slider.noUiSlider?.on("change", () => {
      this.$emit("datesChanged", this.values);
    });

    this.mergeTooltips(this.$refs.slider, 18, " - ");
  }

  isValidYear(year: number): boolean {
    return year.toString().length === 4;
  }

  /** Get startYear and endYear from vuex store */
  getYearState(): (Date | undefined)[] {
    const { startYear, endYear }: UserSearchParameters =
      store.getters[`${StoreNames.UserSearch}/${UserSearchStoreGetters.GET_SEARCH_PARAMETERS}`] || {};

    return [startYear, endYear];
  }

  /** If needed, sets input value back to last valid value */
  onBlur(index: number): void {
    const yearInputsValid = this.yearsAreFourDigits && this.fromIsBeforeOrSameAsTo;
    if (!yearInputsValid && this.$refs.slider?.noUiSlider) {
      // the true returns the values as numbers
      const sliderValues = this.$refs.slider.noUiSlider.get(true);
      this.$set(this.values, index, sliderValues[index]);
    }
  }

  /** Only allow numeric inputs or backspace */
  onKeypress(event: KeyboardEvent): void {
    if (!/\d|Backspace/.test(event.key)) {
      event.preventDefault();
    }
  }

  /** Sets values to min/max */
  resetValues(): void {
    this.values.splice(0, this.values.length, ...[this.min, this.max]);
    this.$refs.slider.noUiSlider?.set(this.values);
    this.$emit("datesChanged");
  }

  @Watch("data")
  updateSliderRange(): void {
    const [startYear, endYear] = this.getYearState().map((d) => d?.getFullYear());
    if (startYear !== this.values[0]) {
      this.values[0] = startYear ?? this.min;
    }
    if (endYear !== this.values[1]) {
      this.values[1] = endYear ?? this.max;
    }

    this.$refs.slider.noUiSlider?.destroy();
    this.initSlider();
  }

  created(): void {
    let startYear: Date | undefined;
    let endYear: Date | undefined;

    const useSavedParams: boolean =
      store.getters[`${StoreNames.UserSearch}/${UserSearchStoreGetters.GET_USE_SAVED_PARAMS}`];

    if (useSavedParams) {
      [startYear, endYear] = this.getYearState();
    }

    this.values = [
      startYear ? new Date(startYear).getFullYear() : this.min,
      endYear ? new Date(endYear).getFullYear() : this.max,
    ];
  }

  mounted(): void {
    this.initSlider();
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/uswds/scss/uswds.scss";

:deep() {
  .noUi-target {
    height: 5px;
    border: 0;
    box-shadow: none;
  }
  .noUi-connects {
    background: color("base-lightest");
    border-radius: 0;
  }
  .noUi-connect {
    background: color("primary");
  }
  .noUi-handle {
    background: color("primary");
    border: 0;
    border-radius: 0;
    box-shadow: none;
    height: 13px;
    width: 13px;
    right: -6px;
    top: -4px;

    &::before,
    &::after {
      display: none;
    }

    &:hover,
    &:focus {
      background: color("primary-dark");
    }
  }
  .noUi-tooltip {
    background: transparent;
    border: 0;
    font-family: family("body");
    font-weight: font-weight("bold");
    font-size: size("body", "sm");
    color: color("base-darkest");
    bottom: -45px !important;
    z-index: -1;
  }
}
</style>
