import { DocumentStagingState } from "@/typings/store/states/DocumentStagingState";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { Module } from "vuex";

const state: DocumentStagingState = {
  summarizedBatchId: "",
  summarizedBatchSiteDocuments: [],
};

export const DocumentStagingModule: Module<DocumentStagingState, any> = {
  state,
  getters,
  mutations,
  actions,
};
