<template>
  <div>
    <basic-dialog
      id="confirm-unsubscribe-dialog"
      title="Unsubscribe"
      primaryButton="Confirm"
      secondaryButton="Cancel"
      :primaryClick="unsubscribe"
      :secondaryClick="cancel"
    >
      <p class="usa-prose mb-0">Are you sure you want to unsubscribe?</p>
    </basic-dialog>
    <success-toast id="unsubscribe-toast" message="You have successfully unsubscribed." />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";

@Component({
  components: {
    BasicDialog,
  },
})
export default class UnsubscribeDialog extends Vue {
  cancel(): void {
    this.$bvModal.hide("confirm-unsubscribe-dialog");
  }

  unsubscribe(): void {
    this.$emit("confirm");
    this.$bvModal.hide("confirm-unsubscribe-dialog");
  }
}
</script>

<style scoped lang="scss">
.usa-form {
  width: 100%;
  max-width: 100%;
}
</style>
