import Vue from "vue";
import moment from "moment";

Vue.filter("formatDate", function (date: Date, format: string = "MM-DD-YYYY", displayAsUtc: boolean = false): string {
  let processedDate = moment(date);
  if (!processedDate.isValid()) {
    return "";
  }
  if (displayAsUtc) {
    processedDate = processedDate.utc();
  }

  return processedDate.format(format);
});
