<template>
  <basic-dialog
    id="update-status-dialog"
    title="Change Status"
    primaryButton="Confirm"
    secondaryButton="Cancel"
    :primaryClick="confirm"
    :secondaryClick="cancel"
    :isDisabled="!isFormValid"
  >
    <form class="usa-form mt-4">
      <fieldset class="usa-fieldset">
        <legend class="usa-legend usa-legend">Data Value Status</legend>
        <div class="usa-radio">
          <input
            class="usa-radio__input"
            id="approve-input"
            type="radio"
            name="status"
            :value="HazardDataStatus.Approved"
            v-model="approvalStatus"
          />
          <label class="usa-radio__label" for="approve-input">Approve</label>
        </div>
        <div class="usa-radio">
          <input
            class="usa-radio__input"
            id="deny-input"
            type="radio"
            name="status"
            :value="HazardDataStatus.Declined"
            v-model="approvalStatus"
          />
          <label class="usa-radio__label" for="deny-input">Decline</label>
        </div>
      </fieldset>
    </form>

    <div class="usa-alert usa-alert--info usa-alert--slim margin-top-5" v-if="isNullOrEmpty(this.selectedDataValues)">
      <div class="usa-alert__body">
        <p class="usa-alert__text">At least 1 data value must be selected from the table.</p>
      </div>
    </div>
    <div class="usa-alert usa-alert--info usa-alert--slim margin-top-5" v-if="hasUnpublishedWarning">
      <div class="usa-alert__body">
        <p class="usa-alert__text">
          <span class="text-bold mr-1">Warning:</span>
          Declining a published data value will unpublish it.
        </p>
      </div>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import HazardDataValue from "@/dataModel/hazardData/hazardDataValue";
import { HazardDataStatus } from "@/constants/HazardDataStatus";
import { isNullOrEmpty } from "@/services/utils";

@Component({
  components: {
    BasicDialog,
  },
})
export default class UpdateDataValueStatusDialog extends Vue {
  approvalStatus: HazardDataStatus | null = null;
  HazardDataStatus = HazardDataStatus;

  @Prop() selectedDataValues!: HazardDataValue[];

  cancel(): void {
    this.$bvModal.hide("update-status-dialog");
  }

  confirm(): void {
    if (this.isFormValid) {
      this.$emit("confirm", this.approvalStatus);
      this.$bvModal.hide("update-status-dialog");
    }
  }

  mounted(): void {
    this.intializeDialogOnOpen();
  }

  intializeDialogOnOpen(): void {
    this.$root.$on("bv::modal::show", () => {
      this.clearForm();
    });
  }

  clearForm(): void {
    this.approvalStatus = null;
  }

  get isFormValid(): boolean {
    return this.approvalStatus != null && !isNullOrEmpty(this.selectedDataValues);
  }

  get hasUnpublishedWarning(): boolean {
    return (
      this.approvalStatus == HazardDataStatus.Declined && this.selectedDataValues.some((dv) => dv.isPublished == true)
    );
  }

  isNullOrEmpty(input: any): boolean {
    return isNullOrEmpty(input);
  }
}
</script>

<style scoped lang="scss"></style>
