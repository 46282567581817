import { Module, Mutation, Action, VuexModule } from "vuex-module-decorators";
import DocumentType from "@/constants/DocumentTypeWithDisplayName";
import { DocumentManagementStoreMutations } from "@/constants/store/siteDocumentManagement/documentManagementStoreConstants";
import SiteDocumentHttpHelper from "@/components/resources/siteDocumentHttpHelper";
import serviceTypes from "@/dependencyInjection/types";
import container from "@/dependencyInjection/config";
import StoreNames from "@/constants/store/StoreNames";

@Module({ namespaced: true, name: StoreNames.DocumentModule })
export default class SiteDocumentManagementModule extends VuexModule {
  documentTypes: DocumentType[] = [];

  @Mutation
  [DocumentManagementStoreMutations.UPDATE_DOCUMENT_TYPES](types: Record<string, string>) {
    this.documentTypes = Object.keys(types)
      .sort()
      .map((key) => new DocumentType(key, types[key]));
  }

  @Action
  async fetchDocumentTypes() {
    if (this.documentTypes.length > 0) {
      return;
    }

    const httpHelper = container.get<SiteDocumentHttpHelper>(serviceTypes.SiteDocumentHttpHelper);
    const types = await httpHelper.getDocumentTypes();
    this.context.commit(DocumentManagementStoreMutations.UPDATE_DOCUMENT_TYPES, types);
  }
}
