export enum AuthStoreActions {
  SET_USER = "SET_USER",
  INITIALIZE_PAGE_ACCESS = "INITIALIZE_PAGE_ACCESS",
  LOGOUT = "LOGOUT",
  SET_SECONDS_IDLE = "SET_SECONDS_IDLE",
  SET_SHOWING_WARNING_DIALOG = "SET_SHOWING_WARNING_DIALOG"
}
export enum AuthStoreGetters {
  GET_USER = "GET_USER",
  GET_ACCESS = "GET_ACCESS",
  IS_AUTHORIZED = "IS_AUTHORIZED",
  GET_AUTH = "GET_AUTH",
}
export enum AuthStoreMutations {
  SET_USER = "SET_USER_STATE",
  SET_PAGE_ACCESS = "SET_PAGE_ACCESS",
  SET_HAS_ADMIN_DOC_ACCESS = "SET_HAS_ADMIN_DOC_ACCESS",
  SET_HAS_ADMIN_ROLE_ACCESS = "SET_HAS_ADMIN_ROLE_ACCESS",
  SET_HAS_ADMIN_USER_REQUEST_ACCESS = "SET_HAS_ADMIN_USER_REQUEST_ACCESS",
  SET_HAS_USER_SEARCH_ACCESS = "SET_HAS_USER_SEARCH_ACCESS",
  SET_HAS_USER_REQUEST_ACCESS = "SET_HAS_USER_REQUEST_ACCESS",
  SET_HAS_USER_APP_ACCESS = "SET_HAS_USER_APP_ACCESS",
  SET_HAS_USER_DOC_ACCESS = "SET_HAS_USER_DOC_ACCESS",
  SET_HAS_ADMIN_USER_MANAGEMENT_ACCESS = "SET_HAS_ADMIN_USER_MANAGEMENT_ACCESS",
  SET_HAS_ADMIN_REPORT_ACCESS = "SET_HAS_ADMIN_REPORT_ACCESS",
  SET_DOCUMENT_APPROVE_ACCESS = "SET_DOCUMENT_APPROVE_ACCESS",
  SET_DOCUMENT_CREATE_UPLOAD_ACCESS = "SET_DOCUMENT_CREATE_UPLOAD_ACCESS",
  SET_DOCUMENT_EDIT_ACCESS = "SET_DOCUMENT_EDIT_ACCESS",
  SET_CATEGORY_MANAGEMENT_ACCESS = "SET_CATEGORY_MANAGEMENT_ACCESS",
  SET_FEATURED_RESOURCE_MANAGEMENT_ACCESS = "SET_FEATURED_RESOURCE_MANAGEMENT_ACCESS",
  SET_DATA_COLLECTION_MANAGEMENT_ACCESS = "SET_DATA_COLLECTION_MANGEMENT_ACCESS",
  SET_HAS_USER_HAZARD_DATA_ACCESS = "SET_HAS_USER_HAZARD_DATA_ACCESS",
  RESET_AUTHENTICATION = "RESET_AUTHENTICATION",
  SET_USAGE_ANALYTICS_ACCESS = "SET_USAGE_ANALYTICS_ACCESS",
  SET_SECONDS_IDLE = "SET_SECONDS_IDLE",
  SET_SHOWING_WARNING_DIALOG = "SET_SHOWING_WARNING_DIALOG"
}
