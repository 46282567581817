import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import { AuthState } from "@/typings/store/states/AuthStoreState";

const state: AuthState = {
  user: null,
  access: {
    pageAccess: [],
    hasAdminDocumentAccess: false,
    hasAdminRolesAccess: false,
    hasAdminUserRequestAccess: false,
    hasUserSearchAccess: false,
    hasUserRequestAccess: false,
    hasUserApplicationAccess: false,
    hasUserDocumentsAccess: false,
    hasAdminUserManagementAccess: false,
    hasAdminReportAccess: false,
    hasDocumentApproveAccess: false,
    hasDocumentCreateUploadAccess: false,
    hasDocumentEditAccess: false,
    hasCategoryManagementAccess: false,
    hasFeaturedResourceManagementAccess: false,
    hasDataCollectionManagementAccess: false,
    hasUserHazardDataAccess: false,
    hasUsageAnalyticsAccess: false,
  },
  isAuthenticated: false,
  secondsIdle: 0,
  showingWarningDialog: false,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
