import { Component, Prop, Vue } from "vue-property-decorator";
import { SiteDocument } from "@/dataModel";
import DocumentType from "../constants/DocumentTypeWithDisplayName";
import serviceTypes from "@/dependencyInjection/types";
import container from "@/dependencyInjection/config";
import SiteDocumentHttpHelper from "@/components/resources/siteDocumentHttpHelper";
import { getModule } from "vuex-module-decorators";
import SiteDocumentManagementModule from "@/store/modules/siteDocumentManagementModule";
import store from "@/store";

@Component
export default class DocumentMixin extends Vue {
  @Prop({ required: false }) id!: string;

  searchTerm: string | undefined;
  siteDocument: SiteDocument | null = null;
  siteDocumentModule = getModule(SiteDocumentManagementModule, store);

  protected siteDocumentHttpHelper = container.get<SiteDocumentHttpHelper>(serviceTypes.SiteDocumentHttpHelper);

  getSearchUrl(): string {
    let url = "/search";
    if (this.searchTerm) {
      url += `?searchTerm=${this.searchTerm}`;
    }
    return url;
  }

  toDocumentDisplayName(typeValue: string): string {
    return this.documentTypes.filter((t) => t.value === typeValue)[0].displayName;
  }

  goToPreviousPage(): void {
    this.$router.go(-1);
  }

  prevRouteContainsUserSearch(prevRoute: string | null): boolean {
    return prevRoute != null && prevRoute == "UserSearch";
  }

  get documentTypes(): DocumentType[] {
    return this.siteDocumentModule.documentTypes;
  }

  async created() {
    await this.siteDocumentModule.fetchDocumentTypes();
  }
}
