<template>
  <basic-dialog
    id="save-changes-dialog"
    title="Save Changes"
    primaryButton="Confirm"
    secondaryButton="Cancel"
    :primaryClick="saveChanges"
    :secondaryClick="cancel"
    :isDisabled="hasError"
  >
    <p class="usa-prose">Are you sure you want to save these permissions?</p>
    <div class="usa-alert usa-alert--info usa-alert--slim margin-top-4" v-if="hasError">
      <div class="usa-alert__body">
        <p class="usa-alert__text">At least 1 permission must be selected.</p>
      </div>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";

@Component({
  components: {
    BasicDialog,
  },
})
export default class SaveChangesDialog extends Vue {
  @Prop(Boolean) readonly hasError: boolean | undefined;

  cancel() {
    this.$bvModal.hide("save-changes-dialog");
  }
  saveChanges() {
    this.$emit("confirm");
    this.$bvModal.hide("save-changes-dialog");
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.usa-prose {
  font-weight: bold;
  margin-top: 0.5rem;
}
</style>
