<template>
  <basic-dialog
    id="export-data-dialog"
    title="Export Data"
    primaryButton="Export"
    secondaryButton="Cancel"
    :primaryClick="exportData"
    :secondaryClick="cancelExport"
  >
    <fieldset class="padding-x-0 margin-top-05">
      <legend class="usa-legend margin-0 text-bold">Select an option for export</legend>
      <div class="usa-radio">
        <input class="usa-radio__input" id="all-columns" type="radio" v-model="includeAllColumns" :value="true" />
        <label class="usa-radio__label" for="all-columns"> Include all data columns </label>
      </div>
      <div class="usa-radio">
        <input class="usa-radio__input" id="selected-columns" type="radio" v-model="includeAllColumns" :value="false" />
        <label class="usa-radio__label" for="selected-columns"> Include only the selected data columns </label>
      </div>
    </fieldset>
    <fieldset class="padding-x-0 margin-top-3">
      <legend class="usa-legend margin-0 text-bold">Select a file type</legend>
      <div class="usa-radio">
        <input class="usa-radio__input" id="excel-type" type="radio" v-model="fileType" :value="fileTypes.Excel" />
        <label class="usa-radio__label" for="excel-type"> Excel Workbook </label>
      </div>
      <div class="usa-radio">
        <input class="usa-radio__input" id="csv-type" type="radio" v-model="fileType" :value="fileTypes.Csv" />
        <label class="usa-radio__label" for="csv-type"> CSV </label>
      </div>
    </fieldset>
  </basic-dialog>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import { ExportFileType } from "@/dataModel/hazardData/enums/ExportFileType";
import ExportDataArgs from "@/dataModel/hazardData/ExportDataArgs";

@Component({
  components: {
    BasicDialog,
  },
})
export default class ExportDataDialog extends Vue {
  includeAllColumns = false;
  fileTypes = ExportFileType;
  fileType: ExportFileType = this.fileTypes.Excel;

  exportData(): void {
    this.$bvModal.hide("export-data-dialog");

    const args: ExportDataArgs = { includeAllColumns: this.includeAllColumns, fileType: this.fileType };
    this.$emit("on-export-data", args);
  }

  cancelExport(): void {
    this.$bvModal.hide("export-data-dialog");
  }
}
</script>
