export enum FilterType {
  Category = 0,
  Classification = 1,
  Audience = 2,
  CopyrightRestriction = 3,
  None = 4,
  DocumentType = 5,
  DisseminationControl = 6,
  Publication = 7,
  Sci = 8,
  Year = 9
}