<template>
  <div>
    <p class="usa-prose margin-top-2 margin-bottom-3">
      The table below displays the current and upcoming documents and/or data views that will be featured on the
      homepage under the Featured Hazard Data section. They will be displayed in the order that is listed in the table.
      To change the ordering, drag and drop the featured document or data view by the hamburger icon near the left side
      of the row to the desired position in the table. To save your changes, click the save changes button that appears
      near the top right of the table.
    </p>

    <current-and-upcoming-table
      :tabView="featuredView"
      :currentFeaturedResources="allFeaturedData"
      :userHazardTypes="userHazardTypes"
      @edit-featured-resource="showEditFeaturedDataDialog"
      @remove-featured-resource="showRemoveFeaturedDataDialog"
      @on-save-display-order="saveDisplayOrderAsync"
      ref="featuredDataTable"
    />
    <p class="usa-prose">*Due to hazard type access permissions, some data views may not be allowed to be loaded.</p>

    <h1 class="font-heading-lg text-primary-dark text-bold margin-top-5">Select data or documents to feature</h1>
    <p class="usa-prose margin-top-2 margin-bottom-3">
      The table below displays saved data views that are available to feature. Click 'Add' to feature the data or
      document.
    </p>

    <!-- Pooled data views -->
    <h1 class="font-heading-md text-primary-dark">Saved Data Views</h1>
    <saved-data-views-table
      :pooledDataViews="pooledDataViews"
      :userHazardTypes="userHazardTypes"
      @add-featured-data-view="featureSelectedDataView"
      @remove-saved-data-view="showRemoveDataViewDialog"
    />

    <!-- Document search -->
    <h1 class="font-heading-md text-primary-dark margin-top-3 margin-bottom-2">Search All Documents</h1>
    <site-documents-section @add-featured-document="featureSelectedDocument" />

    <!-- Dialogs -->
    <featured-resource-dialog
      id="featured-data-dialog"
      @data-confirmed="featuredDataConfirmedAsync"
      :selectedFeaturedResource="selectedHazardData"
      :isAdding="isAddingFeaturedData"
      :isSelectionAlreadyFeatured="selectedResourceAlreadyFeatured"
      :featuredResourceType="featuredView"
    />
    <remove-featured-resource-dialog
      id="remove-featured-data-dialog"
      :tabView="featuredView"
      :selectedFeaturedResource="selectedHazardData"
      @remove-featured-data="removeFeaturedDataAsync"
    />
    <basic-dialog
      id="remove-data-view-dialog"
      title="Remove Saved Data View"
      :primaryButton="'Confirm'"
      :secondaryButton="'Cancel'"
      :primaryClick="removeSelectedDataViewAsync"
      :secondaryClick="cancelDataViewRemoval"
    >
      <div>
        <p>
          Are you sure you want to remove <strong>{{ selectedDataViewName }}</strong> from your saved data views?
        </p>
      </div>
    </basic-dialog>

    <!-- Toasts -->
    <success-toast :id="successToastId" :message="toastMessage" />
    <error-toast :id="errorToastId" :message="toastMessage" />
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { FeaturedResourcesMixin } from "../FeaturedDocumentManagement/FeaturedResourcesMixin";
import FeaturedHazardType from "@/constants/FeaturedHazardType";
import { FeaturedHazardData, SiteDocument, Actor } from "@/dataModel";
import { NewFeaturedHazardDataRequest, ModifyFeaturedHazardDataRequest } from "@/dataModel/requests";
import { PooledFeaturedDataView, FeaturedResource } from "@/dataModel/interfaces";
import SavedDataViewsTable from "./SavedDataViewsTable.vue";
import { FeaturedResourceAction } from "../Constants/FeaturedResourceAction";
import BasicDialog from "@/components/common/BasicDialog.vue";
import { HazardType } from "@/constants/HazardType";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import IAuthService from "@/services/interfaces/IAuthService";
import HazardTypeOption from "@/dataModel/hazardData/hazardTypeOption";

@Component({
  components: {
    SavedDataViewsTable,
    BasicDialog,
  },
})
export default class FeaturedHazardDataSection extends Mixins(FeaturedResourcesMixin) {
  selectedSiteDocument: SiteDocument = new SiteDocument();
  selectedHazardData: FeaturedHazardData = new FeaturedHazardData();
  selectedResourceAlreadyFeatured = false;
  allFeaturedData: FeaturedHazardData[] = [];
  pooledDataViews: PooledFeaturedDataView[] = [];
  selectedDataViewToRemove? = {} as PooledFeaturedDataView;
  isAddingFeaturedData: boolean = false;
  userHazardTypes: HazardType[] = [];

  private authService = container.get<IAuthService>(serviceTypes.AuthService);

  mounted(): void {
    this.featuredView = FeaturedHazardType.HazardData;
    this.refreshFeaturedDataAsync();
    this.setUserHazardTypePermissions();
  }

  async addFeaturedDataAsync(featuredData: NewFeaturedHazardDataRequest): Promise<void> {
    await this.featuredResourceHttpHelper.addFeaturedHazardDataAsync(featuredData);
    this.showResponseStatusDialog(FeaturedResourceAction.CREATE, featuredData.featuredHazardType);
    await this.refreshFeaturedDataAsync();
  }

  async editFeaturedDataAsync(featuredData: NewFeaturedHazardDataRequest): Promise<void> {
    const request = this.getEditFeaturedDataRequest(featuredData);
    await this.featuredResourceHttpHelper.updateFeaturedHazardDataAsync(request);
    this.showResponseStatusDialog(FeaturedResourceAction.UPDATE, featuredData.featuredHazardType);
    await this.refreshFeaturedDataAsync();
  }

  async removeFeaturedDataAsync(featuredData: FeaturedHazardData): Promise<void> {
    let request = this.getRemoveFeaturedDataRequest(featuredData);
    await this.featuredResourceHttpHelper.deleteFeaturedHazardDataAsync(request);
    this.showResponseStatusDialog(
      FeaturedResourceAction.DELETE,
      featuredData.featuredHazardType ?? FeaturedHazardType.HazardData,
    );
    await this.refreshFeaturedDataAsync();
  }

  getEditFeaturedDataRequest(newDataRequest: NewFeaturedHazardDataRequest): ModifyFeaturedHazardDataRequest {
    const request = new ModifyFeaturedHazardDataRequest();
    let featuredDataIndex = 0;

    request.featuredHazardData = this.allFeaturedData.map((data, index) => {
      if (
        data.knowledgeResourceId === newDataRequest.knowledgeResourceId ||
        data.pooledFeaturedDataViewId === newDataRequest.pooledFeaturedDataViewId
      ) {
        featuredDataIndex = index;
      }
      const newRequest = new NewFeaturedHazardDataRequest(
        data.featuredHazardDataId,
        data.knowledgeResourceId,
        data.pooledFeaturedDataViewId,
        index,
        data.startDate,
        data.endDate,
        data.featuredLengthType,
        data.featuredHazardType,
        data.customFeaturedLength,
      );

      return newRequest;
    });

    newDataRequest.displayOrder = featuredDataIndex;
    request.featuredHazardData.splice(featuredDataIndex, 1, newDataRequest);
    return request;
  }

  getRemoveFeaturedDataRequest(featuredDataToRemove: FeaturedHazardData): ModifyFeaturedHazardDataRequest {
    const request = new ModifyFeaturedHazardDataRequest();
    let featuredDataIndex = 0;
    request.featuredHazardData = this.allFeaturedData.map((data, index) => {
      if (data === featuredDataToRemove) {
        featuredDataIndex = index;
      }
      const newRequest = new NewFeaturedHazardDataRequest(
        data.featuredHazardDataId,
        data.knowledgeResourceId,
        data.pooledFeaturedDataViewId,
        index,
        data.startDate,
        data.endDate,
        data.featuredLengthType,
        data.featuredHazardType,
        data.customFeaturedLength,
      );
      newRequest.featuredHazardDataId = data.featuredHazardDataId;

      return newRequest;
    });
    request.featuredHazardData.splice(featuredDataIndex, 1);
    return request;
  }

  async featuredDataConfirmedAsync(featuredData: NewFeaturedHazardDataRequest): Promise<void> {
    if (this.isAddingFeaturedData) {
      await this.addFeaturedDataAsync(featuredData);
    } else {
      await this.editFeaturedDataAsync(featuredData);
    }
  }

  async refreshFeaturedDataAsync(): Promise<void> {
    this.allFeaturedData = await this.featuredResourceHttpHelper.getAllFeaturedHazardData();
    this.pooledDataViews = await this.featuredResourceHttpHelper.getPooledFeaturedDataViewsAsync();
  }

  async saveDisplayOrderAsync(newOrder: FeaturedResource[]): Promise<void> {
    // Construct modify request
    const modifyRequest = new ModifyFeaturedHazardDataRequest();
    modifyRequest.featuredHazardData = newOrder.map((res, index) => {
      const featuredData = res as FeaturedHazardData;
      return new NewFeaturedHazardDataRequest(
        featuredData.featuredHazardDataId,
        featuredData.knowledgeResourceId,
        featuredData.pooledFeaturedDataViewId,
        index,
        featuredData.startDate,
        featuredData.endDate,
        featuredData.featuredLengthType,
        featuredData.featuredHazardType,
        featuredData.customFeaturedLength,
      );
    });

    // Call API
    const response = await this.featuredResourceHttpHelper.updateFeaturedHazardDataAsync(modifyRequest);
    this.allFeaturedData = response;
    this.showResponseStatusDialog(FeaturedResourceAction.ORDER, FeaturedHazardType.HazardData);
  }

  featureSelectedDocument(document: SiteDocument) {
    const newFeaturedData: FeaturedHazardData = FeaturedHazardData.CreateFromDocument(document);
    this.showAddFeaturedResourceDialog(newFeaturedData);
  }

  featureSelectedDataView(dataView: PooledFeaturedDataView) {
    const newFeaturedData: FeaturedHazardData = FeaturedHazardData.CreateFromPooledDataView(dataView);
    this.showAddFeaturedResourceDialog(newFeaturedData);
  }

  async removeSelectedDataViewAsync() {
    const deletedView: PooledFeaturedDataView = await this.featuredResourceHttpHelper.deletePooledFeaturedDataViewAsync(
      this.selectedDataViewToRemove!.id,
    );
    if (deletedView && deletedView.deleteDateTime) {
      this.$bvModal.hide("remove-data-view-dialog");
      this.showSuccessToast("Saved data view removed successfully.");
      await this.refreshFeaturedDataAsync();
    } else {
      this.showErrorToast("A problem occurred removing the saved data view");
    }
  }

  get selectedDataViewName(): string {
    return this.selectedDataViewToRemove?.name ?? "";
  }
  //#region  Dialog methods

  showAddFeaturedResourceDialog(featuredData: FeaturedHazardData): void {
    this.isAddingFeaturedData = true;
    this.selectedHazardData = featuredData;
    this.selectedResourceAlreadyFeatured = this.checkResourceIsFeatured(featuredData);
    this.$nextTick(() => {
      this.$bvModal.show("featured-data-dialog");
    });
  }

  showEditFeaturedDataDialog(featuredData: FeaturedResource): void {
    this.isAddingFeaturedData = false;
    this.selectedHazardData = featuredData as FeaturedHazardData;
    this.$nextTick(() => {
      this.$bvModal.show("featured-data-dialog");
    });
  }

  showRemoveFeaturedDataDialog(featuredData: FeaturedResource): void {
    this.selectedHazardData = featuredData as FeaturedHazardData;
    this.$bvModal.show("remove-featured-data-dialog");
  }

  showRemoveDataViewDialog(selectedDataView: PooledFeaturedDataView) {
    this.selectedDataViewToRemove = selectedDataView;
    this.$bvModal.show("remove-data-view-dialog");
  }

  cancelDataViewRemoval() {
    this.selectedDataViewToRemove = undefined;
    this.$bvModal.hide("remove-data-view-dialog");
  }

  //#endregion

  checkResourceIsFeatured(data: FeaturedHazardData) {
    return this.allFeaturedData.some((d) => {
      const documentIsFeatured =
        data.hazardDocument && d.hazardDocument?.knowledgeResourceId === data.hazardDocument?.knowledgeResourceId;
      const dataViewIsFeatured =
        data.pooledFeaturedDataViewId && d.pooledFeaturedDataViewId === data.pooledFeaturedDataViewId;

      return documentIsFeatured || dataViewIsFeatured;
    });
  }

  setUserHazardTypePermissions(): void {
    const actor = Object.assign(new Actor(), this.authService.getUser().actor);
    const hazTypes: HazardTypeOption[] = actor.getAuthorizedHazardTypeOptions();
    this.userHazardTypes = hazTypes.map((option) => HazardType[option.name]);
  }
}
</script>

<style scoped lang="scss"></style>
