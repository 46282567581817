<template>
  <div class="usa-prose">
    <h2 class="font-heading-xl text-primary-dark text-bold margin-top-0 mb-4" id="application-header">
      PANTHR Applications
    </h2>
    <template v-if="isLoading">
      <spinner />
    </template>
    <template v-else>
      <template v-if="applications && applications.length > 0">
        <ul class="usa-card-group">
          <div class="grid-row" v-for="(app, index) in applications" :key="index">
            <application-card
              :name="app.displayName"
              :description="app.descriptionParagraphs[0]"
              :iconFileName="app.imagePath"
              :loginUrl="app.loginUrl"
            />
          </div>
        </ul>
      </template>
      <template v-else>
        <p>Coming soon</p>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Spinner from "@/components/common/Spinner.vue";
import ApplicationCard from "@/components/common/ApplicationCard.vue";
import { Application } from "@/dataModel";
import ApplicationHttpHelper from "@/components/resources/applicationHttpHelper";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";

@Component({
  components: {
    Spinner,
    ApplicationCard,
  },
})
export default class HomePageApplications extends Vue {
  isLoading: boolean = true;
  private applicationHttpHelper = container.get<ApplicationHttpHelper>(serviceTypes.ApplicationHttpHelper);

  applications: Application[] = [];

  mounted() {
    (async () => {
      try {
        this.applications = await this.applicationHttpHelper.getAllApplications();
      } finally {
        this.isLoading = false;
      }
    })();
    this.isLoading = false;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.usa-section--dark a:visited {
  color: #ffffff;
}

.usa-prose > p {
  max-width: none;
}

.app-text {
  width: 100%;
  margin-left: 0.5rem;
}

.app-list-item {
  max-width: none;
  display: flex;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
}

.app-list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
}

.app-logo {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.logo-container {
  padding: 5px;
  width: 60px;
  height: 60px;
  background-color: white;
  margin-right: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
