import {
  PermissionStoreActions,
  PermissionStoreMutations,
} from "@/constants/store/permission/permissionStoreConstants";
import { Classification, DisseminationControl, Sci, KnowledgeResourceAudience } from "@/dataModel";

export default {
  [PermissionStoreActions.SET_CLASSIFICATIONS](
    { commit }: { commit: Function },
    classifications: Classification[],
  ): void {
    commit(PermissionStoreMutations.SET_CLASSIFICATIONS, classifications);
  },

  [PermissionStoreActions.SET_DISSEMINATION_CONTROLS](
    { commit }: { commit: Function },
    disseminationControls: DisseminationControl[],
  ): void {
    commit(PermissionStoreMutations.SET_DISSEMINATION_CONTROLS, disseminationControls);
  },

  [PermissionStoreActions.SET_SCIS]({ commit }: { commit: Function }, scis: Sci[]): void {
    commit(PermissionStoreMutations.SET_SCIS, scis);
  },

  [PermissionStoreActions.SET_AUDIENCES](
    { commit }: { commit: Function },
    audiences: KnowledgeResourceAudience[],
  ): void {
    commit(PermissionStoreMutations.SET_AUDIENCES, audiences);
  },
};
