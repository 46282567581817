<template>
  <div>
    <p class="usa-prose margin-top-2 margin-bottom-3">
      The table below displays the current and upcoming documents that will be featured on the homepage under the
      Featured Documents section. They will be displayed in the order that is listed in the table. To change the
      ordering, drag and drop the featured document by the hamburger icon near the left side of the row to the desired
      position in the table. To save your changes, click the save changes button that appears near the top right of the
      table.
    </p>

    <current-and-upcoming-table
      :tabView="featuredView"
      :currentFeaturedResources="allFeaturedDocuments"
      @edit-featured-resource="showEditFeaturedDocumentDialog"
      @remove-featured-resource="showRemoveFeaturedDocumentDialog"
      @on-save-display-order="saveDisplayOrderAsync"
      ref="featuredDocumentTable"
    />

    <h1 class="font-heading-lg text-primary-dark text-bold margin-top-5">Search All Documents</h1>
    <site-documents-section @add-featured-document="showAddFeaturedDocumentDialog" />

    <featured-resource-dialog
      id="featured-document-dialog"
      @document-confirmed="featuredDocumentConfirmedAsync"
      :selectedDocument="selectedSiteDocument"
      :selectedFeaturedResource="selectedFeaturedDocument"
      :isAdding="isAddingFeaturedDocument"
      :isSelectionAlreadyFeatured="isSelectedSiteDocumentIdDuplicate"
      :featuredResourceType="featuredView"
    />
    <remove-featured-resource-dialog
      id="remove-featured-doc-dialog"
      :tabView="featuredView"
      :selectedFeaturedResource="selectedFeaturedDocument"
      @remove-featured-document="removeFeaturedDocumentAsync"
    />
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { SiteDocument, FeaturedDocument } from "@/dataModel";
import { NewFeaturedDocumentRequest, ModifyFeaturedDocumentRequest } from "@/dataModel/requests";
import FeaturedHazardType from "@/constants/FeaturedHazardType";
import { FeaturedResourcesMixin } from "./FeaturedResourcesMixin";
import { FeaturedResourceAction } from "../Constants/FeaturedResourceAction";
import { FeaturedResource } from "@/dataModel/interfaces";

@Component
export default class FeaturedDocumentSection extends Mixins(FeaturedResourcesMixin) {
  selectedSiteDocument: SiteDocument = new SiteDocument();
  selectedFeaturedDocument: FeaturedDocument = new FeaturedDocument();
  allFeaturedDocuments: FeaturedDocument[] = [];
  isSelectedSiteDocumentIdDuplicate: boolean = false;
  isAddingFeaturedDocument: boolean = false;

  mounted(): void {
    this.featuredView = FeaturedHazardType.Document;
    this.refreshFeaturedDocumentsAsync();
  }

  async featuredDocumentConfirmedAsync(featuredDocument: NewFeaturedDocumentRequest): Promise<void> {
    if (this.isAddingFeaturedDocument) {
      await this.addFeaturedDocumentAsync(featuredDocument);
    } else {
      await this.editFeaturedDocumentAsync(featuredDocument);
    }
  }

  async addFeaturedDocumentAsync(featuredDocument: NewFeaturedDocumentRequest): Promise<void> {
    await this.featuredResourceHttpHelper.addFeaturedDocument(featuredDocument);
    this.showResponseStatusDialog(FeaturedResourceAction.CREATE, FeaturedHazardType.Document);
    await this.refreshFeaturedDocumentsAsync();
  }

  async editFeaturedDocumentAsync(featuredDocument: NewFeaturedDocumentRequest): Promise<void> {
    let request = this.getEditFeaturedDocumentRequest(featuredDocument);
    await this.featuredResourceHttpHelper.updateFeaturedDocuments(request);
    this.showResponseStatusDialog(FeaturedResourceAction.UPDATE, FeaturedHazardType.Document);
    await this.refreshFeaturedDocumentsAsync();
  }

  async removeFeaturedDocumentAsync(featuredDocument: FeaturedDocument): Promise<void> {
    let request = this.getRemoveFeaturedDocumentRequest(featuredDocument.siteDocumentId);
    await this.featuredResourceHttpHelper.deleteFeaturedDocuments(request);
    this.showResponseStatusDialog(FeaturedResourceAction.DELETE, FeaturedHazardType.Document);
    await this.refreshFeaturedDocumentsAsync();
  }

  async refreshFeaturedDocumentsAsync(): Promise<void> {
    this.allFeaturedDocuments = await this.featuredResourceHttpHelper.getAllFeaturedDocuments();
  }

  async saveDisplayOrderAsync(newOrder: FeaturedResource[]): Promise<void> {
    // Construct modify request
    const modifyRequest = new ModifyFeaturedDocumentRequest();
    modifyRequest.featuredDocuments = newOrder.map((res, index) => {
      const doc = res as FeaturedDocument;
      return new NewFeaturedDocumentRequest(
        doc.siteDocumentId,
        index,
        doc.startDate,
        doc.endDate,
        doc.featuredLengthType,
        doc.customFeaturedLength,
      );
    });

    const response = await this.featuredResourceHttpHelper.updateFeaturedDocuments(modifyRequest);
    this.allFeaturedDocuments = response;
    this.showResponseStatusDialog(FeaturedResourceAction.ORDER, FeaturedHazardType.Document);
  }

  getEditFeaturedDocumentRequest(featuredDocument: NewFeaturedDocumentRequest): ModifyFeaturedDocumentRequest {
    let request = new ModifyFeaturedDocumentRequest();
    let featureDocumentIndex = 0;
    request.featuredDocuments = this.allFeaturedDocuments.map((doc, index) => {
      if (doc.siteDocumentId === featuredDocument.siteDocumentId) {
        featureDocumentIndex = index;
      }
      return new NewFeaturedDocumentRequest(
        doc.siteDocumentId,
        index,
        doc.startDate,
        doc.endDate,
        doc.featuredLengthType,
        doc.customFeaturedLength,
      );
    });
    featuredDocument.displayOrder = featureDocumentIndex;
    request.featuredDocuments.splice(featureDocumentIndex, 1, featuredDocument);
    return request;
  }

  getRemoveFeaturedDocumentRequest(siteDocumentId: string): ModifyFeaturedDocumentRequest {
    let request = new ModifyFeaturedDocumentRequest();
    let featureDocumentIndex = 0;
    request.featuredDocuments = this.allFeaturedDocuments.map((doc, index) => {
      if (doc.siteDocumentId === siteDocumentId) {
        featureDocumentIndex = index;
      }
      return new NewFeaturedDocumentRequest(
        doc.siteDocumentId,
        index,
        doc.startDate,
        doc.endDate,
        doc.featuredLengthType,
        doc.customFeaturedLength,
      );
    });
    request.featuredDocuments.splice(featureDocumentIndex, 1);
    return request;
  }

  showAddFeaturedDocumentDialog(document: SiteDocument): void {
    this.isAddingFeaturedDocument = true;
    this.selectedSiteDocument = document;
    this.isSelectedSiteDocumentIdDuplicate = this.isDocumentFeatured(document);
    this.$nextTick(() => {
      this.$bvModal.show("featured-document-dialog");
    });
  }

  showEditFeaturedDocumentDialog(featuredDocument: FeaturedResource): void {
    this.isAddingFeaturedDocument = false;
    this.selectedFeaturedDocument = featuredDocument as FeaturedDocument;
    this.isSelectedSiteDocumentIdDuplicate = false;
    this.$nextTick(() => {
      this.$bvModal.show("featured-document-dialog");
    });
  }

  showRemoveFeaturedDocumentDialog(featuredDocument: FeaturedResource): void {
    this.selectedFeaturedDocument = featuredDocument as FeaturedDocument;
    this.$bvModal.show("remove-featured-doc-dialog");
  }

  isDocumentFeatured(document: SiteDocument): boolean {
    return this.allFeaturedDocuments.some((doc) => doc.siteDocumentId === document.id);
  }
}
</script>

<style scoped lang="scss"></style>
