<template>
  <footer id="footer">
    <div class="grid-container">
      <div id="link-container">
        <i id="caret" class="fas fa-angle-up"></i>
        <a href="#" id="back-to-top-link">Back to Top</a>
      </div>
    </div>
    <div id="nav-bar-1" class="border-top border-top-width-2px border-base-lighter">
      <div class="grid-container">
        <div>
          <h1 class="dhs-footer-logo">
            <img id="dhs-logo" src="../../assets/img/dhs-logo.svg" role="img" alt="" aria-hidden="true" />
            <span id="logo-text">Homeland Security</span>
          </h1>
          <div id="nav-bar-1-items">
            <ul class="left"></ul>
            <ul class="right"></ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class DhsFooter extends Vue {}
</script>

<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

$icon-width: 4.5rem;
$icon-height: 4.5rem;

#footer {
  padding-bottom: units(3);
  position: absolute;
  bottom: 0;
  width: 100%;
}

ul {
  list-style-type: none;
}

.dhs-footer-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  margin-top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(-$icon-height * 0.5);
  width: $icon-width;
}

.leaf a {
  font-family: family("body");
  font-size: size("body", "sm");
  line-height: lh("body", 2);
  color: #222;
  text-decoration: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

#link-container a {
  color: color("primary");
}

#link-container i {
  padding-right: units(1);
}

#link-container {
  font-family: family("body");
  font-size: size("body", "sm");
  line-height: lh("body", 2);
  margin-left: units(8);
}

#logo-text {
  vertical-align: middle;
  display: inline-block;
  color: color("primary");
  font-family: family("heading");
  font-size: size("heading", "sm");
  line-height: lh("heading", 2);
  margin: 0;
  text-align: center;
}

#dhs-logo {
  margin-bottom: units(1);
  height: $icon-height;
  width: 100%;
}

#nav-bar-1 {
  background: #f8f8f8;
  z-index: -1;
  margin-top: units(3);
  margin-bottom: units(0.5);
  padding-bottom: units(1.5);
}

#nav-bar-1-items {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: units(5) 0 units(5) 0;
}

#nav-bar-1-items ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px;
  margin: 0px;
  width: calc(
    50% - #{calc($icon-width / 2)} - #{units(10)}
  ); // give the left and right side half of the width, subtract the width of the logo, and then remove the margin
}

#nav-bar-2 {
  background: #eeeeee;
}

#nav-bar-2-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px;
  margin: 0px;
  padding: units(1) units(3) units(1) units(3);
}

#nav-bar-2-items li a {
  color: color("primary");
  text-decoration: underline;
  font-family: family("body");
  font-size: size("body", 1);
  line-height: lh("body", 1);
}

#link-container {
  margin-top: units(5);
}
</style>
