import HttpHelper from "@/components/resources/httpHelper";
import { inject, injectable } from "inversify";
import serviceTypes from "@/dependencyInjection/types";
import SearchSourcesResponse from "@/dataModel/hazardData/searchSourcesResponse";
import CreateSourceResponse from "@/dataModel/hazardData/createSourceResponse";
import CreateSourceRequest from "@/dataModel/hazardData/createSourceRequest";
import { UpdateSourceRequest } from "@/dataModel/requests";
import DataSource from "@/dataModel/hazardData/dataSource";
import HazardDataValue from "@/dataModel/hazardData/hazardDataValue";
import CreateDataValueRequest from "@/dataModel/hazardData/createDataValueRequest";
import UpdateDataValueRequest from "@/dataModel/hazardData/updateDataValueRequest";
import BulkUpdateDataValueStatusRequest from "@/dataModel/hazardData/bulkUpdateDataValueStatusRequest";

const hazardDataUrl = "admin/hazard-data";

@injectable()
export default class HazardDataAdminHttpHelper {
  httpHelper: HttpHelper;

  constructor(@inject(serviceTypes.HttpHelper) httpHelper: HttpHelper) {
    this.httpHelper = httpHelper;
  }

  async searchSources(request: any): Promise<SearchSourcesResponse> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(hazardDataUrl + "/sources/search", request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error("Response returned " + response.status + " : " + response.statusText + " searching sources"),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while searching sources"));
        });
    });
  }

  async createSource(request: CreateSourceRequest): Promise<CreateSourceResponse> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(hazardDataUrl + "/sources", request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error("Response returned " + response.status + " : " + response.statusText + " creating source"),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while creating source"));
        });
    });
  }

  async updateSource(request: UpdateSourceRequest): Promise<DataSource> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPut(hazardDataUrl + "/sources", request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error("Response returned " + response.status + " : " + response.statusText + " updating source"),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while updating source"));
        });
    });
  }

  async deleteSource(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpDelete(`${hazardDataUrl}/sources/${id}`)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(`Response returned ${response.status}: ${response.statusText} deleting the hazard source`),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while deleting the hazard source"));
        });
    });
  }

  async createDataValue(request: CreateDataValueRequest): Promise<HazardDataValue> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(hazardDataUrl + "/values", request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error("Response returned " + response.status + " : " + response.statusText + " creating data value"),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while creating data value"));
        });
    });
  }

  async updateDataValue(request: UpdateDataValueRequest): Promise<HazardDataValue> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPut(hazardDataUrl + "/values", request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error("Response returned " + response.status + " : " + response.statusText + " updating data value"),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while updating data value"));
        });
    });
  }

  async deleteDataValues(ids: string[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPut(`${hazardDataUrl}/values/delete`, ids)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error("Response returned " + response.status + " : " + response.statusText + " deleting data values"),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while deleting data values"));
        });
    });
  }

  bulkUpdateDataValueStatusAsync(request: BulkUpdateDataValueStatusRequest): Promise<HazardDataValue[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPut(`${hazardDataUrl}/values/bulk-update`, request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                `Response returned ${response.status}: ${response.statusText} bulk updating data value statuses`,
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while bulk updating data value statuses"));
        });
    });
  }
}
