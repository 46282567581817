import { AuthStoreMutations } from "@/constants/store/auth/authStoreConstants";
import { AuthState, User } from "@/typings/store/states/AuthStoreState";

export default {
  [AuthStoreMutations.SET_USER](state: AuthState, user: User | null) {
    state.user = user;
    state.isAuthenticated = true;
  },
  [AuthStoreMutations.SET_PAGE_ACCESS] (state: AuthState, pageAccess: string[]) {
    state.access.pageAccess = pageAccess
  },
  [AuthStoreMutations.SET_HAS_ADMIN_DOC_ACCESS] (state: AuthState, value: boolean) {
    state.access.hasAdminDocumentAccess = value
  },
  [AuthStoreMutations.SET_HAS_ADMIN_ROLE_ACCESS] (state: AuthState, value: boolean) {
    state.access.hasAdminRolesAccess = value
  },
  [AuthStoreMutations.SET_HAS_ADMIN_USER_REQUEST_ACCESS] (state: AuthState, value: boolean) {
    state.access.hasAdminUserRequestAccess = value
  },
  [AuthStoreMutations.SET_HAS_USER_SEARCH_ACCESS] (state: AuthState, value: boolean) {
    state.access.hasUserSearchAccess = value
  },
  [AuthStoreMutations.SET_HAS_USER_REQUEST_ACCESS] (state: AuthState, value: boolean) {
    state.access.hasUserRequestAccess = value
  },
  [AuthStoreMutations.SET_HAS_USER_APP_ACCESS] (state: AuthState, value: boolean) {
    state.access.hasUserApplicationAccess = value
  },
  [AuthStoreMutations.SET_HAS_USER_DOC_ACCESS] (state: AuthState, value: boolean) {
    state.access.hasUserDocumentsAccess = value
  },
  [AuthStoreMutations.SET_HAS_ADMIN_USER_MANAGEMENT_ACCESS] (state: AuthState, value: boolean) {
    state.access.hasAdminUserManagementAccess = value
  },
  [AuthStoreMutations.SET_HAS_ADMIN_REPORT_ACCESS] (state: AuthState, value: boolean) {
    state.access.hasAdminReportAccess = value
  },
  [AuthStoreMutations.SET_DOCUMENT_APPROVE_ACCESS] (state: AuthState, value: boolean) {
    state.access.hasDocumentApproveAccess = value
  },
  [AuthStoreMutations.SET_DOCUMENT_CREATE_UPLOAD_ACCESS] (state: AuthState, value: boolean) {
    state.access.hasDocumentCreateUploadAccess = value
  },
  [AuthStoreMutations.SET_DOCUMENT_EDIT_ACCESS] (state: AuthState, value: boolean) {
    state.access.hasDocumentEditAccess = value
  },
  [AuthStoreMutations.SET_CATEGORY_MANAGEMENT_ACCESS] (state: AuthState, value: boolean) {
    state.access.hasCategoryManagementAccess = value
  },
  [AuthStoreMutations.SET_FEATURED_RESOURCE_MANAGEMENT_ACCESS] (state: AuthState, value: boolean) {
    state.access.hasFeaturedResourceManagementAccess = value
  },
  [AuthStoreMutations.SET_DATA_COLLECTION_MANAGEMENT_ACCESS] (state: AuthState, value: boolean) {
    state.access.hasDataCollectionManagementAccess = value
  },
  [AuthStoreMutations.SET_HAS_USER_HAZARD_DATA_ACCESS] (state: AuthState, value: boolean) {
    state.access.hasUserHazardDataAccess = value
  },
  [AuthStoreMutations.SET_USAGE_ANALYTICS_ACCESS] (state: AuthState, value: boolean) {
    state.access.hasUsageAnalyticsAccess = value
  },
  [AuthStoreMutations.RESET_AUTHENTICATION] (state: AuthState) {
    state.user = null
    state.isAuthenticated = false;
    state.access = {
      pageAccess: [],
      hasAdminDocumentAccess: false,
      hasAdminReportAccess: false,
      hasAdminRolesAccess: false,
      hasAdminUserManagementAccess: false,
      hasAdminUserRequestAccess: false,
      hasUserApplicationAccess: false,
      hasUserDocumentsAccess: false,
      hasUserRequestAccess: false,
      hasUserSearchAccess: false,
      hasUserHazardDataAccess: false,
      hasDocumentApproveAccess: false,
      hasDocumentCreateUploadAccess: false,
      hasDocumentEditAccess: false,
      hasCategoryManagementAccess: false,
      hasFeaturedResourceManagementAccess: false,
      hasDataCollectionManagementAccess: false,
      hasUsageAnalyticsAccess: false,
    };
  },
  [AuthStoreMutations.SET_SECONDS_IDLE] (state: AuthState, newValue: number) {
    state.secondsIdle = newValue;
  },
  [AuthStoreMutations.SET_SHOWING_WARNING_DIALOG] (state: AuthState, newValue: boolean) {
    state.showingWarningDialog = newValue;
  }
}