import HttpHelper from "@/components/resources/httpHelper";
import { inject, injectable } from "inversify";
import serviceTypes from "@/dependencyInjection/types";
import { SiteDocument, SiteDocumentMetadata, StagedFileDocumentMatch, TitlePermissionIds } from "@/dataModel/";
import {
  SearchResponse,
  BulkFileUpdateResponse,
  SiteDocumentResponse,
  DocumentMatchResponse,
} from "@/dataModel/responses";
import { BulkUpdateDocument } from "@/dataModel/interfaces";
import { CreateSiteDocumentRequest } from "@/dataModel/requests";
import { AxiosError } from "axios";
const url = "admin/site-documents/";

@injectable()
export default class SiteDocumentAdminHttpHelper {
  httpHelper: HttpHelper;

  constructor(@inject(serviceTypes.HttpHelper) httpHelper: HttpHelper) {
    this.httpHelper = httpHelper;
  }

  async updateSiteDocument(request: UpdateSiteDocumentRequest): Promise<SiteDocument> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPut(url, request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error(`Response returned ${response.status}: ${response.statusText} updating site document`));
          }
        })
        .catch((error: AxiosError) => {
          console.error(error);
          reject(new Error(error.response?.data));
        });
    });
  }

  async getMatchingDocumentsForFiles(fileIds: string[]): Promise<DocumentMatchResponse[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(`${url}matching-document`, fileIds)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(`Response returned ${response.status}: ${response.statusText} getting matching documents.`),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error(`An error occured while getting document matches for files`));
        });
    });
  }

  async createCopyrightRestrictedSiteDocument(request: CreateSiteDocumentRequest): Promise<Document> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(url + "copyright-restricted-document", request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                `Response returned ${response.status}: ${response.statusText} creating copyright restricted site document`,
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error(`An error occured while creating copyright restricted site document`));
        });
    });
  }

  async addSiteDocument(request: CreateSiteDocumentRequest): Promise<SiteDocumentMetadata> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(url, request)
        .then((response: any) => {
          if (response.status === 200) {
            const documentMetadata = response.data as SiteDocumentMetadata;
            resolve(documentMetadata);
          } else {
            reject(new Error(`Response returned ${response.status}: ${response.statusText} creating site document`));
          }
        })
        .catch((error: AxiosError) => {
          if (error.response?.status == 400) {
            reject(error.response?.data);
          } else {
            reject(new Error(`An error occurred. Please try again.`));
          }
        });
    });
  }

  async bulkUpdateSiteDocuments(request: BulkUpdateDocument): Promise<SiteDocument[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPut(url + "bulk-update/", request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(`Response returned ${response.status}: ${response.statusText} bulk updating site documents`),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error(`An error occured while bulk updating site documents`));
        });
    });
  }

  async bulkChangeStatusSiteDocuments(ids: string[], isPublished: boolean): Promise<SiteDocument[]> {
    const data = {
      SiteDocumentIds: ids,
      IsPublished: isPublished,
    };
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPut(url + "bulk-update/", data)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                `Response returned ${response.status}: ${response.statusText} bulk updating site documents status`,
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error(`An error occured while bulk updating site documents status`));
        });
    });
  }

  async searchSiteDocuments(
    page: number,
    resultsPerPage: number,
    searchTerm: string,
    sortOrder: string,
    sortColumn: string,
    classificationFilters: string[],
    disseminationControlFilters: string[],
    categoryFilters: string[],
    documentTypeFilters: string[],
    isCopyrightRestrictedFilters: boolean[],
    audienceFilters: string[],
    sciFilters: string[],
    isPublishedFilters: boolean[],
  ): Promise<SearchResponse<SiteDocument>> {
    const categoryFilterList = categoryFilters.length > 0 ? categoryFilters : null;
    const data = {
      Page: page,
      ResultsPerPage: resultsPerPage,
      SearchTerm: searchTerm,
      SortOrder: sortOrder,
      SortColumn: sortColumn,
      ClassificationFilters: classificationFilters,
      DisseminationControlFilters: disseminationControlFilters,
      DocumentTypeFilters: documentTypeFilters,
      IsCopyrightRestrictedFilters: isCopyrightRestrictedFilters,
      CategoryFilters: categoryFilterList,
      AudienceFilters: audienceFilters,
      SciFilters: sciFilters,
      IsPublishedFilters: isPublishedFilters,
    };

    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(url + "search/", data)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " searching site documents.",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while searching site documents."));
        });
    });
  }

  async updateDocumentFilesAsync(
    matches: StagedFileDocumentMatch[],
    classificationDowngrade: boolean,
    disseminationControlUpdate: boolean,
    sciUpdate: boolean,
  ): Promise<BulkFileUpdateResponse> {
    const fullUrl =
      url +
      "update-document-files?classificationDowngradeConfirmed=" +
      classificationDowngrade +
      "&disseminationControlUpdateConfirmed=" +
      disseminationControlUpdate +
      "&sciUpdateConfirmed=" +
      sciUpdate;
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPut(fullUrl, matches)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error(`Response returned ${response.status}: ${response.statusText} updating document files`));
          }
        })
        .catch((error: AxiosError) => {
          if (error.response?.status == 400) {
            reject(error.response?.data);
          } else {
            reject(new Error(`An error occurred. Please try again.`));
          }
        });
    });
  }
}

export class UpdateSiteDocumentRequest {
  titlePermissionIds?: TitlePermissionIds;
  siteDocumentId?: string;
  classification?: string;
  disseminationControls?: string[];
  scis?: string[];
  audiences?: string[];
  title?: string;
  documentType?: string;
  contributors?: string[];
  pages?: string;
  journalName?: string;
  isPublished?: boolean;
  doi?: string;
  volume?: string;
  abstract?: string;
  documentDateTime?: Date;
  isCopyrightRestricted?: boolean;
  categoryIds?: string[];
  documentDatePrecision?: string;
}
