<template>
  <basic-card :title="title" cardWidth="6" :description="description">
    <ol v-if="documentUsage.length > 0">
      <ul v-for="(metadata, index) in documentUsage" :key="`resource-list-${title}-${metadata.id}`" class="list-item">
        <div class="display-flex justify-content-between">
          <div>
            <div class="usa-prose margin-right-1 float-left">{{ index + 1 }}.</div>
            <router-link
              class="usa-button usa-button--unstyled"
              :to="{ name: 'DocumentSummary', query: { id: metadata.id } }"
              >{{ metadata.titleClassification + " " + metadata.title }}
            </router-link>
          </div>
          <div>
            <span class="usa-prose">{{ metadata.accessCount }}</span>
          </div>
        </div>
        <hr v-if="index != documentUsage.length - 1" class="line-break" />
      </ul>
    </ol>
    <div v-else>No analytics available</div>
  </basic-card>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import BasicCard from "@/components/common/BasicCard.vue";
import DocumentUsageMetadata from "@/dataModel/usageAnalytics/documentUsageMetadata";

@Component({
  components: {
    BasicCard,
  },
})
export default class UsageListCard extends Vue {
  @Prop({ default: "" }) readonly title!: string;
  @Prop({ default: [] }) readonly documentUsage!: DocumentUsageMetadata[];
  @Prop({ default: "" }) readonly description!: string;
}
</script>

<style scoped lang="scss">
a {
  display: inline;
}
.line-break {
  background: #c9c9c9;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
