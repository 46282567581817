<template>
  <div>
    <div id="spinner-container" v-if="isTaxonomyLoading" class="d-flex flex-align-center flex-justify-center">
      <spinner class="spinner-sm loading-spinner" />
      <span class="margin-left-3" id="loading-text">{{ loadingFilterText }}</span>
    </div>
    <ul id="filters-list" v-else>
      <li v-for="(rootSection, rootIndex) in filters" :key="rootIndex" class="section-list-item">
        <h1 v-if="showTitle(rootSection)" class="section-title">
          {{ rootSection.stringValue }}
        </h1>
        <date-slider
          v-if="rootSection.filterType === FilterType.Year && yearCount.length"
          @datesChanged="onDatesChanged"
          :data="yearCount"
          :key="rootIndex"
          ref="dateSlider"
        />
        <nav v-else aria-label="Table Filters">
          <ul class="filter-list">
            <search-filter-node
              v-for="(topLevelFilter, index) in rootSection.children"
              :key="index"
              @filtersChanged="filtersChanged"
              @sectionExpanded="sectionExpanded"
              @sectionCollapsed="sectionCollapsed"
              :node="topLevelFilter"
              :index="index"
              :includeCount="includeCount"
              :parentsAreSelectable="parentsAreSelectable"
              :canSearchWithinGroup="canSearchWithinGroup"
              :isSingleSelection="isSingleSelection"
              :truncatedNumberOfChildren="truncatedNumberOfChildren"
              ref="childNodes"
            />
          </ul>
        </nav>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import SearchFilterNode from "@/components/common/SearchFilterNode.vue";
import BasicDialog from "@/components/common/BasicDialog.vue";
import { FilterNodeInformation } from "@/dataModel";
import DateSlider from "@/components/common/DateSlider.vue";
import { YearCount } from "@/dataModel/interfaces";
import { FilterType } from "@/constants/FilterType";
import Spinner from "./Spinner.vue";

@Component({
  components: {
    SearchFilterNode,
    BasicDialog,
    DateSlider,
    Spinner,
  },
})
export default class SearchFilter extends Vue {
  @Prop({ required: true }) taxonomy!: FilterNodeInformation[];
  @Prop({ required: true }) isTaxonomyLoading!: boolean;
  @Prop({ required: true }) includeCount!: boolean;
  @Prop({ default: () => [] }) parentSelectedFilters!: FilterNodeInformation[];
  @Prop({ default: () => [] }) yearCount!: YearCount[];
  @Prop({ default: true }) parentsAreSelectable!: boolean;
  @Prop({ default: true }) canSearchWithinGroup!: boolean;
  @Prop({ default: false }) isSingleSelection!: boolean;
  @Prop({ default: 5 }) readonly truncatedNumberOfChildren!: number;

  $refs!: {
    childNodes: SearchFilterNode[];
    dateSlider: DateSlider;
  };
  selectedFilters: FilterNodeInformation[] = [];
  appliedFilters: Set<FilterNodeInformation> = new Set();
  filters: FilterNodeInformation[] = [];
  FilterType = FilterType;
  loadingFilterText: string = "Loading filters...";

  @Watch("selectedFilters")
  filterWatcher(): void {
    this.$emit("filtersChanged", this.selectedFilters);
  }

  @Watch("parentSelectedFilters")
  parentSelectedFiltersWatcher(): void {
    this.selectedFilters = this.parentSelectedFilters;
  }

  @Watch("taxonomy")
  taxonomyWatcher(): void {
    this.filters = this.taxonomy;
  }

  getSelectedFilters(): FilterNodeInformation[] {
    if (this.$refs.childNodes) {
      let selectedChildren: FilterNodeInformation[] = [];
      this.$refs.childNodes.forEach((x) => {
        selectedChildren = selectedChildren.concat(x.getSelectedFilters());
      });

      const dateNode = this.selectedFilters.find((filter) => filter.filterType === FilterType.Year);
      if (dateNode) {
        selectedChildren.push(dateNode);
      }

      return selectedChildren;
    }
    return [];
  }

  selectFilters(filters: FilterNodeInformation[]) {
    if (this.$refs.childNodes) {
      for (let childNode of this.$refs.childNodes) {
        childNode.selectFilters(filters);
      }
    }
  }

  removeSelectedFilter(filter: FilterNodeInformation): void {
    if (this.$refs.childNodes) {
      for (let childNode of this.$refs.childNodes) {
        let removed = childNode.deselectFilterIfExists(filter);
        if (removed) {
          break;
        }
      }
    }

    if (this.$refs.dateSlider && filter.filterType === FilterType.Year) {
      this.$refs.dateSlider[0].resetValues();
      this.updateYearFilter();
    }
  }

  showTitle(section: FilterNodeInformation): boolean {
    if (section.filterType === FilterType.Year) {
      return this.yearCount.length > 0;
    }

    return true;
  }

  clearSelections(): void {
    this.selectedFilters = [];
    this.$refs.childNodes.forEach((x) => {
      x.isSelected = false;
      x.changeSelectionOnAllChildren(false);
    });
    this.$refs.dateSlider?.[0]?.resetValues();
  }

  removeSelection(index: number): void {
    this.selectedFilters.splice(index, 1);
  }

  filtersChanged(): void {
    this.selectedFilters = this.getSelectedFilters();
  }

  onDatesChanged(event?: number[]): void {
    if (event) {
      const [from, to] = event;
      const dateFilter = new FilterNodeInformation();
      dateFilter.stringValue = from === to ? `${from}` : `${from} - ${to}`;
      dateFilter.filterType = FilterType.Year;
      this.updateYearFilter(dateFilter);
    }

    this.$emit("datesChanged", event);
  }

  updateYearFilter(newFilter?: FilterNodeInformation): void {
    const existingIndex = this.selectedFilters.findIndex((filter) => filter.filterType === FilterType.Year);
    if (existingIndex !== -1) {
      this.selectedFilters.splice(existingIndex, 1);
    }

    if (newFilter) {
      this.selectedFilters.push(newFilter);
    }
  }

  sectionExpanded(rootNode: FilterNodeInformation): void {
    this.$emit("sectionExpanded", rootNode);
  }

  sectionCollapsed(rootNode: FilterNodeInformation): void {
    this.$emit("sectionCollapsed", rootNode);
  }

  created(): void {
    this.filters = this.taxonomy;
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

.filter-list {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-size: 1.06rem;
  line-height: 1.3;
  border-bottom: 1px solid #dfe1e2;
}

.usa-prose {
  margin-bottom: 0.5rem;
}

.section-list-item {
  list-style-type: none;
}

.section-title {
  color: color("base");
  font-family: family("heading");
  font-size: size("heading", "md");
  font-weight: bold;
}

.accordion.not-collapsed {
  @extend .usa-current;
}

.accordion {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

label {
  margin-bottom: 0;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
