import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import { RequestState } from "@/typings/store/states/RequestStoreState";

const state: RequestState = {
  request: null,
  requestFileMetadata: null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
