<template>
  <basic-card :title="title" :description="description">
    <div class="count">{{ count }}</div>
  </basic-card>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import BasicCard from "@/components/common/BasicCard.vue";

@Component({
  components: {
    BasicCard,
  },
})
export default class UsageTotalCard extends Vue {
  @Prop({ default: "" }) readonly title!: string;
  @Prop({ default: 0 }) readonly count!: number;
  @Prop({ default: "" }) readonly description!: string;
}
</script>

<style scoped lang="scss">
.count {
  font-size: 2rem;
}
</style>
