<template>
  <ul class="usa-card-group">
    <li class="tablet:grid-col-6 usa-card usa-card--flag">
      <explore-card
        @click="exploreDocuments"
        header="Explore All Documents"
        :img="require('@/assets/img/icons/Explore.svg')"
        body-text="Each document in the HKMA has been tagged with any relevant categories. Explore the HKMA's documents by 
            selecting one or more categories to view their associated documents."
        button-text="Explore Documents"
      />
    </li>
    <li class="tablet:grid-col-6 usa-card usa-card--flag">
      <explore-card
        @click="exploreData"
        header="Explore Hazard Scientific Data"
        :img="require('@/assets/img/icons/BiologicalAgent.svg')"
        body-text="Hazard research data has been extracted from several documents within the HKMA. Review research data by 
            property or hazard from multiple documents at the same time."
        button-text="Explore Data"
      />
    </li>
  </ul>
</template>

<script lang="ts">
import { Vue, Component, Emit } from "vue-property-decorator";
import ExploreCard from "@/components/common/ExploreCard.vue";

@Component({ components: { ExploreCard } })
export default class ExploreCards extends Vue {
  @Emit()
  exploreDocuments(): void {}

  @Emit()
  exploreData(): void {}
}
</script>

<style scoped lang="scss">
.usa-card {
  margin-bottom: 0 !important;
}
</style>
