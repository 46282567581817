<template>
  <basic-dialog
    id="customize-table-dialog"
    title="Customize Table Data"
    size="lg"
    primaryButton="Apply"
    secondaryButton="Cancel"
    :primaryClick="apply"
    :secondaryClick="cancel"
  >
    <div>
      <p>
        Select the columns you wish to see in the table, and drag each column to put them in the order desired, with the
        top selection being first in the table. Up to six columns can be visible without scrolling. Use the dropdown to
        change the grouping of the values by different data categories.
      </p>
      <data-table
        :items="currentHazardDataOptions"
        :fields="headers"
        :selected="currentSelectedHazardDataOptions"
        @selections="updateSelected"
      >
        <template #cell(dragAndDrop)>
          <div
            class="margin-y-05 draggable"
            @dragenter="dragEnter"
            @dragend="dragEnd"
            @dragstart="dragStart"
            @dragover="dragOver"
            draggable="true"
          >
            <i class="fa fa-bars" />
          </div>
        </template>
        <template #cell(column)="data">
          <div class="usa-checkbox margin-y-05 width-mobile">
            <input
              class="usa-checkbox__input"
              :id="data.item.identifierKey"
              type="checkbox"
              :name="data.item.displayName"
              :value="data.item"
              v-model="currentSelectedHazardDataOptions"
            />
            <label class="usa-checkbox__label margin-0" :title="data.item.displayName" :for="data.item.identifierKey">
              {{ data.item.displayName }}
            </label>
          </div>
        </template>
      </data-table>
    </div>
    <div
      v-if="currentSelectedHazardDataOptions.length == 0"
      class="usa-alert usa-alert--info usa-alert--slim margin-top-3"
    >
      <div class="usa-alert__body">
        <p class="usa-alert__text">At least one column must be selected.</p>
      </div>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import DataTable from "@/components/common/DataTable.vue";
import { DataTableHeader } from "@/dataModel/interfaces/";
import { DraggableMixin } from "../../../mixins/DraggableMixin";
import DataValueOption from "@/dataModel/hazardData/dataValueOption";

@Component({
  components: {
    BasicDialog,
    DataTable,
  },
})
export default class CustomizeTableDialog extends Mixins(DraggableMixin) {
  currentHazardDataOptions: DataValueOption[] = [];
  updatedHazardDataOptions: DataValueOption[] = [];
  currentSelectedHazardDataOptions: DataValueOption[] = [];

  @Prop({ default: () => [] }) allHazardDataOptions!: DataValueOption[];
  @Prop({ default: () => [] }) selectedHazardDataOptions!: DataValueOption[];

  headers: DataTableHeader[] = [
    {
      label: "",
      key: "dragAndDrop",
      sortable: false,
      tdClass: "table-col-icon",
    },
    {
      label: "Column Name",
      key: "column",
      sortable: false,
    },
  ];

  @Watch("allHazardDataOptions")
  allHazardDataOptionsChanged(): void {
    this.currentHazardDataOptions = this.allHazardDataOptions;
    this.updatedHazardDataOptions = this.currentHazardDataOptions.map((option) => option);
  }

  dragEnd(ev: DragEvent): void {
    ev.preventDefault();
    this.draggedElement.style.cursor = "grab";
    let dragEndIndex = this.indexOfRow(this.tableBodyElement, this.draggedElement);
    this.swapColumns(this.dragStartIndex, dragEndIndex);
  }

  private swapColumns(startIndex: number, endIndex: number): void {
    const column = this.updatedHazardDataOptions.splice(startIndex - 1, 1);
    this.updatedHazardDataOptions.splice(endIndex - 1, 0, column[0]);
  }

  apply(): void {
    if (this.currentSelectedHazardDataOptions.length > 0) {
      this.$bvModal.hide("customize-table-dialog");
      this.currentHazardDataOptions = this.updatedHazardDataOptions.map((option) => option);
      this.reorderSelected();
      this.$emit("onApply", this.currentSelectedHazardDataOptions);
    }
  }

  cancel(): void {
    this.$bvModal.hide("customize-table-dialog");
  }

  updateSelected(selections: any[]): void {
    this.currentSelectedHazardDataOptions = selections;
  }

  reorderSelected(): void {
    this.currentSelectedHazardDataOptions = this.updatedHazardDataOptions.filter((option) =>
      this.currentSelectedHazardDataOptions.includes(option),
    );
  }

  mounted(): void {
    this.intializeDialogOnOpen();
  }

  intializeDialogOnOpen(): void {
    this.$root.$on("bv::modal::show", () => {
      this.currentSelectedHazardDataOptions = this.selectedHazardDataOptions;
      this.updatedHazardDataOptions = this.currentHazardDataOptions.map((option) => option);
    });
  }
}
</script>
