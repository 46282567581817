export default class CopyrightStatus {
    public static readonly Unrestricted = new CopyrightStatus("Unrestricted", "Unrestricted", false);
    public static readonly CopyrightRestricted = new CopyrightStatus("CopyrightRestricted", "Copyright Restricted", true);

    private constructor(public readonly value: string, public readonly displayName: string, 
        public readonly isCopyrightRestricted: boolean) {
    }
}

  
