<template>
  <main v-if="siteDocument != null" id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div v-if="prevRouteContainsUserSearch(prevRoute)" class="grid-col">
          <breadcrumb :path="path" />
        </div>
        <div v-else class="padding-top-2 padding-bottom-2">
          <button class="usa-button usa-button--unstyled" @click="goToPreviousPage">
            <i class="fa fa-arrow-left margin-right-05" />Back
          </button>
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <div class="desktop:grid-col-9">
          <h1 class="font-heading-xl text-primary-dark margin-top-0 margin-bottom-1">
            <span>{{ getTitleClassificationDisplay }}</span>
            {{ siteDocument.title }}
          </h1>
        </div>

        <div class="desktop:grid-col-3 text-right" v-if="isViewable">
          <button id="view-button" class="usa-button usa-button--outline" @click="viewDocument">View Document</button>
        </div>
      </div>
      <div class="grid-row mb-1 cn">
        <div class="desktop:grid-col-9">
          <h1 class="sub-header text-black">{{ documentMetadata }}</h1>
        </div>
        <div class="desktop:grid-col-3 text-right" v-if="!siteDocument.isCopyrightRestricted">
          <button
            id="subscribe-button"
            class="usa-button usa-button--unstyled alert-btn usa-tooltip"
            @click="subscribe"
          >
            <i :class="iconClass" />
            {{ subscriptionText }}
            <div :show="!isSubscribed" class="tooltiptext">
              <p class="usa-prose margin-bottom-0">Subscribe to be notified of any changes made to this document</p>
            </div>
          </button>
        </div>
      </div>
      <hr class="line-break" />

      <div v-if="siteDocument.abstract" class="margin-top-4">
        <span class="sub-header field">Abstract:</span>
        <p class="usa-prose margin-top-1">{{ siteDocument.abstract }}</p>
      </div>

      <div class="margin-top-4">
        <span class="sub-header field">Classification:</span>
        <p class="usa-prose margin-top-1">{{ getClassificationDisplay }}</p>
      </div>

      <div class="margin-top-4">
        <span class="sub-header field">Document Type:</span>
        <p class="usa-prose margin-top-1">
          {{ toDocumentDisplayName(siteDocument.documentType) }}
        </p>
      </div>

      <div class="margin-top-4">
        <span class="sub-header field">Copyright Status:</span>
        <p v-if="siteDocument.isCopyrightRestricted" class="usa-prose margin-top-1">Copyright restricted</p>
        <p v-else class="usa-prose margin-top-1">Not restricted</p>
      </div>

      <div class="margin-top-4">
        <span class="sub-header field">Audience Restrictions:</span>
        <p class="usa-prose margin-top-1">{{ audienceDisplay }}</p>
      </div>

      <div v-if="siteDocument.isCopyrightRestricted && siteDocument.location.length > 0" class="margin-top-4">
        <span class="sub-header field">Location:</span>
        <p class="usa-prose margin-top-1">{{ siteDocument.location }}</p>
      </div>

      <div v-if="focusArea.length > 0" class="margin-top-4">
        <span class="sub-header field margin-bottom-1">Focus Area:</span>
        <p v-for="(filter, index) in visibleFocusAreas" :key="index" class="usa-prose margin-top-05 margin-bottom-05">
          {{ filter }}
        </p>
        <a
          v-show="focusArea.length > 3 && !showAllFocusAreas"
          href="#"
          class="usa-link"
          @click.prevent="toggleShowAllFocusAreas"
          >Show all<i class="fa fa-chevron-down fa-fw"
        /></a>
        <a v-show="showAllFocusAreas" href="#" class="usa-link" @click.prevent="toggleShowAllFocusAreas"
          >Show less<i class="fa fa-chevron-up fa-fw"
        /></a>
      </div>

      <div v-if="hazardFocuses.length > 0" class="margin-top-4">
        <span class="sub-header field margin-bottom-1">Hazard Focus:</span>
        <p
          v-for="(filter, index) in visibleHazardFocuses"
          :key="index"
          class="usa-prose margin-top-05 margin-bottom-05"
        >
          {{ filter }}
        </p>
        <a
          v-show="hazardFocuses.length > 3 && !showAllHazardFocuses"
          href="#"
          class="usa-link"
          @click.prevent="toggleShowAllHazardFocuses"
          >Show all<i class="fa fa-chevron-down fa-fw"
        /></a>
        <a v-show="showAllHazardFocuses" href="#" class="usa-link" @click.prevent="toggleShowAllHazardFocuses"
          >Show less<i class="fa fa-chevron-up fa-fw"
        /></a>
      </div>

      <success-toast id="subscribe-status-toast" message="Successfully subscribed to document" />
      <success-toast id="unsubscribe-status-toast" message="Successfully unsubscribed from document" />
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import { BreadcrumbPathItem } from "@/dataModel/interfaces";
import store from "@/store";
import StoreNames from "@/constants/store/StoreNames";
import moment from "moment";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import NotificationHttpHelper, { CreateSubscriptionRequest } from "@/components/resources/notificationHttpHelper";
import { User, Subscription } from "@/dataModel";
import { AuthStoreGetters } from "@/constants/store/auth/authStoreConstants";
import SubscriptionType from "@/constants/SubscriptionType";
import { v4 as uuidv4 } from "uuid";
import { SiteDocumentResponse } from "@/dataModel/responses";
import { UserSearchStoreGetters } from "@/constants/store/userSearch/userSearchStoreConstants";
import { DocumentMixin } from "@/mixins";
import { formatDate } from "@/utils/helperFunctions";

@Component({
  components: {
    Breadcrumb,
  },
})
export default class DocumentSummary extends Mixins(DocumentMixin) {
  documentTypeDisplay: string = "";
  hazardFocuses: string[] = [];
  focusArea: string[] = [];
  showSubscribeButton = false;
  subscription: Subscription | null = null;
  user: User | null = null;
  showAllHazardFocuses: boolean = false;
  showAllFocusAreas: boolean = false;
  prevRoute: string | null = null;
  isViewable: boolean = false;

  private notificationHttpHelper = container.get<NotificationHttpHelper>(serviceTypes.NotificationHttpHelper);

  get path(): BreadcrumbPathItem[] {
    return [
      {
        text: "Home",
        url: "/",
      },
      {
        text: "Search",
        url: this.getSearchUrl(),
      },
      {
        text: "Document Summary",
      },
    ];
  }

  getFocusAreas(paths: string[][]): string[] {
    if (paths) {
      return paths.filter((path) => path[0] == "Focus Area").map((path) => path.slice(1, path.length).join(" > "));
    }
    return [];
  }

  getHazardTypes(paths: string[][]): string[] {
    if (paths) {
      return paths.filter((path) => path[0] == "Hazard Type").map((path) => path.slice(1, path.length).join(" > "));
    }
    return [];
  }

  toggleShowAllFocusAreas(): void {
    this.showAllFocusAreas = !this.showAllFocusAreas;
  }

  toggleShowAllHazardFocuses(): void {
    this.showAllHazardFocuses = !this.showAllHazardFocuses;
  }

  get visibleFocusAreas(): string[] {
    if (!this.showAllFocusAreas) {
      return this.focusArea.slice(0, 3);
    } else {
      return this.focusArea;
    }
  }

  get visibleHazardFocuses(): string[] {
    if (!this.showAllHazardFocuses) {
      return this.hazardFocuses.slice(0, 3);
    } else {
      return this.hazardFocuses;
    }
  }

  get isSubscribed(): boolean {
    const id = this.subscription?.id;
    return id !== null && id !== undefined;
  }

  get subscriptionText(): string {
    if (this.isSubscribed) {
      return "Unsubscribe";
    } else {
      return "Subscribe";
    }
  }

  get iconClass(): string {
    const icon = this.isSubscribed ? "fa-bell-slash" : "fa-bell";
    return `fa ${icon} mr-1`;
  }

  get contributors(): string | undefined {
    return this.siteDocument?.contributors.join(", ");
  }

  get documentMetadata(): string {
    const metadata: string[] = [];
    if ((this.siteDocument?.contributors ?? false) && this.contributors != undefined) {
      metadata.push(this.contributors);
    }
    if (this.siteDocument?.journalName) {
      metadata.push(this.siteDocument.journalName);
    }
    if (this.siteDocument?.documentDateTime) {
      metadata.push(formatDate(this.siteDocument.documentDateTime, "YYYY", true));
    }
    if (this.siteDocument?.volume) {
      metadata.push(this.siteDocument.volume);
    }
    if (this.siteDocument?.pages) {
      metadata.push(this.siteDocument.pages);
    }
    return metadata.join("; ");
  }

  get getTitleClassificationDisplay(): string | undefined {
    return this.siteDocument?.titleClassificationMarking;
  }

  get getClassificationDisplay(): string | undefined {
    return this.siteDocument?.classificationMarking;
  }

  get audienceDisplay(): string {
    let audiences = this.siteDocument?.knowledgeResource.audiences;
    if (audiences && audiences.length > 0) {
      return audiences.map((x) => x.fullName).join("; ");
    }
    return "None";
  }

  async subscribe(): Promise<void> {
    if (this.isSubscribed) {
      // unsubscribe
      await this.deleteSubscription();
      this.$bvToast.show("unsubscribe-status-toast");
      return;
    }

    // subscribe
    await this.createSubscription();
    this.$bvToast.show("subscribe-status-toast");
  }

  goToDocumentErrorPage(): void {
    this.$router.push({
      name: "DocumentError",
    });
  }

  viewDocument(): void {
    if (this.siteDocument && this.isViewable) {
      this.$router.push({
        name: "DocumentFileView",
        params: { id: this.siteDocument.id },
      });
    }
  }

  async getSubscription(): Promise<void> {
    if (this.user && this.siteDocument) {
      this.subscription = await this.notificationHttpHelper.getSubscriptionForUserByKnowledgeResourceId(
        this.user.userId,
        this.siteDocument.knowledgeResource.id,
      );
    }
    this.showSubscribeButton = true;
  }

  async createSubscription(): Promise<void> {
    if (!this.user || !this.siteDocument) {
      return;
    }

    const request = new CreateSubscriptionRequest();
    request.id = uuidv4();
    request.userId = this.user.userId;
    request.subscriptionType = SubscriptionType.Document;
    request.knowledgeResourceId = this.siteDocument.knowledgeResource.id;

    const response = await this.notificationHttpHelper.createSubscription(request);
    this.subscription = response.subscription;
  }

  async deleteSubscription(): Promise<void> {
    if (!this.subscription) {
      return;
    }

    await this.notificationHttpHelper.deleteSubscription(this.subscription.id);
    this.subscription = null;
  }

  async getDocument(): Promise<void> {
    let response: SiteDocumentResponse;
    try {
      response = await this.siteDocumentHttpHelper.getSiteDocument(this.id);
    } catch {
      this.goToDocumentErrorPage();
      return;
    }

    this.siteDocument = response.document;

    if (this.siteDocument?.fileName) {
      this.isViewable = true;
    }

    if (!this.siteDocument.location) {
      this.siteDocument.location = "";
    }
    this.documentTypeDisplay = this.documentTypes[response.document.documentType];
    this.hazardFocuses = this.getHazardTypes(response.document.categoryPaths!);
    this.focusArea = this.getFocusAreas(response.document.categoryPaths!);

    await this.getSubscription();
  }

  beforeRouteEnter(to, from, next): void {
    next((vm) => {
      if (from.name == null || from.name == "DocumentFileView") {
        vm.prevRoute = localStorage.getItem("prevRoute");
      } else {
        localStorage.setItem("prevRoute", from.name);
        vm.prevRoute = from.name;
      }
    });
  }

  async created() {
    this.user = store.getters[`${StoreNames.Auth}/${AuthStoreGetters.GET_USER}`] as User;
    this.searchTerm = store.getters[`${StoreNames.UserSearch}/${UserSearchStoreGetters.GET_SEARCH_TERM}`];
    this.getDocument();
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

.line-break {
  margin: 0;
  background: #c9c9c9;
}

.sub-header {
  display: block;
  font-family: family("heading");
  font-size: size("heading", "sm");
  line-height: lh("heading", 2);
  color: color("base-darkest");
}

.field {
  font-family: family("heading");
  font-weight: font-weight("bold");
  color: color("primary-dark");
}

.cn {
  display: flex;
  justify-content: space-between;
}

.usa-tooltip {
  position: relative;

  &:hover .tooltiptext {
    visibility: visible;
  }
}

.tooltiptext {
  background-color: #000;
  border-radius: 5px;
  color: white;
  padding: 8px;
  position: absolute;
  right: 0;
  text-align: left;
  top: 45px;
  visibility: hidden;
  width: 255px;
  z-index: 1;
}

#view-button {
  margin-right: 0;
}
</style>
