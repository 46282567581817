import { render, staticRenderFns } from "./UpdateDocumentDialog.vue?vue&type=template&id=447ca796&scoped=true"
import script from "./UpdateDocumentDialog.vue?vue&type=script&lang=ts"
export * from "./UpdateDocumentDialog.vue?vue&type=script&lang=ts"
import style0 from "./UpdateDocumentDialog.vue?vue&type=style&index=0&id=447ca796&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "447ca796",
  null
  
)

export default component.exports