<template>
  <basic-dialog
    id="delete-category-dialog"
    title="Delete Category"
    primaryButton="Confirm"
    secondaryButton="Cancel"
    :primaryClick="confirm"
    :secondaryClick="cancel"
    :isDisabled="isCategoryUsedByDocuments"
  >
    <p class="usa-prose mt-3">
      Are you sure you want to delete
      <span class="bold" :class="{ 'text-italic': categoryService.isItalicFormat(category) }">{{
        category.displayName
      }}</span
      >?
    </p>
    <p v-if="numberOfCategoriesBeingDeleted > 1" class="usa-prose mt-2">
      This will delete <span class="bold">{{ numberOfCategoriesBeingDeleted }} </span> categories.
    </p>

    <div class="usa-alert usa-alert--warning usa-alert--slim margin-top-5" v-if="isCategoryUsedByDocuments">
      <div class="usa-alert__body">
        <p class="usa-alert__text">
          This category cannot be deleted because there are one or more documents assigned to it.
        </p>
      </div>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import { Category } from "@/dataModel";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import CategoryService from "@/services/interfaces/ICategoryService";

@Component({
  components: {
    BasicDialog,
  },
})
export default class DeleteCategoryDialog extends Vue {
  @Prop({ required: true }) category!: Category;
  @Prop({ required: true }) isCategoryUsedByDocuments!: boolean;
  @Prop({ default: 0 }) numberOfCategoriesBeingDeleted!: number;

  private categoryService = container.get<CategoryService>(serviceTypes.CategoryService);

  cancel(): void {
    this.$bvModal.hide("delete-category-dialog");
  }

  confirm(): void {
    if (!this.isCategoryUsedByDocuments) {
      this.deleteCategory(this.category.id);
    }
  }

  @Emit()
  deleteCategory(id: string): string {
    this.$bvModal.hide("delete-category-dialog");
    return id;
  }
}
</script>

<style scoped lang="scss">
.bold {
  font-weight: bold;
}
</style>
