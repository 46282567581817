<template>
  <basic-dialog
    :id="dialogId"
    title="Metadata Auto-Extraction Available"
    primaryButton="Confirm"
    secondaryButton="Cancel"
    :primaryClick="confirmHandler"
    :secondaryClick="close"
  >
    <fieldset class="padding-x-0 margin-top-3">
      <legend class="usa-legend my-2">
        Select between uploading a metadata file or having metadata automatically extracted for the document batch.
      </legend>
      <div class="usa-radio">
        <input
          class="usa-radio__input"
          id="automatic-type"
          type="radio"
          v-model="metadataMethod"
          :value="metadataMethodTypes.Automatic"
        />
        <label class="usa-radio__label" for="automatic-type"> Automatically Extract </label>
      </div>
      <div class="usa-radio">
        <input
          class="usa-radio__input"
          id="file-type"
          type="radio"
          v-model="metadataMethod"
          :value="metadataMethodTypes.File"
        />
        <label class="usa-radio__label" for="file-type"> Upload Metadata File </label>
      </div>
    </fieldset>
    <div class="margin-top-2">
      <span v-if="metadataMethod === metadataMethodTypes.Automatic" class="usa-hint" id="allowed-file-types">
        Supported file types: {{ allowedFileTypesString }}
      </span>
    </div>
    <error-toast id="template-error-toast" message="An error has occurred." />
  </basic-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import { MetadataMethodType } from "@/constants/MetadataMethodType";
import FileTypeSetting from "@/dataModel/fileUpload/fileTypeSetting";

@Component({
  components: {
    BasicDialog,
  },
})
export default class AutoExtractOrFilePickerDialog extends Vue {
  metadataMethodTypes = MetadataMethodType;
  metadataMethod: MetadataMethodType = this.metadataMethodTypes.Automatic;

  @Prop({ required: true }) readonly dialogId!: string;
  @Prop({ required: true }) readonly allowedFileTypes!: FileTypeSetting[];

  allowedFileTypesString = "";

  close(): void {
    this.$bvModal.hide(this.dialogId);
  }

  confirmHandler(): void {
    // does this.close() come first?
    if (this.metadataMethod === this.metadataMethodTypes.File) {
      this.$emit("confirm-file");
    } else if (this.metadataMethod === this.metadataMethodTypes.Automatic) {
      this.$emit("confirm-auto");
    }
    this.close();
  }

  created() {
    this.allowedFileTypesString = this.allowedFileTypes.map((x) => x.displayName).join(", ");
  }
}
</script>
