export enum RequestStoreActions {
  SET_SELECTED_REQUEST = "SET_SELECTED_REQUEST",
  SET_REQUEST_FILE_METADATA = "SET_REQUEST_FILE_METADATA",
}

export enum RequestStoreGetters {
  GET_SELECTED_REQUEST = "GET_SELECTED_REQUEST",
  GET_REQUEST_FILE_METADATA = "GET_REQUEST_FILE_METADATA",
}

export enum RequestStoreMutations {
  SET_SELECTED_REQUEST = "SET_SELECTED_REQUEST",
  SET_REQUEST_FILE_METADATA = "SET_REQUEST_FILE_METADATA",
}
