<template>
  <basic-dialog
    id="create-category-dialog"
    title="Create New Category"
    primaryButton="Save"
    secondaryButton="Cancel"
    :primaryClick="confirmFormIsValid"
    :secondaryClick="cancel"
    :isDisabled="false"
  >
    <p class="usa-prose mb-5">Add new nodes for research categorization.</p>
    <p></p>
    <div>
      <label class="usa-label mt-0" for="category-tree">Category Selection</label>
      <treeselect
        id="category-tree"
        v-model="parentCategoryId"
        :multiple="false"
        :options="categoryTree"
        placeholder="Select a category..."
        :disableRootNodes="false"
        aria-describedby="parent-category-error-message"
      />
      <span
        class="usa-error-message"
        id="parent-category-error-message"
        v-show="hasTriedToSubmit && !isValid(parentCategoryId)"
      >
        The parent category cannot be empty
      </span>
    </div>
    <div>
      <label class="usa-label" for="display-name">Display Name</label>
      <input
        class="usa-input"
        id="display-name"
        name="display-name"
        type="text"
        v-model.trim="displayName"
        aria-describedby="empty-display-name-error-message duplicate-sibling-error-message duplicate-parent-error-message"
      />
      <span
        class="usa-error-message"
        id="empty-display-name-error-message"
        v-show="hasTriedToSubmit && !isValid(displayName)"
      >
        The display name cannot be empty
      </span>
      <span
        class="usa-error-message"
        id="duplicate-sibling-error-message"
        v-show="
          hasTriedToSubmit && isValid(parentCategoryId) && isValid(displayName) && !isUniqueWithSiblings(displayName)
        "
      >
        The display name cannot be the same as its sibling categories
      </span>
      <span
        class="usa-error-message"
        id="duplicate-parent-error-message"
        v-show="
          hasTriedToSubmit && isValid(parentCategoryId) && isValid(displayName) && !isUniqueWithParent(displayName)
        "
      >
        The display name cannot be the same as the parent category
      </span>
    </div>
    <div>
      <label class="usa-label" for="format">Display Format</label>
      <select class="usa-select width-mobile" name="format" id="format" v-model="format">
        <option :value="formatTypes.Normal">Normal</option>
        <option :value="formatTypes.Italic">Italic</option>
      </select>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import Treeselect from "@/components/common/CategoryTreeSelect.vue";
import { CreateCategoryRequest } from "@/dataModel/requests";
import FormatType from "@/constants/FormatType";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import CategoryService from "@/services/interfaces/ICategoryService";
import { FilterNodeInformation, Category } from "@/dataModel";

@Component({
  components: {
    BasicDialog,
    Treeselect,
  },
})
export default class CreateCategoryDialog extends Vue {
  parentCategoryId: string | null = null;
  displayName: string = "";
  format = FormatType.Normal;
  hasTriedToSubmit: boolean = false;
  formatTypes = FormatType;

  @Prop({ required: true }) categoryTree!: FilterNodeInformation[];
  @Prop({ required: true }) flatCategoryList!: Category[];
  @Prop({ required: true }) selectedCategoryId!: string | null;

  private categoryService = container.get<CategoryService>(serviceTypes.CategoryService);

  confirmFormIsValid(): void {
    if (!this.isValidForm()) {
      this.displayErrors();
      return;
    }
    const value = this.getCategoryValueFromDisplayName(this.displayName);
    const request = new CreateCategoryRequest(this.parentCategoryId!, this.displayName, value, this.format);
    this.createNewCategory(request);
  }

  isValidForm(): boolean {
    return this.isValid(this.parentCategoryId) && this.isValid(this.displayName) && this.isUnique(this.displayName);
  }

  displayErrors(): void {
    this.hasTriedToSubmit = true;
  }

  getCategoryValueFromDisplayName(displayName: string): string {
    return displayName.replaceAll(" ", "");
  }

  isValid(input: string | null): boolean {
    return input != null && input != undefined && input.length > 0;
  }

  isUnique(displayName: string): boolean {
    return this.isUniqueWithSiblings(displayName) && this.isUniqueWithParent(displayName);
  }

  isUniqueWithSiblings(displayName: string): boolean {
    const siblings = this.getSiblings();
    const categoryDisplayNames = siblings.map((category) => category.value.displayName.toLowerCase());
    return !categoryDisplayNames.includes(displayName.toLowerCase());
  }

  isUniqueWithParent(displayName: string): boolean {
    const parentDisplayName =
      this.flatCategoryList.find((category) => category.id == this.parentCategoryId)?.displayName ?? "";
    return displayName.toLowerCase() != parentDisplayName.toLowerCase();
  }

  getSiblings(): FilterNodeInformation[] {
    const categoryPath = this.categoryService.getCategoryPath(this.parentCategoryId!, this.flatCategoryList);
    let siblings = this.categoryTree.find((category) => category.id == categoryPath[0].id)?.children ?? [];
    for (let i = 1; i < categoryPath.length; i++) {
      const pathItem = categoryPath[i];
      siblings = siblings.find((category) => category.id == pathItem.id)?.children ?? [];
    }
    return siblings;
  }

  @Emit()
  createNewCategory(request: CreateCategoryRequest): CreateCategoryRequest {
    this.$bvModal.hide("create-category-dialog");
    return request;
  }

  cancel(): void {
    this.$bvModal.hide("create-category-dialog");
  }

  mounted(): void {
    this.intializeDialogOnOpen();
  }

  intializeDialogOnOpen(): void {
    this.$root.$on("bv::modal::show", () => {
      this.intializeDialog();
    });
  }

  intializeDialog(): void {
    this.clearInputFields();
    this.setParentCategoryId();
  }

  clearInputFields(): void {
    this.parentCategoryId = null;
    this.displayName = "";
    this.hasTriedToSubmit = false;
    this.format = FormatType.Normal;
  }

  setParentCategoryId(): void {
    this.parentCategoryId = this.selectedCategoryId;
  }
}
</script>

<style scoped lang="scss"></style>
