import StoreNames from "@/constants/store/StoreNames";
import Vue from "vue";
import Vuex from "vuex";
import AuthStoreModule from "./modules/auth";
import StagedFileStoreModule from "./modules/stagedFile";
import RequestStoreModule from "./modules/request";
import DocumentStoreModule from "./modules/document";
import { DocumentStagingModule } from "./modules/documentStaging";
import PermissionStoreModule from "./modules/permission";
import UserSearchStoreModule from "./modules/userSearch";
import SiteDocumentManagementStoreModule from "./modules/siteDocumentManagement";
import DataCollectionStoreModule from "./modules/dataCollection";
import SiteDocumentManagementModule from "./modules/siteDocumentManagementModule";
import SettingModule from "./modules/setting";
import createPersistedState from "vuex-persistedstate";
import AppModule from "./modules/appModule";

Vue.use(Vuex);

let modules = {
  [StoreNames.Auth]: AuthStoreModule,
  [StoreNames.StagedFile]: StagedFileStoreModule,
  [StoreNames.Request]: RequestStoreModule,
  [StoreNames.Document]: DocumentStoreModule,
  [StoreNames.UserSearch]: UserSearchStoreModule,
  [StoreNames.Permission]: PermissionStoreModule,
  [StoreNames.Setting]: SettingModule,
  [StoreNames.SiteDocumentManagement]: SiteDocumentManagementStoreModule,
  [StoreNames.DataCollection]: DataCollectionStoreModule,
  [StoreNames.DocumentModule]: SiteDocumentManagementModule,
  DocumentStagingModule,
  [StoreNames.App]: AppModule,
};

const store = new Vuex.Store({
  modules: modules,
  plugins: [
    createPersistedState({
      key: "IdleState",
      paths: ["idleVue"],
      storage: window.localStorage,
    }),
    createPersistedState({
      key: "State",
      paths: Object.keys(modules),
      storage: window.sessionStorage,
    }),
  ],
});

export default store;
