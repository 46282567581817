import { render, staticRenderFns } from "./InvalidSearch.vue?vue&type=template&id=2bae743d&scoped=true"
import script from "./InvalidSearch.vue?vue&type=script&lang=ts"
export * from "./InvalidSearch.vue?vue&type=script&lang=ts"
import style0 from "./InvalidSearch.vue?vue&type=style&index=0&id=2bae743d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bae743d",
  null
  
)

export default component.exports