import { Actor } from "@/dataModel";

export class User {
  userId: string;
  remoteIdentifier: string;
  email: string;
  displayName: string;
  firstName: string;
  lastName: string;
  organization: string;
  status: string;
  roleIds: string[];
  actor: Actor | null;
  approvalJustification: string;

  constructor(
    userId?: string,
    remoteIdentifier?: string,
    email?: string,
    displayName?: string,
    firstName?: string,
    lastName?: string,
    organization?: string,
    status?: string,
    roleIds?: string[],
    actor?: Actor | null,
    approvalJustification?: string,
  ) {
    this.userId = userId || "";
    this.remoteIdentifier = remoteIdentifier || "";
    this.email = email || "";
    this.displayName = displayName || "";
    this.firstName = firstName || "";
    this.lastName = lastName || "";
    this.organization = organization || "";
    this.status = status || "";
    this.roleIds = roleIds || [];
    this.actor = actor || null;
    this.approvalJustification = approvalJustification || "";
  }
}
