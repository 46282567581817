<template>
  <div>
    <basic-dialog
      id="bulk-edit-dialog"
      :title="title"
      :primaryButton="primaryButtonText"
      secondaryButton="Cancel"
      :primaryClick="bulkEdit"
      :secondaryClick="cancel"
      :isDisabled="!users.length"
    >
      <div v-if="!isBulkEdit" class="usa-alert usa-alert--info usa-alert--slim">
        <div class="usa-alert__body">
          <p class="usa-alert__text">Approved users require a role.</p>
        </div>
      </div>

      <form class="usa-form margin-bottom-2">
        <fieldset class="usa-fieldset margin-top-2">
          <legend class="usa-legend usa-legend">Select Roles</legend>
          <role-selection :roles="roles" @emitRoles="setSelectedRoles($event)" ref="roleSelection"></role-selection>
          <span class="usa-error-message" id="input-error-message" v-if="validRoleSelection(selectedRoles)"
            >At least 1 role must be selected</span
          >
          <div class="usa-alert usa-alert--info usa-alert--slim margin-top-5" v-if="!users.length">
            <div class="usa-alert__body">
              <p class="usa-alert__text">At least 1 user must be selected from the table.</p>
            </div>
          </div>
        </fieldset>
      </form>

      <template v-if="users.length">
        <p class="margin-top-3 margin-bottom-1">The selected roles will be assigned to the following users:</p>
        <ul class="usa-list">
          <li v-for="user in users" :key="user.userId">
            {{ user.displayName }}
          </li>
        </ul>
      </template>
    </basic-dialog>
    <success-toast id="user-bulk-edit-toast" message="The users have successfully been updated."></success-toast>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import RoleSelection from "@/components/navigation/UserManagement/RoleSelection.vue";
import { User } from "@/dataModel";

@Component({
  components: {
    BasicDialog,
    RoleSelection,
  },
})
export default class BulkEditDialog extends Vue {
  @Prop(Array) readonly roles: [] | undefined;
  @Prop({ required: true }) isBulkEdit!: boolean;
  @Prop({ default: () => [] }) users!: User[];

  selectedRoles: string[] = [];
  checkValidation = false;

  get title(): string {
    return this.isBulkEdit ? "Bulk Edit" : "Assign Roles";
  }

  get primaryButtonText(): string {
    return this.isBulkEdit ? "Save Changes" : "Confirm";
  }

  validRoleSelection(input: string[]) {
    return this.checkValidation && input.length == 0;
  }

  validForm() {
    return this.selectedRoles.length > 0 && this.users.length > 0;
  }

  setSelectedRoles(roles: string[]) {
    this.selectedRoles = roles;
  }

  clearUser() {
    this.checkValidation = false;
    this.selectedRoles = [];
  }

  cancel() {
    this.$bvModal.hide("bulk-edit-dialog");
  }

  bulkEdit() {
    if (this.validForm()) {
      const event = this.isBulkEdit ? "bulk-edit" : "approve";
      this.$emit(event, this.selectedRoles);
      this.$bvModal.hide("bulk-edit-dialog");
    } else {
      this.checkValidation = true;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.usa-form {
  width: 100%;
  max-width: 100%;
}
</style>
