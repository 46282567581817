<template>
  <data-table
    @sortingChanged="sortingChanged"
    @add="addFeaturedDocument"
    :items="documents"
    :fields="headers"
    :actions="actions"
    :perPage="resultsPerPage"
    :page="page"
    ref="documentTable"
  >
    <template #cell(title)="data">
      <div class="margin-y-05 table-col-md overflow-ellipsis" :title="data.value">
        <span class="title-classification">{{ data.item.titleClassificationMarking }} </span>
        <a @click="viewDocument(data.item)" class="data-table-item-action usa-link pointer">
          <span>{{ data.value }}</span>
        </a>
      </div>
    </template>
    <template #cell(classificationMarking)="data">
      <div class="margin-y-05 table-col-xs overflow-ellipsis" :title="data.value">
        {{ data.value }}
      </div>
    </template>
  </data-table>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import DataTable from "@/components/common/DataTable.vue";
import { DataTableAction, DataTableHeader } from "@/dataModel/interfaces";
import { SiteDocument } from "@/dataModel";
import { SearchSiteDocumentsRequest } from "@/dataModel/requests";

@Component({
  components: {
    DataTable,
  },
})
export default class SiteDocumentsTable extends Vue {
  sortOrder: string = "ascending";
  sortColumn: string = "title";

  @Prop({ required: true }) searchParameters!: SearchSiteDocumentsRequest;
  @Prop({ default: [] }) documents!: SiteDocument[];

  get page(): number {
    return this.searchParameters.page;
  }

  get resultsPerPage(): number {
    return this.searchParameters.resultsPerPage;
  }

  headers: DataTableHeader[] = [
    {
      label: "Title",
      key: "title",
      sortable: true,
    },
    {
      label: "Classification",
      key: "classificationMarking",
      sortable: true,
    },
    {
      label: "Modified",
      key: "lastUpdatedDateTime",
      sortable: true,
      formatter: (val) => this.$options.filters?.formatDate(val),
    },
    {
      label: "Status",
      key: "isPublished",
      sortable: true,
      formatter: this.formatIsPublished,
    },
    {
      // header for actions
      label: "",
      key: "actions",
      sortable: false,
    },
  ];

  actions: DataTableAction[] = [
    {
      name: "Add",
      icon: "fa-plus",
      action: "add",
    },
  ];

  formatIsPublished(isPublished: boolean): string {
    if (isPublished) {
      return "Published";
    } else {
      return "Unpublished";
    }
  }

  viewDocument(document: SiteDocument): void {
    this.$router.push({ name: "DocumentSummary", query: { id: document?.id } });
  }

  sortingChanged(ctx: any): void {
    this.sortColumn = ctx.sortBy;
    this.sortOrder = ctx.sortDesc ? "descending" : "ascending";
    if (this.sortColumn === "lastUpdatedDateTime") {
      this.sortColumn = "updated";
    }
    this.emitSortOrder(this.sortOrder);
    this.emitSortColumn(this.sortColumn);
    this.search();
  }

  @Emit()
  search(): void {}

  @Emit()
  emitSortOrder(sortOrder: string): string {
    return sortOrder;
  }

  @Emit()
  emitSortColumn(sortColumn: string): string {
    return sortColumn;
  }

  initializeTableSearchParameters(): void {
    this.emitSortOrder(this.sortOrder);
    this.emitSortColumn(this.sortColumn);
  }

  @Emit()
  addFeaturedDocument(document: SiteDocument): SiteDocument {
    return document;
  }

  created(): void {
    this.initializeTableSearchParameters();
  }
}
</script>

<style scoped lang="scss"></style>
