<template>
  <basic-dialog id="response-status-dialog" primaryButton="Close" :primaryClick="close" :isDisabled="false">
    <div>
      <h1 class="status-message">
        {{ reponseStatusMessage }}<i class="fa fa-check-circle fa-lg ml-3" aria-hidden="true" />
      </h1>
      <button
        v-if="hasValue(parentCategoryId)"
        class="usa-button usa-button--unstyled"
        @click="viewDataPath(parentCategoryId)"
      >
        View data path to new category
      </button>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";

@Component({
  components: {
    BasicDialog,
  },
})
export default class ResponseStatusDialog extends Vue {
  @Prop({ required: true }) requestType!: string;
  @Prop({ required: false }) parentCategoryId: string | undefined;

  get reponseStatusMessage() {
    switch (this.requestType) {
      case "create":
        return "Category Created Successfully";
      case "update":
        return "Category Updated Successfully";
      case "delete":
        return "Category Deleted Successfully";
    }
  }

  @Emit()
  viewDataPath(id: string): string {
    this.close();
    return id;
  }

  close(): void {
    this.$bvModal.hide("response-status-dialog");
  }

  hasValue(str: string): boolean {
    return str != null && str != undefined && str.length > 0;
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";
.status-message {
  color: color("primary-dark");
  font-family: family("heading");
  font-size: size("heading", "lg");
}
</style>
