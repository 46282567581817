import { SiteDocument, StagedFile, TitlePermissionIds } from "../";

export class CreateSiteDocumentRequest {
  titlePermissionIds?: TitlePermissionIds;
  classification?: string;
  disseminationControls?: string[];
  scis?: string[];
  audiences?: string[];
  title?: string;
  documentType?: string;
  contributors?: string[];
  pages?: string;
  journalName?: string;
  isPublished?: boolean;
  doi?: string;
  volume?: string;
  abstract?: string;
  documentDateTime?: Date;
  isCopyrightRestricted?: boolean;
  categoryIds?: string[];
  documentDatePrecision?: string;
  stagedFileId?: string;
  fileName?: string;
  location?: string;
  constructor(
    titlePermissionIds?: TitlePermissionIds,
    classification?: string,
    disseminationControls?: string[],
    scis?: string[],
    audiences?: string[],
    title?: string,
    documentType?: string,
    contributors?: string[],
    pages?: string,
    journalName?: string,
    isPublished?: boolean,
    doi?: string,
    volume?: string,
    abstract?: string,
    documentDateTime?: Date,
    isCopyrightRestricted?: boolean,
    categoryIds?: string[],
    documentDatePrecision?: string,
    stagedFileId?: string,
    fileName?: string,
    location?: string,
  ) {
    this.titlePermissionIds = titlePermissionIds || new TitlePermissionIds();
    this.classification = classification ?? "";
    this.disseminationControls = disseminationControls ?? [];
    this.scis = scis ?? [];
    this.audiences = audiences ?? [];
    this.title = title ?? "";
    this.documentType = documentType ?? "";
    this.contributors = contributors ?? [];
    this.pages = pages ?? "";
    this.journalName = journalName ?? "";
    this.isPublished = isPublished ?? false;
    this.doi = doi ?? "";
    this.volume = volume ?? "";
    this.abstract = abstract ?? "";
    this.documentDateTime = documentDateTime;
    this.isCopyrightRestricted = isCopyrightRestricted ?? true;
    this.categoryIds = categoryIds ?? [];
    this.documentDatePrecision = documentDatePrecision ?? "";
    this.stagedFileId = stagedFileId ?? "";
    this.fileName = fileName ?? "";
    this.location = location ?? "";
  }

  public static CreateNew(document: SiteDocument, file: StagedFile): CreateSiteDocumentRequest {
    const knowRes = document.knowledgeResource;

    const titlePermissionIds = new TitlePermissionIds(
      knowRes.titleClassifications.classification!.id,
      knowRes.titleClassifications.disseminationControls!.map((x) => x.id),
      knowRes.titleClassifications.scis!.map((x) => x.id),
    );

    return new CreateSiteDocumentRequest(
      titlePermissionIds,
      knowRes.classification.id,
      knowRes.disseminationControls.map((x) => x.id),
      knowRes.scis.map((x) => x.id),
      knowRes.audiences.map((x) => x.id),
      document.title,
      document.documentType,
      document.contributors,
      document.pages,
      document.journalName,
      document.isPublished,
      document.doi,
      document.volume,
      document.abstract,
      document.documentDateTime,
      document.isCopyrightRestricted,
      document.categoryIds,
      document.documentDatePrecision,
      file.id,
      file.fileName,
      "", //location
    );
  }
}
