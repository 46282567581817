<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="padding-top-2 padding-bottom-2">
          <button class="usa-button usa-button--unstyled" @click="goToPreviousPage">
            <i class="fa fa-arrow-left margin-right-05" />Back
          </button>
        </div>
      </div>

      <download-original-file
        :fileName="fileMetadata?.fileName"
        :fileType="fileMetadata?.fileType"
        :fileContents="originalFileContents"
        :availableForDownload="availableForDownload"
        @downloadOriginalFile="getOriginalFileContents"
      />

      <p v-if="!availableForDownload" class="usa-prose margin-top-1">
        Note: This document is not available for download.
      </p>

      <pdf-viewer v-if="file" :file="file" :allowDownload="availableForDownload" class="margin-top-2" />
      <template v-else>
        <div v-if="!hasError" class="margin-top-3 d-flex flex-column align-items-center">
          <spinner class="spinner margin-bottom-105"></spinner>
          Loading file...
        </div>

        <div v-else class="usa-alert usa-alert--error margin-x-auto margin-top-6" role="alert">
          <div class="usa-alert__body">
            <p class="usa-alert__text">
              An error occurred while loading the file.
              <a @click.prevent="retry" href="#/">Click here to retry</a>.
            </p>
          </div>
        </div>
      </template>
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import Spinner from "@/components/common/Spinner.vue";
import PdfViewer from "@/components/common/PdfViewer.vue";
import RequestHttpHelper from "@/components/resources/requestHttpHelper";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import axios from "axios";
import { RequestFileMetadata } from "@/dataModel";
import store from "@/store";
import StoreNames from "@/constants/store/StoreNames";
import { RequestStoreGetters } from "@/constants/store/request/requestStoreConstants";
import DownloadOriginalFile from "@/components/common/DownloadOriginalFile.vue";

@Component({
  components: {
    PdfViewer,
    Spinner,
    DownloadOriginalFile,
  },
})
export default class RequestFileView extends Vue {
  hasError = false;
  file: string | null = null;
  fileMetadata: RequestFileMetadata | null = null;
  originalFileContents: string | null = null;

  @Prop() id!: string;

  private requestHttpHelper = container.get<RequestHttpHelper>(serviceTypes.RequestHttpHelper);

  get availableForDownload(): boolean {
    return true;
  }

  async getFile(): Promise<void> {
    try {
      let cancelToken = axios.CancelToken.source();
      const file: string | void = await this.requestHttpHelper.downloadFile(this.id, cancelToken, true);
      if (file) {
        this.file = file;
      }
    } catch (error) {
      // error downloading document
      this.hasError = true;
    }
  }

  async getOriginalFileContents(): Promise<void> {
    let cancelToken = axios.CancelToken.source();
    let response = await this.requestHttpHelper.downloadFile(this.id, cancelToken, false);
    if (response) {
      this.originalFileContents = response;
    }
  }

  goToPreviousPage(): void {
    this.$router.go(-1);
  }

  retry(): void {
    this.hasError = false;
    this.getFile();
  }

  async created(): Promise<void> {
    this.fileMetadata = store.getters[`${StoreNames.Request}/${RequestStoreGetters.GET_REQUEST_FILE_METADATA}`];
    await this.getFile();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.usa-alert {
  width: 615px;

  & > .usa-alert__body {
    padding-right: 52px;
  }
}
</style>
