import { PermissionStoreGetters } from "@/constants/store/permission/permissionStoreConstants";
import { Classification, DisseminationControl, Sci, KnowledgeResourceAudience } from "@/dataModel";
import { PermissionState } from "@/typings/store/states/PermissionStoreState";

export default {
  [PermissionStoreGetters.GET_CLASSIFICATIONS]: (state: PermissionState): Classification[] => state.classifications,
  [PermissionStoreGetters.GET_DISSEMINATION_CONTROLS]: (state: PermissionState): DisseminationControl[] =>
    state.disseminationControls,
  [PermissionStoreGetters.GET_SCIS]: (state: PermissionState): Sci[] => state.scis,
  [PermissionStoreGetters.GET_AUDIENCES]: (state: PermissionState): KnowledgeResourceAudience[] => state.audiences,
};
