<template>
  <div>
    <h1 class="header">{{ errorTitle }}</h1>
    <p class="usa-prose">{{ errorMessage }}</p>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class InvalidSearch extends Vue {
  @Prop({ required: true }) errorTitle!: string;
  @Prop({ required: true }) errorMessage!: string;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

.bold {
  font-weight: font-weight("bold");
}

.header {
  font-family: family("heading");
  font-size: size("heading", "lg");
  line-height: lh("heading", 2);
  font-weight: font-weight("bold");
}
</style>
