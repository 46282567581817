import { Permission } from "./Permission";

export class Role {
  id: string;
  name: string;
  permissions: Permission[];

  constructor(id?: string, name?: string, permissions?: Permission[]) {
    this.id = id || "";
    this.name = name || "";
    this.permissions = permissions || [];
  }
}
