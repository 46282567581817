import {
  DhsRequestUsageAnalytics,
  DocumentUsageAnalytics,
  DownloadUsageAnalytics,
  LoginUsageAnalytics,
  UserSearchUsageAnalytics,
} from "./specificUsageDetails";

export default class UsageAnalyticsDetails {
  logins?: LoginUsageAnalytics;
  documents?: DocumentUsageAnalytics;
  downloads?: DownloadUsageAnalytics;
  userSearches?: UserSearchUsageAnalytics;
  dhsRequests?: DhsRequestUsageAnalytics;
}
