<template>
  <basic-dialog
    id="delete-data-collection-dialog"
    title="Confirm Delete"
    primaryButton="Confirm"
    secondaryButton="Cancel"
    :primaryClick="confirm"
    :secondaryClick="cancel"
  >
    <p class="usa-prose mt-3">Are you sure you want to delete this data collection?</p>
  </basic-dialog>
</template>

<script lang="ts">
import { Vue, Component, Emit } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";

@Component({
  components: {
    BasicDialog,
  },
})
export default class ConfirmDeleteCollectionDialog extends Vue {
  @Emit()
  confirm(): void {
    this.cancel();
  }

  cancel(): void {
    this.$bvModal.hide("delete-data-collection-dialog");
  }
}
</script>

<style scoped lang="scss"></style>
