import FormatType from "@/constants/FormatType";
import { Category, FilterNodeInformation } from "@/dataModel";
import { CategoryPathItem } from "@/dataModel/interfaces";
import { injectable } from "inversify";
import ICategoryService from "./interfaces/ICategoryService";

@injectable()
export default class CategoryService implements ICategoryService {
  public getCategoryPath(parentCategoryId: string, flatCategoryList: Category[]): CategoryPathItem[] {
    const path: CategoryPathItem[] = [];
    let currentCategoryId = parentCategoryId;
    if (parentCategoryId) {
      while (currentCategoryId != null) {
        const currentCategory: Category = flatCategoryList.find((category) => category.id == currentCategoryId)!;
        const pathItem: CategoryPathItem = {
          displayName: currentCategory.displayName,
          id: currentCategory.id,
          formatType: currentCategory.formatType,
        };
        path.unshift(pathItem);
        currentCategoryId = currentCategory.parentId;
      }
    }
    return path;
  }

  public isItalicFormat(category: Category): boolean {
    return category.formatType === FormatType.Italic;
  }

  public addCategoryTreeSelectProperties(tree: FilterNodeInformation[]): FilterNodeInformation[] {
    for (let i = 0; i < tree.length; i += 1) {
      // add properties that vue-tree-select is expecting
      tree[i].id = tree[i].value.id;
      tree[i].label = tree[i].stringValue ?? "";
      tree[i].formatType = tree[i].value.formatType;

      if (tree[i].children != null && tree[i].children!.length > 0) {
        // recursively walk through tree adding properties for children
        tree[i].hasChildren = true;
        this.addCategoryTreeSelectProperties(tree[i].children!);
      } else {
        /* if the filter node does not have children, delete children property to prevent delayed loading. 
           More information can found at https://vue-treeselect.js.org/ under the "Delayed Loading" section. */
        tree[i].hasChildren = false;
        delete tree[i].children;
      }
    }

    return tree;
  }

  public getCategoryTreeFromList(list: Category[]): Category[] {
    const map = {};
    let node: Category;
    const roots: Category[] = [];

    for (let i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
      list[i].label = list[i].displayName;
    }

    for (let i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parentId !== null) {
        list[map[node.parentId]].children!.push(node);
      } else {
        roots.push(node);
      }
    }

    this.removeChildrenFromLeaf(roots);

    return roots;
  }

  private removeChildrenFromLeaf(list: Category[]): void {
    for (let i = 0; i < list.length; i++) {
      if (list[i].children!.length === 0) {
        delete list[i].children;
      } else {
        this.removeChildrenFromLeaf(list[i].children!);
      }
    }
  }
}
