import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import { DataCollectionState } from "@/typings/store/states/DataCollectionStoreState";

const state: DataCollectionState = {
  dataCollection: null,
  dataValue: null,
  hazards: null,
  hazardProperty: null,
  hazardType: null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
