<template>
  <div>
    <basic-dialog
      id="bulk-edit-documents-dialog"
      title="Bulk Edit"
      primaryButton="Confirm"
      secondaryButton="Cancel"
      :primaryClick="confirm"
      :secondaryClick="cancel"
      :isDisabled="selectedDocumentCount == 0"
    >
      <div
        class="usa-alert usa-alert--error usa-alert--slim width-mobile-sm"
        v-show="!formIsValid() && checkValidation"
      >
        <div class="usa-alert__body">
          <p class="usa-alert__text" role="alert">At least one field must be set.</p>
        </div>
      </div>

      <fieldset class="usa-fieldset margin-top-3">
        <legend class="usa-legend">Site Publication Status</legend>
        <div class="display-flex">
          <div class="usa-radio">
            <input
              class="usa-radio__input"
              id="status-published"
              type="radio"
              name="publication-status"
              :value="true"
              v-model="isPublished"
            />
            <label class="usa-radio__label" for="status-published"> Published </label>
          </div>
          <div class="usa-radio margin-left-2em">
            <input
              class="usa-radio__input"
              id="status-unpublished"
              type="radio"
              name="publication-status"
              :value="false"
              v-model="isPublished"
              aria-describedby="status-error-message"
            />
            <label class="usa-radio__label" for="status-unpublished"> Unpublished </label>
          </div>
        </div>
      </fieldset>

      <label class="usa-label" for="document-type">Document Type</label>
      <select
        class="usa-select"
        name="document-type"
        id="document-type"
        v-model="documentType"
        aria-describedby="document-type-error-message"
      >
        <option value>- Select -</option>
        <option v-for="type in documentTypes" :key="type.value" :value="type.value">
          {{ type.displayName }}
        </option>
      </select>

      <label class="usa-label" for="category-tree">Categories</label>
      <treeselect
        id="category-tree"
        name="category-tree"
        v-model="categories"
        :flat="true"
        :multiple="true"
        :options="options"
      />

      <div class="usa-alert usa-alert--info usa-alert--slim margin-top-5" v-if="selectedDocumentCount == 0">
        <div class="usa-alert__body">
          <p class="usa-alert__text">At least 1 document must be selected from the table.</p>
        </div>
      </div>
    </basic-dialog>
    <success-toast id="confirm-bulk-edit" message="Documents have successfully been updated"></success-toast>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import { BulkUpdateDocument } from "@/dataModel/interfaces";
import { FilterNodeInformation } from "@/dataModel";
import Treeselect from "@/components/common/CategoryTreeSelect.vue";
import { DocumentMixin } from "@/mixins";

@Component({
  components: {
    Treeselect,
    BasicDialog,
  },
})
export default class BulkEditDialog extends Mixins(DocumentMixin) {
  @Prop(Number) readonly selectedDocumentCount!: number;
  @Prop() readonly options!: FilterNodeInformation[];

  checkValidation: boolean = false;
  isPublished: boolean | null = null;
  documentType: string = "";
  categories: string[] = [];

  formIsValid(): boolean {
    return this.isPublished != null || this.documentType.length > 0 || this.categories.length > 0;
  }

  isEmpty(input: string): boolean {
    return input.length === 0 && this.checkValidation;
  }

  removeSpaces(str: string): string {
    return str.replace(" ", "");
  }

  clearForm(): void {
    this.checkValidation = false;
    this.documentType = "";
    this.isPublished = null;
    this.categories = [];
  }

  cancel(): void {
    this.$bvModal.hide("bulk-edit-documents-dialog");
  }

  confirm(): void {
    this.checkValidation = true;
    if (this.formIsValid()) {
      const newBulkUpdateResponse: BulkUpdateDocument = {
        documentType: this.documentType,
        isPublished: this.isPublished,
        categories: this.categories,
      };
      this.$emit("confirm", newBulkUpdateResponse);
      this.$bvModal.hide("bulk-edit-documents-dialog");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/assets/vue-treeselect/modified-vue-treeselect.css";

.usa-form {
  width: 100%;
  max-width: 100%;
}

.radio-btn {
  float: left;
  width: 33%;
}

.clear-float {
  clear: both;
}

.fit {
  width: fit-content;
}
</style>
