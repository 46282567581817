<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <div class="desktop:grid-col-12">
          <h1 class="font-heading-xl text-primary-dark margin-top-0 margin-bottom-5">Subscription Management</h1>
        </div>
        <div class="desktop:grid-col-12 usa-prose info">
          <p width="100%">
            The table below contains the documents and search terms you have subscribed to. By subscribing to a document
            or search term, you will receive notifications when the document is updated or when there are new results
            for a search term. Clicking settings will allow you to change the frequency of notifications. Clicking on a
            subscription will bring you to the associated document or search results.
          </p>
        </div>
      </div>

      <div class="grid-row margin-top-3">
        <button class="usa-button pl-5 pr-5 align-right mr-0" id="settings-button" @click="updateSettingsHandler()">
          Settings
        </button>
      </div>

      <div class="grid-row grid-gap margin-top-105em margin-bottom-2">
        <h2 class="font-heading-lg text-primary-dark margin-top-0 margin-bottom-0">Document Subscriptions</h2>
        <div class="desktop:grid-col-12">
          <div class="grid-row grid-gap">
            <div class="desktop:grid-col">
              <data-table
                @unsubscribe="unsubscribeHandler"
                :items="documentSubscriptions"
                :fields="documentSubscriptionHeaders"
                :actions="subscriptionActions"
                ref="documentSubscriptionTable"
              >
                <template #cell(title)="data">
                  <div class="margin-y-05 table-col-xl">
                    <button class="usa-button usa-button--unstyled" @click="goToDocument(data.item)">
                      <div class="table-col-xl overflow-ellipsis padding-y-05">
                        {{ data.value }}
                      </div>
                    </button>
                  </div>
                </template>
              </data-table>
            </div>
          </div>
        </div>
      </div>

      <div class="grid-row grid-gap margin-top-105em margin-bottom-2">
        <h2 class="font-heading-lg text-primary-dark margin-top-0 margin-bottom-0">Search Subscriptions</h2>
        <div class="desktop:grid-col-12">
          <div class="grid-row grid-gap">
            <div class="desktop:grid-col">
              <data-table
                @unsubscribe="unsubscribeHandler"
                :items="searchSubscriptions"
                :fields="searchSubscriptionHeaders"
                :actions="subscriptionActions"
                ref="searchSubscriptionTable"
              >
                <template #cell(searchTerm)="data">
                  <div class="margin-y-05 table-col-lg">
                    <button class="usa-button usa-button--unstyled" @click="goToSearchQuery(data.item)">
                      <div class="table-col-lg overflow-ellipsis padding-y-05">
                        {{ data.value }}
                      </div>
                    </button>
                  </div>
                </template>
                <template #cell(filters)="data">
                  <div class="filter-container">
                    <div class="margin-y-05 table-col-lg overflow-ellipsis">
                      {{ data.value }}
                    </div>
                    <i
                      v-if="JSON.parse(data.item.searchQuery).Filters.length > 0"
                      class="fa fa-info-circle text-primary-dark filter-tooltip"
                    >
                      <div class="tooltiptext">
                        <p
                          v-for="(filter, index) in JSON.parse(data.item.searchQuery).Filters"
                          :key="index"
                          class="usa-prose margin-bottom-0"
                        >
                          {{ formatToolTip(filter) }}
                        </p>
                      </div>
                    </i>
                  </div>
                </template>
              </data-table>
            </div>
          </div>
        </div>
      </div>
    </section>

    <notification-settings-dialog @confirm="updateSettings" ref="updateNotificationDialog" />
    <unsubscribe-dialog @confirm="unsubscribe" />
  </main>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Subscription, UserSearchParameters } from "@/dataModel";
import DataTable from "@/components/common/DataTable.vue";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import { DataTableHeader, DataTableAction, BreadcrumbPathItem } from "@/dataModel/interfaces";
import NotificationSettingsDialog from "@/components/navigation/SubscriptionManagement/NotificationSettingsDialog.vue";
import UnsubscribeDialog from "@/components/navigation/SubscriptionManagement/UnsubscribeDialog.vue";
import SubscriptionType from "@/constants/SubscriptionType";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import SiteDocumentHttpHelper from "@/components/resources/siteDocumentHttpHelper";
import store from "@/store";
import { AuthStoreGetters } from "@/constants/store/auth/authStoreConstants";
import StoreNames from "@/constants/store/StoreNames";
import NotificationHttpHelper, { Filter, SubscriptionState } from "@/components/resources/notificationHttpHelper";
import FrequencyType from "@/constants/FrequencyType";
import { UserSearchStoreActions } from "@/constants/store/userSearch/userSearchStoreConstants";
import PermissionService from "@/services/PermissionService";
import { FilterType } from "@/constants/FilterType";

@Component({
  components: {
    Breadcrumb,
    DataTable,
    NotificationSettingsDialog,
    UnsubscribeDialog,
  },
})
export default class SubscriptionManagementPage extends Vue {
  userId: string = "";
  notificationFrequency: FrequencyType = FrequencyType.WEEKLY;
  selectedSubscription!: Subscription;

  private notificationHttpHelper = container.get<NotificationHttpHelper>(serviceTypes.NotificationHttpHelper);
  private siteDocumentHttpHelper = container.get<SiteDocumentHttpHelper>(serviceTypes.SiteDocumentHttpHelper);
  private permissionService = container.get<PermissionService>(serviceTypes.PermissionService);

  documentSubscriptions: Subscription[] = [];
  searchSubscriptions: Subscription[] = [];
  documents: any[] = [];

  path: BreadcrumbPathItem[] = [
    {
      text: "My Content",
    },
    {
      text: "Subscriptions",
    },
  ];

  documentSubscriptionHeaders: DataTableHeader[] = [
    {
      label: "Title",
      key: "title",
      sortable: true,
    },
    {
      label: "Last Updated",
      key: "lastUpdatedDateTime",
      sortable: true,
      formatter: (val) => this.$options.filters?.formatDate(val),
    },
    {
      // header for actions
      label: "",
      key: "actions",
      sortable: false,
    },
  ];

  subscriptionActions: DataTableAction[] = [
    {
      name: "Unsubscribe",
      action: "unsubscribe",
      icon: "fa-bell",
    },
  ];

  searchSubscriptionHeaders: DataTableHeader[] = [
    {
      label: "Search Term",
      key: "searchTerm",
      sortable: true,
      formatter: this.formatSearchTerm,
    },
    {
      label: "Filters",
      key: "filters",
      sortable: true,
      formatter: this.formatFilters,
    },
    {
      label: "Last Updated",
      key: "lastUpdatedDateTime",
      sortable: true,
      formatter: (val) => this.$options.filters?.formatDate(val),
    },
    {
      // header for actions
      label: "",
      key: "actions",
      sortable: false,
    },
  ];

  async getSubscriptions(userId: string): Promise<void> {
    const response = await this.notificationHttpHelper.getSubscriptionsByUserId(userId);
    const subscriptions = response.map((el) => {
      return el.subscription;
    });
    this.documentSubscriptions = subscriptions.filter((sub) => {
      return sub.subscriptionType == SubscriptionType.Document;
    });

    this.mapSearchQueryProperties(response);
    const knowledgeResourceIds = this.documentSubscriptions.map((sub) => {
      return sub.knowledgeResourceId || "";
    });
    this.documents = await this.siteDocumentHttpHelper.getDocumentsByKnowledgeResourceIds(knowledgeResourceIds);
    this.mapDocumentProperties();
  }

  goToSearchQuery(subscription: Subscription): void {
    let searchParameters = new UserSearchParameters();
    searchParameters.filters = JSON.parse(subscription.searchQuery).Filters;

    const yearFilter = searchParameters.filters?.find((f) => f.FilterType === FilterType.Year);
    if (yearFilter?.Value) {
      const [from, to] = yearFilter.Value.split(" - ");
      searchParameters.startYear = from ? new Date(parseInt(from), 0) : undefined;
      searchParameters.endYear = to ? new Date(parseInt(to), 0) : searchParameters.startYear;
    }

    store.dispatch(`${StoreNames.UserSearch}/${UserSearchStoreActions.SET_SEARCH_PARAMETERS}`, searchParameters);
    store.dispatch(`${StoreNames.UserSearch}/${UserSearchStoreActions.SET_USE_SAVED_PARAMS}`, true);
    this.$router.push({
      name: "UserSearch",
      query: {
        searchTerm: subscription.searchTerm,
      },
    });
  }

  goToDocument(documentSubscription: Subscription): void {
    this.$router.push({ name: "DocumentSummary", query: { id: documentSubscription.siteDocumentId } });
  }

  updateSettingsHandler(): void {
    this.getNotificationSettings(this.userId);
    (<NotificationSettingsDialog>this.$refs.updateNotificationDialog).initialize(this.notificationFrequency);
    this.$bvModal.show("update-frequency-dialog");
  }

  async updateSettings(newFrequency: FrequencyType): Promise<void> {
    const response = await this.notificationHttpHelper.updateNotificationSetting(this.userId, newFrequency);
    this.notificationFrequency = response.requestedFrequency;
    this.$bvToast.show("notification-frequency-toast");
  }

  unsubscribeHandler(subscription: Subscription): void {
    this.selectedSubscription = subscription;
    this.$bvModal.show("confirm-unsubscribe-dialog");
  }

  async unsubscribe(): Promise<void> {
    await this.notificationHttpHelper.deleteSubscription(this.selectedSubscription.id);
    this.getSubscriptions(this.userId);
    this.$bvToast.show("unsubscribe-toast");
  }

  mapSearchQueryProperties(searchSubscriptionResponse: SubscriptionState[]): void {
    searchSubscriptionResponse = searchSubscriptionResponse.filter(
      (sub) => sub.subscription.subscriptionType == SubscriptionType.Query,
    );
    for (let i = 0; i < searchSubscriptionResponse.length; i++) {
      const subscription = searchSubscriptionResponse[i].subscription;
      const searchQuery = JSON.parse(subscription.searchQuery);
      if (subscription.searchQuery) {
        subscription.filters = searchQuery.Filters;
        subscription.searchTerm = searchQuery.SearchTerms;
      }
      subscription.lastUpdatedDateTime = searchSubscriptionResponse[i]
        .searchResults!.map((searchResult) => searchResult.createdDateTime)
        .reduce(function (prevNewestDate, currentNewestDate) {
          return currentNewestDate > prevNewestDate ? currentNewestDate : prevNewestDate;
        });
    }

    this.searchSubscriptions = searchSubscriptionResponse.map((el) => {
      return el.subscription;
    });
  }

  mapDocumentProperties(): void {
    for (let i = 0; i < this.documentSubscriptions.length; i++) {
      const subscription = this.documentSubscriptions[i];
      const siteDocument = this.documents.find(
        (x) => x.document.knowledgeResourceId == subscription.knowledgeResourceId,
      );
      Vue.set(subscription, "title", siteDocument.document.title);
      Vue.set(subscription, "siteDocumentId", siteDocument.document.id);
      Vue.set(subscription, "lastUpdatedDateTime", siteDocument.document.lastUpdatedDateTime);
    }
  }

  async getNotificationSettings(userId: string): Promise<void> {
    const response = await this.notificationHttpHelper.getNotificationSettingsForUser(userId);
    this.notificationFrequency = response.requestedFrequency;
  }

  formatFilters(filters: Filter[]): string {
    const filterStrings = filters.map((f) => {
      switch (f.FilterType) {
        case FilterType.Category:
        case FilterType.CopyrightRestriction:
        case FilterType.DocumentType:
          const lastUnderscore = f.Value.lastIndexOf("_");
          return this.addSpaces(f.Value.substring(lastUnderscore + 1));
        case FilterType.Classification:
          return this.permissionService.getClassifications().find((x) => x.id == f.Value)?.title;
        case FilterType.DisseminationControl:
          return f.Value == "Unrestricted"
            ? "No dissemination controls"
            : this.permissionService.getDisseminationControls().find((x) => x.id == f.Value)?.title;
        case FilterType.Sci:
          return f.Value == "Unrestricted"
            ? "No SCI restrictions"
            : this.permissionService.getScis().find((x) => x.id == f.Value)?.title;
        case FilterType.Audience:
          return f.Value == "Unrestricted"
            ? "No audience restrictions"
            : this.permissionService.getAudiences().find((x) => x.id == f.Value)?.fullName;
        case FilterType.Year:
          return f.Value;
      }
    });
    return filterStrings.join(", ");
  }

  formatSearchTerm(searchTerm: string): string {
    return searchTerm.replaceAll("-", " ");
  }

  formatToolTip(filter: Filter): string | undefined {
    switch (filter.FilterType) {
      case FilterType.Category:
        return this.addSpaces(filter.Value).replaceAll("_", " > ");
      case FilterType.CopyrightRestriction:
        return this.concatenateTooltip("Copyright Status", this.addSpaces(filter.Value));
      case FilterType.DocumentType:
        return this.concatenateTooltip("Document Type", this.addSpaces(filter.Value));
      case FilterType.Classification:
        return this.concatenateTooltip(
          "Classification",
          this.permissionService.getClassifications().find((x) => x.id == filter.Value)?.title!,
        );
      case FilterType.DisseminationControl:
        return this.concatenateTooltip(
          "Dissemination Control",
          filter.Value == "Unrestricted"
            ? "No dissemination controls"
            : this.permissionService.getDisseminationControls().find((x) => x.id == filter.Value)?.title!,
        );
      case FilterType.Sci:
        return this.concatenateTooltip(
          "SCI",
          filter.Value == "Unrestricted"
            ? "No SCI restrictions"
            : this.permissionService.getScis().find((x) => x.id == filter.Value)?.title!,
        );
      case FilterType.Audience:
        return this.concatenateTooltip(
          "Audience",
          filter.Value == "Unrestricted"
            ? "No audience restrictions"
            : this.permissionService.getAudiences().find((x) => x.id == filter.Value)?.fullName!,
        );
      case FilterType.Year:
        return this.concatenateTooltip("Year", filter.Value);
    }
  }

  concatenateTooltip(name: string, value: string) {
    return name + " > " + value;
  }

  addSpaces(str: string): string {
    return str.replace(/([a-z])([A-Z])/g, "$1 $2");
  }

  created(): void {
    const user = store.getters[`${StoreNames.Auth}/${AuthStoreGetters.GET_USER}`];
    this.userId = user.userId;
    this.getNotificationSettings(this.userId);
    this.getSubscriptions(this.userId);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.info p {
  max-width: 100%;
}

.align-right {
  margin-left: auto;
}

.table-link .usa-button:focus {
  z-index: 100;
}

.filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-tooltip {
  /* Container for our tooltip */
  position: relative;
}

.tooltiptext {
  white-space: nowrap;
  visibility: hidden;
  background-color: #000;
  color: white;
  padding: 8px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
}

.filter-tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
