import { FileLogAction } from ".";

export class StagedFileEventLog {
  id: number;
  stagedFileId: string;
  actorId: string;
  logAction: FileLogAction;
  note: string;
  logDateTime: Date;

  constructor(id: number, stagedFileId: string, note: string, logAction: FileLogAction, logDateTime: Date) {
    this.id = id;
    this.stagedFileId = stagedFileId;
    this.logAction = logAction;
    this.note = note;
    this.actorId = "";
    this.logDateTime = logDateTime;
  }
}
