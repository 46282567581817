import DatePrecision from "@/constants/DatePrecision";
import { FileType } from "@/constants/FileType";
import { KnowledgeResource } from "./knowledgeResource";

export class SiteDocument {
  id: string;
  knowledgeResource: KnowledgeResource;
  classificationMarking: string;
  titleClassificationMarking: string;
  isPublished: boolean;
  documentType: string;
  title: string;
  lastUpdatedDateTime?: Date;
  pages: string;
  isCopyrightRestricted: boolean;
  categoryIds: string[];
  doi?: string;
  abstract: string;
  contributors: string[];
  journalName?: string;
  documentDateTime?: Date;
  volume?: string;
  url?: string;
  documentDatePrecision?: DatePrecision;
  location?: string;
  categoryPaths?: string[][];
  knowledgeResourceId?: string;
  fileType?: FileType;
  fileName?: string;

  constructor(
    id?: string,
    knowledgeResource?: KnowledgeResource,
    classificationMarking?: string,
    titleClassificationMarking?: string,
    isPublished?: boolean,
    documentType?: string,
    title?: string,
    lastUpdatedDateTime?: Date,
    pages?: string,
    isCopyrightRestricted?: boolean,
    categoryIds?: string[],
    doi?: string,
    abstract?: string,
    contributors?: string[],
    journalName?: string,
    documentDateTime?: Date,
    volume?: string,
    url?: string,
    documentDatePrecision?: DatePrecision,
    location?: string,
    categoryPaths?: string[][],
    knowledgeResourceId?: string,
    fileType?: FileType,
    fileName?: string,
  ) {
    this.id = id ?? "";
    this.knowledgeResource = knowledgeResource ?? new KnowledgeResource();
    this.classificationMarking = classificationMarking ?? "UNKNOWN";
    this.titleClassificationMarking = titleClassificationMarking!;
    this.isPublished = isPublished ?? false;
    this.documentType = documentType ?? "";
    this.title = title ?? "";
    this.lastUpdatedDateTime = lastUpdatedDateTime ?? new Date(Date.now());
    this.pages = pages ?? "";
    this.isCopyrightRestricted = isCopyrightRestricted ?? true;
    this.categoryIds = categoryIds ?? [];
    this.doi = doi ?? "";
    this.abstract = abstract ?? "";
    this.contributors = contributors ?? [];
    this.journalName = journalName ?? "";
    this.documentDateTime = documentDateTime;
    this.volume = volume ?? "";
    this.url = url ?? "";
    this.documentDatePrecision = documentDatePrecision;
    this.location = location ?? "";
    this.categoryPaths = categoryPaths ?? [];
    this.knowledgeResourceId = knowledgeResourceId ?? "";
    this.fileType = fileType ?? FileType.Pdf;
    this.fileName = fileName ?? "";
  }
}
