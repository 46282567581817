import { HazardType } from "@/constants/HazardType";
import HazardDescriptor from "./common/hazardDescriptor";
import HazardDataValue from "./hazardDataValue";

export default class HazardPropertyDetails extends HazardDescriptor {
  valueDetails: HazardDataValue[];
  type: HazardType;

  constructor( 
    valueDetails?: HazardDataValue[],
    type?: HazardType,
  ) {
    super();
    this.valueDetails = valueDetails ?? [];
    this.type = type ?? HazardType.Unknown;
  }
}