import { FileUploadStatus } from "../constants/fileUploadStatus";
import { UploadStagedFileRequest, UploadDhsRequestFileRequest } from "./";
import { CancelTokenSource } from "axios";

export class UploadFileRequestWrapper {
  public constructor(request: UploadStagedFileRequest | UploadDhsRequestFileRequest) {
    this.request = request;
  }
  public request: UploadStagedFileRequest | UploadDhsRequestFileRequest;
  public status: FileUploadStatus = FileUploadStatus.Pending;
  public cancelToken?: CancelTokenSource;
}
