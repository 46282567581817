/**
 * Class used to store featured document from the Featured Hazard Data section
 */
export class FeaturedHazardDocument {
  knowledgeResourceId?: string;
  title: string;
  titleClassification?: string;

  constructor(knowledgeResourceId?: string, title?: string, titleClassification?: string) {
    this.knowledgeResourceId = knowledgeResourceId ?? "";
    this.title = title ?? "";
    this.titleClassification = titleClassification ?? "";
  }
}
