import HttpHelper from "@/components/resources/httpHelper";
import { inject, injectable } from "inversify";
import serviceTypes from "@/dependencyInjection/types";
import { Application } from "@/dataModel";

const applicationUrl = "application";

@injectable()
export default class ApplicationHttpHelper {
  httpHelper: HttpHelper;

  constructor(@inject(serviceTypes.HttpHelper) httpHelper: HttpHelper) {
    this.httpHelper = httpHelper;
  }

  async getAllApplications(): Promise<Application[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(applicationUrl)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error("Response returned " + response.status + " : " + response.statusText + " getting applications"),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting applications"));
        });
    });
  }

  async getApiVersionAsync(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(applicationUrl + "/info")
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(`Response returned ${response.status}: ${response.statusText} getting API version information`),
            );
          }
        })
        .catch((error) => {
          console.error(error.response);
        });
    });
  }
}
