<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <div class="desktop:grid-col-9">
          <h1 class="font-heading-xl text-primary-dark margin-top-0 margin-bottom-5">Create a Request for DHS S&T</h1>
          <div class="usa-prose">
            <p>Please submit a request if you would like to do any of the following:</p>
            <ul>
              <li>Request Information</li>
              <li>Request additional research to be performed</li>
              <li>Share data, reports, or tools through the HKMA site</li>
            </ul>
          </div>
        </div>

        <div class="desktop:grid-col-3 margin-left-auto requestButtonDiv">
          <button class="usa-button" @click="createRequestHandler" id="create-request-button">Create Request</button>
        </div>
      </div>

      <div class="grid-row grid-gap margin-top-105em margin-bottom-2">
        <div class="desktop:grid-col">
          <data-table
            @log="showLog"
            @sortingChanged="sortingChanged"
            :items="requests"
            :fields="mockHeaders"
            :actions="actions"
            :details="details"
            :perPage="perPage"
            :page="page"
            ref="userRequestTable"
          >
            <template #cell(title)="data">
              <div class="margin-y-05 table-col-xl overflow-ellipsis">
                {{ data.value }}
              </div>
            </template>
          </data-table>
        </div>
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import DataTable from "@/components/common/DataTable.vue";
import { RequestStoreActions } from "@/constants/store/request/requestStoreConstants";
import RequestHttpHelper from "@/components/resources/requestHttpHelper";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import { Request } from "@/dataModel";
import store from "@/store";
import { AuthStoreGetters } from "@/constants/store/auth/authStoreConstants";
import StoreNames from "@/constants/store/StoreNames";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import { DataTableAction, DataTableHeader, DataTableDetail, BreadcrumbPathItem } from "@/dataModel/interfaces";

@Component({
  components: {
    Breadcrumb,
    DataTable,
  },
})
export default class UserRequestsPage extends Vue {
  perPage: string = "50"; // Should I include page nav functionality?
  page: number = 1;
  requests: Request[] = [];
  userId: string = "";
  userDisplayName: string = "";

  private requestHttpHelper = container.get<RequestHttpHelper>(serviceTypes.RequestHttpHelper);

  path: BreadcrumbPathItem[] = [
    {
      text: "My Content",
    },
    {
      text: "My Requests",
    },
  ];

  actions: DataTableAction[] = [
    {
      name: "View",
      action: "log",
      icon: "",
    },
  ];

  details: DataTableDetail[] = [
    {
      name: "Title",
      key: "title",
      type: "string",
    },
    {
      name: "Last Updated",
      key: "lastUpdated",
      type: "date",
    },
    {
      name: "Status",
      key: "status",
      type: "string",
    },
  ];

  mockHeaders: DataTableHeader[] = [
    {
      label: "Title",
      key: "title",
      sortable: true,
    },
    {
      label: "Last Updated",
      key: "lastUpdated",
      sortable: true,
      formatter: (val) => this.$options.filters?.formatDate(val),
    },
    {
      label: "Status",
      key: "requestStatus",
      sortable: true,
    },
    {
      // header for actions
      label: "",
      key: "actions",
      sortable: false,
    },
  ];

  createRequestHandler(): void {
    this.$router.push({ name: "CreateUserRequest" });
  }

  showLog(request: Request): void {
    store.dispatch(`${StoreNames.Request}/${RequestStoreActions.SET_SELECTED_REQUEST}`, request);

    this.$router.push({ name: "UserRequestsLog" });
  }

  async getRequests(userId: string): Promise<void> {
    const response = await this.requestHttpHelper.getRequestsByUserId(userId);
    this.requests = response;
  }

  sortingChanged(ctx: any): void {
    this.requests.sort((request1, request2) => {
      const sortBy = ctx.sortBy as keyof Request;
      const first = ctx.sortDesc ? `${request2[sortBy]}` : `${request1[sortBy]}`;
      const second = ctx.sortDesc ? `${request1[sortBy]}` : `${request2[sortBy]}`;

      return first.localeCompare(second, undefined, {
        numeric: true,
        sensitivity: "variant",
        usage: "sort",
      });
    });
  }

  created(): void {
    const user = store.getters[`${StoreNames.Auth}/${AuthStoreGetters.GET_USER}`];
    this.userId = user.userId;
    this.userDisplayName = user.displayName;

    this.getRequests(this.userId);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.requestButtonDiv {
  position: relative;
}

#create-request-button {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
