<template>
  <!-- This router view displays the Featured Resource Management child components -->
  <router-view></router-view>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

@Component
export default class FeaturedResourceManagementBase extends Vue {}
</script>

<style scoped lang="scss"></style>
