import { Filter } from "@/components/resources/notificationHttpHelper";
import SortSelection from "@/constants/SortSelection";

export class UserSearchParameters {
  page?: number;
  resultsPerPage?: number;
  filters?: Filter[];
  sortSelection?: SortSelection;
  startYear?: Date;
  endYear?: Date;
}
