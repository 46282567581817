<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em mb-4">
        <div class="grid-col-1">
          <img
            :src="require('@/assets/img/icons/Properties.svg')"
            class="heading-icon"
            role="img"
            alt=""
            aria-hidden="true"
          />
        </div>
        <div class="grid-col-11 center-y">
          <h2 class="font-heading-xl text-primary-dark mb-0 ml-3">Explore Hazard Properties</h2>
        </div>
      </div>

      <div class="grid-row margin-top-2">
        <div class="grid-col">
          <p class="usa-prose mb-4">
            The HKMA is home to an ever-growing amount of hazard research data. To start exploring, select a hazard
            type, then select a hazard property and then search or browse for one or more hazards from the list below.
            After making your selection, click view property data to view the results.
          </p>
          <p class="usa-prose mb-4">
            Looking for a specific hazard, like Bacillus anthracis or Ricin?
            <router-link :to="{ name: 'ExploreByHazards' }" class="usa-link">
              Explore hazard data by hazard.
            </router-link>
          </p>
        </div>
      </div>

      <div class="grid-row">
        <div class="grid-col">
          <div class="grid-row margin-top-2">
            <h1 class="font-heading-lg text-bold text-primary-dark margin-top-0 margin-bottom-0">
              Select a Hazard Type
            </h1>
          </div>

          <div class="grid-row">
            <div class="grid-col">
              <hazard-type-selection @hazard-type-changed="setHazardType" />
            </div>
          </div>
        </div>
      </div>

      <div v-show="selectedHazardType != null && hazardTypeHasProperties" class="grid-row margin-top-2">
        <div class="grid-col">
          <h3 class="font-heading-lg text-bold text-primary-dark mb-0 mt-3">Select a Hazard Property</h3>
          <label class="usa-label" for="property-options">Hazard Properties</label>
          <select
            class="usa-select property-select"
            name="property-options"
            id="property-options"
            v-model="selectedHazardPropertyId"
          >
            <option value>- Select Hazard Property -</option>
            <option v-for="property in hazardPropertyOptions" :key="property.id" :value="property.id">
              {{ property.name }}
            </option>
          </select>
        </div>
      </div>
      <div
        v-show="selectedHazardType != null && taxonomyTree != null && !hazardTypeHasProperties"
        class="grid-row margin-top-4"
      >
        <div class="grid-col">
          <div>
            <p class="usa-prose text-bold">No hazard properties available</p>
          </div>
        </div>
      </div>

      <hr class="margin-y-4" />

      <hazard-search-and-browse
        v-show="selectedHazardType != null && isPropertyAndTaxonomyNotNull && taxonomyTree[0].value"
        :isSingleSelection="false"
        :selectedHazardPropertyId="selectedHazardPropertyId"
        :selectedHazardType="selectedHazardType"
        @onViewData="viewData"
        @onTaxonomyTreeUpdated="updateTaxonomyTree"
      />
      <div v-show="selectedHazardType != null && isPropertyAndTaxonomyNotNull && !taxonomyTree[0].value">
        <p class="usa-prose text-bold">No data values available</p>
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Mixins, Watch } from "vue-property-decorator";
import { BreadcrumbPathItem } from "@/dataModel/interfaces";
import HazardSearchAndBrowse from "./HazardSearchAndBrowse.vue";
import { FilterNodeInformation, Category } from "@/dataModel";
import { DataPageMixin } from "./DataPageMixin";
import { ExplorePageMixin } from "./ExplorePageMixin";
import store from "@/store";
import StoreNames from "@/constants/store/StoreNames";
import { DataCollectionStoreActions } from "@/constants/store/dataCollection/dataCollectionStoreConstants";
import HazardPropertyDetails from "@/dataModel/hazardData/hazardPropertyDetails";

@Component({
  components: {
    HazardSearchAndBrowse,
  },
})
export default class ExploreByPropertiesPage extends Mixins(DataPageMixin, ExplorePageMixin) {
  selectedHazardPropertyId: string = "";

  path: BreadcrumbPathItem[] = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Explore Hazard Data",
      url: "/hazard-data",
    },
    {
      text: "Explore by Properties",
    },
  ];

  viewData(selections: FilterNodeInformation[]): void {
    this.setDataCollectionStoreState(selections);
    this.$router.push({ name: "DataByProperty" });
  }

  get isPropertyAndTaxonomyNotNull(): boolean {
    return this.selectedHazardPropertyId.length > 0 && this.taxonomyTree != null;
  }

  get hazardTypeHasProperties(): boolean {
    return this.hazardPropertyOptions != null && this.hazardPropertyOptions.length > 0;
  }

  @Watch("selectedHazardType")
  async selectedHazardTypeWatcher(): Promise<void> {
    if (this.selectedHazardType != null) {
      await this.fetchHazardPropertiesByTypeAsync(this.selectedHazardType);
      this.selectedHazardPropertyId = "";
    }
  }

  setDataCollectionStoreState(selections: FilterNodeInformation[]): void {
    const selectedHazards: Category[] = selections.map((node) => node.value);
    const selectedProperty: HazardPropertyDetails = this.hazardPropertyOptions.filter(
      (p) => p.id === this.selectedHazardPropertyId,
    )[0];
    store.dispatch(`${StoreNames.DataCollection}/${DataCollectionStoreActions.SET_SELECTED_HAZARDS}`, selectedHazards);
    store.dispatch(
      `${StoreNames.DataCollection}/${DataCollectionStoreActions.SET_SELECTED_HAZARD_PROPERTY}`,
      selectedProperty,
    );
    store.dispatch(
      `${StoreNames.DataCollection}/${DataCollectionStoreActions.SET_SELECTED_HAZARD_TYPE}`,
      this.selectedHazardType,
    );
  }
}
</script>
