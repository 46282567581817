export enum SettingStoreActions {
  SET_AI_SETTING = "SET_AI_SETTING",
}

export enum SettingStoreGetters {
  GET_AI_SETTING = "GET_AI_SETTING",
}

export enum SettingStoreMutations {
  SET_AI_SETTING = "SET_AI_SETTING",
}
