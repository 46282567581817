<template>
  <header class="usa-header margin-top-3">
    <div class="grid-container">
      <div class="grid-row">
        <div class="grid-col-6">
          <h1 class="usa-hero__heading">
            <router-link :to="{ name: 'Home' }">
              <img
                @click="homepageRedirect"
                id="dhs-logo"
                class="height-9 width-9"
                :src="require('@/assets/img/dhs-logo.svg')"
                role="img"
                alt="DHS Logo"
                aria-label="Return to home page"
              />
            </router-link>
            <span id="logo-text">Hazard Knowledge Management & Analysis</span>
          </h1>
        </div>
        <div class="grid-col-6">
          <div v-if="isSignedIn" id="user-info">
            <p class="margin-0" v-html="loggedInTextHtml" />
            <a href="#" class="usa-link cursor-pointer" @click="signOut">Sign out</a>
          </div>
        </div>
      </div>
      <div class="grid-row" v-if="shouldShowNav">
        <nav aria-label="Primary navigation" class="usa-nav grid-col">
          <ul class="usa-nav__primary usa-accordion">
            <li class="usa-nav__primary-item" v-for="(item, index) in navBarItems" v-bind:key="item.label">
              <template v-if="item.children">
                <template v-if="item.children.length > 1">
                  <button
                    class="usa-accordion__button usa-nav__link usa-current"
                    aria-expanded="false"
                    :aria-controls="'basic-nav-section-' + index"
                  >
                    <span>{{ item.label }}</span>
                  </button>
                  <ul :id="'basic-nav-section-' + index" class="usa-nav__submenu" hidden>
                    <li v-for="child in item.children" v-bind:key="child.label" class="usa-nav__submenu-item">
                      <router-link :to="child.href" class="usa-nav__link"> {{ child.label }}</router-link>
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <router-link class="usa-nav__link" :to="item.children[0].href">
                    <span>{{ item.children[0].label }}</span>
                  </router-link>
                </template>
              </template>
              <template v-else>
                <router-link class="usa-nav__link" :to="item.href">
                  <span>{{ item.label }}</span>
                </router-link>
              </template>
            </li>
            <li class="usa-nav__primary-item">
              <a href="#" class="usa-link font-body-2xs cursor-pointer" @click="openAboutDialog">About</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <about-dialog v-if="isSignedIn" />
  </header>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import NavBarService from "@/services/NavBarService";
import IAuthService from "@/services/interfaces/IAuthService";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import AboutDialog from "../navigation/AboutDialog.vue";

interface NavItem {
  label: string;
  href?: string;
  children?: Array<NavItem>;
}

@Component({
  components: {
    AboutDialog,
  },
})
export default class NavBar extends Vue {
  private navBarService: NavBarService = new NavBarService();
  private isSignedIn: boolean = false;
  private navBarItems: Array<NavItem> = [];
  private auth = container.get<IAuthService>(serviceTypes.AuthService);

  //#region  Methods

  openAboutDialog() {
    this.$bvModal.show("about-dialog");
  }

  homepageRedirect() {
    this.$router.push({ name: "Home" }).catch((err) => {
      if (err.name != "NavigationDuplicated") {
        throw err;
      }
    });
  }

  signOut() {
    this.auth.logout();
  }

  //#endregion

  //#region  Getters

  get shouldShowNav(): boolean {
    const authState = this.auth.getAuthState();
    if (authState != null) {
      return authState.user != null && authState.user.userStatus == "Active";
    }
    return false;
  }

  get loggedInTextHtml() {
    const displayName = this.auth.getUser()?.displayName;
    return displayName ? `Logged in as <strong>${displayName}</strong>` : "";
  }

  //#endregion

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    const authState = this.auth.getAuthState();
    if (authState != null) {
      this.navBarItems = this.navBarService.getNavBarItems(authState.access);
      this.isSignedIn = authState.isAuthenticated;
    } else {
      this.isSignedIn = false;
      this.navBarItems = [];
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/_variables.scss";
@import "~@/assets/uswds/scss/uswds.scss";

.usa-nav__primary {
  & > .usa-nav__primary-item {
    // Divider lines
    &:first-child {
      border-left: none;
    }
    border-left: solid 1px color("gray-30");

    // style anchor tag inside each item
    & > a {
      @extend .font-body-2xs;
      @extend .padding-y-1;
      @extend .padding-x-2;
      color: color("primary");
      font-family: family("body");
      font-weight: font-weight("normal");
      line-height: 1;
      display: block;
    }
  }
}

.usa-nav__primary button {
  @extend .font-body-2xs;
  @extend .padding-y-1;
  @extend .padding-x-105;
  line-height: 1;
  color: color("primary");
  font-family: family("body");
  font-weight: font-weight("normal");

  &[aria-expanded="false"]:hover {
    background-color: transparent;
  }

  & > span {
    @extend .padding-x-05;
  }
}

.usa-nav__submenu .usa-nav__submenu-item a {
  @extend .font-body-2xs;
  font-family: family("body");
  font-weight: font-weight("normal");
  padding: 0;
  line-height: 1.3;
}

.usa-nav__primary button[aria-expanded="true"],
.usa-nav__submenu {
  background-color: #00578d;
}

.usa-hero__heading {
  padding-top: units(2);
  padding-bottom: units(2);
}

.usa-header {
  background-color: #f8f8f8;
  padding-bottom: units(1);
}

#dhs-logo {
  vertical-align: middle;

  &:hover {
    cursor: pointer;
  }
}

#logo-text {
  vertical-align: middle;
  display: inline-block;
  color: color("primary");
  font-family: family("heading");
  font-size: size("heading", "lg");
  line-height: lh("heading", 2);
  margin: 0;
  max-width: 290px;
  margin-left: units(3);
}

#user-info {
  @extend .font-body-2xs;
  padding-top: units(2);
  text-align: right;

  & > a {
    cursor: pointer;
  }
}
</style>
