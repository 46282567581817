<template>
  <basic-dialog
    id="decline-document-dialog"
    :title="`Decline Document${fileNames.length > 1 ? 's' : ''}`"
    primaryButton="Confirm"
    secondaryButton="Cancel"
    :primaryClick="declineDocuments"
    :secondaryClick="cancel"
    :isDisabled="disableDialog"
  >
    <p class="text-body-xs text-bold margin-bottom-0">Filename<span v-if="fileNames.length > 1">s</span>:</p>
    <p>{{ formatNames(fileNames) }}</p>
    <form class="usa-form maxw-full">
      <label class="usa-label" for="reason"> Reason for rejection </label>
      <textarea
        class="usa-textarea"
        id="reason"
        name="reason"
        :placeholder="`Enter the reason why the document${fileNames.length > 1 ? 's are' : ' is'} being declined...`"
        v-model.trim="reason"
        :disabled="files.length === 0"
      ></textarea>
      <span class="usa-error-message" id="documents-error-message" v-if="!formIsValid() && showValidation">
        Reason cannot be empty
      </span>
    </form>
    <div class="usa-alert usa-alert--info usa-alert--slim margin-top-5" v-if="disableDialog">
      <div class="usa-alert__body">
        <p class="usa-alert__text">
          {{ errorText }}
        </p>
      </div>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import { StagedFile } from "@/dataModel";
import FileStatus from "@/constants/FileStatus";

@Component({
  components: { BasicDialog },
})
export default class DeclineDocumentDialog extends Vue {
  @Prop({ required: true }) files!: StagedFile[];

  reason = "";
  showValidation = false;

  get fileNames(): string[] {
    return this.files.map((file) => file.fileName);
  }

  get disableDialog(): boolean {
    return (
      this.files.length === 0 ||
      this.filesContainStatus(FileStatus.Declined) ||
      this.filesContainStatus(FileStatus.Converted)
    );
  }

  get errorText(): string {
    if (this.filesContainStatus(FileStatus.Converted)) {
      return "Cannot decline files that are already converted.";
    }
    if (this.filesContainStatus(FileStatus.Declined)) {
      return "Cannot decline files that are already declined.";
    }
    return "At least 1 file must be selected from the table.";
  }

  filesContainStatus(status: FileStatus): boolean {
    return !this.files.every((file) => file.fileStatus !== status);
  }

  formIsValid(): boolean {
    // max length?
    return this.reason.length > 0;
  }

  declineDocuments(): void {
    this.showValidation = true;
    if (this.formIsValid()) {
      this.$emit("declineDocuments", this.reason);
      this.resetForm();
      this.$bvModal.hide("decline-document-dialog");
    }
  }

  resetForm(): void {
    this.reason = "";
    this.showValidation = false;
  }

  cancel(): void {
    this.resetForm();
    this.$bvModal.hide("decline-document-dialog");
  }

  formatNames(names: string[]): string {
    return names.join(", ");
  }
}
</script>

<style lang="scss" scoped></style>
