<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <div class="desktop:grid-col-9">
          <h1 class="font-heading-xl text-primary-dark margin-top-0">Create New Document</h1>
        </div>
      </div>

      <document-input-fields
        ref="createDocument"
        @fields="createNewDocument"
        @invalidForm="doneLoading"
        :isNewCopyrightRestricted="false"
        :stagedFile="stagedFileToUpdate"
      >
        <!-- Removing :document="preFilledDocument" while metadata auto extraction is WIP -->
        <template #form-slot>
          <p>
            <span class="text-bold">Filename:</span>
            {{ stagedFileToUpdate?.fileName }}
          </p>
        </template>
      </document-input-fields>

      <loading-buttons
        class="padding-top-3 width-tablet"
        @primaryButtonClick="createDocumentHandler"
        @secondaryButtonClick="navigate('ConvertToSiteDocuments')"
        :isLoading="isLoading"
        :secondaryButtonDisabled="isLoading"
        primaryButtonText="Create"
        secondaryButtonText="Back"
        loadingText="Creating document..."
      />

      <div v-if="errorText" class="pre-formatted usa-alert usa-alert--error usa-alert--slim">
        <div class="usa-alert__body">
          <p class="usa-alert__text">{{ errorText }}</p>
        </div>
      </div>

      <basic-dialog
        id="metadata-auto-extract-dialog"
        title="Metadata Extracted"
        primaryButton="OK"
        :primaryClick="close"
      >
        <p class="usa-prose mb-0">
          The document metadata has been automatically extracted. Please review for errors before continuing.
        </p>
      </basic-dialog>

      <error-toast id="auto-extraction-failed" :message="metadataAutoextractErrMsg" />
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import store from "@/store";
import StoreNames from "@/constants/store/StoreNames";
import { StagedFileStoreActions, StagedFileStoreGetters } from "@/constants/store/stagedFile/stagedFileStoreConstants";
import { StagedFile, SiteDocument, TitlePermissionIds } from "@/dataModel";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import { BreadcrumbPathItem } from "@/dataModel/interfaces";
import DocumentInputFields from "@/components/navigation/DocumentManagement/DocumentInputFields.vue";
import FileStatus from "@/constants/FileStatus";
import serviceTypes from "@/dependencyInjection/types";
import container from "@/dependencyInjection/config";
import SiteDocumentAdminHttpHelper from "@/components/resources/siteDocumentAdminHttpHelper";
import { CreateSiteDocumentRequest } from "@/dataModel/requests";
import LoadingButtons from "@/components/common/LoadingButtons.vue";
import { DocumentStoreActions, DocumentStoreGetters } from "@/constants/store/document/documentStoreConstants";
import BasicDialog from "@/components/common/BasicDialog.vue";
import ErrorToast from "@/components/common/ErrorToast.vue";

@Component({
  components: {
    Breadcrumb,
    DocumentInputFields,
    LoadingButtons,
    BasicDialog,
    ErrorToast,
  },
})
export default class CreateNewDocument extends Vue {
  preFilledDocument: SiteDocument | null = null;
  stagedFileToUpdate: StagedFile | null = null;
  isLoading: boolean = false;
  metadataAutoextractErrMsg: string = "Metadata auto-extraction currently unavailable.";

  @Prop({ required: true }) AISettingEnabled!: boolean;
  @Prop({ required: true }) isAutoExtractable!: boolean;
  @Prop({ required: true }) autoExtractErrors!: string[];

  private siteDocumentAdminHttpHelper = container.get<SiteDocumentAdminHttpHelper>(
    serviceTypes.SiteDocumentAdminHttpHelper,
  );

  path: BreadcrumbPathItem[] = [
    {
      text: "Administration",
    },
    {
      text: "Document Staging",
      url: "/document-staging",
    },
    {
      text: "Convert to Site Documents",
      url: "/document-staging/site-documents",
    },
    {
      text: "Create New Document",
    },
  ];
  errorText: string = "";

  createDocumentHandler(): void {
    this.isLoading = true;
    (<DocumentInputFields>this.$refs.createDocument).emitFields();
  }

  async createNewDocument(newDocument: SiteDocument): Promise<void> {
    this.errorText = "";

    if (this.stagedFileToUpdate) {
      this.stagedFileToUpdate.fileStatus = FileStatus.Converted;

      const request = CreateSiteDocumentRequest.CreateNew(newDocument, this.stagedFileToUpdate);

      try {
        // Call API
        await this.siteDocumentAdminHttpHelper.addSiteDocument(request);

        // Set state
        store.dispatch(`${StoreNames.StagedFile}/${StagedFileStoreActions.SET_NUMBER_OF_FILES_UPDATED}`, 1);

        // Navigate
        this.navigate("ConvertToSiteDocuments");
      } catch (err) {
        this.errorText = err as any;
        this.doneLoading();
      }
    }
  }

  navigate(location: string): void {
    this.$router.push({ name: location });
  }

  doneLoading(): void {
    this.isLoading = false;
  }

  close(): void {
    this.$bvModal.hide("metadata-auto-extract-dialog");
  }

  mounted(): void {
    this.preFilledDocument = store.getters[`${StoreNames.Document}/${DocumentStoreGetters.GET_SELECTED_DOCUMENT}`];
    if (this.preFilledDocument && this.autoExtractErrors.length > 0) {
      this.$bvModal.show("metadata-auto-extract-dialog");
    } else {
      if (!this.AISettingEnabled) {
        this.metadataAutoextractErrMsg = "Metadata auto-extraction is currently disabled by the administrator.";
      } else if (!this.isAutoExtractable) {
        this.metadataAutoextractErrMsg = "Metadata auto-extraction is unavailable for the uploaded file type.";
      }
      //this.$bvToast.show("auto-extraction-failed");
    }

    this.preFilledDocument = new SiteDocument();
    if (this.stagedFileToUpdate) {
      this.preFilledDocument.knowledgeResource = this.stagedFileToUpdate?.knowledgeResource;
    }
  }

  created(): void {
    this.stagedFileToUpdate =
      store.getters[`${StoreNames.StagedFile}/${StagedFileStoreGetters.GET_STAGED_FILES_TO_UPDATE}`][0];
  }

  beforeDestroy(): void {
    // clear state when navigating away
    store.dispatch(`${StoreNames.StagedFile}/${StagedFileStoreActions.SET_STAGED_FILES_TO_UPDATE}`, []);
    store.dispatch(`${StoreNames.Document}/${DocumentStoreActions.SET_SELECTED_DOCUMENT}`, null);
  }
}
</script>

<style scoped lang="scss">
.pre-formatted {
  white-space: pre;
}
</style>
