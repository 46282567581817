import { HazardDataStatus } from "@/constants/HazardDataStatus";

export default class BulkUpdateDataValueStatusRequest {
  dataValueIds: string[];
  status: HazardDataStatus;
  isPublished?: boolean;

  constructor(dataValueIds?: string[], status?: HazardDataStatus, isPublished?: boolean) {
    this.dataValueIds = dataValueIds ?? [];
    this.status = status ?? HazardDataStatus.AwaitingApproval;
    this.isPublished = isPublished;
  }
}
