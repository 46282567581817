import { render, staticRenderFns } from "./RequestFileView.vue?vue&type=template&id=0b1861b0&scoped=true"
import script from "./RequestFileView.vue?vue&type=script&lang=ts"
export * from "./RequestFileView.vue?vue&type=script&lang=ts"
import style0 from "./RequestFileView.vue?vue&type=style&index=0&id=0b1861b0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b1861b0",
  null
  
)

export default component.exports