<template>
  <data-table
    @sortingChanged="sortingChanged"
    :isLoadingData="isLoadingData"
    :items="dataCollections"
    :fields="headers"
    :perPage="resultsPerPage"
    :page="page"
    ref="dataCollectionTable"
  >
    <template #cell(title)="data">
      <div class="margin-y-05 table-col-title" :title="data.value">
        <div class="title-container">
          <span class="title-classification">{{ data.item.titleClassificationMarking }} </span>
          <a href="#/" @click="viewDataCollection(data.item.id)" class="data-table-item-action usa-link pointer">
            <span>{{ data.value }}</span>
          </a>
        </div>
      </div>
    </template>
    <template #cell(contributorDisplay)="data">
      <div class="margin-y-05 overflow-ellipsis table-col-contributor" :title="data.value">
        {{ data.value }}
      </div>
    </template>
    <template #cell(values)="row">
      <div class="margin-y-05">
        <div
          v-if="
            statusCounts[row.index].approvedDataValueCount > 0 ||
            statusCounts[row.index].awaitingApprovalDataValueCount > 0
          "
        >
          <div v-if="statusCounts[row.index].approvedDataValueCount > 0">
            <i class="fa fa-check icon text-light-green" />
            <span class="text-bold">{{ statusCounts[row.index].approvedDataValueCount }}</span> - Approved
          </div>
          <div v-if="statusCounts[row.index].awaitingApprovalDataValueCount > 0">
            <i class="fa fa-times icon text-red" />
            <span class="text-bold">{{ statusCounts[row.index].awaitingApprovalDataValueCount }}</span> - Need Approval
          </div>
        </div>
        <div v-else>No Values</div>
      </div>
    </template>
  </data-table>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import DataTable from "@/components/common/DataTable.vue";
import { DataTableHeader } from "@/dataModel/interfaces";
import DataSource from "@/dataModel/hazardData/dataSource";
import SearchSourcesRequest from "@/dataModel/hazardData/searchSourcesRequest";
import DataValueStatusCounts from "@/dataModel/hazardData/dataValueStatusCounts";

@Component({
  components: {
    DataTable,
  },
})
export default class DataCollectionTable extends Vue {
  sortOrder: string = "ascending";
  sortColumn: string = "title";

  @Prop({ required: true }) searchParameters!: SearchSourcesRequest;
  @Prop({ default: [] }) dataCollections!: DataSource[];
  @Prop({ default: [] }) statusCounts!: DataValueStatusCounts[];
  @Prop({ default: true }) isLoadingData!: boolean;

  get page(): number {
    return this.searchParameters.page;
  }

  get resultsPerPage(): number {
    return this.searchParameters.resultsPerPage;
  }

  headers: DataTableHeader[] = [
    {
      label: "Title",
      key: "title",
      sortable: true,
    },
    {
      label: "Authors",
      key: "contributorDisplay",
      sortable: true,
    },
    {
      label: "Data Values",
      key: "values",
      sortable: false,
    },
    {
      label: "Last Updated",
      key: "modifiedDateTime",
      sortable: true,
      formatter: (value) => {
        return this.$options.filters!.formatDate(value);
      },
    },
  ];

  sortingChanged(ctx: any): void {
    this.sortColumn = ctx.sortBy;
    this.sortOrder = ctx.sortDesc ? "descending" : "ascending";
    if (this.sortColumn === "modifiedDateTime") {
      this.sortColumn = "lastUpdated";
    }
    if (this.sortColumn === "contributorDisplay") {
      this.sortColumn = "contributor";
    }
    this.emitSortOrder(this.sortOrder);
    this.emitSortColumn(this.sortColumn);
    this.search();
  }

  @Emit()
  search(): void {}

  @Emit()
  emitSortOrder(sortOrder: string): string {
    return sortOrder;
  }

  @Emit()
  emitSortColumn(sortColumn: string): string {
    return sortColumn;
  }

  initializeTableSearchParameters(): void {
    this.emitSortOrder(this.sortOrder);
    this.emitSortColumn(this.sortColumn);
  }

  created(): void {
    this.initializeTableSearchParameters();
  }

  @Emit()
  viewDataCollection(id: string): string {
    return id;
  }
}
</script>

<style scoped lang="scss">
.table-col-title {
  width: 23rem;
}

.table-col-contributor {
  width: 14rem;
}

.icon {
  width: 1rem;
  margin-right: 0.5rem;
}

.text-light-green {
  color: rgb(0, 151, 63);
}

.data-table-item-action:focus {
  outline: none;
}

.title-container:focus-within {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0;
}
</style>
