<template>
  <basic-dialog
    id="update-publication-dialog"
    title="Change Publication"
    primaryButton="Confirm"
    secondaryButton="Cancel"
    :primaryClick="confirm"
    :secondaryClick="cancel"
    :isDisabled="!isFormValid"
  >
    <form class="usa-form mt-4">
      <fieldset class="usa-fieldset">
        <legend class="usa-legend usa-legend">Data Value Publication Status</legend>
        <div class="usa-radio">
          <input
            class="usa-radio__input"
            id="publish-input"
            type="radio"
            name="publish-status"
            :value="true"
            v-model="isPublished"
          />
          <label class="usa-radio__label" for="publish-input">Publish</label>
        </div>
        <div class="usa-radio">
          <input
            class="usa-radio__input"
            id="unpublish-input"
            type="radio"
            name="publish-status"
            :value="false"
            v-model="isPublished"
          />
          <label class="usa-radio__label" for="unpublish-input">Unpublish</label>
        </div>
      </fieldset>
    </form>

    <div class="usa-alert usa-alert--info usa-alert--slim margin-top-5" v-if="isNullOrEmpty(this.selectedDataValues)">
      <div class="usa-alert__body">
        <p class="usa-alert__text">At least 1 data value must be selected from the table.</p>
      </div>
    </div>
    <div
      class="usa-alert usa-alert--info usa-alert--slim margin-top-5"
      v-if="!isNullOrEmpty(this.selectedDataValues) && !containsOnlyApprovedValues"
    >
      <div class="usa-alert__body">
        <p class="usa-alert__text">Only approved data values can be changed.</p>
      </div>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import HazardDataValue from "@/dataModel/hazardData/hazardDataValue";
import { isNullOrEmpty } from "@/services/utils";
import { HazardDataStatus } from "@/constants/HazardDataStatus";

@Component({
  components: {
    BasicDialog,
  },
})
export default class UpdatePublicationStatusDialog extends Vue {
  isPublished: boolean | null = null;

  @Prop() selectedDataValues!: HazardDataValue[];

  cancel(): void {
    this.$bvModal.hide("update-publication-dialog");
  }

  confirm(): void {
    if (this.isFormValid) {
      this.$emit("confirm", this.isPublished);
      this.$bvModal.hide("update-publication-dialog");
    }
  }

  mounted(): void {
    this.intializeDialogOnOpen();
  }

  intializeDialogOnOpen(): void {
    this.$root.$on("bv::modal::show", () => {
      this.clearForm();
    });
  }

  clearForm(): void {
    this.isPublished = null;
  }

  get isFormValid(): boolean {
    return this.isPublished != null && !isNullOrEmpty(this.selectedDataValues) && this.containsOnlyApprovedValues;
  }

  get containsOnlyApprovedValues(): boolean {
    return this.selectedDataValues.every((value) => value.status === HazardDataStatus.Approved);
  }

  isNullOrEmpty(input: any): boolean {
    return isNullOrEmpty(input);
  }
}
</script>

<style scoped lang="scss"></style>
