<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <h1 class="font-heading-xl text-primary-dark margin-top-0">Convert to Site Documents</h1>
        <div class="margin-left-auto">
          <button @click="navigate('DocumentStaging')" class="usa-button usa-button--outline width-card">Back</button>
        </div>
      </div>

      <div class="grid-row border-top padding-top-205 margin-bottom-2">
        <h3 class="font-heading-lg text-bold text-primary-dark">Staged Documents</h3>
        <p class="font-body-xs">
          The list below contains the selected files to be converted into site documents. If you wish to automatically
          bulk update existing documents, select the files you wish to bulk update and then click Update Existing
          Documents. This will try to match the selected file name to a file name of an existing document. If one
          exists, then it will be automatically updated with the new file. If the file name does not match a file of any
          existing documents, it will need to be handled manually.
        </p>
      </div>

      <div>
        <data-table
          @bulkUpdate="bulkUpdateExistingDocuments"
          @create="createNewDocument"
          @update="updateExistingDocument"
          @selections="setSelectedFiles"
          @sortingChanged="sortingChanged"
          :perPage="50"
          :items="files"
          :fields="headers"
          :actions="actions"
          :bulkActions="bulkActions"
          :selected="selectedFiles"
        >
          <template #cell(fileName)="data">
            <div class="usa-checkbox margin-y-05 width-mobile">
              <input
                class="usa-checkbox__input"
                :id="data.item.id"
                type="checkbox"
                :name="data.value"
                v-model="selectedFiles"
                :value="data.item"
              />
              <label class="usa-checkbox__label margin-0 overflow-ellipsis" :title="data.value" :for="data.item.id"
                >{{ data.value }}
              </label>
            </div>
          </template>
        </data-table>
      </div>

      <success-toast id="files-updated-toast" :message="toastText" />
    </section>
    <basic-dialog id="error-dialog" primaryButton="OK" :primaryClick="closeErrorDialog" title="Error">
      <p>You must select one or more documents to update.</p>
    </basic-dialog>
  </main>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { StagedFileStoreActions, StagedFileStoreGetters } from "@/constants/store/stagedFile/stagedFileStoreConstants";
import store from "@/store";
import StoreNames from "@/constants/store/StoreNames";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import DataTable from "@/components/common/DataTable.vue";
import { DataTableHeader, DataTableAction, BreadcrumbPathItem } from "@/dataModel/interfaces";
import { AppConfig, SiteDocument, StagedFile, StagedFileWithDocumentMatch } from "@/dataModel";
import {
  AISettingIsEnabled,
  isAutoExtractableFileType,
  sleep,
} from "@/components/navigation/DocumentStaging/documentStagingHelpers";
import FileStatus from "@/constants/FileStatus";
import { DocumentMatchResponse } from "@/dataModel/responses";
import SiteDocumentAdminHttpHelper from "@/components/resources/siteDocumentAdminHttpHelper";
import serviceTypes from "@/dependencyInjection/types";
import container from "@/dependencyInjection/config";
import BasicDialog from "@/components/common/BasicDialog.vue";
import MetadataAutoExtractionHttpHelper from "@/components/resources/MetadataAutoExtractionHttpHelper";
import { DocumentStoreActions } from "@/constants/store/document/documentStoreConstants";
import { MAEUploadFileRequest } from "@/dataModel/requests";
import { MAEFileStatusResponse } from "@/dataModel/documentStaging/MAEFileStatusResponse";
import { SettingStoreActions } from "@/constants/store/setting/settingStoreConstants";

@Component({
  components: {
    Breadcrumb,
    DataTable,
    BasicDialog,
  },
})
export default class ConvertToSiteDocumentsPage extends Vue {
  private siteDocumentAdminHttpHelper = container.get<SiteDocumentAdminHttpHelper>(
    serviceTypes.SiteDocumentAdminHttpHelper,
  );

  private metadataAutoExtractionHttpHelper = container.get<MetadataAutoExtractionHttpHelper>(
    serviceTypes.MetadataAutoExtractionHttpHelper,
  );

  private appConfig = container.get<AppConfig>(serviceTypes.AppConfig);

  files: StagedFile[] = [];
  fileName = "";
  selectedFiles: StagedFile[] = [];
  numberFilesUpdated = 0;
  toastText = "";
  errorText = "";
  AI_SettingEnabled: boolean = false;
  autoExtractErrors: string[] = [];

  actions: DataTableAction[] = [
    {
      name: "Create New Document",
      icon: "fa-plus",
      action: "create",
    },
    {
      name: "Update Existing Document",
      icon: "fa-pen",
      action: "update",
    },
  ];

  path: BreadcrumbPathItem[] = [
    {
      text: "Administration",
    },
    {
      text: "Document Staging",
      url: "/document-staging",
    },
    {
      text: "Convert to Site Documents",
    },
  ];

  bulkActions: DataTableAction[] = [
    {
      name: "Update Existing Documents",
      icon: "fa-pen",
      action: "bulkUpdate",
    },
  ];

  headers: DataTableHeader[] = [
    {
      label: "Filename",
      key: "fileName",
      sortable: true,
    },
    {
      label: "Status",
      key: "fileStatus",
      sortable: false,
      formatter: this.addSpaces,
    },
    {
      // header for actions
      label: "",
      key: "actions",
      sortable: false,
    },
  ];

  async bulkUpdateExistingDocuments(): Promise<void> {
    if (this.selectedFiles.length) {
      store.dispatch(
        `${StoreNames.StagedFile}/${StagedFileStoreActions.SET_STAGED_FILES_TO_UPDATE}`,
        await this.getMatchedStagedFiles(this.selectedFiles),
      );
      this.fileName = this.selectedFiles[0].fileName;

      this.navigate("UpdateExistingDocuments");
    } else {
      this.$bvModal.show("error-dialog");
    }
  }

  async autoExtractMetadata(stagedFileId: string, filename: string): Promise<SiteDocument> {
    // Send file to document processing datastore
    let request: MAEUploadFileRequest = {
      StagedFileId: stagedFileId,
      Filename: filename,
    };

    let uploadStatus;
    let docProcessorFileId;

    try {
      docProcessorFileId = await this.metadataAutoExtractionHttpHelper.uploadFileForExtraction(request);
      uploadStatus = await this.metadataAutoExtractionHttpHelper.getFileStatus(docProcessorFileId);
    } catch (err: any) {
      return new SiteDocument();
    }

    // Best way to wait for file to be processed?
    let i = 0;
    let maxIterations = 25;
    while (uploadStatus.status_code != 200 && i < maxIterations) {
      // TODO add model for status status_codes?
      if (uploadStatus.status_code == 404 || uploadStatus.status_code == 422 || uploadStatus.status_code == 500) {
        this.autoExtractErrors.push(
          uploadStatus.message ? uploadStatus.message : "Error occurred during auto-extraction.",
        );
        return new SiteDocument();
      } else if (uploadStatus.status_code == 202) {
        await sleep(5000);
        try {
          uploadStatus = await this.metadataAutoExtractionHttpHelper.getFileStatus(docProcessorFileId);
        } catch (err: any) {
          return new SiteDocument();
        }
      }
      i += 1;
    }

    // Extract metadata on success
    if (uploadStatus.status_code == 200) {
      return await this.metadataAutoExtractionHttpHelper.getMetadata(docProcessorFileId);
    } else {
      this.autoExtractErrors.push(
        uploadStatus.message ? uploadStatus.message : "Error occurred during auto-extraction.",
      );
      return new SiteDocument();
    }
  }

  async aiHandler(file: StagedFile) {
    try {
      let preFilledDocument = await this.autoExtractMetadata(file.id, file.fileName);
      // Prefill form for createNewDocumentPage
      if (preFilledDocument) {
        store.dispatch(`${StoreNames.Document}/${DocumentStoreActions.SET_SELECTED_DOCUMENT}`, preFilledDocument);
      }
    } catch (err: any) {
      // Set empty form for createNewDocumentPage
      this.autoExtractErrors.push(err);
      store.dispatch(`${StoreNames.Document}/${DocumentStoreActions.SET_SELECTED_DOCUMENT}`, null);
    }
  }

  async createNewDocument(file: StagedFile): Promise<void> {
    store.dispatch(`${StoreNames.StagedFile}/${StagedFileStoreActions.SET_STAGED_FILES_TO_UPDATE}`, [file]);
    let autoExtractable = isAutoExtractableFileType(
      file,
      this.appConfig.fileUploadSettings.documents.allowedAutoExtractFileTypes,
    );

    // Check AI setting
    try {
      this.AI_SettingEnabled = await AISettingIsEnabled();
    } catch (err: any) {
      this.AI_SettingEnabled = false;
      this.autoExtractErrors.push("Error occurred retrieving AI:Enabled setting");
    }

    if (this.AI_SettingEnabled && autoExtractable) {
      await this.aiHandler(file);
    }

    this.navigateWithParams("CreateNewDocument", {
      AISettingEnabled: this.AI_SettingEnabled,
      isAutoExtractable: autoExtractable,
      autoExtractErrors: this.autoExtractErrors,
    });
  }

  async getMatchedStagedFiles(files: StagedFile[]): Promise<StagedFileWithDocumentMatch[]> {
    const matches: DocumentMatchResponse[] = await this.siteDocumentAdminHttpHelper.getMatchingDocumentsForFiles(
      files.map((x) => x.id),
    );
    return files.map((file) => {
      let documentMatch = matches.find((x) => x.file?.id == file.id);
      let document = documentMatch?.document;
      return new StagedFileWithDocumentMatch(file, document);
    });
  }

  async updateExistingDocument(file: StagedFile): Promise<void> {
    this.fileName = file.fileName;

    store.dispatch(
      `${StoreNames.StagedFile}/${StagedFileStoreActions.SET_STAGED_FILES_TO_UPDATE}`,
      await this.getMatchedStagedFiles([file]),
    );

    this.navigate("UpdateExistingDocuments");
  }

  closeErrorDialog() {
    this.$bvModal.hide("error-dialog");
  }

  setSelectedFiles(selectedFiles: StagedFile[]): void {
    this.selectedFiles = selectedFiles;
  }

  sortingChanged(ctx: any): void {
    this.files.sort((file1, file2) => {
      const sortBy = ctx.sortBy as keyof StagedFile;
      const first = ctx.sortDesc ? `${file2[sortBy]}` : `${file1[sortBy]}`;
      const second = ctx.sortDesc ? `${file1[sortBy]}` : `${file2[sortBy]}`;

      return first.localeCompare(second, undefined, {
        numeric: true,
        sensitivity: "variant",
        usage: "sort",
      });
    });
  }

  navigate(name: string): void {
    this.$router.push({ name: name });
  }

  navigateWithParams(name: string, params: any): void {
    this.$router.push({ name: name, params: params });
  }

  addSpaces(status: string): string {
    return status.replace(/([A-Z])/g, " $1");
  }

  checkIfFilesLeftToConvert(): void {
    this.selectedFiles = store.getters[`${StoreNames.StagedFile}/${StagedFileStoreGetters.GET_STAGED_FILES_TO_UPDATE}`];
    if (this.selectedFiles.length > 0) {
      this.fileName = this.selectedFiles[0].fileName;
      this.navigate("UpdateExistingDocuments");
    } else {
      this.checkIfAnyFilesConverted();
    }
  }

  checkIfAnyFilesConverted(): void {
    this.numberFilesUpdated =
      store.getters[`${StoreNames.StagedFile}/${StagedFileStoreGetters.GET_NUMBER_OF_FILES_UPDATED}`];

    if (this.numberFilesUpdated > 0) {
      this.toastText = `Successfully converted ${this.numberFilesUpdated} document${
        this.numberFilesUpdated > 1 ? "s" : ""
      }.`;

      this.$bvToast.show("files-updated-toast");

      store.dispatch(`${StoreNames.StagedFile}/${StagedFileStoreActions.SET_NUMBER_OF_FILES_UPDATED}`, 0);
    }
  }

  mounted(): void {
    this.files = store.getters[`${StoreNames.StagedFile}/${StagedFileStoreGetters.GET_SELECTED_FILES}`].filter(
      (file: StagedFile) => file.fileStatus !== FileStatus.Converted,
    );

    if (!this.files || this.files.length == 0) {
      this.navigate("DocumentStaging");
    }
    this.checkIfFilesLeftToConvert();
  }

  beforeDestroy(): void {
    // Reset Setting store state so that an updated value is retrieved next time AISettingIsEnabled is called
    store.dispatch(`${StoreNames.Setting}/${SettingStoreActions.SET_AI_SETTING}`, null);
  }
}
</script>

<style scoped lang="scss"></style>
