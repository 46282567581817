import FormatType from "@/constants/FormatType";

export class UpdateCategoryRequest {
  id: string;
  displayName: string;
  parentId: string;
  formatType: FormatType;

  constructor(id?: string, displayName?: string, parentId?: string, formatType?: FormatType) {
    this.id = id ?? "";
    this.displayName = displayName ?? "";
    this.parentId = parentId ?? "";
    this.formatType = formatType ?? FormatType.Normal;
  }
}
