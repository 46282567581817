import { Vue, Component } from "vue-property-decorator";

@Component
export class DraggableMixin extends Vue {
  draggedElement!: HTMLElement;
  tableBodyElement!: HTMLElement;
  dragStartIndex: number = 0;

  dragStart(ev: DragEvent): void {
    let target = <HTMLElement>ev.target;
    while (target.nodeName !== "TR") {
      target = <HTMLElement>target.parentElement;
    }
    this.draggedElement = <HTMLElement>target;
    this.draggedElement.style.cursor = "grab";
    ev.dataTransfer!.effectAllowed = "move";
    ev.dataTransfer?.setDragImage(this.draggedElement, 0, 0);

    this.tableBodyElement = <HTMLElement>this.draggedElement.parentElement;
    this.dragStartIndex = this.indexOfRow(this.tableBodyElement, this.draggedElement);
  }

  dragEnter(ev: DragEvent): void {
    ev.preventDefault();
    this.draggedElement.style.cursor = "grabbing";
    let target = <HTMLElement>ev.target;
    while (target.nodeName !== "TR") {
      target = <HTMLElement>target.parentNode;
    }

    if (this.isBefore(this.draggedElement, target)) {
      target.parentNode?.insertBefore(this.draggedElement, target);
    } else {
      target.parentNode?.insertBefore(this.draggedElement, target.nextSibling);
    }
  }

  dragOver(ev: DragEvent): void {
    ev.preventDefault();
  }

  dragEnd(ev: DragEvent): void {
    ev.preventDefault();
    this.draggedElement.style.cursor = "grab";
  }

  private isBefore(element1: HTMLElement, element2: HTMLElement): boolean {
    if (element1.parentNode === element2.parentNode) {
      for (
        let current = element1.previousSibling;
        current && current.nodeType !== Node.DOCUMENT_NODE;
        current = current.previousSibling
      ) {
        if (current === element2) {
          return true;
        }
      }
    }
    return false;
  }

  indexOfRow(parentElement: HTMLElement, rowElement: HTMLElement): number {
    return Array.prototype.indexOf.call(parentElement.childNodes, rowElement);
  }
}
