<template>
  <div class="role-container">
    <fieldset class="usa-fieldset">
      <div class="usa-checkbox role-checkbox" v-for="role in roles" :key="role.id">
        <input class="usa-checkbox__input" :id="role.id" type="checkbox" :value="role.id" v-model="selectedRoles" />
        <label class="usa-checkbox__label" :for="role.id">{{ role.name }}</label>
      </div>
    </fieldset>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class RoleSelection extends Vue {
  selectedRoles: string[] = [];
  initialSetup = true;
  @Prop(Array) readonly selected: string[] | any;
  @Prop(Array) readonly roles: string[] | undefined;

  @Watch("selectedRoles")
  emitRoles() {
    this.$emit("emitRoles", this.selectedRoles);
  }

  @Watch("selected")
  selectedRoleWatcher() {
    this.selectedRoles = this.selected;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.role-container {
  height: 180px;
  border: 1px solid #565c65;
  overflow-y: scroll;
}

.role-checkbox {
  margin-left: 1rem;
}
</style>
