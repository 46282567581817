<template>
  <div>
    <!-- Header -->
    <div class="grid-row">
      <h1 class="grid-col font-heading-lg text-primary-dark text-bold">Current & Upcoming</h1>
      <button
        v-show="displayOrderChanged"
        class="grid-col-auto flex-align-self-center usa-button"
        @click="onSaveDisplayOrder"
      >
        Save Changes
      </button>
    </div>

    <!-- Table -->
    <data-table
      :items="currentFeaturedResources"
      :fields="headers"
      :actions="actions"
      :perPage="100000"
      @edit="editFeaturedResource"
      @remove="removeFeaturedResource"
      ref="featuredDocumentsTable"
      v-if="renderComponent"
    >
      <template #cell(dragAndDrop)="data">
        <div
          class="margin-y-05 draggable"
          @dragenter="dragEnter"
          @dragend="dragEnd"
          @dragstart="dragStart"
          draggable="true"
        >
          <div class="mr-3 float-left">{{ data.index + 1 }}</div>
          <i class="fa fa-bars" />
        </div>
      </template>
      <template #cell(title)="data">
        <div class="margin-y-05 table-col-md overflow-ellipsis">
          <span class="title-classification">{{ data.item.titleClassification }} </span>
          <a
            v-if="showTableLink(data.item)"
            class="data-table-item-action usa-link"
            :title="data.value"
            href="#"
            @click="viewResource(data.item)"
          >
            {{ data.value }}
          </a>
          <span v-else>*{{ data.value }}</span>
        </div>
      </template>
      <template #cell(status)="data">
        <div class="margin-y-05">
          {{ getFeaturedResourceStatus(data.item) }}
        </div>
      </template>
    </data-table>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Emit, Watch, Mixins } from "vue-property-decorator";
import DataTable from "@/components/common/DataTable.vue";
import { DataTableAction, DataTableHeader } from "@/dataModel/interfaces";
import { FeaturedDocument, FeaturedHazardData } from "@/dataModel";
import { FeaturedResource, PooledFeaturedDataView } from "@/dataModel/interfaces/";
import { SiteDocumentResponse } from "@/dataModel/responses";
import FeaturedLengthType from "@/constants/featuredLengthType";
import { DraggableMixin } from "@/components/mixins/DraggableMixin";
import FeaturedHazardType from "@/constants/FeaturedHazardType";
import container from "@/dependencyInjection/config";
import FeaturedResourceHttpHelper from "@/components/resources/featuredResourceHttpHelper";
import serviceTypes from "@/dependencyInjection/types";
import SiteDocumentHttpHelper from "@/components/resources/siteDocumentHttpHelper";
import { HazardType } from "@/constants/HazardType";

@Component({
  components: {
    DataTable,
  },
})
export default class CurrentAndUpcomingTable extends Mixins(DraggableMixin) {
  @Prop({ required: true }) tabView!: FeaturedHazardType;
  @Prop({ required: true }) currentFeaturedResources!: FeaturedResource[];
  @Prop({ required: false }) userHazardTypes!: HazardType[];

  updatedFeaturedResources: FeaturedResource[] = [];
  renderComponent: boolean = true;
  displayOrderChanged: boolean = false;
  actions: DataTableAction[] = [
    {
      name: "Edit",
      icon: "fa-pen",
      action: "edit",
    },
    {
      name: "Remove",
      icon: "fa-times",
      action: "remove",
    },
  ];

  featuredResourceHttpHelper = container.get<FeaturedResourceHttpHelper>(serviceTypes.FeaturedResourceHttpHelper);
  documentHttpHelper = container.get<SiteDocumentHttpHelper>(serviceTypes.SiteDocumentHttpHelper);

  /** Gets the table headers based on the featured document tab or the featured data tab */
  get headers(): DataTableHeader[] {
    if (this.tabView === FeaturedHazardType.Document) {
      // Keys based on FeaturedDocument class properties
      return [
        {
          label: "",
          key: "dragAndDrop",
          sortable: false,
        },
        {
          label: "Title",
          key: "title",
          sortable: false,
        },
        {
          label: "Start Date",
          key: "startDate",
          formatter: (val) => this.$options.filters?.formatDate(val, undefined, true),
          sortable: false,
        },
        {
          label: "End Date",
          key: "endDate",
          formatter: (val) => this.$options.filters?.formatDate(val, undefined, true),
          sortable: false,
        },
        {
          label: "Status",
          key: "status",
          sortable: false,
        },
        {
          label: "Length",
          key: "featuredLengthType",
          formatter: this.featuredLengthFormatter,
          sortable: false,
        },
        {
          // header for actions
          label: "",
          key: "actions",
          sortable: false,
        },
      ];
    } else if (this.tabView === FeaturedHazardType.HazardData) {
      // Keys based on FeaturedHazardData class properties
      return [
        {
          label: "",
          key: "dragAndDrop",
          sortable: false,
        },
        {
          label: "Type",
          key: "featuredHazardType",
          formatter: this.featuredTypeFormatter,
          sortable: false,
        },
        {
          label: "Title/Name",
          key: "title",
          sortable: false,
        },
        {
          label: "Start Date",
          key: "startDate",
          formatter: (val) => this.$options.filters?.formatDate(val, undefined, true),
          sortable: false,
        },
        {
          label: "End Date",
          key: "endDate",
          formatter: (val) => this.$options.filters?.formatDate(val, undefined, true),
          sortable: false,
        },
        {
          label: "Status",
          key: "status",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
        },
      ];
    } else {
      throw new Error(`Could not recognize type: ${this.tabView}`);
    }
  }

  featuredTypeFormatter(type: string): string {
    if (type === FeaturedHazardType.Document) {
      return "Document";
    } else if (type === FeaturedHazardType.HazardData) {
      return "Data View";
    } else {
      return "Unknown";
    }
  }

  featuredLengthFormatter(featuredLengthType: string): string {
    switch (featuredLengthType) {
      case FeaturedLengthType.OneMonth:
        return "1 Month";
      case FeaturedLengthType.ThreeMonths:
        return "3 Month";
      case FeaturedLengthType.SixMonths:
        return "6 Month";
      case FeaturedLengthType.Custom:
        return "Custom";
      default:
        return "No End Date";
    }
  }

  getFeaturedResourceStatus(featuredDocument: FeaturedDocument): string {
    const currentDate = new Date(Date.now());
    const startDate = new Date(featuredDocument.startDate);
    const endDate = featuredDocument.endDate == null ? null : new Date(featuredDocument.endDate);

    if (startDate > currentDate) {
      return "Upcoming";
    } else if (startDate <= currentDate && (endDate == null || endDate >= currentDate)) {
      return "Now Featured";
    } else if (endDate != null && endDate < currentDate) {
      return "Past";
    } else {
      return "--";
    }
  }

  async viewResource(resource: FeaturedResource): Promise<void> {
    if (this.tabView === FeaturedHazardType.Document) {
      const document = resource as FeaturedDocument;
      this.$router.push({
        name: "DocumentSummary",
        query: { id: document?.siteDocumentId },
      });
    } else if (resource.featuredHazardType === FeaturedHazardType.Document) {
      // Document on featured hazard data
      const response: SiteDocumentResponse[] = await this.documentHttpHelper.getDocumentsByKnowledgeResourceIds([
        resource.knowledgeResourceId ?? "",
      ]);
      const doc = response[0].document;
      this.$router.push({
        name: "DocumentSummary",
        query: { id: doc.id },
      });
    } else if (resource.featuredHazardType === FeaturedHazardType.HazardData) {
      const data = resource as FeaturedHazardData;
      const dataView: PooledFeaturedDataView = await this.featuredResourceHttpHelper.getPooledFeaturedDataViewByIdAsync(
        data.pooledFeaturedDataViewId ?? "",
      );
      const routeToPage =
        dataView.dataViewSearchParameters.hazardAgents.length > 1 ? "DataByProperty" : "DataByHazards";

      try {
        this.$router.push({
          name: routeToPage,
          params: {
            dataViewName: dataView.name,
            searchParams: JSON.stringify(dataView.dataViewSearchParameters),
            hazardTypeId: HazardType[dataView.hazardTypeId],
          },
        });
      } catch (err: any) {
        console.error(err);
      }
    }
  }

  dragEnd(ev: DragEvent): void {
    ev.preventDefault();
    this.draggedElement.style.cursor = "grab";
    const dragEndIndex = this.indexOfRow(this.tableBodyElement, this.draggedElement);
    this.swapFeaturedDocuments(this.dragStartIndex, dragEndIndex);
    this.checkForDisplayOrderChange();
  }

  private swapFeaturedDocuments(startIndex: number, endIndex: number): void {
    const featuredResource = this.updatedFeaturedResources.splice(startIndex - 1, 1);
    this.updatedFeaturedResources.splice(endIndex - 1, 0, featuredResource[0]);
  }

  private checkForDisplayOrderChange(): void {
    this.displayOrderChanged = this.currentFeaturedResources.some(
      (featuredRes, i) => featuredRes !== this.updatedFeaturedResources[i],
    );
  }

  forceRerenderDataTable(): void {
    this.renderComponent = false;
    this.$nextTick(() => {
      this.renderComponent = true;
    });
  }

  showTableLink(resource: FeaturedResource): boolean {
    return (
      this.tabView === FeaturedHazardType.Document ||
      resource.featuredHazardType === FeaturedHazardType.Document ||
      this.userHazardTypes.includes(HazardType[(resource as FeaturedHazardData).hazardDataView!.hazardType.toString()])
    );
  }

  //#region Emitters

  @Emit()
  editFeaturedResource(featuredRes: FeaturedResource): FeaturedResource {
    return featuredRes;
  }

  @Emit()
  removeFeaturedResource(featuredRes: FeaturedResource): FeaturedResource {
    return featuredRes;
  }

  @Emit()
  onSaveDisplayOrder(): FeaturedResource[] {
    return this.updatedFeaturedResources;
  }

  //#endregion

  //#region Watchers

  @Watch("currentFeaturedResources")
  documentListChanged(newList: FeaturedResource[]): void {
    this.updatedFeaturedResources = newList.map((r) => r);
    this.forceRerenderDataTable();
  }
  //#endregion
}
</script>

<style scoped lang="scss"></style>
