<template>
  <div>
    <label class="usa-label" for="hazard-type-select">Hazard Type</label>
    <select class="usa-select" name="hazard-type-select" id="hazard-type-select" v-model="selectedHazardType">
      <option :value="null">- Select Hazard Type -</option>
      <option v-for="type in hazardTypeOptions" :key="type.id" :value="type.id">
        {{ type.name }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Emit, Watch } from "vue-property-decorator";
import { HazardType } from "@/constants/HazardType";
import HazardTypeOption from "@/dataModel/hazardData/hazardTypeOption";
import ServiceTypes from "@/dependencyInjection/types";
import IAuthService from "@/services/interfaces/IAuthService";
import Container from "@/dependencyInjection/config";
import { Actor } from "@/dataModel";

@Component
export default class HazardTypeSelection extends Vue {
  selectedHazardType: HazardType | null = null;
  hazardTypeOptions: HazardTypeOption[] = [];

  private authService = Container.get<IAuthService>(ServiceTypes.AuthService);

  @Watch("selectedHazardType")
  hazardTypeWatcher(): void {
    this.hazardTypeChanged(this.selectedHazardType);
  }

  @Emit()
  hazardTypeChanged(hazardTypeId: HazardType | null): number | null {
    return hazardTypeId;
  }

  private getFilteredHazardTypeOptions(): void {
    const actor: Actor = Object.assign(new Actor(), this.authService.getUser().actor);
    this.hazardTypeOptions = actor.getAuthorizedHazardTypeOptions();
  }

  created(): void {
    this.getFilteredHazardTypeOptions();
  }
}
</script>

<style scoped lang="scss"></style>
