import HttpHelper from "@/components/resources/httpHelper";
import { inject, injectable } from "inversify";
import serviceTypes from "@/dependencyInjection/types";
import { PermissionCategory } from "@/dataModel/interfaces";
import { Classification, DisseminationControl, Sci, KnowledgeResourceAudience } from "@/dataModel";

const adminPermissionUrl = "admin/permission-categories";
const permissionUrl = "permissions/";

@injectable()
export default class PermissionHttpHelper {
  httpHelper: HttpHelper;

  constructor(@inject(serviceTypes.HttpHelper) httpHelper: HttpHelper) {
    this.httpHelper = httpHelper;
  }

  async getPermissionCategoryAll(): Promise<PermissionCategory[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(adminPermissionUrl)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " getting permission catagories",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting permission catagories"));
        });
    });
  }

  async getPermissionCategory(id: string): Promise<PermissionCategory> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(adminPermissionUrl + "/" + id)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " getting permission catagory",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting permission catagory"));
        });
    });
  }

  async getClassifications(): Promise<Classification[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(permissionUrl + "classification")
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " getting classifications",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting classifications"));
        });
    });
  }

  async getDisseminationControls(): Promise<DisseminationControl[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(permissionUrl + "dissemination-control")
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " +
                  response.status +
                  " : " +
                  response.statusText +
                  " getting dissemination controls",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting dissemination controls"));
        });
    });
  }

  async getScis(): Promise<Sci[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(permissionUrl + "sci")
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error("Response returned " + response.status + " : " + response.statusText + " getting SCIs"));
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting SCIs"));
        });
    });
  }

  async getAudiences(): Promise<KnowledgeResourceAudience[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(permissionUrl + "audience")
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error("Response returned " + response.status + " : " + response.statusText + " getting audiences"),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting audiences"));
        });
    });
  }
}
