<template>
  <basic-dialog
    :id="dialogId"
    title="Metadata Auto-Extraction"
    primaryButton="Done"
    secondaryButton="Cancel"
    :primaryClick="primaryClick"
    :isDisabled="!canContinue"
    :secondaryClick="cancel"
    noCloseOnBackdrop
    noCloseOnEsc
    hideHeaderClose
    :isLoading="inProgress"
    :loadingText="progressDescription"
  >
    <template v-if="batchHasUnapprovedFiles">
      <div class="usa-alert usa-alert--info usa-alert--slim margin-bottom-2">
        <div class="usa-alert__body">
          <p class="usa-alert__text">All files must be approved</p>
        </div>
      </div>
    </template>
    <div v-if="showProgress" class="progress" id="progress">
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        :aria-valuenow="progressValue"
        aria-valuemin="0"
        aria-valuemax="100"
        :style="'width: ' + progressValue + '%'"
      >
        {{ progressValue.toString() }}%
      </div>
    </div>

    <div v-if="errorMessages.length > 0" class="usa-alert usa-alert--slim usa-alert--error">
      <div v-for="errorMessage in errorMessages" class="usa-alert__body">
        <p class="usa-alert__text">{{ errorMessage }}</p>
      </div>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import serviceTypes from "@/dependencyInjection/types";
import container from "@/dependencyInjection/config";
import axios, { CancelTokenSource } from "axios";
import MetadataAutoExtractionHttpHelper from "@/components/resources/MetadataAutoExtractionHttpHelper";
import { AppConfig, SiteDocument, StagedFileBatch } from "@/dataModel";
import { MAEGetStatusRequestWrapper, MAEMetadataRequestWrapper } from "@/dataModel/requests";
import { MetadataExtractionStatus } from "@/dataModel/constants/MetadataExtractionStatus";
import { MAEFileStatusResponse } from "@/dataModel/documentStaging/MAEFileStatusResponse";
import { DocumentStagingActions } from "@/store/modules/documentStaging/actions";
import { sleep } from "@/components/navigation/DocumentStaging/documentStagingHelpers";
import FileStatus from "@/constants/FileStatus";

@Component({
  components: {
    BasicDialog,
  },
})
export default class AutoExtractMetadataBatchDialog extends Vue {
  uploadingDescription: string = "Uploading to metadata auto-extraction service...";
  extractingDescription: string = "Exracting metadata...";
  completedDescription: string = "Auto-Extraction completed.";
  progressDescription: string = this.uploadingDescription;
  showProgress = true;
  progressValue = 0;
  inProgress = true;

  uploadCancelToken?: CancelTokenSource;
  bulkUploadCompleted: boolean = false;

  maeDocIds?: string[];
  statusRequests: MAEGetStatusRequestWrapper[] = [];

  extractionRequests: MAEMetadataRequestWrapper[] = [];
  extractionCompleted = false;
  metadataExtracted: SiteDocument[] = [];
  cancelled = false;
  uploadFailed = false;

  errorMessages: string[] = [];

  private maeHttpHelper = container.get<MetadataAutoExtractionHttpHelper>(
    serviceTypes.MetadataAutoExtractionHttpHelper,
  );

  @Prop({ required: true }) dialogId!: string;
  @Prop({ required: true }) selectedBatch!: StagedFileBatch;

  async primaryClick(): Promise<void> {
    const id = this.selectedBatch.batchId;
    this.$router.push({
      name: "BatchSummaryPage",
      params: { id },
    });

    this.closeDialog();
  }

  updateProgressValue(completed: number, total: number): void {
    this.progressValue = Math.min(Math.round(completed / total), 100);
  }

  cancel(): void {
    if (this.inProgress) {
      if (this.uploadCancelToken) {
        this.uploadCancelToken.cancel();
      }
      if (!this.bulkUploadCompleted) {
        this.cancelGetStatuses();
      } else {
        this.cancelExtraction();
      }
      this.cancelled = true;
      this.showProgress = false;
      this.uploadCancelToken?.cancel();
    }

    this.inProgress = false;
    this.closeDialog();
  }

  closeDialog(): void {
    this.$bvModal.hide(this.dialogId);
  }

  cancelGetStatuses(): void {
    this.statusRequests.forEach((r) => r.cancelToken?.cancel());
  }

  cancelExtraction(): void {
    this.extractionRequests.forEach((r) => r.cancelToken?.cancel());
  }

  async sendUploadRequest(): Promise<string[]> {
    return await this.maeHttpHelper.uploadFileForExtractionBulk({ BatchId: this.selectedBatch.batchId });
  }

  async sendExtractionRequest(id: string): Promise<SiteDocument> {
    return this.maeHttpHelper.getMetadata(id);
  }

  populateStatusRequests(): void {
    this.maeDocIds?.forEach((id) => {
      this.statusRequests?.push({
        maeFileId: id,
        statusResponse: {
          status_code: 202,
          message: "File is processing",
        },
        cancelToken: axios.CancelToken.source(),
      });
    });
  }

  populateExtractionRequests(): void {
    let successfullyUploaded = this.statusRequests.filter((r) => r.statusResponse.status_code === 200);
    successfullyUploaded.forEach((request) => {
      this.extractionRequests.push({
        maeFileId: request.maeFileId,
        status: MetadataExtractionStatus.Pending,
        cancelToken: axios.CancelToken.source(),
      });
    });
  }

  async checkUploadComplete(): Promise<boolean> {
    let pendingFiles: MAEGetStatusRequestWrapper[] = this.statusRequests.filter((file) => {
      file.statusResponse.status_code === 202;
    });
    let total = this.statusRequests.length;

    // Let max iterations timeout depend on batch size
    let i = 0;
    let secPerFile = 60;
    let maxIterations = pendingFiles.length * secPerFile * 2;
    while (pendingFiles.length > 0 && i < maxIterations) {
      await this.checkUploadStatuses(pendingFiles, 500);

      // Remove completed files or files with errors
      pendingFiles = pendingFiles.filter((file) => {
        file.statusResponse.status_code === 202;
      });

      this.updateProgressValue(total - pendingFiles.length, pendingFiles.length);
      i++;
    }

    return true;
  }

  async checkUploadStatuses(pendingFiles: MAEGetStatusRequestWrapper[], msBetweenChecks: number): Promise<boolean> {
    let response: MAEFileStatusResponse;
    let fileIndex: number;
    const successful: boolean = true;

    let i = 0;
    while (i < pendingFiles.length) {
      let file = pendingFiles[i];
      // API call
      try {
        response = await this.maeHttpHelper.getFileStatus(file.maeFileId!);

        // Update pending files
        file.statusResponse = response;

        // Update statusRequests
        fileIndex = this.statusRequests.findIndex((request) => request.maeFileId === file.maeFileId);
        this.statusRequests[fileIndex].statusResponse = response;

        // Check for errors
        if (file.statusResponse.status_code != 200 && file.statusResponse.status_code != 202) {
          this.errorMessages.push(file.statusResponse.message!);
        }
      } catch {
        this.errorMessages.push("Unable to fetch upload status");
        return !successful;
      }
      i += 1;
      sleep(msBetweenChecks);
    }

    return successful;
  }

  async sendExtractionRequests(): Promise<void> {
    let count: number = 0;
    this.extractionRequests.forEach(async (request) => {
      try {
        let metadata = await this.maeHttpHelper.getMetadata(request.maeFileId);
        this.metadataExtracted.push(metadata);
      } catch (error: any) {
        this.errorMessages.push(error.message);
      }
      count += 1;
      this.updateProgressValue(count, this.extractionRequests.length);
    });

    // Add empty site doc for each file(name) missing from metadataExtracted
    if (this.selectedBatch.stagedFiles.length != this.metadataExtracted.length) {
      let missingFiles = this.selectedBatch.stagedFiles.filter(
        (file) => !this.metadataExtracted.some((siteDoc) => file.fileName === siteDoc.fileName),
      );

      missingFiles.forEach((stagedFile) => {
        let doc = new SiteDocument();
        doc.fileName = stagedFile.fileName;
        this.metadataExtracted.push(doc);
      });
    }
  }

  async preventSignOut(): Promise<void> {
    while (this.inProgress) {
      // Ping active every minute. Timeout should be 5 minutes.
      localStorage.setItem("lastActiveTime", new Date().toString());
      await sleep(1000 * 60);
    }
  }

  get canContinue(): boolean {
    return !!this.selectedBatch && !this.inProgress && !this.uploadFailed && !this.batchHasUnapprovedFiles;
  }

  get batchHasUnapprovedFiles(): boolean {
    return this.selectedBatch.stagedFiles?.some(
      (f) => f.fileStatus !== FileStatus.Approved && f.fileStatus !== FileStatus.Converted,
    );
  }

  async completeUpload(): Promise<void> {
    await this.$store.dispatch(DocumentStagingActions.UPDATE_SUMMARIZED_BATCH_DOCUMENTS, this.metadataExtracted);
    this.inProgress = false;
    this.uploadingDescription = this.completedDescription;
  }

  async beginUpload(): Promise<void> {
    // Reset previous store state
    this.$store.dispatch(DocumentStagingActions.UPDATE_SUMMARIZED_BATCH_DOCUMENTS, []);
    this.uploadCancelToken = axios.CancelToken.source();

    try {
      this.inProgress = true;
      this.preventSignOut();
      this.maeDocIds = await this.sendUploadRequest();
    } catch (error: any) {
      this.uploadFailed = true;
      this.inProgress = false;
      if (axios.isCancel(error)) {
        this.errorMessages.push("Auto-Extraction canceled");
      } else {
        this.errorMessages.push(error.message);
      }
      return;
    }

    // Start upload process
    this.populateStatusRequests();
    this.bulkUploadCompleted = await this.checkUploadComplete();

    if (this.bulkUploadCompleted) {
      // reset progress
      this.progressDescription = this.extractingDescription;
      this.updateProgressValue(0, 1);

      // Extract metadata
      this.populateExtractionRequests();
      try {
        await this.sendExtractionRequests();
      } catch (error) {
        this.inProgress = false;
        if (axios.isCancel(error)) {
          this.errorMessages.push("Auto-Extraction canceled");
        } else {
          this.errorMessages.push("Unable to extract metadata.");
        }
        return;
      }

      await this.completeUpload();
    }
  }

  // Better way to do this?
  resetValues(): void {
    this.errorMessages = [];
    this.progressValue = 0;
    this.progressDescription = this.uploadingDescription;
    this.showProgress = true;
    this.inProgress = false;
    this.bulkUploadCompleted = false;
    this.maeDocIds = [];
    this.statusRequests = [];
    this.extractionRequests = [];
    this.extractionCompleted = false;
    this.metadataExtracted = [];
    this.cancelled = false;
  }

  mounted(): void {
    this.$root.$on("bv::modal::show", (_, modalId: string) => {
      if (modalId === this.dialogId) {
        if (!this.batchHasUnapprovedFiles) {
          this.beginUpload();
        } else {
          this.errorMessages.push("Batch has unapproved files.");
          this.inProgress = false;
          this.showProgress = false;
        }
      }
    });

    this.$root.$on("bv::modal::hide", (_, modalId: string) => {
      if (modalId === this.dialogId) {
        this.resetValues();
      }
    });
  }
}
</script>

<style scoped lang="scss">
.usa-alert__text {
  font-size: 14px;
}
</style>
