<template>
  <main id="main-content">
    <div id="section-background" class="bg-primary-darker">
      <img id="hkma-dhs-background-logo" src="../../../assets/img/dhs-logo.svg" role="img" alt="" aria-hidden="true" />
      <section class="grid-container usa-section">
        <div class="grid-row grid-gap">
          <div id="welcome" class="desktop:grid-col-6">
            <h1 id="hkma-name">Hazard Knowledge Management & Analysis: Next Steps</h1>
            <h1 id="hkma-name"></h1>
          </div>
          <div class="desktop:grid-col-6">
            <div class="usa-card__container" v-if="!hasRequestedAccess">
              <header class="usa-card__header">
                <h2 class="usa-card__heading">Additional Information</h2>
                Your account status is still pending. All users are required to provide a reason for why they need
                access to the HKMA before their account is approved.
              </header>
              <div class="usa-card__body">
                <form class="usa-form">
                  <div class="form-section">
                    <label class="usa-label" for="reason">Reason for accessing HKMA</label>
                    <textarea
                      class="usa-textarea"
                      id="reason"
                      name="reason"
                      v-model="reason"
                      v-on:change="validateReason()"
                      maxlength="1000"
                    ></textarea>
                    <span class="usa-hint usa-character-count__message" aria-live="polite">
                      You can enter up to 1000 characters
                    </span>
                  </div>
                  <div class="form-section">
                    <button class="card-button usa-button" @click="submitRequest">Submit Request</button>
                    <div class="usa-alert usa-alert--info usa-alert--slim" v-if="!isValidReason">
                      <div class="usa-alert__body">
                        <p class="usa-alert__text">Reason cannot be empty.</p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="usa-card__container" v-if="hasRequestedAccess">
              <header class="usa-card__header">
                <h2 class="usa-card__heading">Awaiting Approval</h2>
                Your request for access has been received. An administrator will review your request to access the HKMA.
                You will receive an email once the review process has completed.
              </header>
              <div class="usa-card__body" style="height: 309px"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { User as UserState } from "@/typings/store/states/AuthStoreState";
import AccessRequestHttpHelper from "@/components/resources/accessRequestHttpHelper";
import { RequestUserApprovalRequest } from "@/dataModel/requests";
import IAuthService from "@/services/interfaces/IAuthService";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";

@Component
export default class RequestAccessPage extends Vue {
  private auth = container.get<IAuthService>(serviceTypes.AuthService);
  private httpHelper = container.get<AccessRequestHttpHelper>(serviceTypes.AccessRequestHttpHelper);
  reason = "";
  hasRequestedAccess = false;
  isValidReason = true;
  userId = "";

  async submitRequest(event: Event) {
    event.preventDefault();
    if (this.isReasonEmpty()) {
      this.isValidReason = false;
    } else {
      const request = new RequestUserApprovalRequest(this.userId, this.reason);
      try {
        await this.httpHelper.requestAccess(request);
        this.hasRequestedAccess = true;
      } catch (err) {}
    }
  }

  public validateReason() {
    if (this.isReasonEmpty()) {
      this.isValidReason = false;
    } else {
      this.isValidReason = true;
    }
  }

  private isReasonEmpty() {
    return this.reason.trim() === "";
  }

  created() {
    const userState: UserState | null = this.auth.getAuthState()?.user ?? null;
    if (userState == null) {
      this.$router.push("/home");
    } else {
      if (userState.userStatus === "LoginCreated") {
        this.hasRequestedAccess = false;
      } else {
        this.hasRequestedAccess = true;
      }

      this.userId = userState.userId;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./src/styles/login-flow.scss";
</style>
