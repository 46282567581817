// Register class component hooks before importing any components
import "./class-component-hooks";
import Vue from "vue";
import App from "./App.vue";
import ConfigLoadFailedAppTemplate from "./components/configLoadFailed/ConfigLoadFailedAppTemplate.vue";
import RouterWrapper from "./router";
import BootstrapVue from "bootstrap-vue";
import { BCollapse, VBToggle, BModal, VBModal } from "bootstrap-vue";
import SuccessToast from "./components/common/SuccessToast.vue";
import ErrorToast from "./components/common/ErrorToast.vue";
import container from "@/dependencyInjection/config";
import providersContainer from "@/dependencyInjection/services/services.config";
import PROVIDER_TYPES from "@/dependencyInjection/services/services.types";
import { AppConfig } from "./dataModel";
import AppConfigService from "./services/AppConfigService";
import store from "@/store";
import "./filters";

Vue.config.productionTip = true;

Vue.use(BootstrapVue);
Vue.component("BModal", BModal);
Vue.directive("b-modal", VBModal);
Vue.directive("b-toggle", VBToggle);
Vue.component("b-collapse", BCollapse);

Vue.component("success-toast", SuccessToast);
Vue.component("error-toast", ErrorToast);

const appConfigService = new AppConfigService();
appConfigService
  .getAppConfig()
  .then((config) => {
    container.load(providersContainer);
    container.bind<AppConfig>(PROVIDER_TYPES.AppConfig).toConstantValue(config);

    new Vue({
      router: container.get<RouterWrapper>(PROVIDER_TYPES.RouterWrapper),
      store,
      render: (h) => h(App),
    }).$mount("#app");
  })
  .catch(() => {
    new Vue({
      render: (h) => h(ConfigLoadFailedAppTemplate),
    }).$mount("#app");
  });
