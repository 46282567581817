import { render, staticRenderFns } from "./DocumentInputFields.vue?vue&type=template&id=363663b0&scoped=true"
import script from "./DocumentInputFields.vue?vue&type=script&lang=ts"
export * from "./DocumentInputFields.vue?vue&type=script&lang=ts"
import style0 from "./DocumentInputFields.vue?vue&type=style&index=0&id=363663b0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "363663b0",
  null
  
)

export default component.exports