import moment from "moment";

/**
 * Formats a given date object using the moment library
 * @param date Date to be formatted
 * @param format Format string
 * @param displayAsUtc Whether or not to convert to UTC
 * @returns Formatted date as a string
 */
export function formatDate(date: Date, format: string = "MM-DD-YYYY", displayAsUtc: boolean = false): string {
  let processedDate = moment(date);
  if (!processedDate.isValid()) {
    return "";
  }

  if (displayAsUtc) {
    processedDate = processedDate.utc();
  }

  return processedDate.format(format);
}

/**
 * Inserts spaces within a string. Example: PascalCase => Pascal Case
 * @param value String value to examine
 * @returns New string with spaces
 */
export function addSpaces(value: string): string {
  return value.replace(/([A-Z])/g, " $1").trim();
}

/**
 * Removes spaces within a string.
 * @param str String to process.
 * @returns New string without spaces
 */
export function removeSpaces(str: string): string {
  return str.replace(" ", "");
}

/**
 * Takes any error arg from a try-catch as an any type and extracts the error message
 * @param err error object to process
 * @returns error message
 */
export function processError(err: any): string {
  if (typeof err === "string") {
    return err;
  } else if (err instanceof Error) {
    return err.message;
  } else {
    return "Unknown error.";
  }
}
