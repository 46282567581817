import { DetailValueType } from "@/constants/DetailValueType";

export default class DataValueDetailRequest {
  hazardDetailId: string;
  value: number | string | Date;
  valueType: DetailValueType;

  constructor(
    hazardDetailId?: string,
    value?: number | string | Date,
    valueType?: DetailValueType
  ) {
    this.hazardDetailId = hazardDetailId ?? "";
    this.value = value ?? "";
    this.valueType = valueType ?? DetailValueType.Text;
  }
}