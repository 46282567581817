import { render, staticRenderFns } from "./UserRequestsCreatePage.vue?vue&type=template&id=1cb62626&scoped=true"
import script from "./UserRequestsCreatePage.vue?vue&type=script&lang=ts"
export * from "./UserRequestsCreatePage.vue?vue&type=script&lang=ts"
import style0 from "./UserRequestsCreatePage.vue?vue&type=style&index=0&id=1cb62626&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cb62626",
  null
  
)

export default component.exports