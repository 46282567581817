import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import { DocumentState } from '@/typings/store/states/DocumentStoreState';

 const state: DocumentState = {
    document: null
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};