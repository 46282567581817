<template>
  <basic-dialog
    :id="dialogId"
    :title="title"
    :primaryButton="primaryButtonText"
    :secondaryButton="secondaryButtonText"
    :primaryClick="onPrimaryClick"
    :secondaryClick="onSecondaryClick"
  >
    <div v-if="!showError">
      <p class="usa-prose">{{ confirmText }}</p>
    </div>
    <div v-else class="usa-alert usa-alert--info usa-alert--slim margin-top-3">
      <div class="usa-alert__body">
        <p class="usa-alert__text">
          {{ errorText }}
        </p>
      </div>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import { StagedFileBatch } from "@/dataModel";
import { ConfirmDialogTitles } from "./ConfirmDialogTitles";

@Component({
  components: {
    BasicDialog,
  },
})
export default class ConfirmBatchDialog extends Vue {
  @Prop({ required: true }) dialogId!: string;
  @Prop({ required: true }) title!: ConfirmDialogTitles;
  @Prop() batch!: StagedFileBatch;

  errorText: string = "";

  get showError(): boolean {
    return this.errorText.length > 0;
  }

  get primaryButtonText(): string {
    switch (this.title) {
      case ConfirmDialogTitles.Empty:
      case ConfirmDialogTitles.Invalid:
        return "Close";
      default:
        return "Confirm";
    }
  }

  get secondaryButtonText(): string {
    switch (this.title) {
      case ConfirmDialogTitles.Empty:
      case ConfirmDialogTitles.Invalid:
        return "";
      default:
        return "Cancel";
    }
  }

  get confirmText(): string {
    switch (this.title) {
      case ConfirmDialogTitles.Delete:
        return "Are you sure you want to delete this batch and all of its files?";
      case ConfirmDialogTitles.Approve:
        return `Are you sure you want to approve all ${this.batch.stagedFiles.length} files in this batch?`;
      default:
        return "";
    }
  }

  onPrimaryClick(): void {
    if (this.title === ConfirmDialogTitles.Approve) {
      this.$emit("approve");
    } else if (this.title === ConfirmDialogTitles.Delete) {
      this.$emit("delete");
    }
    this.close();
  }

  onSecondaryClick(): void {
    this.close();
  }

  close() {
    this.$bvModal.hide(this.dialogId);
  }
}
</script>

<style scoped lang="scss">
.usa-prose {
  font-weight: bold;
  margin-top: 1.5rem;
}
</style>
