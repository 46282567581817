<template>
  <div>
    <div class="grid-row flex-align-center hazard-data-heading-container">
      <div class="grid-col-2 pr-2">
        <img class="explore-icon" src="../../../assets/img/icons/Data.svg" role="img" alt="" aria-hidden="true" />
      </div>
      <div class="grid-col-10">
        <h3 class="font-heading-lg text-primary-dark text-bold mb-0" id="hazards-head">Hazard Data</h3>
      </div>
    </div>
    <template v-if="isLoading">
      <spinner />
    </template>
    <template v-else>
      <template v-if="featuredHazardDataList && featuredHazardDataList.length > 0">
        <ul class="resource-list">
          <li v-for="(featuredHazardData, index) in featuredHazardDataList" :key="index" class="hazard-data-item">
            <a
              class="usa-link"
              href="#"
              v-html="getFeaturedDataDisplay(featuredHazardData)"
              @click.prevent="visitLink(featuredHazardData)"
            />
            <template v-if="featuredHazardData.featuredHazardType === FeaturedHazardType.HazardData">
              <br />
              <p class="margin-0">{{ getFeaturedDataViewDescription(featuredHazardData.hazardDataView) }}</p>
              <p class="margin-0">
                <strong>Hazards:</strong> {{ getFeaturedDataViewHazardList(featuredHazardData.hazardDataView) }}
              </p>
            </template>
          </li>
        </ul>
      </template>
      <template v-else>
        <p class="mt-0 empty-list">No data featured at this time.</p>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Spinner from "@/components/common/Spinner.vue";
import { FeaturedHazardData, AppConfig, FeaturedHazardDocument, FeaturedHazardDataView, Category } from "@/dataModel";
import { PooledFeaturedDataView } from "@/dataModel/interfaces";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import FeaturedResourceHttpHelper from "@/components/resources/featuredResourceHttpHelper";
import SiteDocumentHttpHelper from "@/components/resources/siteDocumentHttpHelper";
import CategoryHttpHelper from "@/components/resources/categoryHttpHelper";
import FeaturedHazardType from "@/constants/FeaturedHazardType";
import { HazardType } from "@/constants/HazardType";
import { truncate } from "@/services/utils";

@Component({
  components: {
    Spinner,
  },
})
export default class FeaturedHazardDataSection extends Vue {
  truncate = truncate;
  private siteDocumentHttpHelper = container.get<SiteDocumentHttpHelper>(serviceTypes.SiteDocumentHttpHelper);
  private featuredResourceHttpHelper = container.get<FeaturedResourceHttpHelper>(
    serviceTypes.FeaturedResourceHttpHelper,
  );
  private categoryHttpHelper = container.get<CategoryHttpHelper>(serviceTypes.CategoryHttpHelper);
  private appConfig = container.get<AppConfig>(serviceTypes.AppConfig);

  isLoading: boolean = true;
  featuredHazardDataList: FeaturedHazardData[] = [];
  FeaturedHazardType = FeaturedHazardType;
  allCategories: Category[] = [];

  getFeaturedDataDisplay(featuredData: FeaturedHazardData) {
    if (featuredData.featuredHazardType === FeaturedHazardType.Document) {
      const doc = featuredData.hazardDocument as FeaturedHazardDocument;
      return `${doc.titleClassification} ${doc.title}`;
    } else if (featuredData.featuredHazardType === FeaturedHazardType.HazardData) {
      const dataView = featuredData.hazardDataView as FeaturedHazardDataView;
      return dataView.name;
    } else {
      return "<Error>";
    }
  }

  getFeaturedDataViewDescription(dataView: FeaturedHazardDataView): string {
    return dataView.description ? truncate(dataView.description, 170) : "No description.";
  }

  getFeaturedDataViewHazardList(dataView?: FeaturedHazardDataView): string {
    if (!dataView?.searchParameters) {
      return "";
    }

    const hazardCategoryIdList: string[] = dataView.searchParameters.hazardAgents;
    const numHazards = hazardCategoryIdList.length;
    let listStr: string = "";

    // Format list of hazards for the data view
    for (let i = 0; i < numHazards; i++) {
      const hazard: Category | undefined = this.allCategories.find((c) => c.id === hazardCategoryIdList[i]);
      if (!hazard) {
        continue;
      }

      listStr += hazard.displayName;
      if (i > 0) {
        listStr += ", ";
      }

      // Determine whether to truncate remaining hazard names
      if (listStr.length + (hazardCategoryIdList[i + 1]?.length ?? 0) > 50) {
        listStr += ` (and ${numHazards - (i + 1)} others)`;
        break;
      }
    }

    return listStr;
  }

  visitLink(featuredData: FeaturedHazardData): void {
    switch (featuredData.featuredHazardType) {
      case FeaturedHazardType.HazardData:
        this.visitDataViewAsync(featuredData.pooledFeaturedDataViewId);
        break;
      case FeaturedHazardType.Document:
        this.visitDocumentAsync(featuredData.knowledgeResourceId);
        break;
      default:
        break;
    }
  }

  async visitDocumentAsync(knowledgeResourceId?: string): Promise<void> {
    if (knowledgeResourceId === undefined) {
      return;
    }

    const response = await this.siteDocumentHttpHelper.getDocumentsByKnowledgeResourceIds(
      new Array(knowledgeResourceId),
    );
    this.$router.push({
      name: "DocumentSummary",
      query: { id: response[0].document.id },
    });
  }

  async visitDataViewAsync(pooledDataViewId?: string): Promise<void> {
    const dataView: PooledFeaturedDataView = await this.featuredResourceHttpHelper.getPooledFeaturedDataViewByIdAsync(
      pooledDataViewId ?? "",
    );
    if (dataView === undefined) {
      alert("Failed to load data view");
      return;
    }

    const routeToPage = dataView.dataViewSearchParameters.hazardAgents.length > 1 ? "DataByProperty" : "DataByHazards";

    try {
      this.$router.push({
        name: routeToPage,
        params: {
          dataViewName: dataView.name,
          searchParams: JSON.stringify(dataView.dataViewSearchParameters),
          hazardTypeId: HazardType[dataView.hazardTypeId],
        },
      });
    } catch (err: any) {
      console.error(err);
    }
  }

  mounted() {
    (async () => {
      try {
        this.allCategories = await this.categoryHttpHelper.getAllCategories();
        this.featuredHazardDataList = await this.featuredResourceHttpHelper.getFeaturedHazardData(
          this.appConfig.featuredHazardDataLimit,
        );
      } finally {
        this.isLoading = false;
      }
    })();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.resource-list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
}

.resource-list-heading {
  margin-bottom: 0.5em;
}

.hazard-data-item {
  border-bottom: 1px solid black;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
}

.empty-list {
  padding: 0.5rem 1.5rem;
}

.hazard-data-heading-container {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid black;
}

.usa-link:visited {
  color: #005ea2;
}
</style>
