<template>
  <main class="main-content">
    <section class="usa-section usa-section--dark search-section">
      <div class="grid-container">
        <div class="grid-row grid-gap">
          <div class="grid-col usa-prose">
            <h2 class="font-heading-xl margin-top-0 margin-bottom-2">Start Exploring the HKMA.</h2>
            <p>
              The HKMA (Hazard Knowledge Management & Analysis) provides an extensive database of hazard information,
              including reports, books, journals, and other resources. It also serves as a gateway to a variety of
              applications related to risk assessment.
            </p>
            <h3 class="font-heading-md margin-bottom-0">Search for Documents</h3>
            <form
              id="document-search-form"
              v-bind:class="{ 'search-focused': isSearchBoxFocused }"
              class="usa-search usa-search--small mt-2"
              role="search"
              @submit.prevent="goToSearch(true)"
            >
              <label class="usa-sr-only" for="search-field-en-big"
                >Enter keywords, document titles, authors, etc.</label
              >
              <input
                class="usa-input"
                id="search-field-en-big"
                type="search"
                name="search"
                v-model="searchTerm"
                @focus="isSearchBoxFocused = true"
                @blur="isSearchBoxFocused = false"
                placeholder="Enter keywords, document titles, authors, etc."
              />
              <button class="usa-button" id="search-button" :disabled="searchTerm == ''" type="submit"></button>
            </form>
          </div>
        </div>
      </div>
    </section>
    <section class="usa-section">
      <div class="grid-container">
        <div class="tablet:grid-row grid-gap">
          <explore-cards @explore-documents="goToSearch(false)" @explore-data="goToData" />
        </div>
      </div>
    </section>
    <section class="usa-graphic-list usa-section bg-base-lightest">
      <div class="grid-container">
        <div class="grid-row">
          <h2 class="font-heading-xl text-primary-dark text-bold margin-top-0 margin-bottom-2">Featured Resources</h2>
          <p>
            The following documents and hazard scientific data are featured in this section due to their importance,
            relavency, or interesting research to the community. If you would like a document or collection of data to
            be featured in this section, submit a request under the My Content tab in the navigation bar above.
          </p>
        </div>
        <div class="grid-row mt-5">
          <div class="grid-col-6" id="documents-container" aria-labelledby="documents-head">
            <featured-documents-section />
          </div>
          <div class="grid-col-6" id="hazards-container" aria-labelledby="hazards-head">
            <featured-hazard-data-section />
          </div>
        </div>
      </div>
    </section>
    <section class="usa-section">
      <div class="grid-container">
        <div class="grid-row grid-gap">
          <div class="grid-col" aria-labelledby="application-header">
            <applications-section />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import InactiveDialog from "@/components/navigation/InactiveDialog.vue";
import UserSearchPage from "@/components/navigation/UserSearch/UserSearchPage.vue";
import FeaturedDocumentsSection from "@/components/navigation/WelcomePage/FeaturedDocumentsSection.vue";
import FeaturedHazardDataSection from "@/components/navigation/WelcomePage/FeaturedHazardDataSection.vue";
import ApplicationsSection from "@/components/navigation/WelcomePage/ApplicationsSection.vue";
import ExploreCards from "@/components/navigation/WelcomePage/ExploreCards.vue";
import { SiteDocument } from "@/dataModel";
import store from "@/store";
import StoreNames from "@/constants/store/StoreNames";
import { UserSearchStoreActions } from "@/constants/store/userSearch/userSearchStoreConstants";

@Component({
  components: {
    InactiveDialog,
    UserSearchPage,
    FeaturedDocumentsSection,
    FeaturedHazardDataSection,
    ApplicationsSection,
    ExploreCards,
  },
})
export default class AuthorizedWelcomePage extends Vue {
  public currentUser: string = "";
  public accessTokenExpired: boolean | undefined = false;
  public isLoggedIn: boolean = false;
  public isAuthorized: boolean = false;
  public showInactiveAlert = false;
  public searchTerm: string = "";
  public featuredDocuments?: SiteDocument[];
  public featureHazardData?: SiteDocument[];
  public isSearchBoxFocused: boolean = false;

  goToSearch(withSearchTerm: boolean): void {
    const query = withSearchTerm ? { searchTerm: this.searchTerm } : undefined;
    store.dispatch(`${StoreNames.UserSearch}/${UserSearchStoreActions.SET_USE_SAVED_PARAMS}`, false);
    this.$router.push({
      name: "UserSearch",
      query,
    });
  }

  goToData(): void {
    this.$router.push({ name: "ExploreHazardData" });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.icon-background {
  color: #c0ffc0;
}

.usa-prose > p {
  max-width: none;
}

.fa-stack {
  width: 2em;
}

#search-field-en-big {
  max-width: none;
  border: none;
  padding: 1.4rem 1rem;

  &:focus {
    outline: none;
  }
}

#search-button {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  background-size: 1.4rem;
}

.usa-link:visited {
  color: white;
}

#documents-container {
  padding-right: 2em;
}

#hazards-container {
  padding-left: 2em;
}

.search-focused {
  box-shadow: #2491ff 0px 0px 8px;
}

#document-search-form {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  margin-bottom: 8px;
}

.usa-section--lightest {
  background-color: color("base-lightest");
}

.search-section {
  padding-bottom: 2rem;
}
</style>
