import { StagedFileStoreMutations } from "@/constants/store/stagedFile/stagedFileStoreConstants";
import { StagedFile, StagedFileWithDocumentMatch } from "@/dataModel";
import { StagedFileState } from "@/typings/store/states/StagedFileStoreState";

export default {
  [StagedFileStoreMutations.SET_SELECTED_FILES](state: StagedFileState, selectedFiles: StagedFile[]) {
    state.selectedFiles = selectedFiles;
  },
  [StagedFileStoreMutations.SET_STAGED_FILES_TO_UPDATE](
    state: StagedFileState,
    stagedFilesToUpdate: StagedFileWithDocumentMatch[],
  ) {
    state.stagedFilesToUpdate = stagedFilesToUpdate;
  },
  [StagedFileStoreMutations.SET_NUMBER_OF_FILES_UPDATED](state: StagedFileState, numberFilesUpdated: number) {
    state.numberFilesUpdated = numberFilesUpdated;
  },
  [StagedFileStoreMutations.SET_FILTERS](state: StagedFileState, filters: string[]) {
    state.filters = filters;
  },
  [StagedFileStoreMutations.SET_PAGE](state: StagedFileState, page: number) {
    state.page = page;
  },
  [StagedFileStoreMutations.SET_RESULTS_PER_PAGE](state: StagedFileState, resultsPerPage: number) {
    state.resultsPerPage = resultsPerPage;
  },
  [StagedFileStoreMutations.SET_SEARCH_TERM](state: StagedFileState, searchTerm: string) {
    state.searchTerm = searchTerm;
  },
  [StagedFileStoreMutations.RESET_STAGED_FILE_STATE](state: StagedFileState) {
    state.numberFilesUpdated = 0;
    state.selectedFiles = [];
    state.stagedFilesToUpdate = [];
    state.filters = [];
    state.page = 1;
    state.resultsPerPage = 20;
    state.searchTerm = "";
  },
};
