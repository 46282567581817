<template>
  <div>
    <basic-dialog
      id="update-document-dialog"
      title="Update Document"
      :primaryButton="primaryButton"
      :secondaryButton="secondaryButton"
      :primaryClick="primaryClick"
      :secondaryClick="secondaryClick"
      :isDisabled="disableDialog"
      :isLoading="isLoading"
      :loadingText="loadingText"
    >
      <div v-if="!showError && !isLoading">
        <div v-if="showComparisionTable">
          <p>This conversion will change the document's distribution restrictions. Please review the table below.</p>

          <table class="usa-table usa-table--stacked" width="100%">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Before</th>
                <th scope="col">After</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="showClassificationDowngradeText">
                <th scope="row">Classification</th>
                <td>{{ siteDocumentToUpdate.knowledgeResource.classification.title }}</td>
                <td>{{ stagedFileToUpdate.knowledgeResource.classification.title }}</td>
              </tr>

              <tr v-if="showDisseminationControlUpdateText">
                <th scope="row">Dissemination Controls</th>
                <td>
                  <ul class="usa-list margin-top-0">
                    <li
                      v-for="control in getDisseminationDisplay(
                        siteDocumentToUpdate.knowledgeResource.disseminationControls,
                      )"
                      :key="control"
                    >
                      {{ control }}
                    </li>
                  </ul>
                </td>
                <td>
                  <ul class="usa-list margin-top-0">
                    <li
                      v-for="control in getDisseminationDisplay(
                        stagedFileToUpdate.knowledgeResource.disseminationControls,
                      )"
                      :key="control"
                    >
                      {{ control }}
                    </li>
                  </ul>
                </td>
              </tr>

              <tr v-if="showSciUpdateText">
                <th scope="row">Sensitive Compartmented Information</th>
                <td>
                  <ul class="usa-list margin-top-0">
                    <li v-for="sci in getSciDisplay(siteDocumentToUpdate.knowledgeResource.scis)" :key="sci">
                      {{ sci }}
                    </li>
                  </ul>
                </td>
                <td>
                  <ul class="usa-list margin-top-0">
                    <li v-for="sci in getSciDisplay(stagedFileToUpdate.knowledgeResource.scis)" :key="sci">
                      {{ sci }}
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>

          <p class="margin-bottom-0">Would you like to continue?</p>
        </div>
        <p v-else class="usa-prose">Are you sure you want to update this document?</p>
      </div>
      <div v-else-if="!isLoading" class="usa-alert usa-alert--info usa-alert--slim margin-top-3">
        <div class="usa-alert__body">
          <p class="usa-alert__text">
            {{ errorText }}
          </p>
        </div>
      </div>
    </basic-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import { StagedFileDocumentMatch, StagedFile, SiteDocument, DisseminationControl, Sci } from "@/dataModel";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import SiteDocumentAdminHttpHelper from "@/components/resources/siteDocumentAdminHttpHelper";
import store from "@/store";
import StoreNames from "@/constants/store/StoreNames";
import { StagedFileStoreActions, StagedFileStoreGetters } from "@/constants/store/stagedFile/stagedFileStoreConstants";
import Spinner from "@/components/common/Spinner.vue";
import { isEqual } from "lodash";

@Component({
  components: {
    BasicDialog,
    Spinner,
  },
})
export default class UpdateDocumentDialog extends Vue {
  @Prop({ required: true }) stagedFileToUpdate!: StagedFile;
  @Prop({ required: true }) siteDocumentToUpdate!: SiteDocument;

  readonly updateButtonText: string = "Update";
  readonly retryButtonText: string = "Retry";
  readonly yesButtonText: string = "Yes";
  readonly cancelButtonText: string = "Cancel";
  readonly noButtonText: string = "No";
  classificationDowngrade = false;
  classificationDowngradeConfirmed = false;
  showClassificationDowngradeText = false;

  disseminationControlUpdate = false;
  disseminationControlUpdateConfirmed = false;
  showDisseminationControlUpdateText = false;

  sciUpdate = false;
  sciUpdateConfirmed = false;
  showSciUpdateText = false;

  primaryButtonText = this.updateButtonText;
  secondaryButtonText = this.cancelButtonText;
  done = false;
  errorDuringUpdate = false;
  isLoading = false;
  loadingText = "Updating document...";

  private siteDocumentAdminHttpHelper = container.get<SiteDocumentAdminHttpHelper>(
    serviceTypes.SiteDocumentAdminHttpHelper,
  );

  getDisseminationDisplay(controls: DisseminationControl[]): string[] {
    const elements = controls.map((dc) => dc.portionMark);
    return elements.length ? elements : ["N/A"];
  }

  getSciDisplay(scis: Sci[]): string[] {
    const elements = scis.map((sci) => sci.title);
    return elements.length ? elements : ["N/A"];
  }

  get primaryButton(): string {
    return this.primaryButtonText;
  }

  get secondaryButton(): string {
    return this.secondaryButtonText;
  }

  get showComparisionTable(): boolean {
    return this.showClassificationDowngradeText || this.showDisseminationControlUpdateText || this.showSciUpdateText;
  }

  secondaryClick = this.closeDialog;

  async primaryClick() {
    const stagedFileClassification = this.stagedFileToUpdate.knowledgeResource.classification;
    const siteDocumentClassification = this.siteDocumentToUpdate.knowledgeResource.classification;
    this.classificationDowngrade = stagedFileClassification.value < siteDocumentClassification.value;

    const stagedFileDisseminationControls = this.stagedFileToUpdate.knowledgeResource.disseminationControls ?? [];
    const siteDocumentDisseminationControls = this.siteDocumentToUpdate.knowledgeResource.disseminationControls ?? [];
    this.disseminationControlUpdate = !this.arrayEquals(
      stagedFileDisseminationControls,
      siteDocumentDisseminationControls,
    );

    const stagedFileScis = this.stagedFileToUpdate.knowledgeResource.scis ?? [];
    const siteDocumentScis = this.siteDocumentToUpdate.knowledgeResource.scis ?? [];
    this.sciUpdate = !this.arrayEquals(stagedFileScis, siteDocumentScis);

    if (
      (this.classificationDowngrade && !this.showClassificationDowngradeText) ||
      (this.disseminationControlUpdate && !this.showDisseminationControlUpdateText) ||
      (this.sciUpdate && !this.showSciUpdateText)
    ) {
      this.primaryButtonText = this.yesButtonText;
      this.secondaryButtonText = this.noButtonText;
      if (this.classificationDowngrade && !this.showClassificationDowngradeText) {
        this.showClassificationDowngradeText = true;
      }
      if (this.disseminationControlUpdate && !this.showDisseminationControlUpdateText) {
        this.showDisseminationControlUpdateText = true;
      }
      if (this.sciUpdate && !this.showSciUpdateText) {
        this.showSciUpdateText = true;
      }
      return;
    } else {
      if (this.classificationDowngrade && this.showClassificationDowngradeText) {
        this.classificationDowngradeConfirmed = true;
      }
      if (this.disseminationControlUpdate && this.showDisseminationControlUpdateText) {
        this.disseminationControlUpdateConfirmed = true;
      }
      if (this.sciUpdate && this.showSciUpdateText) {
        this.sciUpdateConfirmed = true;
      }
    }

    const matches = [new StagedFileDocumentMatch(this.stagedFileToUpdate.id, this.siteDocumentToUpdate.id)];
    this.isLoading = true;
    const response = await this.siteDocumentAdminHttpHelper.updateDocumentFilesAsync(
      matches,
      this.classificationDowngradeConfirmed,
      this.disseminationControlUpdateConfirmed,
      this.sciUpdateConfirmed,
    );
    this.isLoading = false;
    if (response.isSuccessful) {
      this.done = true;
      this.errorDuringUpdate = false;
      this.closeDialog();
      let numberFilesUpdated: number =
        store.getters[`${StoreNames.StagedFile}/${StagedFileStoreGetters.GET_NUMBER_OF_FILES_UPDATED}`];
      store.dispatch(
        `${StoreNames.StagedFile}/${StagedFileStoreActions.SET_NUMBER_OF_FILES_UPDATED}`,
        ++numberFilesUpdated,
      );
      const selectedFiles = store.getters[`${StoreNames.StagedFile}/${StagedFileStoreGetters.GET_SELECTED_FILES}`];
      store.dispatch(
        `${StoreNames.StagedFile}/${StagedFileStoreActions.SET_SELECTED_FILES}`,
        selectedFiles.filter((file) => file.id != this.stagedFileToUpdate.id),
      );
    } else {
      this.errorDuringUpdate = true;
      this.primaryButtonText = this.retryButtonText;
      this.secondaryButtonText = this.cancelButtonText;
    }
  }

  /** Compare 2 arrays for equality
   * @returns True if arrays are the same, regardless of order
   */
  arrayEquals(a: any[], b: any[]): boolean {
    return isEqual(a.sort(), b.sort());
  }

  closeDialog(): void {
    this.$emit("update", this.done);
    this.resetForm();
    this.$bvModal.hide("update-document-dialog");
  }

  resetForm(): void {
    this.classificationDowngrade = false;
    this.classificationDowngradeConfirmed = false;
    this.showClassificationDowngradeText = false;

    this.disseminationControlUpdate = false;
    this.disseminationControlUpdateConfirmed = false;
    this.showDisseminationControlUpdateText = false;

    this.sciUpdate = false;
    this.sciUpdateConfirmed = false;
    this.showSciUpdateText = false;

    this.done = false;
    this.errorDuringUpdate = false;
    this.isLoading = false;

    this.primaryButtonText = this.updateButtonText;
    this.secondaryButtonText = this.cancelButtonText;
  }

  get disableDialog(): boolean {
    return this.isLoading;
  }

  get showError(): boolean {
    return this.errorDuringUpdate;
  }

  get errorText(): string {
    return "Error occurred during file conversion.";
  }
}
</script>

<style scoped lang="scss">
.usa-prose {
  font-weight: bold;
  margin-top: 1.5rem;
}

.usa-table {
  thead tr th,
  tbody tr th {
    padding: 8px;
  }
}
</style>
