import HttpHelper from "@/components/resources/httpHelper";
import { RequestUserApprovalRequest } from "@/dataModel/requests";
import { inject, injectable } from "inversify";
import serviceTypes from "@/dependencyInjection/types";

const url = "auth/login/request-approval";

@injectable()
export default class AccessRequestHttpHelper {
  httpHelper: HttpHelper;

  constructor(@inject(serviceTypes.HttpHelper) httpHelper: HttpHelper) {
    this.httpHelper = httpHelper;
  }

  async requestAccess(requestData: RequestUserApprovalRequest) {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(url, requestData)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " +
                  response.status +
                  " : " +
                  response.statusText +
                  " when making an approval request.",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while making access request."));
        });
    });
  }
}
