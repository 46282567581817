import FileStatus from "../constants/FileStatus";
import { StagedFileEventLog, KnowledgeResource } from ".";
import { FileType } from "@/constants/FileType";

export class StagedFile {
  id: string;
  fileName: string;
  createdDateTime: Date;
  fileStatus: FileStatus;
  classification: string;
  disseminationControls: string[];
  scis: string[];
  isCompleted: boolean;
  isDeleted: boolean;
  log?: StagedFileEventLog[];
  knowledgeResource: KnowledgeResource;
  siteDocumentId: string;
  fileType: FileType;

  constructor(
    id?: string,
    knowledgeResource?: KnowledgeResource,
    fileName?: string,
    classification?: string,
    disseminationControls?: string[],
    scis?: string[],
    fileStatus?: FileStatus,
    createdDateTime?: Date,
    isCompleted?: boolean,
    isDeleted?: boolean,
    siteDocumentId?: string,
    fileType?: FileType,
  ) {
    this.id = id ?? "";
    this.knowledgeResource = knowledgeResource ?? new KnowledgeResource();
    this.fileName = fileName ?? "";
    this.classification = classification ?? "";
    this.disseminationControls = disseminationControls ?? [];
    this.scis = scis ?? [];
    this.fileStatus = fileStatus ?? FileStatus.AwaitingApproval;
    this.createdDateTime = createdDateTime ?? new Date(Date.now());
    this.isCompleted = isCompleted ?? false;
    this.isDeleted = isDeleted ?? false;
    this.siteDocumentId = siteDocumentId ?? "";
    this.fileType = fileType ?? FileType.Pdf;
  }
}
