import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import { StagedFileState } from "@/typings/store/states/StagedFileStoreState";

const state: StagedFileState = {
  selectedFiles: [],
  stagedFilesToUpdate: [],
  numberFilesUpdated: 0,
  filters: [],
  page: 1,  // page number is decremented when searching
  resultsPerPage: 20,
  searchTerm: "",
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
