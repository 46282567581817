<template>
  <div>
    <basic-dialog
      id="update-frequency-dialog"
      title="Settings"
      primaryButton="Save Changes"
      secondaryButton="Cancel"
      :primaryClick="updateSettings"
      :secondaryClick="cancel"
    >
      <fieldset class="usa-fieldset margin-top-2">
        <legend class="usa-legend usa-legend">Notification Frequency</legend>
        <div class="usa-radio radio-btn">
          <input
            class="usa-radio__input"
            id="frequency-daily"
            type="radio"
            name="frequency-daily"
            :value="FrequencyType.DAILY"
            v-model="notificationFrequency"
          />
          <label class="usa-radio__label fit" for="frequency-daily">Daily</label>
        </div>
        <div class="usa-radio radio-btn">
          <input
            class="usa-radio__input"
            id="frequency-weekly"
            type="radio"
            name="frequency-weekly"
            :value="FrequencyType.WEEKLY"
            v-model="notificationFrequency"
          />
          <label class="usa-radio__label fit" for="frequency-weekly">Weekly</label>
        </div>
        <div class="usa-radio radio-btn">
          <input
            class="usa-radio__input"
            id="frequency-monthly"
            type="radio"
            name="frequency-monthly"
            :value="FrequencyType.MONTHLY"
            v-model="notificationFrequency"
          />
          <label class="usa-radio__label fit" for="frequency-monthly">Monthly</label>
        </div>
      </fieldset>
    </basic-dialog>
    <success-toast
      id="notification-frequency-toast"
      :message="'Notification frequency successfully updated to ' + notificationFrequency + '.'"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import FrequencyType from "@/constants/FrequencyType";

@Component({
  components: {
    BasicDialog,
  },
})
export default class NotificationSettingsDialog extends Vue {
  notificationFrequency: string = "";

  FrequencyType = FrequencyType;

  cancel(): void {
    this.$bvModal.hide("update-frequency-dialog");
  }

  updateSettings(): void {
    this.$emit("confirm", this.notificationFrequency);
    this.$bvModal.hide("update-frequency-dialog");
  }

  initialize(frequency: string): void {
    this.notificationFrequency = frequency;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.usa-form {
  width: 100%;
  max-width: 100%;
}

.radio-btn {
  float: left;
  width: 33%;
}

.fit {
  width: fit-content;
}

.bold {
  font-weight: bold;
}
</style>
