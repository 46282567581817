export default class DataValueCategoryRequest {
  parentId: string;
  hazardCategoryId: string;

  constructor(
    parentId?: string,
    hazardCategoryId?: string,
  ) {
    this.parentId = parentId ?? "";
    this.hazardCategoryId = hazardCategoryId ?? "";
  }
}