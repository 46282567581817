<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <div class="desktop:grid-col-9">
          <h1 class="font-heading-xl text-primary-dark margin-top-0 margin-bottom-4">{{ siteDocument.title }}</h1>
        </div>
      </div>

      <document-input-fields
        ref="editDocument"
        @fields="updateDocument"
        @invalidForm="doneLoading"
        :document="siteDocument"
        :isNewCopyrightRestricted="false"
      />

      <loading-buttons
        class="padding-top-3 width-tablet"
        @primaryButtonClick="editDocumentHandler"
        @secondaryButtonClick="returnToDocumentManagement"
        :isLoading="isLoading"
        :secondaryButtonDisabled="isLoading"
        primaryButtonText="Save Changes"
        secondaryButtonText="Back"
        loadingText="Saving Changes..."
      />

      <div v-if="errorMessage" class="pre-formatted usa-alert usa-alert--error usa-alert--slim">
        <div class="usa-alert__body">
          <p class="usa-alert__text">{{ errorMessage }}</p>
        </div>
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import { DocumentStoreGetters } from "@/constants/store/document/documentStoreConstants";
import StoreNames from "@/constants/store/StoreNames";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import { BreadcrumbPathItem } from "@/dataModel/interfaces";
import { SiteDocument, TitlePermissionIds } from "@/dataModel";
import DocumentInputFields from "@/components/navigation/DocumentManagement/DocumentInputFields.vue";
import SiteDocumentAdminHttpHelper, {
  UpdateSiteDocumentRequest,
} from "@/components/resources/siteDocumentAdminHttpHelper";
import serviceTypes from "@/dependencyInjection/types";
import container from "@/dependencyInjection/config";
import LoadingButtons from "@/components/common/LoadingButtons.vue";

@Component({
  components: {
    Breadcrumb,
    DocumentInputFields,
    LoadingButtons,
  },
})
export default class EditDocument extends Vue {
  siteDocument!: SiteDocument;
  isLoading: boolean = false;
  errorMessage = "";

  private siteDocumentAdminHttpHelper = container.get<SiteDocumentAdminHttpHelper>(
    serviceTypes.SiteDocumentAdminHttpHelper,
  );

  path: BreadcrumbPathItem[] = [
    {
      text: "Administration",
    },
    {
      text: "Document Management",
      url: "/document-management",
    },
    {
      text: "Edit Document",
    },
  ];

  async updateDocument(document: SiteDocument): Promise<void> {
    this.isLoading = true;
    this.errorMessage = "";

    const titlePermissionIds = new TitlePermissionIds(
      document.knowledgeResource.titleClassifications.classification!.id,
      document.knowledgeResource.titleClassifications.disseminationControls!.map((x) => x.id),
      document.knowledgeResource.titleClassifications.scis!.map((x) => x.id),
    );
    const request = new UpdateSiteDocumentRequest();
    request.titlePermissionIds = titlePermissionIds;
    request.siteDocumentId = document.id;
    request.classification = document.knowledgeResource.classification.id;
    request.disseminationControls = document.knowledgeResource.disseminationControls.map((x) => x.id);
    request.scis = document.knowledgeResource.scis.map((x) => x.id);
    request.audiences = document.knowledgeResource.audiences.map((x) => x.id);
    request.title = document.title;
    request.documentType = document.documentType;
    request.contributors = document.contributors;
    request.pages = document.pages;
    request.journalName = document.journalName;
    request.isPublished = document.isPublished;
    request.doi = document.doi;
    request.volume = document.volume;
    request.abstract = document.abstract;
    request.documentDateTime = document.documentDateTime;
    request.isCopyrightRestricted = document.isCopyrightRestricted;
    request.categoryIds = document.categoryIds;
    request.documentDatePrecision = document.documentDatePrecision;
    try {
      await this.siteDocumentAdminHttpHelper.updateSiteDocument(request);
      this.$router.push({ name: "DocumentManagement", query: { usePreviousSetup: "true", editSuccess: "true" } });
    } catch (error) {
      const errorMessage = (error as Error).message;
      this.errorMessage = errorMessage.substring(errorMessage.indexOf(":") + 2);
    } finally {
      this.isLoading = false;
    }
  }

  editDocumentHandler(): void {
    (<DocumentInputFields>this.$refs.editDocument).emitFields();
  }

  doneLoading(): void {
    this.isLoading = false;
  }

  returnToDocumentManagement(): void {
    this.isLoading = true;
    this.$router.push({ name: "DocumentManagement", query: { usePreviousSetup: "true" } });
  }

  created(): void {
    this.siteDocument = store.getters[`${StoreNames.Document}/${DocumentStoreGetters.GET_SELECTED_DOCUMENT}`];
  }
}
</script>

<style scoped lang="scss">
.line-break {
  margin: 0;
  background: #c9c9c9;
}

.sub-header {
  display: block;
  font-family: family("heading");
  font-size: size("heading", "md");
  line-height: lh("heading", 2);
  font-weight: font-weight("bold");
  color: color("primary-dark");
  margin-top: units(4);
}

.update-button {
  margin-top: 1.3rem;
}

.document-container {
  width: 75%;
}

.request-type-container button {
  height: fit-content;
  padding-top: 0.3rem;
  margin-left: units(2);
}

.hide-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pre-formatted {
  white-space: pre;
}
</style>
