<template>
  <data-table
    @selections="selectAllDataValues"
    @expandAllMetadata="expandAllMetadata"
    @changePublication="changePublicationStatus"
    @changeStatus="changeDataValueStatus"
    @delete="deleteDataValues"
    :items="dataValues"
    :fields="headers"
    :bulkActions="bulkActions"
    :selected="selectedDataValues"
    :noLocalSort="false"
    :perPage="resultsPerPage"
    ref="dataValueTable"
  >
    <template #cell(value)="data">
      <div class="usa-checkbox margin-y-05 maxw-mobile table-col-md">
        <input
          class="usa-checkbox__input"
          :id="data.item.id"
          type="checkbox"
          :name="data.value"
          v-model="selectedDataValues"
          :value="data.item"
        />
        <label :class="['usa-checkbox__label', 'margin-0', 'overflow-ellipsis', 'text-bold']" :for="data.item.id">
          {{ data.value }} {{ data.item.scientificUnit.name }}
        </label>
      </div>
    </template>
    <template #cell(property)="data">
      <div class="margin-y-05">
        {{ getPropertyNameById(data.item.propertyId) }}
      </div>
    </template>
    <template #cell(status)="data">
      <div class="margin-y-05">
        <i :class="getStatusIconClasses(data.item.status)" />
        <span class="ml-2">{{ data.value }}</span>
      </div>
    </template>
    <template v-slot:cell(show_details)="row">
      <div class="mt-1">
        <a tabindex="0" @click="row.toggleDetails" @keypress.enter="row.toggleDetails" class="usa-link expander">
          <i
            v-if="!row.detailsShowing"
            class="fa fa-plus usa-tooltip"
            data-position="top"
            title="Click for more information"
          ></i>
          <i v-else class="fa fa-minus"></i>
        </a>
      </div>
    </template>
    <template v-slot:row-details="row">
      <div class="mt-4 mb-4">
        <div class="grid-row grid-gap">
          <div class="grid-col-3 mb-4">
            <p class="usa-prose uppercase mb-1">Value/Scientific Unit</p>
            <p class="usa-prose text-bold">{{ row.item.value }} {{ row.item.scientificUnit.name }}</p>
          </div>
          <div class="grid-col-3 mb-4">
            <p class="usa-prose uppercase mb-1">Hazard</p>
            <p class="usa-prose text-bold">{{ getHazardCategoryNameById(row.item.hazardId) }}</p>
          </div>
          <div class="grid-col-3 mb-4">
            <p class="usa-prose uppercase mb-1">Hazard Property</p>
            <p class="usa-prose text-bold">{{ getPropertyNameById(row.item.propertyId) }}</p>
          </div>
          <div class="grid-col-3 mb-4">
            <p class="usa-prose uppercase mb-1">Source Evaluation</p>
            <p class="usa-prose text-bold">{{ row.item.evaluationRating.name }}</p>
          </div>
          <div v-if="row.item.uncertaintyMeasure" class="grid-col-3 mb-4">
            <p class="usa-prose uppercase mb-1" :title="row.item.uncertaintyMeasure.description">Uncertainty Measure</p>
            <p class="usa-prose text-bold">{{ row.item.uncertaintyMeasure.name }}</p>
          </div>
          <div v-if="row.item.uncertaintyValueOne" class="grid-col-3 mb-4">
            <p class="usa-prose uppercase mb-1" :title="row.item.uncertaintyMeasure.name">Uncertainty Value 1</p>
            <p class="usa-prose text-bold">{{ row.item.uncertaintyValueOne }} {{ row.item.scientificUnit.name }}</p>
          </div>
          <div v-if="row.item.uncertaintyValueTwo" class="grid-col-3 mb-4">
            <p class="usa-prose uppercase mb-1" :title="row.item.uncertaintyMeasure.name">Uncertainty Value 2</p>
            <p class="usa-prose text-bold">{{ row.item.uncertaintyValueTwo }} {{ row.item.scientificUnit.name }}</p>
          </div>
          <div v-for="category in row.item.categories" :key="category.id" class="grid-col-3 mb-4">
            <p class="usa-prose uppercase mb-1" :title="category.description">{{ category.displayName }}</p>
            <p class="usa-prose text-bold">{{ joinCategoryValues(category) }}</p>
          </div>
          <div v-for="detail in row.item.details" :key="detail.id" class="grid-col-3 mb-4">
            <p class="usa-prose uppercase mb-1" :title="detail.description">{{ detail.name }}</p>
            <p class="usa-prose text-bold">{{ joinDetailValues(detail) }}</p>
          </div>
        </div>

        <div class="grid-row">
          <div class="grid-col align-right">
            <button
              class="usa-button margin-0"
              :disabled="!isEditValueEnabled(row.item)"
              @click="editDataValue(row.item)"
            >
              Edit Data Value
            </button>
          </div>
        </div>
        <div v-if="!isEditValueEnabled(row.item)" class="grid-row flex-align-center">
          <div class="grid-col align-right">
            <div
              class="display-flex flex-row usa-tooltip padding-top-1"
              title="You do not have permission to edit this data value. For questions about permissions, contact an administrator."
            >
              <span aria-labelledby="edit-disabled-text">
                <i class="fa fa-question-circle" aria-hidden="true"></i>
              </span>
              <p id="edit-disabled-text" class="margin-left-05 font-sans-2xs">Why is editing disabled?</p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </data-table>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import DataTable from "@/components/common/DataTable.vue";
import { DataTableHeader, DataTableAction } from "@/dataModel/interfaces";
import DataSource from "@/dataModel/hazardData/dataSource";
import HazardPropertyDetails from "@/dataModel/hazardData/hazardPropertyDetails";
import { HazardDataStatus } from "@/constants/HazardDataStatus";
import HazardDataValue from "@/dataModel/hazardData/hazardDataValue";
import HazardDetail from "@/dataModel/hazardData/details/hazardDetail";
import HazardCategoryDetails from "@/dataModel/hazardData/hazardCategoryDetails";
import { DetailValueType } from "@/constants/DetailValueType";
import { Category, Actor } from "@/dataModel";
import ServiceTypes from "@/dependencyInjection/types";
import IAuthService from "@/services/interfaces/IAuthService";
import Container from "@/dependencyInjection/config";
import HazardTypeOption from "@/dataModel/hazardData/hazardTypeOption";
import { HazardType } from "@/constants/HazardType";

@Component({
  components: {
    DataTable,
  },
})
export default class DataValueTable extends Vue {
  dataValues: HazardDataValue[] = [];
  selectedDataValues: HazardDataValue[] = [];
  isAllMetadataExpanded: boolean = false;
  resultsPerPage: number = Number.MAX_SAFE_INTEGER;
  authorizedHazardTypeOptions: HazardTypeOption[] = [];

  @Prop() dataCollection!: DataSource;
  @Prop() propertyFilterId!: string;
  @Prop() allCategories!: Category[];

  private authService = Container.get<IAuthService>(ServiceTypes.AuthService);

  headers: DataTableHeader[] = [
    {
      label: "Values",
      key: "value",
      sortable: true,
    },
    {
      label: "Hazard Property",
      key: "propertyId",
      sortable: true,
      formatter: this.getPropertyNameById,
      sortByFormatted: true,
    },
    {
      label: "Published",
      key: "isPublished",
      sortable: true,
      formatter: this.formatIsPublished,
      sortByFormatted: true,
    },
    {
      label: "Status",
      key: "status",
      sortable: true,
      formatter: this.formatStatus,
      sortByFormatted: true,
    },
    {
      label: "Last Updated",
      key: "modifiedDateTime",
      sortable: true,
      formatter: (value) => {
        return this.$options.filters!.formatDate(value);
      },
    },
    {
      // header for show details
      label: "",
      key: "show_details",
      sortable: false,
    },
  ];

  bulkActions: DataTableAction[] = [
    {
      name: "Expand All Metadata",
      icon: "fa-plus",
      action: "expandAllMetadata",
    },
    {
      name: "Change Publication",
      icon: "fa-check",
      action: "changePublication",
    },
    {
      name: "Change Status",
      icon: "fa-pen",
      action: "changeStatus",
    },
    {
      name: "Delete",
      icon: "fa-times",
      action: "delete",
    },
  ];

  toggleAdditionalInformationAction(): void {
    if (this.isAllMetadataExpanded) {
      Vue.set(this.bulkActions, 0, {
        name: "Collapse All Metadata",
        icon: "fa-minus",
        action: "expandAllMetadata",
      });
    } else {
      Vue.set(this.bulkActions, 0, {
        name: "Expand All Metadata",
        icon: "fa-plus",
        action: "expandAllMetadata",
      });
    }
  }

  selectAllDataValues(allDataValues: any[]): void {
    this.selectedDataValues = allDataValues;
  }

  expandAllMetadata(): void {
    for (let i = 0; i < this.dataValues.length; i++) {
      Vue.set(this.dataValues[i], "_showDetails", !this.isAllMetadataExpanded);
    }
    this.isAllMetadataExpanded = !this.isAllMetadataExpanded;
    this.toggleAdditionalInformationAction();
  }

  @Watch("selectedDataValues")
  selectedDataValuesChanged(): void {
    this.$emit("selected-data-values-changed", this.selectedDataValues);
  }

  editDataValue(dataValue: HazardDataValue): void {
    this.$emit("edit-data-value", dataValue);
  }

  @Emit()
  deleteDataValues(): void {}

  @Emit()
  changePublicationStatus(): void {}

  @Emit()
  changeDataValueStatus(): void {}

  getStatusIconClasses(status: HazardDataStatus): string[] {
    switch (status) {
      case HazardDataStatus.AwaitingApproval:
        return ["fa", "fa-clock", "text-gray-50"];
      case HazardDataStatus.Approved:
        return ["fa", "fa-check", "text-light-green"];
      case HazardDataStatus.Declined:
        return ["fa", "fa-times", "text-red", "mr-1"];
    }
    return [""];
  }

  formatIsPublished(isPublished: boolean): string {
    return isPublished ? "Published" : "Unpublished";
  }

  formatStatus(status: HazardDataStatus): string {
    switch (status) {
      case HazardDataStatus.AwaitingApproval:
        return "Waiting";
      case HazardDataStatus.Approved:
        return "Approved";
      case HazardDataStatus.Declined:
        return "Declined";
    }
    return "Unknown";
  }

  @Watch("dataCollection", { deep: true })
  dataCollectionChanged(): void {
    this.setDataValues();
  }

  @Watch("propertyFilterId")
  propertyFilterChanged(): void {
    this.setDataValues();
  }

  setDataValues(): void {
    this.dataValues = this.flattenDataValues(this.dataCollection.properties);
  }

  flattenDataValues(properties: HazardPropertyDetails[]): HazardDataValue[] {
    let flattenedHazardDataValues: HazardDataValue[] = [];
    if (this.propertyFilterId == "") {
      properties.forEach((property) => {
        flattenedHazardDataValues.push(...property.valueDetails);
      });
    } else {
      let filteredProperties = properties.filter((property) => property.id == this.propertyFilterId)[0];
      flattenedHazardDataValues.push(...filteredProperties.valueDetails);
    }

    return flattenedHazardDataValues;
  }

  getPropertyNameById(propertyId: string): string {
    return this.dataCollection.properties.find((property) => property.id == propertyId)?.name ?? "Unknown";
  }

  getHazardCategoryNameById(hazardCategoryId: string): string {
    return this.allCategories.find((category) => category.id == hazardCategoryId)?.displayName ?? "Unknown";
  }

  joinDetailValues(detailValue: HazardDetail): string {
    if (detailValue.valueType == DetailValueType.DateTime) {
      return detailValue.values.map((value) => this.$options.filters!.formatDate(value.value)).join(", ");
    }
    return detailValue.values.map((value) => value.value).join(", ");
  }

  joinCategoryValues(categoryValue: HazardCategoryDetails): string {
    return categoryValue.categories.map((category) => category.displayName).join(", ");
  }

  isEditValueEnabled(value: HazardDataValue) {
    const type = HazardType[value.hazardTypeId.toString()];
    if (!type) {
      console.error("Unexpected Hazard Type:", value.hazardTypeId);
      return false;
    }

    return this.authorizedHazardTypeOptions.some((o) => o.type === type);
  }

  mounted(): void {
    const actor = Object.assign(new Actor(), this.authService.getUser().actor);
    this.authorizedHazardTypeOptions = actor.getAuthorizedHazardTypeOptions();
  }
}
</script>

<style scoped lang="scss">
.text-light-green {
  color: rgb(0, 151, 63);
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.uppercase {
  text-transform: uppercase;
}
</style>
