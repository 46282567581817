import { FilterType } from "@/constants/FilterType";
import FormatType from "@/constants/FormatType";
import { v4 as uuidv4 } from "uuid";

export class FilterNodeInformation {
  id: string = uuidv4();
  value?: any;
  children?: FilterNodeInformation[];
  path?: string[];
  stringValue?: string;
  hideSelectAll: boolean = false;
  count: number | undefined = 0;
  filterType: FilterType = FilterType.None;
  isExpanded: boolean = false;

  label: string = "";
  formatType: FormatType = FormatType.Normal;
  hasChildren: boolean = false;

  constructor(value?: any, stringValue?: string, path?: string[], children?: FilterNodeInformation[]) {
    this.value = value;
    this.stringValue = stringValue;
    this.path = path;
    this.children = children;
  }

  get leafNodes(): FilterNodeInformation[] {
    return this.getLeafNodes([this]);
  }

  private getLeafNodes(tree: FilterNodeInformation[]): FilterNodeInformation[] {
    const list: FilterNodeInformation[] = [];

    tree.forEach((element) => {
      if (!element) {
        return;
      }
      element.count = element.value?.dataValueCount;

      if (Array.isArray(element.children)) {
        list.push(...this.getLeafNodes(element.children));
      } else {
        list.push(element);
      }
    });

    return list;
  }
}
