import { Vue, Component } from "vue-property-decorator";
import { Classification, DisseminationControl, KnowledgeResourceAudience, Sci } from "@/dataModel";
import ServiceTypes from "@/dependencyInjection/types";
import IAuthService from "@/services/interfaces/IAuthService";
import Container from "@/dependencyInjection/config";

@Component
export class UserMixin extends Vue {
  private authService = Container.get<IAuthService>(ServiceTypes.AuthService);

  userClearanceLevel!: Classification;
  userDisseminationControls: DisseminationControl[] = [];
  userAudiences: KnowledgeResourceAudience[] = [];
  userScis: Sci[] = [];

  async getUser(): Promise<void> {
    const user = this.authService.getUser();
    this.userClearanceLevel = user.actor!.clearanceLevel;
    this.userDisseminationControls = user.actor!.readOnlyDisseminationControls ?? [];
    this.userAudiences = user.actor!.readOnlyAudiences ?? [];
    this.userScis = user.actor!.scis ?? [];
  }
}
