<template>
  <div style="position: relative">
    <div>
      <label class="usa-label margin-top-2" for="graph-options">View Graph By</label>
      <select
        class="usa-select"
        name="graph-by-options"
        id="graph-options"
        v-model="selectedGraphOptionIndex"
        @change="graphOptionChanged"
      >
        <option v-for="(opt, i) in graphByOptions" :key="`graph-by-${i}`" :value="i">
          {{ opt.displayName }}
        </option>
      </select>
    </div>

    <h1 class="font-heading-lg text-primary-dark margin-top-5">
      {{ graphTitle }}
    </h1>

    <div v-if="boxPlotData.length > 0" class="display-flex flex-column flex-align-end">
      <p class="font-sans-3xs margin-0">
        Units: <strong>{{ graphUnits }}</strong>
      </p>
    </div>

    <div>
      <div v-for="(series, index) in boxPlotData" :key="`series${index}`" class="grid-row series-row" role="figure">
        <!-- Series selection -->
        <div class="grid-col-auto display-flex flex-align-center">
          <div class="usa-checkbox">
            <input
              type="checkbox"
              class="usa-checkbox__input"
              :id="seriesId(index)"
              :name="series.name"
              :value="series"
              v-model="selectedSeries"
              :aria-labelledby="`series-label-${index}`"
            />
            <label class="usa-checkbox__label text-bold" :for="seriesId(index)"> </label>
          </div>
        </div>

        <!-- Series header -->
        <div class="grid-col-2 display-flex flex-align-center margin-left-1">
          <span class="icon">
            <i class="fa fa-circle fa-xs" :style="{ color: getSeriesColor(index) }"></i>
          </span>
          <p class="series-name" :id="`series-label-${index}`">{{ series.name }}</p>
          <button
            class="usa-button usa-button--outline series-stats-btn"
            @click="onDataSeriesInfoClick(index)"
            aria-label="Series Stats"
          >
            View Stats
          </button>
        </div>

        <!-- Box plot -->
        <div class="grid-col">
          <plotly
            :id="`graph${seriesId(index)}`"
            :ref="seriesId(index)"
            :data="plotData[index]"
            :layout="getPlotLayout(index)"
            :displayModeBar="false"
          />
        </div>
      </div>
    </div>

    <!-- Selected values panel -->
    <div v-if="boxPlotData.length > 0">
      <div class="margin-top-5 grid-row">
        <div class="grid-col">
          <h1 class="font-heading-md text-primary-dark">Selected Values</h1>
        </div>
        <div class="grid-col-auto">
          <button
            v-if="selectedValueIds.length > 0"
            class="usa-button usa-button--outline"
            @click="clearSelectedPoints"
          >
            Clear All
          </button>
        </div>
      </div>
      <p>Select individual values in the plots above to build a table of selected values.</p>

      <!-- Selected values table -->
      <data-table
        :fields="modifiedTableHeaders"
        :items="selectedDataValues"
        :actions="tableActions"
        :singleSelect="true"
        :isResponsive="true"
        :useCustomDefaultCell="true"
        :hover="true"
        @row-hovered="onDataRowHover"
        @row-unhovered="onDataRowUnhover"
        @removeSelection="onSelectionRemove"
      >
        <template #cell(Value)="data">
          <div class="margin-y-05 table-col-md">
            <p class="text-bold margin-y-05">{{ data.item["Value-SiValue"] }} {{ getValueUnitDisplay(data.item) }}</p>
            <p class="margin-y-05">({{ data.item["Value-Value"] }} {{ data.item["Value-ScientificUnit"] }})</p>
          </div>
        </template>
        <template #cell(Source)="data">
          <div class="margin-y-05 table-col-lg">
            <a
              href="#/"
              @click="routeToSource(data.item)"
              class="usa-link"
              :title="data.item['Source-Title']"
              :aria-label="data.item['Source-Title']"
            >
              <span>{{ data.item["Source-Title"] | truncate(60) }}</span>
            </a>
            <div>{{ data.item["Source-ContributorDisplay"] }}</div>
          </div>
        </template>
        <template #cell(EvaluationRating)="data">
          <div class="margin-y-05">
            <i :class="`fa fa-circle fa-xs mr-2 ${data.item.getEvaluationRatingIconColor(data.value)}`" />
            {{ data.value }}
          </div>
        </template>
        <template #cell()="data">
          <div class="margin-y-05 table-col-lg" :title="data.value">
            {{ data.value }}
          </div>
        </template>
      </data-table>
    </div>
    <div v-else class="display-flex flex-align-center">
      <p>No data points are selected</p>
    </div>

    <div class="loading" v-show="isBusy">
      <spinner id="spinner" />
    </div>

    <box-plot-stats-dialog id="box-stats-dialog" :stats="boxPlotStatsToShow" />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import { Plotly } from "vue-plotly";
import { PlotlyHTMLElement } from "plotly.js";
import { flatten } from "lodash";
import { BoxPlotSeries, BoxPlotDataPoint } from "@/dataModel";
import Colors from "@/components/common/uswdsColors";
import DataValueOption from "@/dataModel/hazardData/dataValueOption";
import HazardPropertyDetails from "@/dataModel/hazardData/hazardPropertyDetails";
import DataValueResult from "@/dataModel/hazardData/dataValueResult";
import { DataTableHeader, DataTableAction } from "@/dataModel/interfaces";
import DataTable from "@/components/common/DataTable.vue";
import Spinner from "@/components/common/Spinner.vue";
import BoxPlotStats from "@/dataModel/hazardData/boxPlotStats";
import BoxPlotStatsDialog from "./Dialogs/BoxPlotStatsDialog.vue";
import IDataValueResultsRequest from "@/dataModel/hazardData/interfaces/IDataValueResultsRequest";
import container from "@/dependencyInjection/config";
import HazardDataSearchHttpHelper from "@/components/resources/hazardDataSearchHttpHelper";
import serviceTypes from "@/dependencyInjection/types";
import { flattenDataValueOptionResults } from "./hazardDataUtils";

@Component({
  components: {
    Plotly,
    DataTable,
    Spinner,
    BoxPlotStatsDialog,
  },
})
export default class DataGraphView extends Vue {
  @Prop({ required: true }) readonly boxPlotData!: BoxPlotSeries[];
  @Prop({ required: true }) readonly selectedProperty?: HazardPropertyDetails;
  @Prop({ required: true }) readonly graphByOptions!: DataValueOption[];
  @Prop({ required: true }) readonly dataValues!: DataValueResult[];
  @Prop({ required: true }) readonly tableHeaders!: DataTableHeader[];
  @Prop({ default: () => [] }) readonly selectedHazardDataOptions!: DataValueOption[];
  @Prop({ default: false }) isBusy!: boolean;

  boxPlotStatsToShow = new BoxPlotStats();
  cachedDataValues: DataValueResult[] = [];
  selectedSeries: BoxPlotSeries[] = [];
  selectedDataValues: DataValueResult[] = [];
  hoveredPointId: string = "";
  selectedGraphOptionIndex: number = 0;

  private hazardDataSearchHttpHelper = container.get<HazardDataSearchHttpHelper>(
    serviceTypes.HazardDataSearchHttpHelper,
  );

  tableActions: DataTableAction[] = [
    {
      name: "",
      icon: "fa-minus-square fa-lg",
      action: "removeSelection",
    },
  ];

  @Watch("boxPlotData")
  dataChanged() {
    this.updatePlots();
    this.cachedDataValues = [];
  }

  get graphTitle(): string {
    const selectedOption = this.graphByOptions[this.selectedGraphOptionIndex];
    return `Showing ${this.selectedProperty?.name} by ${selectedOption?.displayName ?? ""}`;
  }

  get graphUnits(): string {
    return this.boxPlotData[0]?.unit ?? "N/A";
  }

  /** Returns the graph range to be used by all selected series based on overall max value. */
  get graphRange(): number[] {
    const allData = flatten(this.selectedSeries.map((s) => s.dataPoints.map((d) => d.siValue)));
    const max = Math.max(...allData);

    return allData.length === 0 ? [0, 1] : [-(max * 0.01), max * 1.01];
  }

  get selectedValueIds(): string[] {
    return this.selectedDataValues.map((val) => val.dataValueId);
  }

  get modifiedTableHeaders(): DataTableHeader[] {
    return [
      {
        // header for actions
        label: "",
        key: "actions",
        sortable: false,
      },
      // List of selected headers, but are not sortable for the selected table
      ...this.tableHeaders.map((h) => {
        return {
          label: h.label,
          key: h.key,
          sortable: false,
        };
      }),
    ];
  }

  @Emit("graph-option-changed")
  graphOptionChanged(event: any): DataValueOption {
    const optIndex = Number(event.target.value);
    const selection = this.graphByOptions[optIndex];
    return selection;
  }

  @Emit("source-clicked")
  routeToSource(value: DataValueResult): DataValueResult {
    return value;
  }

  getValueUnitDisplay(value: DataValueResult): string {
    const siUnit: string = value["Value-SiUnit"] ?? "none";
    if (siUnit.toLowerCase() === "none") {
      return "";
    }

    return siUnit || value["Value-Unit"];
  }

  onDataSeriesInfoClick(seriesIndex: number) {
    const plot: Plotly = this.$refs[this.seriesId(seriesIndex)]?.[0].$el;
    const boxData = plot.calcdata[0][0];
    const series: string = this.boxPlotData[seriesIndex].name;

    this.boxPlotStatsToShow.AssignFromData(series, boxData);
    this.$bvModal.show("box-stats-dialog");
  }

  onDataRowHover(dataValue: DataValueResult): void {
    this.hoveredPointId = dataValue.dataValueId;
  }

  onDataRowUnhover(dataValue: DataValueResult): void {
    if (this.hoveredPointId === dataValue.dataValueId) {
      this.hoveredPointId = "";
    }
  }

  onSelectionRemove(dataValue: DataValueResult): void {
    const index = this.selectedDataValues.indexOf(dataValue);
    this.selectedDataValues.splice(index, 1);
    this.hoveredPointId = "";
  }

  seriesId(index: number): string {
    return `series-${index}`;
  }

  /** Returns a color based on the series' index */
  getSeriesColor(index: number): string {
    const numColors = Object.keys(Colors).length;
    const colorIndex = index % numColors;

    return Object.values(Colors)[colorIndex];
  }

  /** Returns the custom plot layout configuration depending on the index of the series. */
  getPlotLayout(index: number) {
    const isFirstSeries = index === 0;
    const isLastSeries = index === this.boxPlotData.length - 1;

    return {
      showlegend: false,
      margin: {
        t: 20,
        r: 0,
        b: 20,
        l: 10,
      },
      font: {
        family: "Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif",
      },
      modebar: {
        activecolor: this.getSeriesColor(index),
      },
      hoverlabel: {
        font: {
          family: "Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif",
        },
        bgcolor: this.getSeriesColor(index),
      },
      height: 150,
      autosize: true,
      hovermode: "closest",
      xaxis: {
        side: isFirstSeries ? "top" : "bottom",
        ticklabeloverflow: "allow",
        showticklabels: isFirstSeries || isLastSeries,
        range: this.graphRange,
        fixedrange: true,
        zerolinecolor: "rgb(200,200,200)",
        exponentformat: "e",
      },
      yaxis: {
        showticklabels: false,
        fixedrange: true,
        zeroline: false,
        showline: false,
        showgrid: false,
      },
    };
  }

  get plotData() {
    return this.boxPlotData.map((series, i) => {
      if (this.selectedSeries.includes(series) == false) {
        return [];
      }

      let selectedIndicies: number[] = [];

      // Utilize the selected point style for hover styling. We are limited here on having unique style amongst the boxplot markers
      const hoveredPointIndex = series.dataPoints.findIndex((pt) => pt.dataValueId === this.hoveredPointId);
      const setHoverStyle = hoveredPointIndex !== -1;
      if (setHoverStyle) {
        selectedIndicies = [hoveredPointIndex];
      } else {
        for (let i = 0; i < series.dataPoints.length; i++) {
          if (this.selectedValueIds.includes(series.dataPoints[i].dataValueId)) {
            selectedIndicies.push(i);
          }
        }
      }

      return [
        {
          name: series.name,
          x: series.dataPoints.map((p) => p.siValue),
          type: "box",
          boxmean: true,
          boxpoints: "all",
          pointpos: 0,
          jitter: 0.95,
          line: {
            color: "black",
            width: 1,
          },
          unselected: {
            marker: {
              color: this.getSeriesColor(i),
              size: 5,
              opacity: selectedIndicies.length > 0 ? 0.4 : 1,
            },
          },
          selected: {
            marker: {
              color: setHoverStyle ? "rgb(36,157,255)" : this.getSeriesColor(i),
              size: setHoverStyle ? 12 : 8,
            },
          },
          selectedpoints: selectedIndicies,
          fillcolor: "transparent",
          hoverinfo: "x",
          hoveron: "points",
        },
      ];
    });
  }

  async onPlotPointClick(data: any): Promise<void> {
    const pointData = data.points[0];
    const boxSeries: BoxPlotSeries = this.boxPlotData.filter((d) => d.name === pointData.data.name)[0];
    const clickedPoint: BoxPlotDataPoint = boxSeries.dataPoints[pointData.pointNumber];

    const pos = this.selectedDataValues.findIndex((val) => val.dataValueId === clickedPoint.dataValueId);
    if (pos !== -1) {
      this.selectedDataValues.splice(pos, 1);
    } else {
      let value = this.dataValues.find((val) => val.dataValueId === clickedPoint.dataValueId);

      if (!value) {
        // check cached values before making API call
        value = this.cachedDataValues.find((val) => val.dataValueId === clickedPoint.dataValueId);

        if (!value) {
          // retrieve value from API
          const request: IDataValueResultsRequest = {
            dataValueId: clickedPoint.dataValueId,
            selectedOptions: this.selectedHazardDataOptions,
          };
          const { dataValueId, optionResults } =
            await this.hazardDataSearchHttpHelper.GetHazardDataValueResultsByIdAsync(request);

          value = new DataValueResult(dataValueId, optionResults);
          flattenDataValueOptionResults([value]);

          this.cachedDataValues.push(value);
        }
      }

      this.selectedDataValues.push(value);
    }
  }

  clearSelectedPoints(): void {
    this.selectedDataValues = [];
  }

  updatePlots(): void {
    this.clearSelectedPoints();
    this.selectedSeries = [...this.boxPlotData];

    this.$nextTick(() => {
      const plots: PlotlyHTMLElement[] = Object.values(this.$refs).map((r) => r?.[0]?.$el as PlotlyHTMLElement);
      plots.forEach((plot) => {
        if (!plot) {
          return;
        }
        plot.removeAllListeners("plotly_click");
        plot.on("plotly_click", this.onPlotPointClick);
      });
    });
  }

  mounted(): void {
    this.updatePlots();
  }
}
</script>

<style lang="scss" scoped>
.loading {
  background-color: rgba($color: #ffffff, $alpha: 0.4);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

  #spinner {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.series-stats-btn {
  position: absolute;
  bottom: 0.5em;
  left: 0;
  right: 0;
  padding: 0.5em;
}

.series-name {
  font-weight: bold;
  margin: 2px 0 0 0.5rem;
}

.series-row {
  border-top: 1px solid #dddddd;

  &:last-of-type {
    border-bottom: 1px solid #dddddd;
  }
}
</style>
