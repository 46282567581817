<template>
  <nav aria-label="Table Filters">
    <ul class="usa-sidenav">
      <li v-for="(filter, index) in filters" :key="index" class="usa-sidenav__item">
        <a
          @click.prevent="openDialog(index)"
          href="#"
          class="usa-current filter"
          :aria-label="filter.value + ' filter'"
        >
          {{ filter.displayName }}
        </a>

        <basic-dialog :title="filter.displayName" :id="`dialog-${index}`" hide-footer>
          <p class="usa-prose">Filtering documents tagged with:</p>
          <div class="filter-container margin-bottom-0">
            <div v-for="(selectedFilter, index) in selectedFilterDisplayNames" :key="index" class="margin-bottom-1">
              <span class="applied-filter"
                >{{ selectedFilter }}<i class="fa fa-times delete-filter-btn" @click="removeSelection(index)"
              /></span>
            </div>
          </div>

          <hr class="line-break" />
          <ul class="filter-tree" v-for="(child, index) in filter.children" :key="index">
            <document-management-search-filter-node
              :node="child"
              @filtersChanged="filtersChanged"
              :parentSelectedFilters="parentSelectedFilters"
              :isTopLevel="true"
              ref="searchFilter"
            />
            <hr class="line-break" />
          </ul>
        </basic-dialog>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DocumentManagementSearchFilterNode from "@/components/navigation/DocumentManagement/DocumentManagementSearchFilterNode.vue";
import BasicDialog from "@/components/common/BasicDialog.vue";
import { Category } from "@/dataModel";

@Component({
  components: {
    DocumentManagementSearchFilterNode,
    BasicDialog,
  },
})
export default class SearchFilter extends Vue {
  @Prop({ required: true }) taxonomy!: Category[];
  @Prop({ default: () => [] }) parentSelectedFilters!: string[];
  @Prop() selectedFilterDisplayNames!: string[];

  selectedFilters: string[] = [];
  filters: Category[] = [];

  @Watch("taxonomy")
  taxonomyWatcher(): void {
    this.filters = this.taxonomy;
  }

  @Watch("selectedFilters")
  filterWatcher(): void {
    this.$emit("filtersChanged", this.selectedFilters);
  }

  @Watch("parentSelectedFilters")
  parentSelectedFiltersWatcher(): void {
    this.selectedFilters = this.parentSelectedFilters;
  }

  removeSpaces(str: string) {
    return str.replace(" ", "");
  }

  clearSelections(): void {
    this.selectedFilters = [];
  }

  removeSelection(index: number): void {
    this.selectedFilters.splice(index, 1);
  }

  filtersChanged(selectedFilters: string[]): void {
    this.selectedFilters = selectedFilters;
  }

  openDialog(index: number): void {
    this.$bvModal.show("dialog-" + index);
  }

  setParent(filter: Category): void {
    if (filter.children != undefined && filter.children.length != 0) {
      for (let i = 0; i < filter.children.length; i++) {
        filter.children[i].parent = filter;

        const path: string[] = [];
        let currentNode = filter.children[i];
        while (currentNode.parent != undefined) {
          path.unshift(currentNode.displayName);
          currentNode = currentNode.parent;
        }
        this.setParent(filter.children[i]);
      }
    }
  }

  created(): void {
    this.filters = this.taxonomy;
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

.filter-tree {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.usa-prose {
  margin-bottom: 0.5rem;
}

.filter-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.applied-filter {
  border: 1px solid black;
  padding: 1px 8px;
  margin-right: units(1);
  border-radius: 12px;
  background-color: #e6e6e6;
}

.delete-filter-btn {
  color: color("base-dark");
  margin-left: 10px;
}

.line-break {
  background: #c9c9c9;
}
</style>
