import { UserManager, WebStorageStateStore, UserManagerSettings } from "oidc-client-ts";
import { LoginResponse, HkmaUser, AppConfig } from "@/dataModel";
import axios from "axios";
import IAuthService from "./interfaces/IAuthService";
import store from "@/store";
import { AuthStoreActions, AuthStoreGetters } from "@/constants/store/auth/authStoreConstants";
import StoreNames from "@/constants/store/StoreNames";
import { AuthState, User as UserState } from "@/typings/store/states/AuthStoreState";
import { inject, injectable } from "inversify";
import serviceTypes from "@/dependencyInjection/types";

@injectable()
export default class AuthService implements IAuthService {
  private userManager: UserManager;

  private apiUrl: string;
  private loginUrl = "/auth/login";

  constructor(@inject(serviceTypes.AppConfig) appConfig: AppConfig) {
    const AUTHORITY_DOMAIN: string = appConfig.keycloakBaseUrl;

    const settings: UserManagerSettings = {
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      authority: AUTHORITY_DOMAIN,
      client_id: "HKMA-Web",
      redirect_uri: appConfig.baseUrl + "/callback",
      silent_redirect_uri: appConfig.baseUrl + "/silent-refresh.html",
      response_type: "code",
      scope: "openid profile",
      post_logout_redirect_uri: appConfig.baseUrl + "/",
      filterProtocolClaims: true,
      automaticSilentRenew: false,
      metadata: {
        issuer: AUTHORITY_DOMAIN + "/",
        authorization_endpoint: AUTHORITY_DOMAIN + "/protocol/openid-connect/auth",
        userinfo_endpoint: AUTHORITY_DOMAIN + "/protocol/openid-connect/userinfo",
        end_session_endpoint: AUTHORITY_DOMAIN + "/protocol/openid-connect/logout",
        jwks_uri: AUTHORITY_DOMAIN + "/protocol/openid-connect/certs",
        token_endpoint: AUTHORITY_DOMAIN + "/protocol/openid-connect/token",
      },
    };
    this.userManager = new UserManager(settings);
    this.apiUrl = appConfig.baseUrl + appConfig.apiEndpoint;
    this.setupEventHandlers();
  }

  public getUser(): UserState {
    return store.getters[`${StoreNames.Auth}/${AuthStoreGetters.GET_USER}`];
  }

  public getAuthState(): AuthState | null {
    return store.getters[`${StoreNames.Auth}/${AuthStoreGetters.GET_AUTH}`];
  }

  public async login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  public async signInSilent(): Promise<void> {
    await this.userManager.signinSilent();
    await this.updateUserState();
  }

  public async logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }

  public async getAccessToken(): Promise<string> {
    try {
      const user = await this.userManager.getUser();
      if (user != null) {
        return user.access_token;
      } else {
        return Promise.reject("User not found.");
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async hkmaLogin(): Promise<LoginResponse | null> {
    try {
      const accessToken = await this.getAccessToken();
      if (accessToken !== "") {
        axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
      } else {
        return Promise.reject("Access token was empty.");
      }
      const response = await axios.get(this.apiUrl + this.loginUrl, { headers: {} });
      if (response.status === 200) {
        return Promise.resolve(response.data);
      } else {
        return Promise.reject(
          "Response returned " + response.status + " : " + response.statusText + " logging in to HKMA",
        );
      }
    } catch (error) {
      console.error(error);
      return Promise.reject("An error occurred while logging in.");
    }
  }

  public async completeAuthentication(): Promise<UserState> {
    try {
      const user = await this.userManager.signinRedirectCallback();
      if (user !== null) {
        return this.updateUserState();
      } else {
        return Promise.reject("Login failed.");
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private async updateUserState(): Promise<UserState> {
    const loginResponse = await this.hkmaLogin();

    if (loginResponse === null || loginResponse.user === undefined || loginResponse.user === null) {
      return Promise.reject("Login failed.");
    }

    const hkmaUser: HkmaUser = loginResponse!.user!;

    const userState: UserState = {
      firstName: hkmaUser.firstName,
      lastName: hkmaUser.lastName,
      displayName: hkmaUser.displayName,
      userId: hkmaUser.userId,
      userStatus: hkmaUser.userStatus,
      email: hkmaUser.email,
      actor: hkmaUser.actor,
    };

    store.dispatch(`${StoreNames.Auth}/${AuthStoreActions.SET_USER}`, userState, { root: true });

    if (userState.userStatus !== "LoginCreated" && loginResponse?.accessList !== undefined) {
      store.dispatch(`${StoreNames.Auth}/${AuthStoreActions.INITIALIZE_PAGE_ACCESS}`, loginResponse.accessList, {
        root: true,
      });
    }
    return userState;
  }

  private setupEventHandlers(): void {
    this.userManager.events.addAccessTokenExpiring(() => {
      // if the access token is expiring, exchange the refresh token
      // for a new one
      this.userManager.signinSilent();
    });

    this.userManager.events.addUserUnloaded(() => {
      // when a user logs out locally, remove their record from the store
      store.dispatch(`${StoreNames.Auth}/${AuthStoreActions.LOGOUT}`, { root: true });
    });

    this.userManager.events.addUserSignedOut(() => {
      // if the user is signed out remotely, signed them out locally
      this.logout();
    });

    this.userManager.events.addAccessTokenExpired(() => {
      // if the access token expires, log out the user
      this.logout();
    });
  }
}
