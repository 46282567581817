<template>
  <basic-dialog id="success-dialog" primaryButton="Close" :primaryClick="close" :isDisabled="false">
    <div>
      <h1 class="status-message">{{ message }}<i class="fa fa-check-circle fa-lg ml-3" aria-hidden="true" /></h1>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";

@Component({
  components: {
    BasicDialog,
  },
})
export default class SuccessDialog extends Vue {
  @Prop({ required: true }) message!: string;

  close(): void {
    this.$bvModal.hide("success-dialog");
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

.status-message {
  color: color("primary-dark");
  font-family: family("heading");
  font-size: size("heading", "lg");
}
</style>
