import HttpHelper from "@/components/resources/httpHelper";
import { User } from "@/dataModel";
import { inject, injectable } from "inversify";
import serviceTypes from "@/dependencyInjection/types";
import { SearchResponse } from "@/dataModel/responses";

const userUrl = "admin/users/";

@injectable()
export default class UserHttpHelper {
  httpHelper: HttpHelper;

  constructor(@inject(serviceTypes.HttpHelper) httpHelper: HttpHelper) {
    this.httpHelper = httpHelper;
  }

  async getUser(userId: string): Promise<User> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(userUrl + userId)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error("Response returned " + response.status + " : " + response.statusText + " getting user"));
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting user by id " + userId));
        });
    });
  }

  async getReviewers(): Promise<User[]> {
    const canApproveDocumentPermission = "c85075f3-1327-457f-82e0-864283f0fb71";
    const data = [canApproveDocumentPermission];
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(userUrl + "permissions", data)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error("Response returned " + response.status + " : " + response.statusText + " getting reviewers"),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting reviewers"));
        });
    });
  }

  async createUser(user: User): Promise<User> {
    const data = {
      RemoteIdentifier: user.remoteIdentifier,
      Email: user.email,
      DisplayName: user.displayName,
      FirstName: user.firstName,
      LastName: user.lastName,
      Organization: user.organization,
      Roles: user.roleIds,
    };

    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(userUrl, data)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error("Response returned " + response.status + " : " + response.statusText + " creating user"));
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while creating user"));
        });
    });
  }

  async updateUser(user: User): Promise<User> {
    const data = {
      UserId: user.userId,
      Email: user.email,
      DisplayName: user.displayName,
      FirstName: user.firstName,
      LastName: user.lastName,
      Organization: user.organization,
      Roles: user.roleIds,
      UserStatus: user.status,
      ApprovalJustification: user.approvalJustification,
    };

    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPut(userUrl, data)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error("Response returned " + response.status + " : " + response.statusText + " updating user"));
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while updating user"));
        });
    });
  }

  async searchUsers(
    page: number,
    resultsPerPage: number,
    searchTerm: string,
    sortOrder: string,
    sortColumn: string,
    filters: string[],
    roleFilter?: string,
  ): Promise<SearchResponse<User>> {
    roleFilter = roleFilter || "";
    const data = {
      Page: page,
      ResultsPerPage: resultsPerPage,
      SearchTerm: searchTerm,
      SortOrder: sortOrder,
      SortColumn: sortColumn,
      UserStatusFilters: filters,
      RoleFilter: roleFilter,
    };

    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(userUrl + "search/", data)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error("Response returned " + response.status + " : " + response.statusText + " searching users"),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while searching users"));
        });
    });
  }

  async bulkUpdateStatus(userIds: string[], status: string): Promise<User> {
    const data = {
      UserIds: userIds,
      UserStatus: status,
    };

    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(userUrl + "bulk-update-status/", data)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " updating users statuses",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while updating users statuses"));
        });
    });
  }

  async bulkUpdateRoles(userIds: string[], roleIds: string[]): Promise<User[]> {
    const data = {
      UserIds: userIds,
      RoleIds: roleIds,
    };

    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPost(userUrl + "bulk-update-roles/", data)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " updating users statuses",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while updating users statuses"));
        });
    });
  }

  async getUserByActorId(actorId: string): Promise<User> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(`${userUrl}metadata?actorId=${actorId}`)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error(`Response returned ${response.status}: ${response.statusText} getting user`));
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error(`An error occurred while getting user display name with actor id ${actorId}`));
        });
    });
  }
}
