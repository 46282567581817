<template>
  <div>
    <basic-dialog
      :id="dialogId"
      :title="title"
      :primaryButton="primaryButton"
      :secondaryButton="secondaryButton"
      :primaryClick="primaryClick"
      :secondaryClick="secondaryClick"
      :isDisabled="disableDialog"
    >
      <div v-if="!showError">
        <p class="usa-prose">{{ confirmText }}</p>
      </div>

      <div v-else class="usa-alert usa-alert--info usa-alert--slim margin-top-3">
        <div class="usa-alert__body">
          <p class="usa-alert__text">
            {{ errorText }}
          </p>
        </div>
      </div>
    </basic-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import { StagedFile } from "@/dataModel";
import FileStatus from "@/constants/FileStatus";
import { ConfirmDialogTitles } from "./ConfirmDialogTitles";

@Component({
  components: {
    BasicDialog,
  },
})
export default class ConfirmDialog extends Vue {
  @Prop({ default: "confirm-dialog" }) dialogId!: string;
  @Prop({ required: true }) title!: string;
  @Prop({ default: () => [] }) files!: StagedFile[];

  secondaryClick = this.close;
  fileStatus = FileStatus;

  get primaryButton(): string {
    switch (this.title) {
      case ConfirmDialogTitles.Empty:
      case ConfirmDialogTitles.Invalid:
        return "Close";
      default:
        return "Confirm";
    }
  }

  get secondaryButton(): string {
    switch (this.title) {
      case ConfirmDialogTitles.Empty:
      case ConfirmDialogTitles.Invalid:
        return "";
      default:
        return "Cancel";
    }
  }

  get primaryClick(): () => void {
    switch (this.title) {
      case ConfirmDialogTitles.Delete:
        return () => this.emitEvent("delete");
      case ConfirmDialogTitles.Approve:
        return () => this.emitEvent("approve");
      case ConfirmDialogTitles.Update:
        return () => this.emitEvent("update");
      default:
        return this.close;
    }
  }

  get disableDialog(): boolean {
    switch (this.title) {
      case ConfirmDialogTitles.Approve:
        return (
          this.files.length === 0 ||
          this.filesContainStatus(FileStatus.Approved) ||
          this.filesContainStatus(FileStatus.Converted)
        );
      default: {
        return false;
      }
    }
  }

  get showError(): boolean {
    return (
      this.title !== ConfirmDialogTitles.Delete &&
      (this.disableDialog || this.files.length === 0 || this.title === ConfirmDialogTitles.Invalid)
    );
  }

  get errorText(): string {
    switch (this.title) {
      case ConfirmDialogTitles.Invalid:
        return "Only approved documents can be converted.";
      case ConfirmDialogTitles.Approve: {
        if (this.filesContainStatus(FileStatus.Converted)) {
          return "Cannot approve documents that are already converted.";
        }
        if (this.files.length) {
          return "Cannot approve documents that are already approved.";
        }
      }
      default:
        return "At least 1 file must be selected from the table.";
    }
  }

  get confirmText(): string {
    switch (this.title) {
      case ConfirmDialogTitles.Delete:
        return "Are you sure you want to delete this document?";
      case ConfirmDialogTitles.Approve:
        return `Are you sure you want to approve the selected document${this.files.length > 1 ? "s" : ""}?`;
      case ConfirmDialogTitles.Update:
        return "Are you sure you want to update this document?";
      default:
        return "";
    }
  }

  filesContainStatus(status: FileStatus): boolean {
    return !this.files.every((file) => file.fileStatus !== status);
  }

  close() {
    this.$bvModal.hide(this.dialogId);
  }

  emitEvent(eventName: string): void {
    this.$emit(eventName);
    this.close();
  }
}
</script>

<style scoped lang="scss">
.usa-prose {
  font-weight: bold;
  margin-top: 1.5rem;
}
</style>
