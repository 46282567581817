<template>
  <div>
    <p>
      The file <span class="text-bold mt-0">{{ file.fileName }}</span> has been matched with the following document:
    </p>
    <table class="usa-table usa-table--stacked">
      <thead>
        <tr>
          <th scope="col">Title</th>
          <th scope="col">Classification</th>
          <th scope="col">Contributors</th>
          <th v-if="document.abstract" scope="col">Abstract</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th data-label="Title" scope="row">{{ document.title }}</th>
          <td data-label="Classification">{{ classificationDisplayName }}</td>
          <td data-label="Contributors">{{ formattedContributors }}</td>
          <td v-if="document.abstract" data-label="Abstract">{{ truncate(document.abstract, 500) }}</td>
        </tr>
      </tbody>
    </table>
    <p>Would you like to automatically assign it to this document or manually select another document?</p>
    <ul class="usa-button-group">
      <li class="usa-button-group__item">
        <button class="usa-button" @click="$emit('autoMatch')">Auto-match</button>
      </li>
      <li class="usa-button-group__item">
        <button class="usa-button usa-button--outline" @click="$emit('manuallySelect')">Manually select</button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import DataTable from "@/components/common/DataTable.vue";
import DataTableFilter from "@/components/common/DataTableFilter.vue";
import ResultsPerPageButton from "@/components/common/ResultsPerPageButton.vue";
import TablePagination from "@/components/common/TablePagination.vue";
import ConfirmDialog from "@/components/navigation/DocumentStaging/DocumentStagingDialogs/ConfirmDialog.vue";
import { SiteDocument, StagedFile } from "@/dataModel";
import { truncate } from "@/services/utils";

@Component({
  components: {
    Breadcrumb,
    ConfirmDialog,
    DataTable,
    DataTableFilter,
    TablePagination,
    ResultsPerPageButton,
  },
})
export default class DocumentMatchFoundPage extends Vue {
  truncate = truncate;

  @Prop({ required: true }) file!: StagedFile;
  @Prop({ required: true }) document!: SiteDocument;

  get classificationDisplayName() {
    return this.document.classificationMarking;
  }

  get formattedContributors() {
    return this.document.contributors.join("; ");
  }
}
</script>

<style scoped lang="scss">
.pagination {
  float: right;
}

.pagination-container {
  margin-top: 0.5rem;
}

.search-style {
  width: 50%;
  margin-top: 4px;
}

#filter-header {
  margin-top: 4px;
}
</style>
