export enum DataCollectionStoreActions {
  SET_SELECTED_DATA_COLLECTION = "SET_SELECTED_DATA_COLLECTION",
  SET_SELECTED_DATA_VALUE = "SET_SELECTED_DATA_VALUE",
  SET_SELECTED_HAZARDS = "SET_SELECTED_HAZARDS",
  SET_SELECTED_HAZARD_PROPERTY = "SET_SELECTED_HAZARD_PROPERTY",
  SET_SELECTED_HAZARD_TYPE = "SET_SELECTED_HAZARD_TYPE",
}

export enum DataCollectionStoreGetters {
  GET_SELECTED_DATA_COLLECTION = "GET_SELECTED_DATA_COLLECTION",
  GET_SELECTED_DATA_VALUE = "GET_SELECTED_DATA_VALUE",
  GET_SELECTED_HAZARDS = "GET_SELECTED_HAZARDS",
  GET_SELECTED_HAZARD_PROPERTY = "GET_SELECTED_HAZARD_PROPERTY",
  GET_SELECTED_HAZARD_TYPE = "GET_SELECTED_HAZARD_TYPE",
}

export enum DataCollectionStoreMutations {
  SET_SELECTED_DATA_COLLECTION = "SET_SELECTED_DATA_COLLECTION",
  SET_SELECTED_DATA_VALUE = "SET_SELECTED_DATA_VALUE",
  SET_SELECTED_HAZARDS = "SET_SELECTED_HAZARDS",
  SET_SELECTED_HAZARD_PROPERTY = "SET_SELECTED_HAZARD_PROPERTY",
  SET_SELECTED_HAZARD_TYPE = "SET_SELECTED_HAZARD_TYPE",
}
