<template>
  <main id="main-content">
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <download-original-file
        :fileName="stagedFileMetadata?.fileName"
        :fileType="stagedFileMetadata?.fileType"
        :fileContents="originalFileContents"
        :availableForDownload="true"
        @downloadOriginalFile="getOriginalFileContents"
      />

      <pdf-viewer v-if="file" :file="file" :allowDownload="true" class="margin-top-2" />
      <template v-else>
        <div v-if="!hasError" class="margin-top-3 d-flex flex-column align-items-center">
          <spinner class="spinner margin-bottom-105" />
          Loading file...
        </div>

        <div v-else class="usa-alert usa-alert--error margin-x-auto margin-top-6" role="alert">
          <div class="usa-alert__body">
            <p class="usa-alert__text">
              An error occurred while loading the file.
              <a @click.prevent="retry" href="#/">Click here to retry</a>.
            </p>
          </div>
        </div>
      </template>
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import { BreadcrumbPathItem } from "@/dataModel/interfaces";
import Spinner from "@/components/common/Spinner.vue";
import DocumentStagingHttpHelper from "@/components/resources/documentStagingHttpHelper";
import serviceTypes from "@/dependencyInjection/types";
import container from "@/dependencyInjection/config";
import PdfViewer from "@/components/common/PdfViewer.vue";
import { StagedFile } from "@/dataModel";
import DownloadOriginalFile from "@/components/common/DownloadOriginalFile.vue";

@Component({
  components: {
    Breadcrumb,
    PdfViewer,
    Spinner,
    DownloadOriginalFile,
  },
})
export default class ViewStagedFilePage extends Vue {
  @Prop() id!: string;

  hasError = false;
  stagedFileMetadata: StagedFile | null = null;
  file: string | null = null;
  originalFileContents: string | null = null;

  private docStagingHttpHelper = container.get<DocumentStagingHttpHelper>(serviceTypes.DocumentStagingHttpHelper);

  path: BreadcrumbPathItem[] = [
    {
      text: "Administration",
    },
    {
      text: "Document Staging",
      url: "/document-staging",
    },
    {
      text: "File Viewer",
    },
  ];

  async getFile(): Promise<void> {
    let metadata: StagedFile | null = null;
    try {
      metadata = await this.docStagingHttpHelper.getStagedFile(this.id);
      this.stagedFileMetadata = metadata;
      this.file = await this.docStagingHttpHelper.downloadStagedFile(this.id, true);
    } catch {
      if (!metadata) {
        // file not found
        this.goToDocumentErrorPage();
      }
      this.hasError = true;
    }
  }

  async getOriginalFileContents(): Promise<void> {
    let response = await this.docStagingHttpHelper.downloadStagedFile(this.id, false);
    if (response) {
      this.originalFileContents = response;
    }
  }

  goToDocumentErrorPage(): void {
    this.$router.push({
      name: "DocumentError",
    });
  }

  retry(): void {
    this.hasError = false;
    this.getFile();
  }

  created(): void {
    this.getFile();
  }
}
</script>

<style scoped lang="scss">
.usa-alert {
  width: 615px;

  & > .usa-alert__body {
    padding-right: 52px;
  }
}
</style>
