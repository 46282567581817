import { PermissionStoreMutations } from "@/constants/store/permission/permissionStoreConstants";
import { Classification, DisseminationControl, Sci, KnowledgeResourceAudience } from "@/dataModel";
import { PermissionState } from "@/typings/store/states/PermissionStoreState";

export default {
  [PermissionStoreMutations.SET_CLASSIFICATIONS](state: PermissionState, classifications: Classification[]) {
    state.classifications = classifications;
  },
  [PermissionStoreMutations.SET_DISSEMINATION_CONTROLS](
    state: PermissionState,
    disseminationControls: DisseminationControl[],
  ) {
    state.disseminationControls = disseminationControls;
  },
  [PermissionStoreMutations.SET_SCIS](state: PermissionState, scis: Sci[]) {
    state.scis = scis;
  },
  [PermissionStoreMutations.SET_AUDIENCES](state: PermissionState, audiences: KnowledgeResourceAudience[]) {
    state.audiences = audiences;
  },
};
