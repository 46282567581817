<template>
  <main>
    <section class="grid-container usa-section padding-top-05">
      <!-- Breadcrumb -->
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="breadcrumbPath" />
        </div>
      </div>

      <!-- Page header -->
      <div class="margin-top-105em">
        <h1 class="font-heading-xl text-primary-dark margin-top-0 margin-bottom-5">Create New Data Collection</h1>
        <h4 class="font-heading-md text-bold mb-2">Select a document to link a new data collection</h4>
        <p>
          If the document is not found within the system, go to
          <a class="usa-link" href="/document-staging">document staging</a>
          to upload a new document.
        </p>
      </div>

      <div class="grid-row">
        <!-- Document search -->
        <form
          class="usa-search usa-search--small search-style"
          role="search"
          @submit.prevent="SearchDocumentsAsync"
          v-bind:class="{ 'search-focused': isSearchBoxFocused }"
        >
          <label class="usa-sr-only" for="search-field-en-small">Search</label>
          <input
            class="usa-input"
            id="search-field-en-small"
            type="search"
            name="search"
            v-model="searchTerm"
            placeholder="Search documents..."
          />
          <button class="usa-button" type="submit">
            <span class="usa-sr-only">Search</span>
          </button>
        </form>

        <!-- Pagenation controls -->
        <results-per-page-button
          class="grid-col"
          :prevPerPage="perPage"
          @resultsPerPageChanged="perPageAmountChanged"
        ></results-per-page-button>

        <div class="grid-col-auto pagination-container">
          <table-pagination
            class="pagination"
            @prev="prevPage"
            @next="nextPage"
            :page="page"
            :hasNextPage="hasNextPage"
          />
        </div>
      </div>

      <data-table
        :fields="dataTableHeaders"
        :items="documents"
        :perPage="perPage"
        :page="page"
        @sortingChanged="documentSortChanged"
        ref="documentTable"
      >
        <!-- Custom style for the title column -->
        <template v-slot:cell(title)="data">
          <a class="usa-link" href="#/" :aria-label="data.value" @click.prevent="goToDataCollectionDetails(data.item)">
            {{ data.value }}
          </a>
        </template>
      </data-table>
    </section>
    <existing-data-collection-dialog
      @view-data-collection="viewExistingDataCollectionDetails(selectedDataCollectionId)"
    />
  </main>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { DocumentSearchMixin } from "@/components/mixins/DocumentSearchMixin";
import { UserMixin } from "@/components/mixins/UserMixin";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import TablePagination from "@/components/common/TablePagination.vue";
import ResultsPerPageButton from "@/components/common/ResultsPerPageButton.vue";
import DataTable from "@/components/common/DataTable.vue";
import { DataTableHeader, BreadcrumbPathItem } from "@/dataModel/interfaces";
import { SiteDocument } from "@/dataModel";
import ExistingDataCollectionDialog from "@/components/navigation/DataCollectionManagement/DataCollectionDetails/ExistingDataCollectionDialog.vue";
import CreateSourceRequest from "@/dataModel/hazardData/createSourceRequest";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import HazardDataAdminHttpHelper from "@/components/resources/hazardDataAdminHttpHelper";
import CreateSourceResponse from "@/dataModel/hazardData/createSourceResponse";

@Component({
  components: {
    Breadcrumb,
    DataTable,
    TablePagination,
    ResultsPerPageButton,
    ExistingDataCollectionDialog,
  },
})
export default class CreateNewDataCollectionPage extends Mixins(DocumentSearchMixin, UserMixin) {
  selectedDataCollectionId: string = "";

  private hazardDataAdminHttpHelper = container.get<HazardDataAdminHttpHelper>(serviceTypes.HazardDataAdminHttpHelper);

  breadcrumbPath: BreadcrumbPathItem[] = [
    { text: "Administration" },
    { text: "Data Collection Management", url: "/data-collection-management" },
    { text: "Create New Data Collection" },
  ];

  dataTableHeaders: DataTableHeader[] = [
    {
      label: "Title",
      key: "title",
      sortable: true,
    },
    {
      label: "Authors",
      key: "contributors",
      sortable: false,
      formatter: this.formatContributorList,
    },
    {
      label: "Published",
      key: "documentDateTime",
      sortable: false,
      tdClass: "table-col-xs",
      formatter: (val) => this.$options.filters?.formatDate(val),
    },
    {
      label: "Modified",
      key: "lastUpdatedDateTime",
      sortable: true,
      tdClass: "table-col-xs",
      formatter: (val) => this.$options.filters?.formatDate(val),
    },
  ];

  /**
   * Navigates user to the data collection summary page after selecting the document they wish to link to their new collection
   */
  async goToDataCollectionDetails(document: SiteDocument): Promise<void> {
    const response = await this.createSource(document);
    this.selectedDataCollectionId = response.source.id;

    if (response.isExistingSource) {
      this.$bvModal.show("existing-data-collection-dialog");
    } else {
      this.viewNewDataCollectionDetails(this.selectedDataCollectionId);
    }
  }

  async createSource(document: SiteDocument): Promise<CreateSourceResponse> {
    const createSourceRequest = new CreateSourceRequest(
      document.knowledgeResourceId,
      document.title,
      document.contributors.join(", "),
      document.documentDateTime,
    );
    const response = this.hazardDataAdminHttpHelper.createSource(createSourceRequest);
    return response;
  }

  async SearchDocumentsAsync(): Promise<void> {
    this.page = 1;
    this.publicationStatuses.push(true);
    await this.throttleSearchMethod();
    this.publicationStatuses.pop();
  }

  viewExistingDataCollectionDetails(dataCollectionId: string): void {
    this.$router.push({
      name: "DataCollectionDetails",
      query: {
        id: dataCollectionId,
      },
    });
  }

  viewNewDataCollectionDetails(dataCollectionId: string): void {
    this.$router.push({
      name: "DataCollectionDetails",
      query: {
        id: dataCollectionId,
        createSuccess: "true",
      },
    });
  }

  async created(): Promise<void> {
    await this.getUser().then(
      async () => this.throttleSearchMethod(),
      (reason) => console.error(reason), // Error!
    );
  }
}
</script>
