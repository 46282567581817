const PROVIDER_TYPES = {
  AuthService: Symbol("IAuthService"),
  AppConfig: Symbol("AppConfig"),
  HttpHelper: Symbol("HttpHelper"),
  PermissionHttpHelper: Symbol("PermissionHttpHelper"),
  AccessRequestHttpHelper: Symbol("AccessRequestHttpHelper"),
  RoleHttpHelper: Symbol("RoleHttpHelper"),
  PermissionService: Symbol("IPermissionService"),
  RouterWrapper: Symbol("RouterWrapper"),
  UserHttpHelper: Symbol("UserHttpHelper"),
  DocumentStagingHttpHelper: Symbol("DocumentStagingHttpHelper"),
  DocumentStagingBatchHttpHelper: Symbol("DocumentStagingBatchHttpHelper"),
  RequestHttpHelper: Symbol("RequestHttpHelper"),
  SiteDocumentAdminHttpHelper: Symbol("SiteDocumentAdminHttpHelper"),
  SiteDocumentHttpHelper: Symbol("SiteDocumentHttpHelper"),
  CategoryHttpHelper: Symbol("CategoryHttpHelper"),
  CategoryService: Symbol("CategoryService"),
  NotificationHttpHelper: Symbol("NotificationHttpHelper"),
  ApplicationHttpHelper: Symbol("ApplicationHttpHelper"),
  FeaturedResourceHttpHelper: Symbol("FeaturedResourceHttpHelper"),
  HazardDataAdminHttpHelper: Symbol("HazardDataAdminHttpHelper"),
  HazardDataHttpHelper: Symbol("HazardDataHttpHelper"),
  HazardDataPropertyHttpHelper: Symbol("HazardDataPropertyHttpHelper"),
  HazardDataEvaluationRatingHttpHelper: Symbol("HazardDataEvaluationRatingHttpHelper"),
  HazardDataUncertaintyMeasureHttpHelper: Symbol("HazardDataUncertaintyMeasureHttpHelper"),
  HazardDataUnitsHttpHelper: Symbol("HazardDataUnitsHttpHelper"),
  HazardDataSearchHttpHelper: Symbol("HazardDataSearchHttpHelper"),
  UsageAnalyticsHttpHelper: Symbol("UsageAnalyticsHttpHelper"),
  MetadataAutoExtractionHttpHelper: Symbol("MetadataAutoExtractionHttpHelper"),
  SettingHttpHelper: Symbol("SettingHttpHelper"),
};

export default PROVIDER_TYPES;
