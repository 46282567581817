<template>
  <basic-dialog
    v-bind="$attrs"
    :title="title"
    primaryButton="Confirm"
    secondaryButton="Cancel"
    :primaryClick="confirm"
    :secondaryClick="cancel"
  >
    <p class="usa-prose mt-3" v-html="message" />
  </basic-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { FeaturedDocument, FeaturedHazardData } from "@/dataModel";
import BasicDialog from "@/components/common/BasicDialog.vue";
import { FeaturedResource } from "@/dataModel/interfaces";
import FeaturedHazardType from "@/constants/FeaturedHazardType";

@Component({
  components: {
    BasicDialog,
  },
})
export default class RemoveFeaturedDocumentDialog extends Vue {
  @Prop() selectedFeaturedResource!: FeaturedResource;
  @Prop() tabView!: FeaturedHazardType;

  get title(): string {
    if (this.tabView === FeaturedHazardType.Document) {
      return "Remove Document";
    } else if (this.tabView === FeaturedHazardType.HazardData) {
      return "Remove Featured Data";
    } else {
      return "";
    }
  }

  get message(): string {
    let msg = "Are you sure you want to remove ";
    if (
      this.tabView === FeaturedHazardType.Document ||
      this.selectedFeaturedResource.featuredHazardType === FeaturedHazardType.Document
    ) {
      msg += `<strong>${this.selectedFeaturedResource.title}</strong>?`;
    } else if (this.selectedFeaturedResource.featuredHazardType === FeaturedHazardType.HazardData) {
      const featuredData = this.selectedFeaturedResource as FeaturedHazardData;
      const featureTypeLabel =
        featuredData.featuredHazardType === FeaturedHazardType.HazardData ? "data view" : "document";
      msg += `${featureTypeLabel} <strong>${this.selectedFeaturedResource.title}</strong>?`;
    }

    return msg;
  }

  confirm(): void {
    if (this.tabView === FeaturedHazardType.Document) {
      this.removeFeaturedDocument(this.selectedFeaturedResource as FeaturedDocument);
    } else if (this.tabView === FeaturedHazardType.HazardData) {
      this.removeFeaturedData(this.selectedFeaturedResource as FeaturedHazardData);
    }
    this.$bvModal.hide(this.$attrs.id);
  }

  @Emit()
  removeFeaturedDocument(doc: FeaturedDocument): FeaturedDocument {
    return doc;
  }

  @Emit()
  removeFeaturedData(data: FeaturedHazardData): FeaturedHazardData {
    return data;
  }

  cancel(): void {
    this.$bvModal.hide(this.$attrs.id);
  }
}
</script>

<style scoped lang="scss"></style>
