import Vue from "vue";
import { injectable, decorate, inject } from "inversify";
import serviceTypes from "@/dependencyInjection/types";
import Router, { Route, RouterOptions } from "vue-router";
import Home from "@/components/navigation/Home.vue";
import UserRequestsBase from "@/components/navigation/UserRequests/UserRequestsBase.vue";
import UserRequestsPage from "@/components/navigation/UserRequests/UserRequestsPage.vue";
import UserRequestsCreatePage from "@/components/navigation/UserRequests/UserRequestsCreatePage.vue";
import UserRequestsLogPage from "@/components/navigation/UserRequests/UserRequestsLogPage.vue";
import DocumentStagingBase from "@/components/navigation/DocumentStaging/DocumentStagingBase.vue";
import DocumentStagingPage from "@/components/navigation/DocumentStaging/DocumentStagingPage.vue";
import ConvertToSiteDocumentsBase from "@/components/navigation/DocumentStaging/ConvertToSiteDocumentsBase.vue";
import ConvertToSiteDocumentsPage from "@/components/navigation/DocumentStaging/ConvertToSiteDocumentsPage.vue";
import UpdateExistingDocumentsPage from "@/components/navigation/DocumentStaging/UpdateExistingDocumentsPage.vue";
import CreateNewDocumentPage from "@/components/navigation/DocumentStaging/CreateNewDocumentPage.vue";
import ViewStagedFilePage from "@/components/navigation/DocumentStaging/ViewStagedFilePage.vue";
import RoleManagementPage from "@/components/navigation/RoleManagement/RoleManagementPage.vue";
import UserManagementPage from "@/components/navigation/UserManagement/UserManagementPage.vue";
import RequestManagementBase from "@/components/navigation/RequestManagement/RequestManagementBase.vue";
import RequestManagementPage from "@/components/navigation/RequestManagement/RequestManagementPage.vue";
import IndividualRequest from "@/components/navigation/RequestManagement/IndividualRequest.vue";
import UserSearchPage from "@/components/navigation/UserSearch/UserSearchPage.vue";
import DocumentManagementBase from "@/components/navigation/DocumentManagement/DocumentManagementBase.vue";
import DocumentManagementPage from "@/components/navigation/DocumentManagement/DocumentManagementPage.vue";
import DocumentManagementEdit from "@/components/navigation/DocumentManagement/EditDocument.vue";
import DocumentManagementCreate from "@/components/navigation/DocumentManagement/CreateCopyrightDocument.vue";
import CreateNewDataCollectionPage from "@/components/navigation/DataCollectionManagement/CreateNewDataCollectionPage.vue";
import DocumentSummary from "@/components/navigation/DocumentView/DocumentSummary.vue";
import DocumentFileView from "@/components/navigation/DocumentView/DocumentFileView.vue";
import DocumentViewBase from "@/components/navigation/DocumentView/DocumentViewBase.vue";
import SubscriptionManagementBase from "@/components/navigation/SubscriptionManagement/SubscriptionManagementBase.vue";
import SubscriptionManagementPage from "@/components/navigation/SubscriptionManagement/SubscriptionManagementPage.vue";
import CategoryManagementBase from "@/components/navigation/CategoryManagement/CategoryManagementBase.vue";
import CategoryManagementPage from "@/components/navigation/CategoryManagement/CategoryManagementPage.vue";
import FeaturedResourceManagementBase from "@/components/navigation/FeaturedResourceManagement/FeaturedResourceManagementBase.vue";
import FeaturedResourceManagementPage from "@/components/navigation/FeaturedResourceManagement/FeaturedResourceManagementPage.vue";
import DataCollectionManagementBase from "@/components/navigation/DataCollectionManagement/DataCollectionManagementBase.vue";
import DataCollectionManagementPage from "@/components/navigation/DataCollectionManagement/DataCollectionManagementPage.vue";
import DataCollectionDetailsPage from "@/components/navigation/DataCollectionManagement/DataCollectionDetails/DataCollectionDetailsPage.vue";
import EditDataCollectionPage from "@/components/navigation/DataCollectionManagement/EditDataCollectionPage.vue";
import AddEditDataValue from "@/components/navigation/DataCollectionManagement/DataCollectionDetails/AddEditDataValue.vue";
import RequestFileView from "@/components/navigation/RequestManagement/RequestFileView.vue";
import CallbackPage from "@/components/navigation/LoginFlow/CallbackPage.vue";
import RequestAccess from "@/components/navigation/LoginFlow/RequestAccessPage.vue";
import AccessDeniedPage from "@/components/navigation/LoginFlow/AccessDeniedPage.vue";
import UserAnalytics from "@/components/navigation/UsageAnalytics/UsageAnalytics.vue";
import ErrorPage from "@/components/navigation/ErrorPage.vue";
import PermissionsErrorPage from "@/components/navigation/PermissionsErrorPage.vue";
import * as DataPages from "@/components/navigation/UserHazardData";
import { AuthAccess } from "./typings/store/states/AuthStoreState";
import StoreNames from "./constants/store/StoreNames";
import store from "./store";
import Application from "@/components/navigation/ApplicationsPage.vue";
import { StagedFileStoreActions, StagedFileStoreGetters } from "./constants/store/stagedFile/stagedFileStoreConstants";
import UserStatus from "./constants/UserStatus";
import IAuthService from "./services/interfaces/IAuthService";
import IPermissionService from "@/services/interfaces/IPermissionService";
import { PositionResult } from "vue-router/types/router";

// required for making RouterWrapper injectable
decorate(injectable(), Router);

@injectable()
export default class RouterWrapper extends Router {
  auth: IAuthService;
  private permissionService: IPermissionService;

  constructor(
    @inject(serviceTypes.AuthService) auth: IAuthService,
    @inject(serviceTypes.PermissionService)
    permissionService: IPermissionService,
  ) {
    const options: RouterOptions = {
      mode: "history",
      routes: [
        {
          path: "/",
          component: Home,
        },
        {
          path: "/home",
          name: "Home",
          component: Home,
        },
        {
          path: "/role-management",
          name: "RoleManagement",
          component: RoleManagementPage,
          meta: {
            requiresAuth: true,
            isAuthorized: (userAccess: AuthAccess) => {
              return userAccess.hasAdminRolesAccess;
            },
          },
        },
        {
          path: "/search",
          name: "UserSearch",
          component: UserSearchPage,
          meta: {
            requiresAuth: true,
            isAuthorized: (userAccess: AuthAccess) => {
              return userAccess.hasUserSearchAccess;
            },
          },
          props: (route) => ({
            initialSearchTerm: route.query.searchTerm,
          }),
        },
        {
          path: "/user-management",
          name: "UserManagement",
          component: UserManagementPage,
          meta: {
            requiresAuth: true,
            isAuthorized: (userAccess: AuthAccess) => {
              return userAccess.hasAdminUserManagementAccess;
            },
          },
        },
        {
          path: "/applications-list",
          name: "Applications",
          component: Application,
          meta: {
            requiresAuth: true,
            isAuthorized: (userAccess: AuthAccess) => {
              return userAccess.hasUserApplicationAccess;
            },
          },
        },
        {
          path: "/document-staging",
          component: DocumentStagingBase,
          meta: {
            requiresAuth: true,
          },
          beforeEnter: (_to, _from, next) => {
            // reset state before entering
            store.dispatch(`${StoreNames.StagedFile}/${StagedFileStoreActions.RESET_STAGED_FILE_STATE}`);
            next();
          },
          children: [
            {
              path: "",
              name: "DocumentStaging",
              component: DocumentStagingPage,
              meta: {
                isAuthorized: (userAccess: AuthAccess) => {
                  return userAccess.hasAdminDocumentAccess;
                },
              },
            },
            {
              path: "site-documents",
              component: ConvertToSiteDocumentsBase,
              beforeEnter: (_to, _from, next) => {
                const fileState =
                  store.getters[`${StoreNames.StagedFile}/${StagedFileStoreGetters.GET_SELECTED_FILES}`];
                if (!fileState.length) {
                  next({ name: "DocumentStaging" });
                } else {
                  store.dispatch(`${StoreNames.StagedFile}/${StagedFileStoreActions.SET_STAGED_FILES_TO_UPDATE}`, []);
                  next();
                }
              },
              children: [
                {
                  path: "",
                  name: "ConvertToSiteDocuments",
                  component: ConvertToSiteDocumentsPage,
                  meta: {
                    isAuthorized: (userAccess: AuthAccess) => {
                      return userAccess.hasAdminDocumentAccess;
                    },
                  },
                },
                {
                  path: "existing-documents",
                  name: "UpdateExistingDocuments",
                  component: UpdateExistingDocumentsPage,
                  meta: {
                    isAuthorized: (userAccess: AuthAccess) => {
                      return userAccess.hasAdminDocumentAccess;
                    },
                  },
                  beforeEnter: (_to, _from, next) => {
                    const fileState =
                      store.getters[`${StoreNames.StagedFile}/${StagedFileStoreGetters.GET_STAGED_FILES_TO_UPDATE}`];
                    if (!fileState.length) {
                      next({ name: "ConvertToSiteDocuments" });
                    } else {
                      next();
                    }
                  },
                },
                {
                  path: "new-document",
                  name: "CreateNewDocument",
                  component: CreateNewDocumentPage,
                  meta: {
                    isAuthorized: (userAccess: AuthAccess) => {
                      return userAccess.hasAdminDocumentAccess;
                    },
                  },
                  props: true,
                  beforeEnter: (_to, _from, next) => {
                    const fileState =
                      store.getters[`${StoreNames.StagedFile}/${StagedFileStoreGetters.GET_STAGED_FILES_TO_UPDATE}`];
                    if (!fileState.length) {
                      next({ name: "ConvertToSiteDocuments" });
                    } else {
                      next();
                    }
                  },
                },
              ],
            },
            {
              path: "file-view/:id?",
              name: "ViewStagedFile",
              component: ViewStagedFilePage,
              meta: {
                isAuthorized: (userAccess: AuthAccess) => userAccess.hasAdminDocumentAccess,
              },
              props: (route: Route) => ({ id: route.params.id }),
            },
            {
              path: "batch-summary/:id?",
              name: "BatchSummaryPage",
              component: () => import("@/components/navigation/DocumentStaging/DocumentBatch/BatchSummaryPage.vue"),
              meta: {
                isAuthorized: (userAccess: AuthAccess) => userAccess.hasAdminDocumentAccess,
              },
              props: (route: Route) => ({ id: route.params.id }),
            },
          ],
        },
        {
          path: "/user-requests",
          component: UserRequestsBase,
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: "",
              name: "UserRequests",
              component: UserRequestsPage,
              meta: {
                isAuthorized: (userAccess: AuthAccess) => {
                  return userAccess.hasUserRequestAccess;
                },
              },
            },
            {
              path: "log",
              name: "UserRequestsLog",
              component: UserRequestsLogPage,
              meta: {
                isAuthorized: (userAccess: AuthAccess) => {
                  return userAccess.hasUserRequestAccess;
                },
              },
            },
            {
              path: "new-request",
              name: "CreateUserRequest",
              component: UserRequestsCreatePage,
              meta: {
                isAuthorized: (userAccess: AuthAccess) => {
                  return userAccess.hasUserRequestAccess;
                },
              },
            },
          ],
        },
        {
          path: "/document-management",
          component: DocumentManagementBase,
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: "",
              name: "DocumentManagement",
              component: DocumentManagementPage,
              meta: {
                isAuthorized: (documentAccess: AuthAccess) => {
                  return documentAccess.hasAdminDocumentAccess;
                },
              },
              props: (route) => ({
                usePreviousSetup: route.query.usePreviousSetup === "true",
                editSuccess: route.query.editSuccess,
                createSuccess: route.query.createSuccess,
              }),
            },
            {
              path: "edit",
              name: "EditDocument",
              component: DocumentManagementEdit,
              meta: {
                isAuthorized: (documentAccess: AuthAccess) => {
                  return documentAccess.hasAdminDocumentAccess;
                },
              },
            },
            {
              path: "create-copyright-restricted-document",
              name: "CreateCopyrightDocument",
              component: DocumentManagementCreate,
              meta: {
                isAuthorized: (documentAccess: AuthAccess) => {
                  return documentAccess.hasAdminDocumentAccess;
                },
              },
            },
          ],
        },
        {
          path: "/document-summary",
          component: DocumentViewBase,
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: "",
              name: "DocumentSummary",
              component: DocumentSummary,
              meta: {
                isAuthorized: (documentAccess: AuthAccess) => {
                  return documentAccess.hasAdminDocumentAccess || documentAccess.hasUserDocumentsAccess;
                },
              },
              props: (route) => ({
                id: route.query.id,
              }),
            },
            {
              path: "file-view",
              component: DocumentViewBase,
              meta: {
                requiresAuth: true,
              },
              children: [
                {
                  path: ":id?",
                  name: "DocumentFileView",
                  component: DocumentFileView,
                  meta: {
                    isAuthorized: (documentAccess: AuthAccess) => {
                      return documentAccess.hasAdminDocumentAccess || documentAccess.hasUserDocumentsAccess;
                    },
                  },
                  props: (route) => ({
                    id: route.params.id,
                  }),
                  beforeEnter: (to, _from, next) => {
                    // only allow entry if id is in url
                    if (to.params.id) {
                      next();
                    } else {
                      next({ name: "DocumentError" });
                    }
                  },
                },
              ],
            },
          ],
        },
        {
          path: "/callback",
          name: "Callback",
          component: CallbackPage,
        },
        {
          path: "/request-access",
          name: "RequestAccess",
          component: RequestAccess,
        },
        {
          path: "/access-denied",
          name: "AccessDenied",
          component: AccessDeniedPage,
        },
        {
          path: "/request-management",
          component: RequestManagementBase,
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: "",
              name: "RequestManagement",
              component: RequestManagementPage,
              meta: {
                isAuthorized: (userAccess: AuthAccess) => {
                  return userAccess.hasAdminUserRequestAccess;
                },
              },
            },
            {
              path: "request",
              name: "IndividualRequest",
              component: IndividualRequest,
              meta: {
                isAuthorized: (userAccess: AuthAccess) => {
                  return userAccess.hasAdminUserRequestAccess;
                },
              },
            },
            {
              path: "file-view/:id?",
              name: "RequestFileView",
              component: RequestFileView,
              meta: {
                isAuthorized: (userAccess: AuthAccess) => userAccess.hasAdminUserRequestAccess,
              },
              props: (route: Route) => ({ id: route.params.id }),
            },
          ],
        },
        {
          path: "/subscription-management",
          component: SubscriptionManagementBase,
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: "",
              name: "SubscriptionManagement",
              component: SubscriptionManagementPage,
              meta: {
                isAuthorized: (userAccess: AuthAccess) => {
                  return userAccess.hasUserSearchAccess;
                },
              },
            },
          ],
        },
        {
          path: "/category-management",
          component: CategoryManagementBase,
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: "",
              name: "CategoryManagement",
              component: CategoryManagementPage,
              meta: {
                isAuthorized: (userAccess: AuthAccess) => {
                  return userAccess.hasCategoryManagementAccess;
                },
              },
            },
          ],
        },
        {
          path: "/featured-resource-management",
          component: FeaturedResourceManagementBase,
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: "",
              name: "FeaturedResourceManagement",
              component: FeaturedResourceManagementPage,
              meta: {
                isAuthorized: (userAccess: AuthAccess) => {
                  return userAccess.hasFeaturedResourceManagementAccess;
                },
              },
            },
          ],
        },
        {
          path: "/data-collection-management",
          component: DataCollectionManagementBase,
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: "",
              name: "DataCollectionManagement",
              component: DataCollectionManagementPage,
              meta: {
                isAuthorized: (userAccess: AuthAccess) => {
                  return userAccess.hasDataCollectionManagementAccess;
                },
              },
              props: true,
            },
            {
              path: "create-data-collection",
              name: "CreateNewDataCollection",
              component: CreateNewDataCollectionPage,
              meta: {
                isAuthorized: (documentAccess: AuthAccess) => {
                  return documentAccess.hasDataCollectionManagementAccess;
                },
              },
            },
            {
              path: "edit-data-collection/:id?",
              name: "EditDataCollection",
              component: EditDataCollectionPage,
              meta: {
                isAuthorized: (documentAccess: AuthAccess) => {
                  return documentAccess.hasDataCollectionManagementAccess;
                },
              },
              props: (route) => ({
                id: route.query.id,
              }),
            },
            {
              path: "details/:id?",
              name: "DataCollectionDetails",
              component: DataCollectionDetailsPage,
              meta: {
                isAuthorized: (userAccess: AuthAccess) => {
                  return userAccess.hasDataCollectionManagementAccess;
                },
              },
              props: (route) => ({
                id: route.query.id,
                saveSuccess: route.query.saveSuccess,
                createSuccess: route.query.createSuccess,
              }),
            },
            {
              path: "data-value/:id?",
              name: "AddEditDataValue",
              component: AddEditDataValue,
              meta: {
                isAuthorized: (userAccess: AuthAccess) => {
                  return userAccess.hasDataCollectionManagementAccess;
                },
              },
              props: (route) => ({
                id: route.query.id,
                isNewValueFromMetadata: route.query.isNewValueFromMetadata,
              }),
            },
          ],
        },
        {
          path: "/hazard-data",
          component: DataPages.UserHazardDataBase,
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: "",
              name: "ExploreHazardData",
              component: DataPages.ExploreHazardDataPage,
              meta: {
                isAuthorized: (userAccess: AuthAccess) => userAccess.hasUserHazardDataAccess,
              },
            },
            {
              path: "hazards",
              name: "ExploreByHazards",
              component: DataPages.ExploreByHazardsPage,
              meta: {
                isAuthorized: (userAccess: AuthAccess) => userAccess.hasUserHazardDataAccess,
              },
            },
            {
              path: "hazards/data",
              name: "DataByHazards",
              component: DataPages.DataByHazardsPage,
              meta: {
                isAuthorized: (userAccess: AuthAccess) => userAccess.hasUserHazardDataAccess,
              },
            },
            {
              path: "properties",
              name: "ExploreByProperties",
              component: DataPages.ExploreByPropertiesPage,
              meta: {
                isAuthorized: (userAccess: AuthAccess) => userAccess.hasUserHazardDataAccess,
              },
            },
            {
              path: "properties/data",
              name: "DataByProperty",
              component: DataPages.DataByPropertyPage,
              meta: {
                isAuthorized: (userAccess: AuthAccess) => userAccess.hasUserHazardDataAccess,
              },
            },
          ],
        },
        {
          path: "/usage-analytics",
          component: UserAnalytics,
          meta: {
            isAuthorized: (userAccess: AuthAccess) => userAccess.hasUsageAnalyticsAccess,
          },
        },
        {
          path: "/unauthorized",
          name: "Unauthorized",
          component: ErrorPage,
          props: {
            message: "You must be logged in to view the requested resource.",
          },
        },
        {
          path: "/forbidden",
          name: "Forbidden",
          component: ErrorPage,
          props: {
            message: "You are not permitted to view the requested resource.",
          },
        },
        {
          path: "/permissions-required",
          name: "PermissionsRequired",
          component: PermissionsErrorPage,
        },
        {
          path: "/login-error",
          name: "LoginError",
          component: ErrorPage,
          props: {
            message: "An error occurred during login. Please try again.",
          },
        },
        {
          path: "/document-error",
          name: "DocumentError",
          component: ErrorPage,
          props: {
            message: "Unable to retrieve the requested document.",
          },
        },
        {
          path: "*",
          name: "NotFound",
          component: ErrorPage,
          props: { message: "The requested page does not exist." },
        },
      ],
      scrollBehavior(to, from, savedPosition): Promise<PositionResult> {
        return new Promise((resolve, reject) => {
          if (savedPosition && to.name === "UserSearch") {
            setTimeout(() => {
              resolve(savedPosition);
            }, 1400);
          } else if (savedPosition) {
            resolve(savedPosition);
          } else {
            resolve({ x: 0, y: 0 });
          }
        });
      },
    };
    super(options);

    this.auth = auth;
    this.permissionService = permissionService;

    this.beforeEach(async (to, _from, next) => {
      let authState = this.auth.getAuthState();
      if (!authState?.isAuthenticated && to.name !== "Callback") {
        // attempt to perform a silent sign-in
        try {
          await this.auth.signInSilent();
          authState = this.auth.getAuthState();

          if (authState?.isAuthenticated) {
            await this.permissionService.populateKnowledgeResourcePermissions();
          }
        } catch {}
      }
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (authState != null && authState.isAuthenticated) {
          if (to.meta?.isAuthorized(authState.access) && authState.user?.userStatus === UserStatus.ACTIVE) {
            // user is logged in and able to view the page
            next();
          } else {
            // user is logged in but does not have access
            next("/forbidden");
          }
        } else {
          // user is not logged in
          next("/unauthorized");
        }
      } else {
        // page does not require auth
        next();
      }
    });

    Vue.use(RouterWrapper);
  }
}
