<template>
  <basic-dialog
    :id="dialogId"
    title="Document Metadata Template"
    primaryButton="Download"
    secondaryButton="Cancel"
    :primaryClick="downloadTemplateAsync"
    :secondaryClick="cancel"
    :is-loading="loading"
  >
    <fieldset class="padding-x-0 margin-top-3">
      <legend class="usa-legend margin-0 text-bold">Select a file type</legend>
      <div class="usa-radio">
        <input class="usa-radio__input" id="excel-type" type="radio" v-model="fileType" :value="fileTypes.Excel" />
        <label class="usa-radio__label" for="excel-type"> Excel Workbook </label>
      </div>
      <div class="usa-radio">
        <input class="usa-radio__input" id="csv-type" type="radio" v-model="fileType" :value="fileTypes.Csv" />
        <label class="usa-radio__label" for="csv-type"> CSV </label>
      </div>
    </fieldset>
    <div class="margin-top-2">
      <span v-if="fileType === fileTypes.Csv" class="usa-hint" id="csv-download-hint">
        Selecting CSV will download both the Metadata Template file and a Categories List file to be used for reference.
      </span>
    </div>
    <error-toast id="template-error-toast" message="An error has occurred." />
  </basic-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import { ExportFileType } from "@/dataModel/hazardData/enums/ExportFileType";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import DocumentStagingHttpHelper from "@/components/resources/documentStagingHttpHelper";
import ErrorToast from "@/components/common/ErrorToast.vue";
import CategoryHttpHelper from "@/components/resources/categoryHttpHelper";

@Component({
  components: {
    BasicDialog,
    ErrorToast,
  },
})
export default class DocumentMetadataTemplateDialog extends Vue {
  loading = false;
  fileTypes = ExportFileType;
  fileType: ExportFileType = this.fileTypes.Excel;
  documentStagingHttpHelper = container.get<DocumentStagingHttpHelper>(serviceTypes.DocumentStagingHttpHelper);
  categoryHttpHelper = container.get<CategoryHttpHelper>(serviceTypes.CategoryHttpHelper);

  @Prop({ required: true }) readonly dialogId!: string;

  cancel(): void {
    this.loading = false;
    this.$bvModal.hide(this.dialogId);
  }

  async downloadTemplateAsync(): Promise<void> {
    this.loading = true;

    const blobData = await this.documentStagingHttpHelper.downloadTemplateDataAsync({ fileType: this.fileType });
    if (!blobData) {
      this.$bvToast.show("template-error-toast");
      this.loading = false;
      return;
    }

    let filename = "HKMA Metadata Template";
    if (this.fileType == ExportFileType.Csv) {
      filename += ".csv";

      // Download Categories csv separately
      const categoriesblobData = await this.categoryHttpHelper.downloadCategoriesAsync();
      if (!categoriesblobData) {
        this.$bvToast.show("template-error-toast");
        this.loading = false;
        return;
      }

      this.downloadInBrowser(categoriesblobData, "Categories.csv");
    } else if (this.fileType === ExportFileType.Excel) {
      filename += ".xlsx";
    }

    this.downloadInBrowser(blobData, filename);

    this.loading = false;
    this.$bvModal.hide(this.dialogId);
  }

  downloadInBrowser(blobData: string, filename: string) {
    const url = window.URL.createObjectURL(new Blob([blobData]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }
}
</script>
