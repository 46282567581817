<template>
  <main>
    <section class="grid-container usa-section padding-top-05">
      <div class="grid-row">
        <div class="grid-col">
          <breadcrumb :path="path" />
        </div>
      </div>

      <div class="grid-row margin-top-105em">
        <div class="desktop:grid-col">
          <h1 class="font-heading-xl text-primary-dark margin-top-0">Create New Request</h1>
        </div>
      </div>

      <div class="grid-row border-top padding-top-3">
        <div class="desktop:grid-col">
          <form class="usa-form width-tablet" @submit.prevent>
            <div
              v-if="!isValidForm() && checkValidation"
              class="usa-alert usa-alert--error usa-alert--slim width-mobile-lg"
            >
              <div class="usa-alert__body">
                <p class="usa-alert__text" role="alert">Errors in form.</p>
              </div>
            </div>

            <label class="usa-label" for="input-title">Title</label>
            <input
              class="usa-input width-mobile-lg"
              id="input-title"
              name="input-title"
              type="text"
              v-model.trim="title"
              :disabled="initialUploadCompleted"
              :aria-invalid="isEmpty(title)"
              aria-describedby="title-error-message"
              ref="title"
            />
            <span class="usa-error-message" id="title-error-message" v-if="isEmpty(title)">
              Title cannot be empty
            </span>

            <label class="usa-label" for="input-reason"> Reason for Request </label>
            <textarea
              class="usa-textarea width-mobile-lg"
              id="input-reason"
              name="input-reason"
              v-model.trim="reasonForRequest"
              placeholder="Enter details and/or a justification for your request..."
              :disabled="initialUploadCompleted"
              :aria-invalid="isEmpty(reasonForRequest)"
              aria-describedby="reason-error-message"
            />
            <span class="usa-error-message" id="reason-error-message" v-if="isEmpty(reasonForRequest)">
              Reason for request cannot be empty
            </span>

            <label class="usa-label" for="type-of-request"> Type of Request </label>
            <select
              class="usa-select width-mobile-lg"
              name="type-of-request"
              id="type-of-request"
              v-model.trim="typeOfRequest"
              :disabled="initialUploadCompleted"
              :aria-invalid="isEmpty(typeOfRequest)"
              aria-describedby="type-error-message"
            >
              <option disabled value>- Select -</option>
              <option v-for="type in types" :key="type" :value="type">
                {{ type }}
              </option>
            </select>
            <span class="usa-error-message" id="type-error-message" v-if="isEmpty(typeOfRequest)">
              Type of request cannot be empty
            </span>

            <div v-if="isShareRequest">
              <div class="usa-form-group">
                <fieldset class="usa-fieldset margin-top-3">
                  <legend class="usa-label">Copyright Acknowledgement</legend>
                  <div class="width-mobile-lg">
                    <div class="usa-checkbox margin-left-2">
                      <input
                        class="usa-checkbox__input"
                        id="copyright-acknowledgement"
                        type="checkbox"
                        :value="true"
                        v-model="hasAgreedToCopyrightDisclaimer"
                        :aria-invalid="checkValidation && !hasAgreedToCopyrightDisclaimer"
                        aria-describedby="copyright-acknowledgement-error-message"
                      />
                      <label class="usa-checkbox__label" for="copyright-acknowledgement">
                        {{ copyrightAcknowledgementText }}
                      </label>
                      <span
                        class="usa-error-message"
                        id="copyright-acknowledgement-error-message"
                        v-if="checkValidation && !hasAgreedToCopyrightDisclaimer"
                      >
                        You must agree before the request is created
                      </span>
                    </div>
                  </div>
                </fieldset>

                <label class="usa-label" for="file-input"> Upload Knowledge Resource Files </label>
                <span class="usa-hint"> Allowed file types: {{ fileTypesDisplayString }} </span>
                <input
                  multiple
                  id="file-input"
                  class="usa-file-input"
                  type="file"
                  name="file-input"
                  :accept="fileInputTypesString"
                  :disabled="initialUploadCompleted"
                  required
                  @change="onFilesChanged"
                  :aria-invalid="
                    (checkValidation && (!fileCount || !areAllFilesValidSize())) ||
                    filesContainInvalidFileType() ||
                    tooManyFiles()
                  "
                  aria-describedby="file-empty-error-message file-size-error-message file-invalid-type-error-message file-amount-error-message"
                />
                <span class="usa-error-message" id="file-empty-error-message" v-if="checkValidation && !fileCount">
                  Must upload at least one file
                </span>
                <span
                  class="usa-error-message"
                  id="file-size-error-message"
                  v-else-if="checkValidation && !areAllFilesValidSize()"
                >
                  File size cannot exceed
                  {{ Math.round(maxFileSize / 1000000) }} MB
                </span>
                <span
                  class="usa-error-message"
                  id="file-invalid-type-error-message"
                  v-else-if="filesContainInvalidFileType()"
                >
                  Invalid file type
                </span>
                <span class="usa-error-message" id="file-amount-error-message" v-else-if="tooManyFiles()">
                  Only {{ maxNumberOfFiles }} files can be added to a request
                </span>

                <label class="usa-label" for="classification"> Knowledge Resource Classification </label>
                <select
                  class="usa-select width-mobile-lg"
                  name="classification"
                  id="classification"
                  :disabled="initialUploadCompleted"
                  v-model.trim="fileClassification"
                  :aria-invalid="isEmpty(fileClassification)"
                  aria-describedby="classification-error-message"
                >
                  <option value>- Select -</option>
                  <option
                    v-for="classification in classifications"
                    :key="classification.title"
                    :value="classification.id"
                  >
                    {{ classification.title }}
                  </option>
                </select>
                <span class="usa-error-message" id="classification-error-message" v-if="isEmpty(fileClassification)">
                  Classification cannot be empty
                </span>

                <fieldset v-if="disseminationControls.length" class="usa-fieldset margin-top-3">
                  <legend class="usa-label">Knowledge Resource Dissemination Controls (optional)</legend>
                  <div class="width-mobile-lg">
                    <div
                      class="usa-checkbox margin-left-2"
                      v-for="disseminationControl in disseminationControls"
                      :key="disseminationControl.title"
                    >
                      <input
                        class="usa-checkbox__input"
                        :id="disseminationControl.title"
                        type="checkbox"
                        :value="disseminationControl.id"
                        :disabled="initialUploadCompleted"
                        v-model="fileDisseminationControls"
                      />
                      <label class="usa-checkbox__label" :for="disseminationControl.title">
                        {{ disseminationControl.title }}
                      </label>
                    </div>
                  </div>
                </fieldset>

                <fieldset v-if="scis.length" class="usa-fieldset margin-top-3">
                  <legend class="usa-label">Sensitive Compartmented Information (optional)</legend>
                  <div class="width-mobile-lg">
                    <div class="usa-checkbox margin-left-2" v-for="sci in scis" :key="sci.title">
                      <input
                        class="usa-checkbox__input"
                        :id="sci.title"
                        type="checkbox"
                        :value="sci.id"
                        :disabled="initialUploadCompleted"
                        v-model="fileScis"
                      />
                      <label class="usa-checkbox__label" :for="sci.title">
                        {{ sci.title }}
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>

            <label class="usa-label" for="input-date-needed-by">
              {{ dateNeededText }}
            </label>
            <input
              class="usa-input width-mobile-lg"
              id="input-date-needed-by"
              name="input-date-needed-by"
              type="date"
              v-model.trim="dateNeededBy"
              :disabled="initialUploadCompleted"
              :aria-invalid="isEmpty(dateNeededBy)"
              aria-describedby="date-error-message"
            />
            <span class="usa-error-message" id="date-error-message" v-if="isEmpty(dateNeededBy)">
              Date cannot be empty
            </span>

            <label class="usa-label" for="input-organization"> Organization </label>
            <input
              class="usa-input width-mobile-lg"
              id="input-organization"
              name="input-organization"
              type="text"
              v-model.trim="organization"
              :disabled="initialUploadCompleted"
              :aria-invalid="isEmpty(organization)"
              aria-describedby="org-error-message"
            />
            <span class="usa-error-message" id="org-error-message" v-if="isEmpty(organization)">
              Organization cannot be empty
            </span>

            <label class="usa-label" for="input-email">Email</label>
            <input
              class="usa-input width-mobile-lg"
              id="input-email"
              name="input-email"
              type="email"
              v-model.trim="email"
              :disabled="initialUploadCompleted"
              :aria-invalid="isEmpty(email) || (checkValidation && !isValidEmail(email))"
              aria-describedby="email-error-message valid-email-error-message"
            />
            <span class="usa-error-message" id="email-error-message" v-if="isEmpty(email)">
              Email cannot be empty
            </span>
            <span
              class="usa-error-message"
              id="valid-email-error-message"
              v-else-if="checkValidation && !isValidEmail(email)"
            >
              Must be a valid email
            </span>

            <label class="usa-label" for="input-phone-number"> Phone Number </label>
            <input
              class="usa-input width-mobile-lg"
              id="input-phone-number"
              name="input-phone-number"
              type="tel"
              placeholder="555-555-5555"
              v-model.trim="phoneNumber"
              :disabled="initialUploadCompleted"
              :aria-invalid="isEmpty(phoneNumber) || (checkValidation && !isValidPhoneNumber(phoneNumber))"
              aria-describedby="phone-error-message valid-phone-error-message"
            />
            <span class="usa-error-message" id="phone-error-message" v-if="isEmpty(phoneNumber)">
              Phone number cannot be empty
            </span>
            <span
              class="usa-error-message"
              id="valid-phone-error-message"
              v-else-if="checkValidation && !isValidPhoneNumber(phoneNumber)"
            >
              Must be a valid phone number
            </span>

            <div v-if="isShareRequest">
              <div class="progress width-mobile-lg" id="progress" v-if="showProgress">
                <div
                  class="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  :aria-valuenow="progressValue"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :style="{ width: progressValue + '%' }"
                >
                  {{ progressValue }}%
                </div>
              </div>
              <ul id="file-status-list" class="width-mobile-lg" v-if="requestsWithStatus.length">
                <li v-for="(request, index) in requestsWithStatus" :key="index" class="upload-item">
                  <div v-if="shouldShowAlert(request.status)" :class="getAlertClass(request.status)">
                    <div class="usa-alert__body">
                      <p class="usa-alert__text">{{ getAlertText(request) }}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <loading-buttons
              class="padding-top-3 width-mobile-lg"
              @primaryButtonClick="onCreateClicked"
              @secondaryButtonClick="cancel"
              :isLoading="isLoading"
              :primaryButtonText="primaryButtonText"
              secondaryButtonText="Cancel"
              loadingText="Creating Request..."
              :done="done"
            />
          </form>
        </div>
      </div>

      <success-toast id="request-created-toast" message="Your request has been successfully processed." />
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Request, AppConfig, Classification, DisseminationControl, Sci } from "@/dataModel";
import { UploadDhsRequestFileRequest, UploadFileRequestWrapper as RequestWrapper } from "@/dataModel/requests";
import RequestStatus from "@/constants/RequestStatus";
import RequestType from "@/constants/RequestType";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import { FileUploadStatus } from "@/dataModel/constants/fileUploadStatus";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import RequestHttpHelper from "@/components/resources/requestHttpHelper";
import { BreadcrumbPathItem } from "@/dataModel/interfaces";
import LoadingButtons from "@/components/common/LoadingButtons.vue";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import IPermissionService from "@/services/interfaces/IPermissionService";

@Component({ components: { LoadingButtons, Breadcrumb } })
export default class UserRequestsCreatePage extends Vue {
  readonly createText = "Create";
  readonly retryText = "Retry";

  // input values
  dateNeededBy: string = "";
  email: string = "";
  files: FileList | null = null;
  fileClassification = "";
  fileDisseminationControls: string[] = [];
  fileScis: string[] = [];
  organization: string = "";
  phoneNumber: string = "";
  reasonForRequest: string = "";
  title: string = "";
  typeOfRequest: string = "";
  hasAgreedToCopyrightDisclaimer: boolean = false;

  cancelled = false;
  checkValidation = false;
  classifications: Classification[] = [];
  disseminationControls: DisseminationControl[] = [];
  done = false;
  dhsRequestId = "";
  fileInputTypesString = "";
  fileTypesDisplayString = "";
  filesUploaded = 0;
  initialUploadCompleted = false;
  isDisabled = false;
  maxFileSize = 0;
  maxNumberOfFiles = 20;
  primaryButtonText = this.createText;
  progressValue = 0;
  requestsWithStatus: RequestWrapper[] = [];
  scis: Sci[] = [];
  showProgress = false;
  types = RequestType;
  uploadInProgress = false;
  isLoading = false;
  allowedFileContentTypes: string[] = [];

  path: BreadcrumbPathItem[] = [
    {
      text: "My Content",
    },
    {
      text: "My Requests",
      url: "/user-requests",
    },
    {
      text: "Create New Request",
    },
  ];

  readonly appConfig = container.get<AppConfig>(serviceTypes.AppConfig);

  private userRequestHttpHelper = container.get<RequestHttpHelper>(serviceTypes.RequestHttpHelper);

  private permissionService = container.get<IPermissionService>(serviceTypes.PermissionService);

  get fileCount(): number {
    return this.files?.length ?? 0;
  }

  get isShareRequest(): boolean {
    return this.typeOfRequest === RequestType.Share;
  }

  get isRetry(): boolean {
    return this.primaryButtonText === this.retryText;
  }

  get dateNeededText(): string {
    return this.isShareRequest ? "When would you like the information shared?" : "When is the information needed?";
  }

  get copyrightAcknowledgementText(): string {
    return `I acknowledge by uploading a file, that the U.S. Government must already hold distribution 
    permissions from the copyright owner if the file is copyright restricted.`;
  }

  completeRequestCreation(): void {
    this.done = true;
    // navigate back to request page after 1 second
    this.$bvToast.show("request-created-toast");
    setTimeout(() => {
      this.isLoading = false;
      this.$router.push({ name: "UserRequests" });
    }, 1000);
  }

  onFilesChanged(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files) {
      return;
    }
    this.files = input.files;
  }

  isEmpty(input: string): boolean {
    return this.checkValidation && input.length === 0;
  }

  isValidEmail(email: string): boolean {
    return /\S+@\S+\.\S+/.test(email);
  }

  isValidPhoneNumber(phoneNumber: string): boolean {
    return /^\d{3}-?\d{3}-?\d{4}$/.test(phoneNumber) || /^1-?\d{3}-?\d{3}-?\d{4}$/.test(phoneNumber);
  }

  isValidForm(): boolean {
    const validateFiles =
      this.typeOfRequest === RequestType.Share
        ? this.fileCount > 0 &&
          this.areAllFilesValidSize() &&
          this.fileClassification.length > 0 &&
          !this.filesContainInvalidFileType() &&
          !this.tooManyFiles() &&
          this.hasAgreedToCopyrightDisclaimer
        : true;

    return (
      this.title.length > 0 &&
      this.reasonForRequest.length > 0 &&
      this.typeOfRequest.length > 0 &&
      this.dateNeededBy.length > 0 &&
      this.organization.length > 0 &&
      this.email.length > 0 &&
      this.phoneNumber.length > 0 &&
      this.isValidEmail(this.email) &&
      this.isValidPhoneNumber(this.phoneNumber) &&
      validateFiles
    );
  }

  areAllFilesValidSize(): boolean {
    if (!this.fileCount || !this.files) {
      return true;
    }
    return [...this.files].every((f) => f.size <= this.maxFileSize);
  }

  filesContainInvalidFileType(): boolean {
    return ![...(this.files ?? [])].every((f) => this.allowedFileContentTypes.includes(f.type));
  }

  tooManyFiles(): boolean {
    return this.fileCount > this.maxNumberOfFiles;
  }

  cancel(): void {
    if (this.uploadInProgress) {
      this.cancelled = true;
      this.showProgress = false;
      this.progressValue = 0;
      this.requestsWithStatus.forEach((r) => r.cancelToken?.cancel());
      this.isLoading = false;
      return;
    }

    // return to request home page
    this.$router.push({ name: "UserRequests" });
  }

  async createNewRequest(): Promise<void> {
    const request = new Request(
      undefined,
      undefined,
      RequestStatus.Open,
      this.title,
      this.reasonForRequest,
      this.email,
      this.phoneNumber,
      this.organization,
      false,
      new Date(Date.now()),
      new Date(this.dateNeededBy),
      this.typeOfRequest as RequestType,
      new Date(Date.now()),
    );

    const response = await this.userRequestHttpHelper.createRequest(request);
    this.dhsRequestId = response.id;
  }

  async onCreateClicked(): Promise<void> {
    this.checkValidation = true;

    if (this.isValidForm()) {
      this.isLoading = true;
      if (!this.isRetry) {
        // create request if needed
        await this.createNewRequest();
      }

      if (this.files?.length && this.isShareRequest) {
        // upload files
        await this.uploadFiles();
        return;
      }

      // request has no files attached
      this.completeRequestCreation();
    } else {
      // if form is not valid, scroll to top so the user sees the errors
      document.documentElement.scrollTop = 0;
    }
  }

  async uploadFiles(): Promise<void> {
    this.cancelled = false;
    this.checkValidation = true;

    this.requestsWithStatus
      .filter((r) => r.status === FileUploadStatus.Failed)
      .forEach((r) => (r.status = FileUploadStatus.Pending));

    this.populateRequests();
    await this.processRequests();

    if (this.requestsWithStatus.some((r) => r.status !== FileUploadStatus.Succeeded)) {
      // there are files remaining to retry
      this.showProgress = false;
      this.progressValue = 0;
      this.isDisabled = false;
      this.primaryButtonText = this.retryText;
      return;
    }

    this.completeRequestCreation();
  }

  populateRequests(): void {
    // generate requests list if empty
    if (!this.requestsWithStatus.length) {
      this.requestsWithStatus = [...(this.files ?? [])].map((file) => {
        let request = new UploadDhsRequestFileRequest();
        request.dhsRequestId = this.dhsRequestId;
        request.file = file;
        request.classification = this.fileClassification;
        request.disseminationControlIds = this.fileDisseminationControls;
        request.scis = this.fileScis;
        request.uploadRequestId = uuidv4();
        return new RequestWrapper(request);
      });
    }
  }

  async processRequests(): Promise<void> {
    this.isDisabled = true;
    this.showProgress = true;
    this.initialUploadCompleted = true;
    this.uploadInProgress = true;

    for (const r of this.requestsWithStatus) {
      if (r.status === FileUploadStatus.Succeeded || this.cancelled) {
        this.updateProgressValue();
        continue;
      }

      const request = r.request as UploadDhsRequestFileRequest;
      r.cancelToken = axios.CancelToken.source();

      await this.userRequestHttpHelper
        .addFileToRequest(request, r.cancelToken)
        .then(() => {
          this.filesUploaded += 1;
          r.status = FileUploadStatus.Succeeded;
        })
        .catch((error) => {
          if (/409/.test(error)) {
            // upload already completed
            this.filesUploaded += 1;
            r.status = FileUploadStatus.Succeeded;
          } else if (!axios.isCancel(error)) {
            r.status = FileUploadStatus.Failed;
          }
        })
        .finally(() => this.updateProgressValue());
    }

    this.uploadInProgress = false;
    this.showProgress = false;
  }

  updateProgressValue(): void {
    this.progressValue = Math.min(Math.round(this.progressValue + 100 / (this.files?.length ?? 1)), 100);
  }

  getAlertClass(status: FileUploadStatus): string {
    let alertType = "";
    switch (status) {
      case FileUploadStatus.Pending:
        if (this.cancelled) {
          alertType = "warning";
        }
        break;
      case FileUploadStatus.Succeeded:
        alertType = "success";
        break;
      case FileUploadStatus.Failed:
        alertType = "error";
        break;
    }
    return `usa-alert usa-alert--${alertType} usa-alert--slim`;
  }

  getAlertText(request: RequestWrapper): string {
    const { name } = request.request.file;
    switch (request.status) {
      case FileUploadStatus.Pending:
        if (this.cancelled) {
          return `${name} was cancelled.`;
        }
        return "";
      case FileUploadStatus.Succeeded:
        return `${name} successfully uploaded.`;
      case FileUploadStatus.Failed:
        return `${name} failed to upload.`;
    }
  }

  shouldShowAlert(status: FileUploadStatus): boolean {
    return status === FileUploadStatus.Pending ? this.cancelled : true;
  }

  created(): void {
    this.classifications = this.permissionService.getClassifications();
    this.disseminationControls = this.permissionService.getDisseminationControls();
    this.scis = this.permissionService.getScis();

    this.fileTypesDisplayString = this.appConfig.fileUploadSettings.userRequests.allowedFileTypes
      .map((f) => f.displayName)
      .join(", ");
    this.fileInputTypesString = this.appConfig.fileUploadSettings.userRequests.allowedFileTypes
      .map((f) => f.fileType)
      .join(", ");
    this.maxFileSize = this.appConfig.fileUploadSettings.userRequests.maxFileSizeInBytes;
    this.allowedFileContentTypes = this.appConfig.fileUploadSettings.documents.allowedFileTypes.map((x) => x.fileType!);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.usa-form {
  width: 100%;
  max-width: 100%;
}

#progress {
  margin-top: 16px;
}

#file-status-list {
  margin-top: 16px;
  max-height: 8rem;
  overflow: auto;
}

.upload-item {
  list-style-type: none;
}

.usa-alert__text {
  font-size: 14px;
}
</style>
