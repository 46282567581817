import FileUploadSettings from "./fileUpload/fileUploadSettings";
import { WebsiteClassificationSettings } from "@/dataModel";
import { merge } from "lodash";
import ErrorPageSettings from "./interfaces/ErrorPageSettings";

export class AppConfig {
  populateJson(jsonObject: any) {
    merge(this, jsonObject);
    // nested properties need to be assigned separately
    let origin = window.location.origin;
    if (origin.endsWith("/")) {
      origin = origin.substring(0, origin.lastIndexOf("/") + 1);
    }
    this.baseUrl = origin;
  }
  sessionTimeoutInSeconds: number = 300;
  idleTabSessionTimeoutInSeconds: number = 30;
  secondsBeforeIdleLogoutToShowWarning: number = 30;
  baseUrl: string = "http://localhost:8081";
  keycloakBaseUrl: string = "http://localhost:57910/auth/realms/HKMA";
  apiEndpoint: string = "/api";
  fileUploadSettings: FileUploadSettings = new FileUploadSettings();
  searchRequestMinimumIntervalInMilliseconds: number = 500;
  featuredDocumentsLimit = 5;
  featuredHazardDataLimit = 5;
  websiteClassificationSettings!: WebsiteClassificationSettings;
  errorPageSettings!: ErrorPageSettings;
}
