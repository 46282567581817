import HttpHelper from "@/components/resources/httpHelper";
import { inject, injectable } from "inversify";
import serviceTypes from "@/dependencyInjection/types";
import { Setting } from "@/dataModel";
import { UpdateSettingRequest } from "@/dataModel/requests";

const settingUrl = "setting/";

@injectable()
export default class SettingHttpHelper {
  httpHelper: HttpHelper;

  constructor(@inject(serviceTypes.HttpHelper) httpHelper: HttpHelper) {
    this.httpHelper = httpHelper;
  }

  async getAllSettings(): Promise<Setting[]> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(settingUrl)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " + response.status + " : " + response.statusText + " getting all settings.",
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting all settings."));
        });
    });
  }

  async getSettingByKey(settingKey: string): Promise<Setting> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpGet(settingUrl + "key?SettingKey=" + settingKey)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " +
                  response.status +
                  " : " +
                  response.statusText +
                  " getting setting by key: " +
                  settingKey,
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while getting setting by key: " + settingKey));
        });
    });
  }

  async updateSetting(request: UpdateSettingRequest): Promise<Setting> {
    return new Promise((resolve, reject) => {
      this.httpHelper
        .httpPut(settingUrl, request)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(
              new Error(
                "Response returned " +
                  response.status +
                  " : " +
                  response.statusText +
                  " updating setting with key: " +
                  request.settingKey,
              ),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(new Error("An error occurred while updating setting with key: " + request.settingKey));
        });
    });
  }
}
