<template>
  <section class="classification-banner" v-bind:style="{ 'background-color': backgroundColor }">
    <p class="classification-text" v-bind:style="{ color: textColor }">
      Highest Possible Sensitivity of Information is {{ marking }}
    </p>
  </section>
</template>
<script lang="ts">
import { AppConfig } from "@/dataModel";
import container from "@/dependencyInjection/config";
import serviceTypes from "@/dependencyInjection/types";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ClassificationNotice extends Vue {
  private appConfig = container.get<AppConfig>(serviceTypes.AppConfig);

  get marking() {
    return this.appConfig.websiteClassificationSettings.classificationMarking;
  }

  get backgroundColor() {
    return this.appConfig.websiteClassificationSettings.classificationBannerBackgroundColor;
  }

  get textColor() {
    return this.appConfig.websiteClassificationSettings.classificationBannerTextColor;
  }
}
</script>

<style lang="scss">
@import "~@/assets/uswds/scss/uswds.scss";

.classification-banner {
  height: units(3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.classification-text {
  display: inline-block;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: family("body");
  font-size: size("body", "3xs");
  line-height: lh("body", 1);
}
</style>
