<template>
  <basic-dialog id="box-stats-dialog" title="Chart Stats" primaryButton="Close" :primaryClick="close">
    <h1 class="font-heading-md text-primary-dark">{{ stats.seriesName }}</h1>
    <hr />
    <div class="grid-row">
      <p class="grid-col text-bold">Minimum</p>
      <p class="grid-col-auto">{{ stats.min | formatValue }}</p>
    </div>
    <div class="grid-row">
      <p class="grid-col text-bold">Lower Quartile</p>
      <p class="grid-col-auto">{{ stats.q1 | formatValue }}</p>
    </div>
    <div class="grid-row">
      <p class="grid-col text-bold">Median</p>
      <p class="grid-col-auto">{{ stats.median | formatValue }}</p>
    </div>
    <div class="grid-row">
      <p class="grid-col text-bold">Upper Quartile</p>
      <p class="grid-col-auto">{{ stats.q3 | formatValue }}</p>
    </div>
    <div class="grid-row">
      <p class="grid-col text-bold">Maximum</p>
      <p class="grid-col-auto">{{ stats.max | formatValue }}</p>
    </div>
    <hr />
    <div class="grid-row">
      <p class="grid-col text-bold">Mean</p>
      <p class="grid-col-auto">{{ stats.mean | formatValue }}</p>
    </div>
    <div class="grid-row">
      <p class="grid-col text-bold">Standard Deviation</p>
      <p class="grid-col-auto">{{ stats.stdDev | formatValue }}</p>
    </div>
  </basic-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import BasicDialog from "@/components/common/BasicDialog.vue";
import BoxPlotStats from "@/dataModel/hazardData/boxPlotStats";

@Component({
  components: {
    BasicDialog,
  },
  filters: {
    formatValue(val: number) {
      let formatted = val.toPrecision(5).replace(/0+$/, "");

      // Trim trailing decimal if it exists after removing the trailing zeros
      if (formatted.endsWith(".")) {
        formatted = formatted.slice(0, formatted.length - 1);
      }

      return formatted;
    },
  },
})
export default class BoxPlotStatsDialog extends Vue {
  @Prop() readonly stats!: BoxPlotStats;

  close(): void {
    this.$bvModal.hide("box-stats-dialog");
  }
}
</script>

<style lang="scss" scoped></style>
