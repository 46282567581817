import { Vue, Component } from "vue-property-decorator";
import ServiceTypes from "@/dependencyInjection/types";
import FeaturedResourceHttpHelper from "@/components/resources/featuredResourceHttpHelper";
import ServiceContainer from "@/dependencyInjection/config";
import FeaturedHazardType from "@/constants/FeaturedHazardType";
import CurrentAndUpcomingTable from "./CurrentAndUpcomingTable.vue";
import SiteDocumentsSection from "./SiteDocumentsSection.vue";
import FeaturedResourceDialog from "../Dialogs/FeaturedResourceDialog.vue";
import RemoveFeaturedResourceDialog from "../Dialogs/RemoveFeaturedResourceDialog.vue";
import { FeaturedResourceAction } from "../Constants/FeaturedResourceAction";

@Component({
  components: {
    CurrentAndUpcomingTable,
    SiteDocumentsSection,
    FeaturedResourceDialog,
    RemoveFeaturedResourceDialog,
  },
})
export class FeaturedResourcesMixin extends Vue {
  featuredView = FeaturedHazardType.Document;
  successToastId = "featured-resources-success-toast";
  errorToastId = "featured-resoruces-error-toast";
  toastMessage = "";

  featuredResourceHttpHelper = ServiceContainer.get<FeaturedResourceHttpHelper>(
    ServiceTypes.FeaturedResourceHttpHelper,
  );

  showResponseStatusDialog(action: FeaturedResourceAction, resourceType?: FeaturedHazardType): void {
    this.$emit("show-new-status", { action, resourceType });
  }

  showSuccessToast(msg: string) {
    this.toastMessage = msg;
    this.$bvToast.show(this.successToastId);
  }

  showErrorToast(msg: string) {
    this.toastMessage = msg;
    this.$bvToast.show(this.errorToastId);
  }
}
