<template>
  <div class="display-flex flex-align-start">
    <div
      class="status-tag display-flex flex-align-center margin-y-05"
      :class="{ 'has-border': !status }"
      :style="{ 'background-color': tagBgColor, color: tagFgColor }"
    >
      <i class="fa fa-sm" :class="tagIcon"></i>
      <p class="status-tag-text margin-left-1 margin-y-0">{{ tagText }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import FileStatus from "@/constants/FileStatus";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class BatchStatusTag extends Vue {
  @Prop({ required: true }) status?: FileStatus;
  @Prop({ required: true }) count!: number;

  get tagIcon(): string {
    if (!this.status) {
      return "";
    }

    switch (this.status) {
      case FileStatus.Approved:
        return "fa-check";
      case FileStatus.AwaitingApproval:
        return "fa-clock";
      case FileStatus.Converted:
        return "fa-pen";
      case FileStatus.Declined:
        return "fa-ban";
      case FileStatus.Error:
        return "fa-exclamation";
      default:
        return "";
    }
  }

  get tagBgColor(): string {
    switch (this.status) {
      case FileStatus.Approved:
        return "#538200";
      case FileStatus.AwaitingApproval:
        return "#FEE685";
      case FileStatus.Converted:
        return "#003267";
      case FileStatus.Declined:
        return "#B50909";
      case FileStatus.Error:
        return "#D83933";
      default:
        return "#ffffff";
    }
  }

  get tagFgColor(): string {
    return !this.status || this.status === FileStatus.AwaitingApproval ? "black" : "white";
  }

  get tagText(): string {
    if (!this.status) {
      return "Batch Empty";
    }

    return `${this.status!} (${this.count})`;
  }
}
</script>

<style scoped lang="scss">
.status-tag {
  height: 30px;
  padding: 0 10px;
  border-radius: 15px;
}

.has-border {
  border: 1px solid;
}

.status-tag-text {
  font-weight: 500;
  font-size: 0.8em;
}
</style>
