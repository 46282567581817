export default class CreateSourceRequest {
  knowledgeResourceId: string;
  title: string;
  contributorDisplay: string;
  documentDateTime: Date;
  
  constructor(
    knowledgeResourceId?: string,
    title?: string,
    contributorDisplay?: string,
    documentDateTime?: Date,
  ) {
    this.knowledgeResourceId = knowledgeResourceId ?? "";
    this.title = title ?? "";
    this.contributorDisplay = contributorDisplay ?? "";
    this.documentDateTime = documentDateTime ?? new Date;
  }
}