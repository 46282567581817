<template>
  <li :class="`usa-card tablet:grid-col-${cardWidth}`">
    <div class="usa-card__container">
      <div class="usa-card__header display-flex justify-content-between">
        <h2 class="usa-card__heading">{{ title }}</h2>
        <i class="fa fa-info-circle ml-3" :title="description" />
      </div>
      <div class="usa-card__body">
        <slot></slot>
      </div>
    </div>
  </li>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class BasicCard extends Vue {
  @Prop({ default: "" }) readonly title!: string;
  @Prop({ default: "" }) readonly description!: string;
  @Prop({ default: "3" }) readonly cardWidth!: string;
}
</script>

<style scoped lang="scss">
.usa-card__heading {
  font-size: 0.9rem;
}
</style>
