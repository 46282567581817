import { SiteDocument } from "@/dataModel";
import { DocumentStagingState } from "@/typings/store/states/DocumentStagingState";
import { GetterTree } from "vuex";

export enum DocumentStagingGetters {
  GET_SUMMARIZED_BATCH_ID = "GET_SUMMARIZED_BATCH_ID",
  GET_SUMMARIZED_BATCH_DOCUMENTS = "GET_SUMMARIZED_BATCH_DOCUMENTS",
}

export const getters: GetterTree<DocumentStagingState, any> = {
  [DocumentStagingGetters.GET_SUMMARIZED_BATCH_ID](state): string {
    return state.summarizedBatchId;
  },
  [DocumentStagingGetters.GET_SUMMARIZED_BATCH_DOCUMENTS](state): SiteDocument[] {
    return state.summarizedBatchSiteDocuments;
  },
};
